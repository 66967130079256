<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span>{{ type | titlecase }} App Bundle</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="appBundleFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true"><fa-icon [icon]="faTimes"></fa-icon></span>
    </button>
  </div>

  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <div class="row">
        <div *ngIf="action === 'delete'">
          <div class="col-12">
            <p class="font-16">
              Archive the app device type
              {{ targetBundle.label }}?
            </p>
          </div>
        </div>
        <form
          *ngIf="!formLoading && action !== 'delete'"
          class="text-center edit-form striped-form"
          autocomplete="disabled"
          [formGroup]="manageAppBundleForm"
        >
          <div class="row p-0">
            <div class="col-12">
              <p>
                <span class="action-msg-small">Required*</span>
              </p>
            </div>
          </div>
          <div class="row striped">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form">
                  <input
                    type="text"
                    id="itemLabel"
                    class="form-control"
                    formControlName="label"
                    mdbInput
                  />
                  <label for="itemLabel">* Label</label>
                  <mdb-error
                    *ngIf="label.invalid && (label.dirty || label.touched)"
                  >
                    Please provide a label
                  </mdb-error>
                </div>
              </div>
              <div class="grid-item">
                <div class="md-form">
                  <button
                    mdbBtn
                    class="info-button color-theme-base-medium"
                    mdbPopover="Note: Name is a code-friendly reference for this experience. Name should only contain numbers, leters, and underscores, and it must be unique across the entire Admin Panel System."
                    placement="right"
                    mdbPopoverHeader="About the Name Field"
                    triggers="focus"
                    mdbWavesEffect
                  >
                    <mdb-icon fas icon="info-circle"></mdb-icon>
                  </button>
                  <input
                    type="text"
                    id="itemName"
                    class="form-control"
                    formControlName="name"
                    mdbInput
                  />
                  <label for="itemName">* Name</label>
                  <mdb-error
                    *ngIf="name.invalid && (name.dirty || name.touched)"
                  >
                    Please provide a name
                  </mdb-error>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form">
                  <input
                    type="text"
                    id="experienceDescription"
                    class="form-control"
                    formControlName="description"
                    mdbInput
                  />
                  <label for="experienceDescription">Description</label>
                </div>
              </div>
              <div class="grid-item">
                <div class="md-form">
                  <input
                    type="text"
                    id="bundleID"
                    class="form-control"
                    formControlName="bundle_id"
                    mdbInput
                  />
                  <label for="bundleID">* Bundle ID</label>
                  <mdb-error
                    *ngIf="
                      bundle_id.invalid &&
                      (bundle_id.dirty || bundle_id.touched)
                    "
                  >
                    Please provide a bundle ID
                  </mdb-error>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form">
                  <input
                    type="text"
                    id="environment"
                    class="form-control"
                    formControlName="environment"
                    mdbInput
                  />
                  <label for="environment">* Environment</label>
                  <mdb-error
                    *ngIf="
                    environment.invalid &&
                      (environment.dirty || environment.touched)
                    "
                  >
                    Please provide an environment
                  </mdb-error>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      [disabled]="manageAppBundleForm.invalid"
      (click)="manageAppBundle()"
      class="theme-modal-button btn"
    >
      {{ action }} App Bundle
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      class="theme-modal-cancel-button btn"
      data-dismiss="modal"
      (click)="appBundleFrame.hide()"
    >
      Close
    </button>
  </div>
</div>
