import {
  Component,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
} from "@angular/core";

import { NgIf } from "@angular/common";

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

import { Subscription } from "rxjs";

import { ToastrService, ToastPackage, Toast } from "ngx-toastr";

import { IndividualConfig } from "ngx-toastr/toastr/toastr-config";
import {
  faTimes,
  faCheckCircle,
  faCircleNotch,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-custom-toast",
  templateUrl: "./custom-toast.component.html",
  styleUrls: ["./custom-toast.component.scss"],
  animations: [
    trigger("flyInOut", [
      state("inactive", style({ opacity: 0 })),
      state("active", style({ opacity: 1 })),
      state("removed", style({ opacity: 0 })),
      transition(
        "inactive => active",
        animate("{{ easeTime }}ms {{ easing }}")
      ),
      transition("active => removed", animate("{{ easeTime }}ms {{ easing }}")),
    ]),
  ],
  preserveWhitespaces: false,
})
export class CustomToastComponent extends Toast {
  public faTimes = faTimes;
  public faCheckCircle = faCheckCircle;
  public faCircleNotch = faCircleNotch;
  public faExclamationCircle = faExclamationCircle;
  public faExclamationTriangle = faExclamationTriangle;
}
