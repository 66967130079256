import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, ElementRef, OnInit } from "@angular/core";
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { DateFormatPipe } from "ngx-moment";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-add-setting-modal",
  templateUrl: "./add-setting-modal.component.html",
  styleUrls: ["./add-setting-modal.component.scss"],
})
export class AddSettingModalComponent implements OnInit {
  //incoming
  public teamProps;
  public teamID: number;

  public props: any = [];
  public props_names: any = [];

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another Class",
    retry: "Retry",
  };

  public eventPropForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Add Event Prop to client",
  };
  public formState: string = "active";

  public continueType: string = "reset";

  public propsForm = this.formBuilder.group({
    props_name: "",
  });
  public propFormGroup: UntypedFormGroup;

  searchText = new Subject();
  results: Observable<string[]>;

  public token: string;

  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    public eventPropFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private _settingsService: SettingsService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.propFormGroup = this.propsForm;
    this.retrieveEventProps();
    this.results = this.searchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value))
    );
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.props_names.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }

  public retrieveEventProps() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/props",
      "get",
      data,
      getOptions
    );

    eventProps.subscribe((response) => {
      ;
      this.props = response.props;
      this.processEventProps();
      this.props.forEach((element) => {
        this.props_names.push(element.label);
      });
      this.results = this.searchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value))
      );
    });
  }

  private processEventProps() {
    let temp_props = [];
    ;
    this.props.forEach((element) => {
      let index = this.teamProps.findIndex((x) => x.props.id === element.id);
      if (index == -1) {
        temp_props.push(element);
      }
    });

    this.props = temp_props;
  }

  public addEventProps() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Adding prop</span>";
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let addedEventProp = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" +
        this.teamID +
        "/schedule/prop/" +
        this.getPropIdFromLabel(this.propsForm.value.props_name) +
        "/add",
      "post",
      data,
      getOptions
    );

    addedEventProp.subscribe((response) => {
      this.msgs.processingMsg = "";
      this.msgs.errorMsg = "";
      this.msgs.statusMsg = "Event Prop successfully added";
      if (response.error != 418) {
        ;
        let outgoingData = {
          action: "add",
          prop: response.prop,
        };
        this.outgoing.next(outgoingData);
      }
    },
    (error) => {
      ;
      this.msgs.processingMsg = "";
      let errorMsg = JSON.parse(error._body);
      this.btnLabel.retry = "Retry";
      this.msgs.errorMsg = errorMsg.error;
    });
  }

  private getPropIdFromLabel(label: string) {
    let index = this.props.findIndex((x) => x.label === label);
    ;
    ;
    return this.props[index].id;
  }

  public resetForm() {
    this.propsForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        
        this.eventPropFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

}
