import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ElementRef } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { DateFormatPipe } from "ngx-moment";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-add-event-type-modal",
  templateUrl: "./add-event-type-modal.component.html",
  styleUrls: ["./add-event-type-modal.component.scss"],
})
export class AddEventTypeModalComponent implements OnInit {
  public teamID: number;

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another Class",
    retry: "Retry",
  };

  public eventTypesForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Schedule a Class for the experience:",
  };
  public formState: string = "active";

  public eventTypes: any;
  public token: string;

  public constrained: boolean = false;

  private outgoing: Subject<any> = new Subject();

  public timeBoundariesSelect = [
    { label: "Bound", value: "bound" },
    { label: "None", value: "none" },
  ];

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    public eventTypeFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.retrieveTeamID();

    // !!!! add dynamic settings from the client_settings
    this.eventTypes = this.formatEventTypes();

    this.eventTypesForm = new UntypedFormGroup({
      label: new UntypedFormControl(""),
      name: new UntypedFormControl(""),
      description: new UntypedFormControl(""),
      time_boundaries: new UntypedFormControl(""),
    });

    this.retrieveToken();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }
  
  private retrieveTeamID(){
    this.teamID = JSON.parse(this.coolLocalStorage.getItem("admin_panel_team_id"));
  }

  // this needs to change once the eventTypes options are placed on the client_settings
  private formatEventTypes() {
    let eventTypes = [
      { label: "label", value: "string" },
      { label: "name", value: "string" },
      { label: "description", value: "string" },
      { label: "time_boundaries", value: "string" },
    ];

    return eventTypes;
  }

  public addEventTypes() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      label: this.eventTypesForm.value.label,
      name: this.eventTypesForm.value.name,
      description: this.eventTypesForm.value.description,
      time_boundaries: this.eventTypesForm.value.time_boundaries,
    };

    let addEventType = this._xrPlatformRestService.restfulAPIQuery(
      "/scheduled-event-type",
      "post",
      data,
      getOptions
    );

    addEventType.subscribe((response) => {
      if (response.error != 418) {
        ;
        let outgoingData = {
          action: "add",
          prop: response.event_type,
        };
        this.outgoing.next(outgoingData);
      }
    });

    this.eventTypeFrame.hide();
  }
}
