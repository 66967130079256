import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { throwError } from "rxjs";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import zoomPlugin from "chartjs-plugin-zoom";
//import { Chart, Point } from 'chart.js';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
import "chartjs-plugin-zoom";
import Chart from "chart.js/auto";
Chart.register(zoomPlugin);
import { MetricsServicesService } from "../../../services/metrics-services.service";
import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-user-inventory-breakdown",
  templateUrl: "./user-inventory-breakdown.component.html",
  styleUrls: ["./user-inventory-breakdown.component.scss"],
})
export class UserInventoryBreakdownComponent implements OnInit {
  @Input("teamID") teamID: number;
  @Input("session_id") session_id: number;
  @Input("labels") labels: any;

  public token: string;

  @ViewChild("overallChart") private overallChartRef: ElementRef;
  @ViewChild("statusChart") private statusChartRef: ElementRef;
  @ViewChild("countsChart") private countsChartRef: ElementRef;
  private overallChart: Chart;
  private statusChart: Chart;
  private countsChart: Chart;

  public metricsLoading: boolean = true;
  public init: boolean = true;
  public usersSelect: { value: string; label: string }[];
  public userLoading: boolean = true;

  constructor(
    private http: HttpClient,
    private _clientManagementService: ClientManagementService,
    private _metricsServicesService: MetricsServicesService,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService
  ) { }

  public chartTypeLT: string = "line";
  public chartDatasetsLT: Array<any> = [];
  public chartLabelsLT: Array<any> = [];

  public yLabels: Array<string>;

  public chartOptionsLT: any = {};
  public chartColorsLT: Array<any> = [
    {
      backgroundColor: ["#7F95D1"],
      borderColor: ["#7F95D1"],
    },
  ];

  public empty: boolean = true;

  public inventoryMetricsLoading = false;
  public active: any;

  ngOnInit(): void {
    this.inventoryMetricsLoading = true;
  }

  ngAfterViewInit(): void {
    this.retrieveToken();
    this.getSessionUsers();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public getSessionUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.restfulAPIQuery(
      "/inventory/user/metrics/" + this.session_id,
      "get",
      {},
      options
    );

    retrieveUsers.subscribe(
      (response) => {
        this.processUsers(response.entities);
      },
      (error) => { }
    );
  }

  private processUsers(users) {
    let usersSelect = [];

    //first alpha sort users
    users.sort((a, b) => a.last_name.localeCompare(b.last_name));

    users.forEach((users) => {
      let thisUser = {
        value: users.id,
        label: `${users.first_name} ${users.last_name}`,
      };

      usersSelect.push(thisUser);
    });

    this.usersSelect = usersSelect;
    this.userLoading = false;
  }

  public getInventoryMetrics(event) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveInventoryMetrics = this._xrPlatformRestService.restfulAPIQuery(
      "/inventory/metrics/" + this.session_id,
      "get",
      {},
      options
    );

    retrieveInventoryMetrics.subscribe(
      (response) => {
        let aggregates =
          response.aggregates !== undefined &&
            response.aggregates.length &&
            response.aggregates[0].aggregates !== undefined
            ? response.aggregates[0].aggregates
            : {};

        let statusDatasets = [];
        let statusLabels = [];

        let overallDatasets = [];
        let overallLabels = [];

        let countsDatasets = [];
        let countsLabels = [];

        for (let key in aggregates) {
          let values = aggregates[key];

          for (let value in values) {
            if (key == "status") {
              statusDatasets.push(values[value]);
              statusLabels.push(value);
            } else if (key == "overall") {
              overallDatasets.push(values[value]);
              overallLabels.push(value);
            } else {
              countsDatasets.push(values[value]);
              countsLabels.push(value);
            }
          }
        }

        if (this.overallChart) {
          this.overallChart.destroy();
        }

        let max = Math.max(...overallDatasets);
        const overallData = {
          labels: overallLabels,
          datasets: [
            {
              data: overallDatasets,
              borderColor: "#483c46",
              backgroundColor: "rgba(29; 61; 124; 0.65)",
              label: "",
            },
          ],
        };

        this.overallChart = new Chart(this.overallChartRef.nativeElement, {
          type: "bar",
          data: overallData,
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });

        max = Math.max(...overallDatasets);
        const statusData = {
          labels: statusLabels,
          datasets: [
            {
              data: statusDatasets,
              borderColor: "#483c46",
              backgroundColor: "rgba(29; 61; 124; 0.65)",
              label: "",
            },
          ],
        };

        if (this.statusChart) {
          this.statusChart.destroy();
        }

        this.statusChart = new Chart(this.statusChartRef.nativeElement, {
          type: "bar",
          data: statusData,
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });

        const countsData = {
          labels: countsLabels,
          datasets: [
            {
              data: countsDatasets,
              borderColor: "#483c46",
              backgroundColor: "rgba(29; 61; 124; 0.65)",
              label: "",
            },
          ],
        };

        if (this.countsChart) {
          this.countsChart.destroy();
        }

        this.countsChart = new Chart(this.countsChartRef.nativeElement, {
          type: "bar",
          data: countsData,
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });

        this.metricsLoading = false;
      },
      (error) => { }
    );
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }
}
