<ng-container [formGroup]="formGroup">
    <!--start prop type string-->
    <div class="prop-item grid-item" *ngIf="prop.props.type === 'string'">
      <div class="md-form">
        <input
          type="text"
          [id]="prop.props.name"
          class="form-control"
          [formControlName]="prop.props.name"
          mdbInput
        />
        <label class="active-label active" [for]="prop.props.name">{{ prop.props.label }}</label>
      </div>
    </div>
    <!--end prop type string-->
    <!--start prop type select_list-->
    <div class="prop-item grid-item" *ngIf="prop.props.type === 'select_list'">
      <div class="md-form">
        <mdb-select-2
          placeholder="Select {{ prop.props.label }}"
          label="{{ prop.props.label }}"
          [formControlName]="prop.props.name"
        >
          <mdb-select-option *ngFor="let option of teamPropsSelects[prop.props.name]" [value]="option.value">
            {{ option.label }}
          </mdb-select-option>
        </mdb-select-2>
      </div>
    </div>
    <!--end prop type select_list-->
    <!--start prop type image-->
    <div class="prop-item grid-item" *ngIf="prop.props.type === 'asset' && prop.props.subtype === 'image'">
      <div class="prop-wrapper">
        <button
          class="theme-modal-button btn"
          type="button"
          mdbBtn
          mdbWavesEffect
          (click)="openSelectMediaModalForProp(prop, index)"
        >
          <fa-icon [icon]="faCirclePlus"></fa-icon>
          <span>{{ action === 'update' ? 'Update' : 'Add' }} {{ prop.props.label }}</span>
        </button>
        <div class="thumbnail" *ngIf="prop.props.asset !== undefined && prop.props.asset !== null">
          <div
            *ngIf="prop.props.asset.thumbnail_asset_url !== null"
            [ngStyle]="{ 'background-image': 'url(' + prop.props.asset.thumbnail_asset_url + ')' }"
            class="thumbnail-container"
          ></div>
          <ng-template [ngIf]="prop.props.asset.thumbnail_asset_url === null">
            <div *ngIf="prop.props.subtype === 'image'" class="thumbnail-container fallback">
              <fa-icon [icon]="faImages"></fa-icon>
            </div>
          </ng-template>
        </div>
        <div *ngIf="prop.props.asset === undefined || prop.props.asset === null" class="thumbnail-container">
          <fa-icon [icon]="faImages"></fa-icon>
        </div>
      </div>
    </div>
    <!--end prop type image-->
    <!--start prop type add_media_button-->
    <div class="prop-item grid-item" *ngIf="prop.props.type === 'add_media_button'">
      <div class="prop-wrapper">
        <button
          class="theme-modal-button btn"
          type="button"
          mdbBtn
          mdbWavesEffect
          (click)="openManageMediaModal(targetEvent)"
        >
          <fa-icon [icon]="faCirclePlus"></fa-icon>
          {{ action === 'update' ? 'Update' : 'Add' }} {{ labels.media.plural }}
        </button>
        <span>{{ labels.media.plural | titlecase }} Items: {{ attached.length }}</span>
      </div>
    </div>
    <!--end prop type add_media_button-->
  </ng-container>
