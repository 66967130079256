<div class="interactive-analytics-card">
  <div class="d-flex justify-content-between align-items-center w-100 metrics-controls">
    <div class="back-button">
      <!-- back btn -->
      <a
        mdbBtn
        class="btn-flat p-0 m-0 back-button"
        mdbWavesEffect
        [routerLink]="['/client', clientCode, 'analytics']"
        [queryParams]="{
          breakdownType: breakdownType,
          start_date: start_date,
          end_date: end_date
        }"
        mdbTooltip="Interactive Analytics"
        placement="bottom"
      >
        <mdb-icon fas icon="chevron-left"></mdb-icon>
        Back
      </a>
    </div>
  </div>
  <mdb-card class="session-breakdown-card analytics-card">
    <!-- button  -->
    <div *ngIf="navigationLoading">
      <div class="loading-text">
        <p>Loading...</p>
      </div>
    </div>
    <div *ngIf="!navigationLoading" class="container-nav-breakdown">
      <div class="row align-items-center mt-3 mb-3">
        <div class="col-3 d-flex align-items-center justify-content-start">
          <a
            mdbBtn
            class="btn-flat p-0 m-0 back-button w-100"
            [ngClass]="{ diable: navActive }"
            mdbWavesEffect
            [routerLink]="[
              '/client',
              clientCode,
              'analytics',
              previous_session
            ]"
            [queryParams]="{
              breakdownType: breakdownType,
              start_date: start_date,
              end_date: end_date
            }"
            mdbTooltip="Previous Session"
            placement="bottom"
          >
            <mdb-icon fas icon="chevron-left"></mdb-icon>
            Previous Session
          </a>
        </div>
        <div class="col-6 text-center">
          <h6 class="mb-0">
            <strong>Session Name: </strong>{{ session_name }}
          </h6>
        </div>
        <div class="col-3 d-flex align-items-center justify-content-end">
          <a
            mdbBtn
            class="btn-flat p-0 m-0 back-button w-100 text-right"
            [ngClass]="{ diable: navActive }"
            mdbWavesEffect
            [routerLink]="['/client', clientCode, 'analytics', next_session]"
            [queryParams]="{
              breakdownType: breakdownType,
              start_date: start_date,
              end_date: end_date
            }"
            mdbTooltip="Next Session"
            placement="bottom"
          >
            Next Session
            <mdb-icon fas icon="chevron-right"></mdb-icon>
          </a>
        </div>
      </div>
      <hr />
      <mdb-tabset
        [buttonClass]="'classic-tabs'"
        class="admin-tab-styles sessions-breackdown-tabs"
        [contentClass]="'remove-card'"
      >
        <mdb-tab
          heading="{{
            labels.user.singular | titlecase
          }} Inventory Score Breakdown"
          *ngIf="metricsOptions.user_inventory"
          (select)="tabSelected('user_inventory')"
        >
          <app-user-inventory-breakdown
            *ngIf="loading.user_inventory"
            [teamID]="teamID"
            [session_id]="session_id"
            [labels]="labels"
          ></app-user-inventory-breakdown>
        </mdb-tab>
        <mdb-tab
          heading="{{ labels.user.singular | titlecase }} Entries Breakdown"
          *ngIf="metricsOptions.user_entries"
          (select)="tabSelected('user_entries')"
        >
          <app-connection-line-breakdown
            *ngIf="loading.user_entries"
            [teamID]="teamID"
            [session_id]="session_id"
            [labels]="labels"
            [users]="users"
            [clientSettings]="clientSettings"
          >
          </app-connection-line-breakdown>
        </mdb-tab>
        <mdb-tab
          heading="{{ labels.user.singular | titlecase }} Speaking Breakdown"
          *ngIf="metricsOptions.user_speaking"
          (select)="tabSelected('user_speaking')"
        >
          <app-speaking-line-breakdown
            *ngIf="loading.user_speaking"
            [teamID]="teamID"
            [session_id]="session_id"
            [labels]="labels"
            [users]="users"
            [clientSettings]="clientSettings"
          >
          </app-speaking-line-breakdown>
        </mdb-tab>
        <mdb-tab
          heading="{{ labels.user.singular | titlecase }} Gazing Breakdown"
          *ngIf="metricsOptions.users_gazing"
          (select)="tabSelected('users_gazing')"
        >
          <app-gazing-breakdown
            *ngIf="loading.users_gazing"
            [teamID]="teamID"
            [session_id]="session_id"
            [labels]="labels"
            [users]="users"
            [clientSettings]="clientSettings"
          ></app-gazing-breakdown>
        </mdb-tab>

        <mdb-tab
          heading="{{
            labels.user.singular | titlecase
          }} Inventory Score Breakdown"
          *ngIf="metricsOptions.user_inventory_table"
          (select)="tabSelected('user_inventory_table')"
        >
          <app-user-inventory-breakdown-table
            *ngIf="loading.user_inventory_table"
            [teamID]="teamID"
            [session_id]="session_id"
            [labels]="labels"
          ></app-user-inventory-breakdown-table>
        </mdb-tab>
        <mdb-tab
          heading="Data Entry Breakdown"
          *ngIf="metricsOptions.data_entry"
          (select)="tabSelected('data_entry')"
        >
          <app-data-entry-breakdown
            *ngIf="loading.data_entry"
            [teamID]="teamID"
            [session_id]="session_id"
            [labels]="labels"
            [users]="users"
            [clientSettings]="clientSettings"
          ></app-data-entry-breakdown>
        </mdb-tab>
      </mdb-tabset>
    </div>
  </mdb-card>
</div>
