import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, OnInit, ViewChild, ViewEncapsulation, Renderer2, ElementRef, ViewChildren, QueryList, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';

import {
  faTrashAlt,
  faGripLines,
  faCircleCheck,
  faPlus,
  faCopy,
  faPaperPlane,
  faFilePen
} from "@fortawesome/free-solid-svg-icons";
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';
import { ActiveToast } from 'ngx-toastr';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { EditableColumn, Table } from 'primeng/table';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ConfirmPopup } from 'primeng/confirmpopup';

interface ActionParameterOption {
  id: number;
  name: string;
  label: string;
  value: string;
  action_parameter_id: number;
  is_default: boolean;
}

interface ActionValue {
  id: number;
  value: string;
  order: number;
  action_parameter_option_id: number;
}

interface ActionParameter {
  id: number;
  label: string;
  name: string;
  type: string;
  constrained: boolean;
  max_value: number;
  required: boolean;
  updating: boolean;
  success: boolean;
  timeOut: any;
  action_parameter_options: ActionParameterOption[];
  action_value: ActionValue;
}

interface TableAction {
  loading: boolean;
  id: number;
  label: string;
  name: string;
  action_function_id: number;
  order: number;
  background: string;
  action_parameters: ActionParameter[];
}

@Component({
  selector: 'app-authoring-skill-actions',
  templateUrl: './authoring-skill-actions.component.html',
  styleUrls: ['./authoring-skill-actions.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthoringSkillActionsComponent implements OnInit {
  @ViewChild('lastRow') lastRow: ElementRef;
  @ViewChild('authoringTable') authoringTable: Table;
  @ViewChild('actionSelectOverlay') actionSelectOverlay: OverlayPanel;
  @ViewChild('actionCopyOverlay') actionCopyOverlay: OverlayPanel;
  @ViewChildren(EditableColumn) private editableColumns: QueryList<EditableColumn>;

  //persistent
  public token: string;
  public skillId: string;
  public skill: any;
  public charLimit = 240;
  public clientCode: string;

  //data
  public actions: any;
  public actionsLength: number;
  public actionFunctions: any;
  public actionFunctionsOverlay: any;
  public newAction: any;
  public copyLocation: number;
  public copyIndex: number;

  //subscriptions
  private shareDataSubscription: Subscription;

  //user feedback
  public errorRetrievingMsg: string = "";
  public tableLoading: boolean = true;

  //debouncing
  private debounceSubject = new Subject();

  public tableAction: TableAction = {
    loading: false,
    id: 0,
    label: "",
    name: "",
    action_function_id: 0,
    order: 0,
    background: "#ffffff",
    action_parameters: [
      {
        id: 0,
        label: "",
        name: "",
        type: "",
        constrained: false,
        max_value: 0,
        required: false,
        updating: false,
        success: false,
        timeOut: null,
        action_parameter_options: [
          {
            id: 0,
            name: "",
            label: "",
            value: "",
            action_parameter_id: 0,
            is_default: false,
          }
        ],
        action_value: {
          id: 0,
          value: "",
          order: 0,
          action_parameter_option_id: 0,
        }
      }
    ]
  };

  public contextOptions = [
    { value: "NO_CONTEXT", label: "NO_CONTEXT" },
    { value: null, label: "Empty" }
  ]

  public wordListOptions: { value: string, label: string }[];

  public tableActions: TableAction[] = [this.tableAction];

  //table support
  public parameterColumns: { parameter: string; value: string }[] = [];
  public numOfColumns: number = 1;

  //fa icons
  public faTrashAlt = faTrashAlt;
  public faGripLines = faGripLines;
  public faCircleCheck = faCircleCheck;
  public faPlus = faPlus;
  public faCopy = faCopy;
  public faPaperPlane = faPaperPlane;
  public faFilePen = faFilePen;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private confirmationService: ConfirmationService,
    private _sharedDataService: SharedDataService,
    private scrollToService: ScrollToService,
    private _notificationService: NotificationsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.retrieveToken();
    this.retrieveClientCode();

    //get skill ID from route value skill_id
    this.route.params.subscribe(params => {
      this.skillId = params['skill_id'];
      this.retrieveData();
    });

    //setup debouncing
    this.debounceSubject
      .pipe(debounceTime(350))
      .subscribe((params: any) => this.manageActionValue(params));

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "push_to_sheets"
          ) {
            this.initiatePushToSheets();
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.shareDataSubscription !== undefined)
      this.shareDataSubscription.unsubscribe();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private async retrieveData() {

    let retrieveskill = await this.retrieveSkill().catch((error) => {
      this._notificationsService.errorNotification(
        "There was an issue retrieving the skill. Please try again or contact support.",
      );
      this.errorRetrievingMsg = "There was an issue retrieving the skill. Please try again or contact support.";
    });

    this.skill = retrieveskill.skill;

    if (this.skill.phoneme_lists.length) {
      this.wordListOptions = this.skill.phoneme_lists.map((list) => {
        return {
          value: list.phoneme_list.name,
          label: list.phoneme_list.label
        }
      });
    }

    let retrieveActions = await this.retrieveActions().catch((error) => {
      this._notificationsService.errorNotification(
        "There was an issue retrieving the actions for this skill. Please try again or contact support.",
      );
      this.errorRetrievingMsg = "There was an issue retrieving the actions for this skill. Please try again or contact support.";
    });

    this.actions = retrieveActions.actions;

    if (this.actions.length) await this.configureTableActions();

    let retrieveActionFunctions = await this.retrieveActionFunctions().catch((error) => {
      this._notificationsService.errorNotification(
        "There was an issue retrieving the action functions. Please try again or contact support.",
      );
      this.errorRetrievingMsg = "There was an issue retrieving the action functions. Please try again or contact support.";
    });

    this.actionFunctions = retrieveActionFunctions.action_functions;
    this.actionFunctionsOverlay = retrieveActionFunctions.action_functions;

    this.configureColumns();
  }

  private retrieveSkill() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/skill/${this.skillId}`;

    let skillRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "get",
      {},
      getOptions
    )

    return skillRetrieve.toPromise();
  }

  private retrieveActions() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/actions/${this.skillId}`;

    let actionsRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "get",
      {},
      getOptions
    )

    return actionsRetrieve.toPromise();
  }

  private retrieveActionFunctions() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/action-functions`;

    let actionFunctionsRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "get",
      {},
      getOptions
    )

    return actionFunctionsRetrieve.toPromise();
  }

  private async configureTableActions() {
    let promises = this.actions.map(async (action, index) => {

      let getActionParameters = await this.configureActionFunctionParameters(action.action_function_parameters);

      let actionParameters = getActionParameters.response;

      return {
        loading: false,
        id: action.id,
        label: action.action_function.label,
        name: action.action_function.name,
        action_function_id: action.action_function_id,
        order: action.order,
        background: `#${action.action_function.function_color.hex_code}`,
        action_parameters: actionParameters
      };
    });

    let getTableActions = await Promise.all(promises);

    console.log("getTableActions", getTableActions);

    this.tableActions = getTableActions;
    this.actionsLength = this.tableActions.length;
  }

  private configureColumns() {

    //setup six parameter columns
    while (this.parameterColumns.length < this.numOfColumns) {
      this.parameterColumns.push({
        parameter: "",
        value: "",
      });
    }

    console.log("this.tableActions", this.tableActions);
    this.tableLoading = false;
    this.cdr.markForCheck();
    let pageTitleChange = {
      type: "pageTitleChange",
      data: {
        pageTitle: `Actions for ${this.skill.label}`,
        loadingAni: false,
      },
    };

    this._sharedDataService.sendSharedData(pageTitleChange);

    //also let menu know that we're done loading
    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);
  }

  public addTableRow(event) {

    if (this.tableActions[0].id !== 0) this.tableActions.push(JSON.parse(JSON.stringify(this.tableAction)));

    this.actionsLength = this.tableActions.length;

    this.tableActions[this.tableActions.length - 1].action_function_id = event;

    this.manageAction("add", this.tableActions.length - 1, event);

    this.cdr.markForCheck();

    setTimeout(() => {
      this.scrollToService.scrollTo({
        target: 'authoringTableLastRow',
        offset: 50,
        duration: 500,
        easing: 'easeInOutQuart'
      });

      this.newAction = null;
    }, 50);

  }

  public addRowBelow(event, index) {

    console.log("event in addRowBelow", event);

    this.actionFunctionsOverlay = this.actionFunctionsOverlay.map((actionFunction, i) => {

      actionFunction.currentIndex = index;

      return actionFunction;
    });

    this.actionSelectOverlay.show(event);

    this.cdr.markForCheck();

  }

  public async onActionOverlaySelect(event, selectedAction) {
    this.actionSelectOverlay.hide();

    let notification = this._notificationsService.savingNotification(
      "Adding action..."
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    console.log("selectedAction in onActionOverlaySelect", selectedAction);

    let currentIndex = selectedAction.currentIndex;

    console.log("currentIndex in onActionOverlaySelect", currentIndex);

    //insert new action into tableActions right after the current index
    this.newAction = JSON.parse(JSON.stringify(this.tableAction));
    this.newAction.action_function_id = selectedAction.id;

    console.log("this.newAction in onActionOverlaySelect", this.newAction);

    let updatedAction = await this.manageAction("add", currentIndex + 1, selectedAction.id);

    console.log("updatedAction in onActionOverlaySelect", updatedAction);

    //update order of remaining actions
    let tableActions = this.tableActions.map((action, i) => {

      if (action.id === updatedAction.id) return action;

      if (action.order > currentIndex + 1) {
        action.order++;
      }

      return action;
    });

    this.tableActions = tableActions;
    this.actionsLength = this.tableActions.length;
    this.newAction.order = currentIndex + 2;
    this.tableActions.splice(currentIndex + 1, 0, this.newAction);

    this.newAction = null;

    this._notificationsService.clearNotification(notification);

    this._notificationsService.successNotification(
      "Action successfully added."
    );

    this.cdr.markForCheck();

  }

  public copyRowConfirm(event, index) {

    this.copyLocation = index + 2;
    this.copyIndex = index;

    this.actionCopyOverlay.show(event);

    this.cdr.markForCheck();
  }

  public async copyRow(event) {

    this.actionCopyOverlay.hide();

    let notification = this._notificationsService.savingNotification(
      "Copying action..."
    );

    let targetLocation = this.copyLocation;
    let currentAction = this.tableActions[this.copyIndex];
    currentAction.order = targetLocation;

    let cloneAction = await this.cloneAction(currentAction, targetLocation).catch((error) => {
      this._notificationsService.errorNotification(
        "There was an issue copying the action. Please try again or contact support.",
      );
    });

    let getActionParameters = await this.configureActionFunctionParameters(cloneAction.action.action_function_parameters);

    let actionParameters = getActionParameters.response;

    let clonedAction = {
      loading: false,
      id: cloneAction.action.id,
      label: cloneAction.action.action_function.label,
      name: cloneAction.action.action_function.name,
      action_function_id: cloneAction.action.action_function_id,
      order: cloneAction.action.order,
      background: `#${cloneAction.action.action_function.function_color.hex_code}`,
      action_parameters: actionParameters
    }

    this.tableActions.splice(targetLocation - 1, 0, clonedAction);

    //update order of remaining actions
    let tableActions = this.tableActions.map((action, i) => {

      if (action.id === clonedAction.id) return action;

      if (action.order >= targetLocation) {
        action.order++;
      }

      return action;
    });

    this.tableActions = tableActions;
    this.actionsLength = this.tableActions.length;

    this._notificationsService.clearNotification(notification);

    this._notificationsService.successNotification(
      "Action successfully copied."
    );

    this.cdr.markForCheck();

  }

  private cloneAction(newAction, order) {

    console.log("newAction in cloneAction", newAction);
    console.log("order in cloneAction", order);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let body = {
      order: order,
    };

    let targetURL = `/authoring/action/${newAction.id}/copy`;

    let actionCopy = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "post",
      body,
      postOptions
    );

    return actionCopy.toPromise();
  }

  public cancelCopy(event) {
    this.actionCopyOverlay.hide();
  }


  updateTDzindex(event: any) {
    const parentTd = event.element.parentElement.closest('td');
    if (parentTd !== null) this.renderer.setStyle(parentTd, 'z-index', '2');
    this.cdr.markForCheck();
  }

  resetTDzindex(event: any) {
    const parentTd = event.element.parentElement.closest('td');
    if (parentTd !== null) this.renderer.removeStyle(parentTd, 'z-index');
    this.cdr.markForCheck();
  }

  public getParameterOptionLabel(action_parameter_option_id, action_parameter_options, key = "id") {

    let targetOption = action_parameter_options.find(option => parseInt(option[key]) === parseInt(action_parameter_option_id));

    return targetOption !== undefined ? targetOption.label : "";
  }

  public async manageAction(action, index, actionID) {
    if (action !== "delete" && this.newAction === null) return;

    console.log("actionID in manageAction", actionID);

    let notification: ActiveToast<any>;

    try {

      if (action === "delete") {
        notification = this._notificationsService.savingNotification("Deleting action...");
      }

      let typeOfAction = action === "update" ? actionID === 0 ? "add" : "update" : action;

      let updateAction = await this.updateAction(typeOfAction, index, actionID).catch((error) => {
        this._notificationsService.errorNotification(
          "There was an issue adding this action. Please try again or contact support.",
        );
      });

      console.log("updateAction in manageAction", updateAction);

      if (action !== "delete") this.populateParameters(index, updateAction.action);

      if (action === "delete") {
        this.tableActions = this.tableActions.filter((action, i) => {
          return action.id !== actionID;
        });

        //if we are clearing the last row, re-set up the initial tableAction
        if (!this.tableActions.length) this.tableActions = [this.tableAction];

        this.actionsLength = this.tableActions.length;
        this._notificationsService.clearNotification(notification);
        this._notificationsService.successNotification("Action deleted successfully.");

        //update order of remaining actions
        this.tableActions = this.tableActions.map((action, i) => {

          if (action.order > index) {
            action.order = i + 1;
          }

          return action;
        });
      }

      this.cdr.markForCheck();

      return updateAction;
    } catch (error) {
      this._notificationsService.errorNotification(
        "There was an issue adding this action. Please try again or contact support.",
      );
      throw error;
    }

  }

  public async populateParameters(index, action) {
    console.log("this.tableActions[index -1] in populateParameters", this.tableActions[index - 1]);
    this.tableActions[index - 1].loading = true;

    let getActionParameters = await this.configureActionFunctionParameters(action.action_function_parameters);

    let actionParameters = getActionParameters.response;
    console.log("actionParameters in populateParameters", actionParameters);

    this.tableActions = this.tableActions.map((thisAction, i) => {
      if (thisAction.id === 0) {

        return {
          loading: false,
          id: action.id,
          label: action.action_function.label,
          name: action.action_function.name,
          action_function_id: action.action_function_id,
          order: action.order,
          background: `#${action.action_function.function_color.hex_code}`,
          action_parameters: actionParameters
        };

      }
      return thisAction;
    });

    this.tableActions[index - 1].loading = false;
    this.cdr.markForCheck();
  }

  public updateAction(action, index, actionID) {

    let body = {
      action_function_id: actionID,
      order: index + 1,
      skill_id: this.skillId,
    };

    let targetURL = action === "add" ? "/authoring/action" : `/authoring/action/${actionID}`;

    if (action === "delete") targetURL = `/authoring/action/${actionID}/delete`;

    let method = action === "add" ? "post" : "put";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let actionManage = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      method,
      body,
      postOptions
    );

    return actionManage.toPromise();

  }

  public initiateActionValueUpdate(actionParam, index, actionID) {
    this.debounceSubject.next({
      actionParam: actionParam,
      index: index,
      actionID: actionID
    });
  }

  public async manageActionValue(params) {

    //actionParam, index, actionI
    let actionParam = params.actionParam;
    let index = params.index;
    let actionID = params.actionID;

    let closeImmediately = ["select", "boolean", "repeat_list"]

    this.editableColumns.forEach((column, i) => {

      if (closeImmediately.indexOf(actionParam.type) === -1) return;

      if (column.el.nativeElement.className.indexOf("p-cell-editing") !== -1) {
        column.closeEditingCell(true, true);
      }
    });

    actionParam.updating = true;
    actionParam.success = false;
    actionParam.timeOut !== null ? clearTimeout(actionParam.timeOut) : null;

    console.log("actionParam in manageActionValue", index, actionID, actionParam);
    let updateActionValue = await this.updateActionValue(actionParam, index, actionID).catch((error) => {
      this._notificationsService.errorNotification(
        "There was an issue updating the action value. Please try again or contact support.",
      );
    });

    this.tableActions = this.tableActions.map((action, i) => {

      if (action.id === actionID) {

        let parameters = action.action_parameters;

        if (parameters[index].type.indexOf("chip") !== -1) {

          let stored = 0;

          actionParam.chip_groups.forEach((chipGroup, i) => {

            if (chipGroup.chip.id === null) chipGroup.chip.id = 0;
            if (chipGroup.modifier.id === null) chipGroup.modifier.id = 0;
            if (chipGroup.text1.id === null) chipGroup.text1.id = 0;
            if (chipGroup.text2.id === null) chipGroup.text2.id = 0;

            if (chipGroup.chip.id !== 0) {
              stored++;
              chipGroup.chip.label = actionParam.chip_options.chips.find(chip => chip.id === chipGroup.chip.id).label;
            }

            if (chipGroup.modifier.id !== 0) {
              stored++;
              chipGroup.modifier.label = actionParam.chip_options.modifiers.find(modifier => modifier.id === chipGroup.modifier.id).label;
            }

            if (chipGroup.text1.id !== 0) {
              stored++;
              chipGroup.text1.label = actionParam.chip_options.texts.find(text => text.id === chipGroup.text1.id).label;
            }

            if (chipGroup.text2.id !== 0) {
              stored++;
              chipGroup.text2.label = actionParam.chip_options.texts.find(text => text.id === chipGroup.text2.id).label;
            }

          });

          parameters[index]["chip_groups"] = actionParam.chip_groups;
          parameters[index]["stored_chips_length"] = stored;
          parameters[index]["action_value"] = {
            id: updateActionValue.action_value.id,
            value: updateActionValue.action_value.value,
            order: updateActionValue.action_value.order,
            action_parameter_option_id: updateActionValue.action_value.action_parameter_option_id,
          }

        } else if (parameters[index].type === "repeat_list") {

          console.log("action.action_parameters in manageActionValue for repeatListValue", action.action_parameters);

          //get value from repeat_list parameter
          let repeatListValue = action.action_parameters.find(param => param.name === "repeat_list");

          let extras = {
            phonemeListValues: []
          };

          if (repeatListValue !== undefined && repeatListValue.action_value !== null && repeatListValue.action_value.value !== "") {
            //get phoneme list from skill
            let phonemeList = this.skill.phoneme_lists.find(list => list.phoneme_list.name === repeatListValue.action_value.value);

            console.log("phonemeList in manageActionValue for repeatListValue", phonemeList);

            if (phonemeList !== undefined) {
              let phonemeListValues = phonemeList.phoneme_list.phoneme_words.map(phoneme => {
                return {
                  label: phoneme.word,
                  value: phoneme.id
                }
              });

              extras["phonemeListValues"] = phonemeListValues;

              //find the intial_word parameter and update the phonemeListValues
              let initialWordParam = action.action_parameters.find(param => param.type === "initial_word");

              if (initialWordParam !== undefined) {
                initialWordParam["extras"]["phonemeListValues"] = phonemeListValues;
                initialWordParam["action_value"]["value"] = phonemeList.phoneme_list.phoneme_words[0].id;
              }
            }
          }

        } else {
          parameters[index]["action_value"]["id"] = updateActionValue.action_value.id;
        }

      }

      return action;
    });

    actionParam.updating = false;
    actionParam.success = true;
    this.cdr.markForCheck();

    actionParam.timeOut = setTimeout(() => {
      actionParam.success = false;
    }, 3000);
  }

  public updateActionValue(actionParam, index, actionID) {

    let actionParameterOptionID = actionParam.action_value.action_parameter_option_id;
    let actionParameterValue = actionParam.type.indexOf("chip") !== -1 ? null : actionParam.action_value.value;

    if (actionParameterValue !== null && actionParameterValue.value !== undefined) {
      actionParameterValue = actionParameterValue.value;
      let thisTableaction = this.tableActions.find(action => action.id === actionID);
      thisTableaction.action_parameters[index].action_value.value = actionParameterValue;
    }

    //for multiselect
    if (actionParam.type === "multiselect") {
      actionParameterValue = `[${actionParameterValue.toString()}]`;
    } else if (actionParam.type === "number") {

      if (actionParameterValue > actionParam.max_value) {
        actionParameterValue = actionParam.max_value;

        //also update in tableActions
        this.tableActions = this.tableActions.map((action, i) => {

          if (action.id === actionID) {
            let parameters = action.action_parameters;
            parameters[index]["action_value"] = {
              id: actionParam.action_value.id,
              value: actionParam.max_value,
              order: actionParam.action_value.order,
              action_parameter_option_id: actionParam.action_value.action_parameter_option_id,
            }
          }

          return action;
        });

      }

    }

    console.log("actionParameterValue in updateActionValue", actionParameterValue);

    let body = {
      action_parameter_id: actionParam.id,
      action_id: actionID,
      value: actionParameterValue === "" ? null : actionParameterValue,
      action_parameter_option_id: actionParameterOptionID === 0 ? null : actionParameterOptionID,
      chip_groups: actionParam.chip_groups,
    };

    let targetURL = actionParam.action_value.id === 0 ? "/authoring/action-value" : `/authoring/action-value/${actionParam.action_value.id}`;
    let method = actionParam.action_value.id === 0 ? "post" : "put";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let actionValueUpdate = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      method,
      body,
      postOptions
    );

    return actionValueUpdate.toPromise();
  }

  private retrieveActionFunctionParameters(actionFunctionId) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/action-function-parameters/${actionFunctionId}`;

    let actionFunctionParametersRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "get",
      {},
      getOptions
    )

    return actionFunctionParametersRetrieve.toPromise();
  }

  private async configureActionFunctionParameters(actionFunctionParameters): Promise<{ status: string; message: string; response: any }> {

    let actionParametersOut = [];

    let promises = await actionFunctionParameters.map(async (parameter, index) => {

      let actionParameter = parameter.action_parameter;

      let action_value = {
        id: 0,
        value: "",
        order: 0,
        action_parameter_option_id: 0,
      };

      if (parameter.action_value) {
        action_value = {
          id: parameter.action_value.id,
          value: this.processActionValue(parameter.action_value.value, actionParameter.type, actionParameter),
          order: parameter.action_value.order,
          action_parameter_option_id: parameter.action_value.action_parameter_option_id,
        };
      }

      let extras = {}

      if (actionParameter.type === "initial_word") {

        console.log("actionFunctionParameters for repeatListValue in configureActionFunctionParameters", actionFunctionParameters);

        //get value from repeat_list parameter
        let repeatListValue = actionFunctionParameters.find(param => param.action_parameter.name === "repeat_list");

        extras["phonemeListValues"] = [];

        if (repeatListValue !== undefined && repeatListValue.action_value !== null && repeatListValue.action_value.value !== "") {
          //get phoneme list from skill
          let phonemeList = this.skill.phoneme_lists.find(list => list.phoneme_list.name === repeatListValue.action_value.value);

          if (phonemeList !== undefined) {
            let phonemeListValues = phonemeList.phoneme_list.phoneme_words.map(phoneme => {
              return {
                label: phoneme.word,
                value: phoneme.id
              }
            });

            extras["phonemeListValues"] = phonemeListValues;
          }
        }

      }

      let chip_groups = [];
      let chip_options = {
        "chips": [{
          "label": "",
          "id": 0
        }],
        "modifiers": [{
          "label": "",
          "id": 0
        }],
        "texts": [{
          "label": "",
          "id": 0
        }],
      }

      let stored_chips = [];

      if (actionParameter.type.indexOf("chip") !== -1) {

        let numChipGroups = 12;

        switch (actionParameter.type) {
          case "chip":
            numChipGroups = 1;
            break;
          case "chips_build_order":
            numChipGroups = 6;
            break;
        }

        let chipGroup = {
          "chip": {
            "label": "",
            "id": 0
          },
          "modifier": {
            "label": "",
            "id": 0
          },
          "text1": {
            "label": "",
            "id": 0
          },
          "text2": {
            "label": "",
            "id": 0
          }
        }

        stored_chips = parameter.action_value !== undefined && parameter.action_value && parameter.action_value.chips !== undefined ? parameter.action_value.chips : [];

        //iterative over numChipGroups and add a chipGroup to the chip_groups array
        for (let i = 0; i < numChipGroups; i++) {
          let chipGroupCopy = JSON.parse(JSON.stringify(chipGroup));

          let stored_chip = stored_chips.find(chip => chip.order === i + 1);

          if (stored_chip) {

            if (stored_chip.chip !== null) {
              chipGroupCopy.chip = {
                label: stored_chip.chip.label,
                id: stored_chip.chip.id
              }
            }

            if (stored_chip.modifier !== null) {
              chipGroupCopy.modifier = {
                label: stored_chip.modifier.label,
                id: stored_chip.modifier.id
              }
            }

            if (stored_chip.text1 !== null) {
              chipGroupCopy.text1 = {
                label: stored_chip.text1.label,
                id: stored_chip.text1.id
              }
            }

            if (stored_chip.text2 !== null) {
              chipGroupCopy.text2 = {
                label: stored_chip.text2.label,
                id: stored_chip.text2.id
              }
            }
          }

          chip_groups.push(chipGroupCopy);
        }

        chip_options.chips = actionParameter.chips.map(option => {
          return {
            label: option.label,
            id: option.id
          }
        });

        chip_options.modifiers = actionParameter.modifiers.map(option => {
          return {
            label: option.label,
            id: option.id
          }
        });

        chip_options.texts = actionParameter.texts.map(option => {
          return {
            label: option.label,
            id: option.id
          }
        });

      }

      actionParametersOut.push({
        order: parameter.order,
        id: actionParameter.id,
        label: actionParameter.label,
        name: actionParameter.name,
        type: actionParameter.type,
        constrained: actionParameter.constrained,
        max_value: actionParameter.max_value,
        required: parameter.required,
        action_parameter_options: actionParameter.action_parameter_options,
        action_value: action_value,
        chip_groups: chip_groups,
        chip_options: chip_options,
        stored_chips_length: stored_chips.length,
        extras: extras
      });

      //sort actionParametersOut by order
      actionParametersOut.sort((a, b) => {
        return a.order - b.order;
      });
    });

    //sort actionParametersOut by order
    actionParametersOut.sort((a, b) => {
      return a.order - b.order;
    });

    //see if we need to up the column count
    if (actionParametersOut.length > this.numOfColumns) {
      this.numOfColumns = actionParametersOut.length;
      this.configureColumns();
    }

    let outbound = {
      response: actionParametersOut,
      status: "success",
      message: "Action parameters configured successfully."
    };

    this.cdr.markForCheck();

    return Promise.resolve(outbound);
  }

  public outputChips(chipGroups) {

    let output = [];

    chipGroups.forEach(chip => {

      let chipOutput1 = []
      let chipOutput2 = []

      if (chip.chip.id !== 0) {
        chipOutput1.push(chip.chip.label);
      }

      if (chip.modifier.id !== 0) {
        chipOutput1.push(chip.modifier.label);
      }

      if (chip.text1.id !== 0) {
        chipOutput2.push(chip.text1.label);
      }

      if (chip.text2.id !== 0) {
        chipOutput2.push(chip.text2.label);
      }

      let finalOutput = []

      if (chipOutput1.length) finalOutput.push(chipOutput1.join("/"));
      if (chipOutput2.length) finalOutput.push(chipOutput2.join("/"));

      if (finalOutput.length) output.push(finalOutput.join(":"));

    });

    if (!output.length) return ["Select Chips"]

    return output;

  }

  public async updateRowOrder(event) {
    let notification = this._notificationsService.savingNotification(
      "Updating order action..."
    );

    console.log("event in updateRowOrder", event);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let currentAction = this.tableActions[event.dropIndex];

    let actionUpdate = await this.updateActionOrder(currentAction.id, event.dropIndex + 1).catch((error) => {
      this._notificationsService.errorNotification(
        "There was an issue updating the action order. Please try again or contact support.",
      );
    });

    this.tableActions = this.tableActions.map((action, i) => {

      if (action.id === currentAction.id) {
        action.order = event.dropIndex + 1;
      } else {

        //if we are moving the action down
        if (event.dropIndex > event.dragIndex) {
          if (action.order > event.dragIndex + 1 && action.order <= event.dropIndex + 1) {
            action.order--;
          }
        }

        //if we are moving the action up
        if (event.dropIndex < event.dragIndex) {
          if (action.order < event.dragIndex + 1 && action.order >= event.dropIndex + 1) {
            action.order++;
          }
        }
      }

      return action;
    });

    this.cdr.markForCheck();

    this._notificationsService.clearNotification(notification);

    this._notificationsService.successNotification(
      "Action order updated successfully."
    );

  }

  private updateActionOrder(actionID, order) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let body = {
      order: order,
    };

    let targetURL = `/authoring/action/${actionID}`;

    let actionUpdate = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "put",
      body,
      postOptions
    );

    return actionUpdate.toPromise();
  }

  private processActionValue(value, type, parameter) {

    switch (type) {
      case "boolean":
        return value === "true" ? true : false;
      case "number":
        return parseInt(value);
      case "multiselect":

        try {
          return JSON.parse(value);
        } catch (error) {

          //find the teacher value
          let teacherValue = parameter.action_parameter_options.find(option => option.value === value);

          if (teacherValue) return [teacherValue.id];

          return [];

        }
      default:
        return value;
    }

  }

  public confirmDelete(action, index, thisAction) {
    this.confirmationService.confirm({
      message: `Do you want to delete the action ${thisAction.label}?`,
      icon: 'pi pi-exclamation-triangle',
      key: 'cd',
      accept: () => {
        this.manageAction(action, index, thisAction.id);
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            console.log("rejected");
            break;
          case ConfirmEventType.CANCEL:
            console.log("confirmed");
            break;
        }
      }
    });
  }

  public onEditCellClick(event, action, index) {
    console.log("event in onEditCellClick", event);
    console.log("action in onEditCellClick", action);
    console.log("index in onEditCellClick", index);
  }

  public initiatePushToSheets() {
    this.confirmationService.confirm({
      message: `Do you want to push ${this.skill.label} to Google Sheets?`,
      icon: 'pi pi-exclamation-triangle',
      key: "sheetsConfirm",
      accept: () => {
        this.pushSkillToSheets(this.skill);
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            console.log("rejected");
            break;
          case ConfirmEventType.CANCEL:
            console.log("confirmed");
            break;
        }
      }
    });
  }

  public pushSkillToSheets(thisSkill) {
    let notification = this._notificationService.savingNotification("Pushing skill to sheets...");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/actions/${thisSkill.id}/to-sheets`;

    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "put",
      {},
      postOptions
    );

    eventRetrieve.subscribe((response) => {
      this._notificationService.clearNotification(notification);
      this._notificationService.successNotification("Skill pushed to sheets successfully");
    }, (error) => {
      this._notificationService.errorNotification("There was an error pushing skill to sheets");
    });

  }
}
