import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { MetricsServicesService } from "../../../services/metrics-services.service";
import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-user-inventory-breakdown-table",
  templateUrl: "./user-inventory-breakdown-table.component.html",
  styleUrls: ["./user-inventory-breakdown-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UserInventoryBreakdownTableComponent implements OnInit {
  @Input("teamID") teamID: number;
  @Input("session_id") session_id: number;
  @Input("labels") labels: any;

  public token: string;
  public metricsLoading: boolean = true;
  public tableStatus: string = "Loading";

  public tableAggregates = [];

  headElements = [
    "Username",
    "Base Score",
    "Item Status Score",
    "Product Status Score",
    "Reported Amounts Score",
    "Custom Answers Count",
  ];
  public sort_elements = [
    "username",
    "overall",
    "status",
    "product",
    "amounts",
    "custom",
  ];

  public headingHit: any = null;

  // temp tooltips
  public tooltips = [];

  constructor(
    private _clientManagementService: ClientManagementService,
    private _metricsServicesService: MetricsServicesService,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.getSessionInventoryResults();

    this.tooltips = [
      "This is the name of the" + this.labels.user.singular + "saving the data seen on this table",
      "This is the average score of all the other score criteria shown on this row of this graph",
      "This is the percentage of correct answers in the " + this.labels.user.singular + " data saved pertaining to the box status",
      "This is the percentage of correct answers in the " + this.labels.user.singular + " data saved pertaining to the product status",
      "This is the percentage of correct answers in the " + this.labels.user.singular + " data saved pertaining to the number of products in the box",
      "This is the amount of times the " + this.labels.user.singular + " added a custom answer for 'other' status in a session",
    ];
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public getSessionInventoryResults() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.restfulAPIQuery(
      "/inventory/metrics/" + this.session_id,
      "get",
      {},
      options
    );

    retrieveUsers.subscribe(
      (response) => {
        if (response.aggregates.length == 0) {
          this.tableStatus = "Empty";
        } else {
          this.ProcessInventoryMetrics(response);
        }
      },
      (error) => {
        this.tableStatus = "Error";
        ;
      }
    );
  }

  private ProcessInventoryMetrics(response) {
    ;
    let temp_data = [];
    response.aggregates.forEach((element) => {
      ;
      let overall = element.aggregates.overall;
      let amounts = element.aggregates.amounts;
      let status = element.aggregates.status;
      let products = element.aggregates.products;
      let custom = element.aggregates.custom;
      let row = {
        username: element.user,
        overall:
          (overall.correct / (overall.correct + overall.incorrect)) * 100,
        status: (status.correct / (status.correct + status.incorrect)) * 100,
        amounts:
          (amounts.correct / (amounts.correct + amounts.incorrect)) * 100,
        product:
          (products.correct / (products.correct + products.incorrect)) * 100,
        custom: custom,
      };
      temp_data.push(row);
    });

    this.tableAggregates = temp_data;
    this.tableStatus = "Loaded";
  }

  public mouseEnter(i) {
    this.headingHit = i;
  }
  public mouseLeave() {
    this.headingHit = null;
  }
}
