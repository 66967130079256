import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { CoolLocalStorage } from "@angular-cool/storage";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-photo-region-to-class",
  templateUrl: "./photo-region-to-class.component.html",
  styleUrls: ["./photo-region-to-class.component.scss"],
  providers: [TitleCasePipe]
})
export class PhotoRegionToClassComponent implements OnInit {
  //persistent
  private token: string;
  public labels: any;

  //incoming
  public teamID: number;
  public experience: any;
  public targetClass: any;
  public action: string;

  //data
  public regions: Array<any> = [];

  //form values
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Add Photon Region",
    reset: "Add Another Photon Region",
    retry: "Retry",
  };

  public continueType: string;

  public preSelected: {
    class_name: string;
    id: any;
    startDate: any;
    endDate: any;
    schedule_id: any;
  } = {
    class_name: null,
    id: null,
    startDate: null,
    endDate: null,
    schedule_id: null,
  };

  public addRegionForm: UntypedFormGroup;
  public formLoading: boolean = false;
  public selectRegions: any[];

  //feedback
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Schedule a Region for the experience:",
  };
  public formState: string = "active";

  //outgoing
  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public addRegionFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private TitleCase: TitleCasePipe
  ) {}

  ngOnInit() {
    ;
    if (this.action === undefined) this.action = "add";
    this.regions = this.experience.scheduled_photon_regions;
    this.retrieveToken();
    this.retrieveLabels();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private processRegions(incomingTeam) {
    let regionsOut = [];
    let existingRegions = this.experience.scheduled_photon_regions;

    ;

    if (incomingTeam.meta === null || incomingTeam.meta.regions === undefined)
      return [];

    let regions = incomingTeam.meta.regions;

    ;

    regions.forEach((element) => {
      let check = false;
      existingRegions.forEach((existing) => {
        if (existing.id === element.id) {
          check = true;
        }
      });

      if (check) return false;

      let thisRegionSelect = {
        value: element.id,
        label: element.name,
      };

      regionsOut.push(thisRegionSelect);
    });

    this.selectRegions = regionsOut;
    this.makeFormValidatingGroup();
  }

  private makeFormValidatingGroup() {
    this.addRegionForm = new UntypedFormGroup({
      scheduledRegion: new UntypedFormControl(null, Validators.required),
    });
    this.formLoading = true;
  }

  get scheduledRegion() {
    return this.addRegionForm.get("scheduledRegion");
  }

  public remove(target_id) {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.formState = `<span class='loading-msg'>Removing ${this.TitleCase.transform(this.labels.networkRegion.singular)}</span>`;

    let removeList = [
      {
        scheduled_event_id: this.experience.schedule_id,
        invitee_type: "photon_region",
        invitee_id: target_id,
      },
    ];

    let body = {
      entity: "schedule",
      entity_id: this.experience.schedule_id,
      toRemove: removeList,
    };
    let action = "remove";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    ;

    let removeRegion = this._xrPlatformRestService.scheduledExperiences(
      action,
      body,
      options
    );

    removeRegion.subscribe(
      (response) => {
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.networkRegion.singular)} successfully removed`;
        this.formState = "success";
        let outgoingData = {
          action: "remove",
          scheduled_event: response.scheduled_event,
          region_id: target_id,
          experience: this.experience
        };
        this.outgoing.next(outgoingData);
      },
      (err) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public addRegion() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = `<span class='loading-msg'>Adding ${this.labels.networkRegion.singular}</span>`;
    this.formState = "processing";

    let incomingValues = this.addRegionForm.value;
    let body = {};
    let action = "schedule-invitee";
    let formValues = [];

    formValues = [
      {
        scheduled_event_id: this.experience.schedule_id,
        invitee_type: "photon_region",
        invitee_id: incomingValues.scheduledRegion,
      },
    ];

    ;

    body = JSON.stringify(formValues);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
      schedule_id: this.experience.schedule_id,
    };

    let addRegion = this._xrPlatformRestService.scheduledExperiences(
      action,
      body,
      options
    );

    addRegion.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.networkRegion.singular)} successfully added to ${this.labels.event.singular}`;
        this.formState = "success";

        let outgoingData = {
          action: "add",
          scheduled_event: response.scheduled_event,
          region_id: incomingValues.scheduledRegion,
          experience: this.experience
        };
        this.outgoing.next(outgoingData);

      },
      (err) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
        ;
      }
    );
  }

  public handleIncomingAction(action) {
    ;
    switch (action) {
      case "close-modal":
        this.addRegionFrame.hide();
        break;
      case "reset-form":
        //this.resetForm();
        break;
      case "clear-status":
        //this.clearStatus();
        break;
    }
  }
}
