<div class="management-card content-card">
  <!-- tab nav -->
  <mdb-tabset
    #tabsetEl
    [buttonClass]="'classic-tabs'"
    class="admin-tab-styles"
    [contentClass]="'remove-card'"
    tabsButtonsClass="tab-nav-fluid"
    tabsContentClass="tab-content-fluid w-100"
  >
    <!-- all users -->
    <mdb-tab
      heading="{{ labels.user.plural | titlecase }}"
      (select)="tabSelected($event, 'users')"
    >
      <ng-container *ngIf="loading.users">
        <div class="row">
          <div class="col-12">
            <!-- if content hasnt load -->
            <div
              class="loading-card d-flex align-content-center justify-content-center"
            >
              <div
                class="d-flex justify-content-center"
                *ngIf="errorRetrievingMsg === ''"
              >
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div
                class="d-flex justify-content-center"
                *ngIf="errorRetrievingMsg !== ''"
              >
                <div
                  class="alert alert-danger"
                  role="alert"
                  [innerHTML]="errorRetrievingMsg"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading.users && !allUsers.length">
        <div class="row flex-row flex-nowrap">
          <div class="col-12 p-4">
            You have added no {{ labels.user.plural }} yet.<span
              class="color-theme-base-medium"
              ><button
                (click)="openInviteUsersModal()"
                class="inline"
                mdbBtn
                type="button"
                flat="true"
                size="lg"
                mdbWavesEffect
              >
                &nbsp;Add {{ labels.user.plural }} now.
              </button></span
            >
          </div>
        </div>
      </ng-container>
      <div *ngIf="!loading.users && allUsers.length">
        <div class="row flex-row flex-nowrap">
          <div class="col-12 p-0">
            <div class="table-responsive">
              <table
                class="table table-striped user-table"
                [ngClass]="{ 'with-persona': showPersona }"
                mdbTable
                #tableEl="mdbTable"
                small="true"
              >
                <colgroup
                  class="users"
                  [ngClass]="{ 'with-persona': showPersona }"
                >
                  <col
                    *ngFor="let head of headElements; let i = index"
                    [ngClass]="'col-' + sort_elements[i].head"
                  />
                </colgroup>
                <thead class="theme-base-medium-dark white-text users">
                  <tr>
                    <th
                      *ngFor="let head of headElements; let i = index"
                      scope="col"
                      [ngClass]="'head-' + sort_elements[i].head"
                    >
                      <div class="header-wrapper">
                        <span class="text-ellipsis" *ngIf="head !== 'action'">{{
                          head | titlecase
                        }}</span>
                        <ng-container *ngIf="sort_elements[i].type !== 'none'">
                          <mdb-icon
                            fas
                            *ngIf="sortBy !== sort_elements[i].head"
                            icon="sort"
                            class="sort-icon"
                            [mdbTooltip]="'Sort by ' + head"
                            placement="bottom"
                            (click)="
                              sortTable(
                                sort_elements[i].head,
                                'desc',
                                sort_elements[i].type
                              )
                            "
                          ></mdb-icon>
                          <mdb-icon
                            *ngIf="
                              sortBy == sort_elements[i].head &&
                              sortOrder == 'asc'
                            "
                            (click)="
                              sortTable(
                                sort_elements[i].head,
                                'desc',
                                sort_elements[i].type
                              )
                            "
                            fas
                            icon="caret-up"
                            class="sort-icon"
                          ></mdb-icon>
                          <mdb-icon
                            *ngIf="
                              sortBy == sort_elements[i].head &&
                              sortOrder == 'desc'
                            "
                            (click)="
                              sortTable(
                                sort_elements[i].head,
                                'asc',
                                sort_elements[i].type
                              )
                            "
                            fas
                            icon="caret-down"
                            class="sort-icon"
                          ></mdb-icon>
                        </ng-container>
                      </div>
                    </th>
                  </tr>
                  <tr class="event-filters">
                    <th
                      *ngFor="let head of headElements; let i = index"
                      scope="col"
                      [ngClass]="'head-' + sort_elements[i].head"
                    >
                      <ng-container *ngIf="filter_elements[i].filter">
                        <!--all filter types except select-->
                        <div
                          class="md-form md-filter-input mb-0 mt-0"
                          *ngIf="
                            filter_elements[i].type !== 'select' &&
                            filter_elements[i].type !== 'downloadCSV'
                          "
                        >
                          <!--start text filter-->
                          <input
                            *ngIf="filter_elements[i].type === 'text'"
                            type="text"
                            [(ngModel)]="filterText[filter_elements[i].target]"
                            class="form-control text-ellipsis"
                            [id]="'filter-' + filter_elements[i].target"
                            [placeholder]="
                              'All ' + filter_elements[i].placeholder
                            "
                            (ngModelChange)="
                              this.triggerFilterUpdate.next($event)
                            "
                            mdbInput
                          />
                          <!--end text filter-->
                          <!--start number filter-->
                          <input
                            *ngIf="filter_elements[i].type === 'number'"
                            type="number"
                            [(ngModel)]="filterText[filter_elements[i].target]"
                            class="form-control"
                            [id]="'filter-' + filter_elements[i].target"
                            [placeholder]="
                              'All ' + filter_elements[i].placeholder
                            "
                            (ngModelChange)="
                              this.triggerFilterUpdate.next($event)
                            "
                            mdbInput
                          />
                          <!--end number filter-->
                          <fa-icon
                            class="cursor-pointer"
                            *ngIf="
                              filterText[filter_elements[i].target] !== '' &&
                              filterText[filter_elements[i].target] !== null
                            "
                            [icon]="faTimes"
                            (click)="resetFilter(i)"
                          ></fa-icon>
                        </div>
                        <!--end all filter types except select-->
                        <!-- download CSV button-->
                        <div
                          class="btn-spacing download-csv"
                          *ngIf="filter_elements[i].type === 'downloadCSV'"
                        >
                          <button
                            class="theme-modal-button medium btn"
                            type="button"
                            mdbBtn
                            mdbWavesEffect
                            [disabled]="csvStatus === 'loading'"
                            (click)="downloadCSV()"
                          >
                            <fa-icon [icon]="faDownload"></fa-icon>
                            <span
                              *ngIf="
                                csvStatus !== 'loading' && csvStatus !== 'error'
                              "
                              >CSV Export</span
                            >
                            <span *ngIf="csvStatus === 'loading'">
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span class="sr-only">Loading...</span></span
                            >
                            <span *ngIf="csvStatus === 'error'">
                              <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                              Error
                            </span>
                          </button>
                        </div>
                        <!-- end download CSV button-->
                        <!--start access filter type-->
                        <div
                          class="md-form md-filter-input md-filter-select mb-0 mt-0"
                          *ngIf="filter_elements[i].type === 'select'"
                        >
                          <!--start access filter-->
                          <mdb-select-2
                            class="custom-arrow"
                            [id]="'filter-' + filter_elements[i].target"
                            [(ngModel)]="filterText[filter_elements[i].target]"
                            (ngModelChange)="
                              this.triggerFilterUpdate.next($event)
                            "
                          >
                            <mdb-select-option
                              *ngFor="let option of filter_elements[i].options"
                              [value]="option.value"
                              >{{ option.label }}</mdb-select-option
                            >
                          </mdb-select-2>
                          <!--end access filter-->
                          <fa-icon
                            class="cursor-pointer"
                            *ngIf="filterText[filter_elements[i].target] !== 0"
                            [icon]="faTimes"
                            (click)="resetFilter(i, 0)"
                          ></fa-icon>
                        </div>
                        <!--end access filter type-->
                      </ng-container>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="users.length == 0">
                    <td class="action-icons">
                      <div class="empty-query">
                        <span>No {{ labels.user.plural }} for this query</span>
                      </div>
                    </td>
                    <td colspan="7"></td>
                  </tr>
                  <tr
                    mdbTableCol
                    *ngFor="let thisUser of users; let user_index = index"
                  >
                    <td class="action-icons">
                      <div class="btn-group list-btns-sm btn-icons">
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="openUserModal('update', thisUser)"
                          mdbTooltip="Edit"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </a>
                        <a
                          *ngIf="
                            thisUser.role_label !== 'Admin' ||
                            (thisUser.role_label === 'Admin' &&
                              user.role_type_id === 1)
                          "
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="openPasswordModal(thisUser)"
                          mdbTooltip="Edit Password"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faKey"></fa-icon>
                        </a>
                        <a
                          *ngIf="
                            thisUser.role_label !== 'Admin' ||
                            (thisUser.role_label === 'Admin' &&
                              user.role_type_id === 1)
                          "
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="openUserModal('delete', thisUser)"
                          mdbTooltip="Delete"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faTrashAlt"></fa-icon>
                        </a>
                      </div>
                    </td>
                    <td>{{ thisUser.first_name }}</td>
                    <td>{{ thisUser.last_name }}</td>
                    <td>{{ thisUser.email }}</td>
                    <td>{{ thisUser.username }}</td>
                    <td>
                      {{ this.processAdminCaps(thisUser.role_type_id) }}
                    </td>
                    <td>
                      {{ this.processExpRole(thisUser.meta) }}
                    </td>
                    <td *ngIf="showPersona">
                      {{ this.processExpPersona(thisUser.meta) }}
                    </td>
                  </tr>
                </tbody>
                <tfoot class="grey lighten-5 w-100" *ngIf="users.length">
                  <tr>
                    <td colspan="12">
                      <p-paginator
                        #pagination
                        (onPageChange)="onPageChange($event)"
                        [first]="first"
                        [rows]="rows"
                        [totalRecords]="usersLength"
                        [showFirstLastIcon]="true"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="{first} - {last} of {totalRecords}"
                      ></p-paginator>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </mdb-tab>
    <!-- /all users -->
    <!-- all groups -->
    <mdb-tab
      heading="{{ labels.userGroup.plural | titlecase }}"
      (select)="tabSelected($event, 'userGroups')"
    >
      <app-user-groups
        [users]="users"
        [loading]="loading"
        [groups]="groups"
        [labels]="labels"
        [loadingError]="loadingError"
        [loadingGroups]="loadingGroups"
        [errorRetrievingMsg]="errorRetrievingMsg"
        [allUserGroups]="allUserGroups"
        [userGroupsLength]="userGroupsLength"
      ></app-user-groups>
    </mdb-tab>
    <!-- /all groups -->
  </mdb-tabset>
</div>
<!-- /tab nav -->
