import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';

@Component({
  selector: 'app-archive-inventory-modal',
  templateUrl: './archive-inventory-modal.component.html',
  styleUrls: ['./archive-inventory-modal.component.scss']
})
export class ArchiveInventoryModalComponent implements OnInit {

  //incoming
  public entityType: string;
  public entity: any;
  public team_id: number;
  
  constructor(
    public archiveEntityModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,) { }

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Room",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();
  public token: string;

  public entityName: string = '';

  ngOnInit(): void {
    this.continueType = "none";
    this.retrieveToken();

    if(this.entityType == "item")
    {
      this.entityName = this.entity.name;
    }
    else if(this.entityType == "option")
    {
      this.entityName = "/inventory/" + this.entity.id + "/options/"  + this.entity.id + "/archive";
    }
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");

  }

  public clickArchive()
  {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Archiving " + this.entityType + "</span>";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let url = "";
    if(this.entityType == "item")
    {
      url = "/team/" + this.team_id + "/inventory/" + this.entity.id + "/archive";
    }
    else if(this.entityType == "option")
    {
      url = "/inventory/" + this.entity.id + "/options/"  + this.entity.id + "/archive";
    }

    let archiveEntity = this._xrPlatformRestService.restfulAPIQuery(
      url, 
      'put',
      {},
      getOptions
    );

    archiveEntity.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = this.entityType + " successfully archived";
        let outgoingData = {
          action: "delete",
        }
        this.outgoing.next(outgoingData);
      },
      (error) => {
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    )
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.archiveEntityModal.hide();
        break;
      case "reset-form":
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

}
