import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
    selector: 'app-manage-persona-modal',
    templateUrl: './manage-persona-modal.component.html',
})
export class ManagePersonaModalComponent implements OnInit {

    public team_id: number;
    public token: string;
    public mode: any;
    public persona: any;
    public personaForm: UntypedFormGroup;

    //status messages
    public msgs: {
        errorMsg: string;
        statusMsg: string;
        processingMsg: string;
    } = {
            errorMsg: "",
            statusMsg: "",
            processingMsg: "",
        };

    public continueType: string;

    public btnLabel: {
        close: string;
        reset: string;
        retry: string;
    } = {
            close: "Close",
            reset: "Add Another Persona",
            retry: "Retry",
        };

    private outgoing: Subject<any> = new Subject();
    private outgoingData: any;

    constructor(
        public managePersonaModal: MDBModalRef,
        private coolLocalStorage: CoolLocalStorage,
        private _xrPlatformRestService: XrPlatformRestService,
        private formBuilder: UntypedFormBuilder) { }

    ngOnInit(): void {
        if (this.mode === 'Add') {
            this.continueType = "reset";
            this.personaForm = this.formBuilder.group({
                persona_name: "",
                persona_description: "",
            });
        } else if (this.mode === 'Edit') {
            this.btnLabel.retry = "Continue Editing";
            this.continueType = "continue";
            this.personaForm = this.formBuilder.group({
                persona_name: this.persona.persona_name,
                persona_description: this.persona.description,
            });
        }

        this.retrieveToken();
    }

    private retrieveToken() {
        this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
    }

    public resetForm() {
        this.personaForm.reset();
        this.clearStatus();
    }

    public clearStatus() {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
    }

    public handleIncomingAction(action) {
        switch (action) {
            case "close-modal":
                this.managePersonaModal.hide();
                break;
            case "reset-form":
                this.resetForm();
                break;
            case "clear-status":
                this.clearStatus();
                break;
        }
    }
    managePersona() {
        if (this.mode === 'Add') {
            this.addPersona();
        } else if (this.mode === 'Edit') {
            this.editPersona();
        }
    }

    addPersona() {
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "<span class='processing-msg'>Adding persona</span>";
        ;
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
        };

        const getOptions = {
            headers: headers,
        };

        const data = {
            persona_name: this.personaForm.value.persona_name,
            description: this.personaForm.value.persona_description,
        };
        let addedPersona = this._xrPlatformRestService.restfulAPIQuery(
            '/personas/addpersona/' + this.team_id,
            'post',
            data,
            getOptions
        );
        addedPersona.subscribe(
            (response) => {
                ;

                this.msgs.processingMsg = "";
                this.msgs.errorMsg = "";
                this.msgs.statusMsg = "Persona successfully added";

                let outgoingData = {
                    action: 'add',
                    persona: response.persona
                }
                this.outgoing.next(outgoingData);
            },
            (error) => {
                ;
                this.msgs.processingMsg = "";
                let errorMsg = JSON.parse(error._body);
                this.btnLabel.retry = "Retry";
                this.msgs.errorMsg = errorMsg.error;
            }
        );
    }
    editPersona() {
        ;

        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "";
        this.msgs.processingMsg =
            "<span class='processing-msg'>Editing branding persona</span>";

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
        };

        const getOptions = {
            headers: headers,
        };

        const data = {
            persona_name: this.personaForm.value.persona_name,
            description: this.personaForm.value.persona_description,
        };
        let edittedPersona = this._xrPlatformRestService.restfulAPIQuery(
            '/team/' + this.team_id + '/persona/' + this.persona.id,
            'put',
            data,
            getOptions
        );
        edittedPersona.subscribe(
            (response) => {
                ;

                this.msgs.processingMsg = "";
                this.msgs.errorMsg = "";
                this.msgs.statusMsg = "Branding Persona successfully udpated";

                if (response.error != 418) {
                    ;
                    let outgoingData = {
                        action: 'update',
                        persona: response.persona
                    }
                    this.outgoing.next(outgoingData);
                }
            },
            (error) => {
                ;
                this.msgs.processingMsg = "";
                let errorMsg = JSON.parse(error._body);
                this.btnLabel.retry = "Retry";
                this.msgs.errorMsg = errorMsg.error;
            }
        );
    }


}
