<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">
      {{ title | titlecase }}
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="scheduleFrame.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      {{ msgs.actionMsg }}
      <form
        *ngIf="formLoading && action !== 'delete'"
        [formGroup]="groupingForm"
        class="text-center"
      >
        <!--group_name-->
        <div class="md-form mb-5">
          <input
            type="text"
            id="groupingForm-group-name"
            class="form-control"
            formControlName="group_name"
            mdbInput
            mdbValidate
          />
          <label for="groupingForm-group-name"
            >{{ labels.userGroup.singular | titlecase }} Name</label
          >
          <mdb-error
            *ngIf="
              group_name.invalid && (group_name.dirty || group_name.touched)
            "
          >
            Group name required
          </mdb-error>
        </div>
        <!--end group_name-->

        <!--group_description-->
        <div class="md-form mb-5">
          <input
            type="text"
            id="groupingForm-group-description"
            class="form-control"
            formControlName="group_description"
            mdbInput
            mdbValidate
          />
          <label for="groupingForm-group-description"
            >{{ labels.userGroup.singular | titlecase }} Description</label
          >
        </div>
      </form>

      <!-- for deletion dialog -->
      <ul class="list-unstyled" *ngIf="action === 'delete'">
        <li>
          <span class="bold">Name:</span>
          {{ preSelected.group_name }}
        </li>
        <li>
          <span class="bold">Description:</span>
          {{ preSelected.group_description
          }}<span *ngIf="preSelected.group_description === ''" class="italic"
            >No description</span
          >
        </li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      [ngClass]="{ 'btn-danger': action == 'delete' }"
      mdbWavesEffect
      class="theme-modal-button btn"
      data-dismiss="modal"
      [disabled]="
        (action !== 'delete' && !groupingForm.valid) ||
        formState === 'processing' ||
        !this.showMain
      "
      (click)="manageGroup()"
    >
      {{ btnLabel.main }}
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      class="theme-modal-cancel-button btn"
      (click)="scheduleFrame.hide()"
    >
      {{ btnLabel.close }}
    </button>
  </div>
</div>
