import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModulesModule } from "../shared-modules/shared-modules.module";
import { PersistentModule } from "../persistent/persistent.module";
import { EventsBaseComponent } from "./events-base/events-base.component";
import { ManageEventMediaComponent } from "./modals/manage-event-media/manage-event-media.component";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { MediaManagementModule } from "../media-management/media-management.module";
import { EventSettingsManageMediaComponent } from "./modals/event-settings-manage-media/event-settings-manage-media.component";
import { EventsViewTableComponent } from "./events-base/views/events-view-table/events-view-table.component";
import { ManageEventComponent } from "./modals/manage-event/manage-event.component";
import { ManageEventRosterComponent } from "./modals/manage-event-roster/manage-event-roster.component";
import { HubsWithinAZoneComponent } from "./modals/hubs-within-a-zone/hubs-within-a-zone.component";
import { CloneEventComponent } from './modals/clone-event/clone-event.component';
import { ManageEventPartPropsComponent } from './modals/manage-event/parts/manage-event-part-props/manage-event-part-props.component';
import { ManageEventPartExperiencesComponent } from './modals/manage-event/parts/manage-event-part-experiences/manage-event-part-experiences.component';
@NgModule({
  declarations: [
    EventsBaseComponent,
    ManageEventMediaComponent,
    EventSettingsManageMediaComponent,
    EventsViewTableComponent,
    ManageEventComponent,
    ManageEventRosterComponent,
    HubsWithinAZoneComponent,
    CloneEventComponent,
    ManageEventPartPropsComponent,
    ManageEventPartExperiencesComponent,
  ],
  imports: [
    CommonModule,
    SharedModulesModule,
    PersistentModule,
    MDBBootstrapModulesPro.forRoot(),
    MediaManagementModule,
  ],
})
export class EventManagementModule {}
