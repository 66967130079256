<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">Archive {{ type }}</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="archiveExperienceModal.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-3 p-0">
    <div class="row mb-3">
      <div class="col-12">
        <div class="md-form">
          <p><strong>Are you sure you want to archive this Experience?:</strong> {{ targetExperience.label }}</p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        mdbBtn
        mdbWavesEffect
        (click)="clickArchive()"
        type="button"
        size="md"
        data-dismiss="modal"
        class="theme-modal-button btn"
      >
        Archive Experience
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        data-dismiss="modal"
        (click)="archiveExperienceModal.hide()"
      >
        Close
      </button>
    </div>
  </div>
</div>
