import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';

@Component({
  selector: 'app-manage-question-modal',
  templateUrl: './manage-question-modal.component.html',
  styleUrls: ['./manage-question-modal.component.scss']
})
export class ManageQuestionModalComponent implements OnInit {

  public type: string;
  public question: any;
  public teamID: number;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public questionForm: any;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Question",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();

  public constrained: boolean = false;

  public typeSelect: {value: string; label: string}[] = 
        [{value: 'string', label: "String"}, 
          {value: 'number', label: "Number"},
          {value: 'asset', label: "Asset"},
          {value: 'select_list', label: "Select List"},
          {value: 'multi_select_list', label: "Multi Select List"},];

  constructor(
    public manageSurveyModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.continueType = "reset";
    if(this.type === 'Add') {
      this.questionForm = this.formBuilder.group({
        name: '',
        label: '',
        question: '',
        constrained: false,
        type: ''
      });
    }
    else {
      this.questionForm = this.formBuilder.group({
        name: this.question.name,
        label: this.question.label,
        question: this.question.question,
        constrained: this.constrained,
        type: this.question.type
      });
      this.constrained = this.question.constrained;
    }
  }

  public updateCheckbox(event: any) {
    
    this.constrained = event.checked;
    
  }

  public clickUpdateQuestion() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Adding Question</span>";

    const data = {
      name: this.questionForm.value.name,
      label: this.questionForm.value.label,
      question: this.questionForm.value.question,
      constrained: this.constrained,
      type: this.questionForm.value.type
    }

    

    if(this.type === "add") {
      
      let addQuestion = this._xrPlatformRestService.userDataHandler(
        '/question',
        'post',
        data
      )

      addQuestion.subscribe((response) => {
        
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Question successfully added";

        let outgoingData = {
          action: 'add',
          question: response.question
        }
        this.outgoing.next(outgoingData);
        
        
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      });

    }
    else if(this.type === "Edit") {
      let editQuestion = this._xrPlatformRestService.userDataHandler(
        '/question/' + this.question.id + '/update',
        'put',
        data
      )

      editQuestion.subscribe((response) => {
        
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Question successfully updated";

        let outgoingData = {
          action: 'edit',
          question: response.question
        }
        this.outgoing.next(outgoingData);
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      });
    }
  }

  public resetForm() {
    this.questionForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.manageSurveyModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

}
