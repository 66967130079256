import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { CoolLocalStorage } from "@angular-cool/storage";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";

@Component({
  selector: "app-experience-attribute-to-global-experience",
  templateUrl: "./experience-attribute-to-global-experience.component.html",
  styleUrls: ["./experience-attribute-to-global-experience.component.scss"],
})
export class ExperienceAttributeToGlobalExperienceComponent implements OnInit {
  private team_id: number;
  public token: string;
  private experience: any;
  public experienceAttributeNames = [];
  private experienceAttributes: any;

  public experienceAttributeForm = this.formBuilder.group({
    experience_attribute_name: "",
  });
  public experienceAttributeFormGroup: UntypedFormGroup;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Experience Attribute",
    retry: "Retry",
  };

  searchText = new Subject();
  results: Observable<string[]>;

  private outgoing: Subject<any> = new Subject();
  private outgoingData: any;

  constructor(
    public experienceAttributeToExperienceModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {}

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  //retrieve experience attributes
  private retrieveExperienceAttributes() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let experience_attribute_request =
      this._xrPlatformRestService.restfulAPIQuery(
        "/experience-attributes",
        "get",
        {},
        getOptions
      );

    experience_attribute_request.subscribe((response) => {
      this.experienceAttributes = response;
      this.experienceAttributeNames = this.experienceAttributes.map(
        (experienceAttribute) => experienceAttribute.name
      );
      this.results = this.searchText.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    });
  }

  private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.experienceAttributeNames.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }
}
