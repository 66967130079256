import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { throwError } from "rxjs";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { TitleCasePipe } from "@angular/common";
import { MetricsServicesService } from "../../../services/metrics-services.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-connection-line-breakdown",
  templateUrl: "./connection-line-breakdown.component.html",
  styleUrls: ["./connection-line-breakdown.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class ConnectionLineBreakdownComponent implements OnInit {
  //incoming
  @Input("teamID") teamID: number;
  @Input("session_id") session_id: number;
  @Input("labels") labels: any;
  @Input("users") users: any;
  @Input("clientSettings") clientSettings: any;

  public chartTypeLT: string = "line";
  public chartDatasetsLT: Array<any> = [];
  public chartLabelsLT: Array<any> = [];

  public yLabels: Array<string>;

  public chartOptionsLT: any = {};
  public chartColorsLT: Array<any> = [
    {
      backgroundColor: ["#7F95D1"],
      borderColor: ["#7F95D1"],
    },
  ];

  public lineBreakLoading = false;
  public lineBreakSelected = false;

  public basicLoad = false;
  public active: any;

  public chartColors: any = [];
  constructor(
    private http: HttpClient,
    private _clientManagementService: ClientManagementService,
    private _metricsServicesService: MetricsServicesService,
    private _settingsService: SettingsService,
    private TitleCase: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.chartColors = this._metricsServicesService.getMetricsColors();

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.lineBreakLoading = true;
    this.setupUserBreakdown();
  }

  public setupUserBreakdown() {
    let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const randomColor = () => {
      return "hsl(" + 360 * Math.random() + "," + "70%," + "50%)";
    };

    const changeSaturation = (colorHSL) => {
      return colorHSL.replace("50", function () {
        return Math.floor(Math.random() * (85 - 75 + 1)) + 75;
      });
    };

    let retrieveMetricsData = this._metricsServicesService.getUserBreakdown(
      this.teamID,
      this.session_id,
      tmz
    );

    retrieveMetricsData.subscribe(
      (response: any) => {
        let labels = response.Graph.labelData;
        let datasets = [];
        let graph = response.Graph;
        let yLabels = [];

        yLabels.push("");
        let i = 0;
        graph.Data.forEach((element) => {
          console.log("element in graph data", element);

          let user = {
            id: element.external_user_id,
            username: element.user_id
          };

          if (this.clientSettings.metricsUsername.unconstrained_default){
            let findUser = this.users.find((x) => x.id === parseInt(element.external_user_id));

            if (findUser === undefined || findUser === null) {
              user = {
                id: element.external_user_id,
                username: element.user_id
              }
            } else {
              user = {
                id: findUser.id,
                username: findUser.username
              }
            }
          }

          let label = user.id;

          if (this.clientSettings.metricsUsername.unconstrained_default) label = user.username;

          yLabels.push(label);
          let color = randomColor();
          let tempData = {
            data: element.periods,
            label: label,
            borderColor: this.chartColors[i],
            backgroundColor: this.chartColors[i],
            tension: 0,
            fill: false,
          };

          i += 1;
          if (i >= this.chartColors.length) {
            i = 0;
          }

          this.chartDatasetsLT.push(tempData);
          datasets.push(tempData);
        });
        let data = [];
        ;
        let tempData = {
          data: Array(labels.length).fill(this.chartDatasetsLT.length + 1, 0),
          label: "",
          borderColor: "rgba(255, 99, 132, 0)",
          backgroundColor: "rgba(255, 99, 132, 0)",
          tension: 0,
          fill: false,
        };
        yLabels.push("");
        this.chartDatasetsLT.push(tempData);
        datasets.push(tempData);
        ;
        this.chartLabelsLT = graph.labelData;

        const getOrCreateTooltip = (chart) => {
          let tooltipEl = chart.canvas.parentNode.querySelector("div");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
            tooltipEl.style.borderRadius = "3px";
            tooltipEl.style.color = "white";
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.transform = "translate(-50%, 0)";
            tooltipEl.style.transition = "all .1s ease";
            tooltipEl.style.zIndex = "10000";
            tooltipEl.style.padding = "5px";

            const table = document.createElement("table");
            table.style.margin = "0px";

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
          }

          return tooltipEl;
        };

        const externalTooltipHandler = (context) => {
          const { chart, tooltip } = context;
          const tooltipEl = getOrCreateTooltip(chart);
          let y = tooltip["dataPoints"][0]["raw"];
          let index = labels.indexOf(tooltip["dataPoints"][0]["label"]);
          if (
            y != null &&
            (datasets[y - 1]["data"][index - 1] == null ||
              datasets[y - 1]["data"][index + 1] == null)
          ) {
            let body_text = "";
            let reason = graph.Data[y - 1]["exit_reasons"][index];
            if (
              datasets[y - 1]["data"][index - 1] == null &&
              datasets[y - 1]["data"][index + 1] == null
            ) {
              body_text +=
                this.TitleCase.transform(this.labels.user.singular) + " entered and exited at " +
                tooltip["dataPoints"][0]["label"] +
                " Reason: " +
                reason;
            } else if (datasets[y - 1]["data"][index - 1] == null) {
              body_text +=
                this.TitleCase.transform(this.labels.user.singular) + " entered at " + tooltip["dataPoints"][0]["label"];
            } else {
              body_text +=
                this.TitleCase.transform(this.labels.user.singular) + " exited at " +
                tooltip["dataPoints"][0]["label"] +
                " Reason: " +
                reason;
            }
            tooltipEl.style.opacity = 1;
            if (tooltip.body) {
              const titleLines = tooltip.title || [];
              const bodyLines = tooltip.body.map((b) => b.lines);

              const tableHead = document.createElement("thead");

              titleLines.forEach((title) => {
                const tr = document.createElement("tr");
                //tr.style.borderWidth = 0;

                const th = document.createElement("th");
                //th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
              });

              const tableBody = document.createElement("tbody");
              bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement("span");
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = "2px";
                span.style.marginRight = "10px";
                span.style.height = "10px";
                span.style.width = "10px";
                span.style.display = "inline-block";

                const tr = document.createElement("tr");
                tr.style.backgroundColor = "inherit";
                //tr.style.borderWidth = 0;

                const td = document.createElement("td");
                //td.style.borderWidth = 0;

                const text = document.createTextNode(body_text);

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
              });

              const tableRoot = tooltipEl.querySelector("table");

              // Remove old children
              while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
              }

              // Add new childSren
              tableRoot.appendChild(tableHead);
              tableRoot.appendChild(tableBody);
            }

            const { offsetLeft: positionX, offsetTop: positionY } =
              chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + "px";
            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
              tooltip.padding + "px " + tooltip.padding + "px";
          } else {
            tooltipEl.style.opacity = 0;
          }
        };

        this.chartOptionsLT = {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          interaction: {
            intersect: false,
          },
          layout: {
            padding: {
              left: 15,
              right: 75,
              top: 0,
              bottom: 0
            }
          },
          plugins: {
            title: {
              display: true,
              text: graph.graphTitle,
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: externalTooltipHandler,
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Time",
              },
            },
            y: {
              title: {
                display: true,
                text: "Username",
              },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                callback: function (value, index, values) {
                  return yLabels[index];
                },
              },
            },
          },
          elements: {
            point: {
              radius: function adjustLineEndPoints(context) {
                if (context.parsed === undefined) return 0;

                var y = context.parsed.y;
                var index = context.parsed.x;
                if (
                  y != null &&
                  (datasets[y - 1]["data"][index - 1] == null ||
                    datasets[y - 1]["data"][index + 1] == null)
                ) {
                  return 5;
                }
                return 0;
              },
              hoverRadius: function adjustLineEndPoints(context) {
                if (context.parsed === undefined) return 0;

                var y = context.parsed.y;
                var index = context.parsed.x;
                if (
                  y != null &&
                  (datasets[y - 1]["data"][index - 1] == null ||
                    datasets[y - 1]["data"][index + 1] == null)
                ) {
                  return 10;
                }
                return 0;
              },
            },
          },
        };

        this.lineBreakLoading = false;
      },
      (error) => {
        ;
      }
    );
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }
}
