import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropzoneCustomComponent } from './dropzone.custom.component';
import { DropzoneCustomDirective } from './dropzone.custom.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DropzoneCustomComponent, DropzoneCustomDirective],
  exports: [CommonModule, DropzoneCustomComponent, DropzoneCustomDirective]
})
export class DropzoneCustomModule {
}
