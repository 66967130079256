import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-archive-modal",
  templateUrl: "./archive-modal.component.html",
  styleUrls: ["./archive-modal.component.scss"],
})
export class ArchiveModalComponent implements OnInit {
  //incoming
  public entityType: string;
  public entity: any;
  public team_id: number;

  constructor(
    public archiveEntityModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService
  ) {}

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Room",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();
  public token: string;

  public entityName: string = "";

  ngOnInit(): void {
    this.continueType = "none";
    this.retrieveToken();

    if (this.entityType == "persona") {
      this.entityName = this.entity.persona_name;
    } else if (this.entityType == "room") {
      this.entityName = this.entity.name;
    } else if (this.entityType == "location") {
      this.entityName = this.entity.name;
      ;
    } else if (this.entityType == "permission") {
      this.entityName = this.entity.permission_name;
    } else if (this.entityType == "role") {
      this.entityName = this.entity.name;
    } else if (this.entityType == "experience") {
      this.entityName = this.entity.name;
    } else if (this.entityType == "branding space") {
      this.entityName = this.entity.name;
    }
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public clickArchive() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Archiving " + this.entityType + "</span>";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let url = "";
    if (this.entityType == "persona") {
      url = "/team/" + this.team_id + "/persona/" + this.entity.id + "/archive";
    } else if (this.entityType == "room") {
      url = "/team/" + this.team_id + "/room/" + this.entity.id + "/archive";
    } else if (this.entityType == "location") {
      url =
        "/team/" + this.team_id + "/location/" + this.entity.id + "/archive";
    } else if (this.entityType == "permission") {
      url = "/personas/addpermission/" + this.entity.id + "/archive";
    } else if (this.entityType == "role") {
      url = "/team/" + this.team_id + "/role/" + this.entity.id + "/archive";
    } else if (this.entityType == "experience") {
      url = "/team/experience/" + this.entity.id + "/archive";
    } else if (this.entityType == "branding space") {
      url =
        "/team/" +
        this.team_id +
        "/branding-space/" +
        this.entity.id +
        "/archive";
    }

    let archiveEntity = this._xrPlatformRestService.restfulAPIQuery(
      url,
      "put",
      {},
      getOptions
    );

    archiveEntity.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = this.entityType + " successfully archived";
        let outgoingData = {
          action: "update",
        };
        this.outgoing.next(outgoingData);
      },
      (error) => {
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.archiveEntityModal.hide();
        break;
      case "reset-form":
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
