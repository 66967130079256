<div class="class-wrapper">
  <app-status-messages [msgs]="msgs" [btnLabel]="btnLabel" [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Add Event Prop</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="eventPropFrame.hide()"
      mdbTooltip="Close" placement="bottom">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-3 p-0">
    <div class="row mb-3">
      <div class="col-12">
        <form class="px-5" [formGroup]="propFormGroup" autocomplete="off">
          <div class="md-form">
            <input type="text" id="prop_name" formControlName="props_name" [ngModel]="searchText | async"
              (ngModelChange)="searchText.next($event)" [mdbAutoCompleter]="auto" placeholder="Name of prop to add"
              class="completer-input form-control mdb-autocomplete" mdbInput />
            <label for="prop_name">Prop Name</label>
            <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="No results found">
              <mdb-option *ngFor="let option of results | async" [value]="option">
                {{ option }}
              </mdb-option>
            </mdb-auto-completer>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="theme-modal-button btn" type="button" mdbBtnp mdbWavesEffect (click)="addEventProps()">
      Add
    </button>
    <button class="theme-modal-button btn" type="button" mdbBtnp mdbWavesEffect (click)="eventPropFrame.hide()">
      Close
    </button>
  </div>
</div>