import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterBy",
})
export class FilterByPipe implements PipeTransform {
  transform(value: any, targetProperty: string, searchValue: string): any {
    if (!searchValue) return value;
    return value.filter((val) => {

      let targetVal = val[targetProperty];
      ;
      if (targetVal === "undefined" || targetVal === null) targetVal = "";

      return targetVal.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    });
  }
}
