import {
  Component,
  Inject,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import {
  UntypedFormGroup,
} from "@angular/forms";
import {
  MDBModalRef,
  MDBModalService,
} from "ng-uikit-pro-standard";
import { DOCUMENT } from "@angular/common";

import {
  faCirclePlus,
  faImages,
} from "@fortawesome/free-solid-svg-icons";

import { EventSettingsManageMediaComponent } from "../../../event-settings-manage-media/event-settings-manage-media.component";
import { ManageEventMediaComponent } from "../../../manage-event-media/manage-event-media.component";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

@Component({
  selector: 'app-manage-event-part-props',
  templateUrl: './manage-event-part-props.component.html',
  styleUrls: ['./manage-event-part-props.component.scss']
})
export class ManageEventPartPropsComponent {
  @Input() prop: any;
  @Input() index: number;
  @Input() teamID: number;
  @Input() teamPropsSelects: any;
  @Input() action: string;
  @Input() labels: any;
  @Input() attached: any[];
  @Input() targetEvent: any;
  @Input() formGroup: UntypedFormGroup;
  @Input() isClean: boolean;
  @Input() eventMedia: any[];

  @Output() formGroupChange = new EventEmitter();
  @Output() mediaChanged = new EventEmitter();
  @Output() isCleanChange = new EventEmitter();

  //fa icons
  public faCirclePlus = faCirclePlus;
  public faImages = faImages;

  private modalOptions = {
    backdrop: "static",
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public selectMediaFrame: MDBModalRef;
  public manageEventMediaFrame: MDBModalRef;

  constructor(@Inject(DOCUMENT) private document: Document, private modalService: MDBModalService, private _notificationService: NotificationsService) { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe(() => {
      this.formGroupChange.emit(this.formGroup);
    });
  }

  public openSelectMediaModalForProp(prop, index) {

    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.data = {
      teamID: this.teamID,
      type: prop.props.subtype,
      targetAsset: prop.props.asset !== undefined ? prop.props.asset : null,
      fromLocation: "manageEvents",
      experience: this.targetEvent,
      labels: this.labels,
      parentModalClass: "schedule-class-container",
    };

    this.modalOptions.class =
      this.modalOptions.class + " modal-full-height modal-right";
    this.modalOptions.containerClass = "event-media-container";
    this.selectMediaFrame = this.modalService.show(
      EventSettingsManageMediaComponent,
      this.modalOptions
    );

    this.selectMediaFrame.content.outgoing.subscribe((changedData) => {

      console.log("changedData in selectMediaFrame", changedData);

      if (changedData.media !== undefined && changedData.media.length) {
        prop.props.asset = changedData.media[0];
      } else if (changedData.media === undefined || !changedData.media.length) {
        prop.props.asset = null;
      }

      this.isClean = false;
      this.isCleanChange.emit(this.isClean);
    });
  }

  public openManageMediaModal(targetEvent, type?, amount?) {
    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    //legacy support
    if (targetEvent !== undefined) targetEvent.schedule_id = targetEvent.id;

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "event-media-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };

    this.modalOptions.data = {
      action: this.targetEvent !== undefined ? "update" : "add",
      targetEvent: targetEvent,
      attached: this.targetEvent === undefined ? this.attached : [],
      teamID: this.teamID,
      type: type !== undefined ? type : "all",
      amount: amount !== undefined ? amount : "multi",
    };

    this.manageEventMediaFrame = this.modalService.show(
      ManageEventMediaComponent,
      this.modalOptions
    );

    this.manageEventMediaFrame.content.outgoing.subscribe((changedData) => {
      if (
        changedData.action !== undefined &&
        changedData.action === "update_event_media"
      ) {
        if (this.targetEvent !== undefined) {
          let toastMsg = `Media updated for ${targetEvent.event_name}`;
          this._notificationService.successNotification(toastMsg);
        }

        if (
          changedData.finalSelectedMedia.length > 0 &&
          this.action === "add"
        ) {
          this.isClean = false;
          this.isCleanChange.emit(this.isClean);
        }

        this.eventMedia = changedData.finalSelectedMedia;
        this.attached = this.eventMedia;

        this.mediaChanged.emit({
          eventMedia: this.eventMedia,
          attached: this.attached,
        });
      }
    });
  }
}
