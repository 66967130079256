import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { ActivatedRoute } from "@angular/router";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import * as moment from "moment";
import { Subject } from "rxjs";

import { MediaServicesV3Service } from "src/app/modules/media-management/services/media.services.v3.service";

@Component({
  selector: "app-manage-media-locker",
  templateUrl: "./manage-media-locker.component.html",
  styleUrls: ["./manage-media-locker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageMediaLockerComponent implements OnInit {
  public teamID: number;

  public title = "Manage Backpack Media";
  public actionMsg: string = "";

  private outgoing: Subject<any> = new Subject();

  public inputActiveButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
    info: false,
    share: false,
    delete: false,
  };

  public actionButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
    info: false,
    share: false,
    delete: false,
  };

  public columnVisibility: {
    thumbnail: boolean;
    pdfStatus: boolean;
    fileType: boolean;
    owner: boolean;
    urlType: boolean;
    dateCreated: boolean;
    shares: boolean;
  } = {
    thumbnail: true,
    pdfStatus: false,
    fileType: true,
    owner: true,
    urlType: false,
    dateCreated: true,
    shares: false,
  };

  public selectable: boolean = true;

  //public selectedMedia: any = [];

  public filterByType: string = "all";

  public selectableType: string = "multiple";

  public clickableThumb: boolean = false;

  public systemButtons: any = {
    action: "Update",
    cancel: "Cancel",
  };

  public systemInteraction: string = "locker_media";

  constructor(public addBackpackModal: MDBModalRef) {}

  ngOnInit(): void {}

  public handleIncomingActions(incoming) {
    switch (incoming.action) {
      case "closeModal":
        this.addBackpackModal.hide();
        break;
      case "successfulUpdate":
        this.finalActions(incoming);
        break;
    }
  }

  private finalActions(incoming) {
    if (incoming.status === "success") {
      let response = incoming.response;

      //updates for adds
      let outgoingData = {
        action: "add",
        locker: response.added.locker,
      };
      this.outgoing.next(outgoingData);

      //updates for removals
      outgoingData = {
        action: "delete",
        locker: response.removed.locker,
      };
      this.outgoing.next(outgoingData);
    }
  }
}
