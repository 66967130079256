import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-manage-media-update-thumbnail",
  templateUrl: "./manage-media-update-thumbnail.component.html",
  styleUrls: ["./manage-media-update-thumbnail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageMediaUpdateThumbnailComponent implements OnInit {
  //incoming
  public teamID: number;
  public media_item: any;

  //persistent
  private token: string;
  private proxyURL: string = environment.proxyURL;
  public modelLoaded: boolean = false;

  //media related
  public thumbnailSrc: string = null;

  private outgoing: Subject<any> = new Subject();

  //for model viewer
  public modelSrc: string = "";
  public generatedThumbnail: any = null;

  constructor(
    public modalFrame: MDBModalRef,
    private _MediaManagementServicesService: MediaManagementServicesService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    console.log(
      "this.media_item in ManageMediaUpdateThumbnailComponent",
      this.media_item
    );

    this.token = this._MediaManagementServicesService.retrieveToken();
    this.configureModel();
  }

  private async configureModel() {
    if (this.media_item.thumbnail_asset_url !== null) {
      this.thumbnailSrc = this.proxyURL + this.media_item.thumbnail_asset_url;
    }

    console.log(
      "this.thumbnailSrc in ManageMediaUpdateThumbnailComponent",
      this.thumbnailSrc
    );

    console.log("this.media_item.cdn_uri", this.media_item.cdn_uri);

    if (this.media_item.cdn_uri !== null) {
      this.modelSrc = this.proxyURL + this.media_item.cdn_uri;
    } else {
      let retrieveURL = await this.retrieveModelURL(this.media_item.uuid).catch (
        (err) => {
          console.log("err in retrieveModelURL", err);
        }
      );

      console.log("retrieveURL", retrieveURL);

      this.modelSrc = this.proxyURL + retrieveURL;
    }

    this.modelLoaded = true;
  }

  private retrieveModelURL(uuid) {
    const headers = {
      "Content-Type": "text/html",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
      responseType: "text",
    };

    let getPrivateLink = this._xrPlatformRestService.retrieveEntityData(
      "asset",
      uuid + "/url",
      getOptions
    );

    return getPrivateLink.toPromise();
  }

  public onGeneratedThumbnail(event) {
    console.log("event in onGeneratedThumbnail", event);

    //convert blog to image we can use for preview
    this.generatedThumbnail = event;
    let generatedThumbnailLink = URL.createObjectURL(event);
    this.thumbnailSrc = generatedThumbnailLink;
  }

  public uploadThumbnail(media_item) {
    let notification = this._notificationService.savingNotification(
      "Updating thumbnail..."
    );

    const headers = {
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    //remove extension from name without removing any other periods
    let nameWithoutExtension = media_item.name.substring(
      0,
      media_item.name.lastIndexOf(".")
    );

    let file = new File([this.generatedThumbnail], nameWithoutExtension, {
      type: this.generatedThumbnail.type,
    });

    let data = new FormData();
    data.append("file", file);
    data.append("max_width", "500");

    const targetURL = "/asset/" + media_item.uuid + "/thumbnail";

    let uploadThumbnail = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "post",
      data,
      options,
      "v1"
    );

    uploadThumbnail.subscribe(
      (res) => {
        console.log("res in uploadThumbnail", res);
        this._notificationService.clearNotification(notification);
        this._notificationService.successNotification("Thumbnail updated");
        let outgoingData = {
          action: "update",
          changedData: res,
        };
        this.outgoing.next(outgoingData);
        this.modalFrame.hide();
      },
      (err) => {
        console.log("err in uploadThumbnail", err);
        this._notificationService.clearNotification(notification);
        this._notificationService.errorNotification("Error updating thumbnail");
      }
    );
  }
}
