import { Component, OnInit } from "@angular/core";

import { ClientManagementService } from "src/app/services/utilities/client-management.service";

import { CoolLocalStorage } from "@angular-cool/storage";

import { environment } from "./../../../../environments/environment";
import { ActivatedRoute } from "@angular/router";
import { FileUploader } from "ng2-file-upload";

@Component({
  selector: "app-users-csv-upload",
  templateUrl: "./users-csv-upload.component.html",
  styleUrls: ["./users-csv-upload.component.scss"],
})
export class UsersCsvUploadComponent implements OnInit {
  public copy = "Upload a csv to import users.";
  public token: string;
  public teamID: number;

  private restURL: string = this._clientManagementService.getRESTurl("v1");

  //uploader vars
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _clientManagementService: ClientManagementService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.retrieveToken();
    this.retrieveTeamID();
    this.configureUploader();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID(){
    this.teamID = JSON.parse(this.coolLocalStorage.getItem("admin_panel_team_id"));
  } 

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  private configureUploader() {
    this.uploader = new FileUploader({
      url: this.restURL + "/user/create/csv",
      disableMultipart: false,
      authToken: "Bearer " + this.token,
      method: "POST",
      isHTML5: true,
      itemAlias: "users_csv",
      removeAfterUpload: true,
      queueLimit: 1,
      allowedMimeType: [
        "application/csv",
        "text/csv",
        "application/vnd.ms-excel",
      ],
    });

    this.hasBaseDropZoneOver = false;

    this.response = "";

    this.uploader.onAfterAddingFile = (fileItem) => {
      fileItem.withCredentials = false;
    };

    this.uploader.response.subscribe((res) => {
      this.postProcessCSV(JSON.parse(res));
    });

    this.uploader.onCompleteAll = () => {
      ;
    };
  }

  private postProcessCSV(response: any) {
    if (response.status === undefined || response.status !== "OK") {
      this.response = "Error uploading CSV";
    } else {
      this.response = "CSV Successfully uploaded.";
    }
  }
}
