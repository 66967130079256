<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 text-center">
        <h5>Select {{ labels.user.singular | titlecase }} Gazing Breakdown</h5>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div
    class="col-12 d-flex justify-content-center align-items-center flex-row loading-height"
    *ngIf="loading"
  >
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div
    class="col-12 d-flex justify-content-center align-items-center flex-row loading-height"
    *ngIf="!loading && error"
  >
    <p>There was a problem retrieving this data, please reach out to support.</p>
  </div>
  <div class="col-12" *ngIf="!loading && !error">
    <table
      hover="true"
      mdbTableScroll
      scrollY="true"
      maxHeight="600"
      bordered="true"
      class="table table-responsive-md btn-table gazing-table"
      mdbTable
      small="true"
    >
      <col style="width: 15%" />
      <thead class="sticky-top">
        <tr>
          <th [attr.colspan]="colSpan" class="labelsX">
            Select {{ labels.user.plural | titlecase }} being gazed at:
          </th>
        </tr>
        <tr>
          <th class="connecting-cell"></th>
          <th class="text-center heading-cells">
            <mdb-icon fas icon="graphUsers"></mdb-icon>
          </th>
          <th
            *ngFor="let head of graphUsers; let i = index"
            scope="col"
            class="heading-cells"
          >
            {{ head.username }}
          </th>
        </tr>
      </thead>
      <tbody #row>
        <tr class="labelsY-container">
          <td [attr.rowspan]="rowSpan" class="labelsY">
            Select {{ labels.user.plural | titlecase }} gazing at:
          </td>
        </tr>
        <tr
          mdbTableCol
          *ngFor="let gazingrow of gazingData; let i = index"
          class="gazing-data-containers"
        >
          <td>
            {{ graphUsers[i].username }}
          </td>
          <td *ngFor="let el of gazingrow; let i = index">
            {{ el }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
