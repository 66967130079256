<div class="media-management-card content-card h-100 w-100 p-0">
  <div class="row fluid-content h-100">
    <div class="w-100 h-100 tabs-table">
      <div
        class="loading-screen d-flex align-content-center"
        *ngIf="loading.allMedia"
      >
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg === ''"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg !== ''"
        >
          <div
            class="alert alert-danger"
            role="alert"
            [innerHTML]="errorRetrievingMsg"
          ></div>
        </div>
      </div>
      <app-media-manager-table-view
        *ngIf="!loading.allMedia && errorRetrievingMsg === ''"
        [teamID]="teamID"
        [defaultTab]="defaultTab"
        [actionButtons]="actionButtons"
        [columnVisibility]="columnVisibility"
        [selectable]="selectable"
        [selectedMedia]="selectedMedia"
        [media]="media"
        [guardrailSettings]="guardrailSettings"
        [errorRetrievingMsg]="errorRetrievingMsg"
        [emptyMedia]="emptyMedia"
        [mediaSharing]="mediaSharing"
        [filterByType]="filterByType"
        [selectableType]="selectableType"
        [clickableThumb]="clickableThumb"
        [systemButtons]="systemButtons"
        [systemInteraction]="systemInteraction"
        [TargetEntity]="TargetEntity"
        [attached]="attached"
        [tab_current_page]="tab_current_page"
        [tab_current_total]="tab_current_total.allMedia"
        [tableLoading]="tableLoading.allMedia"
        [loadMore]="loadMore"
        [quantityChange]="quantityChange"
        [loading]="loading"
        (updatedMedia)="handleUpdatedMedia($event)"
        (filterChange)="onFilterChange($event)"
        (selected)="updateSelected($event)"
        (sortChange)="onSortChange($event)"
        (loadMoreEvent)="loadMoreItems($event)"
      ></app-media-manager-table-view>
    </div>
  </div>
  <!-- /tab nav -->
</div>
<!-- modal footer -->
<div
  class="modal-footer justify-content-between"
  *ngIf="systemButtons.action !== null || systemButtons.cancel !== null"
>
  <div
    class="alert custom-alert d-flex"
    role="alert"
    [ngClass]="{
      'visible alert-danger': errorMsg !== '',
      'visible alert-info': statusMsg !== ''
    }"
  >
    <div *ngIf="errorMsg !== ''" [innerHTML]="errorMsg"></div>
    <div *ngIf="statusMsg !== ''" [innerHTML]="statusMsg"></div>

    <button
      *ngIf="errorMsg !== ''"
      type="button"
      class="close close-status"
      aria-label="Close"
      (click)="hideStatus('error')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <button
      *ngIf="statusMsg !== ''"
      type="button"
      class="close close-status"
      aria-label="Close"
      (click)="hideStatus('status')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-actions">
    <button
      *ngIf="systemButtons.cancel !== null"
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="closeParentModal()"
    >
      {{ systemButtons.cancel }}
    </button>
    <button
      *ngIf="systemButtons.action !== null"
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      data-dismiss="modal"
      (click)="manageActions()"
    >
      {{ systemButtons.action }}
    </button>
  </div>
</div>
