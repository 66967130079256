import { throwError } from "rxjs";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { CoolLocalStorage } from "@angular-cool/storage";
import { catchError } from "rxjs/operators";

import { XrPlatformRestService } from "./../../../services/rest/xr-platform/xr-platform-rest.service";
import { environment } from "./../../../../environments/environment";

import numWords from "num-words";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { HttpClient } from "@angular/common/http";

import * as moment from "moment";
import { DateTime, IANAZone } from "luxon";
import * as timezoneFixes from "src/assets/timezones/ianaToAbbreviation.json";

@Component({
  selector: "app-metrics-performance",
  templateUrl: "./metrics-performance.component.html",
  styleUrls: ["./metrics-performance.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MetricsPerformanceComponent implements OnInit {
  @ViewChild("myTable", { static: false }) table: any;
  @ViewChild("myTableTest", { static: false }) tableTest: any;

  private proxyURL: string = environment.proxyURL;
  public restURL: string = "";
  public tz: any;
  public tzString: string = "America/New_York";

  public assetPrefix: string = "/assets/json";
  public token: string;
  public teamID: number;
  public tableLoading: boolean = true;
  public users: any;
  public temp = [];
  public tempTest = [];
  public taskSorting: string;
  public taskSortingTest: string;
  public processCache: any;
  public sortingOptions: { value: string; label: string }[] = [
    {
      value: "skips",
      label: "Did they skip",
    },
    {
      value: "tries",
      label: "# tries to 100%",
    },
  ];
  public sortingOptionsTest: { value: string; label: string }[] = [
    {
      value: "percent_complete",
      label: "% Complete",
    },
    {
      value: "out_of_order",
      label: "Out of Order",
    },
  ];

  public copy =
    "This page details the specific, in-experience metrics that will show how each user is performing. You have separate tables for corrective guidance, where the learner is told when mistakes are made, and the unguided, test mode for final assessment.";

  public rows: any[] = [];
  public rowsTest: any[] = [];
  public bodyHeight: number;

  /**
   * Keep task names short
   */

  public columns: any[] = [
    { name: "Name", field: "name", frozenLeft: true, class: "core-mid", type: "core" },
    {
      name: "Started",
      field: "start_at",
      frozenLeft: true,
      class: "core-long",
      type: "core-date",
    },
    {
      name: "Avg Skips",
      field: "avgSkips",
      frozenLeft: true,
      class: "core-short",
      type: "core",
    },
    {
      name: "Avg Tries",
      field: "avgTries",
      frozenLeft: true,
      class: "core-short",
      type: "core",
    },
    {
      name: "Format",
      field: "format",
      frozenLeft: true,
      class: "core-long",
      type: "format",
    },
  ];

  public frozenColumns = [
    { field: "name", name: "Name" },
    { field: "start_at", name: "Started" },
    { field: "avgSkips", name: "Avg Skips" },
    { field: "avgTries", name: "Avg Tries" },
    { field: "format", name: "Format" },
  ];

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _settingsService: SettingsService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.taskSorting = "skips";
    this.taskSortingTest = "percent_complete";

    this.retrieveTeamID();
    this.retrieveToken();
    this.resolveTimeZone();

    let storedSettings = this._settingsService.getSettingsFromStorage(this.teamID);
    this.restURL = this.proxyURL + storedSettings.metricsURI.unconstrained_default;

    this.setupTable();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private resolveTimeZone() {
    let tz_iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.tz = moment.tz(tz_iana).format("z");
    this.tzString = tz_iana;

    //if the string includes a negative number in the form -03 or -02, check for a timezone fix
    if (this.tz.includes("-")) {
      let fix = timezoneFixes[tz_iana];

      if (fix !== undefined) {
        this.tz = fix;
      } else {
        //fall back to UTC from Luxon
        this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
      }
    } else if (this.tz.includes("+")) {
      //fall back to UTC from Luxon
      //@todo: update timezone fixes to include plus returns
      this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
    }


  }

  private setupTable() {
    let retrieveUsersData = this.retrieveUsers();
    let retrieveTableData = this.retrieveTableData();

    const promises = [retrieveUsersData, retrieveTableData] as const;

    Promise.all(promises)
      .then((response) => {
        console.log("response in promises", response);

        this.users = response[0];
        let tableData = response[1];
        this.processCache = tableData;
        this.processTableData(tableData);
      })
      .catch((error) => console.log("error in promises", error));
  }

  private retrieveUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options,
      true
    );

    return retrieveUsers.toPromise();
  }

  private retrieveTableData() {
    // let retrieveTableData = this._xrPlatformRestService.retrieveJSON(
    //   this.assetPrefix + "/performance-metrics-live-test.json?v=0.0.0.5"
    // );

    let targetURL = this.restURL;

    let retrieveTableData = this.http
      .get(targetURL, {})
      .pipe(catchError(this.handleError));

    return retrieveTableData.toPromise();
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }

  private processTableData(response) {
    console.log("response in processTableData", response);

    let aggregate = []; //response.aggregate.tasks;
    let users = response.users.user;
    let rows = [];
    let rowsTest = [];

    let taskHit = false;
    users.forEach((userEntry) => {
      console.log("userEntry", userEntry);

      if (taskHit) return false;

      if (userEntry.sessions !== null && userEntry.sessions.length) {
        aggregate = userEntry.sessions[0].tasks;
        if (aggregate === null) aggregate = [];
        taskHit = true;
      }
    });

    aggregate.forEach((task, index) => {
      let thisColumn = {
        name: task.name,
        field: this.toCamelCaseString(numWords(index) + task.name),
        frozenLeft: false,
        width: 140,
        type: "task",
      };

      this.columns.push(thisColumn);
    });

    users.forEach((userEntry) => {
      //let user = userEntry.user;
      let user = userEntry;

      if (userEntry.sessions === null || !user.sessions.length) {
        return false;
      }

      let sessionHitCorrective = false;
      let sessionHitTest = false;
      let latestSession = {
        avg_skips: null,
        avg_tries: null,
        start_at: null,
        tasks: [],
      };
      let latestSessionTest = {
        avg_skips: null,
        avg_tries: null,
        start_at: null,
        tasks: [],
      };

      user.sessions.forEach((session) => {
        if (sessionHitTest && sessionHitCorrective) {
          return false;
        }

        if (!sessionHitCorrective && session.session_type === "corrective") {
          latestSession = session;
          sessionHitCorrective = true;
        }

        if (
          !sessionHitTest &&
          session.session_type ===
          "test_mode" /**session.session_type === "test"*/
        ) {
          latestSessionTest = session;
          sessionHitTest = true;
        }
      });

      let retrieveThisUser = this.users.filter((storedUser) => {
        return storedUser.id === user.id;
      });

      console.log("retrieveThisUser", retrieveThisUser);

      if (!retrieveThisUser.length) return false;

      let thisUser = retrieveThisUser[0];

      if (latestSession.tasks !== null && latestSession.tasks.length) {
        let thisRow: any = {};
        thisRow.last_name = thisUser.last_name;
        thisRow.name = `${thisUser.first_name} ${thisUser.last_name}`;

        console.log("latestSession", latestSession);
        console.log("this.tzString", this.tzString);

        thisRow.start_at = moment(`${latestSession.start_at}Z`).tz(this.tzString).local().format("MM/DD/YYYY hh:mm A");
        thisRow.avgSkips =
          Math.round(parseFloat(latestSession.avg_skips) * 100) / 100;
        thisRow.avgTries =
          Math.round(parseFloat(latestSession.avg_tries) * 100) / 100;
        thisRow.format = "format";

        latestSession.tasks.forEach((task, index) => {
          let rowProp = this.toCamelCaseString(numWords(index) + task.name);
          thisRow[rowProp] = {
            sort: this.taskSorting,
            skips: task.skips,
            tries: task.tries,
          };
        });

        rows.push(thisRow);
      }

      if (latestSessionTest.tasks !== null && latestSessionTest.tasks.length) {
        let thisRowTest: any = {};
        thisRowTest.last_name = thisUser.last_name;
        thisRowTest.name = `${thisUser.first_name} ${thisUser.last_name}`;
        thisRowTest.start_at = latestSessionTest.start_at;
        thisRowTest.avgSkips =
          Math.round(parseFloat(latestSessionTest.avg_skips) * 100) / 100;
        thisRowTest.avgTries =
          Math.round(parseFloat(latestSessionTest.avg_tries) * 100) / 100;
        thisRowTest.format = "format";

        latestSessionTest.tasks.forEach((task, index) => {
          let rowProp = this.toCamelCaseString(numWords(index) + task.name);
          thisRowTest[rowProp] = {
            sort: this.taskSortingTest,
            percent_complete:
              isNaN(task.percent_complete) || task.percent_complete === null
                ? 0
                : task.percent_complete + "%",
            out_of_order: task.out_of_order,
          };
        });

        rowsTest.push(thisRowTest);
      }
    });

    this.rows = rows;
    this.rowsTest = rowsTest;
    this.temp = [...rows];
    this.tempTest = [...rowsTest];

    console.log("this.columns", this.columns);
    console.log("this.rows", this.rows);

    this.tableLoading = false;
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public onSortingOptionsChange(event, session_type) {

    console.log("event in onSortingOptionsChange", event);
    console.log("session_type in onSortingOptionsChange", session_type);

    if (session_type === "corrective") {
      this.taskSorting = event;
    } else {
      this.taskSortingTest = event;
    }

    this.processTableData(this.processCache);
  }

  public customSort(event, table) {

    event.data.sort((data1, data2) => {

      console.log("event", event);
      console.log("data1", data1);
      console.log("this.taskSorting", this.taskSorting);
      console.log("this.taskSortingTest", this.taskSortingTest);

      let value1 = data1[event.field][table === 'corrective' ? this.taskSorting : this.taskSortingTest];

      console.log("data2", data2);

      let value2 = data2[event.field][table === 'corrective' ? this.taskSorting : this.taskSortingTest];

      //convert percent strings, if necessary
      if (typeof value1 === 'string' && value1.includes('%')) {
        value1 = parseFloat(value1.replace('%', ''));
      }

      if (typeof value2 === 'string' && value2.includes('%')) {
        value2 = parseFloat(value2.replace('%', ''));
      }

      //conver booleans, if necessary
      if (typeof value1 === 'boolean') {
        value1 = value1 ? 1 : 0;
      }

      if (typeof value2 === 'boolean') {
        value2 = value2 ? 1 : 0;
      }

      console.log("value1", value1);
      console.log("value2", value2);

      let result = null;

      if (value1 === null) {
        result = -1;
      } else if (value2 === null) {
        result = 1;
      } else if (typeof value1 === "object") {
        result = this.taskComparator(
          value1,
          value2,
          data1,
          data2,
          event.order
        );

      } else {
        if (value1 < value2) {
          result = -1;
        }
        if (value1 >= value2) {
          result = 1;
        }
      }

      return event.order * result;
    });

  }

  public taskComparator(valueA, valueB, rowA, rowB, sortDirection) {
    if (valueA.sort !== undefined) {
      valueA = valueA[valueA.sort];
      valueB = valueB[valueB.sort];

      if (parseFloat(valueA) < parseFloat(valueB)) {
        return -1;
      }
      if (parseFloat(valueA) >= parseFloat(valueB)) {
        return 1;
      }
    } else {
      if (valueA.toLowerCase() < valueB.toLowerCase()) {
        return -1;
      }
      if (valueA.toLowerCase() >= valueB.toLowerCase()) {
        return 1;
      }
    }
  }

  updateFilter(session_type, event) {
    const val = event.target.value.toLowerCase();
    //let bodyHeight = this.table.bodyHeight;

    if (session_type === "corrective") {
      // filter our data
      const temp = this.temp.filter(function (d) {
        return d.last_name.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.rows = temp;
    } else {
      // filter our data
      const temp = this.tempTest.filter(function (d) {
        return d.last_name.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.rowsTest = temp;
    }

    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
    // this.tableTest.offset = 0;
    setTimeout(() => {
      // this.table.bodyHeight = bodyHeight;
      // this.tableTest.bodyHeight = bodyHeight;
      // this.table.cd.markForCheck();
      // this.tableTest.cd.markForCheck();
    }, 50);
  }

  // util function to convert the input to string type
  private convertToString(input) {
    if (input) {
      if (typeof input === "string") {
        return input;
      }

      return String(input);
    }
    return "";
  }

  // convert string to words
  private toWords(input) {
    input = this.convertToString(input);

    var regex =
      /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;

    return input.match(regex);
  }

  // convert the input array to camel case
  private toCamelCase(inputArray) {
    let result = "";

    for (let i = 0, len = inputArray.length; i < len; i++) {
      let currentStr = inputArray[i];

      let tempStr = currentStr.toLowerCase();

      if (i != 0) {
        // convert first letter to upper case (the word is in lowercase)
        tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
      }

      result += tempStr;
    }

    return result;
  }

  // this function call all other functions

  private toCamelCaseString(input) {
    let words = this.toWords(input);

    //convert numbers to words to avoid key issues
    let filteredWords = words.map((word) => {
      if (isNaN(word)) {
        return word;
      } else {
        return this.toCamelCase(numWords(word));
      }
    });

    return this.toCamelCase(filteredWords);
  }
}
