import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { DateFormatPipe } from 'ngx-moment';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { ClientManagementService } from 'src/app/services/utilities/client-management.service';
import { SettingsService } from 'src/app/services/utilities/settings.service';

@Component({
  selector: 'app-add-team-prop-modal',
  templateUrl: './add-team-prop-modal.component.html',
  styleUrls: ['./add-team-prop-modal.component.scss']
})
export class AddTeamPropModalComponent implements OnInit {

  //incoming 
  public eventID: number;
  public teamProps: any;
  public teamID: number;

  public props: any = [];
  public props_names: any = [];

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another Class",
    retry: "Retry",
  };

  public eventPropForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Schedule a Class for the experience:",
  };
  public formState: string = "active";

  public propsForm = this.formBuilder.group({
    props_name: "",
  });
  public propFormGroup: UntypedFormGroup;

  searchText = new Subject();
  results: Observable<string[]>;
  
  public token: string;

  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    public eventPropFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private _settingsService: SettingsService,
    private formBuilder: UntypedFormBuilder,) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.propFormGroup = this.propsForm;
    this.retrieveEventProps();
    this.results = this.searchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value))
    );

  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.props_names.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }

  public retrieveEventProps() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
    };

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      '/team/' + this.teamID + '/schedule/props',
      'get',
      data,
      getOptions
    );

    eventProps.subscribe((response) => {
      
      this.props = response.props
      this.processEventProps();
      this.props.forEach((element) => {
        this.props_names.push(element.props.label);
      });
      this.results = this.searchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value))
      );
    })  
  }

  private processEventProps() {
    let temp_props = []
    this.props.forEach(element => {
      let index = this.teamProps.findIndex((x) => x.props.id === element.id);
      if(index == -1) {
        temp_props.push(element)
      }
    });
    
    this.props = temp_props;
  }

  public addEventProps() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      prop_id:this.getPropIdFromLabel(this.propsForm.value.props_name).props.id
    };

    let addedEventProp = this._xrPlatformRestService.restfulAPIQuery(
      '/schedule/' + this.eventID + '/prop',
      'post',
      data,
      getOptions
    );

    addedEventProp.subscribe((response) => {
      if (response.error != 418) {
        ;
        let outgoingData = {
          action: 'add',
          prop: response.prop
        }
        this.outgoing.next(outgoingData);
      }
    });
  }

  private getPropIdFromLabel(label: string) {
    let index = this.props.findIndex((x) => x.props.label === label);
    
    
    return this.props[index];
  }

}
