<div class="heading-content">
  <h4>{{labels.experience.plural | titlecase}}</h4>
</div>
<mdb-card class="experiences-all-card content-card">
  <div class="container-fluid" *ngIf="loading">
    <div class="row">
      <div class="col-12">
        <p class="loading-text">Loading...</p>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="!loading">
    <div class="row">
      <div class="col-4">
        <div class="md-form w-100 mr-2 experience-search">
          <!-- add model to the input for search -->
          <input
            class="form-control"
            id="search"
            type="search"
            name="searchExperiences"
            autocomplete="off"
            [(ngModel)]="searchText"
            mdbInput
          />
          <label for="search">Search {{labels.experience.singular | titlecase}} Names</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-group flex-wrap">
          <div
            *ngFor="let experience of experiences | filterBy: 'name' : searchText"
            [ngClass]="{ archived: experience.archived }"
          >
            <!-- incorporate search here -->
            <mdb-card>
              <!-- alignment of the cards -->
              <mdb-card-body>
                <!-- title -->
                <mdb-card-title>
                  <h5 class="mb-3">
                    {{ experience.name }}
                    <span *ngIf="experience.archived">(archived)</span>
                    <span *ngIf="this.user.role_type_id === 1">
                      ID: {{ experience.id }}
                    </span>
                  </h5>
                </mdb-card-title>
                <!-- text -->
                <mdb-card-text class="mb-0">
                  <ul class="list-unstyled">
                    <li class="experience-description">
                      <span class="font-weight-bold">Description: </span><br />
                      {{ experience.description }}
                    </li>
                    <li>
                      <span
                        *ngIf="
                          user.role_type_id === 1 || user.role_type_id === 2
                        "
                        class="color-theme-base-dark"
                        >Number of events:
                      </span>
                      <span
                        *ngIf="
                          user.role_type_id !== 1 && user.role_type_id !== 2
                        "
                        class="color-theme-base-dark"
                        >Your events:
                      </span>
                      <span class="color-theme-base-medium-dark">{{
                        experience.number_of_events
                      }}</span>
                    </li>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
              <mdb-card-footer class="text-right">
                <p class="font-small mb-0 view-experience-details">
                  View
                  <mdb-icon fas icon="eye" class="pl-1"></mdb-icon>
                </p>
              </mdb-card-footer>
              <a
                [routerLink]="['/client', clientCode, 'experiences', experience.id]"
                class="stretched-link"
              ></a>
            </mdb-card>
          </div>
          <div *ngIf="(experiences | filter: searchText).length < 1">
            <div class="row">
              <div class="col-12">
                <p>Experience not found...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mdb-card>
