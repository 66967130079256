import { Component, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from "@angular/forms";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { IMyOptions } from "ng-uikit-pro-standard";

import * as moment from "moment";
import "moment-timezone";
import { DateFormatPipe } from "ngx-moment";
import { map, startWith } from "rxjs/operators";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-schedule-group",
  templateUrl: "./schedule-group.component.html",
  styleUrls: ["./schedule-group.component.scss"],
  providers: [TitleCasePipe]
})
export class ScheduleGroupComponent implements OnInit {
  //persistent
  private token: string;
  public groupsIncoming: any[] = [];
  public groups: any[] = [];
  public scheduledUserGroups: any;
  public labels: any;

  //incoming
  public teamID: number;
  public experience: any;
  public user: any;
  public users: any;
  public targetGroup: any;
  public action: string;

  //feedback response
  public title = "Schedule Group";
  public errorMsg: string = "";
  public statusMsg: string = "";
  public actionMsg: string = "Schedule an entire group:";
  public formState: string = "active";

  //form vars
  public formLoading: boolean = false;
  public scheduleForm: UntypedFormGroup;
  public groupsSelect: any[];
  public viewOnly: { group: string; date: string; allGroupsAdded: boolean } = {
    group: null,
    date: null,
    allGroupsAdded: false,
  };
  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Add",
  };
  public preSelected: {
    id: number;
    name: string;
    date: any;
    schedule_id;
  } = {
    id: null,
    name: null,
    date: null,
    schedule_id: null,
  };

  //datepicker specific
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "ddd, d mmm yyyy",
  };

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  public groupNames = [];
  searchText = new Subject();
  results: Observable<string[]>;
  public groupForm = this.formBuilder.group({
    groupAutocomplete: ''
  });

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public scheduleGroupFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private formBuilder: UntypedFormBuilder,
    private TitleCase: TitleCasePipe
  ) {}

  ngOnInit() {
    this.scheduledUserGroups = this.experience.scheduled_user_groups;
    this.retrieveToken();
    this.retrieveLabels();
    this.buildPreselected();
    this.dynamicCopy();
    this.makeFormValidatingGroup();
    this.retrieveGroups();

    this.results = this.searchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value))
    );
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveGroups() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveGroups = this._xrPlatformRestService.retrieveEntityData(
      "team",
      this.teamID,
      options
    );

    retrieveGroups.subscribe(
      (result) => {
        this.groupsIncoming =
          result.meta === null || result.meta.groups === undefined
            ? []
            : result.meta.groups;
        this.groups = this.processGroupsLegacy(this.groupsIncoming);
        this.processGroupSelect();
      },
      (error) => {
        ;
      }
    );
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.groupNames.filter((item: string) => item.toLowerCase().includes(filterValue));
    return filtered;
  }

  private processGroupsLegacy(incomingGroups) {
    let groupsOut = [];

    incomingGroups.forEach((group) => {
      let thisGroup = {
        group_name: group.name,
        id: group.id,
        number_users: group.members.length,
        members: [],
      };

      if (!group.members.length) {
        groupsOut.push(thisGroup);
        return false;
      }

      group.members.forEach((member) => {
        let getMember = this.getUserData(this.users, member.id);
        let thisMember = !getMember.length ? member : getMember[0];

        thisGroup.members.push(thisMember);
      });

      groupsOut.push(thisGroup);
    });

    return groupsOut;
  }

  private processGroupSelect() {
    let groupsSelect = [];
    let existingGroups = this.experience.scheduled_user_groups;

    this.groups.forEach((group) => {
      let check = false;
      existingGroups.forEach((existing) => {
        if (existing.id === group.id) {
          check = true;
        }
      });

      if (check) return false;

      let thisGroupSelect = {
        value: group.id,
        label: group.group_name,
      };

      this.groupNames.push(group.group_name)

      groupsSelect.push(thisGroupSelect);
    });

    this.groupsSelect = groupsSelect;
    this.formLoading = true;
    this.viewOnly.allGroupsAdded =
      this.groups.length && !this.groupsSelect.length ? true : false;
  }

  private buildPreselected() {
    this.preSelected.name =
      this.targetGroup === undefined ? null : this.targetGroup.group_name;
    this.preSelected.id =
      this.targetGroup === undefined ? null : this.targetGroup.id;
    this.preSelected.date =
      this.targetGroup === undefined
        ? null
        : this.targetGroup.expiration === undefined
        ? new Date(this.experience.endDate)
        : new Date(this.targetGroup.expiration);
    this.preSelected.schedule_id =
      this.targetGroup === undefined ? null : this.targetGroup.schedule_id;

    this.viewOnly.group = this.targetGroup === undefined ? null : "disabled";
    this.viewOnly.date =
      this.action === undefined ||
      (this.action !== undefined && this.action === "add")
        ? null
        : this.action;
  }

  public dynamicCopy() {
    if (this.action) {
      switch (this.action) {
        case "update":
          this.actionMsg = `Update ${this.labels.userGroup.singular} for the following ${this.labels.event.singular}: `;
          this.btnLabel.main = "Update";
          break;
        case "delete":
          this.actionMsg =
            `Are you sure you want to delete the following ${this.labels.userGroup.singular} for this ${this.labels.event.singular}?`;
          this.btnLabel.main = "Delete";
          break;
        case "remove":
          this.actionMsg =
            `Are you sure you want to remove the ${this.labels.userGroup.singular} for the following ${this.labels.event.singular}? `;
          this.btnLabel.main = "Remove";
          break;
      }
    }
  }

  private makeFormValidatingGroup() {
    this.scheduleForm = new UntypedFormGroup({
      scheduledGroup: new UntypedFormControl(this.preSelected.id, Validators.required),
      scheduledDate: new UntypedFormControl(
        this.preSelected.date,
        Validators.required
      ),
      scheduleID: new UntypedFormControl(this.preSelected.schedule_id),
    });
  }

  get scheduledGroup() {
    return this.scheduleForm.get("scheduledGroup");
  }

  get scheduledDate() {
    return this.scheduleForm.get("scheduledDate");
  }

  public scheduleGroup() {
    this.errorMsg = "";
    this.formState = "processing";

    let incomingValues = this.scheduleForm.value;
    let body = {};
    let action = "new-scheduled-event";
    let today = moment();
    let todayFormatted =
      today.format("ddd, DD MMM YYYY HH:mm:ss") +
      " " +
      moment.tz(moment.tz.guess()).format("z");
    let endDateFormatted =
      incomingValues.scheduledDate +
      " 23:59:59 " +
      moment.tz(moment.tz.guess()).format("z");
    let formValues = {};

    let invitees = this.buildInvitees(incomingValues);

    if (this.targetGroup === undefined) {
      this.statusMsg = `<span class='loading-msg'>Adding ${this.TitleCase.transform(this.labels.userGroup.singular)}</span>`;
      formValues = {
        resource_type: "single_experience_user_group",
        resource_id: this.experience.id,
        event_name: this.experience.name,
        start_at: todayFormatted,
        end_at: endDateFormatted,
        invitees: invitees,
      };

      body = JSON.stringify(formValues);
    } else if (this.action === "update") {
      this.statusMsg =
        `<span class='loading-msg'>Updating ${this.TitleCase.transform(this.labels.userGroup.singular)}</span>`;
      formValues = {
        resource_type: "single_experience_user_group",
        resource_id: this.experience.id,
        start_at: todayFormatted,
        end_at: endDateFormatted,
      };

      body = JSON.stringify(formValues);

      action = this.targetGroup.schedule_id;
    } else if (this.action === "delete") {
      this.statusMsg = `<span class='loading-msg'>Removing ${this.TitleCase.transform(this.labels.userGroup.singular)}</span>`;
      body = {
        entity: "schedule",
        entity_id: this.targetGroup.schedule_id,
      };
      action = "delete";
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let scheduleGroup = this._xrPlatformRestService.scheduledExperiences(
      action,
      body,
      options
    );

    scheduleGroup.subscribe(
      (response) => {
        if (this.action === "update") {
          this.statusMsg = `${this.TitleCase.transform(this.labels.userGroup.singular)} successfully updated`;
        } else if (this.action === "delete") {
          this.statusMsg = `${this.TitleCase.transform(this.labels.userGroup.singular)} successfully removed`;
        } else {
          this.statusMsg = `${this.TitleCase.transform(this.labels.userGroup.singular)} added successfully`;
        }
        this.formState = "success";
        let outgoingData = {
          action: this.action === undefined ? "new" : this.action,
          scheduled_event: response.scheduled_event,
          user_id: this.targetGroup === undefined ? 0 : this.targetGroup.id,
        };
        this.outgoing.next(outgoingData);
      },
      (err) => {
        this.statusMsg = "";
        let errorMsg = JSON.parse(err._body);
        this.errorMsg = errorMsg.error;
      }
    );
  }

  public buildInvitees(incomingValues) {
    let invitees_out = [];

    let team = {
      invitee_type: "team",
      invitee_id: this.teamID,
    };
    let group = {
      invitee_type: "user_group",
      invitee_id: incomingValues.scheduledGroup,
    };
    invitees_out.push(team);
    invitees_out.push(group);

    let thisGroup = this.getGroupData(
      this.groups,
      incomingValues.scheduledGroup
    );

    if (thisGroup[0].members !== null) {
      thisGroup[0].members.forEach((member) => {
        let thisMember = {
          invitee_type: "user",
          invitee_id: member.id,
        };

        invitees_out.push(thisMember);
      });
    }

    return invitees_out;
  }

  public getUserData(users, user_id) {
    return users.filter((user) => {
      return user.id === user_id;
    });
  }

  private getGroupData(groups, group_id) {
    return groups.filter((group) => {
      return group.id === group_id;
    });
  }
}
