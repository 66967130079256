<div class="vendor-management-page page">
    <div class="row header-row">
        <div class="col-md-12">
            <div class="title-callout">
                <div class="callout-headers-wrapper">
                    <div class="callout-headers float-left">
                        <h2 class="h4">Vendor Management</h2>
                    </div>
                </div>
                <div class="callout-text-wrapper">
                    <p>
                        <span></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row body-row">
        <div class="col-md-12">
            <mdb-card class="vendor-management-card content-card">
                <mdb-tabset #staticTabs [buttonClass]="'classic-tabs'"
                    class="admin-tab-styles vendor-management-breackdown-tabs" [contentClass]="'remove-card'">
                    <mdb-tab heading="Surveys">
                        <div class="surveys-container">
                            <div class="row justify-content-between">
                                <div class="col-4">
                                    <div class="md-form w-100 mr-2 experience-search">
                                        <input class="form-control" id="search" type="search" [(ngModel)]="searchText"
                                            mdbInput />
                                        <label for="search">Search Surveys</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="btn-spacing text-right">
                                        <button *ngIf="!loading" class="theme-modal-button btn" type="button"
                                            (click)="openManageSurveyModal('Add', '', 0)" mdbBtnp mdbWavesEffect>
                                            <mdb-icon far icon="plus-square" class="pr-1"></mdb-icon>
                                            Add Survey
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="!loading">
                                <div class="card-group">
                                    <div *ngFor="let survey of surveys | filter: searchText;
                                    let i = index">
                                        <mdb-card>
                                            <mdb-card-body>
                                                <mdb-card-title>
                                                    <div class="row">
                                                        <h5 class="mb-3">{{ survey.label }}</h5>
                                                        <a class="activator px-3 mr-6">
                                                            <mdb-icon fas icon="users"
                                                                (click)="openViewSurveyModal(survey, i)">
                                                            </mdb-icon>
                                                            <mdb-icon far
                                                                (click)="openManageSurveyModal('Edit', survey, i)"
                                                                icon="edit"></mdb-icon>
                                                            <mdb-icon fas icon="archive"
                                                                (click)="openArchiveModal('survey', survey, i)">
                                                            </mdb-icon>
                                                        </a>
                                                    </div>
                                                </mdb-card-title>
                                                <mdb-card-text class="mb-0">
                                                    <div class="d-flex flex-row">
                                                        <ul class="list-unstyled">
                                                            <li>
                                                                Name: <b>{{ survey.name }}</b>
                                                            </li>
                                                            <li>
                                                                Description <b>{{ survey.description }}</b>
                                                            </li>
                                                            <li>
                                                                System <b>{{ survey.system }}</b>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </mdb-card-text>
                                            </mdb-card-body>
                                            <mdb-card-footer> </mdb-card-footer>
                                        </mdb-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mdb-tab>
                    <mdb-tab heading="Questions">
                        <div class="questions-container">
                            <div class="row justify-content-between">
                                <div class="col-4">
                                    <div class="md-form w-100 mr-2 experience-search">
                                        <input class="form-control" id="search" type="search" [(ngModel)]="searchQuestionText"
                                            mdbInput />
                                        <label for="search">Search Questions</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="btn-spacing text-right">
                                        <button *ngIf="!loading" class="theme-modal-button btn" type="button"
                                            (click)="openManageQuestionModal('add', '', 0)" mdbBtnp mdbWavesEffect>
                                            <mdb-icon far icon="plus-square" class="pr-1"></mdb-icon>
                                            Add Question
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="!questionsLoading">
                                <div class="card-group">
                                    <div *ngFor="let question of questions | filter: searchQuestionText;
                                    let i = index">
                                        <mdb-card>
                                            <mdb-card-body>
                                                <mdb-card-title>
                                                    <div class="row">
                                                        <h5 class="mb-3">{{ question.label }}</h5>
                                                        <a class="activator px-3 mr-6">
                                                            <mdb-icon fas icon="users" *ngIf="question.constrained"
                                                                (click)="openViewQuestionModal(question, i)">
                                                            </mdb-icon>
                                                            <mdb-icon far
                                                                (click)="openManageQuestionModal('Edit', question, i)"
                                                                icon="edit"></mdb-icon>
                                                            <mdb-icon fas icon="archive"
                                                                (click)="openArchiveModal('question', question, i)">
                                                            </mdb-icon>
                                                        </a>
                                                    </div>
                                                </mdb-card-title>
                                                <mdb-card-text class="mb-0">
                                                    <div class="d-flex flex-row">
                                                        <ul class="list-unstyled">
                                                            <li>
                                                                Name: <b>{{ question.name }}</b>
                                                            </li>
                                                            <li>
                                                                Type <b>{{ question.question }}</b>
                                                            </li>
                                                            <li>
                                                                Type <b>{{ question.type }}</b>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </mdb-card-text>
                                            </mdb-card-body>
                                            <mdb-card-footer> </mdb-card-footer>
                                        </mdb-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mdb-tab>
                </mdb-tabset>
            </mdb-card>
        </div>
    </div>
</div>