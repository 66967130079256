<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 text-center">
        <h5>Select {{labels.user.singular | titlecase}} Speaking Breakdown</h5>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 d-flex justify-content-center align-items-center flex-row loading-height"
        *ngIf="speakingLoading"
      >
        <div
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12">
        <div style="
            position: relative;
            height: 50vh;
            width: auto;
            margin-top: 2rem;
            margin-bottom: 3rem;
          ">
          <canvas #speakingChart> </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
