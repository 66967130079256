<!-- Modal Content -->
<p-confirmDialog
  [key]="'deleteConfirm'"
  class="delete-confirm"
  #deleteConfirm
  [style]="{ width: '30rem' }"
>
  <ng-template pTemplate="header">
    <div class="modal-header text-center">
      <h4 class="modal-title w-100">Delete List?</h4>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="modal-footer">
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button danger btn"
        (click)="deleteConfirm.accept()"
      >
        Delete List
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn"
        (click)="deleteConfirm.reject()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4
      class="modal-title w-100 font-weight-bold"
      [ngClass]="{ removeTitle: action === 'delete' }"
    >
      <span>{{ action | titlecase }} Skill</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="skillFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <ng-container *ngIf="action === 'archive'">
        <div class="row">
          <div class="col-12">
            <p class="font-16">Archive this skill?</p>
            <ul class="list-unstyled">
              <li>
                <span class="bold">Name:</span>
                <strong>{{ targetSkill.label }}</strong>
              </li>
              <li>
                <span class="bold">Organization:</span>
                <strong>{{ targetSkill.organization }}</strong>
              </li>
              <li>
                <span class="bold">Description:</span>
                <strong>{{ targetSkill.description }}</strong>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="action !== 'archive'">
        <div class="row">
          <form [formGroup]="skillForm">
            <div class="row p-0">
              <div class="col-12">
                <p>
                  <span class="action-msg-small">Required*</span>
                </p>
              </div>
            </div>
            <div class="md-form">
              <input
                type="text"
                id="skillLabel"
                class="form-control"
                formControlName="label"
                mdbInput
              />
              <label for="skillLabel">* Name</label>
              <mdb-error
                *ngIf="
                  skillForm.controls.label.invalid &&
                  (skillForm.controls.label.dirty ||
                    skillForm.controls.label.touched)
                "
              >
                Skill name is required
              </mdb-error>
            </div>
            <div class="md-form">
              <input
                type="text"
                id="skillOrganization"
                class="form-control"
                formControlName="organization"
                mdbInput
              />
              <label for="skillOrganization">Organization</label>
            </div>
            <div class="md-form">
              <mdb-select
                [options]="targetUserOptions"
                id="target-user"
                class="target-user"
                formControlName="target_user"
                mdbInput
                mdbValidate
                placeholder="Select Target User"
              ></mdb-select>
            </div>
            <div class="md-form">
              <input
                type="text"
                id="skillDescription"
                class="form-control"
                formControlName="description"
                mdbInput
              />
              <label for="skillDescription">Description</label>
            </div>
            <div class="md-form skill-pdf">
              <input
                hidden="true"
                type="text"
                id="skillAssetID"
                class="form-control"
                formControlName="asset_id"
                mdbInput
              />
              <button
                class="theme-modal-button btn"
                type="button"
                mdbBtnp
                mdbWavesEffect
                (click)="openSelectMediaModalForProp()"
              >
                <fa-icon [icon]="faCirclePlus"></fa-icon>
                <span>{{ skillMedia ? "Update" : "Add" }} Skill PDF</span>
              </button>
              <fa-icon [icon]="faFilePdf"></fa-icon>
              <span
                class="ellipsis-wrapper skill-media-thumb"
                *ngIf="skillMedia"
                ><span class="text-ellipsis">{{ skillMedia.name }}</span></span
              >
              <span class="skill-media-thumb" *ngIf="!skillMedia"
                >No PDF selected</span
              >
            </div>
          </form>
        </div>
        <div class="row" *ngIf="targetSkill">
          <div class="col-12">
            <mdb-card class="management-card content-card w-100">
              <mdb-card-header class="d-flex justify-content-between"
                ><span>Word Lists</span
                ><span
                  ><button
                    (click)="managePhonemeLists('add')"
                    class="theme-modal-button btn"
                  >
                    Add Word List +
                  </button></span
                ></mdb-card-header
              >
              <mdb-card-body>
                <ng-container *ngIf="!targetSkill.phoneme_lists.length"
                  >No word lists added yet -
                  <button
                    (click)="managePhonemeLists('add')"
                    class="btn btn-link"
                  >
                    add a word list now
                  </button></ng-container
                >
                <ng-container *ngIf="targetSkill.phoneme_lists.length"
                  ><ul>
                    <li *ngFor="let phoneme of targetSkill.phoneme_lists">
                      {{ phoneme.phoneme_list.label }}
                      <div class="btn-group list-btns-sm btn-icons">
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="initiateDeleteList(phoneme.phoneme_list)"
                          mdbTooltip="Delete List"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faTrashAlt"></fa-icon>
                        </a>
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="
                            managePhonemeLists('update', phoneme.phoneme_list)
                          "
                          mdbTooltip="Delete List"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </a>
                      </div>
                    </li></ul
                ></ng-container>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      data-dismiss="modal"
      (click)="manageSkill()"
      class="theme-modal-button btn"
    >
      {{ action | titlecase }} Skill
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="skillFrame.hide()"
    >
      Cancel
    </button>
  </div>
</div>
