import { Subject } from "rxjs";
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import { MDBModalRef, PopoverDirective } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";

import * as moment from "moment";
import "moment-timezone";

import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { DOCUMENT, TitleCasePipe } from "@angular/common";
import { EventMediaVersion3Service } from "src/app/modules/event-management/services/event.media.version.3.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "manage-event-media",
  templateUrl: "./manage-event-media.component.html",
  styleUrls: ["./manage-event-media.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class ManageEventMediaComponent implements OnInit {
  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  //fa icons
  public faTimes = faTimes;

  public labels: any;
  private token: string;
  public media: any;
  public tz: any = moment.tz(moment.tz.guess()).format("z");

  //incoming
  public teamID: number;
  public targetEvent: any;
  public action: string;
  public attached: any;

  //start legacy
  public type: string;
  public amount: string;
  //end legacy

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  //feedback response
  public title = "";
  public errorMsg: string = "";
  public statusMsg: string = "";
  public actionMsg: string = "Add or remove media from class";
  public formState: string = "active";
  public formLoading: boolean = false;
  public isClean: boolean = true;

  //form vars
  public scheduleForm: any = { myOptionsArray: [] };
  public mediaSelect: any[];
  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Update",
  };

  //start legacy
  public pdfOptionsGroup: any;
  public imageOptionsGroup: any;
  public videoOptionsGroup: any;
  public existingValues: {
    pdf: any;
    image: any;
    video: any;
    pdfSrc: string;
    imgSrc: string;
    videoSrc: string;
  } = {
      pdf: "",
      image: "",
      video: "",
      pdfSrc: "",
      imgSrc: "",
      videoSrc: "",
    };
  //end legacy

  //media handling
  public selectedMedia: {
    name: string;
    date: string;
    meta: any;
    thumbnail_url: string;
    file_ext: string;
    id: number;
  };

  // thumbnails
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpeg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";

  public actionButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
      info: false,
      share: false,
      delete: false,
    };

  public columnVisibility: {
    thumbnail: boolean;
    pdfStatus: boolean;
    fileType: boolean;
    owner: boolean;
    urlType: boolean;
    dateCreated: boolean;
    shares: boolean;
  } = {
      thumbnail: true,
      pdfStatus: false,
      fileType: true,
      owner: true,
      urlType: false,
      dateCreated: true,
      shares: false,
    };

  public selectable: boolean = true;

  public filterByType: string = "all";

  public selectableType: string = "multiple";

  public clickableThumb: boolean = true;

  public systemButtons: any = {
    action: null,
    cancel: null,
  };

  public systemInteraction: string = "event_media";

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _mediaManagementService: MediaManagementServicesService,
    public manageMediaFrame: MDBModalRef,
    private TitleCase: TitleCasePipe,
    private eventMediaVersion3Service: EventMediaVersion3Service,
    private _notificationService: NotificationsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    if (this.attached === undefined) this.attached = [];

    console.log("this.attached", this.attached);

    this.selectedMedia = {
      name: "",
      date: "",
      meta: {},
      thumbnail_url: "",
      file_ext: "",
      id: 0,
    };

    this.retrieveLabels();
    this.title =
      "Manage " + this.labels.event.singular + " " + this.labels.media.plural;
    this.retrieveToken();
    this.dynamicCopy();
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private dynamicCopy() {
    if (this.action === "add") {
      this.btnLabel.main = `Save and return to ${this.labels.event.singular}`;
    } else if (this.action === "update") {
      this.btnLabel.main = `Update and return to ${this.labels.event.singular}`;
    }
  }

  public async manageMedia() {
    if (this.targetEvent === undefined || this.targetEvent === null) {
      this.sendMediaDataBack();
      return;
    }

    if (this.isClean) {
      this.sendMediaDataBack();
      return;
    }

    let notification = this._notificationService.savingNotification(
      "Updating Event Media..."
    );

    let eventMedia = await this.eventMediaVersion3Service
      .manageMedia(
        this.token,
        this.targetEvent,
        this.setupSelectedMedia(),
      ).catch((err) => {
        this._notificationService.clearNotification(notification);
        this.errorMsg = "There was an error updating the media.";
      });

    if (eventMedia && eventMedia.status === "success") {
      this._notificationService.clearNotification(notification);
      this.sendMediaDataBack();
    }
  }

  private async sendMediaDataBack() {
    let data = {
      action: "update_event_media",
      finalSelectedMedia: this.attached,
    };

    this.outgoing.next(data);
    this.isClean = true;
    this.closeModal();
  }

  private getMediaItem(media) {
    let getMediaItem = this._mediaManagementService.retrieveMediaItem(
      media.uuid,
      this.token
    );

    return getMediaItem.toPromise();
  }

  public hideStatus(type) {
    if (type === "error") {
      this.errorMsg = "";
    } else {
      this.statusMsg = "";
    }
  }

  public resetMedia(type) {
    if (type === "pdf") {
      this.existingValues.pdf = "";
      this.existingValues.pdfSrc = "";
      this.scheduleForm.controls["pdfOptionsValue"].setValue("");
    } else if (type === "image") {
      this.existingValues.image = "";
      this.existingValues.imgSrc = "";
      this.scheduleForm.controls["imageOptionsValue"].setValue("");
    } else if (type === "video") {
      this.existingValues.video = "";
      this.existingValues.videoSrc = "";
      this.scheduleForm.controls["videoOptionsValue"].setValue("");
    }
  }

  public onParentActions(incoming) {

    console.log("incoming in onParentActions", incoming);

    if (incoming.action === "update_event_media") {
      this.media = incoming.media;
      this.attached = incoming.attached;

      this.isClean = false;
    }

    if (incoming.action === "closeModal") {
      this.closeModal();
    }
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("secondary");
      mainEventDisplay.item(0).classList.add("restore-primary");
    }

    this.manageMediaFrame.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  private setupSelectedMedia() {
    let temp_selected = [];

    console.log("this.attached in setupSelectedMedia", this.attached);

    this.attached.forEach((element) => {
      temp_selected.push({
        id: element.id
      });
    });

    return temp_selected;
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}
