import { environment } from "./../../../../../../environments/environment";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";

import { CoolLocalStorage } from "@angular-cool/storage";
import { MDBModalRef } from "ng-uikit-pro-standard";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { TitleCasePipe } from "@angular/common";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

@Component({
  selector: "app-manage-app-bundles",
  templateUrl: "./manage-app-bundles.component.html",
  styleUrls: ["./manage-app-bundles.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class ManageAppBundlesComponent {
  //incoming
  public teamID: number;
  public type: string;
  public targetBundle: any;
  public action: string;
  public data: any;

  private preSelected: {
    label: string;
    name: string;
    description: string;
    bundle_id: string;
    environment: string;
  };

  public manageAppBundleForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public formState: string = "active";
  public token: string;
  private outgoing: Subject<any> = new Subject();

  //icons
  public faTimes = faTimes;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public appBundleFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService,
    private TitleCase: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.buildPreselected();
    this.makeValidatingForm();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private buildPreselected() {
    if (!this.targetBundle) {
      this.preSelected = {
        label: "",
        name: "",
        description: "",
        bundle_id: "",
        environment: "",
      };
    } else {
      this.preSelected = {
        label: this.targetBundle.label,
        name: this.targetBundle.name,
        description: this.targetBundle.description,
        bundle_id: this.targetBundle.bundle_id,
        environment: this.targetBundle.environment,
      };
    }
  }

  private makeValidatingForm() {
    this.manageAppBundleForm = new UntypedFormGroup({
      label: new UntypedFormControl(
        this.preSelected.label,
        Validators.required
      ),
      name: new UntypedFormControl(this.preSelected.name, Validators.required),
      description: new UntypedFormControl(this.preSelected.description),
      bundle_id: new UntypedFormControl(
        this.preSelected.bundle_id,
        Validators.required
      ),
      environment: new UntypedFormControl(
        this.preSelected.environment,
        Validators.required
      ),
    });
  }

  //getters for form controls
  get label() {
    return this.manageAppBundleForm.controls.label;
  }

  get name() {
    return this.manageAppBundleForm.controls.name;
  }

  get bundle_id() {
    return this.manageAppBundleForm.controls.bundle_id;
  }

  get environment() {
    return this.manageAppBundleForm.controls.environment;
  }

  public manageAppBundle() {
    let actionAdj = this.action.replace(/e$/, "");
    let notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing ${this.type}...`
    );

    let outbound = {
      label: this.manageAppBundleForm.value.label,
      name: this.manageAppBundleForm.value.name,
      description: this.manageAppBundleForm.value.description,
      bundle_id: this.manageAppBundleForm.value.bundle_id,
      environment: this.manageAppBundleForm.value.environment,
      archived: this.targetBundle ? this.targetBundle.archived : false,
    };

    if (this.action === "delete") outbound.archived = true;

    //determine the type of request
    let requestType =
      this.action === "edit" || this.action === "delete" ? "put" : "post";
    let requestURL =
      requestType === "put"
        ? `/app-bundle/${this.targetBundle.id}`
        : "/app-bundle";

    //user xrplatformrest service to make the request
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let manageBundle = this._xrPlatformRestService.restfulAPIQuery(
      requestURL,
      requestType,
      outbound,
      options
    );

    manageBundle.subscribe(
      (response: any) => {
        //clear notification
        this._notificationService.clearNotification(notification);

        this._notificationService.successNotification(
          `${this.TitleCase.transform(actionAdj)}ed ${this.type}!`
        );
        this.outgoing.next(response);
        this.appBundleFrame.hide();
      },
      (error: any) => {
        //clear notification
        this._notificationService.clearNotification(notification);

        this._notificationService.errorNotification(
          `Error while ${this.TitleCase.transform(actionAdj)}ing ${
            this.type
          }. Please try again or contact support`
        );
        this.outgoing.next(error);
        this.appBundleFrame.hide();
      }
    );
  }
}
