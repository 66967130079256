import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  OnChanges
} from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";

import { faTimes, faPencil, faSave } from "@fortawesome/free-solid-svg-icons";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-text-inline-edit",
  templateUrl: "./text-inline-edit.component.html",
  styleUrls: ["./text-inline-edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ height: 0, opacity: 0 }),
        animate("1s ease-out", style({ height: 46, opacity: 1 })),
      ]),
      transition(":leave", [
        style({ height: 46, opacity: 1 }),
        animate("1s ease-in", style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class TextInlineEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input("data") data: string;
  @Input("type") type: string = "text";
  @Input("exclude") exclude: string = "";
  @Input("name") name: string;
  @Input("label") label: string;
  @Input("selectOptions") selectOptions: { value: string; label: string }[];
  @Input("disabled") disabled: boolean = false;
  @Output() saveData = new EventEmitter<string>();
  public editMode = false;
  private legacyValue = "";
  public value: string = "";

  //subscriptions
  private shareDataSubscription: Subscription;

  //fa icons
  public faTimes = faTimes;
  public faPencil = faPencil;
  public faSave = faSave;

  constructor(private _sharedDataService: SharedDataService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {

    console.log("this.data in text-inline-edit init", this.data);

    this.configureByType();

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {

        console.log("incoming", incoming)

        if (incoming.type === "editSuccesful") {
          if (incoming.data.name === this.name) {
            this.data = incoming.data.changed;
            this.exclude = incoming.data.exclude;
            this.configureByType();
            this.editMode = false;
          }
        }
      });
  }

  ngOnChanges() {
    console.log("this.data in text-inline-edit changes", this.data);
    this.configureByType();
  }

  private configureByType() {
    switch (this.type) {
      case "select":
        if (this.selectOptions === undefined || this.selectOptions === null) this.selectOptions = [];

        this.value = this.selectOptions.find((x) => x.value === this.data).label;

        break;
      default:
        if (this.exclude === undefined || this.exclude === null) this.exclude = "";
        if (this.data === undefined || this.data === null) this.data = "";

        console.log("this.exclude in configureByType", this.exclude);

        this.data = this.data.replace(this.exclude, "");

        console.log("this.data in configureByType", this.data);

        this.value = this.data;
    }

  }

  ngOnDestroy(): void {
    this.shareDataSubscription.unsubscribe();
  }

  onFocus(e) {
    this.legacyValue = this.data;
  }

  onCancel() {
    //this.data = this.legacyValue;
    this.editMode = false;
  }

  onSave() {
    this.saveData.emit(this.data);
  }

  public onKeyboardEnter(event: KeyboardEvent) {
    if (event.key === "Enter") {
      const target = event.target as HTMLElement;
      target.blur();
    }
  }
}
