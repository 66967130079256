import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { Subject } from "rxjs";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";

@Component({
  selector: 'app-manage-media-change',
  templateUrl: './manage-media-change.component.html',
  styleUrls: ['./manage-media-change.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageMediaChangeComponent {
  //persistent
  private token: string;
  public labels: any;

  //incoming
  public targetMedia: any; //incoming media object
  public teamID: number;
  public values: { [key: string]: any } = {};
  public title: string = "Update Media";
  public message: string = "Update the media details below and click 'Update' to save changes.";
  public button: string = "Update";

  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Add",
  };

  //data back to the parent
  private outgoing: Subject<any> = new Subject();

  //media handling
  public clientSettings: any;

  constructor(
    public mediaFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService,
    private _sharedDataService: SharedDataService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit() {
    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.retrieveToken();
    this.retrieveLabels();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  public async manageMedia() {

    let notification = this._notificationService.savingNotification(
      `Updating ${this.labels.media.singular}...`
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let updateProp = this._xrPlatformRestService
      .restfulAPIQuery(
        "/asset/" + this.targetMedia.uuid,
        "put",
        this.values,
        options
      )
      .toPromise();

    let updateResult = await updateProp.catch((err) => {
      console.log("err in runUpdate", err);
      this._notificationService.clearNotification(notification);
      this._notificationService.errorNotification("There was an issue updating this media. Please try again.");
    });

    let outgoingData = {
      action: "update",
      changedData: updateResult,
    };

    this.outgoing.next(outgoingData);
    this._notificationService.clearNotification(notification);
    this.mediaFrame.hide();
  }
}
