import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { Subject } from "rxjs";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";

@Component({
  selector: 'app-clone-skill',
  templateUrl: './clone-skill.component.html',
  styleUrls: ['./clone-skill.component.scss']
})
export class CloneSkillComponent {
  //persistent
  private token: string;
  public labels: any;

  //incoming
  public targetSkill: any; //incoming media object
  public action: string;
  public values: { [key: string]: any } = {};
  public title: string = "Clone Skill";
  public message: string = "Clone this skill?";
  public button: string = "Clone";

  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Add",
  };

  //data back to the parent
  private outgoing: Subject<any> = new Subject();

  constructor(
    public cloneFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService,
    private _sharedDataService: SharedDataService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit() {
    this.retrieveToken();
    this.retrieveLabels();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  public async cloneSkill(){
    let notification = this._notificationService.savingNotification(
      `Cloning skill...`
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let cloneSkill = this._xrPlatformRestService.restfulAPIQuery(
      `/authoring/skill/${this.targetSkill.id}/clone`,
      "get",
      {},
      options
    ).toPromise();

    let cloneResult = await cloneSkill.catch((error) => {
      this._notificationService.clearNotification(notification);
      this._notificationService.errorNotification(`There was an issue cloning this skill. Please try again.`);
    });

    let new_skill = cloneResult.skill;

    let outgoingData = {
      action: this.action,
      changedData: new_skill
    }

    this.outgoing.next(outgoingData);
    this._notificationService.clearNotification(notification);
    this.cloneFrame.hide();
  }

}
