import { AfterViewInit, Component, ElementRef, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { TitleCasePipe } from "@angular/common";

import * as moment from "moment";
import "moment-timezone";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { UserServicesService } from "src/app/modules/user-management/services/user-services.service";

@Component({
  selector: "app-schedule-user-to-class",
  templateUrl: "./schedule-user-to-class.component.html",
  styleUrls: ["./schedule-user-to-class.component.scss"],
  providers: [TitleCasePipe],
})
export class ScheduleUserToClassComponent implements OnInit, AfterViewInit {
  public confirmOn = false;
  public mainModal: any;
  public labels: any;
  private token: string;
  public teamID: number;
  private users: any;
  private outgoing: Subject<any> = new Subject();
  public existingUsers: any;
  public clientSettings: any;

  public targetEvent: any;
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another User",
    retry: "Retry",
  };
  public continueType: string;

  public scheduleForm: UntypedFormGroup;
  public selectUsers: any[];

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Schedule a User for this class:",
  };
  public formState: string = "active";

  userSearchText = new Subject();
  userResults: Observable<string[]>;
  private userNames: string[] = [];

  roleSearchText = new Subject();
  roleResults: Observable<string[]>;
  private roleNames: string[] = [];
  private roles: any;

  personaSearchText = new Subject();
  personaResults: Observable<string[]>;
  private personaNames: string[] = [];
  private personas: any;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _userServices: UserServicesService,
    public scheduleFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private TitleCase: TitleCasePipe
  ) {}

  ngOnInit() {
    console.log("this.targetEvent", this.targetEvent);
    this.existingUsers = JSON.parse(
      JSON.stringify(this.targetEvent.scheduled_users)
    );
    this.retrieveLabels();
    this.msgs.actionMsg =
      this.labels.schedule.singular +
      " a " +
      this.labels.user.singular +
      " for this " +
      this.labels.event.singular;
    this.btnLabel.main = this.labels.schedule.singular;
    this.btnLabel.reset = "Add Another " + this.labels.user.singular;
    this.retrieveToken();
    this.retrieveUsers();
    this.retrieveRoles();
    this.retrievePersonas();
    this.makeFormValidatingGroup();

    this.userResults = this.userSearchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value, this.userNames))
    );

    this.roleResults = this.roleSearchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value, this.roleNames))
    );

    this.personaResults = this.personaSearchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value, this.personaNames))
    );
  }
  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private filter(value: string, names: string[]): string[] {
    if (value === null) value = "";

    const filterValue = value.toLowerCase();
    let filtered = names.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }

  private retrieveUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options
    );

    retrieveUsers.subscribe(
      (response) => {
        this.users = response;
        ;
        this.processUsers(response);
      },
      (error) => {
        ;
      }
    );
  }

  private retrieveRoles() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let roles_request = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/role",
      "get",
      {},
      options
    );
    roles_request.subscribe((params) => {
      ;
      this.roles = params.roles;
      this.roles.forEach((element) => {
        this.roleNames.push(element.name);
      });
      this.roleResults = this.roleSearchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value, this.roleNames))
      );
    });
  }

  private retrievePersonas() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let personas_request = this._xrPlatformRestService.restfulAPIQuery(
      "/personas/findpersonasbyclient/" + this.teamID,
      "get",
      {},
      options
    );
    personas_request.subscribe((params) => {
      ;
      this.personas = params.personas;
      this.personas.forEach((element) => {
        this.personaNames.push(element.persona_name);
      });
      this.personaResults = this.personaSearchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value, this.personaNames))
      );
    });
  }

  private processUsers(response) {
    let usersSelect = [];

    response.forEach((element) => {
      let check = false;
      this.existingUsers.forEach((existing) => {
        if (existing.id === element.id) {
          check = true;
        }
      });

      if (check == false) {
        this.userNames.push(element.username);
      }

      if (check) return false;

      let thisUserSelect = {
        value: element.id,
        label: element.first_name + " " + element.last_name,
      };

      usersSelect.push(thisUserSelect);
    });
    this.userResults = this.userSearchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value, this.userNames))
    );

    this.selectUsers = usersSelect;
  }

  private makeFormValidatingGroup() {
    this.scheduleForm = new UntypedFormGroup({
      scheduleUser: new UntypedFormControl(null, Validators.required),
    });
  }

  get scheduleUser() {
    return this.scheduleForm.get("scheduleUser");
  }

  public scheduleUsers() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = `<span class='loading-msg'>Adding ${this.TitleCase.transform(
      this.labels.user.singular
    )}</span>`;
    this.formState = "processing";

    let incomingValues = this.scheduleForm.value;
    let user_id = this.getUserId(incomingValues.scheduleUser);
    let role_id = this.getRoleId(incomingValues.userRole);
    let persona_id = this.getPersonaId(incomingValues.userPersona);
    ;
    ;
    let body = {};
    let formValues = [];

    formValues = [
      {
        scheduled_event_id: this.targetEvent.schedule_id,
        invitee_type: "user",
        invitee_id: user_id,
        role_id: role_id,
        persona_id: persona_id,
        meta: {
          individual: true,
        },
      },
    ];

    body = JSON.stringify(formValues);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
      schedule_id: this.targetEvent.schedule_id,
    };

    //if user already exists via a group, we need to update meta
    let retrieveScheduledEvent = this._userServices.retrieveScheduledEventByID(
      this.targetEvent.schedule_id,
      options
    );

    retrieveScheduledEvent
      .then((response) => {
        let scheduled_event = response.scheduled_event;

        if (scheduled_event.invitees.length) {
          let hit = false;

          scheduled_event.invitees.forEach((invitee) => {
            if (
              invitee.invitee_id === incomingValues.scheduleUser &&
              invitee.invitee_type === "user"
            ) {
              if (
                invitee.meta !== undefined &&
                invitee.meta !== null &&
                invitee.meta.groups !== undefined &&
                invitee.meta.groups.length
              ) {
                hit = true;
                let meta = invitee.meta;
                meta.individual = true;

                let updateBody = [
                  {
                    scheduled_event_id: this.targetEvent.schedule_id,
                    invitee_type: "user",
                    invitee_id: incomingValues.scheduleUser,
                    meta: meta,
                  },
                ];

                this.updateUserMeta(
                  updateBody,
                  this.targetEvent.schedule_id,
                  user_id,
                  options
                );
              }
            }
          });

          if (!hit) this.addUsersToClass(body, options, user_id);
        } else {
          this.addUsersToClass(body, options, user_id);
        }
      })
      .catch ((error) => {
        ;
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = `There was an issue scheduling this ${this.labels.user.singular}, please try again.`;
      });
  }

  private updateUserMeta(toUpdate, entity_id, user_id, options) {
    let body = {
      entity: "schedule",
      entity_id: entity_id,
      toUpdate: toUpdate,
    };

    let updateUserinGroup = this._xrPlatformRestService.scheduledExperiences(
      "invitee-meta-update",
      body,
      options
    );

    updateUserinGroup.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(
          this.labels.user.singular
        )} successfully added to class`;
        this.formState = "success";
        let outgoingData = {
          action: "scheduled-invitee",
          scheduled_event: response.scheduled_event,
          experience: this.targetEvent,
          user_id: user_id,
          users: this.users,
        };
        this.outgoing.next(outgoingData);

        let addedUser = {
          id: user_id,
        };
        this.existingUsers.push(addedUser);
        this.retrieveUsers();
      },
      (err) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
        ;
      }
    );
  }

  private addUsersToClass(body, options, user_id) {
    let scheduleUser = this._xrPlatformRestService.scheduledExperiences(
      "schedule-invitee",
      body,
      options
    );

    scheduleUser.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(
          this.labels.user.singular
        )} successfully added to class`;
        this.formState = "success";
        let outgoingData = {
          action: "scheduled-invitee",
          scheduled_event: response.scheduled_event,
          experience: this.targetEvent,
          user_id: user_id,
          users: this.users,
        };
        this.outgoing.next(outgoingData);
        let addedUser = {
          id: user_id,
        };
        this.existingUsers.push(addedUser);
        this.retrieveUsers();
      },
      (err) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
        ;
      }
    );
  }

  private getUserId(username: string) {
    let index = this.users.findIndex((x) => x.username === username);
    return this.users[index].id;
  }

  private getRoleId(rolename: string) {
    let index = this.roles.findIndex((x) => x.name === rolename);
    if (index == -1) {
      return null;
    }
    return this.roles[index].id;
  }

  private getPersonaId(personaname: string) {
    let index = this.personas.findIndex((x) => x.persona_name === personaname);
    if (index == -1) {
      return null;
    }
    return this.personas[index].id;
  }

  public resetForm() {
    this.scheduleForm.reset();
    this.formState = "active";
    this.userNames = [];
    this.processUsers(this.users);
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.formState = "active";
  }

  public handleIncomingAction(action) {
    ;
    switch (action) {
      case "close-modal":
        this.scheduleFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }
  public turnConfirmOn(frame) {
    this.confirmOn = true;

    this.mainModal.classList.add("overlay");

    frame.show();
  }

  public closeConfirm(frame) {
    this.confirmOn = false;
    this.mainModal.classList.remove("overlay");

    frame.hide();
  }
  public closeAllModals(frame) {
    this.confirmOn = false;
    frame.hide();
    this.scheduleFrame.hide();
  }
}
