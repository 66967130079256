import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";

import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import {
  IMyOptions,
  MDBModalRef,
  MDBModalService,
  MdbTableDirective,
  MdbTablePaginationComponent,
  ToastService,
} from "ng-uikit-pro-standard";

import { Subject, Subscription, from, of } from "rxjs";
import { debounceTime, catchError, map } from "rxjs/operators";

import { CompoundFilterPipe } from "src/app/modules/shared-modules/pipes/compound-filter.pipe";
import { SingleSortPipe } from "src/app/modules/shared-modules/pipes/single-sort.pipe";

import * as moment from "moment";

import { SettingsService } from "src/app/services/utilities/settings.service";
import { EventSettingsModalComponent } from "src/app/modules/experience-management/modals/event-settings-modal/event-settings-modal.component";
import { UserServicesService } from "src/app/modules/user-management/services/user-services.service";

import {
  faFilter,
  faFilterCircleXmark,
  faSquareMinus,
  faPencilAlt,
  faImages,
  faUsers,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faCopy,
  faTimes,
  faClone
} from "@fortawesome/free-solid-svg-icons";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { ManageEventComponent } from "../../../modals/manage-event/manage-event.component";
import { ManageEventMediaComponent } from "../../../modals/manage-event-media/manage-event-media.component";
import { ManageEventRosterComponent } from "../../../modals/manage-event-roster/manage-event-roster.component";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { CloneEventComponent } from "../../../modals/clone-event/clone-event.component";
import { TitleCasePipe } from "@angular/common";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-events-view-table",
  templateUrl: "./events-view-table.component.html",
  styleUrls: ["./events-view-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [CompoundFilterPipe, SingleSortPipe, TitleCasePipe],
})
export class EventsViewTableComponent implements OnInit, OnDestroy {
  @Input() clientSettings: any;

  private mdbTableData: MdbTableDirective;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTableDirective, { static: false }) set Content(
    content: MdbTableDirective
  ) {
    if (content) {
      this.mdbTableData = content;
      this.mdbTableData.setDataSource(this.events);
    }
  }

  @ViewChild("pagination") pagination: any;

  //fa icons
  public faFilter = faFilter;
  public faFilterCircleXmark = faFilterCircleXmark;
  public faSquareMinus = faSquareMinus;
  public faPencilAlt = faPencilAlt;
  public faImages = faImages;
  public faUsers = faUsers;
  public faTrashAlt = faTrashAlt;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public faCopy = faCopy;
  public faTimes = faTimes;
  public faClone = faClone;

  //system values
  public teamID: number;
  public user: any;
  public labels: any;
  public token: string;

  //group data
  public groups: any;

  //subscriptions
  private shareDataSubscription: Subscription;

  //experience data
  public experiences: any;
  public experiencesSelect: { value: number; label: string }[];
  public hubExperiencesSelect: { value: number; label: string }[];
  public zoneExperiencesSelect: { value: number; label: string }[];
  public subZoneExperiencesSelect: { value: number; label: string }[];
  public startingExperiencesSelectBase: { value: number; label: string }[];

  //visibilty control
  public user_role_id: number;
  public editPermissions: any;
  public showAccessCode: boolean = false;
  public isModerator: boolean = false;

  //event data
  public events: any = [];
  public allEvents: any = [];
  public eventsLength: number = 0;
  public defaultRegion: any = null;

  //event props
  public props: any = [];

  //user feedback
  public errorRetrievingMsg: string = "";

  //filter management
  public startAtFilterPickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d yyyy",
  };
  public endAtFilterPickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d yyyy",
  };

  //table management
  public tableLoading: boolean = true;
  public headElements = [];
  sort_elements = [
    {
      type: "none",
      head: "actions",
    },
    {
      type: "string",
      head: "event_name",
    },
    {
      type: "date",
      head: "start_at",
    },
    {
      type: "date",
      head: "end_at",
    },
    {
      type: "string",
      head: "access",
    },
    {
      type: "number",
      head: "user_invitees_count",
    },
    {
      type: "number",
      head: "group_invitees_count",
    },
    {
      type: "number",
      head: "experiences_count",
    },
  ];
  sort = {
    direction: "asc",
    head: "name",
    type: "string",
  };
  filter_elements = [
    {
      filter: false,
      type: "close_filters",
      target: "actions",
    },
    {
      filter: true,
      type: "text",
      target: "event_name",
      placeholder: "Names",
    },
    {
      filter: true,
      type: "start_at",
      target: "start_at",
    },
    {
      filter: true,
      type: "end_at",
      target: "end_at",
    },
    {
      filter: true,
      type: "access",
      target: "access",
      options: [
        {
          value: "all",
          label: "All Access Types",
        },
        {
          value: "public",
          label: "Public",
        },
        {
          value: "private_invite",
          label: "Private by Invitation",
        },
        {
          value: "private_access_code",
          label: "Private by Access Code",
        },
      ],
    },
    {
      filter: true,
      type: "number",
      target: "user_invitees_count",
      placeholder: "Users",
    },
    {
      filter: true,
      type: "number",
      target: "group_invitees_count",
      placeholder: "Groups",
    },
    {
      filter: true,
      type: "number",
      target: "experiences_count",
      placeholder: "Experiences",
    },
  ];
  public accessTypes = ([] = [
    { value: "all", label: "All Types" },
    { value: "public", label: "Public" },
    { value: "private_invite", label: "Private by Invitation" },
    { value: "private_access_code", label: "Private by Access Code" },
  ]);

  //table sorting
  public sortBy = "none";
  public sortOrder = "none";

  //table filtering
  public filterText: {
    event_name: string;
    start_at: string;
    end_at: string;
    access: string;
    user_invitees_count: string;
    group_invitees_count: string;
    experiences_count: string;
  } = {
      event_name: "",
      start_at: "",
      end_at: "",
      access: "",
      user_invitees_count: "",
      group_invitees_count: "",
      experiences_count: "",
    };

  //table paginiation
  public first: number = 0;
  public rows: number = 20;
  public page: number = 1;

  //filter and sort detection
  public triggerFilterUpdate: Subject<any> = new Subject();

  //debouncing
  private debounceSubject = new Subject();

  //modal related
  private modalOptions = {
    backdrop: "static",
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public scheduleFrame: MDBModalRef;
  public eventRosterFrame: MDBModalRef;
  public cloneFrame: MDBModalRef;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private clipboard: Clipboard,
    private modalService: MDBModalService,
    private _settingsService: SettingsService,
    private _userServices: UserServicesService,
    private _sharedDataService: SharedDataService,
    private _notificationService: NotificationsService,
    private compoundFilterPipe: CompoundFilterPipe,
    private singleSortPipe: SingleSortPipe,
    private TitleCase: TitleCasePipe,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.editPermissions = false;
    this.user_role_id = 0;

    //preset access filter
    this.filterText["access"] = "all";

    //setup debouncing
    this.debounceSubject
      .pipe(debounceTime(350))
      .subscribe((params: any) => this.copyAction(params));

    this.triggerFilterUpdate
      .pipe(debounceTime(350))
      .subscribe(() => this.updateFilteredEvents(true));

    this.retrieveTeamID();
    this.retrieveLabels();
    this.configureSettings();

    this.headElements = [
      "action",
      `${this.TitleCase.transform(this.labels.event.singular)} Name`,
      "Starts",
      "Ends",
      "Access",
      "# of Users",
      "# of Groups",
      "# of Experiences",

    ]

    this.retrieveUser();
    this.retrieveToken();
    this.retrieveData();

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "add_meeting"
          ) {
            this.openEventManagement("add");
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.shareDataSubscription !== undefined)
      this.shareDataSubscription.unsubscribe();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private configureSettings() {
    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");

    this.user_role_id = this.user.role_type_id;
    if (this.user_role_id === 3) this.isModerator = true;

    let editPermissions = [1, 2, 3];

    if (editPermissions.indexOf(this.user_role_id) !== -1)
      this.editPermissions = true;
  }

  private async retrieveData() {
    let getEvents = await this.retrievEevents().catch((error) => {
      this.errorRetrievingMsg = `There was an error while retrieving ${this.labels.event.plural}. Please try again or contact support`;
    });

    this.errorRetrievingMsg = "";

    let getExperiences = await this.retrieveTeamExperiences().catch(
      (error) => {
        //we don't need to specify to the end user the exact nature of the error
        this.errorRetrievingMsg = `There was an error while retrieving ${this.labels.event.plural}. Please try again or contact support`;
      }
    );

    //for some reason this runs on logout
    //@todo: figure out why
    if (getExperiences === undefined) return false;

    this.experiences = getExperiences.experiences;

    this.processExperiences(getExperiences);

    let groups = [];
    let getGroups = await this.retrieveGroups();
    groups = this._userServices.processGroups(getGroups);
    this.groups = groups;

    let events = this.processEvents(getEvents.events);

    this.events = events;
    this.allEvents = events;

    this.sortTable("start_at", "desc", "date");
    this.updateFilteredEvents();

    this.tableLoading = false;

    //also let menu know that we're done loading
    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this.route.params.subscribe((params) => {

      if (params["event_id"] !== undefined) {
        let eventID = params["event_id"];
        this.openEventManagement("update", {
          id: eventID
        });
      }

    });

    this._sharedDataService.sendSharedData(toSend);

    //send correct label info
    let updatePageTitle = {
      type: "pageTitleChange",
      data: {
        pageTitle: `${this.TitleCase.transform(this.labels.event.singular)} Management`,
      },
    };

    this._sharedDataService.sendSharedData(updatePageTitle);

    let updateButtonTitle = {
      type: "buttonTitleChange",
      data: {
        buttonTitle: `Add ${this.TitleCase.transform(this.labels.event.singular)}`,
      },
    };

    this._sharedDataService.sendSharedData(updateButtonTitle);
  }

  private retrievEevents() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let targetURL = `/team/${this.teamID}/events`;

    if (this.isModerator) targetURL = `/team/${this.teamID}/events/user`;

    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "get",
      {},
      getOptions
    );

    return eventRetrieve.toPromise();
  }

  private retrieveTeamExperiences() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let experiencesRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      `/team/${this.teamID}/experiences`,
      "get",
      {},
      getOptions
    );

    return experiencesRetrieve.toPromise();
  }

  public retrieveGroups() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveGroups = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "groups",
      this.teamID,
      options,
      true
    );

    return retrieveGroups.toPromise().catch((err) => {
      return {
        group_information: [],
      };
    });
  }

  //mainly here for legacy support
  private processEvents(incomingEvents) {
    let eventsOut = [];

    incomingEvents.forEach((event) => {
      eventsOut.push(this.processEvent(event));
    });

    return eventsOut;
  }

  private processEvent(event) {
    event.endDate = event.end_at;
    event.startDate = event.start_at;
    event.name = event.event_name;
    event.schedule_id = event.id;

    event.access = "public";
    event.showAccessCode = false;

    if (!event.is_public && event.access_code !== null) {
      event.access = "private_access_code";
    } else if (!event.is_public && event.has_invitees) {
      event.access = "private_invite";
    }

    //user moment js to determine if the endDate is on the same day as the startDate
    let startDate = moment(event.start_at);
    let endDate = moment(event.end_at);

    event.sameDay = startDate.isSame(endDate, "day");

    return event;
  }

  private processExperiences(incomingExperiences) {
    let experiencesSelect = [];
    let startingExperiencesSelectBase = [];
    let hubExperiencesSelect = [];
    let zoneExperiencesSelect = [];
    let subZoneExperiencesSelect = [];

    let experiences =
      incomingExperiences !== null &&
        incomingExperiences.experiences !== undefined
        ? incomingExperiences.experiences
        : [];

    experiences.forEach((thisRel) => {
      let thisExperience = {
        value: thisRel.experience.id,
        label: `${thisRel.experience.label} (Capacity: ${thisRel.experience.max_attendees})`,
      };

      if (thisRel.experience.experience_type.name === "hub")
        thisExperience.label = `${thisExperience.label} (Hub)`;

      if (thisRel.experience.experience_type.name === "aia")
        thisExperience.label = `${thisExperience.label} (AIA)`;

      zoneExperiencesSelect.push(thisExperience);

      if (thisRel.experience.experience_type.name === "hub") {
        hubExperiencesSelect.push(thisExperience);
        startingExperiencesSelectBase.push(thisExperience);
      } else {
        if (thisRel.experience.experience_type.name !== "video_360") {
          experiencesSelect.push(thisExperience);
          startingExperiencesSelectBase.push(thisExperience);
        }
        subZoneExperiencesSelect.push(thisExperience);
      }
    });

    this.hubExperiencesSelect = hubExperiencesSelect;
    this.hubExperiencesSelect.sort((a, b) => a.label.localeCompare(b.label));
    this.experiencesSelect = experiencesSelect;
    this.experiencesSelect.sort((a, b) => a.label.localeCompare(b.label));
    this.zoneExperiencesSelect = zoneExperiencesSelect;
    this.zoneExperiencesSelect.sort((a, b) => a.label.localeCompare(b.label));
    this.subZoneExperiencesSelect = subZoneExperiencesSelect;
    this.subZoneExperiencesSelect.sort((a, b) => a.label.localeCompare(b.label));
    this.startingExperiencesSelectBase = startingExperiencesSelectBase;
    this.startingExperiencesSelectBase.sort((a, b) => a.label.localeCompare(b.label));
  }
  updateFilteredEvents(resetPage?: boolean) {

    if (resetPage === undefined) resetPage = false;

    this.events = this.compoundFilterPipe.transform(this.allEvents, {
      event_name: {
        text: this.filterText.event_name,
        type: 'text'
      },
      start_at: {
        text: this.filterText.start_at,
        type: 'start_at'
      },
      end_at: {
        text: this.filterText.end_at,
        type: 'end_at'
      },
      access: {
        text: this.filterText.access,
        type: 'access'
      },
      user_invitees_count: {
        text: this.filterText.user_invitees_count,
        type: 'number'
      },
      group_invitees_count: {
        text: this.filterText.group_invitees_count,
        type: 'number'
      },
      experiences_count: {
        text: this.filterText.experiences_count,
        type: 'number'
      }
    });

    this.events = this.singleSortPipe.transform(this.events, this.sort.direction, this.sort.head, this.sort.type);

    //get length of filtered events before pagination
    this.eventsLength = this.events.length;

    if (resetPage) {
      this.page = 1;
      this.pagination.changePage(0);
    }

    this.events = this.events.slice((this.page - 1) * this.rows, (this.page - 1) * this.rows + this.rows);

  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction, type) {
    this.sortBy = head;
    this.sortOrder = direction;

    this.sort.head = head;
    this.sort.direction = direction;
    this.sort.type = type;

    this.updateFilteredEvents();
  }

  onPageChange(event) {

    this.page = event.page + 1;
    this.updateFilteredEvents();

  }

  /**
   * Reset specific filter ngModel filterText[filter_elements[i].target]
   * @param index
   */
  public resetFilter(index, value?) {
    if (value === undefined) value = "";

    this.filterText[this.filter_elements[index].target] = value;

    this.updateFilteredEvents();
  }

  public toggleAccessCode(event_id) {
    let i = this.events.findIndex((event) => {
      return event.id === event_id;
    });

    this.events[i].showAccessCode = !this.events[i].showAccessCode;
  }

  public copyAccessCode(event) {
    let params = {
      copy: event.access_code,
    };
    this.debounceSubject.next(params);
  }

  private copyAction(params) {
    let copyResults = this.clipboard.copy(params.copy);
    let message = "Code successfully copied";

    if (!copyResults) {
      message = "Issue copying code";
      this._notificationService.errorNotification(message);
      return false;
    }

    this._notificationService.successNotification(message);
  }

  public openEventManagement(action: string, targetEvent?) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass:
        action === "delete"
          ? "remove-class-container"
          : "schedule-class-container",
      class:
        action !== "delete"
          ? this.modalOptions.class +
          " modal-full-height modal-right manage-event-display"
          : "",
    };

    if (targetEvent !== undefined) targetEvent.schedule_id = targetEvent.id;
    this.modalOptions.data = {
      teamID: this.teamID,
      targetEvent: targetEvent,
      clientSettings: this.clientSettings,
      user: this.user,
      defaultRegion: this.defaultRegion,
      labels: this.labels,
      action: action,
      multiExperiences: true,
      experiences: this.experiences,
      experiencesSelectBase: this.experiencesSelect,
      startingExperiencesSelectBase: this.startingExperiencesSelectBase,
      hubExperiencesSelectBase: this.hubExperiencesSelect,
      zonesSelectBase: this.zoneExperiencesSelect,
      subZonesSelectBase: this.subZoneExperiencesSelect,
      events: this.events,
    };

    this.scheduleFrame = this.modalService.show(
      ManageEventComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((changedData) => {
      let scheduled_event =
        changedData.scheduled_event !== undefined
          ? changedData.scheduled_event
          : {};

      if (
        changedData.action === "update" ||
        changedData.action === "update_no_message"
      ) {
        this.allEvents.forEach((event, index) => {
          if (event.id === scheduled_event.id) {
            this.allEvents[index] = this.processEvent(scheduled_event);
          }
          this.allEvents = this.allEvents.filter((event) => {
            return event.id !== null;
          });
        });
      } else if (changedData.action === "add") {
        let events = this.allEvents;
        events.push(this.processEvent(scheduled_event));
        this.allEvents = events;
        this.allEvents = this.allEvents.filter((event) => {
          return event.id !== null;
        });
        this.mdbTableData.setDataSource(this.allEvents);
        this.sortTable("start_at", "desc", "date");
      } else if (changedData.action === "delete") {
        this.allEvents = this.allEvents.filter((event) => {
          return event.id !== scheduled_event.id;
        });
      }

      this.updateFilteredEvents();

      if (changedData.action === "update_no_message") return;

      //user regex to remove the e from the end of the action string, if it exists
      let actionAdj = changedData.action.replace(/e$/, "");
      let toastMsg = `${scheduled_event.event_name} has been ${actionAdj}ed`;

      this._notificationService.successNotification(toastMsg);
    });
  }

  public cloneEvent(event) {
    this.modalOptions.containerClass = "";
    this.modalOptions.class = "modal-dialog modal-dialog-centered";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "delete-asset-container",
    };

    this.modalOptions.data = {
      targetEvent: event,
      teamID: this.teamID,
      action: "clone",
      clientSettings: this.clientSettings,
      title: `Clone ${event.event_name}?`,
      message: `Do you want to clone ${event.event_name}?`,
      button: `Clone`,
    };

    this.cloneFrame = this.modalService.show(
      CloneEventComponent,
      this.modalOptions
    );

    this.cloneFrame.content.outgoing.subscribe((result: any) => {

      if (result.action !== undefined && result.action === "clone") {
        let toastMsg = `${event.event_name} has been cloned`;
        this._notificationService.successNotification(toastMsg);

        let events = this.allEvents;
        events.push(this.processEvent(result.changedData));
        this.allEvents = events;
        this.allEvents = this.allEvents.filter((event) => {
          return event.id !== null;
        });
        this.mdbTableData.setDataSource(this.allEvents);
        this.sortTable("start_at", "desc", "date");

        this.updateFilteredEvents();
      }
    });

  }

  emitDataSourceChange() {
    this.mdbTableData.dataSourceChange().subscribe((data: any) => { });
  }

  public openExperienceMetaModal(targetEvent) {
    //legacy support
    targetEvent.schedule_id = targetEvent.id;

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      // class: "modal-dialog-centered modal-fluid",
      containerClass: "edit-class-settings-container",
    };

    this.modalOptions.data = {
      targetEvent: targetEvent,
      teamID: this.teamID,
      action: "update",
    };

    this.scheduleFrame = this.modalService.show(
      EventSettingsModalComponent,
      this.modalOptions
    );
  }

  public openManageMediaModal(targetEvent, type?, amount?) {
    //legacy support
    targetEvent.schedule_id = targetEvent.id;

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "event-media-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };

    this.modalOptions.data = {
      action: "update",
      targetEvent: targetEvent,
      teamID: this.teamID,
      type: type !== undefined ? type : "all",
      amount: amount !== undefined ? amount : "multi",
    };

    this.scheduleFrame = this.modalService.show(
      ManageEventMediaComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((result: any) => {
      let toastMsg = `Media updated for ${targetEvent.event_name}`;
      this._notificationService.successNotification(toastMsg);
    });
  }

  public getGroupData(groups, group_id) {
    return groups.filter((group) => {
      return group.id === group_id;
    });
  }

  public openUserManagementModal(targetEvent, index) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "event-roster-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };

    targetEvent.schedule_id = targetEvent.id;
    this.modalOptions.data = {
      action: "update",
      teamID: this.teamID,
      clientSettings: this.clientSettings,
      targetEvent: targetEvent,
      labels: this.labels,
      from: "events",
    };

    this.eventRosterFrame = this.modalService.show(
      ManageEventRosterComponent,
      this.modalOptions
    );

    this.eventRosterFrame.content.outgoing.subscribe((changedData) => {
      if (
        changedData.action !== undefined &&
        changedData.action === "update_event_roster"
      ) {
        let toastMsg = `Roster updated for ${targetEvent.event_name}`;
        this._notificationService.successNotification(toastMsg);

        this.allEvents[index].user_invitees_count =
          changedData.eventRoster.users.length;
        this.allEvents[index].group_invitees_count =
          changedData.eventRoster.groups.length;

        this.updateFilteredEvents();
      }
    });
  }
}
