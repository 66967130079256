<div theme class="status-messages">
  <!-- processing msg -->
  <div
    *ngIf="msgs.processingMsg !== ''"
    class="
      status-wrapper
      processing-wrapper
      flex-wrap
      d-flex
      flex-column
      justify-content-center
      p-3
    "
  >
    <div
      class="
        alert
        text-capitalize text-center
        d-flex
        justify-content-center
        align-content-center
      "
      role="alert"
      [innerHTML]="msgs.processingMsg"
    ></div>
  </div>
  <!-- /processing msg -->
  <div
    *ngIf="msgs.errorMsg !== ''"
    class="
      status-wrapper
      error-wrapper
      flex-wrap
      d-flex
      flex-column
      justify-content-center
      p-3
    "
  >
    <div class="alert text-center" role="alert">
      <p [innerHTML]="msgs.errorMsg"></p>
      <div class="button-wrapper d-flex justify-content-end">
        <button
          mdbBtn
          type="button"
          mdbWavesEffect
          class="theme-modal-button btn"
          (click)="triggerAction('clear-status')"
        >
          {{ btnLabel.retry }}
        </button>
        <button
          mdbBtn
          mdbWavesEffect
          type="button"
          size="md"
          color="link"
          (click)="triggerAction('close-modal')"
        >
          {{ btnLabel.close }}
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="msgs.statusMsg !== ''"
    class="
      status-wrapper
      success-wrapper
      flex-wrap
      d-flex
      flex-column
      justify-content-center
      p-3
    "
  >
    <div class="alert text-center" role="alert">
      <p [innerHTML]="msgs.statusMsg"></p>
      <div class="button-wrapper d-flex justify-content-end">
        <button
          *ngIf="continueType === 'continue'"
          mdbBtn
          type="button"
          mdbWavesEffect
          class="theme-modal-button btn"
          (click)="triggerAction('clear-status')"
        >
          {{ btnLabel.retry }}
        </button>
        <button
          *ngIf="continueType === 'reset'"
          mdbBtn
          type="button"
          mdbWavesEffect
          class="theme-modal-button btn"
          (click)="triggerAction('reset-form')"
        >
          {{ btnLabel.reset }}
        </button>

        <button
          mdbBtn
          mdbWavesEffect
          type="button"
          size="md"
          color="link"
          (click)="triggerAction('close-modal')"
        >
          {{ btnLabel.close }}
        </button>
      </div>
    </div>
  </div>
</div>
