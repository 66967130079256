<div class="modal-header">
  <h4 class="modal-title w-100 font-weight-bold">
    {{ title }} <mdb-icon
      far
      icon="calendar-alt"
    ></mdb-icon>
  </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="mediaGroupFrame.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body media-modal m-3 p-0">
  <div
    class="content-section content-pdfs theme-base-light"
    *ngIf="type === 'pdf' && !loading"
  >
    <app-media-manager
      [teamID]="teamID"
      [defaultTab]="'personal-media'"
      [actionButtons]="actionButtons"
      [columnVisibility]="columnVisibility"
      [selectable]="selectable"
      [selectedMedia]="media"
      [filterByType]="filterByType"
      [selectableType]="selectableType"
      [clickableThumb]="clickableThumb"
      [systemButtons]="systemButtons"
      [systemInteraction]="systemInteraction"
      (selected)="outputSelected($event)"
      (parentActions)="onParentActions($event)"
    ></app-media-manager>
  </div>
  <div
    class="content-section content-images theme-base-light"
    *ngIf="type === 'image' && !loading"
  >
    <app-media-manager
      [teamID]="teamID"
      [defaultTab]="'personal-media'"
      [actionButtons]="actionButtons"
      [columnVisibility]="columnVisibility"
      [selectable]="selectable"
      [selectedMedia]="selectedMedia"
      [filterByType]="filterByType"
      [selectableType]="selectableType"
      [clickableThumb]="clickableThumb"
      [systemButtons]="systemButtons"
      [systemInteraction]="systemInteraction"
      (selected)="outputSelected($event)"
      (parentActions)="onParentActions($event)"
    ></app-media-manager>
  </div>
</div>
