import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { LoginSharing } from "./../../models/login.models";

@Injectable({
  providedIn: "root",
})
export class SharedDataService {
  sharedDataObserver = new Subject();
  public sharedDataComm$ = this.sharedDataObserver.asObservable();
  public outgoingGroupData$: BehaviorSubject<any> = new BehaviorSubject(false);
  public sessionTimeoutEvent: EventEmitter<void> = new EventEmitter();

  constructor() {}

  sendSharedData(data) {
    this.sharedDataObserver.next(data);
  }

  triggerSessionTimeout(data) {
    this.sessionTimeoutEvent.emit(data);
  }

}
