<!-- <div class="row">
  <div class="col-12"><h6 class="text-center">3D Object Preview</h6></div>
</div> -->
<div class="model-wrapper">
  <ng-container *ngIf="modelSrc">
    <model-viewer #modelViewer [src]="modelSrc" camera-controls></model-viewer>
  </ng-container>
</div>
<button
  mdbBtn
  type="button"
  mdbWavesEffect
  class="theme-modal-button btn thumbnail-button"
  *ngIf="action === 'update'"
  [disabled]="!modelLoaded"
  (click)="generateThumbnail()"
>
  Change Thumbnail
</button>
<div *ngIf="thumbnailSource !== ''">
  <img class="img-fluid" [src]="thumbnailSource" />
</div>
