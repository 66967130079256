<div class="media-upload-page page">
  <div class="row header-row" style="margin-bottom: 0%">
    <div class="col-md-12">
      <div class="title-callout">
        <div class="callout-headers-wrapper">
          <div class="callout-headers float-left">
            <h2 class="h4 strong m-0">Inventory Management</h2>
          </div>
        </div>
        <div class="callout-text-wrapper">
          <p>
            <span [innerHTML]="copy"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row body-row">
    <div class="col-md-12">
      <mdb-tabset
        #staticTabs
        [buttonClass]="'nav md-pills pills-primary mb-2'"
        [justified]="true"
        style="padding-top: 0%"
      >
        <mdb-tab heading="Inventory Items" *ngIf="enableItems">
          <div class="row" *ngIf="!loading">
            <div class="upload-button">
              <button
                type="button"
                mdbBtn
                class="waves-light theme-action-button"
                mdbWavesEffect
                (click)="openAddInventoryEntityModal('item')"
              >
                <i class="">&nbsp;Add Item</i>
              </button>
            </div>
          </div>
          <div class="row" *ngIf="!loading">
            <div class="col-md-12 container-gallery justify-content-start">
              <div *ngFor="let item of items; let i = index">
                <mdb-card class="w-100">
                  <mdb-card-body>
                    <a class="activator px-3 mr-6">
                      <mdb-icon
                        fas
                        (click)="openViewItemOptionsModal(item)"
                        icon="users"
                      ></mdb-icon>
                      <mdb-icon
                        far
                        (click)="openEditInventoryModal(item, 'item', i)"
                        icon="edit"
                      ></mdb-icon>
                      <mdb-icon
                        fas
                        icon="archive"
                        (click)="openArchiveInventoryModal(item, 'item', i)"
                      ></mdb-icon>
                    </a>
                    <mdb-card-title>
                      <h4>{{ item.name }}</h4>
                    </mdb-card-title>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </div>
        </mdb-tab>
        <mdb-tab heading="Items Columns" *ngIf="enableColumns">
          <div class="row" *ngIf="!columnsLoading">
            <div class="upload-button">
              <button
                type="button"
                mdbBtn
                class="waves-light theme-action-button"
                mdbWavesEffect
                (click)="openAddInventoryEntityModal('column')"
              >
                <i class="">&nbsp;Add Column</i>
              </button>
            </div>
          </div>
          <div class="col-md-12 container-gallery justify-content-start" *ngIf="!columnsLoading">
            <div *ngFor="let column of columns; let i = index">
              <mdb-card class="w-100">
                <mdb-card-body>
                  <a class="activator px-3 mr-6">
                    <mdb-icon
                      far
                      (click)="openEditInventoryModal(column, 'column', i)"
                      icon="edit"
                    ></mdb-icon>
                    <mdb-icon
                      fas
                      icon="archive"
                      (click)="openArchiveInventoryModal(column, 'column', i)"
                    ></mdb-icon>
                  </a>
                  <mdb-card-title>
                    <h4>{{ column.label }}</h4>
                  </mdb-card-title>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <mdb-tab heading="Items Options" *ngIf="enableOptions">
          <div class="row" *ngIf='!optionsLoading'>
            <div class="upload-button">
              <button
                type="button"
                mdbBtn
                class="waves-light theme-action-button"
                mdbWavesEffect
                (click)="openAddInventoryEntityModal('option')"
              >
                <i class="">&nbsp;Add Option</i>
              </button>
            </div>
          </div>
          <div class="col-md-12 container-gallery justify-content-start" *ngIf='!optionsLoading'>
            <div *ngFor="let option of options; let i = index">
              <mdb-card class="w-100">
                <mdb-card-body>
                  <a class="activator px-3 mr-6">
                    <mdb-icon
                      far
                      (click)="openEditInventoryModal(option, 'option', i)"
                      icon="edit"
                    ></mdb-icon>
                    <mdb-icon
                      fas
                      icon="archive"
                      (click)="openArchiveInventoryModal(option, 'option', i)"
                    ></mdb-icon>
                  </a>
                  <mdb-card-title>
                    <h4>{{ option.name }}</h4>
                  </mdb-card-title>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
      </mdb-tabset>
    </div>
  </div>
</div>
