 <div class="modal-header text-center">
    <h4 class="modal-title w-100">Manage {{labels.event.singular|titlecase}} {{labels.user.plural|titlecase}}</h4>
    </div>
  <p class="note">
    Note: {{labels.user.plural|titlecase}} added via a {{labels.userGroup.singular}} can be found under Manage {{labels.userGroup.plural|titlecase}} -> View {{labels.userGroupMember.plural|titlecase}}

  </p>
<div class="modal-body media-modal mx-3 p-0 overflow-auto" style="width: auto">
  <div class="table-responsive">
    <table *ngIf="targetEvent.userCount > 0" class="table table-striped table-hover table-sm" 
      mdbTable #tableEl="mdbTable"
      mdbTableScroll
      scrollY="true"
      maxHeight="200"
      hover="true"
      striped="true">
      <thead class="sticky-top theme-base-medium-dark white-text">
        <tr>
          <th *ngFor="let head of scheduledTableHeaders" scope="col">
            {{ head | titlecase }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr mdbTableCol *ngFor="let user of invitees; let i = index">
          <td>{{ user.user.first_name }}</td>
          <td>{{ user.user.last_name }}</td>
          <td>{{ user.user.email }}</td>
          <td>{{ user.user.username }}</td>
          <td *ngIf="showRoles">
            <span *ngIf="user.role.name !== undefined">{{ user.role.name }}</span
            ><span *ngIf="user.role.name === undefined">Not Set</span>
          </td>
          <td *ngIf="showPersonas">
            <span *ngIf="user.persona.persona_name !== undefined">{{
              user.persona.persona_name
            }}</span
            ><span *ngIf="user.persona.persona_name === undefined">Not Set</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <p *ngIf="targetEvent.userCount === 0">
    No users are yet scheduled for this experience.
  </p>
</div>

<div class="modal-footer">
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    size="md"
    color="link"
    (click)="manageUsersFrame.hide()"
  >
  {{ btnLabel.close }}
  </button>
</div>
