import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MustMatch } from "../../user-management/validators/must-match.directive";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { CoolLocalStorage } from "@angular-cool/storage";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"],
})
export class PasswordResetComponent implements OnInit {
  //persistent
  public teamID: number = 0;
  public domain: string = "superadmin";
  public clientSettings: any;
  public clientURL: string = "";
  public token: string = null;
  public showContent: boolean = false;
  public isToken: boolean = false;
  public isValidToken: boolean = true;

  //feedback response
  public errorMsg: string = "";
  public statusMsg: string = "";
  public formState: string = "active";

  //user values
  private user_id: number;

  //form vars
  public pwForm: UntypedFormGroup;
  public formLoading: boolean = true;
  public btnLabel: { close: string; main: string; send: string } = {
    close: "Cancel",
    main: "Update",
    send: "Send",
  };
  public preSelected: {
    id: any;
    password: string;
  } = {
    id: null,
    password: null,
  };
  public emailForm: UntypedFormGroup;
  public emailFormLoading: boolean = true;
  public emailBtnLabel: { close: string; main: string } = {
    close: "Cancel",
    main: "send",
  };

  constructor(
    private route: ActivatedRoute,
    private _clientManagementService: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _settingsService: SettingsService,
    private coolLocalStorage: CoolLocalStorage
  ) {}

  ngOnInit(): void {
    this.retrieveTeamID();
    this.route.params.subscribe((params) => {
      this.token = params["token"] !== undefined ? params["token"] : null;
      this.retrieveTeamDomain();
      this.clientSettings = this._settingsService.getSettingsFromStorage(
        this.teamID
      );

      ;

      if (this.teamID === 0) {
        this.clientURL = `${location.host}`;
      } else {
        this.clientURL = `${location.host}/client/${this.teamID}`;
      }

      ;

      if (this.token) {
        this.retrieveUserWithToken();
      } else {
        this.makeEmailFormValidatingGroup();
        this.showContent = true;
      }
    });
  }

  private retrieveTeamID(){
    this.teamID = JSON.parse(this.coolLocalStorage.getItem("admin_panel_team_id"));
  }

  public retrieveTeamDomain() { 
    let restURL = this._clientManagementService.getRESTurl();
    let myRestURL = restURL + "/team/" + this.teamID + "/domain";
    if(this.teamID == 0) {
      myRestURL = restURL + "/team/1/domain";
    }

    let retrieveDomain = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/domain",
      "get",
      {},
    )
    
    retrieveDomain.subscribe((response) => {
      this.domain = response.domain
    },
    (error) => {
      
    })
  }

  private retrieveUserWithToken() {
    const headers = {
      "Content-Type": "application/json",
    };

    const options = {
      headers: headers,
    };

    let body = {
      token: this.token,
    };

    let getUserByToken = this._xrPlatformRestService.getUserByToken(
      body,
      options
    );

    getUserByToken.subscribe(
      (response) => {
        this.isToken = true;
        this.showContent = true;
        this.token = response.accessToken;
        this.user_id = response.user.id;
        this.makeFormValidatingGroup();
      },
      (err) => {
        this.showContent = true;
        this.isValidToken = false;
        this.makeEmailFormValidatingGroup();
      }
    );
  }

  private makeFormValidatingGroup() {
    this.pwForm = new UntypedFormGroup(
      {
        password: new UntypedFormControl(
          "",
          Validators.compose([Validators.required, Validators.minLength(6)])
        ),
        confirm_password: new UntypedFormControl(
          "",
          Validators.compose([Validators.required])
        ),
      },
      [MustMatch("password", "confirm_password")]
    );

    this.formLoading = false;
  }

  get password() {
    return this.pwForm.get("password");
  }

  get confirm_password() {
    return this.pwForm.get("confirm_password");
  }

  private makeEmailFormValidatingGroup() {
    this.emailForm = new UntypedFormGroup({
      email: new UntypedFormControl(""),
    });
  }

  get email() {
    return this.emailForm.get("email");
  }

  public managePassword() {
    this.errorMsg = "";
    let incomingValues = this.pwForm.value;
    this.formState = "processing";
    this.statusMsg = "<span class='loading-msg'>Updating password</span>";

    ;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const httpOptions = {
      headers: headers,
    };

    let formValues = {
      password: incomingValues.password,
    };

    const options = JSON.stringify(formValues);

    let updateUser = this._xrPlatformRestService.manageUsers(
      this.user_id,
      "update-by-admin",
      options,
      httpOptions
    );

    updateUser.subscribe(
      (response) => {
        this.statusMsg = "Password successfully updated.";
        this.formState = "success";
      },
      (err) => {
        ;
        this.statusMsg = "";
        this.formState = "active";
        let errorMsg = JSON.parse(err._body);
        this.errorMsg = errorMsg.error;
      }
    );
  }

  public sendResetEmail() {
    this.errorMsg = "";
    let incomingValues = this.emailForm.value;
    ;
    this.formState = "processing";
    this.statusMsg = "<span class='loading-msg'>Sending reset link</span>";

    const headers = {
      "Content-Type": "application/json",
    };

    const options = {
      headers: headers,
    };

    let formValues = {
      source_email: this.clientSettings.sourceEmail,
      target_email: incomingValues.email,
      domain: this.domain,
      template_data: {
        clientname: this.clientSettings.clientName,
        clienturl: this.clientURL,
        contactemail: this.clientSettings.contactEmail,
      },
      profile_name: this.clientSettings.emailProfileName,
      template_name_suffix: this.clientSettings.emailTemplateSuffix,
    };

    formValues.source_email = this.clientSettings.sourceEmail.unconstrained_default;
    formValues.template_data.clientname = this.clientSettings.clientName.unconstrained_default;
    formValues.template_data.contactemail = this.clientSettings.contactEmail.unconstrained_default;
    formValues.profile_name = this.clientSettings.emailProfileName.unconstrained_default;
    formValues.template_name_suffix = this.clientSettings.emailTemplateSuffix.unconstrained_default;

    ;

    const body = JSON.stringify(formValues);

    let sendResetEmail = this._xrPlatformRestService.sendResetPasswordLink(
      body,
      options
    );

    sendResetEmail.subscribe(
      (response) => {
        ;
        this.statusMsg = "Reset link successfully sent.";
        this.formState = "success";
      },
      (err) => {
        ;
        this.statusMsg = "";
        this.formState = "active";
        let errorMsg = err.error;
        this.errorMsg = errorMsg.error;
      }
    );
  }
}
