<div class="text-inline-wrapper">
  <p class="label" [ngClass]="{ editing: editMode }">{{ label }}</p>
  <div
    *ngIf="!editMode"
    class="md-form form-sm d-flex align-content-center cell"
  >
    <input
      class="form-control form-control-sm rounded-0 m-0"
      mdbInput
      disabled
      type="text"
      [value]="value"
    />
    <button
      (click)="editMode = true"
      class="rounded-0"
      mdbBtn
      [disabled]="disabled"
      type="button"
      size="md"
    >
      <fa-icon [icon]="faPencil"></fa-icon>
    </button>
  </div>
  <div class="md-form form-sm d-flex align-content-center" *ngIf="editMode">
    <ng-container *ngIf="type === 'text'">
      <input
        [id]="'form' + name"
        class="form-control form-control-sm rounded-0 entry"
        (focus)="onFocus($event)"
        (keydown)="onKeyboardEnter($event)"
        [(ngModel)]="data"
        appAutofocus
        type="text"
        mdbInput
      />
    </ng-container>
    <ng-container *ngIf="type === 'select'">
      <mdb-select-2 class="custom-arrow" [(ngModel)]="data"
        ><mdb-select-option
          *ngFor="let option of selectOptions"
          [value]="option.value"
          >{{ option.label }}</mdb-select-option
        ></mdb-select-2
      >
    </ng-container>
    <label [for]="'form' + name">{{ label }}</label>
    <button (click)="onSave()" class="rounded-0" mdbBtn type="button" size="md">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button
      (click)="onCancel()"
      class="rounded-0"
      mdbBtn
      type="button"
      size="md"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</div>
