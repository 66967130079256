import { CoolLocalStorage } from '@angular-cool/storage';
import { XrPlatformRestService } from '../../../services/rest/xr-platform/xr-platform-rest.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MetricsServicesService } from '../../metrics/services/metrics-services.service';
import { GridChartsModule } from "@ag-grid-enterprise/charts-enterprise";

import 'ag-grid-enterprise';

import {
  ModuleRegistry,
} from "@ag-grid-community/core";

import {
  ColDef,
  ColGroupDef,
  ColumnResizedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridState,
  SideBarDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  createGrid,
} from "ag-grid-community";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
// import { ReadquestAnalyticsDrillDownGridsComponent } from './modals/readquest-analytics-drill-down-grids/readquest-analytics-drill-down-grids.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { StateUpdatedEvent } from 'ag-grid-community';
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MenuModule,
  RowGroupingModule,
  SetFilterModule,
  GridChartsModule
]);

@Component({
  selector: 'app-readquest-analytics-highlevel',
  templateUrl: './readquest-analytics-highlevel.component.html',
  styleUrls: ['./readquest-analytics-highlevel.component.scss'],
})
export class ReadquestAnalyticsHighlevelComponent implements OnInit {
  @ViewChild('myGrid') myGrid!: AgGridAngular;

  private gridApi!: GridApi<any>

  //persistent
  private token: string;
  public loading: boolean = true;
  public teamID: number;
  public users: any;
  private targetURL: string;

  //user feedback
  public errorRetrievingMsg: string = "";

  //table config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public defaultColDef: ColDef = {
    flex: 1,
    filter: true,
    minWidth: 220
  };
  public autoGroupColumnDef: any = {
    pinned: 'left',
  };
  public sideBar: SideBarDef | string | string[] | boolean | null = "columns";
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      skipHeader: false
    };

  public gridVisible = true;

  public rowData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //chart config
  public enableCharts: boolean = true;
  public enableRangeSelection: boolean = true;

  public initialState?: GridState;

  //sidebar
  public sidebarVisible: boolean = false;


  public chartFrame: MDBModalRef;

  // Mapping of string identifiers to component classes
  // private componentMapping = {
  //   'drillDownGrids': ReadquestAnalyticsDrillDownGridsComponent,
  // };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _metricsServicesService: MetricsServicesService,
    private _xrPlatformRestService: XrPlatformRestService,
    private http: HttpClient,
    private modalService: MDBModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveTeamID();

    // Listen for route changes
    this.activatedRoute.params.subscribe(params => {
      const legacyState = params['legacy_state'];

      this.targetURL = `rqu/team/${this.teamID}/highlevel-data`;

      if (legacyState !== undefined) this.targetURL = `/team/${this.teamID}/readquest-highlevel-data`;

      this.retrieveData();
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private async retrieveData() {

    let users = await this.retrieveUsers().catch((error) => {
      this.errorRetrievingMsg = "Error retrieving users. Please try again later.";
      this.loading = false;
    });

    this.users = users;

    this.retrieveTableData().then((data: any) => {
      let columnDefs = data.columnDefs;

      columnDefs = columnDefs.map((column: any) => {

        if (data.durations.includes(column.field)) {
          column.valueFormatter = function (x) {
            let value = x.value;
            if (value === 0) return "00:00:00";


            var hours = Math.floor(value / 3600);
            var minutes = Math.floor((value % 3600) / 60);
            var seconds = Math.round(value % 60);

            return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
          }
        }

        return column;
      });

      this.columnDefs = columnDefs;

      let rowData = data.rowData;

      rowData = rowData.map((row: any) => {

        let keys = Object.keys(row);
        let newRow = {};
        keys.forEach((key) => {
          if (key.indexOf("time") > -1) {
            if (row[key] === "9999-12-31 23:59:59") {
              newRow[key] = "N/A";
            } else {
              newRow[key] = row[key];
            }
          } else {
            newRow[key] = row[key];
          }
        });

        return newRow;

      });

      this.rowData = rowData;

      console.log("this.columnDefs", this.columnDefs)
      console.log("this.rowData", this.rowData)

      this.loading = false;
    }).catch((error) => {
      this.errorRetrievingMsg = "Error retrieving analytics data. Please try again later.";
      this.loading = false;
    });
  }

  private retrieveUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options,
      true
    );

    return retrieveUsers.toPromise();
  }

  private retrieveTableData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    console.log("this.teamID", this.teamID);

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "get", {}, getOptions).toPromise();
  }

  onColumnResized(params: ColumnResizedEvent) {
    console.log(params);
  }

  autoSizeAll(skipHeader: boolean) {
    const allColumnIds: string[] = [];
    this.gridApi.getColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.gridApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  public onGridReady(params) {
    this.gridApi = params.api;
    this.autoSizeAll(false);
    console.log("this.gridApi", this.gridApi)
  }

  public initiateRoutedModal(targetModal, targetType) {
    let queryParams = { modal: targetModal, target: targetType };

    this.router.navigate([], {
      queryParams: queryParams,
      queryParamsHandling: 'merge' // Merge with existing query params
    });
  }

  saveColumnState() {
    const columnState = this.gridApi.getState();
    this.coolLocalStorage.setItem('columnState', JSON.stringify(columnState));
  }

  // Method to load and apply column state to the target grid
  loadColumnState() {
    const columnState = JSON.parse(localStorage.getItem('columnState'));

    if (columnState) {
      console.log("columnState", columnState);
      this.gridVisible = false;
      this.cdRef.detectChanges();
      this.initialState = columnState;
      this.rowData = undefined;
      setTimeout(() => {
        this.gridVisible = true;
        this.cdRef.detectChanges();
      });
    }
  }

  onStateUpdated(params: StateUpdatedEvent<any>): void {
    console.log('State updated', params.state);
  }

}
