import { Component, OnInit } from "@angular/core";
import { CoolLocalStorage } from "@angular-cool/storage";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ManageGlobalExperienceComponent } from "./modals/manage-global-experience/manage-global-experience.component";
import { ArchiveGlobalExperienceComponent } from "./modals/archive-global-experience/archive-global-experience.component";

@Component({
  selector: "app-experiences-global",
  templateUrl: "./experiences-global.component.html",
  styleUrls: ["./experiences-global.component.scss"],
})
export class ExperiencesGlobalComponent implements OnInit {
  public token: string;
  public user: any;

  public experiencesGlobalLoading: boolean = true;
  public experiencesGlobalSearchText: string = "";

  public experiencesGlobal: any = [];

  public teamID: number;
  public copy = "";

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private modalService: MDBModalService
  ) {}

  ngOnInit(): void {
    this.retrieveUser();
    this.retrieveToken();
    this.retrieveexperiencesGlobal();
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveexperiencesGlobal() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let experiencesGlobal = this._xrPlatformRestService.restfulAPIQuery(
      "/experiences",
      "get",
      {},
      options
    );

    experiencesGlobal.subscribe(
      (response) => {
        ;
        this.experiencesGlobal = response.experiences;
        this.experiencesGlobalLoading = false;
      },
      (error) => {}
    );
  }

  public manageExperience(type, index?, targetExperience?) {
    this.modalOptions.data = {
      team_id: this.teamID,
      type: type,
      targetExperience: targetExperience,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-experiences-container",
    };

    this.modalFrame = this.modalService.show(
      ManageGlobalExperienceComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.experiencesGlobal.push(changedData.experience);
            break;
          case "update":
            this.experiencesGlobal[index] = changedData.experience;
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public archiveExperience(targetExperience) {
    this.modalOptions.data = {
      teamID: this.teamID,
      targetExperience: targetExperience,
      type: "delete",
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "archive-event-types-container",
    };
    this.modalFrame = this.modalService.show(
      ArchiveGlobalExperienceComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            break;
          case "delete":
            let updatedExperiences = this.experiencesGlobal.filter(
              (experience) => {
                return experience.id !== changedData.experience.id;
              }
            );

            this.experiencesGlobal = updatedExperiences;
            break;
        }
      } else {
      }
    });
  }
}
