<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">Add {{labels.eventGroupMember.singular | titlecase}}</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="selectPublicEventModal.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3 p-0">
    <div class="row add-form-flex-container">
      <h4>Select a public {{labels.event.singular}}</h4>
      <form
        class="text-center border border-light p-5 edit-form w-100"
        [formGroup]="selectForm"
        autocomplete="off"
      >
        <div class="md-form mb-5 ap-autocomplete">
          <input
            type="text"
            id="event_name"
            formControlName="event"
            placeholder="Select a public {{labels.event.singular | titlecase}}"
            [mdbAutoCompleter]="auto"
            class="completer-input form-control mdb-autocomplete"
            mdbInput
          />
          <label for="event_name">{{labels.event.singular | titlecase}} Name</label>
          <mdb-auto-completer
            [displayValue]="onEventDisplayValue"
            #auto="mdbAutoCompleter"
            textNoResults="{{textNoResults}}"
            [clearButton]="true"
          >
            <mdb-option
              *ngFor="let option of results | async"
              [value]="option"
            >
              {{ option.event_name }}
            </mdb-option>
          </mdb-auto-completer>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="selectPublicEventModal.hide()"
    >
      Close
    </button>
    <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="selectPublicEvent()"
    >
      Select Public Event
    </button>
  </div>
</div>
