<ng-container *ngIf="loading.userGroups">
  <div class="row">
    <div class="col-12">
      <!-- if content hasnt load -->
      <div
        class="loading-card d-flex align-content-center justify-content-center"
      >
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg === ''"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg !== ''"
        >
          <div
            class="alert alert-danger"
            role="alert"
            [innerHTML]="errorRetrievingMsg"
          ></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading.userGroups && !allUserGroups.length">
  <div class="row flex-row flex-nowrap">
    <div class="col-12 p-4">
      You have added no {{ labels.userGroup.plural }} yet.<span
        class="color-theme-base-medium"
        ><button
          (click)="openManageGroupModal()"
          class="inline"
          mdbBtn
          type="button"
          flat="true"
          size="lg"
          mdbWavesEffect
        >
          &nbsp;Add a {{ labels.userGroup.singular }} now.
        </button></span
      >
    </div>
  </div>
</ng-container>
<div *ngIf="!loading.users && allUserGroups.length">
  <div class="row flex-row flex-nowrap">
    <div class="col-12 p-0">
      <div class="table-responsive">
        <table
          class="table table-striped user-table"
          mdbTable
          #groupTableEl="mdbTable"
          small="true"
        >
          <colgroup class="user-groups">
            <col
              *ngFor="let head of headElements; let i = index"
              [ngClass]="'col-' + sort_elements[i].head"
            />
          </colgroup>
          <thead class="theme-base-medium-dark white-text">
            <tr>
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="'col-' + head"
              >
                <span *ngIf="head !== 'action'">{{ head | titlecase }}</span>
                <ng-template [ngIf]="sort_elements[i].type !== 'none'">
                  <mdb-icon
                    fas
                    *ngIf="sortBy !== sort_elements[i].head"
                    icon="sort"
                    class="sort-icon"
                    [mdbTooltip]="'Sort by ' + head"
                    placement="bottom"
                    (click)="
                      sortTable(
                        sort_elements[i].head,
                        'desc',
                        sort_elements[i].type
                      )
                    "
                  ></mdb-icon>
                  <mdb-icon
                    *ngIf="
                      sortBy == sort_elements[i].head && sortOrder == 'asc'
                    "
                    (click)="
                      sortTable(
                        sort_elements[i].head,
                        'desc',
                        sort_elements[i].type
                      )
                    "
                    fas
                    icon="caret-up"
                    class="sort-icon"
                  ></mdb-icon>
                  <mdb-icon
                    *ngIf="
                      sortBy == sort_elements[i].head && sortOrder == 'desc'
                    "
                    (click)="
                      sortTable(
                        sort_elements[i].head,
                        'asc',
                        sort_elements[i].type
                      )
                    "
                    fas
                    icon="caret-down"
                    class="sort-icon"
                  ></mdb-icon>
                </ng-template>
              </th>
            </tr>
            <tr class="event-filters">
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="'col-' + sort_elements[i].head"
              >
                <ng-template [ngIf]="filter_elements[i].filter">
                  <!--all filter types -->
                  <div
                    class="md-form md-filter-input mb-0 mt-0"
                    *ngIf="filter_elements[i].type !== 'select'"
                  >
                    <!--start text filter-->
                    <input
                      *ngIf="filter_elements[i].type === 'text'"
                      type="text"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      class="form-control"
                      [id]="'filter-' + filter_elements[i].target"
                      mdbInput
                      (ngModelChange)="
                              this.triggerFilterUpdate.next($event)
                            "
                    />
                    <!--end text filter-->
                    <!--start number filter-->
                    <input
                      *ngIf="filter_elements[i].type === 'number'"
                      type="number"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      class="form-control"
                      [id]="'filter-' + filter_elements[i].target"
                      mdbInput
                      (ngModelChange)="
                              this.triggerFilterUpdate.next($event)
                            "
                    />
                    <!--end number filter-->
                    <fa-icon
                      *ngIf="
                        filterText[filter_elements[i].target] === '' ||
                        filterText[filter_elements[i].target] === null
                      "
                      [icon]="faFilter"
                    ></fa-icon>
                    <fa-icon
                      class="cursor-pointer"
                      *ngIf="
                        filterText[filter_elements[i].target] !== '' &&
                        filterText[filter_elements[i].target] !== null
                      "
                      [icon]="faFilterCircleXmark"
                      (click)="resetFilter(i)"
                    ></fa-icon>
                  </div>
                  <!--end all filter types -->
                </ng-template>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              mdbTableCol
              *ngFor="let group of groups; let group_index = index"
            >
              <td class="action-icons">
                <div class="btn-group list-btns-sm btn-icons">
                  <a
                    size="lg"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-flat btn-icon"
                    (click)="openManageGroupModal(group, 'update')"
                    mdbTooltip="Update {{
                      labels.userGroup.singular | titlecase
                    }}"
                    placement="bottom"
                  >
                    <fa-icon [icon]="faUsersCog"></fa-icon>
                  </a>
                  <a
                    size="lg"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-flat btn-icon"
                    (click)="openManageGroupModal(group, 'delete')"
                    mdbTooltip="Delete {{
                      labels.userGroup.singular | titlecase
                    }}"
                    placement="bottom"
                    ><fa-icon [icon]="faTrashAlt"></fa-icon>
                  </a>
                </div>
              </td>
              <td class="action-column">
                {{ group.name }}
              </td>
              <td>{{ group.number_of_members }}</td>
              <td>{{ group.number_of_events }}</td>
            </tr>
          </tbody>
          <tfoot class="grey lighten-5 w-100" *ngIf="groups.length">
            <tr>
              <td colspan="12">
                <p-paginator
                  #pagination
                  (onPageChange)="onPageChange($event)"
                  [first]="first"
                  [rows]="rows"
                  [totalRecords]="userGroupsLength"
                  [showFirstLastIcon]="true"
                  [showCurrentPageReport]="true"
                  currentPageReportTemplate="{first} - {last} of {totalRecords}"
                ></p-paginator>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
