<mdb-card
  class="event-management-card content-card loading-card"
  *ngIf="tableLoading"
>
  <div class="row">
    <div class="col-12">
      <!-- if content hasnt load -->
      <div
        class="loading-screen d-flex align-content-center justify-content-center"
      >
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg === ''"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg !== ''"
        >
          <div
            class="alert alert-danger"
            role="alert"
            [innerHTML]="errorRetrievingMsg"
          ></div>
        </div>
      </div>
    </div>
  </div>
</mdb-card>
<mdb-card
  class="management-card content-card"
  *ngIf="!tableLoading && !allEvents.length"
>
  <div class="row flex-row flex-nowrap">
    <div class="col-12 p-4">
      You have added no {{ labels.event.plural }} yet.<span
        class="color-theme-base-medium"
        ><button
          (click)="openEventManagement('add')"
          class="inline"
          mdbBtn
          type="button"
          flat="true"
          size="lg"
          mdbWavesEffect
        >
          &nbsp;Add a new {{ labels.event.singular }} now.
        </button></span
      >
    </div>
  </div>
</mdb-card>
<mdb-card
  class="management-card content-card"
  *ngIf="!tableLoading && allEvents.length"
>
  <div class="row flex-row flex-nowrap">
    <div class="col-12 p-0">
      <div class="table-responsive">
        <table
          class="table table-striped"
          mdbTable
          #tableEl="mdbTable"
          small="true"
        >
          <colgroup class="events">
            <col
              *ngFor="let head of headElements; let i = index"
              [ngClass]="'col-' + sort_elements[i].head"
            />
          </colgroup>
          <thead class="theme-base-medium-dark white-text">
            <tr class="sorting">
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="'col-' + sort_elements[i].head"
              >
                <div class="header-wrapper">
                  <span class="text-ellipsis" *ngIf="head !== 'action'">{{
                    head
                  }}</span>
                  <ng-template [ngIf]="sort_elements[i].type !== 'none'">
                    <mdb-icon
                      fas
                      *ngIf="sortBy !== sort_elements[i].head"
                      icon="sort"
                      class="sort-icon"
                      [mdbTooltip]="'Sort by ' + head"
                      placement="bottom"
                      (click)="
                        sortTable(
                          sort_elements[i].head,
                          'desc',
                          sort_elements[i].type
                        )
                      "
                    ></mdb-icon>
                    <mdb-icon
                      *ngIf="
                        sortBy == sort_elements[i].head && sortOrder == 'asc'
                      "
                      (click)="
                        sortTable(
                          sort_elements[i].head,
                          'desc',
                          sort_elements[i].type
                        )
                      "
                      fas
                      icon="caret-up"
                      class="sort-icon"
                    ></mdb-icon>
                    <mdb-icon
                      *ngIf="
                        sortBy == sort_elements[i].head && sortOrder == 'desc'
                      "
                      (click)="
                        sortTable(
                          sort_elements[i].head,
                          'asc',
                          sort_elements[i].type
                        )
                      "
                      fas
                      icon="caret-down"
                      class="sort-icon"
                    ></mdb-icon>
                  </ng-template>
                </div>
              </th>
            </tr>
            <tr class="event-filters">
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="'col-' + sort_elements[i].head"
              >
                <ng-template [ngIf]="filter_elements[i].filter">
                  <!--all filter types except access-->
                  <div
                    class="md-form md-filter-input mb-0 mt-0"
                    [ngClass]="'md-filter-' + filter_elements[i].type"
                    *ngIf="filter_elements[i].type !== 'access'"
                  >
                    <!--start text filter-->
                    <input
                      *ngIf="filter_elements[i].type === 'text'"
                      type="text"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      class="form-control text-ellipsis"
                      [id]="'filter-' + filter_elements[i].target"
                      [placeholder]="'All ' + filter_elements[i].placeholder"
                      (ngModelChange)="this.triggerFilterUpdate.next($event)"
                      mdbInput
                    />
                    <!--end text filter-->
                    <!--start number filter-->
                    <input
                      *ngIf="filter_elements[i].type === 'number'"
                      type="number"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      class="form-control text-ellipsis"
                      [id]="'filter-' + filter_elements[i].target"
                      [placeholder]="'All ' + filter_elements[i].placeholder"
                      (ngModelChange)="this.triggerFilterUpdate.next($event)"
                      mdbInput
                    />
                    <!--end number filter-->
                    <!--start start_at filter-->
                    <mdb-date-picker
                      *ngIf="filter_elements[i].type === 'start_at'"
                      #datePickerStart
                      class="start-picker"
                      name="startDateFilter"
                      [id]="'filter-' + filter_elements[i].target"
                      [options]="startAtFilterPickerOptions"
                      placeholder="Add Dates"
                      [inline]="true"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      (ngModelChange)="this.triggerFilterUpdate.next($event)"
                      inlineIcon="fas fa-calendar-alt"
                    ></mdb-date-picker>
                    <!--end start_at filter-->
                    <!--start end_at filter-->
                    <mdb-date-picker
                      *ngIf="filter_elements[i].type === 'end_at'"
                      #datePickerEnd
                      class="end-picker"
                      name="endDateFilter"
                      [id]="'filter-' + filter_elements[i].target"
                      [options]="endAtFilterPickerOptions"
                      placeholder="Add Dates"
                      [inline]="true"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      (ngModelChange)="this.triggerFilterUpdate.next($event)"
                      inlineIcon="fas fa-calendar-alt"
                    ></mdb-date-picker>
                    <!--end end_at filter-->
                    <fa-icon
                      class="cursor-pointer"
                      *ngIf="
                        filterText[filter_elements[i].target] !== '' &&
                        filterText[filter_elements[i].target] !== null
                      "
                      [icon]="faTimes"
                      (click)="resetFilter(i)"
                    ></fa-icon>
                  </div>
                  <!--end all filter types except access-->
                  <!--start access filter type-->
                  <div
                    class="md-form md-filter-input md-filter-access mb-0 mt-0"
                    *ngIf="filter_elements[i].type === 'access'"
                  >
                    <!--start access filter-->
                    <mdb-select-2
                      class="custom-arrow"
                      [id]="'filter-' + filter_elements[i].target"
                      [dropdownClass]="'access-dropdown'"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      (ngModelChange)="this.triggerFilterUpdate.next($event)"
                    >
                      <mdb-select-option
                        *ngFor="let option of filter_elements[i].options"
                        [value]="option.value"
                        >{{ option.label }}</mdb-select-option
                      >
                    </mdb-select-2>
                    <!--end access filter-->
                    <fa-icon
                      class="cursor-pointer"
                      *ngIf="filterText[filter_elements[i].target] !== 'all'"
                      [icon]="faTimes"
                      (click)="resetFilter(i, 'all')"
                    ></fa-icon>
                  </div>
                  <!--end access filter type-->
                </ng-template>
              </th>
            </tr>
          </thead>
          <tbody #row>
            <tr *ngIf="events.length == 0">
              <td class="action-icons">
                <div class="empty-query">
                  <span>No {{ labels.event.plural }} for this query</span>
                </div>
              </td>
              <td colspan="7"></td>
            </tr>
            <tr *ngFor="let event of events; let i = index">
              <td class="action-icons">
                <span class="btn-group list-btns-sm btn-icons">
                  <button
                    size="lg"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-flat btn-icon ml-2"
                    mdbTooltip="Edit {{ labels.event.singular }}"
                    placement="bottom"
                    (click)="openEventManagement('update', event)"
                  >
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </button>
                  <button
                    size="lg"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-flat btn-icon"
                    (click)="openManageMediaModal(event)"
                    mdbTooltip="Manage {{ labels.event.singular }} {{
                      labels.media.plural
                    }}"
                    placement="bottom"
                  >
                    <fa-icon [icon]="faImages"></fa-icon>
                  </button>
                  <button
                    size="lg"
                    mdbBtn
                    mdbWavesEffect
                    [disabled]="
                      event.is_public || event.is_access_code_required
                    "
                    class="btn-flat btn-icon"
                    (click)="openUserManagementModal(event, i)"
                    mdbTooltip="Manage {{ labels.event.singular }} {{
                      labels.user.plural
                    }}"
                    placement="bottom"
                  >
                    <fa-icon [icon]="faUsers"></fa-icon>
                  </button>
                  <button
                    size="lg"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-flat btn-icon"
                    (click)="cloneEvent(event)"
                    mdbTooltip="Clone {{ labels.event.singular }}"
                    placement="bottom"
                  >
                    <fa-icon [icon]="faClone"></fa-icon>
                  </button>
                  <button
                    size="lg"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-flat btn-icon"
                    (click)="openEventManagement('delete', event)"
                    mdbTooltip="Delete {{ labels.event.singular }}"
                    placement="bottom"
                  >
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </button>
                </span>
              </td>
              <td>
                <span>{{ event.event_name }}</span>
              </td>
              <td>
                <span class="date">{{
                  event.start_at | amDateFormat : "MMM D YYYY"
                }}</span>
                <br />
                <span class="time">{{
                  event.start_at | amDateFormat : "h:mm a"
                }}</span>
              </td>
              <td>
                <span
                  class="date"
                  [ngClass]="{ 'different-day': !event.sameDay }"
                  >{{ event.end_at | amDateFormat : "MMM D YYYY" }}</span
                >
                <br />
                <span class="time">{{
                  event.end_at | amDateFormat : "h:mm a"
                }}</span>
              </td>
              <td
                [ngClass]="{
                  'access-code':
                    !event.is_public && event.is_access_code_required
                }"
              >
                <span
                  *ngIf="!event.is_public"
                  class="color-theme-base-medium-alt bold"
                  >Private: </span
                ><br *ngIf="!event.is_public" /><span
                  class="color-theme-base-medium"
                  *ngIf="!event.is_public && event.has_invitees"
                  >By Invitation</span
                ><span
                  class="color-theme-base-status-processing"
                  *ngIf="!event.is_public && event.is_access_code_required"
                  >By Access Code</span
                ><span
                  *ngIf="event.is_public"
                  class="color-theme-base-medium-light-alt bold"
                  >Public</span
                >
                <div
                  *ngIf="!event.is_public && event.is_access_code_required"
                  class="input-group mr-sm-2"
                >
                  <input
                    mdbInput
                    [type]="event.showAccessCode ? 'text' : 'password'"
                    class="form-control py-0"
                    id="inlineFormInputGroupUsername2"
                    [value]="event.access_code"
                    [ngClass]="{ 'code-hidden': !event.showAccessCode }"
                  />
                  <div class="input-group-append cursor-pointer">
                    <div
                      class="input-group-text"
                      (click)="toggleAccessCode(event.id)"
                    >
                      <fa-icon
                        *ngIf="event.showAccessCode"
                        [icon]="faEyeSlash"
                      ></fa-icon>
                      <fa-icon
                        *ngIf="!event.showAccessCode"
                        [icon]="faEye"
                      ></fa-icon>
                    </div>
                    <button
                      class="input-group-text input-end"
                      (click)="copyAccessCode(event)"
                    >
                      <fa-icon [icon]="faCopy"></fa-icon>
                    </button>
                  </div>
                </div>
              </td>
              <td class="text-center">{{ event.user_invitees_count }}</td>
              <td class="text-center">{{ event.group_invitees_count }}</td>
              <td class="text-center">{{ event.experiences_count }}</td>
            </tr>
          </tbody>
          <tfoot class="grey lighten-5 w-100" *ngIf="events.length">
            <tr>
              <td colspan="12">
                <p-paginator
                  #pagination
                  (onPageChange)="onPageChange($event)"
                  [first]="first"
                  [rows]="rows"
                  [totalRecords]="eventsLength"
                  [showFirstLastIcon]="true"
                  [showCurrentPageReport]="true"
                  currentPageReportTemplate="{first} - {last} of {totalRecords}"
                ></p-paginator>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</mdb-card>
