<div class="heading-content">
  <div *ngIf="!experienceLoading">
    <h4>{{ currentExperience.name }}</h4>
    <div class="callout-text-wrapper">
      <p>{{ labels.event.plural | titlecase }}</p>
    </div>
  </div>
  <div *ngIf="experienceLoading">
    <h4>Loading Experience...</h4>
  </div>
</div>
<mdb-card
  class="single-experience-card content-card"
  *ngIf="!experienceLoading"
>
  <div class="container-fluid" *ngIf="loading && recursionCounter < 10">
    <div class="row">
      <div class="col-12">
        <p class="loading-text">Loading...</p>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="loading && recursionCounter === 10">
    <div class="row">
      <div class="col-12">
        <p class="loading-text">
          There was an issue loading this Experience. Please try and refresh
          this page.
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="!loading">
    <div class="row justify-content-between">
      <div class="col-4">
        <div class="md-form w-100 mr-2 experience-search">
          <input
            class="form-control"
            id="searchEvents"
            type="search"
            name="searchEvents"
            autocomplete="off"
            [(ngModel)]="searchText"
            mdbInput
          />
          <label for="searchEvents"
            >Search {{ labels.event.plural | titlecase }}</label
          >
        </div>
      </div>
      <div class="col-6">
        <div class="btn-spacing text-right">
          <button
            *ngIf="!loading"
            class="theme-modal-button btn"
            type="button"
            mdbBtnp
            mdbWavesEffect
            (click)="openClassesModal()"
          >
            <mdb-icon far icon="calendar-check" class="pr-1"></mdb-icon>
            {{ labels.schedule.singular | titlecase }}
            {{ labels.event.singular | titlecase }}
          </button>
          <!-- <button
            *ngIf="!loading && user.role_type_id === 1"
            class="theme-modal-button btn"
            mdbBtn
            mdbWavesEffect
            (click)="openSuperAdminUpdateModal()"
          >
            <mdb-icon
              fas
              icon="users-cog"
              class="pr-1"
            ></mdb-icon>
            SE Updates
          </button> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-group flex-wrap">
          <div
            *ngFor="
              let event of events | filterBy : 'name' : searchText;
              let i = index
            "
          >
            <!-- card -->
            <mdb-card
              [ngClass]="{
                'viewer-mode': [4, 6].indexOf(user.role_type_id) !== -1
              }"
            >
              <!-- card body -->
              <mdb-card-body>
                <!-- action button -->
                <div class="dropdown edit-add-dropdown" mdbDropdown>
                  <a
                    *ngIf="[1, 2, 3].indexOf(user.role_type_id) !== -1"
                    mdbDropdownToggle
                    mdbBtn
                    flat="true"
                    class="activator px-0 mx-0 my-0 waves-light"
                    type="button"
                    mdbWavesEffect
                    mdbTooltip="Edit / Add"
                    placement="bottom"
                  >
                    <mdb-icon fas icon="ellipsis-v" size="lg"></mdb-icon>
                  </a>

                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      type="button"
                      (click)="
                        openExperiencesModal(currentExperience, event, 'update')
                      "
                      >Edit {{ labels.event.singular | titlecase }}</a
                    >
                    <a
                      *ngIf="showLegacyEventMeta"
                      class="dropdown-item"
                      type="button"
                      (click)="openExperienceMetaModalLegacy(event)"
                      >Edit {{ labels.event.singular | titlecase }} Settings</a
                    >
                    <a
                      *ngIf="showEventProperties"
                      class="dropdown-item"
                      type="button"
                      (click)="openExperienceMetaModal(event)"
                      >Edit
                      {{ labels.event.singular | titlecase }} Properties</a
                    >
                    <a
                      class="dropdown-item"
                      type="button"
                      (click)="
                        openExperiencesModal(currentExperience, event, 'delete')
                      "
                      >Remove {{ labels.event.singular | titlecase }}</a
                    >
                    <div class="divider dropdown-divider"></div>

                    <a
                      class="dropdown-item"
                      type="button"
                      (click)="openUserToClassModal(event)"
                      >Add {{ labels.user.singular | titlecase }}</a
                    >
                    <a
                      class="dropdown-item"
                      type="button"
                      (click)="openGroupToClassModal(event)"
                      >Add {{ labels.userGroup.singular | titlecase }}</a
                    >
                  </div>
                </div>
                <!-- card title -->
                <mdb-card-title>
                  <h5 class="mb-1">{{ event.name }}</h5>
                  <p
                    class="font-weight-bold"
                    *ngIf="this.user.role_type_id === 1"
                  >
                    ID: {{ event.id }}
                  </p>
                </mdb-card-title>
                <!-- body list -->
                <!-- add space between the title and content -->
                <mdb-card-text class="mb-0">
                  <!-- first section users, groups -->
                  <ul class="list-unstyled" *ngIf="editPermissions">
                    <li class="number-of-users">
                      Scheduled {{ labels.user.plural | titlecase }} :
                      {{ event.userCount }}
                    </li>
                    <li class="number-of-groups">
                      Scheduled {{ labels.userGroup.plural | titlecase }}:
                      {{ event.userGroupCount }}
                    </li>
                    <hr />
                  </ul>
                  <ul class="list-unstyled" *ngIf="showLocation">
                    <li class="event-building" *ngIf="event.building === null">
                      {{ labels.building.singular | titlecase }}:
                      {{ labels.building.singular | titlecase }} not set
                      <mdb-icon
                        *ngIf="editPermissions"
                        mdbTooltip="Add Building"
                        placement="bottom"
                        fas
                        icon="plus-circle"
                        (click)="openMetaModal('building', event, 'add')"
                      ></mdb-icon>
                    </li>
                    <li class="event-building" *ngIf="event.building !== null">
                      {{ labels.building.singular | titlecase }}:
                      {{ event.building.label | titlecase }}
                      <mdb-icon
                        *ngIf="editPermissions"
                        mdbTooltip="Remove Building"
                        placement="bottom"
                        fas
                        icon="minus-circle"
                        (click)="openMetaModal('building', event, 'remove')"
                      ></mdb-icon>
                      {{ event.building.label }}
                      <!-- <mdb-icon
                        *ngIf="editPermissions"
                        fas
                        icon="edit"
                        (click)="openMetaModal('building', event, 'edit')"
                      ></mdb-icon> -->
                    </li>
                    <hr />
                  </ul>
                  <!-- dates bottom -->
                  <div
                    class="d-flex justify-content-around text-center event-dates"
                  >
                    <div class="start-date">
                      <h6 class="strong color-theme-base-dark">
                        {{ labels.startDate.singular | titlecase }}:
                      </h6>
                      <p class="color-theme-base-medium-dark mb-0">
                        {{ event.startDate | amDateFormat : "ddd." }}
                      </p>
                      <p class="color-theme-base-medium-dark mb-0">
                        {{ event.startDate | amDateFormat : "Do MMM YYYY" }}
                      </p>
                      <p class="color-theme-base-medium-dark">
                        <span class="color-theme-base-dark">Time:</span>
                        {{ event.startDate | amDateFormat : "h:mm a" }}
                      </p>
                    </div>
                    <div class="vertical-divider"></div>
                    <div class="end-date">
                      <h6 class="strong color-theme-base-dark">
                        {{ labels.endDate.singular | titlecase }}:
                      </h6>
                      <p class="color-theme-base-medium-dark mb-0">
                        {{ event.endDate | amDateFormat : "ddd." }}
                      </p>
                      <p class="color-theme-base-medium-dark mb-0">
                        {{ event.endDate | amDateFormat : "Do MMM YYYY" }}
                      </p>
                      <p class="color-theme-base-medium-dark">
                        <span class="color-theme-base-dark">Time:</span>
                        {{ event.endDate | amDateFormat : "h:mm a" }}
                      </p>
                    </div>
                  </div>
                </mdb-card-text>
              </mdb-card-body>
              <mdb-card-footer
                *ngIf="[1, 2, 3].indexOf(user.role_type_id) !== -1"
                class="text-center"
              >
                <div
                  class="btn-group manage-dropdown"
                  mdbDropdown
                  [dropup]="true"
                >
                  <button
                    mdbDropdownToggle
                    mdbBtn
                    flat="true"
                    class="dropdown-toggle waves-light py-0"
                    type="button"
                  >
                    Manage
                  </button>
                  <div class="dropdown-menu dropdown-menu-center">
                    <a
                      class="dropdown-item"
                      type="button"
                      (click)="openManageUsersModal(event, i)"
                      >Manage Single {{ labels.user.plural | titlecase }}</a
                    >
                    <a
                      class="dropdown-item"
                      type="button"
                      (click)="openManageGroupsModal(event, i)"
                      >Manage {{ labels.userGroup.plural | titlecase }}</a
                    >
                    <a
                      class="dropdown-item"
                      type="button"
                      (click)="openManageMediaModal(event)"
                      >Manage {{ labels.media.plural | titlecase }}</a
                    >
                  </div>
                </div>
              </mdb-card-footer>
            </mdb-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</mdb-card>
