<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 text-center">
        <h5>User Inventory Breakdown</h5>
      </div>
    </div>
    <div class="row" *ngIf="!userLoading">
      <div class="col-4 users-select d-flex justify-content-end">
        <div class="md-form mb-5 w-100">
          <mdb-select
            [options]="usersSelect"
            id="usersSelect"
            mdbInput
            mdbValidate
            placeholder="Select {{labels.user.singular | titlecase}}"
            (selected)="getInventoryMetrics($event)"
          ></mdb-select>
          <label for="user-to-reivew">Select {{labels.user.singular | titlecase}} to Review</label>
        </div>
      </div>
    </div>
    <div class="row graph-spacing-bottom">
      <div class="col-12 text-center" *ngIf="!metricsLoading"> 
        <h5>Select {{labels.user.singular | titlecase}} Overall Score</h5>
      </div>
      <div class="col-12">
        <div
          style="
            position: relative;
            height: 50vh;
            width: auto;
            margin-top: 2rem;
            margin-bottom: 3rem;
          "
        >
          <canvas #overallChart> </canvas>
        </div>
      </div>
    </div>
    <div class="row graph-spacing">
      <div class="col-6 text-center" *ngIf="!metricsLoading">
        <h5>Assigning Status Score</h5>
      </div>
      <div class="col-6 text-center" *ngIf="!metricsLoading">
        <h5>Assigning Amounts Score</h5>
      </div>
      <div class="col-6">
        <div
          style="
            position: relative;
            height: 50vh;
            width: auto;
            margin-top: 2rem;
            margin-bottom: 3rem;
          "
        >
          <canvas #statusChart> </canvas>
        </div>
      </div>
      <div class="col-6">
        <div
          style="
            position: relative;
            height: 50vh;
            width: auto;
            margin-top: 2rem;
            margin-bottom: 3rem;
          "
        >
          <canvas #countsChart> </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
