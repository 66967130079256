import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import videojs from "video.js";
import Player from "video.js/dist/types/player";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild("target", { static: true }) target: ElementRef;
  @Output("videoLoaded") videoLoaded = new EventEmitter();

  // See options: https://videojs.com/guides/options
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };

  player: Player;

  constructor(private elementRef: ElementRef) { }

  // Instantiate a Video.js player OnInit
  ngOnInit() {

    let self = this;

    this.player = videojs(
      this.target.nativeElement,
      this.options,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
        self.videoLoaded.emit(this);
      }
    );
  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
