import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { CoolLocalStorage } from "@angular-cool/storage";
import { MDBModalRef } from "ng-uikit-pro-standard";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

//rxjs
import { map, startWith } from "rxjs/operators";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-select-public-event",
  templateUrl: "./select-public-event.component.html",
  styleUrls: ["./select-public-event.component.scss"],
})
export class SelectPublicEventComponent implements OnInit {
  //incoming
  public teamID: number;
  public events: any;

  //persistent
  public token: string;
  public loading: boolean = true;
  public labels: any;

  //autocomplete related
  public textNoResults: string = "No results found";
  searchText = new Subject();
  results: Observable<{ event_name: string }[]>;
  public selectForm: UntypedFormGroup;

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "",
    retry: "Retry",
  };

  //outbound
  private outgoing: Subject<any> = new Subject();

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    public selectPublicEventModal: MDBModalRef
  ) {}

  ngOnInit(): void {
    this.continueType = "none";

    this.selectForm = new UntypedFormGroup({
      event: new UntypedFormControl(""),
    });

    this.retrieveToken();
    this.retrieveLabels();
    this.processEvents();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private processEvents() {
    let startingValue = {
      event_name: "",
      value: -1,
    };

    this.results = this.selectForm.controls.event.valueChanges.pipe(
      startWith(startingValue),
      map((event) => this.filterEvents(event))
    );
    this.textNoResults = "No results found";

    this.loading = false;
  }

  private filterEvents(value: any): { event_name: string }[] {
    const filterValue = value.event_name ? value.event_name.toLowerCase() : "";
    return this.events.filter((item) =>
      item.event_name.toLowerCase().includes(filterValue)
    );
  }

  public onEventDisplayValue(event): string | undefined {
    return event ? event.event_name : event;
  }

  public selectPublicEvent() {
    let incomingValues = this.selectForm.value;
    let event = incomingValues.event;

    if (event === "") {
      this.msgs.errorMsg = "You must select a public event";
      return false;
    }

    let outbound = {
      action: "update",
      event_id: event.id,
    };

    this.outgoing.next(outbound);
    this.selectPublicEventModal.hide();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.selectPublicEventModal.hide();
        break;
      case "reset-form":
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
