import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ScheduleUserComponent } from "./modals/schedule-user/schedule-user.component";

import { PersistentModule } from "./../persistent/persistent.module";
import { SharedModulesModule } from "./../shared-modules/shared-modules.module";
import { ScheduleClassComponent } from "./modals/schedule-class/schedule-class.component";
import { ScheduleUserToClassComponent } from "./modals/schedule-user-to-class/schedule-user-to-class.component";
import { ScheduleGroupComponent } from "./modals/schedule-group/schedule-group.component";
import { ScheduleGroupToClassComponent } from "./modals/schedule-group-to-class/schedule-group-to-class.component";
import { PhotoRegionToClassComponent } from "./modals/photo-region-to-class/photo-region-to-class.component";
import { ExperienceMetaComponent } from './modals/experience-meta/experience-meta.component';
import { SelectMetaMediaComponent } from './modals/select-meta-media/select-meta-media.component';
import { ReactiveFormsModule } from "@angular/forms";
import { AddClassRoomComponent } from "./modals/add-class-room/add-class-room.component";
import { EditClassRoomComponent } from './modals/edit-class-room/edit-class-room.component';
import { AddEventConnectionModalComponent } from './modals/add-event-connection-modal/add-event-connection-modal.component';
import { ExperiencesAllComponent } from './experiences-all/experiences-all.component';
import { ExperiencesSingleComponent } from './experiences-single/experiences-single.component';
import { ManageUsersComponent } from './modals/manage-users/manage-users.component';
import { ManageGroupsComponent } from './modals/manage-groups/manage-groups.component';
import { ManageGroupsModalComponent } from './modals/manage-groups-modal/manage-groups-modal.component';
import { ViewGroupModalComponent } from './modals/view-group-modal/view-group-modal.component';
import { SuperAdminExperienceUpdatesComponent } from './modals/super-admin-experience-updates/super-admin-experience-updates.component';
import { AddTeamPropModalComponent } from './modals/add-team-prop-modal/add-team-prop-modal.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { EventSettingsModalComponent } from './modals/event-settings-modal/event-settings-modal.component';
import { MediaToClassMediaManagerComponent } from './modals/media-to-class-media-manager/media-to-class-media-manager.component';
import { MediaManagementModule } from '../media-management/media-management.module';


@NgModule({
  declarations: [
    ScheduleUserComponent,
    ScheduleClassComponent,
    ScheduleUserToClassComponent,
    ScheduleGroupComponent,
    ScheduleGroupToClassComponent,
    PhotoRegionToClassComponent,
    ExperienceMetaComponent,
    SelectMetaMediaComponent,
    AddClassRoomComponent,
    EditClassRoomComponent,
    AddEventConnectionModalComponent,
    ExperiencesAllComponent,
    ExperiencesSingleComponent,
    ManageUsersComponent,
    ManageGroupsComponent,
    ManageGroupsModalComponent,
    ViewGroupModalComponent,
    SuperAdminExperienceUpdatesComponent,
    AddTeamPropModalComponent,
    EventSettingsModalComponent,
    MediaToClassMediaManagerComponent,
  ],
  imports: [
    CommonModule,
    SharedModulesModule,
    PersistentModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro.forRoot(),
    MediaManagementModule
  ],
  entryComponents: [
    ScheduleUserComponent,
    ScheduleClassComponent,
    ScheduleUserToClassComponent,
    ScheduleGroupComponent,
    ScheduleGroupToClassComponent,
    PhotoRegionToClassComponent
  ],
})
export class ExperienceManagementModule {}
