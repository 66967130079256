import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "singleSort",
})
export class SingleSortPipe implements PipeTransform {
  transform(items: [], direction: string, head: string, type: string) {
    let sortedItems = [];
    
    if (items === undefined || !Array.isArray(items)) return items;

    switch (type) {
      case "string":
        if (direction === "asc") {
          sortedItems = items.sort((a, b) => {
            return this.nullStringCheck(a[head]).localeCompare(
              this.nullStringCheck(b[head])
            );
          });
        } else {
          sortedItems = items.sort((a, b) => {
            return this.nullStringCheck(b[head]).localeCompare(
              this.nullStringCheck(a[head])
            );
          });
        }
        break;
      case "date":
        if (direction === "desc") {
          sortedItems = items.sort((a, b) => {
            let dateA = this.convertDateToEpoch(a[head]);
            let dateB = this.convertDateToEpoch(b[head]);

            return dateB - dateA;
          });
        } else {
          sortedItems = items.sort((a, b) => {
            let dateA = this.convertDateToEpoch(a[head]);
            let dateB = this.convertDateToEpoch(b[head]);
            return dateA - dateB;
          });
        }

        break;
      case "array_count":
        if (direction === "desc") {
          sortedItems = items.sort((a: [], b: []) => {
            return b[head].length - a[head].length;
          });
        } else {
          sortedItems = items.sort((a: [], b: []) => {
            return a[head].length - b[head].length;
          });
        }

        break;
      default:
        if (direction === "desc") {
          sortedItems = items.sort((a, b) => {
            return b[head] - a[head];
          });
        } else {
          sortedItems = items.sort((a, b) => {
            return a[head] - b[head];
          });
        }
    }

    return sortedItems;
  }

  private nullStringCheck(incoming) {
    if (incoming === null) incoming = "";

    return incoming;
  }

  private convertDateToEpoch(incomingDate): number {
    return parseInt(
      moment(new Date(incomingDate), "MMMM D YYYY, H:mm A Z").format("X")
    );
  }
}
