import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MetricsServicesService } from "../../services/metrics-services.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";

@Component({
  selector: "app-session-breakdown",
  templateUrl: "./session-breakdown.component.html",
  styleUrls: ["./session-breakdown.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SessionBreakdownComponent implements OnInit {
  //persistent vars
  public teamID: number;
  public clientCode: string;
  public session_id: number;
  public labels;

  public navigationLoading: boolean = true;
  public navActive: boolean = false;
  public session_name: string;
  public session_date: any;

  public clientSettings: any;
  public users: any = [];

  public previous_session: number;
  public next_session: number;

  //security vars
  public token: string;

  public metricsOptions: {
    user_inventory: boolean;
    user_entries: boolean;
    user_speaking: boolean;
    users_gazing: boolean;
    user_inventory_table: boolean;
    data_entry: boolean;
  } = {
      user_inventory: false,
      user_entries: false,
      user_speaking: false,
      users_gazing: false,
      user_inventory_table: false,
      data_entry: false,
    };

  public loading: {
    user_inventory: boolean;
    user_entries: boolean;
    user_speaking: boolean;
    users_gazing: boolean;
    user_inventory_table: boolean;
    data_entry: boolean;
  } = {
      user_inventory: false,
      user_entries: false,
      user_speaking: false,
      users_gazing: false,
      user_inventory_table: false,
      data_entry: false,
    };

  public start_date;
  public end_date;
  public breakdownType;
  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private router: Router,
    private _metricsServicesService: MetricsServicesService,
    private _settingsService: SettingsService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    this.retrieveTeamID();
    this.retrieveLabels();
    this.retrieveClientCode();

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.params.subscribe((params) => {
      this.session_id =
        params["session_id"] !== undefined ? parseInt(params["session_id"]) : 0;
    });
    ;

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    let options = this.clientSettings.interactiveMetricsOptions.options;
    this.metricsOptions.user_inventory = options.user_inventory.is_default;
    this.metricsOptions.user_entries = options.user_entries.is_default;
    this.metricsOptions.user_speaking = options.user_speaking.is_default;
    this.metricsOptions.users_gazing = options.users_gazing.is_default;
    this.metricsOptions.data_entry = options.data_entry.is_default;

    if (this.metricsOptions.user_inventory) this.loading.user_inventory = true;
    if (!this.metricsOptions.user_inventory && this.metricsOptions.user_entries)
      this.loading.user_entries = true;
    if (
      !this.metricsOptions.user_inventory &&
      !this.metricsOptions.user_entries &&
      this.metricsOptions.user_speaking
    )
      this.loading.user_speaking = true;
    if (
      !this.metricsOptions.user_inventory &&
      !this.metricsOptions.user_entries &&
      !this.metricsOptions.user_speaking &&
      this.metricsOptions.users_gazing
    )
      this.loading.users_gazing = true;
    if (
      !this.metricsOptions.user_inventory &&
      !this.metricsOptions.user_entries &&
      !this.metricsOptions.user_speaking &&
      !this.metricsOptions.users_gazing &&
      this.metricsOptions.data_entry
    )
      this.loading.data_entry = true;

    this.route.queryParams.subscribe((params) => {
      this.start_date = params["start_date"];
      this.end_date = params["end_date"];
      this.breakdownType = params["breakdownType"];
    });

    this.retrieveToken();
    this.retrieveData();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    //retrieve users
    this.users = await this.retreiveUsers(getOptions);

    //retrieve session data
    let getSessionNav = await this.getSessionsNavigation();

    let data = getSessionNav.navigation;
    if (data[2] !== undefined || data[3] !== undefined) {
      this.session_name = data[0];
      this.session_date = data[1];
      this.previous_session = data[2];
      this.next_session = data[3];
      this.navigationLoading = false;
      this.navActive = true;
    } else {
      this.navigationLoading == false;
    }
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  /**
   * Retrieve Team Users
   * @param options
   * @returns
   */
  private retreiveUsers(options) {
    let retrieverUsers = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/users",
      "get",
      {},
      options
    );

    return retrieverUsers.toPromise();
  }

  private getSessionsNavigation() {
    let sessionNavigation =
      this._metricsServicesService.restfulForetellAPIRequest(
        "/sessions/client/" +
        this.teamID +
        "/session/" +
        this.session_id +
        "/navigation",
        "get",
        {}
      );

    return sessionNavigation.toPromise();
  }

  public tabSelected(targetTab) {
    ;

    this.loading[targetTab] = true;
  }
}
