import { CoolLocalStorage } from "@angular-cool/storage";
import { group } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { AddPersonaPermissionModalComponent } from "../add-persona-permission-modal/add-persona-permission-modal.component";

@Component({
  selector: "app-view-persona-permission-modal",
  templateUrl: "./view-persona-permission-modal.component.html",
  styleUrls: ["./view-persona-permission-modal.component.scss"],
})
export class ViewPersonaPermissionModalComponent implements OnInit {
  public team_id: number;
  public persona: any;
  public permissions: any;
  public token: string;
  public loading: boolean;

  public addPersonaPermissionModal: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    public viewPersonaPermissionModal: MDBModalRef,
    private modalService: MDBModalService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.retrieveToken();
    this.retrievePersonaPermissions();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrievePersonaPermissions() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let persona_permissions = this._xrPlatformRestService.restfulAPIQuery(
      "/personas/getallclasspersonapermissions/" +
        this.team_id +
        "/" +
        this.persona.id,
      "get",
      {},
      getOptions
    );
    persona_permissions.subscribe((response) => {
      this.permissions = response.persona_permissions;
      this.loading = false;
      (error) => {
        //@todo: handle error
      };
    });
  }

  public clickDeletePersonaPermission(
    persona_permission_id: number,
    index: number
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let personaPermissionDelete = this._xrPlatformRestService.restfulAPIQuery(
      "/personas/" +
        this.persona.id +
        "/permissions/" +
        persona_permission_id +
        "/remove",
      "delete",
      {},
      getOptions
    );
    personaPermissionDelete.subscribe((response) => {
      this.permissions.splice(index, 1);
      if (response.error != 418) {
      }
      (error) => {
        //@todo: handle error
      };
    });
  }

  public openAddPersonaPermissionModal() {
    this.modalOptions.data = {
      team_id: this.team_id,
      persona: this.persona,
    };

    this.modalOptions.class =
      "modal-dialog modal-dialog-centered modal-lg adjust";

    this.addPersonaPermissionModal = this.modalService.show(
      AddPersonaPermissionModalComponent,
      this.modalOptions
    );
    this.addPersonaPermissionModal.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.permissions.push(changedData.permission);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }
}
