import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TitleCasePipe } from "@angular/common";

import { Subject } from "rxjs";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-user-to-group",
  templateUrl: "./user-to-group.component.html",
  styleUrls: ["./user-to-group.component.scss"],
  providers: [TitleCasePipe]
})
export class UserToGroupComponent implements OnInit {
  //persistent
  public labels:any;
  private token: string;
  public existingUsers: any;
  private legacyMeta: boolean = false;

  //incoming
  public teamID: number;
  public users: any;
  public group: any;
  public groups: any;
  public action: string;
  public targetUser: any;

  //feedback response
  public formLoading: boolean = true;
  public title = "Add User To Group";
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Add a User to this Group:",
  };
  public formState: string = "active";
  public continueType: string;

  //form vars
  public scheduleForm: UntypedFormGroup;
  public selectUsers: any[];
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Add",
    reset: "Add Another User",
    retry: "Retry",
  };
  public preSelected: {
    group_user: number;
  } = {
    group_user: null,
  };

  //data back to the parent
  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public userToGroupFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private TitleCase: TitleCasePipe
  ) {}

  ngOnInit() {
    ;
    ;
    ;

    this.continueType = "reset";
    this.retrieveLabels();

    this.btnLabel.reset = `Add Another ${this.TitleCase.transform(this.labels.user.singular)}`;

    this.retrieveToken();
    this.handleDynamicCopy();
    this.buildUpPreselected();
    this.retrieveMembers();
  }
  
  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private buildUpPreselected() {
    if (this.targetUser === null) return false;

    this.preSelected.group_user =
      this.targetUser.id === undefined ? null : this.targetUser.id;
  }

  private retrieveMembers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveMembers = this._xrPlatformRestService.retrieveEntityCollection(
      "group",
      "members",
      this.group.id,
      options,
      true
    );

    retrieveMembers.subscribe(
      (response) => {
        this.existingUsers = response.members;
        this.processUsers();
      },
      (error) => {
        ;
      }
    );
  }

  private processUsers() {
    let usersSelect = [];
    ;

    this.users.forEach((user) => {
      let check = false;
      this.existingUsers.forEach((existing) => {
        if (existing.other_id === user.id) {
          check = true;
        }
      });

      if (check) return false;

      let thisUserSelect = {
        value: user.id,
        label: user.first_name + " " + user.last_name,
      };

      usersSelect.push(thisUserSelect);
    });

    this.selectUsers = usersSelect;
    this.makeFormValidatingGroup();
  }

  private makeFormValidatingGroup() {
    this.scheduleForm = new UntypedFormGroup({
      group_user: new UntypedFormControl(
        this.preSelected.group_user,
        Validators.required
      ),
    });

    this.formLoading = false;
  }

  get group_user() {
    return this.scheduleForm.get("group_user");
  }

  private handleDynamicCopy() {
    this.title="Add "+this.labels.user.singular+" To "+this.labels.userGroup.singular;
    this.msgs.actionMsg="Add a "+this.labels.user.singular +" to this "+this.labels.userGroup.singular+":";
    switch (this.action) {
      case "remove":
        this.title = `Remove ${this.TitleCase.transform(this.labels.user.singular)} from ${this.TitleCase.transform(this.labels.userGroup.singular)}`;
        this.msgs.actionMsg = `Do you wish to remove this ${this.labels.user.singular} from this ${this.labels.userGroup.singular}`;
        this.btnLabel.main = "Remove";
        this.continueType = "none";
        break;
    }
  }

  public userToGroup() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = `<span class='loading-msg'>Adding ${this.labels.user.singular}</span>`;
    this.formState = "processing";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    let incomingValues = this.scheduleForm.value;

    let getMember = this.getUserData(this.users, incomingValues.group_user);

    //fallback for deleted users
    if (!getMember.length && this.action === "remove") {
      getMember = [
        {
          username: this.targetUser.name,
          id: this.targetUser.other_id,
        },
      ];
    }

    let thisMember = {
      members: [
        {
          name: getMember[0].username,
          reference_id: getMember[0].id,
          type: "user",
        },
      ],
    };

    let options = {
      body: JSON.stringify(thisMember),
      childEntity: {
        parentEntityID: this.group.id,
        entityType: "members",
      },
    };

    let manageGroup = this._xrPlatformRestService.manageEntity(
      "group",
      this.teamID,
      "add",
      options,
      headers
    );

    ;

    switch (this.action) {
      case "remove":
        this.msgs.statusMsg = `<span class='loading-msg'>Removing ${this.labels.user.singular}</span>`;

        manageGroup = this._xrPlatformRestService.manageEntity(
          "group",
          this.teamID,
          "remove",
          options,
          headers
        );

        break;
    }

    manageGroup.subscribe(
      (response) => {
        this.msgs.statusMsg =
          `<span class='loading-msg'>Updating ${this.TitleCase.transform(this.labels.event.plural)}</span>`;

        this.retrieveScheduledEvents(
          this.group.id,
          getMember[0].id,
          incomingValues
        );
      },
      (err) => {
        this.msgs.statusMsg = "";
        let errorMsg = err.statusText;
        this.msgs.errorMsg = errorMsg;
      }
    );
  }

  private retrieveScheduledEvents(group_id, user_id, incomingValues) {
    let body = {
      deleted: false,
      invitees: [
        {
          invitee_type: "group",
          invitee_id: group_id,
        },
      ],
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveScheduledEvents = this._xrPlatformRestService.scheduledExperiences(
      "filtered",
      JSON.stringify(body),
      options
    );

    retrieveScheduledEvents.subscribe(
      (response) => {
        if (!response.scheduled_events.length) {
          this.outboundActions(incomingValues);
        } else {
          let updatedInvitees = [];

          ;
          response.scheduled_events.forEach((event) => {
            if (this.action === "remove") {
              if (event.invitees.length) {
                event.invitees.forEach((invitee) => {
                  if (
                    invitee.invitee_id === user_id &&
                    invitee.invitee_type === "user"
                  ) {
                    let thisInvitee = invitee;
                    let hit = false;

                    if (
                      invitee.meta !== undefined &&
                      invitee.meta !== null &&
                      invitee.meta.groups !== undefined &&
                      invitee.meta.groups.length
                    ) {
                      invitee.meta.groups.forEach((group, index) => {
                        console.log(
                          "group,group_id, group_id",
                          group.group_id,
                          group_id
                        );
                        if (group.group_id === group_id) {
                          thisInvitee.meta.groups.splice(index, 1);
                          hit = true;
                        }
                      });
                    }

                    if (
                      !invitee.meta.groups.length &&
                      (invitee.meta.individual === undefined ||
                        !invitee.meta.individual)
                    ) {
                      let thisUpdatedInvitee = {
                        schedule_id: event.id,
                        invitee: thisInvitee,
                        inviteeAction: "remove",
                      };

                      if (hit) updatedInvitees.push(thisUpdatedInvitee);
                    } else {
                      let thisUpdatedInvitee = {
                        schedule_id: event.id,
                        invitee: thisInvitee,
                        inviteeAction: "update",
                      };

                      if (hit) updatedInvitees.push(thisUpdatedInvitee);
                    }
                  }
                });
              }
            } else {
              let thisInvitee = {
                invitee_type: "user",
                invitee_id: user_id,
                scheduled_event_id: event.id,
                meta: {
                  groups: [{ group_id: group_id }],
                },
              };
              let inviteeAction = "add";

              if (event.invitees.length) {
                event.invitees.forEach((invitee) => {
                  if (
                    invitee.invitee_id === user_id &&
                    invitee.invitee_type === "user"
                  ) {
                    inviteeAction = "update";
                    if (invitee.meta === undefined) invitee.meta = null;

                    thisInvitee.meta = this.processInviteeMeta(
                      thisInvitee.meta,
                      invitee.meta
                    );
                  }
                });
              }

              let thisUpdatedInvitee = {
                schedule_id: event.id,
                invitee: thisInvitee,
                inviteeAction: inviteeAction,
              };

              updatedInvitees.push(thisUpdatedInvitee);
            }
          });

          console.log(
            "updatedInvitees in retrieveScheduledEvents",
            updatedInvitees
          );

          this.processUpdatedInvitees(updatedInvitees, incomingValues);
        }
      },
      (err) => {
        this.msgs.statusMsg = "";
        this.msgs.errorMsg = `There was an issue updating ${this.TitleCase.transform(this.labels.event.plural)} for user_id: ${user_id} and group_id: ${group_id}. Please report this to support along with the user_id and group_id.`;
      }
    );
  }

  private async processUpdatedInvitees(invitees, incomingValues) {
    await Promise.all(
      invitees.map(async (invitee) => {
        const response = await this.manageInvitees(invitee);
        ;
      })
    );

    ;
    this.outboundActions(incomingValues);
  }

  private manageInvitees(invitee) {
    ;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
      schedule_id: invitee.schedule_id,
    };

    if (invitee.inviteeAction === "add") {
      let usersBody = JSON.stringify([invitee.invitee]);

      let scheduleUsersToClassGroup = this._xrPlatformRestService.scheduledExperiences(
        "schedule-invitee",
        usersBody,
        options
      );

      return scheduleUsersToClassGroup.toPromise();
    } else if (invitee.inviteeAction === "remove") {
      let removeList = [
        {
          scheduled_event_id: invitee.schedule_id,
          invitee_type: "user",
          invitee_id: invitee.invitee.invitee_id,
        },
      ];

      ;

      let body = {
        entity: "schedule",
        entity_id: invitee.schedule_id,
        toRemove: removeList,
      };

      let removeInvitees = this._xrPlatformRestService.scheduledExperiences(
        "remove",
        body,
        options
      );

      return removeInvitees.toPromise();
    } else {
      ;
      return this.updateMeta(invitee, options);
    }
  }

  private updateMeta(invitee, options) {
    //@todo: saving this for when update meta works
    let body = {
      entity: "schedule",
      entity_id: invitee.schedule_id,
      toUpdate: [invitee.invitee],
    };
    let updateUserinGroup = this._xrPlatformRestService.scheduledExperiences(
      "invitee-meta-update",
      body,
      options
    );
    return updateUserinGroup.toPromise();
  }

  private processInviteeMeta(existing, incoming) {
    if (incoming === null) return existing;

    if (incoming.groups !== undefined && incoming.groups.length) {
      let updatedGroups = incoming.groups.concat(existing.groups);
      existing.groups = updatedGroups;
    }

    return existing;
  }

  private outboundActions(incomingValues) {
    this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.userGroupMember.singular)} succuessfully added.`;

    switch (this.action) {
      case "remove":
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.userGroupMember.singular)} successfully removed.`;
        break;
      case "add":
        let addedUser = {
          other_id: incomingValues.group_user,
        };

        this.existingUsers.push(addedUser);
        this.processUsers();

        break;
    }

    let outbound = {
      action: this.action,
      group_id: this.group.id,
      group_user: incomingValues.group_user,
    };

    ;

    this.outgoing.next(outbound);
  }

  public getUserData(users, user_id) {
    return users.filter((user) => {
      return user.id === user_id;
    });
  }

  public resetForm() {
    this.scheduleForm.reset();
    this.formState = "active";
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.formState = "active";
  }

  public handleIncomingAction(action) {
    ;
    switch (action) {
      case "close-modal":
        this.userToGroupFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
