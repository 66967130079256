import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, Input } from "@angular/core";

import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { UserToGroupComponent } from "../user-to-group/user-to-group.component";

@Component({
  selector: "app-users-by-group",
  templateUrl: "./users-by-group.component.html",
  styleUrls: ["./users-by-group.component.scss"],
})
export class UsersByGroupComponent implements OnInit {
  //inbound
  public group: any;
  public teamID: number;
  public users: any;
  public groups: any;

  //outgoing
  private outgoing: Subject<any> = new Subject();
  private outgoingData: any;

  //persistent
  public labels:any;
  private token: string;

  public groupedUsersCount: number = 0;

  public groupedUsersTableHeaders: string[] = [
    "First",
    "Last",
    "Email",
    "Username",
    "",
  ];

  public userFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private modalService: MDBModalService,
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    public manageGroupMembers: MDBModalRef
  ) {}

  ngOnInit() {
    ;
    this.retrieveLabels();
    this.retrieveToken();
    this.retrieveMembers();
  }
  
  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }
  
  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveMembers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveMembers = this._xrPlatformRestService.retrieveEntityCollection(
      "group",
      "members",
      this.group.id,
      options,
      true
    );

    retrieveMembers.subscribe(
      (response) => {
        this.group.members = response.members;
        console.log(
          "this.groups.members in retrieveMembers",
          this.group.members
        );
        this.processMembers();
      },
      (error) => {
        ;
      }
    );
  }

  public openUsersModal(group, targetUser?, action?) {
    this.modalOptions.data = {
      teamID: this.teamID,
      users: this.users,
      groups: this.groups,
      group: group,
      targetUser: targetUser === undefined ? null : targetUser,
      action: action === undefined ? "add" : action,
    };

    this.userFrame = this.modalService.show(
      UserToGroupComponent,
      this.modalOptions
    );

    this.userFrame.content.outgoing.subscribe((result: any) => {
      ;
      if (result.action === "remove") {
        this.groups.forEach((groupItem, groupDex) => {
          if (groupItem.id === result.group_id) {
            this.groups[groupDex].members.forEach((member, memberdex) => {
              if (member.id === result.group_user) {
                this.groups[groupDex].members.splice(memberdex, 1);
                this.groups[groupDex].number_of_members--;

                this.outgoingData = {
                  updatedGroups: this.groups,
                };

                this.outgoing.next(this.outgoingData);
              }
            });
          }
        });
      }
    });
  }

  private processMembers() {
    ;

    this.group.members.forEach((member, index) => {
      let thisUser = this.getUserData(this.users, member.other_id);

      if (!thisUser.length) {
        thisUser = [
          {
            first_name: "User",
            last_name: "Deleted",
            email: "Please Remove",
            username: member.name,
            id: member.other_id,
          },
        ];
      }

      this.group.members[index].first_name = thisUser[0].first_name;
      this.group.members[index].last_name = thisUser[0].last_name;
      this.group.members[index].email = thisUser[0].email;
      this.group.members[index].username = thisUser[0].username;
      this.group.members[index].id = thisUser[0].id;
    });

    this.groupedUsersCount = this.group.members.length;
  }

  public getUserData(users, user_id) {
    return users.filter((user) => {
      return user.id === user_id;
    });
  }
}
