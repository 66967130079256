<!-- <div class="background-replacement" (click)="turnConfirmOn(confirmModal)"></div> -->
<app-close-confirmation
  #closeConfirmation
  [action]="action"
  [mainModal]="mainModal"
  [parentFrame]="scheduleFrame"
  (confirmOn)="confirmOnEvent()"
></app-close-confirmation>
<div class="class-wrapper" [ngClass]="{ 'confirm-on': confirmOn }">
  <!-- status message -->
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <!-- end status message -->
  <!-- Modal Content -->
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <!--<mdb-icon far icon="calendar-alt"></mdb-icon>-->
      <span> {{ title | titlecase }}</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="turnConfirmOn()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- content -->
  <div class="modal-body mt-3 p-0">
    <div class="row">
      <div class="col-12">
        <p class="text-center">
          {{ msgs.actionMsg | capitalizeFirst:[] }}
          <strong *ngIf="!multiExperiences">{{
            experience.name | titlecase
          }}</strong>
        </p>
      </div>
    </div>
    <form
      *ngIf="!formLoading && action !== 'delete'"
      [formGroup]="scheduleForm"
    >
      <div *ngIf="!viewOnly.date">
        <div class="row">
          <div class="col-12">
            <p class="text-left color-theme-base-medium-dark bold">
              Enter {{ labels.event.singular | titlecase }} Details:
            </p>
          </div>
        </div>

        <div class="row">
          <!--class name-->
          <div
            class="col-12"
          >
            <div class="md-form mb-1">
              <input
                type="text"
                id="eventName"
                class="form-control"
                formControlName="class_name"
                mdbInput
                mdbValidate
                (focus)="labelClass.class_name = 'active-label active'"
                (blur)="maybeAdjustLabel($event)"
              />
              <label for="eventName" [ngClass]="labelClass.class_name"
                >{{ labels.event.singular | titlecase }} Name</label
              >
              <mdb-error
                *ngIf="
                  class_name.invalid && (class_name.dirty || class_name.touched)
                "
              >
                Please enter an {{ labels.event.singular }} name
              </mdb-error>
            </div>
          </div>
          <!-- end class name -->

          <!-- event public status -->
          <div
            class="col-12 mb-4 mt-4"
            *ngIf="multiExperiences"
            [ngClass]="{
              'on-private': showPrivateTypes,
              'on-public': !showPrivateTypes
            }"
          >
            <div
              class="grid"
              [ngClass]="{ disabled: targetClass !== undefined }"
            >
              <p class="mb-0 mr-2 grid-item">Event Access:</p>
              <div class="form-check form-check-inline grid-item">
                <input
                  type="radio"
                  class="form-check-input"
                  id="publicStatusTrue"
                  [value]="true"
                  formControlName="is_public"
                  name="is_public"
                />
                <label class="form-check-label" for="publicStatusTrue"
                  >Public</label
                >
              </div>
              <div class="form-check form-check-inline grid-item">
                <input
                  type="radio"
                  class="form-check-input"
                  id="publicStatusFalse"
                  [value]="false"
                  formControlName="is_public"
                  name="is_public"
                />
                <label class="form-check-label" for="publicStatusFalse"
                  >Private</label
                >
              </div>
              <p *ngIf="showPrivateTypes === true" class="mb-0 mr-2 grid-item">
                Privacy Type:
              </p>
              <div
                *ngIf="showPrivateTypes === true"
                class="form-check form-check-inline grid-item"
              >
                <input
                  type="radio"
                  class="form-check-input"
                  id="privateTypeHasInvitees"
                  value="has_invitees"
                  formControlName="private_type"
                  name="private_type"
                />
                <label class="form-check-label" for="privateTypeHasInvitees"
                  >By Invite</label
                >
              </div>
              <div
                *ngIf="showPrivateTypes === true"
                class="form-check form-check-inline grid-item"
              >
                <input
                  type="radio"
                  class="form-check-input"
                  id="privateTypeIsAccessCodeRequired"
                  value="is_access_code_required"
                  formControlName="private_type"
                  name="private_type"
                />
                <label
                  class="form-check-label"
                  for="privateTypeIsAccessCodeRequired"
                  >By Access Code</label
                >
              </div>
            </div>
          </div>
        </div>
        <!-- end event public status -->
        <div class="row">
          <!-- start date -->
          <div class="col-6">
            <p class="text-left color-theme-base-medium-dark">
              {{ labels.startDate.singular | titlecase }}:
            </p>
            <div class="md-form">
              <mdb-date-picker
                #datePickerStart
                class="start-picker"
                name="startDate"
                [options]="myDatePickerOptions"
                placeholder="Select {{ labels.startDate.singular | titlecase }}"
                label="Select {{ labels.startDate.singular | titlecase }}"
                [inline]="true"
                formControlName="startDate"
                required
                (inputFieldChanged)="onInputFieldChange($event, 'start')"
              ></mdb-date-picker>
              <mdb-error
                *ngIf="
                  startDate.invalid && (startDate.dirty || startDate.touched)
                "
              >
                Please enter a date
              </mdb-error>
            </div>
            <div class="md-form">
              <mdb-time-picker
                class="start-time-picker"
                [buttonLabel]="'Done'"
                [twelvehour]="true"
                [darktheme]="false"
                placeholder="Select {{ labels.startTime.singular }}"
                label="Select {{ labels.startTime.singular | titlecase }}"
                formControlName="startTime"
                (timeChanged)="onTimeChange($event, 'startTime')"
              ></mdb-time-picker>
              <mdb-error
                *ngIf="
                  startTime.invalid &&
                  !startTime.errors.timeMustBeValid &&
                  (startTime.dirty || startTime.touched)
                "
              >
                Please enter an end time
              </mdb-error>
              <mdb-error
                *ngIf="startTime.invalid && startTime.errors.timeMustBeValid"
                >Please enter a valid
                {{ labels.startTime.singular | titlecase }}
              </mdb-error>
            </div>
          </div>
          <!-- end start date -->
          <!-- end date -->
          <div class="col-6">
            <p class="text-left color-theme-base-medium-dark">
              {{ labels.endDate.singular | titlecase }}:
            </p>
            <div
              class="md-form"
              [ngClass]="{ 'date-disabled': timeTracker.start === 0 }"
            >
              <mdb-date-picker
                #datePickerEnd
                class="end-picker"
                name="endDate"
                [options]="myEndDatePickerOptions"
                placeholder="Select {{ labels.endDate.singular | titlecase }}"
                label="Select {{ labels.endDate.singular | titlecase }}"
                [inline]="true"
                formControlName="endDate"
                [disabled]="timeTracker.start === 0"
                (inputFieldChanged)="onInputFieldChange($event, 'end')"
                required
              ></mdb-date-picker>
              <mdb-error
                *ngIf="
                  endDate.invalid &&
                  !endDate.errors.dateMustBeLater &&
                  (endDate.dirty || endDate.touched)
                "
              >
                Please enter a date
              </mdb-error>
              <mdb-error
                *ngIf="endDate.invalid && endDate.errors.dateMustBeLater"
              >
                {{ labels.endDate.singular | titlecase }} must be on the same
                day or later than the
                {{ labels.startDate.singular | titlecase }}.
              </mdb-error>
            </div>
            <div class="md-form">
              <mdb-time-picker
                class="end-time-picker"
                [buttonLabel]="'Done'"
                [twelvehour]="true"
                [darktheme]="false"
                placeholder="Select {{ labels.endTime.singular | titlecase }}"
                label="Select {{ labels.endTime.singular | titlecase }}"
                formControlName="endTime"
                (timeChanged)="onTimeChange($event, 'endTime')"
              ></mdb-time-picker>
              <mdb-error
                *ngIf="
                  endTime.invalid &&
                  !endTime.errors.timeMustBeValid &&
                  !endTime.errors.timeMustBeLater &&
                  (endTime.dirty || endTime.touched)
                "
              >
                Please enter an end time
              </mdb-error>
              <mdb-error
                *ngIf="endTime.invalid && endTime.errors.timeMustBeValid"
                >Please enter a valid
                {{ labels.endTime.singular | titlecase }}
              </mdb-error>
              <mdb-error
                *ngIf="endTime.invalid && endTime.errors.timeMustBeLater"
              >
                {{ labels.endTime.singular | titlecase }} must be later than the
                {{ labels.startTime.singular | titlecase }}
              </mdb-error>
            </div>
          </div>
          <!-- end of end date -->
        </div>
        <div
          class="row"
          *ngIf="this.multiExperiences && this.hubExperiencesSelect.length"
        >
          <div class="col-12">
            <div
              class="grid mb-3"
              [ngClass]="{ disabled: targetClass !== undefined }"
            >
              <p class="mb-0 mr-2 grid-item">Hub Event:</p>
              <div class="form-check form-check-inline grid-item">
                <input
                  type="radio"
                  class="form-check-input"
                  id="hubStatusTrue"
                  [value]="true"
                  formControlName="is_hub"
                  name="is_hub"
                />
                <label class="form-check-label" for="hubStatusTrue">Yes</label>
              </div>
              <div class="form-check form-check-inline grid-item">
                <input
                  type="radio"
                  class="form-check-input"
                  id="hubStatusFalse"
                  [value]="false"
                  formControlName="is_hub"
                  name="is_hub"
                />
                <label class="form-check-label" for="hubStatusFalse">No</label>
              </div>
            </div>
          </div>
        </div>
        <div class="hubs" *ngIf="this.multiExperiences && is_hub.value">
          <div class="w-100">
            <p class="bold subhead">Hub</p>
            <div class="zones-wrapper mt-2">
              <div class="md-form experience">
                <!--experience-->
                <mdb-select-2
                  placeholder="Select Hub {{
                    labels.experience.singular | titlecase
                  }}"
                  label="{{ labels.experience.singular | titlecase }} Name"
                  formControlName="hubs_experience"
                >
                  <mdb-select-option
                    *ngFor="let option of hubExperiencesSelect"
                    [value]="option.value"
                    >{{ option.label }}</mdb-select-option
                  >
                </mdb-select-2>
              </div>
            </div>
            <div>
              <p class="bold subhead">Zones</p>
            </div>
            <div
              class="zones-fields-wrapper"
              *ngFor="let zoneFields of zonesFieldsGroups; let i = index"
              [ngClass]="{ striped: i % 2 === 0 }"
            >
              <p>Zone {{ i + 1 }}</p>
              <div [formGroup]="zoneFields">
                <div class="d-flex">
                  <div class="md-form mb-1 flex-grow-1 px-2">
                    <input
                      type="text"
                      [id]="'label' + i"
                      class="form-control"
                      formControlName="zone_label"
                      mdbInput
                      placeholder="Enter Zone Name"
                    />
                  </div>
                  <div class="md-form experience flex-grow-1 px-2">
                    <!--experience-->
                    <mdb-select-2
                      placeholder="Choose an {{
                        labels.experience.singular | titlecase
                      }}"
                      label="{{ labels.experience.singular | titlecase }} Name"
                      formControlName="zone_experience_id"
                    >
                      <mdb-select-option
                        *ngFor="let option of zonesSelect[i]"
                        [value]="option.value"
                        >{{ option.label }}</mdb-select-option
                      >
                    </mdb-select-2>
                  </div>
                </div>
                <div
                  mdbCollapse
                  [isCollapsed]="!zoneFields.controls['has_extras'].value"
                >
                  <div class="d-flex extras-actions">
                    <div
                      class="extras-action hub"
                      *ngIf="zoneFields.controls['has_hub'].value"
                    >
                      <button
                        class="theme-modal-button btn"
                        type="button"
                        mdbBtnp
                        mdbWavesEffect
                        [disabled]="formLoading"
                        (click)="openZoneHubModal(i)"
                      >
                        Manage Zones for this Hub
                      </button>
                    </div>
                    <ng-template [ngIf]="getZoneExtrasFieldsGroups(i).length">
                      <ng-template
                        ngFor
                        let-extras_i="index"
                        let-zoneExtraFields
                        [ngForOf]="getZoneExtrasFieldsGroups(i)"
                      >
                        <div
                          class="extras-action extra"
                          [formGroup]="zoneExtraFields"
                        >
                          <ng-template
                            [ngIf]="
                              zoneExtraFields.controls.attribute_type.value ===
                              'asset_single'
                            "
                          >
                            <div
                              [ngStyle]="{
                                'background-image':
                                  'url(' +
                                  checkThumbnail(
                                    zoneExtraFields.controls.attribute_full_data
                                      .value
                                  ) +
                                  ')'
                              }"
                              class="thumbnail-container"
                            ></div>
                            <button
                              class="theme-modal-button btn"
                              type="button"
                              mdbBtnp
                              mdbWavesEffect
                              (click)="
                                openSelectMediaModal(
                                  extras_i,
                                  i,
                                  zoneExtraFields.controls.attribute_subtype
                                    .value,
                                  zoneExtraFields.controls.attribute_full_data
                                    .value
                                )
                              "
                            >
                              <mdb-icon
                                far
                                icon="plus-square"
                                class="pr-1"
                              ></mdb-icon>
                              Manage
                              {{
                                zoneExtraFields.controls.attribute_label.value
                              }}
                            </button>
                          </ng-template>
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row experiences"
          *ngIf="this.multiExperiences && !is_hub.value"
        >
          <div class="col-6">
            <p>Multiple Experiences</p>
            <div
              class="experiences-wrapper mt-2"
              *ngFor="
                let experienceUserFields of experienceFieldsGroups;
                let i = index
              "
            >
              <div class="d-flex" [formGroup]="experienceUserFields">
                <div class="btn-group list-btns-sm btn-icons">
                  <button
                    *ngIf="experiencesSelectCurrent.length > 0"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-flat btn-icon"
                    [disabled]="
                      (experienceUserFields.controls.experience_id.invalid &&
                        experienceUserFields.controls.experience_id !== null &&
                        experienceUserFields.controls.experience_id.errors !==
                          null &&
                        !experienceUserFields.controls.experience_id.errors
                          .eventCapacity) ||
                      (experienceUserFields.controls.experience_id.valid &&
                        i < experienceFieldsGroups.length - 1) ||
                      (experienceUserFields.controls.experience_id.errors !==
                        null &&
                        experienceUserFields.controls.experience_id.errors
                          .eventCapacity &&
                        i < experienceFieldsGroups.length - 1)
                    "
                    (click)="addExperienceFields(i + 1)"
                  >
                    <mdb-icon
                      class="color-theme-base-medium"
                      fas
                      icon="plus-square"
                      size="2x"
                    ></mdb-icon>
                  </button>
                  <button
                    *ngIf="i > 0"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-flat btn-icon"
                    (click)="removeExperienceField(i)"
                  >
                    <mdb-icon
                      class="color-theme-base-medium-dark"
                      fas
                      icon="minus-square"
                      size="2x"
                    ></mdb-icon>
                  </button>
                </div>
                <div class="md-form experience">
                  <!--experience-->
                  <mdb-select-2
                    placeholder="Choose an {{
                      labels.experience.singular | titlecase
                    }}"
                    label="{{ labels.experience.singular | titlecase }} Name"
                    formControlName="experience_id"
                  >
                    <mdb-select-option
                      *ngFor="let option of experiencesSelect[i]"
                      [value]="option.value"
                      >{{ option.label }}</mdb-select-option
                    >
                  </mdb-select-2>
                  <mdb-error
                    *ngIf="
                      experienceUserFields.controls.experience_id.invalid &&
                      experienceUserFields.controls.experience_id !== null &&
                      experienceUserFields.controls.experience_id.errors ===
                        null &&
                      (experienceUserFields.controls.experience_id.dirty ||
                        experienceUserFields.controls.experience_id.touched)
                    "
                  >
                    Please select an {{ labels.experience.singular }}
                  </mdb-error>
                  <mdb-error
                    *ngIf="
                      experienceUserFields.controls.experience_id.errors !==
                        null &&
                      experienceUserFields.controls.experience_id.errors
                        .eventCapacity
                    "
                  >
                    This experience capacity is under the starting experience
                    capacity, click the info icon for additional details.
                    <ng-template #capacityWarning>
                      <p>
                        The number of attendees for your starting experience
                        currently exceeds the capacity of the above experience.
                        If the number of actual attendees in the VR experience
                        exceeds this capacity, you will not be able to enter the
                        above experience. Please contact support with any
                        additional questions.
                      </p>
                    </ng-template>
                    <button
                      mdbBtn
                      class="info-button color-theme-base-medium"
                      [mdbPopover]="capacityWarning"
                      placement="top"
                      mdbPopoverHeader="Starting Experience Capacity"
                      mdbWavesEffect
                      triggers="focus"
                    >
                      <mdb-icon
                        fas
                        icon="info-circle"
                        class="text-danger"
                      ></mdb-icon>
                    </button>
                  </mdb-error>
                  <!--end persona-->
                </div>
                <div class="md-form starting-experience">
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      [id]="'startingExperience' + i"
                      [value]="i"
                      formControlName="starting_experience"
                      name="starting_experience"
                      (click)="selectStartingExperience(i)"
                    />
                    <label
                      class="form-check-label"
                      [for]="'startingExperience' + i"
                    ></label>
                    <label class="active">Starting Experience</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row" *ngIf="attendeePropID > 0">
              <div class="col-6">
                <p class="text-left color-theme-base-medium-dark">
                  Starting Experience: Number of Attendees
                </p>
                <!-- start number of attendees -->
                <div class="md-form">
                  <mdb-select
                    class="number-of-attendees-prop"
                    [options]="attendeeSelect"
                    placeholder="Select Number of Attendees"
                    formControlName="number_of_attendees_prop"
                    label="Select Number of Attendees"
                  ></mdb-select>
                </div>
                <!-- end number of attendees -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <input
        *ngIf="!multiExperiences"
        type="hidden"
        formControlName="scheduleID"
        mdbInput
      />
    </form>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="!formLoading"
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      data-dismiss="modal"
      [disabled]="updateButtonStatus(scheduleForm)"
      (click)="scheduleClass()"
    >
      {{ btnLabel.main }}
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="turnConfirmOn()"
    >
      {{ btnLabel.close }}
    </button>
  </div>
  <!-- /content -->
</div>
<!-- confirm modal -->
<div
  mdbModal
  #confirmModal="mdbModal"
  class="modal confirm-modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModal"
  aria-hidden="true"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content"></div>
  </div>
</div>
