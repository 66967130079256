import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Track } from "src/app/modules/audio-player/model/track.model";

import {
  faFileAudio
} from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: "app-audio-media-player",
  templateUrl: "./audio-media-player.component.html",
  styleUrls: ["./audio-media-player.component.scss"],
})
export class AudioMediaPlayerComponent implements OnInit {
  @Input() tracks: Track[] = [];
  @Output("audioLoaded") audioLoaded = new EventEmitter();

  faFileAudio = faFileAudio;

  msaapDisplayTitle = false;
  msaapDisplayPlayList = false;
  msaapPageSizeOptions = [10];
  msaapDisplayVolumeControls = true;
  msaapDisplayRepeatControls = false;
  msaapDisplayArtist = false;
  msaapDisplayDuration = false;
  msaapDisablePositionSlider = false;
  msaapPlaylist: Track[] = []

  constructor() { }

  ngOnInit(): void {
    this.msaapPlaylist = this.tracks;
    this.audioLoaded.emit(this.msaapPlaylist);
  }

  onEnded() { }

}
