import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
} from "@angular/forms";
import { CoolLocalStorage } from '@angular-cool/storage';
import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-persona-permission-modal',
  templateUrl: './add-persona-permission-modal.component.html',
  styleUrls: ['./add-persona-permission-modal.component.scss']
})
export class AddPersonaPermissionModalComponent implements OnInit {

  constructor(
    public addPersonaPermissionModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage, 
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder) { }

  private team_id: number;
  public token: string;
  private persona: any;
  public permission_names = [];
  private permissions: any;

  public permissionForm = this.formBuilder.group({
    permission_name: ''
  });
  public permissionFormGroup: UntypedFormGroup;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Permission",
    retry: "Retry",
  };

  searchText = new Subject();
  results: Observable<string[]>;

  private outgoing: Subject<any> = new Subject();
  private outgoingData: any;

  ngOnInit(): void {
    this.retrieveToken();
    this.getPermissions();
    this.results = this.searchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value))
    );

    
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private getPermissions()
  {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    
    let permission_request = this._xrPlatformRestService.restfulAPIQuery(
      '/personas/getpermissions',
      'get',
      {},
      getOptions
    );
    permission_request.subscribe((params) => {
      
      this.permissions = params.permissions;
      this.permissions.forEach(element => {
        this.permission_names.push(element.permission_name)
      });
      this.results = this.searchText.pipe(
        startWith(''),
        map((value: string) => this.filter(value))
      );
    });
  }
  
  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.permission_names.filter((item: string) => item.toLowerCase().includes(filterValue));
    return filtered;
  }

  public clickAddToPersona()
  {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    
    const data = {
      persona_id: this.persona.id,
      permission_id: this.getSpaceIdFromName(this.permissionForm.value.permission_name)
    };
    let addedPersona = this._xrPlatformRestService.restfulAPIQuery(
      '/personas/addpersonapermission',
      'post',
      data,
      getOptions
    );
    addedPersona.subscribe((response) =>{
      
      this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Permission successfully added";
      if(response.error != 418)
      {
        
        let outgoingData = {
          action: 'add',
          permission: response.persona_permission
        }
        this.outgoing.next(outgoingData);
      }
    },
    (error) => {
      ;
      this.msgs.processingMsg = "";
      let errorMsg = JSON.parse(error._body);
      this.btnLabel.retry = "Retry";
      this.msgs.errorMsg = errorMsg.error;
    });

  }

  private getSpaceIdFromName(permission_name: string)
  {
    let index = this.permissions.findIndex(x => x.permission_name === permission_name);
    return this.permissions[index].id;
  }

  public resetForm() {
    this.permissionForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.addPersonaPermissionModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
