<div class="archive-wrapper">
  <app-status-messages [msgs]="msgs" [btnLabel]="btnLabel" [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">Archive {{ type }}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="archiveEntityModal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3 p-0">
    <div class="d-flex flex-row">
      <ul class="list-unstyled">
        <li>
          Label <b>{{ entity.label }}</b>
        </li>
        <li>
          Name: <b>{{ entity.name }}</b>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">

    <button mdbBtn class="waves-light theme-action-button" mdbWavesEffect (click)="archiveEntityModal.hide()">
      Close
    </button>

    <button mdbBtn class="waves-light theme-action-button" mdbWavesEffect (click)="clickArchiveEntity()">
      Archive {{ type }}
    </button>
  </div>
</div>