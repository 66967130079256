import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-view-group-modal",
  templateUrl: "./view-group-modal.component.html",
  styleUrls: ["./view-group-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ViewGroupModalComponent implements OnInit {
  //incoming
  public targetEvent: any;
  public parent_group: any;
  public teamID: number;
  public clientSettings: any;

  //settings
  public showRoles = false;
  public showPersonas = false;

  public members: any[];

  public labels: any;
  public token: string;
  public groups: any;
  public num_of_members: number = 0;
  public invitees: any;

  public title = "Manage users";
  public errorMsg: string = "";
  public statusMsg: string = "";
  public actionMsg: string = "";
  public formState: string = "active";
  public formLoading: boolean = false;

  public scheduledTableHeaders: string[] = [
    "First",
    "Last",
    "Email",
    "Username",
  ];

  //btn labels
  public btnLabel: { close: string } = {
    close: "Close",
  };

  public viewGroupFrame: MDBModalRef;
  public userManageFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private modalService: MDBModalService,
    public manageGroupFrame: MDBModalRef,
    public manageUsersFrame: MDBModalRef,
    public editPersonaRoleModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.retrieveLabels();
    this.retrieveToken();

    this.members = [];
    
    this.showRoles = this.clientSettings.showRole.unconstrained_default;
    this.showPersonas = this.clientSettings.showPersona.unconstrained_default;

    ;
    this.configureHeaders();
    this.retrieveGroupMembers();
  }
  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private configureHeaders() {
    if (this.showRoles)
      this.scheduledTableHeaders.push(this.labels.eventRoles.singular);
    if (this.showPersonas)
      this.scheduledTableHeaders.push(this.labels.eventPersona.singular);
  }

  public retrieveGroupMembers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let retrieveGroup = this._xrPlatformRestService.restfulAPIQuery(
      "/group/" + this.parent_group.id + "/members",
      "get",
      {},
      getOptions
    );

    retrieveGroup.subscribe(
      (response) => {
        console.log(response);
        this.members = response.members;

        if (this.showPersonas) {
          this.retrievePersonas(getOptions);
        } else {
          this.processGroup([]);
        }
      },
      (error) => {}
    );
  }

  private retrievePersonas(options) {
    let retrievePersonas = this._xrPlatformRestService.restfulAPIQuery(
      "/personas/findpersonasbyclient/" + this.teamID,
      "get",
      {},
      options
    );

    retrievePersonas.subscribe(
      (response) => {
        console.log(response);
        this.processGroup(response.personas);
      },
      (error) => {}
    );
  }

  private processGroup(personas) {
    this.members.forEach((member) => {
      console.log("member in processGroup", member);

      member.first_name = member.user.first_name;
      member.last_name = member.user.last_name;
      member.email = member.user.email;
      member.username = member.user.username;
      member.meta = member.user.meta;

      let role = "Not Set";

      if (member.meta !== null && member.meta.role !== undefined) {
        switch (member.meta.role) {
          case 1:
            role = "Observer";
            break;
          case 2:
            role = "Leader";
            break;
          case 3:
            role = "Participant";
            break;
        }
      }

      member.role_name = role;

      if (this.showPersonas) {
        if (member.meta && member.meta.persona_id) {
          const personaDetails = personas.filter(
            (item) => item.id === member.meta.persona_id
          );
          if (personaDetails.length > 0) {
            member.persona_name = personaDetails[0].persona_name;
          } else {
            member.persona_name = "Not Set";
          }
        } else {
          member.persona_name = "Not Set";
        }
      }
    });
  }
}
