import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WelcomePageComponent } from "./welcome-page/welcome-page.component";

import { SharedModulesModule } from "./../shared-modules/shared-modules.module";
import { PersistentModule } from "./../persistent/persistent.module";
import { ClientBaseComponent } from "./client-base/client-base.component";
import { AuthenticationModule } from "./../authentication/authentication.module";

@NgModule({
  declarations: [WelcomePageComponent, ClientBaseComponent],
  imports: [
    CommonModule,
    SharedModulesModule,
    PersistentModule,
    AuthenticationModule,
  ],
  exports: [],
})
export class PagesModule {}
