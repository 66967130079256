import { TitleCasePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { MediaManagementServicesService } from "../../media-management/services/media-management-services.service";

@Injectable({
  providedIn: "root",
})
export class EventMediaVersion2Service {
  token: string;
  targetEvent: any;
  public errorObj: BehaviorSubject<any> = new BehaviorSubject(false);
  constructor(
    private _mediaManagementService: MediaManagementServicesService,
    private _xrPlatformRestService: XrPlatformRestService
  ) {}

  /**
   * Retrieves client media and media attached to an event of given schedule_id (i.e. the event ID)
   * @param teamID - ID of the current client
   * @param token - user token for making backend requests
   * @param schedule_id - i.e. event ID
   * @returns an object with the following properties
   * @prop status - currently just returns "success", but in the future we can update this to handle errors as well
   * @prop media - the client media
   * @prop attached - the media attached to this event
   */
  public async retrieveMedia(
    teamID: number,
    token: string,
    schedule_id: number,
    retrieveAllMedia?: boolean
  ): Promise<{ status: string; media: any; attached: any }> {
    //we need this part to make TypeScript happy

    if (retrieveAllMedia === undefined) retrieveAllMedia = true;

    let media = [];

    if (retrieveAllMedia) {
      /**
       * Calls async request @method MediaManagementService::mediaService(), and waits for response
       * Assigns response to local @var retrieveMedia
       */
      let retrieveMedia = await this._mediaManagementService
        .mediaService(teamID, token)
        .toPromise();

      /** After response from above, processes incoming media via @method processMedia() and returns to local @var media */
      media = this.processMedia(retrieveMedia);
    }

    /**
     * Calls async request @method retrieveAttachedMedia(), and waits for response
     * Assigns respone to local @var attached
     */
    let attached = await this.retrieveAttachedMedia(token, schedule_id);

    /**
     * After response from above, adds local @var media and @var attached to an outbound object @var outbound that also includes @prop status
     */
    let outbound = {
      status: "success",
      media: media,
      attached: attached,
    };

    /** resolves promise and returns @var outbound */
    return Promise.resolve(outbound);
  }

  private processMedia(incoming) {
    let media = [];

    media = incoming.assets.filter((asset) => {
      return !asset.is_preview_image;
    });

    media.forEach((thisMedia) => {
      thisMedia.loading = "loaded";
    });

    return media;
  }

  /**
   * Configures async call to @method XrPlatformRestService::retrieveEntityData()
   * This is now a promise so we can use it in an async/await in @method retrieveMedia()
   * @param token
   * @param schedule_id
   * @returns Promise
   */
  public retrieveAttachedMedia(token, schedule_id): Promise<any> {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let scheduled_event_id = schedule_id;
    let retrieveAttachedMedia = this._xrPlatformRestService.retrieveEntityData(
      "schedule",
      scheduled_event_id,
      options
    );

    return retrieveAttachedMedia.toPromise();
  }

  public async manageMedia(
    openExtraSpaceModal,
    token,
    targetEvent,
    scheduleForm,
    media,
    mediaLabel,
    attachedList
  ): Promise<any> {
    this.token = token;
    this.targetEvent = targetEvent;
    //handling user output
    //@todo: update to use app-status-message

    const errorMsgObj = {
      errorMsg: "",
      statusMsg: `<span class='loading-msg'>Updating ${mediaLabel}</span>`,
      formState: "processing",
    };

    this.errorObj.next(errorMsgObj);

    //constants for holding header and option data for outbound requests
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
      schedule_id: targetEvent.schedule_id,
    };

    //key local vars
    //Stores the incoming values from the media-to-event form (which actually includes all of the media items)
    ;
    let incomingValues = scheduleForm.value;
    //An array to store media that should be removed from this event
    let removals = [];
    //An array to store media that should be added to this event as invitees
    let invitees = [];
    /**
     * An array to track what media items need meta updates
     * Note: this is for updating the meta field on the *media* item, not on the invitee item (see below)
     * In V2 of media-to-events, we don't have a direct relationship between media and events, and therefore we don't have an easy way to track which events media is attached to.
     * So we currently use the media meta to track what events a media item is attached to, which is important for things like removing a media item, were we also need to know which events to remove it from.
     */
    let mediaToUpdate = [];

    console.log(
      "incomingValues.myOptionsArray in manageMedia",
      incomingValues.myOptionsArray
    );

    //here we loop through the incominValues, i.e. all of the media items on the media-to-event form
    incomingValues.myOptionsArray.forEach((thisMedia) => {
      //adds this new invitee object to the array of invitees to add, i.e. media to add to the is event
      if (thisMedia.selected) {
        //for a media item that will be added to this event, we need to setup the invitee object
        let thisInvitee = {
          scheduled_event_id: targetEvent.schedule_id, //event id
          invitee_type: "media", //indicates this is a media item
          invitee_id: thisMedia.id, //media item id
          meta: {
            //contains useful media item properties
            media: {
              created: thisMedia.created,
              description: thisMedia.description,
              file_ext: thisMedia.file_ext,
              id: thisMedia.id,
              modified: thisMedia.modified,
              name: thisMedia.name,
              type: thisMedia.type,
              uuid: thisMedia.uuid,
              meta: thisMedia.meta,
              thumbnail_asset_uuid: thisMedia.thumbnail_asset_uuid,
            },
          },
        };

        invitees.push(thisInvitee);
        mediaToUpdate.push({
          action: "add",
          item: thisMedia,
        });
      } else {
        //configures the removal object
        let thisRemove = {
          invitee_type: "media",
          invitee_id: thisMedia.id,
        };

        removals.push(thisRemove);
        mediaToUpdate.push({
          action: "remove",
          item: thisMedia,
        });
      }
    });

    //configuring the invitees into a body string
    let addBody = JSON.stringify(invitees);

    ;
    ;

    //configure the body object for the removal request
    if (removals.length) {
      let body = {
        entity: "schedule",
        entity_id: targetEvent.schedule_id,
        toRemove: removals,
      };

      ;

      //our first step is to handle the removal of media items from the event (if there any to remove)
      //@todo: rearchitect into an async/await structure and use a conditional to only run removal if the local @var removals is not empty
      let removeMedia = await this._xrPlatformRestService
        .scheduledExperiences("remove", body, options)
        .toPromise()
        .catch ((err) => {
          ;

          let errorMsg = err;

          if (err._body !== undefined) {
            errorMsg = JSON.parse(err._body);
          } else if (err.error !== undefined) {
            errorMsg = err.error;
          }

          ;

          if (
            errorMsg.error !== undefined &&
            errorMsg.error.indexOf("no invitee found") !== -1
          ) {
            let resolve = {
              error: null,
              scheduled_event: {},
              status: "OK",
            };

            Promise.resolve(resolve);
          }
        });

        ;
    }

    //after running the remove process, we then media items to this event (if there are any to add)
    //@todo: rearchitect into an async/await structure and use a conditional to run only if there are media items to add to this event
    let attachMedia = await this._xrPlatformRestService
      .scheduledExperiences("schedule-invitee", addBody, options)
      .toPromise();

    /** Local @var mediaToUpdate tells us we have media items that need their meta fields updated (see above) */
    if (mediaToUpdate.length) {
      /**If local @var mediaToUpdate is not empty, we kick start the process to update media meta via @method getMediaMeta() */
      let mediaData = await this.getMediaMeta(
        mediaToUpdate,
        openExtraSpaceModal,
        attachMedia,
        mediaLabel
      );
      return Promise.resolve(mediaData);
    } else {
      /**If local @var mediaToUpdate is empty, we show a success message and move on to the @method finalActions() */
      const errorMsgObj = {
        errorMsg: "",
        statusMsg: `${mediaLabel} successfully added.`,
        formState: "processing",
      };

      this.errorObj.next(errorMsgObj);
    }
  }

  private determineCurrentMedia(media, media_id) {
    return media.filter((thisMedia, index) => {
      thisMedia.current_index = index;
      return thisMedia.id === media_id;
    });
  }

  private async getMediaMeta(
    mediaToUpdate,
    openExtraSpaceModal,
    response,
    mediaLabel
  ) {
    let mediaMeta = [];

    await Promise.all(
      mediaToUpdate.map(async (media) => {
        const getMediaResponse = await this.getMediaItem(media.item);

        let toUpdate = {
          action: media.action,
          item: getMediaResponse,
        };

        mediaMeta.push(toUpdate);
      })
    );

    ;

    return this.updateMediaMeta(
      mediaMeta,
      openExtraSpaceModal,
      response,
      mediaLabel
    );
  }

  private getMediaItem(media) {
    let getMediaItem = this._mediaManagementService.retrieveMediaItem(
      media.uuid,
      this.token
    );

    return getMediaItem.toPromise();
  }

  private async updateMediaMeta(
    mediaToUpdate,
    openExtraSpaceModal,
    response,
    mediaLabel
  ) {
    await Promise.all(
      mediaToUpdate.map(async (media) => {
        const mediaMetaResponse = await this.updateMediaItem(
          media.item,
          media.action
        );
        ;
      })
    );

    //@todo: need to add error handling here
    const errorMsgObj = {
      statusMsg: `${mediaLabel} successfully added.`,
    };
    this.errorObj.next(errorMsgObj);
    return response;
  }

  private updateMediaItem(media, action) {
    let meta = {
      scheduled_events: [],
    };

    if (media.meta !== undefined && media.meta !== null) meta = media.meta;

    if (meta.scheduled_events === undefined) meta.scheduled_events = [];

    let existing = false;

    if (meta.scheduled_events.length) {
      meta.scheduled_events.forEach((event, index) => {
        if (event.id === this.targetEvent.schedule_id) {
          existing = true;
          if (action === "remove") meta.scheduled_events.splice(index, 1);
        }
      });
    }

    if (!existing && action === "add") {
      let thisAttached = {
        name: this.targetEvent?.name,
        experience: this.targetEvent?.experience,
        id: this.targetEvent?.id,
      };

      meta.scheduled_events.push(thisAttached);
    }
    let body = {
      scheduled_events: meta.scheduled_events,
    };

    let updateMediaItem = this._mediaManagementService.manageMedia(
      media.uuid,
      this.token,
      "update-meta",
      body
    );

    return updateMediaItem.toPromise();
  }
}
