<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">
      Add Permission to Persona
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="addPersonaPermissionModal.hide()"
      id="close-modal-icon"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body m-3 p-0">
    <div class="row flex-add-form">
      <form
        class="text-center p-5 edit-form w-100"
        [formGroup]="permissionForm"
        autocomplete="off"
      >
        <div class="md-form">
          <input
            type="text"
            id="permission_name"
            formControlName="permission_name"
            [ngModel]="searchText | async"
            (ngModelChange)="searchText.next($event)"
            [mdbAutoCompleter]="auto"
            placeholder="Name of Permission to add"
            class="completer-input form-control mdb-autocomplete"
            mdbInput
          />
          <label for="space_name">Permission Name</label>
          <mdb-auto-completer
            #auto="mdbAutoCompleter"
            textNoResults="No results found"
          >
            <mdb-option *ngFor="let option of results | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      data-dismiss="modal"
      (click)="clickAddToPersona()"
      id="add-permission-to-perona-btn"
    >
      Add Persona
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="addPersonaPermissionModal.hide()"
      id="close-modal-btn"
    >
      close
    </button>
  </div>
</div>
