import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { Subject } from "rxjs";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";

@Component({
  selector: 'app-clone-event',
  templateUrl: './clone-event.component.html',
  styleUrls: ['./clone-event.component.scss']
})
export class CloneEventComponent {
  //persistent
  private token: string;
  public labels: any;

  //incoming
  public targetEvent: any; //incoming media object
  public teamID: number;
  public action: string;
  public values: { [key: string]: any } = {};
  public title: string = "Update Media";
  public message: string = "Update the media details below and click 'Update' to save changes.";
  public button: string = "Update";

  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Add",
  };

  //data back to the parent
  private outgoing: Subject<any> = new Subject();

  //media handling
  public clientSettings: any;

  constructor(
    public cloneFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService,
    private _sharedDataService: SharedDataService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit() {
    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.retrieveToken();
    this.retrieveLabels();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  public async cloneEvent() {
    let notification = this._notificationService.savingNotification(
      `Cloning ${this.labels.event.singular}...`
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let cloneEvent = this._xrPlatformRestService.restfulAPIQuery(
      `/event/${this.targetEvent.id}/clone`,
      "post",
      {},
      options
    ).toPromise();

    let cloneResult = await cloneEvent.catch((error) => {
      this._notificationService.clearNotification(notification);
      this._notificationService.errorNotification(`There was an issue cloning this ${this.labels.event.singular}. Please try again.`);
    });

    let event = cloneResult.scheduled_event;

    console.log("event.invitees", event.invitees);

    event.user_invitees_count = event.invitees.filter((invitee) => invitee.invitee_type === "user").length;
    event.group_invitees_count = event.invitees.filter((invitee) => invitee.invitee_type === "group").length;
    event.experiences_count = event.experiences.length;

    let outgoingData = {
      action: this.action,
      changedData: event,
    };

    this.outgoing.next(outgoingData);
    this._notificationService.clearNotification(notification);
    this.cloneFrame.hide();

  }

}
