import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-manage-experience-attributes",
  templateUrl: "./manage-experience-attributes.component.html",
  styleUrls: ["./manage-experience-attributes.component.scss"],
})
export class ManageExperienceAttributesComponent implements OnInit {
  //incoming
  public teamID: number;
  public type: string;
  public targetExperienceAttribute: any;

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Add",
    reset: "Add Another Experience Attribute",
    retry: "Retry",
  };

  private preSelected: {
    label: string;
    name: string;
    description: string;
    type: string;
    constrained: boolean;
  };

  public experienceAttributeForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public continueType: string;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Add an Experience Attribute",
  };
  public formState: string = "active";
  public token: string;
  private outgoing: Subject<any> = new Subject();

  public propsTypes: any = [
    { label: "Boolean", value: "boolean" },
    { label: "Multi Select List", value: "multi_select_list" },
    { label: "Number", value: "number" },
    { label: "Select List", value: "select_list" },
    { label: "Text", value: "string" },
  ];

  public constrained: boolean = false;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public experienceAttributeFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage
  ) {}

  ngOnInit(): void {
    this.continueType = "reset";

    if (this.type === "add") {
      this.continueType = "reset";
    } else if (this.type === "edit") {
      this.btnLabel.retry = "Continue Editing";
      this.continueType = "continue";
    }

    this.retrieveTeamID();
    this.retrieveToken();
    this.buildPreselected();
    this.makeValidatingForm();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private buildPreselected() {
    if (!this.targetExperienceAttribute) {
      this.preSelected = {
        label: "",
        name: "",
        description: "",
        type: "",
        constrained: false,
      };
    } else {
      this.preSelected = {
        label: this.targetExperienceAttribute.label,
        name: this.targetExperienceAttribute.name,
        description: this.targetExperienceAttribute.description,
        type: this.targetExperienceAttribute.type,
        constrained: this.targetExperienceAttribute.constrained,
      };
    }
  }

  private makeValidatingForm() {
    this.experienceAttributeForm = new UntypedFormGroup({
      label: new UntypedFormControl(this.preSelected.label),
      name: new UntypedFormControl(this.preSelected.name),
      description: new UntypedFormControl(this.preSelected.description),
      type: new UntypedFormControl(this.preSelected.type),
      constrained: new UntypedFormControl(this.preSelected.constrained),
    });
  }

  public addExperienceAttribute() {
    let link = "/experience-attribute";
    let requestType = "post";
    let returnMessage = "added";
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Adding Experience Attribute</span>";
    let action = "add";
    if (this.type === "add") {
      this.continueType = "reset";
    } else if (this.type === "edit") {
      link = link + "/" + this.targetExperienceAttribute.id;
      requestType = "put";
      this.msgs.processingMsg =
        "<span class='processing-msg'>Editting Experience Attribute</span>";
      returnMessage = "edited";
      action = "update";
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      label: this.experienceAttributeForm.value.label,
      name: this.experienceAttributeForm.value.name,
      description: this.experienceAttributeForm.value.description,
      type: this.experienceAttributeForm.value.type,
      constrained: this.experienceAttributeForm.value.constrained,
    };

    let addedexperienceAttribute = this._xrPlatformRestService.restfulAPIQuery(
      link,
      requestType,
      data,
      getOptions
    );

    addedexperienceAttribute.subscribe(
      (response) => {
        ;
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        if (response.error !== 418) {
          this.msgs.statusMsg = "Experience Attribute successfully " + returnMessage;
          let outgoingData = {
            action: action,
            experienceAttribute: response.experience_attribute,
          };
          this.outgoing.next(outgoingData);
        } else {
          this.msgs.processingMsg = "";
          this.btnLabel.retry = "Retry";
          this.msgs.errorMsg = response.status;
        }
      },
      (error) => {
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public resetForm() {
    this.experienceAttributeForm.reset();
    this.experienceAttributeForm.value.constrained = false;
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.experienceAttributeFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
