<div class="modal-header text-center">
   <h4 class="modal-title w-100">Manage {{labels.event.singular|titlecase}} {{labels.user.plural|titlecase}}</h4>
</div>
<div class="row"> 
  <div class="col-12">
    <p class="mb-0 my-2">
      Note: {{labels.userGroupMember.plural}} can only be removed from a {{labels.userGroup.singular}} in the {{labels.user.plural|titlecase}}->{{labels.userGroup.plural|titlecase}}
    tab
    </p>
  </div>
</div>
<div class="modal-body media-modal mx-3 p-0 overflow-auto" style="width: auto">
  <div class="table-responsive">
    <table
      *ngIf="members.length > 0"
      class="table table-striped table-hover table-sm"
      mdbTable
      #tableEl="mdbTable"
      mdbTableScroll
      maxHeight="200"
      hover="true"
      striped="true"
    >
      <thead class="sticky-top theme-base-medium-dark white-text">
        <tr>
          <th *ngFor="let head of scheduledTableHeaders" scope="col">
            {{ head | titlecase }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr mdbTableCol *ngFor="let user of members; let i = index">
          <td>{{ user.first_name }}</td>
          <td>{{ user.last_name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.username }}</td>
          <td *ngIf="this.showRoles">
            <span *ngIf="user.role_name !== undefined">{{ user.role_name }}</span
            ><span *ngIf="user.role_name === undefined">Not Set</span>
          </td>
          <td *ngIf="this.showPersonas">
            <span *ngIf="user.persona_name !== undefined">{{
              user.persona_name
            }}</span
            ><span *ngIf="user.persona_name === undefined">Not Set</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <p *ngIf="members.length === 0">No users are yet scheduled for this group.</p>
</div>
<div class="modal-footer">
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    size="md"
    color="link"
    (click)="manageGroupFrame.hide()"
  >
    Close
  </button>
</div>
