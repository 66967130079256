import { CoolLocalStorage } from "@angular-cool/storage";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  NgZone,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef } from "ng-uikit-pro-standard";

import {
  DropzoneComponent,
  DropzoneDirective,
  DropzoneConfigInterface,
} from "ngx-dropzone-wrapper";
import { Subject } from "rxjs";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-manage-media-uploads-model-viewer",
  templateUrl: "./manage-media-uploads-model-viewer.component.html",
  styleUrls: ["./manage-media-uploads-model-viewer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageMediaUploadsModelViewerComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(DropzoneComponent, { static: false })
  componentRef?: DropzoneComponent;
  @ViewChild(DropzoneDirective, { static: false })
  directiveRef?: DropzoneDirective;
  @ViewChild("drpzone") drpzone: DropzoneComponent;
  @ViewChild("dzCustomPreview") dzCustomPreview: ElementRef;
  private restURL: string = this._clientManagementService.getRESTurl();

  //incoming
  public user: any;
  public teamID: number;
  public media_item: any;

  //persistent
  public clientSettings;
  public loading: boolean = true;
  public config: DropzoneConfigInterface = {};

  public generatedThumbnail: any;

  private outgoing: Subject<any> = new Subject();
  public labels: any;
  public token: string;

  //for model viewer
  public modelSrc: string = "";

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    public modalFrame: MDBModalRef,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private _MediaManagementServicesService: MediaManagementServicesService,
    private _clientManagementService: ClientManagementService,
    private _settingsService: SettingsService,
    private _ngZone: NgZone,
    private _notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.retrieveLabels();
    this.token = this._MediaManagementServicesService.retrieveToken();
    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );
  }

  ngAfterViewInit(): void {
    const self = this;

    let previewTemplate = this.dzCustomPreview.nativeElement.innerHTML;

    this.config = {
      acceptedFiles: ".glb",
      autoProcessQueue: false,
      addRemoveLinks: true,
      clickable: true,
      maxFiles: 1,
      errorReset: 5000,
      cancelReset: 5000,
      chunking: true,
      maxThumbnailFilesize: 100,
      maxFilesize: 100000000,
      parallelUploads: 2,
      previewTemplate: previewTemplate,
      previewsContainer: "#previews",

      // TODO: Find out what I did here
      init: function () {
        this.on("addedfile", function (file) {
          console.log("addedfile", file);

          // Check if the file is a GLB
          if (file.name.endsWith(".glb")) {
            // Create a URL for the file

            self._ngZone.run(() => {
              self.modelSrc = URL.createObjectURL(file);

              console.log("modelSrc in addedfile", self.modelSrc);
            });
            // Now you can use the URL to load the file into the Google model-viewer
            // For example:
            // document.querySelector('model-viewer').src = url;
          }

          let maxFiles = 1;
          for (let i = this.files.length - maxFiles - 1; i >= 0; i--) {
            let f = this.files[i];
            if (f.upload.uuid !== file.upload.uuid) this.removeFile(f);
          }
        });
        this.on("complete", function (file) {
          setTimeout(() => {
            this.removeFile(file);
          }, 2000);
        });
      },
    };

    this.restURL = this._clientManagementService.getRESTurl("v1");
    this.config.url = this.restURL + "/asset/" + this.teamID + "/upload";

    const headers = {
      Authorization: "Bearer " + this.token,
    };

    this.config.headers = headers;

    this.loading = false;
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  public onUploadInit(args: any): void {
    console.log("onUploadInit:", args);
  }

  public onUploadError(args: any): void {
    console.log("onUploadError:", args);
  }

  public async onUploadSuccess(args: any): Promise<void> {
    console.log("onUploadSuccess:", args);

    if (this.drpzone.directiveRef.dropzone().getQueuedFiles().length)
      this.drpzone.directiveRef.dropzone().processQueue();
    let openmedia = args[1];
    let num_of_events = 0;
    if (
      openmedia.asset.meta &&
      openmedia.asset.meta != null &&
      openmedia.asset.meta.scheduled_events != null
    ) {
      num_of_events = openmedia.asset.meta.scheduled_events.length;
    }
    openmedia.asset.num_of_events = num_of_events;

    openmedia.asset.num_of_shares = openmedia.asset.shared_with.length;
    openmedia.asset.attached = [];

    if (this.generatedThumbnail !== undefined) {
      let uploadThumbnail = await this.uploadThumbnail(
        this.generatedThumbnail,
        openmedia.asset.uuid,
        openmedia.asset.name
      ).catch ((err) => {
        console.log("err in uploadThumbnail", err);
      });

      let outgoingData = {
        action: "add",
        changedData: uploadThumbnail,
      };

      this.outgoing.next(outgoingData);
    } else {
      let outgoingData = {
        action: "add",
        changedData: openmedia,
      };
      this.outgoing.next(outgoingData);
    }
  }

  public startUpload(): void {
    this.drpzone.directiveRef.dropzone().processQueue();
  }

  public onGeneratedThumbnail(event) {
    console.log("event in onGeneratedThumbnail", event);

    //convert blog to image we can use for preview
    this.generatedThumbnail = event;
    let generatedThumbnailLink = URL.createObjectURL(event);

    //add to dropzone preview
    let preview = document.querySelector(".dz-preview");
    let previewImage = preview.querySelector(".dz-image");
    let previewImageThumb = previewImage.querySelector("img");
    previewImageThumb.src = generatedThumbnailLink;
  }

  private uploadThumbnail(res, uuid, name) {
    const headers = {
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    //remove extension from name without removing any other periods
    let nameWithoutExtension = name.substring(0, name.lastIndexOf("."));

    let file = new File([res], nameWithoutExtension, {
      type: res.type,
    });

    let data = new FormData();
    data.append("file", file);
    data.append("max_width", "500");

    const targetURL = "/asset/" + uuid + "/thumbnail";

    let uploadThumbnail = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "post",
      data,
      options,
      "v1"
    );

    return uploadThumbnail.toPromise();
  }
}
