import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadquestAnalyticsHighlevelComponent } from './readquest-analytics-highlevel/readquest-analytics-highlevel.component';
import { SharedModulesModule } from '../shared-modules/shared-modules.module';



@NgModule({
  declarations: [ReadquestAnalyticsHighlevelComponent],
  imports: [
    CommonModule, SharedModulesModule
  ],
  exports: [ReadquestAnalyticsHighlevelComponent]
})
export class DevOnlyModule { }
