<div class="row">
  <audio-player
    style="padding-top: 20px"
    [playlist]="msaapPlaylist"
    [displayTitle]="msaapDisplayTitle"
    [autoPlay]="false"
    muted="muted"
    [displayPlaylist]="msaapDisplayPlayList"
    [pageSizeOptions]="msaapPageSizeOptions"
    [displayArtist]="msaapDisplayArtist"
    [displayDuration]="msaapDisplayDuration"
    [expanded]="true"
    [displayVolumeControls]="msaapDisplayVolumeControls"
    [displayRepeatControls]="msaapDisplayRepeatControls"
    [displayVolumeSlider]="true"
    [disablePositionSlider]="msaapDisablePositionSlider"
    (trackEnded)="onEnded()"
  >
  </audio-player>
</div>
