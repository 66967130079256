<div class="event-types-container">
  <div class="row justify-content-between">
    <div class="col-4">
      <div class="md-form w-100 mr-2 experience-search">
        <input
          class="form-control"
          id="search"
          type="search"
          mdbInput
          [(ngModel)]="eventTypesSearchText"
        />
        <label for="search">Search Event Types</label>
      </div>
    </div>
    <div class="col-3">
      <div class="btn-spacing text-right">
        <button
          class="theme-modal-button btn"
          type="button"
          mdbBtnp
          mdbWavesEffect
          (click)="openAddEventTypesModal()"
        >
          <mdb-icon far icon="plus-square" class="pr-1"></mdb-icon>
          Add Event Type
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="!eventTypesLoading">
      <div class="card-group">
        <div
          *ngFor="
            let eventType of eventTypes | filter: eventTypesSearchText;
            let i = index
          "
        >
          <mdb-card>
            <mdb-card-body>
              <a class="activator px-3 mr-6">
                <mdb-icon far icon="edit"></mdb-icon>
                <mdb-icon fas icon="archive"></mdb-icon>
              </a>
              <mdb-card-title>
                <h5 class="mb-3">{{ eventType.label }}</h5>
              </mdb-card-title>
              <mdb-card-text class="mb-0">
                <div class="col-md-12">
                  <ul class="list-unstyled">
                    <li>
                      {{ eventType.description }}
                    </li>
                  </ul>
                </div>
              </mdb-card-text>
            </mdb-card-body>
            <mdb-card-footer> </mdb-card-footer>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
</div>
