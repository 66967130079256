import { Component, Input, Output, ViewEncapsulation } from "@angular/core";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { ManageAppVersionsComponent } from "./modals/manage-app-versions/manage-app-versions.component";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "app-versions",
  templateUrl: "./app-versions.component.html",
  styleUrls: ["./app-versions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppVersionsComponent {
  @Input() data: any;
  @Input() opts: any;
  @Input() clientSettings: any;
  @Output() outgoing: any = new Subject();

  //table handling
  public tableLoading: boolean = false;
  public errorRetrievingMsg: string = "Error retrieving versions";
  public tableData: any;
  public tableDataType: {
    singular: string;
    plural: string;
  } = {
    singular: "version",
    plural: "versions",
  };
  public tableColumns: {
    type: string;
    parent?: string;
    target: string;
    actions?: any;
  }[] = [
    {
      type: "actions",
      target: "actions",
      actions: [
        {
          label: "Edit",
          icon: "faPencilAlt",
          type: "edit",
          onClick: (row) => {
            this.manageVersion("edit", row);
          },
        },
        {
          label: "Delete",
          icon: "faTrashAlt",
          type: "delete",
          onClick: (row) => {
            this.manageVersion("delete", row);
          },
        },
      ],
    },
    {
      type: "keyValue",
      target: "label",
    },
    {
      type: "keyValue",
      target: "as_of",
    },
    {
      type: "keyValue",
      target: "current_version",
    },
    {
      type: "keyParent",
      parent: "app_bundle",
      target: "label",
    },
    {
      type: "keyParent",
      parent: "app_device",
      target: "label",
    },
  ];

  //head elements
  public headElements = [
    "action",
    "Label",
    "As Of",
    "Current Version",
    "Bundle",
    "Device",
  ];

  //sorting
  sort_elements = [
    {
      type: "none",
      head: "actions",
    },
    {
      type: "string",
      head: "label",
    },
    {
      type: "date",
      head: "as_of",
    },
    {
      type: "string",
      head: "current_version",
    },
    {
      type: "string",
      head: "label",
      parent: "app_bundle",
    },
    {
      type: "string",
      head: "label",
      parent: "app_device",
    },
  ];
  sort = {
    direction: "asc",
    head: "as_of",
    type: "date",
  };

  public sortBy = "as_of";
  public sortOrder = "desc";

  //filtering
  filter_elements = [];
  public filterText = {
    label: "",
    as_of: "",
    current_version: "",
    app_bundle: 0,
    app_device: 0,
  };
  public compoundFilterParams: {};

  //modal related
  private modalOptions = {
    backdrop: "static",
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public versionFrame: MDBModalRef;

  //subscriptions
  private shareDataSubscription: Subscription;

  constructor(
    private modalService: MDBModalService,
    private _sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.configureFilters();
    this.tableData = this.data.versions;

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "add_version"
          ) {
            this.manageVersion("add");
          }
        }
      });

    //also let menu know that we're done loading
    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);
  }

  private configureFilters() {
    this.filter_elements = [
      {
        filter: false,
        type: "close_filters",
        target: "actions",
      },
      {
        filter: true,
        type: "text",
        target: "label",
      },
      {
        filter: true,
        type: "date",
        target: "as_of",
      },
      {
        filter: true,
        type: "text",
        target: "current_version",
      },
      {
        filter: true,
        type: "select",
        target: "label",
        parent: "app_bundle",
        options: this.opts.appBundles,
      },
      {
        filter: true,
        type: "select",
        target: "label",
        parent: "app_device",
        options: this.opts.appDevices,
      },
    ];

    this.compoundFilterParams = {
      label: {
        text: this.filterText.label,
        type: "text",
      },
      as_of: {
        text: this.filterText.as_of,
        type: "date",
      },
      current_version: {
        text: this.filterText.current_version,
        type: "text",
      },
      app_bundle: {
        text: this.filterText.app_bundle,
        parent: "app_bundle",
        type: "select",
      },
      app_device: {
        text: this.filterText.app_device,
        parent: "app_device",
        type: "select",
      },
    };
  }

  public manageVersion(action, version?) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass:
        action === "delete"
          ? "new remove-class-container"
          : "new schedule-class-container",
      class:
        action !== "delete"
          ? this.modalOptions.class +
            " modal-full-height modal-right manage-event-display"
          : "",
    };

    this.modalOptions.data = {
      teamID: this.data.teamID,
      type: "version",
      targetVersion: version,
      action: action,
      data: this.data,
    };

    this.versionFrame = this.modalService.show(
      ManageAppVersionsComponent,
      this.modalOptions
    );

    this.versionFrame.content.outgoing.subscribe((result: any) => {
      ;

      if (action === "add") {
        this.data.versions.push(result.app_version);
      } else if (action === "edit") {
        this.data.versions = this.data.versions.map((version) => {
          if (version.id === result.app_version.id) {
            version = result.app_version;
          }
          return version;
        });
      } else if (action === "delete") {
        this.data.versions = this.data.versions.filter((version) => {
          return version.id !== result.app_version.id;
        });
      }

      console.log(
        "this.data.versions after processing return",
        this.data.versions
      );
      this.tableData = this.data.versions.filter((version) => {
        return version;
      });

      this.outgoing.next({
        type: "updateVersions",
        data: {
          versions: this.data.versions,
        },
      });
    });
  }
}
