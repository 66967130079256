<div class="background-replacement" (click)="turnConfirmOn(confirmModal)"></div>
<div class="class-wrapper" [ngClass]="{ 'confirm-on': confirmOn }">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <mdb-icon fas icon="{{ icon }}"></mdb-icon> {{ title | titlecase }}
      <mdb-icon
        fas
        icon="user-plus"
        (click)="openAddEventPropsModal()"
      ></mdb-icon>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="experienceMetaFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- default form -->
  <div class="modal-body mt-3 p-0">
    <div class="row">
      <div class="col-12">
        <!-- info -->
        <p class="text-center" *ngIf="action !== 'remove'">
          {{ msgs.actionMsg }}: <strong>{{ targetEvent.name }}</strong>
        </p>
        <p class="text-center" *ngIf="action === 'remove'">
          Are you sure want to remove this building?
        </p>
        <!-- /info -->
      </div>
    </div>

    <!--default form-->
    <div *ngIf="metaType === 'default' && !showProps">
      <form *ngIf="formLoading" [formGroup]="metaForm">
        <div class="row">
          <div class="col-6">
            <div class="md-form">
              <input
                type="text"
                id="metaDescription"
                class="form-control"
                formControlName="description"
                mdbInput
              />
              <label for="metaDescription">Description</label>
            </div>
          </div>

          <div class="col-6" *ngIf="formOptions.number_of_seats.status">
            <div class="md-form">
              <div>
                <mdb-select
                  [options]="formOptions.number_of_seats.selectValues"
                  id="number_of_seats"
                  mdbInput
                  mdbValidate
                  formControlName="number_of_seats"
                  placeholder="Choose Number of Seats"
                  label="Number of Seats"
                >
                </mdb-select>
              </div>
            </div>
          </div>

          <div class="col-6" *ngIf="formOptions.protocol.status">
            <div class="md-form">
              <div>
                <mdb-select
                  [options]="formOptions.protocol.selectValues"
                  id="protocol"
                  mdbInput
                  mdbValidate
                  formControlName="protocol"
                  placeholder="Choose a Protocol"
                  label="Protocol"
                >
                </mdb-select>
              </div>
            </div>
          </div>

          <div class="col-6" *ngIf="formOptions.display_name.status">
            <div class="md-form">
              <input
                type="text"
                id="displayName"
                class="form-control"
                formControlName="display_name"
                mdbInput
              />
              <label for="displayName">Display Name</label>
            </div>
          </div>

          <div class="col-6" *ngIf="formOptions.chimera_environment.status">
            <div class="md-form">
              <input
                type="text"
                id="chimeraEnvironment"
                class="form-control"
                formControlName="chimera_environment"
                mdbInput
              />
              <label for="chimeraEnvironment">Chimera Environment</label>
            </div>
          </div>

          <div class="col-6" *ngIf="formOptions.initial_pdf.status">
            <div class="md-form">
              <input
                type="text"
                id="initialPDF"
                class="form-control"
                formControlName="initial_pdf"
                mdbInput
              />
              <label for="initialPDF">Initial PDF</label>
            </div>
          </div>
          <div class="col-6" *ngIf="formOptions.initial_video.status">
            <div class="md-form">
              <input
                type="text"
                id="initialVideo"
                class="form-control"
                formControlName="initial_video"
                mdbInput
              />
              <label for="initialVideo">Initial Video</label>
            </div>
          </div>

          <div class="col-6" *ngIf="formOptions.photon_id.status">
            <div class="md-form">
              <input
                type="text"
                id="photonID"
                class="form-control"
                formControlName="photon_id"
                mdbInput
              />
              <label for="photonID">Photon ID</label>
            </div>
          </div>
          <div class="col-6" *ngIf="formOptions.event_type.status">
            <div class="md-form">
              <mdb-select
                [options]="formOptions.event_type.selectValues"
                formControlName="event_type"
                label="Type of Room"
              ></mdb-select>
            </div>
          </div>
        </div>

        <div
          class="row attachment"
          *ngIf="
            formOptions.logo.status ||
            formOptions.pdf.status ||
            formOptions.video.status
          "
        >
          <div class="col-4" *ngIf="formOptions.logo.status">
            <mdb-card class="w-100">
              <mdb-card-body class="exp-card">
                <div class="md-form">
                  <p>Logo</p>
                  <button
                    type="button"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-sm"
                    outline="true"
                    color="theme-medium"
                    (click)="openMediaModal('image', 'logo')"
                  >
                    select logo
                  </button>
                  <div
                    [ngStyle]="{
                      'background-image':
                        'url(' +
                        (thumbnails.logo !== null
                          ? thumbnails.logo
                          : placeHolderImg) +
                        ')'
                    }"
                    class="thumbnail-container"
                  ></div>
                  <p *ngIf="thumbnails.logo !== null" class="text-ellipsis">
                    {{ preSelected.logo[0].name }}
                  </p>
                  <p *ngIf="thumbnails.logo === null" class="text-ellipsis">
                    Not Selected
                  </p>
                </div>
              </mdb-card-body>
            </mdb-card>
          </div>

          <div class="col-4" *ngIf="formOptions.pdf.status">
            <mdb-card class="w-100">
              <mdb-card-body class="exp-card">
                <div class="md-form">
                  <p>PDF</p>
                  <button
                    type="button"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-sm"
                    outline="true"
                    color="theme-medium"
                    (click)="openMediaModal('pdf', 'pdf')"
                  >
                    select pdf
                  </button>
                  <div
                    [ngStyle]="{
                      'background-image':
                        'url(' +
                        (thumbnails.pdf ? activeHolderPdf : placeHolderPdf) +
                        ')'
                    }"
                    class="thumbnail-container"
                  ></div>

                  <p *ngIf="thumbnails.pdf" class="text-ellipsis">
                    {{ preSelected.pdf[0].name }}
                  </p>
                  <p *ngIf="!thumbnails.pdf" class="text-ellipsis">
                    Not Selected
                  </p>
                </div>
              </mdb-card-body>
            </mdb-card>
          </div>

          <div class="col-4" *ngIf="formOptions.video.status">
            <mdb-card class="w-100">
              <mdb-card-body class="exp-card">
                <div class="md-form">
                  <p>Video</p>
                  <button
                    type="button"
                    mdbBtn
                    mdbWavesEffect
                    class="btn-sm"
                    outline="true"
                    color="theme-medium"
                    (click)="openMediaModal('video', 'video')"
                  >
                    select video
                  </button>
                  <div
                    [ngStyle]="{
                      'background-image':
                        'url(' +
                        (thumbnails.video ? activeHolderMp4 : placeHolderMp4) +
                        ')'
                    }"
                    class="thumbnail-container"
                  ></div>
                  <p *ngIf="thumbnails.video !== null" class="text-ellipsis">
                    {{ preSelected.video[0].name }}
                  </p>
                  <p *ngIf="!thumbnails.video" class="text-ellipsis">
                    Not Selected
                  </p>
                </div>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
      </form>
    </div>
    <!-- /default form-->

    <!--buildings form-->
    <div
      class="row justify-content-center"
      *ngIf="metaType === 'building' && action !== 'remove'"
    >
      <div class="col-9">
        <form
          *ngIf="formLoading"
          [formGroup]="metaForm"
          class="text-center my-3"
        >
          <div class="md-form">
            <mdb-select-2
              id="building"
              formControlName="building"
              placeholder="Choose A {{ labels.building.singular }}"
              label="{{ labels.event.singular | titlecase }} {{ labels.building.singular | titlecase }}"
              class="select-buildings"
            >
              <mdb-select-option
                *ngFor="let building of buildingSelect"
                [value]="building.value"
              >{{ building.label }}
              </mdb-select-option>
            </mdb-select-2>
          </div>
        </form>
      </div>
    </div>
    <!--/buildings form-->

    <div class="modal-footer">
      <button
        *ngIf="formLoading"
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button btn"
        data-dismiss="modal"
        [disabled]="
          action !== 'remove'
            ? formState === 'processing' ||
              (metaType === 'building' &&
                (!optionSelected))
            : false
        "
        (click)="updateMetaInit()"
      >
        {{ action !== "remove" ? btnLabel.main : "Remove" }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        data-dismiss="modal"
        (click)="turnConfirmOn(confirmModal)"
      >
        {{ btnLabel.close }}
      </button>
    </div>
  </div>
</div>
<!-- confirm modal -->
<div
  mdbModal
  #confirmModal="mdbModal"
  class="modal confirm-modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModal"
  aria-hidden="true"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100">Confirm</h4>
      </div>
      <div class="modal-body">
        <p>
          <strong>Are you sure?</strong>
        </p>
        <p>All form fields will be cleared upon exit.</p>
      </div>
      <div class="modal-footer">
        <button
          mdbBtn
          type="button"
          mdbWavesEffect
          class="theme-modal-button btn"
          (click)="this.closeConfirm(confirmModal)"
        >
          Continue Editing
        </button>
        <button
          mdbBtn
          mdbWavesEffect
          type="button"
          size="md"
          color="link"
          (click)="this.closeAllModals(confirmModal)"
        >
          Close Without Saving
        </button>
      </div>
    </div>
  </div>
</div>
