<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">Edit {{labels.room.singular|titlecase}}</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="editclassroom.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3 p-0">
    <div class="row flex-add-form">
      <form
        class="text-center border border-light p-5 edit-form w-100"
        [formGroup]="roomFormGroup"
        autocomplete="off"
      >
        <div class="md-form mb-5">
          <input
            type="text"
            id="room_name"
            formControlName="room_name"
            [ngModel]="searchText | async"
            (ngModelChange)="searchText.next($event)"
            [mdbAutoCompleter]="auto"
            placeholder="Name of Event you to edit"
            class="completer-input form-control mdb-autocomplete"
            mdbInput
          />
          <label for="room_name">Room Name</label>
          <mdb-auto-completer
            #auto="mdbAutoCompleter"
            textNoResults="No results found"
          >
            <mdb-option *ngFor="let option of results | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="editclassroom.hide()"
    >
      Close
    </button>
    <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="clickEditRoom()"
    >
      Edit {{labels.room.singular|titlecase}}
    </button>
  </div>
</div>