import { CoolLocalStorage } from "@angular-cool/storage";
import { DOCUMENT, TitleCasePipe } from "@angular/common";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

import {
  faTimes,
  faCirclePlus,
  faFilePdf,
  faTrashAlt,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import { EventSettingsManageMediaComponent } from "src/app/modules/event-management/modals/event-settings-manage-media/event-settings-manage-media.component";
import { ManagePhonemeListsComponent } from "../manage-phoneme-lists/manage-phoneme-lists.component";
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

interface Body {
  label?: string;
  description?: string;
  organization?: string;
  archived?: boolean;
  asset_id?: number;
  target_user?: number;
}

@Component({
  selector: 'app-authoring-skills-management',
  templateUrl: './authoring-skills-management.component.html',
  styleUrls: ['./authoring-skills-management.component.scss'],
  providers: [TitleCasePipe, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class AuthoringSkillsManagementComponent implements OnInit {
  //incoming
  public action: string;
  public targetSkill: any;
  public skills: any;
  public user: any;
  public targetUserOptions: { value: string; label: string }[];
  public phonemeTypes: any;
  public unpredictableVowelTeams: any;

  //persistent
  private teamID: number;
  public token: string;
  public labels: any;
  public skillMedia: any;

  //form values
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: "Add",
      reset: "Add Another Skill",
      retry: "Retry",
    };

  private preSelected: {
    label: string;
    organization: string;
    description: string;
    asset_id: number;
    target_user: string;
  };

  public skillForm: UntypedFormGroup;
  public formLoading: boolean = true;
  public formState: string = "active";

  //outbound
  private outgoing: Subject<any> = new Subject();

  //user feeback
  public notification: any;

  //tokens
  public faTimes = faTimes;
  public faCirclePlus = faCirclePlus;
  public faFilePdf = faFilePdf;
  public faTrashAlt = faTrashAlt;
  public faPencilAlt = faPencilAlt;

  //modal related
  private modalOptions = {
    backdrop: "static",
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public selectMediaFrame: MDBModalRef;
  public skillsFrame: MDBModalRef;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public skillFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService,
    private TitleCase: TitleCasePipe,
    private modalService: MDBModalService,
    private confirmationService: ConfirmationService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.retrieveTeamID();
    this.retrieveToken();
    this.retrieveLabels();
    this.buildPreselected();
    this.makeValidatingForm();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(this.coolLocalStorage.getItem("admin_panel_team_id"));
  }

  private buildPreselected() {
    if (!this.targetSkill) {
      this.preSelected = {
        label: "",
        organization: "",
        description: "",
        asset_id: 0,
        target_user: '0',
      };

      this.skillMedia = null;
    } else {
      this.preSelected = {
        label: this.targetSkill.label,
        organization: this.targetSkill.organization,
        description: this.targetSkill.description,
        asset_id: this.targetSkill.asset_id,
        target_user: this.targetSkill.target_user.toString(),
      };

      this.skillMedia = this.targetSkill.asset !== null ? this.targetSkill.asset : null;
    }
  }

  private makeValidatingForm() {
    this.skillForm = new UntypedFormGroup({
      label: new UntypedFormControl(
        this.preSelected.label,
        Validators.required
      ),
      organization: new UntypedFormControl(this.preSelected.organization),
      description: new UntypedFormControl(this.preSelected.description),
      asset_id: new UntypedFormControl(this.preSelected.asset_id),
      target_user: new UntypedFormControl(this.preSelected.target_user),
    });

    this.formLoading = false;
  }

  public async manageSkill() {
    this.formState = "processing";

    let actionAdj = this.action.replace(/e$/, "");
    this.notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing skill...`
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let method = this.action === "add" ? "post" : "put";
    let target = this.action === "add" ? "skill" : `skill/${this.targetSkill.id}`;

    const body: Body = this.action === 'archive'
      ? { archived: true }
      : {
        label: this.skillForm.value.label,
        organization: this.skillForm.value.organization,
        description: this.skillForm.value.description,
        asset_id: this.skillForm.value.asset_id,
        target_user: parseInt(this.skillForm.value.target_user),
      };

    let addSkill = await this._xrPlatformRestService.restfulAPIQuery(
      `/authoring/${target}`,
      method,
      body,
      options
    ).toPromise().catch((error) => {
      this.formState = "error";
      this._notificationService.errorNotification(
        "There was an error while adding the skill. Please try again or contact support"
      );
    });

    if (addSkill) {

      let outbound = {
        action: this.action,
        skill: addSkill.skill,
      };

      this.outgoing.next(outbound);
      this.formState = "active";
      this._notificationService.clearNotification(this.notification);
      this.skillFrame.hide();
    }

  }

  public openSelectMediaModalForProp() {
    let mainEventDisplay = this.document.getElementsByClassName(
      "manage-experiences-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.data = {
      type: "pdf",
      targetAsset: this.targetSkill.asset !== null ? this.targetSkill.asset : null,
      fromLocation: "manageSkill",
      experience: null,
      labels: this.labels,
      parentModalClass: "manage-experiences-container",
      typeLabel: "PDF",
      buttonLabel: "Update and Return to Skill",
      teamID: this.teamID,
    };

    this.modalOptions.class =
      this.modalOptions.class + " modal-full-height modal-right";
    this.modalOptions.containerClass = "event-media-container";
    this.selectMediaFrame = this.modalService.show(
      EventSettingsManageMediaComponent,
      this.modalOptions
    );

    this.selectMediaFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.media !== undefined && changedData.media.length) {

        this.skillForm.controls.asset_id.setValue(changedData.media[0].id);
        this.skillMedia = changedData.media[0];

      } else if (changedData.media === undefined || !changedData.media.length) {

        this.skillForm.controls.asset_id.setValue(null);
        this.skillMedia = null;
      }
    });
  }

  //open modeal managePhonemeLists
  public managePhonemeLists(action, phoneme_list?) {

    let loadingNotification = null;

    if (action === "update") loadingNotification = this._notificationService.savingNotification("Loading phoneme list...");

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-phonemes-container",
      class: this.modalOptions.class + " modal-full-height modal-full-width modal-right",
    };

    this.modalOptions.data = {
      action: action,
      user: this.user,
      skillID: this.targetSkill.id,
      targetList: phoneme_list !== undefined ? phoneme_list : null,
      phonemeTypes: this.phonemeTypes,
      unpredictableVowelTeams: this.unpredictableVowelTeams,
      loadingNotification: loadingNotification
    };

    this.skillsFrame = this.modalService.show(ManagePhonemeListsComponent, this.modalOptions);

    this.skillsFrame.content.outgoing.subscribe((result) => {

      console.log("result in skills frame", result);

      if (result.action !== undefined) {
        let actionAdj = "phoneme lists";
        this._notificationService.successNotification(
          `Word List successfully ${actionAdj}ed`
        );

        if (result.action === "add") {

          let thisList = {
            id: result.data.phoneme_list.id,
            list_id: result.data.phoneme_list.list_id,
            phoneme_list: result.data.phoneme_list,
            skill_id: this.targetSkill.id
          }

          this.targetSkill.phoneme_lists.push(thisList);
        } else {
          this.targetSkill.phoneme_lists = this.targetSkill.phoneme_lists.map((list) => {
            if (list.id === result.data.phoneme_list.id) console.log("result.data.phoneme_list", JSON.parse(JSON.stringify(result.data.phoneme_list)));
            return list.id === result.data.phoneme_list.id ? {
              id: result.data.phoneme_list.id,
              list_id: result.data.phoneme_list.list_id,
              phoneme_list: result.data.phoneme_list,
              skill_id: this.targetSkill.id
            } : list;
          });
        }


        console.log("this.targetSkill", this.targetSkill);

        let outgoingData = {
          action: "update",
          skill: this.targetSkill
        }

        this.outgoing.next(outgoingData);
      }

    });
  }

  public initiateDeleteList(list) {
    this.confirmationService.confirm({
      message: `Do you want to delete the ${list.label} list?`,
      icon: 'pi pi-exclamation-triangle',
      key: "deleteConfirm",
      accept: () => {
        this.deleteList(list);
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            console.log("rejected");
            break;
          case ConfirmEventType.CANCEL:
            console.log("confirmed");
            break;
        }
      }
    });
  }

  public deleteList(list) {

    let notification = this._notificationService.savingNotification("Deleting list...");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/skill/${this.targetSkill.id}/phoneme-list/${list.id}/delete`;

    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "put",
      {},
      postOptions
    );

    eventRetrieve.subscribe((response) => {
      this._notificationService.clearNotification(notification);
      this._notificationService.successNotification("List deleted successfully");

      this.targetSkill.phoneme_lists = this.targetSkill.phoneme_lists.filter((thisList) => thisList.id !== list.id);

      let outgoingData = {
        action: "update",
        skill: this.targetSkill
      }

      this.outgoing.next(outgoingData);

    }, (error) => {
      this._notificationService.errorNotification("There was an error deleting list");
    });

  }
}
