import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef, PopoverDirective } from 'ng-uikit-pro-standard';

import {
  faCirclePlus,
  faSquareMinus,
  faUsers,
  faSquarePlus,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { DOCUMENT, TitleCasePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { CoolLocalStorage } from '@angular-cool/storage';

@Component({
  selector: 'app-manage-media-events',
  templateUrl: './manage-media-events.component.html',
  styleUrls: ['./manage-media-events.component.scss'],
  providers: [TitleCasePipe]
})
export class ManageMediaEventsComponent implements OnInit {
  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  //incoming
  public media_item: any;
  public labels: any;
  public teamID: number;
  public token: string;
  public action: string = "update";

  //outbound
  private outgoing: Subject<any> = new Subject();

  //tracking
  public eventsLoading: boolean = true;
  public attachedEventsLoading: boolean = true;
  public isClean: boolean = true;
  public processing: boolean = false;

  //visibility control
  public user: any;
  public isModerator: boolean = false;

  //search
  public SearchText: string;

  //event data
  public eventList: any = [];
  public origEventList: any = [];
  public unchangedEventList: any = [];
  public inviteeEvents: any = [];
  public origInviteeEvents: any = [];
  public addedEvents: any = [];

  //icons
  public faCirclePlus = faCirclePlus;
  public faSquareMinus = faSquareMinus;
  public faUsers = faUsers;
  public faSquarePlus = faSquarePlus;
  public faTimes = faTimes;

  constructor(public eventMediaModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService,
    private TitleCase: TitleCasePipe,
    private coolLocalStorage: CoolLocalStorage,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.retrieveUser();
    this.retrieveData();
  }

  private async retrieveData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let events = await this.retrieveEvents(options);
    let attachedEvents = await this.retrieveAttachedEvents(options);

    this.processEvents(events.events, attachedEvents.events);

    this.eventsLoading = false;
    this.attachedEventsLoading = false;

    this.buildPreselected();
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");

    if (this.user.role_type_id === 3) this.isModerator = true;
  }

  private retrieveEvents(options) {

    let targetURL = `/team/${this.teamID}/events`;

    if (this.isModerator) targetURL = `/team/${this.teamID}/events/user`;

    return this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "get",
      {},
      options
    ).toPromise();
  }

  private retrieveAttachedEvents(options) {
    return this._xrPlatformRestService.restfulAPIQuery(
      `/asset/${this.media_item.id}/events`,
      "get",
      {},
      options
    ).toPromise();
  }

  private processEvents(events, attachedEvents) {

    if (attachedEvents.length > 0) {
      attachedEvents.forEach((attachedEvent) => {
        let event = events.filter((event) => event.id === attachedEvent.id);
        if (event.length > 0) {
          this.origEventList.push(event[0]);
          this.inviteeEvents.push(event[0]);

          //remove from events
          events = events.filter((item) => item.id !== event[0].id);
        }
      });
    }

    events = events.sort((a, b) =>
      a.event_name > b.event_name ? 1 : b.event_name > a.event_name ? -1 : 0
    );

    this.origInviteeEvents = JSON.parse(JSON.stringify(events));
    this.unchangedEventList = JSON.parse(JSON.stringify(events));
    this.eventList = events;

  }

  buildPreselected() {
    this.inviteeEvents.forEach((event) => {
      this.addedEvents.push(event);
    });
  }

  search(): void {

    if (this.SearchText === undefined || this.SearchText === null || this.SearchText === "") {
      this.eventList = this.unchangedEventList;
      return;
    }

    const searchKey = this.SearchText.toLowerCase();
    this.eventList = this.eventList.filter(function (tag) {
      return (
        tag.event_name.toLowerCase().indexOf(searchKey) >= 0
      );
    });
  }

  public addEvent(event) {
    const isEventAlreadyAdded =
      this.addedEvents.filter((item) => item.id === event.id).length > 0;
    if (!isEventAlreadyAdded) {
      this.eventList = this.eventList.filter((item) => item.id !== event.id);
      this.origEventList = this.origEventList.filter((item) => item.id !== event.id);
      this.addedEvents.push(event);

      this.isClean = this.checkForRosterChanges(
        this.origInviteeEvents,
        this.addedEvents
      );
    }
  }

  public removeAddedEvent(event) {
    this.addedEvents.forEach((element, index) => {
      if (element.id == event.id) {
        this.addedEvents.splice(index, 1);
      }
    });

    this.isClean = this.checkForRosterChanges(
      this.origInviteeEvents,
      this.addedEvents
    );

    console.log("removedItem in removeAddedEvent", event);

    this.eventList.push(event);
    this.eventList.sort((a, b) =>
      a.event_name > b.event_name ? 1 : b.event_name > a.event_name ? -1 : 0
    );
    this.origEventList.push(event);
    this.origEventList.sort((a, b) =>
      a.event_name > b.event_name ? 1 : b.event_name > a.event_name ? -1 : 0
    );
  }

  /**
  * For each item in origList, see if the property other_id matches the id property in any item in currentList
  * Also do the vice versa to check for new adds
  * If it does not, then there are changes
  * @param origList
  * @param currentList
  */
  private checkForRosterChanges(origList, currentList) {
    let isClean = true;

    console.log("origList in checkForRosterChanges", origList);
    console.log("currentList in checkForRosterChanges", currentList);

    if (origList.length !== currentList.length) return false;

    origList.forEach((element) => {
      let isFound = currentList.filter((item) => item.id === element.other_id);
      if (isFound.length === 0) isClean = false;
    });

    currentList.forEach((element) => {
      let isFound = origList.filter((item) => item.other_id === element.id);
      if (isFound.length === 0) isClean = false;
    });

    return isClean;
  }

  public async updateAttachedEvents() {
    let attached = this.addedEvents;
    let newAttached = attached.map((event) => {
      return {
        id: event.id,
      };
    });

    //add toast notifications
    let actionAdj = this.action.replace(/e$/, "");
    let notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing ${this.labels.media.singular
      }`
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let body = {
      events: newAttached,
    };

    let response = await this._xrPlatformRestService.restfulAPIQuery(
      `/asset/${this.media_item.id}/events`,
      "post",
      body,
      options
    ).toPromise();

    let outgoing = {
      action: "updated_attached_events",
      response: response.added,
    }

    this.outgoing.next(outgoing);

    this._notificationService.clearNotification(notification);
    this.eventMediaModal.hide();
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("secondary");
      mainEventDisplay.item(0).classList.add("restore-primary");
    }

    this.eventMediaModal.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }

}
