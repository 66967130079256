import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ViewChildren,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { TitleCasePipe } from "@angular/common";

import {
  MDBModalRef,
  MDBDatePickerComponent,
  MDBModalService,
} from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { IMyOptions, IMyDate } from "ng-uikit-pro-standard";
import { CollapseComponent } from "ng-uikit-pro-standard";

import * as moment from "moment";
import "moment-timezone";
import { DateFormatPipe } from "ngx-moment";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { TimeMustBeLater } from "../validators/time-must-be-later.directive";
import { DateMustBeLater } from "../validators/date-must-be-later.directive";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { CloseConfirmationComponent } from "src/app/modules/persistent/close-confirmation/close-confirmation.component";
import { EventCapacity } from "../validators/event-capacity.directive";
import { HubsWithinAZoneComponent } from "../../../event-management/modals/hubs-within-a-zone/hubs-within-a-zone.component";
import { EventSettingsManageMediaComponent } from "src/app/modules/event-management/modals/event-settings-manage-media/event-settings-manage-media.component";

@Component({
  selector: "app-schedule-class",
  templateUrl: "./schedule-class.component.html",
  styleUrls: ["./schedule-class.component.scss"],
  providers: [DateFormatPipe, TitleCasePipe],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleClassComponent implements OnInit, AfterViewInit {
  @ViewChild("datePickerStart", { static: false })
  datePickerStart: MDBDatePickerComponent;

  @ViewChild("datePickerEnd", { static: false })
  datePickerEnd: MDBDatePickerComponent;

  @ViewChild("closeConfirmation", { static: false })
  closeConfirmation: CloseConfirmationComponent;

  @ViewChildren(CollapseComponent) collapses!: CollapseComponent[];

  public foo: boolean = true;

  public labels: any;
  private token: string;
  public clientSettings: any;
  public clientSettingsLegacy: any;
  public user: any;
  public teamID: number;
  public inviteeVersion: number;
  private outgoing: Subject<any> = new Subject();
  public timeTracker: {
    start: number;
    end: number;
  } = {
      start: 0,
      end: 0,
    };
  public dateLabels: {
    end: string;
  } = {
      end: "Please select a start date first",
    };
  public mainModal: any;

  public experience: any;
  public multiExperiences: boolean = false;
  public experiences: any;

  public teamProps: any = [];
  public teamPropOptions: any = [];
  public attendeePropID: number = 0;
  public attendeeSelectDisabled: boolean = true;
  public attendeeSelect: { value: string; label: string }[] = [];
  public currentAttendeeValue: number = 0;

  // placeholders
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";

  public events: any;
  public experienceInDB: boolean;

  public targetClass: any;
  public title: string = " ";
  public action: string;
  public viewOnly: { date: string };
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: "Schedule",
      reset: "Add Another Class",
      retry: "Retry",
    };
  public continueType: string;
  public preSelected: {
    class_name: string;
    is_public: boolean;
    private_type: string;
    id: any;
    startDate: any;
    endDate: any;
    schedule_id: any;
    photonRegion: number;
    number_of_attendees_prop: number;
    eventType: any;
    is_hub: boolean;
    starting_experience_id: number;
  } = {
      class_name: null,
      is_public: false,
      private_type: "has_invitees",
      id: null,
      startDate: null,
      endDate: null,
      schedule_id: null,
      photonRegion: null,
      number_of_attendees_prop: null,
      eventType: null,
      is_hub: false,
      starting_experience_id: null,
    };
  public confirmOn = false;
  public showEventProperties: boolean = false;
  public showPrivateTypes: boolean = true;
  public labelClass: { class_name: string } = {
    class_name: "",
  };

  public tz: any = moment.tz(moment.tz.guess()).format("z");

  public times: {
    start: any;
    end: any;
  } = {
      start: moment().format("hh:mm A") + " " + this.tz,
      end: null,
    };

  public scheduleForm: UntypedFormGroup;
  public formLoading: boolean = true;
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "ddd, d mmm yyyy",
    useDateObject: true,
  };
  public myEndDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "ddd, d mmm yyyy",
    useDateObject: true,
  };
  public selectRegions: any[];
  public defaultRegion: any = null;

  public experiencesSelectBase: { value: number; label: string }[];
  public experiencesSelectCurrent: { value: number; label: string }[];
  public experiencesSelect: any[] = [];

  public hubExperiencesSelectBase: { value: number; label: string }[];
  public hubExperiencesSelectCurrent: { value: number; label: string }[];
  public hubExperiencesSelect: any[] = [];

  public zones: any;
  public zonesSelectBase: { value: number; label: string }[];
  public subZonesSelectBase: { value: number; label: string }[];
  public zonesSelect: any[] = [];
  public starting_experience_index = 0;

  public zonesWithZonesTracker: [
    {
      zones: any[];
      state: string;
    }
  ];

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
      errorMsg: "",
      statusMsg: "",
      processingMsg: "",
      actionMsg: "",
    };
  public formState: string = "active";
  private event: any;

  public hubsWithinZoneFrame: MDBModalRef;
  public selectMediaFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    public scheduleFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private amDateFormat: DateFormatPipe,
    private elementRef: ElementRef,
    private modalService: MDBModalService,
    private _settingsService: SettingsService,
    private TitleCase: TitleCasePipe,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.viewOnly = {
      date: this.action === "delete" ? "disabled" : null,
    };

    if (this.action === undefined) this.action = "add";
    if (this.experienceInDB === undefined) this.experienceInDB = false;
    if (this.inviteeVersion === undefined) this.inviteeVersion = 1;

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.clientSettingsLegacy = this._clientManagementService.getClientSettings(
      this.teamID
    );
    this.showEventProperties =
      this.clientSettings.showEventProperties.unconstrained_default;

    this.retrieveLabels();
    this.title =
      this.labels.schedule.singular + " " + this.labels.event.singular;
    this.msgs.actionMsg =
      this.labels.schedule.singular +
      " an " +
      this.labels.event.singular +
      (!this.multiExperiences
        ? " for the following " + this.labels.experience.singular + ": "
        : "");
    this.btnLabel.main = this.labels.schedule.singular;
    this.dynamicCopy();
    this.retrieveToken();

    //a bit of a saftey
    //@todo: add validation on the backend so this doesn't ever happen
    if (
      this.targetClass !== undefined &&
      this.targetClass.starting_experience !== undefined &&
      this.targetClass.starting_experience === null
    )
      this.targetClass.starting_experience = 0;

    if (this.action === "delete") {
      this.formLoading = false;
    } else {
      this.retrieveData();
    }
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    if (this.action === "update") {
      let retrieveEvent = await this.retrieveEvent(getOptions).catch(
        (error) => {
          let customMessage = `There was an error while this ${this.labels.event.singular}. Please try again or contact support`;
          this.errorActions(error, customMessage);
        }
      );

      this.event = retrieveEvent.scheduled_event;

      let retrieveZones = await this.retrieveZones(getOptions).catch(
        (error) => {
          let customMessage = `There was an error while retrieving the ${this.labels.zone.plural}. Please try again or contact support`;
          this.errorActions(error, customMessage);
        }
      );

      this.zones = retrieveZones.zones;

      if (this.showEventProperties) {
      }
    }

    if (this.showEventProperties && this.multiExperiences) {
      let eventProps = await this.retrieveTeamProps(getOptions).catch(
        (error) => {
          //do error stuff
        }
      );
      this.teamProps = eventProps.props;

      let eventPropOptions = await this.retrieveTeamPropOptions(
        getOptions
      ).catch((error) => {
        //do error stuff
      });

      this.teamPropOptions = eventPropOptions.options;
      this.evaluateProps();
    }

    this.makeFormValidatingGroup();
  }

  private retrieveEvent(getOptions) {
    let updateEvent = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/" + this.targetClass.id,
      "get",
      {},
      getOptions
    );

    return updateEvent.toPromise();
  }

  private retrieveZones(getOptions) {
    let retrieveZones = this._xrPlatformRestService.restfulAPIQuery(
      "/zones/event/" + this.targetClass.id,
      "get",
      {},
      getOptions
    );

    return retrieveZones.toPromise();
  }

  private retrieveEventProps(getOptions) {
    const data = {};

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/schedule/" + this.targetClass.id + "/props",
      "get",
      data,
      getOptions
    );

    eventProps.subscribe((response) => {
      if (response.error != 418) {
        // this.props = this.formatEventProps(response.props);
        // this.propsLoading = false;
      }
    });
  }

  private retrieveTeamProps(options) {
    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/schedule/props",
      "get",
      {},
      options
    );

    return eventProps.toPromise();
  }

  private retrieveTeamPropOptions(options) {
    let propOptions = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/props/options",
      "get",
      {},
      options
    );

    return propOptions.toPromise();
  }

  private processRegions(incomingTeam) {
    let regionsOut = [];

    if (incomingTeam.meta === null || incomingTeam.meta.regions === undefined)
      return [];

    let regions = incomingTeam.meta.regions;

    regions.forEach((element) => {
      if (
        element.name.indexOf("USA") > -1 &&
        element.name.indexOf("East") > -1
      ) {
        this.defaultRegion = element;
      }

      let thisRegionSelect = {
        value: element.id,
        label: element.name,
      };

      regionsOut.push(thisRegionSelect);
    });

    this.selectRegions = regionsOut;
  }

  private evaluateProps(max?, newExperience?) {
    this.attendeeSelect = [];
    let numberOfattendeesDefaultID = null;
    let updateValue =
      newExperience !== undefined
        ? this.getExperienceAttendeeDefault(newExperience)
        : null;

    this.teamProps.forEach((prop) => {
      if (prop.props.name === "number_of_attendees") {
        this.attendeePropID = prop.prop_id;

        this.teamPropOptions.forEach((option) => {
          if (option.prop_id === prop.prop_id) {
            //check for preexisting attendee prop
            if (
              this.event !== undefined &&
              this.event.properties.number_of_attendees !== undefined &&
              parseInt(this.event.properties.number_of_attendees) ===
              parseInt(option.value)
            ) {
              this.preSelected.number_of_attendees_prop = option.id;
              this.currentAttendeeValue = option.value;
            }

            if (
              updateValue !== null &&
              parseInt(option.value) === parseInt(updateValue)
            ) {
              numberOfattendeesDefaultID = option.id;
            }

            let thisAttendee = {
              value: option.id.toString(),
              label: option.label,
            };

            if (max !== undefined && parseInt(option.value) > max) {
              return;
            }

            this.attendeeSelect.push(thisAttendee);
          }
        });

        if (updateValue !== null) {
          this.scheduleForm.controls["number_of_attendees_prop"].setValue(
            numberOfattendeesDefaultID
          );
          this.currentAttendeeValue = updateValue;
        }
      }
    });
  }

  private buildPreselected() {
    if (this.targetClass !== undefined) {
      this.times.start =
        moment(this.targetClass.startDate).format("hh:mm A") + " " + this.tz;
      this.times.end =
        moment(this.targetClass.endDate).format("hh:mm A") + " " + this.tz;

      this.preSelected.class_name =
        this.targetClass.name === undefined ? null : this.targetClass.name;
      this.preSelected.is_public =
        this.targetClass.is_public === undefined
          ? false
          : this.targetClass.is_public;

      if (
        this.targetClass.is_access_code_required !== undefined &&
        this.targetClass.is_access_code_required
      ) {
        this.preSelected.private_type = "is_access_code_required";
      } else {
        this.preSelected.private_type = "has_invitees";
      }

      if (this.targetClass.is_public) this.showPrivateTypes = false;

      this.preSelected.id =
        this.targetClass.id === undefined ? null : this.targetClass.id;
      this.preSelected.startDate =
        this.targetClass.startDate === undefined
          ? new Date()
          : new Date(this.targetClass.startDate);
      this.preSelected.endDate =
        this.targetClass.endDate === undefined
          ? null
          : new Date(this.targetClass.endDate);
      this.preSelected.schedule_id =
        this.targetClass.schedule_id === undefined
          ? null
          : this.targetClass.schedule_id;
      if (this.targetClass.scheduled_photon_regions_count > 0) {
        this.preSelected.photonRegion =
          this.targetClass.scheduled_photon_regions[0].id;
      } else if (this.defaultRegion) {
        this.preSelected.photonRegion = this.defaultRegion.id;
      }

      if (this.multiExperiences) {
        if (this.targetClass.starting_experience !== null)
          this.preSelected.starting_experience_id =
            this.targetClass.starting_experience.id;

        //setup attendees based on starting experience max
        if (this.attendeePropID > 0) {
          let max = this.targetClass.starting_experience.max_attendees;

          this.evaluateProps(max);
        }
      }
    } else {
      this.preSelected.startDate = new Date();
      if (this.defaultRegion) {
        this.preSelected.photonRegion = this.defaultRegion.id;
      }
    }
  }

  public dynamicCopy() {
    this.continueType = "reset";

    this.btnLabel.reset =
      "Add Another " + this.TitleCase.transform(this.labels.event.singular);

    if (this.action) {
      switch (this.action) {
        case "update":
          this.msgs.actionMsg =
            "Update " +
            this.labels.event.singular +
            (!this.multiExperiences
              ? " for the following " + this.labels.experience.singular + ": "
              : "");
          this.btnLabel.main = "Update";
          this.continueType = "continue";
          this.title = "Update " + this.labels.event.singular;
          break;
        case "delete":
          this.msgs.actionMsg = "";
          this.msgs.actionMsg =
            "Are you sure you want to remove this " +
            this.labels.event.singular +
            "?";
          this.btnLabel.main = "Remove";
          this.continueType = "none";
          this.title = "";
          this.title = "remove " + this.labels.event.singular;
          break;
      }
    }
  }

  private makeFormValidatingGroup() {
    this.buildPreselected();

    let validators = [
      TimeMustBeLater("startTime", "endTime", "startDate", "endDate"),
      DateMustBeLater("startDate", "endDate"),
    ];

    this.scheduleForm = new UntypedFormGroup({
      startDate: new UntypedFormControl(
        this.preSelected.startDate,
        Validators.required
      ),
      startTime: new UntypedFormControl(this.times.start, Validators.required),
      endDate: new UntypedFormControl(this.preSelected.endDate, Validators.required),
      endTime: new UntypedFormControl(this.times.end, Validators.required),
      class_name: new UntypedFormControl(
        this.preSelected.class_name,
        Validators.required
      ),
      is_public: new UntypedFormControl({
        value: this.preSelected.is_public,
        disabled: this.targetClass === undefined ? false : true,
      }),
      is_hub: new UntypedFormControl({
        value: false,
        disabled: this.targetClass === undefined ? false : true,
      }),
      private_type: new UntypedFormControl({
        value: this.preSelected.private_type,
        disabled: this.targetClass === undefined ? false : true,
      }),
      scheduledRegion: new UntypedFormControl(this.preSelected.photonRegion),
      eventType: new UntypedFormControl(this.preSelected.eventType),
    });

    if (!this.multiExperiences) {
      this.scheduleForm.addControl(
        "scheduleID",
        new UntypedFormControl(this.experience.id, Validators.required)
      );
    } else {
      this.scheduleForm.addControl("experiences", new UntypedFormArray([]));
      this.scheduleForm.addControl("zones", new UntypedFormArray([]));
      //add control for hubs experience selection
      this.scheduleForm.addControl(
        "hubs_experience",
        new UntypedFormControl({
          value: this.preSelected.starting_experience_id,
          disabled: this.targetClass === undefined ? false : true,
        })
      );

      //provide a starter for experience selects
      this.experiencesSelectCurrent = JSON.parse(
        JSON.stringify(this.experiencesSelectBase)
      );
      this.hubExperiencesSelectCurrent = JSON.parse(
        JSON.stringify(this.hubExperiencesSelectBase)
      );

      //configure hub select
      this.hubExperiencesSelect = JSON.parse(
        JSON.stringify(this.hubExperiencesSelectCurrent)
      );

      let is_hub = false;

      if (this.action === "add") {
        this.addExperienceFields();
      } else {
        is_hub = this.setupZones(is_hub);

        this.setupExperiences();
      }

      //is_hub is only established for existing events
      //if is_hub is true, we don't need to setup attendees as the form will directly load the hubs interface
      if (!is_hub) this.setupAttendees(validators);
    }

    //add validators to scheduleForm
    this.scheduleForm.setValidators(validators);

    if (this.multiExperiences) this.onFormChanges();

    this.formLoading = false;
  }

  private setupExperiences() {
    console.log("this.targetClass", this.targetClass);

    if (this.event.experiences.length) {
      //for updating, clean up the experience selects for existing experiences
      this.event.experiences.forEach((exp_rep, index) => {
        let updatedSelects = this.removeExperienceFromSelects(
          exp_rep.id,
          index,
          this.experiencesSelectCurrent,
          this.experiencesSelect
        );

        this.experiencesSelectCurrent = updatedSelects.currentSelects;
        this.experiencesSelect = updatedSelects.selects;
      });

      this.event.experiences.forEach((exp_rep, index) => {
        console.log("exp_rep", exp_rep);

        if (this.targetClass.starting_experience.id === exp_rep.id) {
          //if starting experience has experience_type.name === 'hub', set hubs to true
          this.starting_experience_index = index;
        }

        this.addExperienceFields(index, exp_rep.id);
        let currentExperience = {
          value: exp_rep.id,
          label: `${exp_rep.label} (Capacity: ${exp_rep.max_attendees})`,
        };

        console.log("currentExperience", currentExperience);

        this.experiencesSelect = this.experiencesSelectSetupEach(
          index,
          exp_rep.id,
          currentExperience,
          this.experiencesSelectCurrent,
          this.experiencesSelect
        );
      });
    } else {
      this.addExperienceFields();
    }
  }

  private setupZones(is_hub) {
    //check for hubs using the starting experience
    if (
      this.targetClass.starting_experience.experience_type === undefined ||
      this.targetClass.starting_experience.experience_type.name !== "hub"
    )
      return is_hub;

    is_hub = true;
    //update form control is_hub to true
    this.scheduleForm.controls["is_hub"].setValue(true);
    let number_of_hubs = this.getNumberOfHubs(
      this.targetClass.starting_experience
    );

    //loop through zones and add a form control for each using addZones()
    //experience_id = zone.experience_event_rel.expeirence_id
    //label = zone.label
    this.zones.forEach((zone, index) => {
      //saftey in case something strange happened
      //index + 1 should never be greater than number_of_hubs
      if (index + 1 > number_of_hubs) return;

      let currentExperience = this.getExperienceByID(
        zone.experience_event_rel.experience_id
      );

      this.addZones(
        index,
        currentExperience.length ? currentExperience[0].experience : undefined,
        zone
      );
    });

    return is_hub;
  }

  private setupAttendees(validators) {
    if (
      !this.experienceFieldsGroups.length ||
      !this.experienceFieldsGroups[0].controls.experience_id.value
    ) {
      this.attendeeSelectDisabled = true;
    } else {
      this.attendeeSelectDisabled = false;
    }

    if (this.attendeePropID > 0) {
      this.scheduleForm.addControl(
        "number_of_attendees_prop",
        new UntypedFormControl({
          value: this.preSelected.number_of_attendees_prop,
          disabled: this.attendeeSelectDisabled,
        })
      );

      validators.push(
        EventCapacity(
          "experiences",
          "number_of_attendees_prop",
          this.experiences,
          this.attendeePropID,
          this.teamPropOptions
        )
      );

      this.scheduleForm.controls[
        "number_of_attendees_prop"
      ].valueChanges.subscribe((value: any) => {
        this.currentAttendeeValue = value;
      });
    }
  }

  public updateButtonStatus(scheduleForm) {
    let capacityErrorOnly = false;

    if (
      this.action !== "delete" &&
      this.multiExperiences &&
      !this.checkFormErrors() &&
      scheduleForm.controls.experiences !== undefined &&
      scheduleForm.controls.experiences.errors !== null &&
      scheduleForm.controls.experiences.errors.eventCapacity !== undefined
    ) {
      capacityErrorOnly = true;
    }

    if (
      this.action !== "delete" &&
      ((!scheduleForm.valid && !capacityErrorOnly) ||
        this.formState === "processing")
    ) {
      return true;
    }

    return false;
  }

  //loop through form controls and look for errors
  private checkFormErrors() {
    let checkForErrors = false;

    Object.keys(this.scheduleForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.scheduleForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          if (keyError === "eventCapacity") return;
          checkForErrors = true;
        });
      }
    });

    return checkForErrors;
  }

  // convenience getters for easy access to form fields
  get scheduleClassFormControls() {
    return this.scheduleForm.controls;
  }
  get experiencesFormArray() {
    return this.scheduleClassFormControls.experiences as UntypedFormArray;
  }

  get experienceFieldsGroups() {
    return this.experiencesFormArray.controls as UntypedFormGroup[];
  }

  public addExperienceFields(index?, experience_id?) {
    if (index === undefined) index = 0;

    let starting_experience =
      this.targetClass !== undefined &&
        this.targetClass.starting_experience !== undefined &&
        experience_id === this.targetClass.starting_experience.id
        ? this.starting_experience_index
        : 0;

    this.experiencesFormArray.push(
      new UntypedFormGroup({
        experience_id: new UntypedFormControl(
          experience_id === undefined ? "" : experience_id,
          Validators.required
        ),
        starting_experience: new UntypedFormControl(starting_experience),
      })
    );

    this.experiencesSelect[index] = JSON.parse(
      JSON.stringify(this.experiencesSelectCurrent)
    );

    let start_with =
      experience_id !== undefined
        ? {
          experience_id: experience_id,
          starting_experience_id: starting_experience,
        }
        : null;

    this.experiencesFormArray.controls[index].valueChanges
      .pipe(startWith(start_with), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        //this is a starting experience change
        if (prev !== null && prev.experience_id === next.experience_id) return;

        //reset form protection
        if (next.experience_id !== undefined && next.experience_id === null)
          return;

        let updatedSelects = this.removeExperienceFromSelects(
          next.experience_id,
          index,
          this.experiencesSelectCurrent,
          this.experiencesSelect
        );

        this.experiencesSelectCurrent = updatedSelects.currentSelects;
        this.experiencesSelect = updatedSelects.selects;

        if (prev !== null) {
          let updatedSelects = this.restoreExperienceSelect(
            prev.experience_id,
            "experience_id",
            this.experiencesSelectCurrent,
            this.experiencesSelect,
            this.experiencesSelectBase,
            this.experiencesFormArray
          );

          this.experiencesSelectCurrent = updatedSelects.currentSelects;
          this.experiencesSelect = updatedSelects.selects;
        }

        console.log("this.attendeePropID", this.attendeePropID);
        console.log(
          "this.starting_experience_index",
          this.starting_experience_index
        );
        console.log("index", index);

        //update number of attendees if this is the starting experience
        if (
          this.attendeePropID > 0 &&
          this.starting_experience_index === index
        ) {
          let newExperience = this.getExperienceByID(next.experience_id);
          let max = newExperience[0].experience.max_attendees;

          this.evaluateProps(max, newExperience[0].experience);
          this.scheduleForm.controls["number_of_attendees_prop"].enable();
        }
      });
  }

  public removeExperienceField(index) {
    //if this experience is our current starting experience, we're going to make the previous experience in the list the starting experience
    if (
      this.starting_experience_index === index &&
      this.starting_experience_index > 0
    ) {
      this.starting_experience_index = index - 1;

      if (this.experiencesFormArray.controls[index - 1] !== undefined) {
        this.experiencesFormArray.controls[index - 1]
          .get("starting_experience")
          .setValue(1);
        let newExperience = this.getExperienceByID(
          this.experiencesFormArray.controls[index - 1].value.experience_id
        );
        let max = newExperience[0].experience.max_attendees;

        this.evaluateProps(max, newExperience[0].experience);
      }
    }

    let currentValue = this.experiencesFormArray.controls[index].value;
    this.experiencesSelect.splice(index, 1);
    this.experiencesFormArray.removeAt(index);

    if (currentValue.experience_id !== "") {
      let updatedSelects = this.restoreExperienceSelect(
        currentValue.experience_id,
        "experience_id",
        this.experiencesSelectCurrent,
        this.experiencesSelect,
        this.experiencesSelectBase,
        this.experiencesFormArray
      );

      this.experiencesSelectCurrent = updatedSelects.currentSelects;
      this.experiencesSelect = updatedSelects.selects;
    }
  }

  public restoreExperienceSelect(
    currentValue,
    targetKey,
    currentSelects,
    selects,
    baseSelects,
    currentFormArray
  ) {
    let restoredValue = this.experiencesSelectGetValue(
      currentValue,
      baseSelects
    );

    currentSelects.unshift(restoredValue[0]);

    currentFormArray.controls.forEach((control, control_index) => {
      let selectedArray = JSON.parse(JSON.stringify(currentSelects));

      if (control.value[targetKey] === "") {
        selects[control_index] = selectedArray;
        return;
      }

      let currentExperience = this.experiencesSelectGetValue(
        control.value[targetKey],
        baseSelects
      );

      selectedArray.unshift(currentExperience[0]);
      selects[control_index] = selectedArray;
    });

    return {
      currentSelects: currentSelects,
      selects: selects,
    };
  }

  private removeExperienceFromSelects(
    experience_id,
    index,
    currentSelects,
    selects
  ) {
    let currentExperience = {
      value: null,
      label: null,
    };
    currentSelects.forEach((option, option_index) => {
      if (option.value === experience_id) {
        currentExperience = option;
      }
    });

    currentSelects = currentSelects.filter((option) => {
      return option.value !== experience_id;
    });

    selects = this.experiencesSelectSetupEach(
      index,
      experience_id,
      currentExperience,
      currentSelects,
      selects
    );

    return {
      currentSelects: currentSelects,
      selects: selects,
    };
  }

  private experiencesSelectSetupEach(
    index,
    experience_id,
    currentExperience,
    currentSelects,
    selects
  ): any {
    selects.forEach((select, select_index) => {
      if (select_index !== index) {
        select.forEach((option, option_index) => {
          if (option.value === experience_id) {
            selects[select_index].splice(option_index, 1);
          }
        });
      }

      let selectedArray = JSON.parse(JSON.stringify(currentSelects));
      selectedArray.unshift(currentExperience);

      selects[index] = selectedArray;
    });

    return selects;
  }

  private experiencesSelectGetValue(currentValue, baseSelects) {
    return baseSelects.filter((option) => {
      return option.value === currentValue;
    });
  }

  private getExperienceByID(experience_id) {
    return this.experiences.filter((experience) => {
      return experience.experience_id === experience_id;
    });
  }

  private getZoneById(zone_id) {
    return this.zones.filter((zone) => {
      return zone.id === zone_id;
    });
  }

  //start zones
  // convenience getters for easy access to form fields
  get zonesFormArray() {
    return this.scheduleClassFormControls.zones as UntypedFormArray;
  }

  get zonesFieldsGroups() {
    return this.zonesFormArray.controls as UntypedFormGroup[];
  }

  //get a zone_extra form array by index
  getZoneExtras(index) {
    return this.zonesFieldsGroups[index].controls.zone_extras as UntypedFormArray;
  }

  //get zone extras fields groups by index
  getZoneExtrasFieldsGroups(index) {
    return this.getZoneExtras(index).controls as UntypedFormGroup[];
  }

  public addZones(index?, experience?, zone?) {
    if (index === undefined) index = 0;

    this.zonesFormArray.push(
      new UntypedFormGroup({
        zone_experience_id: new UntypedFormControl(
          experience === undefined ? "" : experience.id
        ),
        zone_label: new UntypedFormControl(zone === undefined ? "" : zone.label),
        has_extras: new UntypedFormControl(
          this.determineExtras(experience === undefined ? null : experience)
        ),
        zone_object: new UntypedFormControl(zone === undefined ? null : zone),
        zone_extras: new UntypedFormArray([]),
        has_hub: new UntypedFormControl(
          experience === undefined
            ? false
            : experience.experience_type.name === "hub"
              ? true
              : false
        ),
      })
    );

    //add zone extras
    this.addZoneExtras(experience, zone, index, true);

    let thisSubZone = {
      state: "put",
      zones:
        zone === undefined ? [] : this.setupInitialHubWithAzone(zone.zones),
    };

    if (this.zonesWithZonesTracker === undefined) {
      this.zonesWithZonesTracker = [thisSubZone];
    } else {
      this.zonesWithZonesTracker.push(thisSubZone);
    }

    this.zonesSelect[index] = JSON.parse(JSON.stringify(this.zonesSelectBase));

    //listen for changes to zone experience_id and update zone selects
    this.zonesFormArray.controls[index].valueChanges
      .pipe(
        startWith({
          zone_experience_id: experience === undefined ? "" : experience.id,
        }),
        pairwise()
      )
      .subscribe(([prev, next]: [any, any]) => {
        //this is a starting experience change
        if (
          prev !== null &&
          prev.zone_experience_id === next.zone_experience_id
        )
          return;

        //reset form protection
        if (
          next.zone_experience_id !== undefined &&
          next.zone_experience_id === null
        )
          return;

        //lastly, check to see if we just activated or deactivated extras
        let thisExperience = this.getExperienceByID(next.zone_experience_id);
        let legacyExperience = this.getExperienceByID(prev.zone_experience_id);

        let has_hub =
          thisExperience[0].experience.experience_type.name === "hub"
            ? true
            : false;
        let has_extras = this.determineExtras(thisExperience[0].experience);

        this.zonesFieldsGroups[index].controls["has_hub"].setValue(has_hub);
        this.zonesFieldsGroups[index].controls["has_extras"].setValue(
          has_extras
        );

        if (has_extras) {
          this.collapses.forEach(
            (collapse: CollapseComponent, collapse_index) => {
              if (collapse_index === index) {
                collapse.show();
              }
            }
          );

          //reset extras
          for (let i = this.getZoneExtras(index).length - 1; i >= 0; i--) {
            this.getZoneExtras(index).removeAt(i);
          }

          this.addZoneExtras(
            thisExperience[0].experience,
            this.zonesFieldsGroups[index].controls["zone_object"].value,
            index,
            true
          );
        } else {
          this.collapses.forEach(
            (collapse: CollapseComponent, collapse_index) => {
              if (collapse_index === index) {
                collapse.hide();
              }
            }
          );

          //reset extras
          for (let i = this.getZoneExtras(index).length - 1; i >= 0; i--) {
            this.getZoneExtras(index).removeAt(i);
          }
        }

        //if this was a hub, we need to let the backend know to remove the hub sub zones by setting the zonesWithinZonesTracker state to delete
        if (
          !has_hub &&
          legacyExperience.length &&
          legacyExperience[0].experience.experience_type.name === "hub"
        ) {
          this.zonesWithZonesTracker[index].state = "delete";
        }
      });
  }

  public removeZone(index) {
    this.zonesFormArray.removeAt(index);
    this.zonesSelect.splice(index, 1);
    this.zonesWithZonesTracker.splice(index, 1);
  }

  private setupInitialHubWithAzone(zones) {
    let outBoundZones = [];

    if (zones === null) return outBoundZones;

    zones.forEach((zone, zone_index) => {
      let currentExperience = this.getExperienceByID(
        zone.experience_event_rel.experience_id
      );

      let thisZone = {
        label: zone.label,
        experience_id: zone.experience_event_rel.experience_id,
        has_extras: this.determineExtras(
          currentExperience.length ? currentExperience[0].experience : null
        ),
        zone_object: new UntypedFormControl(zone === undefined ? null : zone),
        zone_extras: this.addZoneExtras(
          currentExperience.length ? currentExperience[0].experience : null,
          zone,
          zone_index,
          false
        ),
        experience_event_rel: zone.experience_event_rel,
      };

      outBoundZones.push(thisZone);
    });

    return outBoundZones;
  }

  //start zone extras
  /**
   * @description this function creates a formarray of zone extras
   *
   * With the incoming experience object, we can iterate through the experience_attributes and determine if any are editable or not
   *
   * Editable experiences are then turned into a formgroup and added to the zoneExtrasFormArray
   *
   * @param experience
   */
  public addZoneExtras(experience, zone, index, is_form) {
    if (experience === undefined || experience === null) return;

    if (experience.experience_attributes === undefined) return;

    let zoneExtras = [];
    let zoneExtrasFormArray = is_form ? this.getZoneExtras(index) : null;

    experience.experience_attributes.forEach((attribute) => {
      let valueHandler = this.determineExtrasValue(
        zone,
        attribute.experience_attribute.name,
        attribute.experience_attribute.type
      );

      if (attribute.experience_attribute.editable) {
        if (is_form) {
          zoneExtrasFormArray.push(
            new UntypedFormGroup({
              attribute_id: new UntypedFormControl(attribute.experience_attribute.id),
              attribute_value: new UntypedFormControl(valueHandler.value),
              attribute_label: new UntypedFormControl(
                attribute.experience_attribute.label
              ),
              attribute_full_data: new UntypedFormControl(valueHandler.fullData),
              attribute_type: new UntypedFormControl(
                attribute.experience_attribute.type
              ),
              attribute_subtype: new UntypedFormControl(
                attribute.experience_attribute.subtype
              ),
            })
          );
        } else {
          zoneExtras.push({
            attribute_id: attribute.experience_attribute.id,
            attribute_value: valueHandler.value,
            attribute_label: attribute.experience_attribute.label,
            attribute_full_data: valueHandler.fullData,
            attribute_type: attribute.experience_attribute.type,
            attribute_subtype: attribute.experience_attribute.subtype,
          });
        }
      }
    });

    return is_form ? zoneExtrasFormArray : zoneExtras;
  }

  private determineExtras(incomingExperience) {
    if (incomingExperience === null) return false;

    //if experience_attributes are undefined or null, return false
    if (
      incomingExperience.experience_attributes === undefined ||
      incomingExperience.experience_attributes === null
    )
      return false;

    //if this a hub, return true
    if (
      incomingExperience.experience_type !== undefined &&
      incomingExperience.experience_type.name === "hub"
    )
      return true;

    let extras = false;
    //loop through experience_attributes, and look at the property of experience_attribute.editable
    //if any attribute has experience_attribute.editable as true, return true
    incomingExperience.experience_attributes.forEach((attribute) => {
      if (attribute.experience_attribute.editable) extras = true;
    });

    return extras;
  }

  /**
   * @description determine zone extra value from the incoming zone object; the target value is zone.experience_event_rel.experience_attributes[attribute_key]
   *
   * @param zone: zone object
   * @param attribute_key: string
   */
  private determineExtrasValue(zone, attribute_key, attribute_type) {
    let outboundValue = {
      value: null,
      fullData: null,
    };

    if (zone === null) return outboundValue;

    if (zone.experience_event_rel.experience_attributes === null)
      return outboundValue;

    //make sure experience_attribute with attribute key exists
    if (
      zone.experience_event_rel.experience_attributes[attribute_key] ===
      undefined
    )
      return outboundValue;

    switch (attribute_type) {
      case "asset_single":
        if (
          zone.experience_event_rel.experience_attributes[attribute_key] ===
          null
        )
          break;
        outboundValue.value =
          zone.experience_event_rel.experience_attributes[attribute_key].id;
        outboundValue.fullData =
          zone.experience_event_rel.experience_attributes[attribute_key];
        break;
    }

    return outboundValue;
  }

  public checkThumbnail(fullData) {
    let thumbnail = this.placeHolderImg;

    if (fullData === null) return thumbnail;

    //if fullData does not have a thumbnail_asset_url property or that property is null, return the placeholder image
    if (
      fullData.thumbnail_asset_url === undefined ||
      fullData.thumbnail_asset_url === null
    )
      return thumbnail;

    //if fullData has a thumbnail_asset_url property, return that
    return fullData.thumbnail_asset_url;
  }

  public openZoneHubModal(index) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "schedule-class-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
      ignoreBackdropClick: true,
    };

    this.modalOptions.data = {
      zoneNumber: index + 1,
      zoneLabel: this.zonesFieldsGroups[index].controls.zone_label.value,
      zonesSelectBase: this.subZonesSelectBase,
      zones: this.zonesWithZonesTracker[index].zones,
      currentExperienceID:
        this.zonesFieldsGroups[index].controls.zone_experience_id.value,
      experiences: this.experiences,
      labels: this.labels,
      teamID: this.teamID,
      action: this.action,
    };

    this.hubsWithinZoneFrame = this.modalService.show(
      HubsWithinAZoneComponent,
      this.modalOptions
    );

    this.hubsWithinZoneFrame.content.outgoing.subscribe((result: any) => {
      if (result.zones !== undefined && result.zones.length > 0) {
        //get the array of zones for this index
        let zones = this.zonesWithZonesTracker[index].zones;

        if (zones === null) zones = [];

        if (zones.length > 0) {
          //loop through the zones and match the index to results.zones, then update the label and experience_id properties
          zones.forEach((zone, zone_index) => {
            result.zones.forEach((result_zone, result_index) => {
              if (zone_index === result_index) {
                zone.label = result_zone.zone_label;
                zone.experience_id = result_zone.zone_experience_id;
                zone.has_extras = result_zone.has_extras;
                zone.zone_extras = result_zone.zone_extras;
              }
            });
          });
        } else {
          //we must be adding new sub zones
          this.zonesWithZonesTracker[index].state = "add";

          //map result.zones to zonesWithZonesTracker[index].zones
          //experience_id = zone_experience_id
          //label = zone_label
          this.zonesWithZonesTracker[index].zones = result.zones.map(
            (result_zone) => {
              return {
                label: result_zone.zone_label,
                experience_id: result_zone.zone_experience_id,
                has_extras: result_zone.has_extras,
                zone_extras: result_zone.zone_extras,
              };
            }
          );
        }
      }
    });
  }

  public openSelectMediaModal(extras_index, zone_index, subtype, asset) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "select-asset-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
      ignoreBackdropClick: true,
    };

    this.modalOptions.data = {
      teamID: this.teamID,
      type: subtype,
      targetAsset: asset,
      fromLocation: "zoneMedia",
    };

    this.selectMediaFrame = this.modalService.show(
      EventSettingsManageMediaComponent,
      this.modalOptions
    );

    this.selectMediaFrame.content.outgoing.subscribe((result: any) => {
      if (result.media !== undefined && result.media.length) {
        let media = result.media[0];

        let zoneExtrasFieldsGroups = this.getZoneExtrasFieldsGroups(zone_index);
        let zoneExtra = zoneExtrasFieldsGroups[extras_index];

        zoneExtra.controls["attribute_value"].setValue(media.id);
        zoneExtra.controls["attribute_full_data"].setValue(media);
      } else if (result.media !== undefined && !result.media.length) {
        let zoneExtrasFieldsGroups = this.getZoneExtrasFieldsGroups(zone_index);
        let zoneExtra = zoneExtrasFieldsGroups[extras_index];

        //reset the media
        zoneExtra.controls["attribute_value"].setValue(null);
        zoneExtra.controls["attribute_full_data"].setValue(null);
      }
    });
  }

  private compareArray(arr_one, arr_two) {
    let is_same =
      arr_one.length == arr_one.length &&
      arr_one.every(function (element, index) {
        return element === arr_two[index];
      });
    return is_same;
  }

  get is_hub() {
    return this.scheduleForm !== undefined
      ? this.scheduleForm.get("is_hub")
      : {
        value: false,
      };
  }

  get is_public() {
    return this.scheduleForm !== undefined
      ? this.scheduleForm.get("is_public")
      : null;
  }

  get startDate() {
    return this.scheduleForm !== undefined
      ? this.scheduleForm.get("startDate")
      : null;
  }

  get endDate() {
    return this.scheduleForm !== undefined
      ? this.scheduleForm.get("endDate")
      : null;
  }

  get startTime() {
    return this.scheduleForm !== undefined
      ? this.scheduleForm.get("startTime")
      : null;
  }

  get endTime() {
    return this.scheduleForm !== undefined
      ? this.scheduleForm.get("endTime")
      : null;
  }

  get class_name() {
    return this.scheduleForm !== undefined
      ? this.scheduleForm.get("class_name")
      : null;
  }

  private processTime(time) {
    if (time.indexOf("AM") !== -1) {
      time = time.replace(/AM/, "");
      time += " AM";
    } else {
      time = time.replace(/PM/, "");
      time += " PM";
    }

    return time;
  }

  private checkAfternoons(incomingTime, momentTime) {
    //setup time as normal
    let outGoingtime = momentTime.format("HH:mm:ss");

    //check to make sure formatted value is really in the afternoon
    if (incomingTime.indexOf("PM") !== -1) {
      var hour = momentTime.hour();

      //uh oh something happened
      if (hour < 12) outGoingtime = momentTime.add(12, "h").format("HH:mm:ss");
    }

    return outGoingtime;
  }

  public onInputFieldChange(event, type) {
    let dateValue = this.scheduleForm.controls.startDate.value;

    if (type === "end") {
      dateValue = this.scheduleForm.controls.endDate.value;
    }

    this.timeTracker[type] = moment(dateValue).format("X");

    if (type === "start") {
      let yesterday = moment(dateValue).subtract(1, "days").format("M-D-YYYY");
      let parseYesterday = yesterday.split("-");
      this.dateLabels.end = "Select End Date";
      this.myEndDatePickerOptions = {
        ...this.myEndDatePickerOptions,
        disableUntil: {
          year: parseInt(parseYesterday[2]),
          month: parseInt(parseYesterday[0]),
          day: parseInt(parseYesterday[1]),
        },
      };
    }
  }

  public onTimeChange(event, targetTime) {
    //check if event is a string and if event is empty
    if (typeof event === "string" && event === "") {
      return true;
    }

    let newTime = this.processTime(event);
    this.scheduleForm.controls[targetTime].setValue(newTime + " " + this.tz);
  }

  public maybeAdjustLabel(event) {
    if (this.scheduleForm.controls["class_name"].value !== null) {
      this.labelClass.class_name = "active-label active";
    } else {
      this.labelClass.class_name = "";
    }
  }

  public getExperienceAttendeeDefault(experience) {
    let attendeeDefault = experience.min_attendees;

    if (experience.experience_attributes.length) {
      experience.experience_attributes.forEach((attribute) => {
        if (
          attribute.experience_attribute.name === "default_number_of_attendees"
        ) {
          attendeeDefault = parseInt(attribute.default_value);
        }
      });
    }

    return attendeeDefault;
  }

  public selectStartingExperience(index) {
    this.starting_experience_index = index;

    let currentExperienceID =
      this.experiencesFormArray.controls[index].value.experience_id;
    let getCurrentExperience = this.experiences.filter((exp) => {
      return exp.experience_id === currentExperienceID;
    });
    let currentExperience = getCurrentExperience[0].experience;

    let max = currentExperience.max_attendees;

    this.evaluateProps(max, currentExperience);
  }

  public confirmOnEvent(incomingValue?) {
    this.confirmOn = incomingValue;
  }

  public turnConfirmOn() {
    this.closeConfirmation.turnConfirmOn();
  }

  private onFormChanges(): void {
    this.scheduleForm.get("is_public").valueChanges.subscribe((value) => {
      if (value) {
        this.showPrivateTypes = false;
      } else {
        this.showPrivateTypes = true;
      }
    });

    this.scheduleForm.get("is_hub").valueChanges.subscribe((value) => {
      if (value) {
        //when switching to hub, we need to remove all experiences except for the first one
        for (let i = this.experienceFieldsGroups.length - 1; i > -1; i--) {
          console.log("removing experience field", i);
          this.removeExperienceField(i);
        }
      } else {
        this.addExperienceFields(0);
      }
    });

    this.scheduleForm.get("hubs_experience").valueChanges.subscribe((value) => {
      let experience = this.getExperienceByID(value)[0].experience;
      let number_of_hubs = this.getNumberOfHubs(experience);

      if (this.zonesFieldsGroups.length < number_of_hubs) {
        for (let i = this.zonesFieldsGroups.length; i < number_of_hubs; i++) {
          this.addZones(i);
        }
      } else if (this.zonesFieldsGroups.length > number_of_hubs) {
        for (
          let i = this.zonesFieldsGroups.length - 1;
          i >= number_of_hubs;
          i--
        ) {
          this.removeZone(i);
        }
      }
    });
  }

  private getNumberOfHubs(experience) {
    let num_of_hubs_attr = experience.experience_attributes.filter(
      (attr) => attr.experience_attribute.name === "max_number_of_zones"
    );

    let number_of_hubs = 0;

    if (num_of_hubs_attr.length) {
      number_of_hubs = parseInt(num_of_hubs_attr[0].default_value);
    }

    return number_of_hubs;
  }

  private configureOutboundExperienceData(incomingValues, formValues) {
    if (!this.multiExperiences) return formValues;

    if (!this.is_hub.value) {
      let starting_experience_id = null;
      let experiences = [];

      incomingValues.experiences.forEach((experience, index) => {
        if (index === this.starting_experience_index)
          starting_experience_id = experience.experience_id;

        let this_experience = {
          id: experience.experience_id,
        };

        experiences.push(this_experience);
      });

      formValues["starting_experience_id"] = starting_experience_id;
      formValues["experiences"] = experiences;
    } else {
      //get hubs experience from experience_id
      let hubs_experience = this.getExperienceByID(
        incomingValues.hubs_experience
      );

      let starting_experience_id = hubs_experience[0].experience_id;
      let experiences = [
        {
          id: hubs_experience[0].experience_id,
        },
      ];

      formValues["starting_experience_id"] = starting_experience_id;
      formValues["experiences"] = experiences;
    }

    return formValues;
  }

  public scheduleClass() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.formState = "processing";

    let incomingValues = {};

    if (this.action !== "delete")
      incomingValues = this.scheduleForm.getRawValue();

    if (
      (this.action === "add" || this.action === "update") &&
      this.checkExistingClassNames(incomingValues)
    ) {
      this.msgs.processingMsg = "";
      this.btnLabel.retry = "Retry";
      this.msgs.errorMsg =
        this.TitleCase.transform(this.labels.event.singular) +
        " name is already taken, please use only one class name per experience.";
    } else {
      this.submitScheduledClass(incomingValues);
    }
  }

  private async submitScheduledClass(incomingValues) {
    let body = {};
    let action = "new-scheduled-event";

    if (this.action !== "delete") {
      let startTime = moment(this.processTime(incomingValues.startTime), [
        "h:mm A",
      ]);

      //Sat, 21 Dec 2019
      let startDateFormatted =
        moment(incomingValues.startDate).format("ddd, D MMM YYYY") +
        " " +
        this.checkAfternoons(incomingValues.startTime, startTime) +
        " " +
        this.tz;
      let endTime = moment(this.processTime(incomingValues.endTime), [
        "h:mm A",
      ]);
      let endDateFormatted =
        moment(incomingValues.endDate).format("ddd, D MMM YYYY") +
        " " +
        this.checkAfternoons(incomingValues.endTime, endTime) +
        " " +
        this.tz;
      let formValues = {};

      if (this.targetClass === undefined) {
        let invitees = [
          {
            invitee_type: "team",
            invitee_id: this.teamID,
          },
        ];

        if (incomingValues.scheduledRegion) {
          let region = {
            invitee_type: "photon_region",
            invitee_id: incomingValues.scheduledRegion,
          };

          invitees.push(region);
        }

        let meta = {};

        if (this.inviteeVersion > 1) {
          meta = {
            invitee_version: 2,
          };
        }

        if (
          this.clientSettingsLegacy.experienceMetaOptions.event_type.status &&
          this.clientSettingsLegacy.experienceMetaOptions.event_type.label ===
          "Type of Room"
        )
          meta["event_type"] = "normal";

        this.msgs.processingMsg = `<span class="processing-msg">Setting up ${this.TitleCase.transform(
          this.labels.event.singular
        )}</span>`;
        formValues = {
          event_name: incomingValues.class_name.trim(),
          is_public: incomingValues.is_public,
          has_invitees:
            incomingValues.private_type === "has_invitees" ? true : false,
          is_access_code_required:
            incomingValues.private_type === "is_access_code_required"
              ? true
              : false,
          start_at: startDateFormatted,
          end_at: endDateFormatted,
          invitees: invitees,
          meta: meta,
        };

        if (!this.multiExperiences) {
          let legacyValues = {
            resource_type: "experience_class",
            resource_id: this.experience.id,
          };

          formValues = { ...formValues, ...legacyValues };
        }

        if (this.attendeePropID > 0) {
          formValues["number_of_attendees_prop"] =
            incomingValues.number_of_attendees_prop;
        }

        //for super admins, make sure the team association is correct
        if (this.user.role_type_id === 1) formValues["team_id"] = this.teamID;
        if (this.experienceInDB && !this.multiExperiences)
          formValues["experience_id"] = this.experience.id;

        this.configureOutboundExperienceData(incomingValues, formValues);

        body = JSON.stringify(formValues);
      } else if (this.action === "update") {
        let meta = {};

        if (this.inviteeVersion > 1) {
          meta = {
            invitee_version: 2,
          };
        }

        this.msgs.processingMsg = `<span class='spinner-grow'>Updating ${this.TitleCase.transform(
          this.labels.event.singular
        )}</span>`;

        formValues = {
          event_name: incomingValues.class_name.trim(),
          start_at: startDateFormatted,
          end_at: endDateFormatted,
          meta: meta,
        };

        if (this.experienceInDB && !this.multiExperiences)
          formValues["experience_id"] = this.experience.id;

        if (!this.multiExperiences) {
          let legacyValues = {
            resource_type: "experience_class",
            resource_id: this.experience.id,
          };

          formValues = { ...formValues, ...legacyValues };
        }

        if (this.attendeePropID > 0) {
          formValues["number_of_attendees_prop"] =
            incomingValues.number_of_attendees_prop;
        }

        this.configureOutboundExperienceData(incomingValues, formValues);

        body = JSON.stringify(formValues);
        action = this.targetClass.schedule_id;
      }
    }

    if (this.action === "delete") {
      this.msgs.processingMsg = `<span class='processing-msg'>Removing ${this.TitleCase.transform(
        this.labels.event.singular
      )}</span>`;
      body = {
        entity: "schedule",
        entity_id: this.targetClass.schedule_id,
      };
      action = "delete";
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let scheduleClass = this._xrPlatformRestService
      .scheduledExperiences(action, body, options)
      .toPromise();

    let response = await scheduleClass.catch((error) => {
      let customMessage = `There was an error managing this ${this.labels.event.singular}. Please try again or contact support`;
      this.errorActions(error, customMessage);
    });

    //manage zones if necessary
    if (this.multiExperiences && this.is_hub.value) {
      let event = response.scheduled_event;
      //get first experience from array of experiences
      let experience = event.experiences[0];
      let number_of_zones = this.zonesFieldsGroups.length;
      let zones = [];
      let requestType =
        this.action === "update" && this.zones.length > 0 ? "put" : "post";
      //loop through zone fields and create of objects
      //each object has two params: label and experience_id
      this.zonesFieldsGroups.forEach((zone, index) => {
        let zone_object = {
          label: zone.value.zone_label,
          experience_id:
            zone.value.zone_experience_id === ""
              ? null
              : zone.value.zone_experience_id,
        };

        //process zone extras
        let extras = this.processZoneExtras(zone);
        if (extras.length) zone_object["attributes"] = extras;

        //if action === update, we need to retrieve the full zone object from the zones array using the index, then add the zone_id
        //we also need to add the location_num, i.e. index + 1 to the zone_object
        if (this.action === "update" && this.zones.length > 0) {
          let zoneToUpdate = this.zones[index];
          zone_object["id"] = zoneToUpdate.id;
          zone_object["location_num"] = zoneToUpdate.location_num;
        }

        //check the state
        let state = this.zonesWithZonesTracker[index].state;

        let sub_zones = this.processSubZones(zone, index, state);

        if (sub_zones.length) {
          zone_object["sub_zones_state"] = state;
          zone_object["zones"] = sub_zones;
        }

        zones.push(zone_object);
      });

      let zoneBody = {
        zones: zones,
        number_of_zones: number_of_zones,
        event_id: event.id,
      };

      if (requestType === "post")
        zoneBody["experience_event_rel_id"] = experience.experience_rel_id;

      console.log(
        "zoneBody in ScheduleClassComponent::submitScheduledClass",
        zoneBody
      );

      if (this.action !== "delete") {
        let addZones = this._xrPlatformRestService
          .restfulAPIQuery("/zones", requestType, zoneBody, options)
          .toPromise();

        let zoneResponse = await addZones.catch((error) => {
          let customMessage = `There was an error managing this ${this.labels.event.singular}. Please try again or contact support`;
          this.errorActions(error, customMessage);
        });
      }
    }

    this.returnActions(
      incomingValues,
      response,
      incomingValues.scheduledRegion
    );
  }

  /**
   * @description: process sub zones for submission
   */
  private processSubZones(zone, index, state) {
    let sub_zones = [];

    //use the index to get the zones array from the zonesWithZonesTracker array
    let zonesArray = this.zonesWithZonesTracker[index].zones;

    //if we're "deleting" the subzones in a new event, we're really just skipping them
    if (state === "delete" && this.targetClass === undefined) return sub_zones;

    if (zonesArray === null || !zonesArray.length) return sub_zones;
    //loop through zones array and add label and experience_id to sub_zones array
    zonesArray.forEach((sub_zone) => {
      console.log(
        "sub_zone in ScheduleClassComponent::submitScheduledClass",
        sub_zone
      );

      let sub_zone_object = {
        label: sub_zone.label,
        experience_id:
          sub_zone.experience_id === "" ? null : sub_zone.experience_id,
      };

      //process zone extras
      let sub_zone_extras = this.processZoneExtras(sub_zone);
      if (sub_zone_extras.length)
        sub_zone_object["attributes"] = sub_zone_extras;

      //if this is an update, add the id and location_num
      if (this.action === "update" && sub_zone.zone_object !== undefined) {
        let thisZoneObject = sub_zone.zone_object.value;

        sub_zone_object["id"] = thisZoneObject.id;
        sub_zone_object["location_num"] = thisZoneObject.location_num;
      }

      sub_zones.push(sub_zone_object);
    });

    return sub_zones;
  }

  /**
   * @description: process zone extras for submission
   * This function checks if the zone_extras control exists, and if zone_extras.controls is not empty
   * Then it loops through the zone_extras.controls array and adds the attribute_id and attribute_value to the zone_extras array
   *
   * @param zone
   * @returns [{id: number, value: string}}]
   */
  private processZoneExtras(zone) {
    let zone_extras = [];

    console.log("zone in processZoneExtras", zone);

    let extras =
      zone.controls !== undefined
        ? zone.controls.zone_extras.controls
        : zone.zone_extras;

    if (extras === undefined || extras.length === 0) return zone_extras;

    extras.forEach((extra) => {
      let thisExtra = extra.value !== undefined ? extra.value : extra;

      let zone_extra = {
        id: thisExtra.attribute_id,
        value: thisExtra.attribute_value,
      };

      zone_extras.push(zone_extra);
    });

    return zone_extras;
  }

  private returnActions(incomingValues, response, region_id?) {
    this.msgs.processingMsg = "";
    if (this.action === "update") {
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.event.singular
      )} ${incomingValues.class_name} successfully updated`;
      this.btnLabel.retry = "Continue Editing";
    } else if (this.action === "delete") {
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.event.singular
      )} ${incomingValues.class_name} successfully removed`;
    } else {
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.event.singular
      )} ${incomingValues.class_name} successfully added`;
    }
    this.formState = "success";

    let outgoingData = {
      action: this.action === undefined ? "new" : this.action,
      scheduled_event: response.scheduled_event,
      region_id: null,
    };

    outgoingData.scheduled_event.building = null;
    if (region_id) {
      outgoingData.region_id = region_id;
    }

    this.outgoing.next(outgoingData);
  }

  private errorActions(err, customMessage?) {
    this.msgs.processingMsg = "";
    this.btnLabel.retry = "Retry";

    if (customMessage !== undefined) {
      this.msgs.errorMsg = customMessage;
    } else {
      let errorMsg = JSON.parse(err._body);
      this.msgs.errorMsg = errorMsg.error;
    }
  }

  private addRegion(incomingValues, headers) {
    let formValues = [
      {
        scheduled_event_id: this.targetClass.id,
        invitee_type: "photon_region",
        invitee_id: incomingValues.scheduledRegion,
      },
    ];

    let body = JSON.stringify(formValues);

    const options = {
      headers: headers,
      schedule_id: this.targetClass.id,
    };

    let action = "schedule-invitee";

    return this._xrPlatformRestService
      .scheduledExperiences(action, body, options)
      .toPromise();
  }

  private checkExistingClassNames(incomingValues) {
    let duplicate = false;

    //for updating, if class name is not changed in form, skip this
    if (this.targetClass !== undefined) {
      if (this.targetClass.name === incomingValues.class_name.trim()) {
        return duplicate;
      }
    }

    this.events.forEach((experience) => {
      if (experience.name === incomingValues.class_name.trim()) {
        duplicate = true;
      }
    });

    return duplicate;
  }

  public resetForm() {
    this.scheduleForm.reset();
    this.formState = "active";
    this.scheduleForm.controls["startTime"].setValue(
      moment().format("hh:mm A") + " " + this.tz
    );
    this.scheduleForm.controls["startDate"].setValue(new Date());
    this.scheduleForm.controls["is_public"].setValue(false);
    this.scheduleForm.controls["private_type"].setValue("has_invitees");
    this.datePickerEnd.clearDate();
    //provide a starter for experience selects
    this.experiencesSelectCurrent = JSON.parse(
      JSON.stringify(this.experiencesSelectBase)
    );
    this.experiencesFormArray.clear();
    this.addExperienceFields();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.formState = "active";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.scheduleFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
