import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  ActivatedRoute,
  Params,
  Router,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import * as moment from "moment";
import { environment } from "../../../../environments/environment";

import { SharedDataService } from "./../../../services/shared-data/shared-data.service";
import { ClientManagementService } from "./../../../services/utilities/client-management.service";

import { CoolLocalStorage } from "@angular-cool/storage";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { MetricsServicesService } from "../../metrics/services/metrics-services.service";

interface loginType {
  username: string;
  password: string;
  code?: string;
  domain?: string;
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild("focusElem", { static: false }) focusElem: ElementRef;

  public loginForm: UntypedFormGroup;
  public errorMsg: string = "";
  public statusMsg: string = "";
  public teamID: number = 0;
  public domain: string = "superadmin";
  public subdomain: string = "";
  public formLoading: boolean = true;
  public token: string = "";
  public login: string = "";

  private routeSnap: ActivatedRouteSnapshot;
  public clientcode: any = "";
  public username: string = "";
  public rememberLogin: boolean = false;

  user = {
    login: "",
    password: "",
    info: {
      email: "",
      first_name: "",
      id: 0,
      last_name: "",
      role_type_id: 0,
      username: "",
      team_id: 0,
    },
  };

  private settings: any = [];
  private labels: any = [];

  constructor(
    private http: HttpClient,
    private coolLocalStorage: CoolLocalStorage,
    private _shareDataService: SharedDataService,
    private _clientManagementService: ClientManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private _settingsService: SettingsService,
    private _metricsService: MetricsServicesService
  ) {
    this.routeSnap = route.snapshot;
  }

  ngOnInit() {
    this.subdomain = location.origin;

    this.route.params.subscribe((params: Params) => {
      this.clientcode =
        params["client_code"] !== undefined ? params["client_code"] : "";
      //check for stored code too
      let storedCode = this.coolLocalStorage.getItem("admin_panel_stored_code");
      storedCode = storedCode ? storedCode.replace(/['"]+/g, "") : null;

      //when retrieving stored details for a client using the code-based login URL, first make sure the client code in the URL matches the stored code
      if (
        (storedCode && storedCode === this.clientcode) ||
        (storedCode && this.clientcode === "")
      ) {
        let storedUsername = this.coolLocalStorage.getItem(
          "admin_panel_stored_username"
        );
        let rememberLogin = this.coolLocalStorage.getObject(
          "admin_panel_stored_rememberLogin"
        );

        if (storedCode !== null && rememberLogin) {
          this.clientcode = storedCode;
          this.rememberLogin = true;
        }

        if (storedUsername !== null && rememberLogin) {
          this.username = storedUsername;
          this.rememberLogin = true;
        }
      }

      this.buildForm();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.focusElem.nativeElement.click();
    }, 1);
  }

  private buildForm() {
    this.loginForm = new UntypedFormGroup({
      clientcode: new UntypedFormControl(this.clientcode, Validators.required),
      rememberLogin: new UntypedFormControl(
        this.rememberLogin,
        Validators.required
      ),
      username: new UntypedFormControl(this.username, Validators.required),
      password: new UntypedFormControl("", Validators.required),
    });

    this.formLoading = false;
  }

  public processLogin() {
    let myTimestamp: number = moment().unix();
    //tare out any error messages
    this.errorMsg = "";
    this.statusMsg = "Logging in...";

    let restURL = this._clientManagementService.getRESTurl();

    let incoming = this.loginForm.value;

    //temporary workaround for Vivo
    //@todo: if Vivo becomes more of a thing, we need to do this in a better way
    if (this.subdomain.indexOf("vivo-panel.glimpsegroup.com") !== -1)
      restURL = this._clientManagementService.getRESTurl("v2");

    const myRestURL = restURL + "/login";

    console.log("myRestURL", myRestURL);

    const options = {
      //withCredentials: true,
      observe: "response" as "response",
    };

    this.clientcode = incoming.clientcode;

    let login: loginType = {
      username: incoming.username,
      password: incoming.password,
      code: incoming.clientcode,
    };

    //for super admins
    if (parseInt(incoming.clientcode) === 1)
      login = {
        username: incoming.username,
        password: incoming.password,
        domain: "superadmin",
      };

    this.http.post(myRestURL, login, options).subscribe(
      (response) => {
        this.statusMsg = "";

        const data = response.body;

        console.log("data in login", data);

        if (data["accessToken"] && data["error"] != 418) {
          this.token = data["accessToken"];
          this.login = this.user.login;
          this.user.info = {
            email: data["user"].email,
            first_name: data["user"].first_name,
            id: data["user"].id,
            last_name: data["user"].last_name,
            role_type_id: data["user"].role_type_id,
            username: data["user"].username,
            team_id: data["user"].team_id,
          };
          this._settingsService.setSettings(
            data["user"].team_id,
            data["settings"]
          );
          this.coolLocalStorage.setItem(
            "the_panel_labels",
            JSON.stringify(data["labels"])
          );
          this._settingsService.setContent(
            data["contents"],
            data["user"].team_id
          );

          this._settingsService.setSettings(
            this.user.info.team_id,
            data["settings"]
          );
          this._settingsService.setContent(
            data["contents"],
            this.user.info.team_id
          );

          //initiate settings for metrics service
          this._metricsService.setTeamID(this.user.info.team_id);

          this.loginProcessing(
            data["accessToken"],
            myTimestamp,
            incoming.rememberLogin
          );
        } else {
          this.errorMsg = "<strong>ERROR</strong>: " + data["status"];
        }
      },
      (err) => {
        let errorMsg = err.error.error;
        this.statusMsg = "";

        if (errorMsg.indexOf("Invalid username") !== -1) {
          errorMsg = "<strong>ERROR</strong>: Invalid username or password.";
        }

        this.errorMsg = errorMsg;
      }
    );
  }

  private loginProcessing(token, myTimestamp, rememberLogin) {
    if (environment.localstorage) {

      console.log("setting localstorage");

      this.coolLocalStorage.setItem("admin_panel_jwt", token);
      this.coolLocalStorage.setItem(
        "admin_panel_username",
        this.user.info.username
      );
      this.coolLocalStorage.setObject("admin_panel_userinfo", this.user.info);
      this.coolLocalStorage.setObject(
        "admin_panel_team_id",
        this.user.info.team_id
      );

      this.coolLocalStorage.setItem(
        "admin_panel_clientcode",
        this.user.info.team_id === 1 ? 1 : this.clientcode
      );

      this.coolLocalStorage.setObject(
        "admin_panel_rememberLogin",
        rememberLogin
      );

      this.coolLocalStorage.setItem(
        "admin_panel_login_time_stamp",
        String(myTimestamp)
      );

      this.coolLocalStorage.setItem("admin_panel_user_logged_in", "true");

      this.redirectAfterLogin();
    }
  }

  private redirectAfterLogin() {
    const params = this.routeSnap["params"];

    let redirect = "/client-list";

    if (this.user.info.team_id !== 1) {
      redirect = "/client/" + this.clientcode;
    }

    this.router.navigateByUrl(this.router.createUrlTree([redirect]));
  }
}
