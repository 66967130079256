import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { Subject } from "rxjs";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { MediaServicesV3Service } from "src/app/modules/media-management/services/media.services.v3.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

@Component({
  selector: 'app-manage-media-removal',
  templateUrl: './manage-media-removal.component.html',
  styleUrls: ['./manage-media-removal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageMediaRemovalComponent implements OnInit {
  //persistent
  private token: string;
  public eventsAttachedTo: any = []; //to hold events this media is attached
  public eventsAttachedToAsMeta: any = []; //to hold events this media is added as an event setting (i.e. logo, video, pdf)
  public attachedProfilePictures: any = []; //to hold user accounts this media is added to as a profile picture
  public eventsAttachedToAsProps: any = [];
  public assetPrefix: string = "/assets/json";
  public labels: any;

  //incoming
  public targetMedia: any; //incoming media object
  public teamID: number;

  //feedback response
  public loading: boolean = false;
  public errorMsg: string = "";
  public statusMsg: string = "";
  public formState: string = "active";

  //form vars
  public showWarning: boolean = false; //triggers the warning or warnings about media attached to other entities, i.e. events or user accounts
  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Add",
  };

  //data back to the parent
  private outgoing: Subject<any> = new Subject();

  //media handling
  public clientSettings: any;

  constructor(
    public mediaFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService,
    private mediaServicesV3Service: MediaServicesV3Service,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.retrieveToken();
    this.retrieveLabels();

    this.mediaServicesV3Service.retrieveScheduledEvents(this.targetMedia, this.token).then(res => {
      this.eventsAttachedToAsMeta = res.eventsAttachedToAsMeta ? res.eventsAttachedToAsMeta : [];
      this.attachedProfilePictures = res.attachedProfilePictures ? res.attachedProfilePictures : [];
      this.showWarning = res.showWarning;
      this.eventsAttachedTo = res.eventsAttachedTo ? res.eventsAttachedTo : [];
      this.eventsAttachedToAsProps = res.eventsAttachedToAsProps ? res.eventsAttachedToAsProps : [];
      this.loading = false;
    })
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }
  public manageMedia() {

    let notification = this._notificationService.savingNotification(
      `Deleting ${this.labels.media.singular}`
    );

    this.mediaServicesV3Service.manageMedia(this.targetMedia, this.showWarning, this.token, this.attachedProfilePictures, this.eventsAttachedTo, this.eventsAttachedToAsMeta, this.eventsAttachedToAsProps).then(res => {

      this.errorMsg = "";
      this.formState = res.status;
      this.statusMsg = res.message;

      this._notificationService.clearNotification(notification);

      this.outgoing.next(res.response.deleted_asset);
      this.mediaFrame.hide();
    })

    this.mediaServicesV3Service.statusMsg.subscribe(status => {
      if (status) {
        this.errorMsg = status.errorMsg;
        this.formState = status.formState;
        this.statusMsg = status.statusMsg;
      }
    })

    this.mediaServicesV3Service.outgoing.subscribe(data => {
      this.outgoing.next(data);
    })

  }

}

