import {
  startWith,
  map,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import * as fileSaver from "file-saver";
import * as moment from "moment";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";

import {
  faCircleInfo
} from "@fortawesome/free-solid-svg-icons";

export class chartDates {
  constructor(public start: string, public end: string) { }
}

@Component({
  selector: "app-user-meta-inventory-session-results",
  templateUrl: "./user-meta-inventory-session-results.component.html",
  styleUrls: [
    "./user-meta-inventory-session-results.component.scss",
    "../../../../metrics/metrics-interactive/metrics-interactive.component.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UserMetaInventorySessionResultsComponent implements OnInit {
  //persistent
  public token: string;
  public teamID: number;
  public users: any;
  public sessions: any;

  //icons
  public faCircleInfo = faCircleInfo;

  public tz: any = moment.tz(moment.tz.guess()).format("z");

  //content
  public copy: string = "";

  //user autocomplete
  @Input() filterByType: string = "all";
  @Input() userID: any = -1;
  public userForm: UntypedFormGroup;
  public filterData = {
    usersOrig: [],
    users: [],
  };
  public userText = new Subject();
  userResults: Observable<{ label: string }[]>;
  public formLoading: boolean = true;

  //inventory types
  public inventoryTypes: any = [];

  //inventory exercises
  public inventoryExercises: any = [];
  public inventoryTypeSelectionRequired: boolean = false;

  //debouncing
  public debounceLength: number = 250;

  //feedback response
  public loading: boolean = true;
  public isUserSelected: boolean = false;
  public sessionsLoading: boolean = true;
  public tableStatus: string = "pending";
  public csvStatus: string = "pending";

  //hours target index
  public hoursTargetIndex: number = 0;
  public dateTargetIndex: string = "";

  //form vars
  public sessionsSelect: { value: string; label: string }[];
  public usersSelect: { value: string; label: string }[];
  public typeSelect: { value: string; label: string }[] = [];

  //table data
  public tableData: {
    m_dataSaveTime: string;
    m_itemName: string;
    m_expectedItemStatusList: { m_statusNames: string[] };
    m_studentStatuses: { m_statusNames: string[] };
    m_expectedProductAmount: string;
    m_reportedProductAmount: string;
    m_Location: string;
    m_userInputOtherStatuses: string;
  }[] = [];

  //role conditions
  public adminDefault: boolean;
  private user: any = null;

  public inventory: any = null;

  // public maxDate = moment();
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";

  public headingHit: any = null;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService
  ) { }

  ngOnInit() {
    this.retrieveTeamID();

    this.copy =
      "Review Inventory Session Results. To begin the process, use the user search on the right to locate a user to review, then select the Inventory Type to retrieve Inventory Results. These results can be further refined by selecting a specific Inventory Entry. <span class='bold action-msg-small color-theme-base-medium-dark'>Required* fields<span>";

    this.retrieveToken();
    this.retrieveUser();

    if (this.user.role_type_id === 1 || this.user.role_type_id === 2) {
      this.adminDefault = true;
    }

    this.retrieveData();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private async retrieveData() {
    if (this.adminDefault) {
      //await this.retrieveUsers();
      let usersResponse = await this.retrieveUsers().catch((error) => {
        this.tableStatus = "noData";
      });
      this.processUsers(usersResponse);
    }

    //await retrieve user data groups and drop into inventoryTypes
    let userDataGroupsResponse = await this.retrieveUserDataGroups().catch(
      (error) => { }
    );

    this.processInventoryTypes(userDataGroupsResponse);

    this.buildForm();

    this.loading = false;
    this.formLoading = false;
  }

  private retrieveUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options,
      true
    );

    return retrieveUsers.toPromise();
  }

  /**
   * Retrieve user data groups
   *
   */

  private retrieveUserDataGroups() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUserDataGroups =
      this._xrPlatformRestService.retrieveEntityCollection(
        "user-data-groups/by-group-type",
        "warehouse_inventory",
        "name",
        options,
        true
      );

    return retrieveUserDataGroups.toPromise();
  }

  private processUsers(users) {
    users.forEach((user) => {
      let thisUser = {
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      };

      this.filterData.users.push(thisUser);
      this.filterData.usersOrig.push(thisUser);
    });
  }

  private processInventoryTypes(incoming) {
    let inventoryTypes = [];

    incoming.user_data_groups.forEach((group) => {
      let thisGroup = {
        label: group.label,
        value: group.id,
      };

      inventoryTypes.push(thisGroup);
    });

    this.inventoryTypes = inventoryTypes;
  }

  private buildForm() {
    this.userForm = new UntypedFormGroup({
      userSelection: new UntypedFormControl(null),
      inventoryTypeSelection: new UntypedFormControl({
        value: null,
        disabled: true,
      }),
      inventoryExerciseSelection: new UntypedFormControl({
        value: null,
        disabled: true,
      }),
    });

    this.userResults = this.userForm.controls.userSelection.valueChanges.pipe(
      startWith(""),
      map((user) => this.filterUsers(user))
    );

    this.onFormChanges();

    if (!this.adminDefault) {
      //if this is not an admin, we need to set the userSelection to the current user
      let thisUser = {
        label: `${this.user.first_name} ${this.user.last_name}`,
        value: this.user.id,
      };

      this.userForm.controls.userSelection.setValue(thisUser);
    }
  }

  /**
   * Capture form changes for userSelection and inventoryTypeSelection
   */
  private onFormChanges(): void {
    this.userForm.controls.userSelection.valueChanges
      .pipe(debounceTime(this.debounceLength), distinctUntilChanged())
      .subscribe((val) => {
        if (typeof val === "object" && val !== null) {
          console.log(
            "userSelection: conditions typeof val === 'object' && val !== null"
          );

          this.userForm.controls.inventoryTypeSelection.setValue(null);
          this.userForm.controls.inventoryTypeSelection.enable({
            emitEvent: false,
          });

          let exerciseVal =
            this.userForm.controls.inventoryExerciseSelection.value;

          if (parseInt(exerciseVal) > 0) {
            this.userForm.controls.inventoryExerciseSelection.setValue(null);
            this.userForm.controls.inventoryExerciseSelection.disable({
              emitEvent: false,
            });
          }

          this.tableStatus = "needType";
          this.inventoryTypeSelectionRequired = true;
        } else {
          let inventoryVal =
            this.userForm.controls.inventoryTypeSelection.value;

          if (inventoryVal !== null && parseInt(inventoryVal) > 0) {
            this.userForm.controls.inventoryTypeSelection.setValue(null);
            this.userForm.controls.inventoryTypeSelection.disable({
              emitEvent: false,
            });
          }

          let exerciseVal =
            this.userForm.controls.inventoryExerciseSelection.value;

          if (parseInt(exerciseVal) > 0) {
            this.userForm.controls.inventoryExerciseSelection.setValue(null);
            this.userForm.controls.inventoryExerciseSelection.disable({
              emitEvent: false,
            });
          }
          this.tableStatus = "pending";
        }
      });

    this.userForm.controls.inventoryTypeSelection.valueChanges.subscribe(
      (val) => {
        if (val !== null && parseInt(val) > 0) {
          console.log(
            "inventoryTypeSelection: conditions val !== null && parseInt(val) > 0"
          );

          this.tableStatus = "loading";
          this.inventoryTypeSelectionRequired = false;

          let exerciseVal =
            this.userForm.controls.inventoryExerciseSelection.value;

          if (parseInt(exerciseVal) > 0) {
            this.userForm.controls.inventoryExerciseSelection.setValue(null);
            this.userForm.controls.inventoryExerciseSelection.disable({
              emitEvent: false,
            });
          }

          this.getInventory(false);
        }
      }
    );

    this.userForm.controls.inventoryExerciseSelection.valueChanges.subscribe(
      (val) => {
        if (parseInt(val) > 0) {
          console.log(
            "inventoryExerciseSelection: conditions parseInt(val) > 0"
          );

          this.tableStatus = "loading";

          //see if all inventory entries is already in the array
          let allInventoryEntries = this.inventoryExercises.find((exercise) => {
            return exercise.value === -1;
          });

          if (allInventoryEntries === undefined) {
            this.inventoryExercises = [
              {
                label: "All Inventory Entries",
                value: -1,
              },
              ...this.inventoryExercises,
            ];
          }

          this.getInventory(true);
        } else if (val === -1) {
          this.tableStatus = "loading";
          this.getInventory(false);
        }
      }
    );
  }

  private filterUsers(option): { label: string }[] {
    return this.filterData.users.filter((user) =>
      user.label
        .toLowerCase()
        .includes(
          option.label ? option.label.toLowerCase() : option.toLowerCase()
        )
    );
  }

  public onUserDisplayValue(user): string | undefined {
    return user ? user.label : user;
  }

  private async getInventory(retrieveExercises: boolean) {
    this.tableStatus = "loading";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let userID = this.userForm.controls.userSelection.value.value;
    let inventoryTypeID = this.userForm.controls.inventoryTypeSelection.value;

    let targetURL = `/user/${userID}/user-data-group/${inventoryTypeID}/user-data-entries/table`;

    if (retrieveExercises) {
      let exerciseID = this.userForm.controls.inventoryExerciseSelection.value;
      targetURL = `/user/${userID}/user-data-group/${inventoryTypeID}/user-data-exercise/${exerciseID}/user-data-entries/table`;
    }

    //retrieve user data
    let inventory = await this._xrPlatformRestService
      .restfulAPIQuery(targetURL, "get", {}, options)
      .toPromise()
      .catch((error) => { });

    this.processInventory(inventory, retrieveExercises);

    //if we're not retrieving the table data by specific exercise, it means we need to populate the exercises dropdown
    if (!retrieveExercises) {
      let exerciseURL = `/user/${userID}/user-data-group/${inventoryTypeID}/user-data-exercises`;

      let exercises = await this._xrPlatformRestService
        .restfulAPIQuery(exerciseURL, "get", {}, options)
        .toPromise()
        .catch((error) => { });

      this.processExercises(exercises);
    }
  }

  private processInventory(inventory, retrieveExercises: boolean = false) {
    this.inventory = inventory;

    if (inventory.values.length > 0) {

      //locate hours target index
      //loop through inventory.labels and find label = 'm_dataSaveTimeHour'
      //@todo need a much better way to do this
      this.inventory.labels.forEach((label, index) => {
        if (label.name === "m_dataSaveTimeHour") {
          this.hoursTargetIndex = index;
        } else if (label.name === "m_dataSaveTime") {
          this.dateTargetIndex = index;
        }
      });

      this.inventory.values.forEach((columns) => {
        let thisDate = "";

        //first get date
        columns.forEach((column, columnDex) => {
          if (columnDex === this.dateTargetIndex) {
            thisDate = column;
          }
        });

        //then use date to populate hours in correct tz
        columns.forEach((column, columnDex) => {
          if (columnDex === this.hoursTargetIndex) {
            let thisHour = moment(thisDate + " " + column + " UTC").format(
              "h:mm:ss a"
            );
            columns[columnDex] = thisHour;
          }
        });
      });

      this.tableStatus = "dataLoaded";
    } else {
      this.tableStatus = "noData";
    }
  }

  private processExercises(exercises) {
    let exerciseSelect = [];

    exercises.forEach((exercise) => {
      //if exercise.user_data_entries_groups_count === 0, don't include
      if (exercise.user_data_entries_groups_count === 0) return;

      let thisExercise = {
        label:
          exercise.session_name +
          " " +
          moment(exercise.created).format("ddd, D MMM YYYY") +
          " " +
          moment(exercise.created).format("hh:mm A") +
          " " +
          this.tz,
        value: exercise.id,
      };

      exerciseSelect.push(thisExercise);
    });

    this.inventoryExercises = exerciseSelect;

    this.userForm.controls.inventoryExerciseSelection.enable({
      emitEvent: false,
    });
  }

  public possibleData(data, target) {
    if (data[target] === undefined) return "";

    return data[target];
  }

  public downloadCSV() {
    this.csvStatus = "loading";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
      responseType: "blob",
    };
    let name = "";

    let user = this.user;
    user.label = `${user.first_name} ${user.last_name}`;
    let userID = user.id;

    if (this.adminDefault) {
      userID = this.userForm.controls.userSelection.value.value;
      user = this.getUserByID(userID);
    }

    let inventoryTypeID = this.userForm.controls.inventoryTypeSelection.value;
    let exerciseID = this.userForm.controls.inventoryExerciseSelection.value;

    let inventoryType = this.getInventoryTypeByID(inventoryTypeID);

    name =
      user.label.toLowerCase().replace(" ", ".") +
      "." +
      inventoryType.label.toLowerCase().replace(" ", ".");

    let target_url = `/user/${userID}/user-data-group/${inventoryTypeID}/user-data-entries/csv`;

    if (exerciseID !== null) {
      let exercise = this.getInventoryExerciseByID(exerciseID);

      name += "." + exercise.label.toLowerCase().replace(" ", ".");

      target_url = `/user/${userID}/user-data-group/${inventoryTypeID}/user-data-exercise/${exerciseID}/user-data-entries/csv`;
    }

    //retrieve user data
    let downloadCSV = this._xrPlatformRestService.restfulAPIQuery(
      target_url,
      "post",
      {
        timezone: this.tz,
      },
      options
    );

    downloadCSV.subscribe(
      (file) => {
        let blob: any = new Blob([file], { type: "text/csv; charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(
          blob,
          name + "." + moment(new Date()).format("YYYY.M.D.hh.mm.A") + ".csv"
        );
        this.csvStatus = "success";
      },
      (error) => {
        this.csvStatus = "error";
      }
    );
  }

  public getUserByID(id) {
    return this.filterData.users.find((user) => {
      return user.value === id;
    });
  }

  public getInventoryTypeByID(id) {
    return this.inventoryTypes.find((type) => {
      return type.value === id;
    });
  }

  public getInventoryExerciseByID(id) {
    return this.inventoryExercises.find((exercise) => {
      return exercise.value === id;
    });
  }

  public mouseEnter(i) {
    this.headingHit = i;
  }
  public mouseLeave() {
    this.headingHit = null;
  }

  public onShown(event) {
    console.log("event in onShown: ", event);
  }

  public onHidden(event) {
    console.log("event in onHidden: ", event);
  }
}
