<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 text-center">
        <h5>Inventory Breakdown</h5>
        <!-- <p>Content Here</p> -->
      </div>
    </div>
    <div class="row">
      <div class="custom-tooltip" *ngIf="headingHit !== null">
        <p>{{ tooltips[headingHit] }}</p>

        <div class="tailShadow"></div>
        <div class="tail1"></div>
        <div class="tail2"></div>
      </div>
      <div class="col-12" *ngIf="tableStatus === 'Loaded'">
        <table
          class="table table-responsive-md btn-table my-3"
          mdbTable
          small="true"
          mdbTableScroll
          scrollY="true"
          maxHeight="400"
          hover="true"
        >
          <thead class="sticky-top theme-base-medium-dark white-text">
            <th
              *ngFor="let head of headElements; let i = index"
              [mdbTableSort]="tableAggregates"
              [sortBy]="sort_elements[i]"
            >
              <mdb-icon
                fas
                icon="question-circle"
                class="info-icon"
                (mouseenter)="mouseEnter(i)"
                (mouseleave)="mouseLeave()"
              ></mdb-icon>
              {{ head | titlecase }}
              <mdb-icon fas icon="sort"></mdb-icon>
            </th>
          </thead>
          <tbody #row>
            <tr *ngFor="let el of tableAggregates; let i = index">
              <td class="text-center">
                {{ el.username }}
              </td>
              <td class="text-center">{{ el.overall }}%</td>
              <td class="text-center">{{ el.status }}%</td>
              <td class="text-center">{{ el.product }}%</td>
              <td class="text-center">{{ el.amounts }}%</td>
              <td class="text-center">
                {{ el.custom }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="tableStatus == 'Loading'">
        <h3>Table is loading</h3>
      </div>
      <div *ngIf="tableStatus == 'Empty'">
        <h3>No data for this session</h3>
      </div>
    </div>
  </div>
</div>
