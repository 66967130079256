<div class="user-csv-upload-page page">
  <div class="row header-row">
    <div class="col-md-12">
      <div class="title-callout">
        <div class="callout-headers-wrapper">
          <div class="callout-headers float-left">
            <h2 class="h4 strong m-0">User CSV Upload</h2>
          </div>
        </div>
        <div class="callout-text-wrapper">
          <p>
            <span [innerHTML]="copy"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row body-row">
    <div class="col-md-12">
      <h3>Select CSV File</h3>

      <div
        ng2FileDrop
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        class="well my-drop-zone"
      >
        Base drop zone
      </div>
      <br /><br />
      <input
        type="file"
        ng2FileSelect
        [uploader]="uploader"
        multiple
        id="dropzone-upload"
      /><br />

      <p>Queue length: {{ uploader?.queue?.length }}</p>

      <table class="table">
        <thead>
          <tr>
            <th width="25%">Preview</th>
            <th width="25%">Name</th>
            <th>Size</th>
            <th>Progress</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploader.queue">
            <td class="preview">
              <img
                src=""
                mediaPreview
                [media]="item?._file"
                class="media-object img-fluid"
              />
            </td>
            <td>
              <strong>{{ item?.file?.name }}</strong>
            </td>
            <td *ngIf="uploader.options.isHTML5" nowrap>
              {{ item?.file?.size / 1024 / 1024 | number: ".2" }} MB
            </td>
            <td *ngIf="uploader.options.isHTML5">
              <div class="progress" style="margin-bottom: 0">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngStyle]="{ width: item.progress + '%' }"
                ></div>
              </div>
            </td>
            <td class="text-center">
              <span *ngIf="item.isSuccess"
                ><i class="glyphicon glyphicon-ok"></i
              ></span>
              <span *ngIf="item.isCancel"
                ><i class="glyphicon glyphicon-ban-circle"></i
              ></span>
              <span *ngIf="item.isError"
                ><i class="glyphicon glyphicon-remove"></i
              ></span>
            </td>
            <td nowrap>
              <button
                type="button"
                class="btn btn-success btn-xs"
                (click)="item.upload()"
                [disabled]="item.isReady || item.isUploading || item.isSuccess"
                id="upload-success-btn"
              >
                <span class="glyphicon glyphicon-upload"></span> Upload
              </button>
              <button
                type="button"
                class="btn btn-warning btn-xs"
                (click)="item.cancel()"
                [disabled]="!item.isUploading"
                id="cancel-upload-btn"
              >
                <span class="glyphicon glyphicon-ban-circle"></span> Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger btn-xs"
                (click)="item.remove()"
                id="remove-upload-btn"
              >
                <span class="glyphicon glyphicon-trash"></span> Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <div>
          Queue progress:
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              [ngStyle]="{ width: uploader.progress + '%' }"
            ></div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success btn-s"
          (click)="uploader.uploadAll()"
          [disabled]="!uploader.getNotUploadedItems().length"
        >
          <span class="glyphicon glyphicon-upload"></span> Upload all
        </button>
        <button
          type="button"
          class="btn btn-warning btn-s"
          (click)="uploader.cancelAll()"
          [disabled]="!uploader.isUploading"
        >
          <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
        </button>
        <button
          type="button"
          class="btn btn-danger btn-s"
          (click)="uploader.clearQueue()"
          [disabled]="!uploader.queue.length"
        >
          <span class="glyphicon glyphicon-trash"></span> Remove all
        </button>
      </div>

      <br /><br />

      <div class="panel panel-default">
        <div class="panel-heading">Response</div>
        <div class="panel-body">
          {{ response }}
        </div>
      </div>
    </div>
  </div>
</div>
