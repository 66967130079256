import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { EventMediaVersion3Service } from 'src/app/modules/event-management/services/event.media.version.3.service';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { SettingsService } from 'src/app/services/utilities/settings.service';

@Component({
  selector: 'app-edit-client-logo-modal',
  templateUrl: './edit-client-logo-modal.component.html',
  styleUrls: ['./edit-client-logo-modal.component.scss']
})
export class EditClientLogoModalComponent implements OnInit {

  public token;
  public type = "image";
  public loading: boolean = true;

  //incoming
  public client;

  public title = "Select Logo";

  private outgoing: Subject<any> = new Subject();

public actionButtons: {
  info: boolean,
  share: boolean,
  delete: boolean
} = {
  info: false,
  share: false,
  delete: false
}

public columnVisibility: {
  thumbnail: boolean,
  pdfStatus: boolean,
  fileType: boolean,
  owner: boolean,
  urlType: boolean,
  dateCreated: boolean,
  shares: boolean
} = {
  thumbnail: true,
  pdfStatus: false,
  fileType: true,
  owner: true,
  urlType: false,
  dateCreated: true,
  shares: false
}

public selectable: boolean = true;

  public filterByType: string = "image";

  public selectableType: string = "single";

  public selectedMedia: any[] = [];

  public clickableThumb: boolean = false;

  public systemButtons: any = {
    "action": "Select",
    "cancel": "Cancel"
  };

  public systemInteraction: string = "settings_media";

  constructor(
    public mediaGroupFrame: MDBModalRef,
    private eventMediaVersion3Service: EventMediaVersion3Service,
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService,
    private _xrPlatformRestService: XrPlatformRestService,
  ) { }

  ngOnInit(): void {
    let team_Settings = this._settingsService.retrieveSettings(this.client.id)
    team_Settings.subscribe((response) => {
      
      this._settingsService.setSettings(this.client.id, response.settings)
      this.loading = false;
    });

    this.retrieveToken()
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public outputSelected(event) {
    
    let outgoing = {
      media: event,
      action: 'update',
    };

    let settings = this._settingsService.getSettingsFromStorage(this.client.id)

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let data = {
      setting_id: settings.clientLogo.id,
      team_id: this.client.id,
      option_id: null,
      value: event.id,
    };

    let addTeamSetting = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.client.id + "/setting/" + settings.clientLogo.id,
      "post",
      data,
      options
    );

    addTeamSetting.subscribe(
      (response) => {
        ;
        this._settingsService.updateSetting(
          response.team_settings,
          this.client.id
        );
      },
      (error) => {
        ;
      }
    );
  
    this.outgoing.next(outgoing);
    this.mediaGroupFrame.hide();
  }

  public onParentActions(incoming){

    if(incoming.action !== undefined && incoming.action === "closeModal"){
  
      this.mediaGroupFrame.hide();
  
    }
  
  }

}
