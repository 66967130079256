import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { AddQuestionOptionModalComponent } from '../add-question-option-modal/add-question-option-modal.component';

@Component({
  selector: 'app-view-question-modal',
  templateUrl: './view-question-modal.component.html',
  styleUrls: ['./view-question-modal.component.scss']
})
export class ViewQuestionModalComponent implements OnInit {

  public question: any;
  public teamID: number;

  private outgoing: Subject<any> = new Subject();

  public addQuestionOptionModal: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-add-room',
    containerClass: '',
    animated: true,
    data: {},
  };

  constructor(
    public viewQuestionModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private modalService: MDBModalService,
  ) { }

  ngOnInit(): void {
  }

  public openAddQuestionOptionModal(question: any)
  {
    this.modalOptions.data = {
      question: question,
      teamID: this.teamID
    };

    this.modalOptions.class = ''
    this.addQuestionOptionModal = this.modalService.show(
      AddQuestionOptionModalComponent,
      this.modalOptions
    );

    this.addQuestionOptionModal.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.question.options.push(changedData.option)
            break;
          case "update":
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }

}
