import { Injectable } from "@angular/core";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { CoolLocalStorage } from "@angular-cool/storage";

@Injectable({
  providedIn: "root",
})
export class ExperienceServicesService {
  private user: any;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
  ) { }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  public retrieveExperiences(token, teamID) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let retrieveExperiences =
      this._xrPlatformRestService.retrieveEntityCollection(
        "team",
        "experiences/flag",
        teamID,
        options,
        true
      );

    return retrieveExperiences.toPromise();
  }

  public retrieveScheduledExperiences(
    teamID: number,
    experienceID: number,
    token: string
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const httpOptions = {
      headers: headers,
    };

    let targetExperience = `legacy-experience/${experienceID}/flag`;

    let retrieveScheduledExperiences =
      this._xrPlatformRestService.retrieveEntityCollection(
        "team",
        targetExperience,
        teamID,
        httpOptions,
        true
      );

    return retrieveScheduledExperiences.toPromise();
  }

  public retrieveUsers(teamID: number, token: string) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      teamID,
      options
    );

    retrieveUsers.subscribe(
      (response) => { },
      (error) => {
        ;
      }
    );

    return retrieveUsers.toPromise();
  }

  public retrieveGroups(teamID: number, token: string) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let retrieveGroups = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "groups",
      teamID,
      options,
      true
    );

    return retrieveGroups.toPromise().catch((err) => {
      return {
        group_information: [],
      };
    });
  }

  public retrieveTeamMeta(teamID: number, token: string) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let retrieveGroups = this._xrPlatformRestService.retrieveEntityData(
      "team",
      teamID,
      options
    );

    return retrieveGroups.toPromise();
  }

  public setupExperiences(
    teamID: number,
    token: string,
    experience: any,
    clientSettings: any
  ) {
    this.retrieveUser();

    let retrieveScheduledExperiences = this.retrieveScheduledExperiences(
      teamID,
      experience.id,
      token
    );

    let retrieveTeamMeta = this.retrieveTeamMeta(teamID, token);

    let promises = [retrieveScheduledExperiences, retrieveTeamMeta];

    let retrieveGroups = this.retrieveGroups(teamID, token);
    promises.push(retrieveGroups);

    return Promise.all(promises);
  }

  public mergeExperiencesData(
    currentExperience,
    scheduledExperiences,
    groups,
    regions,
    clientSettings
  ) {
    let thisExperience = this.parseScheduledExperiences(
      currentExperience,
      scheduledExperiences,
      groups,
      regions,
      clientSettings
    );

    return thisExperience;
  }

  public getUserData(users, user_id) {
    return users.filter((user) => {
      return user.id === user_id;
    });
  }

  public getGroupData(groups, group_id) {
    return groups.filter((group) => {
      return group.id === group_id;
    });
  }

  public getPhotonRegionData(regions, region_id) {
    return regions.filter((region) => {
      return region.id === region_id;
    });
  }

  public getExperienceByID(experiences, experience_id) {
    return experiences.filter((experience) => {
      return experience.experience_id === experience_id;
    });
  }

  private parseScheduledExperiences(
    currentExperience,
    scheduledExperiences,
    groups,
    regions,
    clientSettings
  ) {
    let experienceOut = {
      id: currentExperience.id,
      name: currentExperience.name,
      team_id: currentExperience.team_id,
      description: currentExperience.description,
      counts: {
        scheduled_experiences_count: 0,
        scheduled_users_count: 0,
        scheduled_user_groups_count: 0,
        scheduled_photon_regions_count: 0,
        scheduled_experiences: [],
      },
      scheduled_experiences: [],
    };

    scheduledExperiences.forEach((experience) => {
      if (experience.experience_id !== currentExperience.id) return;

      let userCount = 0;
      let userGroupCount = 0;
      let regionCount = 0;
      let storedUsers = [];
      let storedUserGroups = [];
      let storedRegions = [];
      if (experience.invitees.length) {
        experience.invitees.forEach((invitee) => {
          if (invitee.invitee_type === "user") {
            if (invitee.user === undefined) return false;
            let thisUser = invitee.user;

            let groupUserOnly = false;

            if (
              invitee.meta !== undefined &&
              invitee.meta !== null &&
              invitee.meta.groups !== undefined &&
              invitee.meta.groups.length
            ) {
              if (
                invitee.meta.individual === undefined ||
                !invitee.meta.individual
              )
                groupUserOnly = true;
            }

            if (invitee.as_individual === false) groupUserOnly = true;

            if (groupUserOnly) return;

            thisUser.schedule_id = invitee.id;
            storedUsers.push(thisUser);
            userCount++;
            experienceOut.counts.scheduled_users_count++;
          } else if (invitee.invitee_type === "group") {
            let group_id = invitee.invitee_id;

            let thisGroup = JSON.parse(
              JSON.stringify(this.getGroupData(groups, group_id))
            );

            if (!thisGroup.length) return;

            let existingGroupIndex = -1;

            storedUserGroups.forEach((userGroup, groupdex) => {
              if (userGroup.id === group_id) existingGroupIndex = groupdex;
            });

            if (existingGroupIndex < 0) {
              ;

              thisGroup[0].schedule_id = invitee.scheduled_event_id;
              thisGroup[0].invitee_id = invitee.invitee_id;

              userGroupCount++;
              storedUserGroups.push(thisGroup[0]);
            }
          } else if (invitee.invitee_type === "photon_region") {
            let thisRegion = JSON.parse(
              JSON.stringify(
                this.getPhotonRegionData(regions, invitee.invitee_id)
              )
            );

            if (!thisRegion.length) return;

            thisRegion[0].schedule_id = invitee.id;

            storedRegions.push(thisRegion[0]);
            regionCount++;
            experienceOut.counts.scheduled_photon_regions_count++;
          }
        });
      }

      let meta = experience.meta === undefined ? null : experience.meta;

      let thisExperience = {
        access_code: experience.access_code,
        experience: currentExperience.name,
        name: experience.event_name,
        is_public: experience.is_public,
        has_invitees: experience.has_invitees,
        is_access_code_required: experience.is_access_code_required,
        id: experience.id,
        meta: meta,
        startDate: experience.start_at,
        endDate: experience.end_at,
        schedule_id: experience.id,
        owner_id: experience.owner_id,
        team_id: experience.team_id,
        rooms: experience.rooms,
        spaces: experience.spaces,
        userCount: userCount,
        userGroupCount: userGroupCount,
        scheduled_users: storedUsers,
        scheduled_user_groups: storedUserGroups,
        scheduled_photon_regions: storedRegions,
        scheduled_photon_regions_count: regionCount,
      };

      //optional buildings parameters
      if (clientSettings.experienceOptions.buildings.properties.length) {
        let building =
          meta !== null && meta.building !== undefined ? meta.building : null;
        thisExperience["building"] = building;
      }

      experienceOut.scheduled_experiences.push(thisExperience);
      experienceOut.counts.scheduled_experiences_count++;
    });

    experienceOut.scheduled_experiences.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );

    return experienceOut;
  }
}
