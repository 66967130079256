import { Component, OnInit } from "@angular/core";
import { CoolLocalStorage } from "@angular-cool/storage";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ManageExperienceAttributesComponent } from "./modals/manage-experience-attributes/manage-experience-attributes.component";
import { ArchiveExperienceAttributesComponent } from "./modals/archive-experience-attributes/archive-experience-attributes.component";

@Component({
  selector: "app-experience-attributes",
  templateUrl: "./experience-attributes.component.html",
  styleUrls: ["./experience-attributes.component.scss"],
})
export class ExperienceAttributesComponent implements OnInit {
  public token: string;
  public user: any;

  public experienceAttributesLoading: boolean = true;
  public experienceAttributesSearchText: string = "";

  public experienceAttributes: any = [];

  public teamID: number;
  public copy = "";

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private modalService: MDBModalService
  ) {}

  ngOnInit(): void {
    this.retrieveUser();
    this.retrieveToken();
    this.retrieveExperienceAttributes();
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  //retrieve experienceAttributes
  private retrieveExperienceAttributes() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let experienceAttributes = this._xrPlatformRestService.restfulAPIQuery(
      "/experience-attributes",
      "get",
      {},
      options
    );

    experienceAttributes.subscribe(
      (response) => {
        ;
        this.experienceAttributes = response.experience_attributes;
        this.experienceAttributesLoading = false;
      },
      (error) => {}
    );
  }

  public manageExperienceAttribute(type, index?, targetExperienceAttribute?) {
    this.modalOptions.data = {
      team_id: this.teamID,
      type: type,
      targetExperienceAttribute: targetExperienceAttribute,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-experience-attributes-container",
    };

    this.modalFrame = this.modalService.show(
      ManageExperienceAttributesComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.experienceAttributes.push(changedData.experienceAttribute);
            break;
          case "update":
            this.experienceAttributes[index] = changedData.experienceAttribute;
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public archiveExperienceAttribute(targetExperienceAttribute) {
    this.modalOptions.data = {
      teamID: this.teamID,
      targetExperienceAttribute: targetExperienceAttribute,
      type: "delete",
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "archive-event-types-container",
    };
    this.modalFrame = this.modalService.show(
      ArchiveExperienceAttributesComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            break;
          case "delete":
            let updatedExperienceAttributes = this.experienceAttributes.filter(
              (experienceAttribute) => {
                return experienceAttribute.id !== changedData.experienceAttribute.id;
              }
            );

            this.experienceAttributes = updatedExperienceAttributes;
            break;
        }
      } else {
      }
    });
  }
}
