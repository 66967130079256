<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>

  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <!--<mdb-icon far icon="calendar-alt"></mdb-icon>-->
      <span> {{ title | titlecase }}</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      mdbTooltip="Close"
      placement="bottom"
      (click)="clientFrame.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-3 p-0">
    <form *ngIf="!formLoading" [formGroup]="clientForm">
      <div class="row">
        <div class="col-12">
          <p class="text-left color-theme-base-medium-dark">
            Enter Client Details:
            <span class="action-msg-small">Required* field</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="md-form">
            <input
              type="text"
              id="client-name"
              class="form-control"
              formControlName="client_name"
              mdbInput
              mdbValidate
            />
            <label for="client-name">* Client Name</label>
            <mdb-error
              *ngIf="
                client_name.invalid &&
                (client_name.dirty || client_name.touched)
              "
            >
              Client name required
            </mdb-error>
          </div>
        </div>
        <div class="col-6">
          <div class="md-form input-group mb-3">
            <input
              type="text"
              id="domain"
              class="form-control"
              formControlName="client_domain"
              mdbInput
            />
            <label for="domain" [ngClass]="{ disabled: action == 'update' }"
              >* Client Domain</label
            >
            <mdb-error
              *ngIf="
                client_domain.invalid &&
                (client_domain.dirty || client_domain.touched)
              "
            >
              Client domain required
            </mdb-error>
            <div class="input-group-append disabled" *ngIf="action == 'update'">
              <mdb-icon fas icon="lock"></mdb-icon>
            </div>
          </div>
          <small>This item can't be editted after initial creation</small>
        </div>
      </div>
      <div class="row" *ngIf="action == 'add'">
        <div class="col-6">
          <div class="md-form input-group mb-3">
            <input
              [type]="fieldTextType ? 'text' : 'password'"
              id="pwform-password"
              class="form-control p-0"
              formControlName="default_password"
              mdbInput
            />
            <label for="pwform-password">* Password</label>
            <mdb-error
              *ngIf="
                default_password.invalid &&
                (default_password.dirty || default_password.touched)
              "
            >
              Default password is required
            </mdb-error>

            <div class="input-group-append">
              <button
                mdbBtn
                color="link"
                size="lg"
                class="m-0 px-3 py-2"
                type="button"
                id="button-addon2"
                mdbWavesEffect
                (click)="toggleFieldTextType()"
                mdbTooltip="Show Password"
                placement="bottom"
              >
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-eye-slash': !fieldTextType,
                    'fa-eye': fieldTextType
                  }"
                ></i>
              </button>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="md-form">
            <p>
              <b>Note:</b> make sure you record this default password, it cannot
              be retrieved again.
            </p>
            <div
              [ngClass]="{
                'alert alert-danger':
                  !acknowledgement.value &&
                  (default_password.dirty || default_password.touched)
              }"
            >
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="acknowledgement"
                id="acknowledgement"
              />
              <label for="acknowledgement">Recorded Password</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-start" *ngIf="action == 'update'">
        <div class="col-12">
          <button
            mdbBtn
            mdbWavesEffect
            class="theme-modal-button"
            type="button"
            size="md"
            color="link"
            (click)="openPasswordModal(client)"
          >
            Update Password
          </button>
        </div>
      </div>
      <div class="row engagement-window">
        <div class="col-12">
          <p class="text-left color-theme-base-medium-dark">
            Engagement Window:
          </p>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <div class="md-form">
                <mdb-date-picker
                  #datePickerStart
                  class="start-picker"
                  name="startDate"
                  [options]="myDatePickerOptions"
                  placeholder="Select Start Date"
                  label="Select Start Date"
                  [inline]="true"
                  formControlName="start_date"
                  required
                  (inputFieldChanged)="onInputFieldChange($event, 'start')"
                >
                </mdb-date-picker>
                <mdb-error
                  *ngIf="
                    start_date.invalid &&
                    (start_date.dirty || start_date.touched)
                  "
                >
                  Please enter a date
                </mdb-error>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form">
                <mdb-time-picker
                  class="start-time-picker"
                  [buttonLabel]="'Done'"
                  [twelvehour]="true"
                  [darktheme]="false"
                  placeholder="Select Start Time"
                  label="Select Start Time"
                  formControlName="start_time"
                ></mdb-time-picker>
                <mdb-error
                  *ngIf="
                    start_time.invalid &&
                    !start_time.errors.timeMustBeValid &&
                    (start_time.dirty || start_time.touched)
                  "
                >
                  Please enter a start time
                </mdb-error>
                <mdb-error
                  *ngIf="
                    start_time.invalid && start_time.errors.timeMustBeValid
                  "
                  >Please enter a valid start time
                </mdb-error>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="md-form">
                <mdb-date-picker
                  #datePickerEnd
                  class="end-picker"
                  name="endDate"
                  [options]="myEndDatePickerOptions"
                  placeholder="Select End Date"
                  label="Select End Date"
                  [inline]="true"
                  formControlName="end_date"
                  required
                >
                </mdb-date-picker>
                <mdb-error
                  *ngIf="
                    end_date.invalid &&
                    !end_date.errors.dateMustBeLater &&
                    (end_date.dirty || end_date.touched)
                  "
                >
                  Please enter a date
                </mdb-error>
                <mdb-error
                  *ngIf="end_date.invalid && end_date.errors.dateMustBeLater"
                  >End date must be on the same day or later than the start
                  date.
                </mdb-error>
              </div>
            </div>
            <div class="col-6">
              <div class="md-form">
                <mdb-time-picker
                  class="end-time-picker"
                  [buttonLabel]="'Done'"
                  [twelvehour]="true"
                  [darktheme]="false"
                  placeholder="Select End Time"
                  label="Select End Time"
                  formControlName="end_time"
                ></mdb-time-picker>
                <mdb-error
                  *ngIf="
                    end_time.invalid &&
                    !end_time.errors.timeMustBeValid &&
                    !end_time.errors.timeMustBeLater &&
                    (end_time.dirty || end_time.touched)
                  "
                >
                  Please enter an end time
                </mdb-error>
                <mdb-error
                  *ngIf="end_time.invalid && end_time.errors.timeMustBeValid"
                  >Please enter a valid end time
                </mdb-error>
                <mdb-error
                  *ngIf="end_time.invalid && end_time.errors.timeMustBeLater"
                  >End time must be later than the start time.
                </mdb-error>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="md-form">
            <input
              type="text"
              id="client-description"
              class="form-control"
              formControlName="description"
              mdbInput
            />
            <label for="client-description">Client Description</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="md-form">
            <input
              type="text"
              id="engagement-description"
              class="form-control"
              formControlName="engagement_description"
              mdbInput
            />
            <label for="engagement-description">Engagement Description</label>
          </div>
        </div>
      </div>
    </form>

    <div
      class="alert alert-danger text-center"
      role="alert"
      *ngIf="
        !formLoading &&
        !acknowledgement.value &&
        (acknowledgement.dirty || acknowledgement.touched) &&
        (default_password.dirty || default_password.touched)
      "
    >
      <b>See Above:</b> Please make sure to record your password and check off
      "Password Recorded"
    </div>

    <div class="modal-footer">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        data-dismiss="modal"
        [disabled]="!clientForm.valid || !acknowledgement.value"
        (click)="clickActionButton()"
        class="confirm-btn"
      >
        {{ action | titlecase }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        data-dismiss="modal"
        (click)="clientFrame.hide()"
      >
        Close
      </button>
    </div>
  </div>
</div>
