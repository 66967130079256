<div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">
      Manage options for item {{ item.name }}
      <mdb-icon
        fas
        icon="user-plus"
      ></mdb-icon>
    </h4>
  </div>
  <div class="modal-body m-3 p-0">
    <div class="row container-gallery justify-content-start" *ngIf="!loading">
      <div *ngFor="let option of options; let i = index">
        <mdb-card class="w-100">
          <mdb-card-body>
            <a class="activator px-3 mr-2">
              <mdb-icon
                fas
                icon="minus-circle"
                (click)="
                clickDeleteItemOption(option.id, i)
                "
              ></mdb-icon>
            </a>
  
            <mdb-card-title>
              <h4>{{ option.label }}</h4>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button
      mdbBtn
      class="waves-light"
      mdbWavesEffect
      (click)="viewItemOptionsModal.hide()"
    >
      Close
    </button>
  </div>