<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" *ngIf="!loading">
      <span>Delete this {{ labels.media.singular }}?</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="mediaFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <p *ngIf="loading" class="text-center">Loading...</p>
      <div *ngIf="!loading">
        <p class="justify-content-center text-center">
          This action will delete {{ targetMedia.name }} from the
          {{ labels.media.singular }} library and from any associated
          {{ labels.event.plural }}.
        </p>
        <p class="semi-bold justify-content-center text-center">
          Are you sure you want to delete this
          {{ labels.media.singular }}?
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-button btn"
      [disabled]="loading || formState === 'processing'"
      (click)="manageMedia()"
    >
      delete {{ labels.media.singular }}
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="mediaFrame.hide()"
    >
      cancel
    </button>
  </div>
</div>
