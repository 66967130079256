import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
  Inject,
} from "@angular/core";
import { Subject } from "rxjs";

import {
  MDBModalRef,
  MDBModalService,
  PopoverDirective,
} from "ng-uikit-pro-standard";

import { ManageMediaSharingComponent } from "../manage-media-sharing/manage-media-sharing.component";
import { ManageMediaOwnerComponent } from "../manage-media-owner/manage-media-owner.component";

import { CoolLocalStorage } from "@angular-cool/storage";
import { ManageMediaRemovalComponent } from "../manage-media-removal/manage-media-removal.component";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { ManageMediaUpdateThumbnailComponent } from "../manage-media-update-thumbnail/manage-media-update-thumbnail.component";
import { debounceTime } from "rxjs/operators";

import {
  faCirclePlus,
  faImages,
  faPlusSquare,
  faMinus,
  faTimes,
  faEyeSlash,
  faEye,
  faCopy,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFileAudio
} from "@fortawesome/free-regular-svg-icons";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { environment } from "src/environments/environment";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-manage-media-view",
  templateUrl: "./manage-media-view.component.html",
  styleUrls: ["./manage-media-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageMediaViewComponent implements OnInit {
  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  //incoming
  public teamID: number;
  public mediaSharing: any;

  //visibility control
  public isModerator: boolean = false;
  public isParticipant: boolean = false;

  public mediaFrame: MDBModalRef;

  //options
  public is360Options = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  //fa icons
  public faCirclePlus = faCirclePlus;
  public faImages = faImages;
  public faMinus = faMinus;
  public faPlusSquare = faPlusSquare;
  public faMinusSquare = faMinusSquare;
  public faTimes = faTimes;
  public faEyeSlash = faEyeSlash;
  public faEye = faEye;
  public faCopy = faCopy;
  public faFileAudio = faFileAudio;

  //tracking updates
  public isClean: boolean = true;
  public mediaLoading: boolean = true;
  public isGeneric: boolean = false;

  //debouncing
  private debounceSubject = new Subject();

  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";
  public placeHolderMp3: string = "assets/img/mp3-thumbnail.png";
  public placeHolderWav: string = "assets/img/wav-thumbnail.png";

  //data back to the parent
  private outgoing: Subject<any> = new Subject();
  modalFrame: MDBModalRef;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private shareModal: MDBModalRef,
    private ownerModal: MDBModalRef,
    public viewModal: MDBModalRef,
    private modalService: MDBModalService,
    private _clientManagementService: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _sharedDataService: SharedDataService,
    private _notificationService: NotificationsService,
    @Inject(DOCUMENT) private document: Document
  ) { }
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  private ownerModalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public labels: any;
  public user_id: number;
  public media_item: any;
  public user: any;
  public sharelevel: string = "";
  public clientSettings: any;
  private token: string;

  //player options
  public videoPlayerOptions: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };
  public tracks: { title: string; link: string; artist: string; duration: number; }[] = [];
  public pdfSrc: string = "";

  //for model viewer
  public modelLoaded: boolean = false;
  public modelSrc: string = "";
  public generatedThumbnail: any = null;
  public thumbnailSrc: string = null;
  private proxyURL: string = environment.proxyURL;

  ngOnInit(): void {

    console.log("this.media_item in ManageMediaViewComponent", this.media_item);

    if (this.user.role_type_id === 3) this.isModerator = true;
    if (this.user.role_type_id === 4 || this.user.role_type_id === 6) this.isParticipant = true;

    this.retrieveLabels();
    this.retrieveToken();
    this.user_id = this.user.id;
    this.getShareLevel();
    this.clientSettings = this._clientManagementService.getClientSettings(
      this.teamID
    );

    this.configurePlayers();

    //subscribe to debounce subject
    this.debounceSubject
      .pipe(debounceTime(350))
      .subscribe((params: any) => this.runUpdate(params));

    //if the file is a type that doesn't load, load the default placeholder and set mediaLoading to false
    let loading_types = ["image", "video", "audio", "pdf", "3d-object"];

    if (loading_types.indexOf(this.media_item.type) === -1) this.isGeneric = true;
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private getShareLevel() {
    this.media_item.shared_with.forEach((element) => {
      if (element.user_id == this.user.id) {
        this.sharelevel = element.share_level;
      }
    });
  }

  private configurePlayers() {
    switch (this.media_item.type) {
      case "video":
        this.videoPlayerOptions = {
          fluid: true,
          aspectRatio: "16:9",
          autoplay: false,
          sources: [
            {
              src: this.media_item.cdn_uri,
              type: "video/mp4",
            },
          ],
        };
        break;
      case "audio":
        let thisTrack = {
          title: this.media_item.name,
          link: this.media_item.cdn_uri,
          artist: "Admin Panel",
          duration: 27,
        };

        this.tracks.push(thisTrack);

        break;
      case "pdf":
        this.pdfSrc = this.media_item.cdn_uri;
        break;
      case "3d-object":
        this.configureModel();
        break;
    }
  }

  public onAssetLoad(event) {
    console.log("event in onAssetLoad", event);
    this.mediaLoading = false;
  }

  public updateMediaDetails(event, targetProp, excluded = "") {

    let params = {
      targetProp: targetProp,
      event: event,
      excluded: excluded,
    };
    this.debounceSubject.next(params);
  }

  public async runUpdate(params) {
    console.log("params in runUpdate", params);
    let notification = this._notificationService.savingNotification(
      "Updating media..."
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let value = params.event + params.excluded;

    console.log("value in runUpdate", value);
    console.log("this.media_item in runUpdate", this.media_item);
    console.log("this.media_item[params.targetProp] in runUpdate", this.media_item[params.targetProp]);

    //if the value is the same, don't update it (especially for the name)
    if (value === this.media_item[params.targetProp]) {
      this._notificationService.clearNotification(notification);
      this._notificationService.successNotification("Media updated successfully");
      return;
    }

    //if string is a boolean, convert to an actual boolean
    if (value === "true") value = true;
    if (value === "false") value = false;

    let updateProp = this._xrPlatformRestService
      .restfulAPIQuery(
        "/asset/" + this.media_item.uuid,
        "put",
        {
          [params.targetProp]: value,
        },
        options
      )
      .toPromise();

    let updateResult = await updateProp.catch((err) => {
      console.log("err in runUpdate", err);
      this._notificationService.clearNotification(notification);
      this._notificationService.errorNotification("There was an issue updating this media. Please try again.");
    });

    console.log("updateResult in runUpdate", updateResult);

    this.media_item = updateResult.asset;
    this._notificationService.clearNotification(notification);
    this._notificationService.successNotification("Media updated successfully");

    this.media_item = updateResult.asset;

    let outgoingData = {
      action: "update",
      changedData: updateResult,
    };

    this.outgoing.next(outgoingData);

    this._sharedDataService.sendSharedData({
      type: "editSuccesful",
      data: {
        name: params.targetProp,
        changed: params.event,
        exclude: params.excluded,
      }
    });
  }

  public openUpdateModelThumbnailModal(media_item) {
    this.modalOptions.data = {
      user: this.user,
      teamID: this.teamID,
      media_item: media_item,
    };
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "upload-modal-container",
    };

    this.modalFrame = this.modalService.show(
      ManageMediaUpdateThumbnailComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((result: any) => {
      console.log("result in openUpdateModelThumbnailModal", result);
      this.media_item = result.changedData.asset;
      let outgoingData = {
        action: "update-thumbnail",
        changedData: result.changedData.asset,
      };

      this.outgoing.next(outgoingData);
    });
  }

  public openShareModal(user_id, media_item) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "share-modal-container",
    };

    this.modalOptions.data = {
      media_item: media_item,
      user: this.user,
    };

    this.shareModal = this.modalService.show(
      ManageMediaSharingComponent,
      this.modalOptions
    );

    this.shareModal.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.media_item.shared_with.push(changedData.share);
            let outgoingData = {
              action: "add",
              shares: this.media_item.shared_with,
            };
            this.outgoing.next(outgoingData);
            break;
          case "update":
            this.media_item.shared_with.splice(
              this.media_item.shared_with.findIndex(
                (x) => x.id === changedData.share.id
              ),
              1
            );
            let updateoutgoingData = {
              action: "update",
              shares: this.media_item.shared_with,
            };
            this.outgoing.next(updateoutgoingData);
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openOwnerModal(media_item) {
    this.ownerModalOptions.containerClass = "";
    this.ownerModalOptions = {
      ...this.ownerModalOptions,
      containerClass: "owner-modal-container",
    };

    this.ownerModalOptions.data = {
      media_item: this.media_item,
      user: this.user,
    };

    this.ownerModal = this.modalService.show(
      ManageMediaOwnerComponent,
      this.ownerModalOptions
    );
  }

  public clickDeleteAsset() {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "delete-asset-container",
    };
    this.modalOptions.data = {
      targetMedia: this.media_item,
      action: "delete",
      teamID: this.teamID,
      clientSettings: this.clientSettings,
    };

    this.mediaFrame = this.modalService.show(
      ManageMediaRemovalComponent,
      this.modalOptions
    );

    this.mediaFrame.content.outgoing.subscribe((result: any) => {
      let outgoingData = {
        action: "delete",
        targetMedia: result,
      };

      this.outgoing.next(outgoingData);
      this.viewModal.hide();
    });
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    let mainEventDisplay = this.document.getElementsByClassName(
      "event-media-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("secondary");
      mainEventDisplay.item(0).classList.add("restore-primary");
    }

    this.viewModal.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  private async configureModel() {
    if (this.media_item.thumbnail_asset_url !== null) {
      this.thumbnailSrc = this.proxyURL + this.media_item.thumbnail_asset_url;
    }

    console.log(
      "this.thumbnailSrc in ManageMediaUpdateThumbnailComponent",
      this.thumbnailSrc
    );

    console.log("this.media_item.cdn_uri", this.media_item.cdn_uri);

    if (this.media_item.cdn_uri !== null) {
      this.modelSrc = this.proxyURL + this.media_item.cdn_uri;
    } else {
      let retrieveURL = await this.retrieveModelURL(this.media_item.uuid).catch(
        (err) => {
          console.log("err in retrieveModelURL", err);
        }
      );

      console.log("retrieveURL", retrieveURL);

      this.modelSrc = this.proxyURL + retrieveURL;
    }

    this.modelLoaded = true;
  }

  private retrieveModelURL(uuid) {
    const headers = {
      "Content-Type": "text/html",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
      responseType: "text",
    };

    let getPrivateLink = this._xrPlatformRestService.retrieveEntityData(
      "asset",
      uuid + "/url",
      getOptions
    );

    return getPrivateLink.toPromise();
  }

  public onGeneratedThumbnail(event) {
    console.log("event in onGeneratedThumbnail", event);

    //convert blog to image we can use for preview
    this.generatedThumbnail = event;
    let generatedThumbnailLink = URL.createObjectURL(event);
    this.thumbnailSrc = generatedThumbnailLink;
    this.uploadThumbnail(this.media_item);
  }

  public uploadThumbnail(media_item) {
    let notification = this._notificationService.savingNotification(
      "Updating thumbnail..."
    );

    const headers = {
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    //remove extension from name without removing any other periods
    let nameWithoutExtension = media_item.name.substring(
      0,
      media_item.name.lastIndexOf(".")
    );

    let file = new File([this.generatedThumbnail], nameWithoutExtension, {
      type: this.generatedThumbnail.type,
    });

    let data = new FormData();
    data.append("file", file);
    data.append("max_width", "500");

    const targetURL = "/asset/" + media_item.uuid + "/thumbnail";

    let uploadThumbnail = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "post",
      data,
      options,
      "v1"
    );

    uploadThumbnail.subscribe(
      (res) => {
        console.log("res in uploadThumbnail", res);
        this._notificationService.clearNotification(notification);
        this._notificationService.successNotification("Thumbnail updated");

        this.media_item.thumbnail_asset_url = res.asset.cdn_uri;
        this.media_item.thumbnail_asset_uuid = res.asset.uuid;

        let outgoingData = {
          action: "update-thumbnail",
          changedData: this.media_item,
        };
        this.outgoing.next(outgoingData);
      },
      (err) => {
        console.log("err in uploadThumbnail", err);
        this._notificationService.clearNotification(notification);
        this._notificationService.errorNotification("Error updating thumbnail");
      }
    );
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}
