import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-edit-site-settings",
  templateUrl: "./edit-site-settings.component.html",
  styleUrls: ["./edit-site-settings.component.scss"],
})
export class EditSiteSettingsComponent implements OnInit {
  public token: string;
  public team_id: number;
  public loading: boolean;
  public assets: any;

  private outgoing: Subject<any> = new Subject();

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    public addLogoModal: MDBModalRef,
    private modalService: MDBModalService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.retrieveToken();

    this.retrieveExperienceAssetImages();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveExperienceAssetImages() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};
    let brandingSpaces = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.team_id + "/assets/image",
      "get",
      data,
      getOptions
    );
    brandingSpaces.subscribe((response) => {
      this.assets = response.assets;
      this.loading = false;
      (error) => {
        //@todo: handle error
      };
    });
  }

  public clickCustomAsset(asset: any, index: number) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      branding_type: "custom",
      asset_id: asset.id,
    };

    let outGoingData = {
      action: "update",
      asset: asset.id,
    };
    this.outgoing.next(outGoingData);
  }
}
