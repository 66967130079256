import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
    selector: 'app-manage-role-modal',
    templateUrl: './manage-role-modal.component.html',
})
export class ManageRoleModalComponent implements OnInit {

    public team_id: number;
    public token: string;
    public mode: any;
    public role: any;
    public roleForm = this.formBuilder.group({
        role_name: "",
        role_description: "",
    });

    //status messages
    public msgs: {
        errorMsg: string;
        statusMsg: string;
        processingMsg: string;
    } = {
            errorMsg: "",
            statusMsg: "",
            processingMsg: "",
        };

    public continueType: string;

    public btnLabel: {
        close: string;
        reset: string;
        retry: string;
    } = {
            close: "Close",
            reset: "Add Another Role",
            retry: "Retry",
        };

    private outgoing: Subject<any> = new Subject();
    private outgoingData: any;

    constructor(
        public manageRoleModal: MDBModalRef,
        private coolLocalStorage: CoolLocalStorage,
        private _xrPlatformRestService: XrPlatformRestService,
        private formBuilder: UntypedFormBuilder) { }

    ngOnInit(): void {
        if (this.mode === 'Add') {
            this.continueType = "reset";
            this.roleForm = this.formBuilder.group({
                role_name: "",
                role_description: "",
            });
            this.outgoingData = {
                action: 'add',
                roles: []
            }
        } else if (this.mode === 'Edit') {
            this.btnLabel.retry = "Continue Editing";
            this.continueType = "continue";
            this.roleForm = this.formBuilder.group({
                role_name: this.role.name,
                role_description: this.role.description,
            });
        }

        this.retrieveToken();
    }

    private retrieveToken() {
        this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
    }
    public resetForm() {
        this.roleForm.reset();
        this.clearStatus();
    }

    public clearStatus() {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
    }

    public handleIncomingAction(action) {
        ;

        switch (action) {
            case "close-modal":
                this.manageRoleModal.hide();
                break;
            case "reset-form":
                this.resetForm();
                break;
            case "clear-status":
                this.clearStatus();
                break;
        }
    }
    manageRole() {
        if (this.mode === 'Add') {
            this.addRole();
        } else if (this.mode === 'Edit') {
            this.editRole();
        }
    }
    addRole() {
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "<span class='processing-msg'>Adding role</span>";
        ;
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
        };

        const getOptions = {
            headers: headers,
        };

        const data = {
            name: this.roleForm.value.role_name,
            description: this.roleForm.value.role_description,
        };
        let addedRole = this._xrPlatformRestService.restfulAPIQuery(
            '/team/' + this.team_id + '/role',
            'post',
            data,
            getOptions
        );
        addedRole.subscribe(
            (response) => {
                ;

                this.msgs.processingMsg = "";
                this.msgs.errorMsg = "";
                this.msgs.statusMsg = "Role successfully added";
                let outgoingData = {
                    action: 'add',
                    role: response.role
                }
                
                this.outgoing.next(outgoingData);
            },
            (error) => {
                ;
                this.msgs.processingMsg = "";
                let errorMsg = JSON.parse(error._body);
                this.btnLabel.retry = "Retry";
                this.msgs.errorMsg = errorMsg.error;
            }
        );
    }
    editRole() {
        ;

        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "";
        this.msgs.processingMsg =
            "<span class='processing-msg'>Editing branding role</span>";

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
        };

        const getOptions = {
            headers: headers,
        };

        const data = {
            name: this.roleForm.value.role_name,
            description: this.roleForm.value.role_description,
        };
        let edittedRole = this._xrPlatformRestService.restfulAPIQuery(
            '/team/' + this.team_id + '/role/' + this.role.id,
            'put',
            data,
            getOptions
        );
        edittedRole.subscribe(
            (response) => {
                ;

                this.msgs.processingMsg = "";
                this.msgs.errorMsg = "";
                this.msgs.statusMsg = "Branding Role successfully udpated";

                if (response.error != 418) {
                    ;
                    let outgoingData = {
                        action: 'update',
                        role: response.role
                    }
                    this.outgoing.next(outgoingData);
                }
            },
            (error) => {
                ;
                this.msgs.processingMsg = "";
                let errorMsg = JSON.parse(error._body);
                this.btnLabel.retry = "Retry";
                this.msgs.errorMsg = errorMsg.error;
            }
        );
    }
}