import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";

import { MDBModalRef } from "ng-uikit-pro-standard";

@Component({
  selector: "app-close-confirmation-modal",
  templateUrl: "./close-confirmation-modal.component.html",
  styleUrls: ["./close-confirmation-modal.component.scss"],
})
export class CloseConfirmationModalComponent implements OnInit {
  constructor(public confirmFrame: MDBModalRef) {}

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  ngOnInit(): void {}

  public closeConfirm() {
    this.confirmFrame.hide();
    this.outgoing.next("closeConfirmation");
  }

  public closeAllModals() {
    this.confirmFrame.hide();
    this.outgoing.next("closeAll");
  }
}
