import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';

@Component({
  selector: 'app-manage-survey',
  templateUrl: './manage-survey.component.html',
  styleUrls: ['./manage-survey.component.scss']
})
export class ManageSurveyComponent implements OnInit {

  public type: string;
  public survey: any;
  public teamID: number;
  public token: string;
  public user: any;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public surveyForm: any;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Survey",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();
  private outgoingData: any;

  public systemSelect: {value: string; label: string}[]= [{value: 'apb', label: "Admin Panel Backend"}];

  constructor(
    public manageSurveyModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.continueType = "reset";

    this.retrieveUser();
    if(this.type === 'Add') {
      this.surveyForm = this.formBuilder.group({
        name: '',
        label: '',
        description: '',
        system: ''
      });
    }
    else {
      this.surveyForm = this.formBuilder.group({
        name: this.survey.name,
        label: this.survey.label,
        description: this.survey.description,
        system: this.survey.system
      });
    }
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  public clickUpdateSurvey() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Adding Survey</span>";

    const data = {
      name: this.surveyForm.value.name,
      label: this.surveyForm.value.label,
      description: this.surveyForm.value.description,
      system: this.surveyForm.value.system,
      user_id: this.user.id,
      team_id: this.teamID
    }

    if(this.type === "Add") {
      
      let addSurvey = this._xrPlatformRestService.userDataHandler(
        '/survey',
        'post',
        data
      )

      addSurvey.subscribe((response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Survey successfully added";

        let outgoingData = {
          action: 'add',
          survey: response.survey
        }
        this.outgoing.next(outgoingData);
        
        
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      });

    }
    else if(this.type === "Edit") {
      let editSurvey = this._xrPlatformRestService.userDataHandler(
        '/survey/' + this.survey.id + '/update',
        'put',
        data
      )

      editSurvey.subscribe((response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Survey successfully updated";

        let outgoingData = {
          action: 'edit',
          survey: response.survey
        }
        this.outgoing.next(outgoingData);
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      });
    }
  }

  public resetForm() {
    this.surveyForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.manageSurveyModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
