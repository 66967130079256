import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";

import { CoolLocalStorage } from "@angular-cool/storage";
import { TitleCasePipe } from "@angular/common";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { Subscription } from "rxjs";

import {
  faPhotoVideo,
  faUsersCog,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { EditUserComponent } from "../../user-management/user-catalog/modals/edit-user/edit-user.component";

@Component({
  selector: "app-welcome-page",
  templateUrl: "./welcome-page.component.html",
  styleUrls: ["./welcome-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WelcomePageComponent implements OnInit {
  public teamID: number;
  public clientCode: string;
  public copy: string = "";
  public labels: any;
  public user: any;
  public usersCount: number;
  public metrics: {
    events: number;
    users: number;
    assets: number;
  } = {
      events: 0,
      users: 0,
      assets: 0,
    };
  public token: string;
  public assetPrefix: string = "/assets/json";
  public clientSettings: any;

  public team: any;
  public users: any;
  public teamLoading: boolean = true;
  public metricsLoading: boolean = true;

  //subscriptions
  private shareDataSubscription: Subscription;

  //modal options
  public manageAccountFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  //icons
  public faPhotoVideo = faPhotoVideo;
  public faUsersCog = faUsersCog;
  public faCalendarDay = faCalendarDay;

  constructor(
    private _clientManagementService: ClientManagementService,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private TitleCase: TitleCasePipe,
    private _settingsService: SettingsService,
    private modalService: MDBModalService,
    private _sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.retrieveTeamID();
    this.retrieveClientCode();
    this.retrieveUser();
    this.retrieveToken();
    this.retrieveLabels();

    //also let menu know that we're done loading
    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);

    this.retrieveData();

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    let content = this._settingsService.getContentFromStorage(this.teamID);
    this.copy = content.dashboardMain.content_value;

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "manage_account_details"
          ) {
            this.openAccountDetails();
          }
        }
      });
  }

  ngOnDestroy() {
    console.log("destroying subscription from welcome page");
    this.shareDataSubscription.unsubscribe();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retreiveUsers = await this.retrieveUsers(options).catch((error) => {
      ;
    });

    this.users = retreiveUsers;
    this.usersCount = retreiveUsers.length;
    this.metrics.users = this.usersCount;

    let team = await this.retrieveTeam(options).catch((error) => {
      ;
    });

    this.team = team;
    this.teamLoading = false;

    let retrieveEvents = await this.retrievEeventCount(options).catch(
      (error) => {
        ;
      }
    );

    this.metrics.events = retrieveEvents.count;

    let retrieveMedia = await this.retrieveAssetsCount(options).catch(
      (error) => {
        ;
      }
    );

    this.metrics.assets = retrieveMedia.assets;

    this.metricsLoading = false;
  }

  private retrieveUsers(options) {
    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options,
      true
    );

    return retrieveUsers.toPromise();
  }

  private retrieveTeam(options) {
    let retrieveTeam = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID,
      "get",
      {},
      options
    );

    return retrieveTeam.toPromise();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrievEeventCount(options) {
    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      `/teams/events/${this.teamID}/count`,
      "get",
      {},
      options
    );

    return eventRetrieve.toPromise();
  }

  private retrieveAssetsCount(options) {
    let assetRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      `/team/${this.teamID}/assets/count`,
      "get",
      {},
      options
    );

    return assetRetrieve.toPromise();
  }

  public openAccountDetails() {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "add-users-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };

    this.modalOptions.data = {
      user: this.user,
      users: this.users,
      targetUser: this.user,
      teamID: this.teamID,
      action: "update",
      domain: "default",
      sourceType: "account",
    };

    this.manageAccountFrame = this.modalService.show(
      EditUserComponent,
      this.modalOptions
    );

    this.manageAccountFrame.content.outgoing.subscribe((changedData) => {
      this.user = changedData.user;
      this.coolLocalStorage.setObject("admin_panel_userinfo", this.user);
    });
  }
}
