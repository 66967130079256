import { AfterViewInit, Component, ElementRef, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TitleCasePipe } from "@angular/common";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";

import * as moment from "moment";
import "moment-timezone";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { UserServicesService } from "src/app/modules/user-management/services/user-services.service";
import { Meta } from "@angular/platform-browser";
import { map, startWith } from "rxjs/operators";
import { EventServicesService } from "src/app/modules/event-management/services/event-services.service";

@Component({
  selector: "app-schedule-group-to-class",
  templateUrl: "./schedule-group-to-class.component.html",
  styleUrls: ["./schedule-group-to-class.component.scss"],
  providers: [TitleCasePipe],
})
export class ScheduleGroupToClassComponent implements OnInit, AfterViewInit {
  public confirmOn = false;
  public mainModal: any;
  //persistent
  public labels: any;
  private token: string;
  private eventGroups: any;
  public existingGroups: any;

  //incoming
  public targetEvent: any;
  public targetGroup: any;
  public teamID: number;
  public action: string;
  public groups: any;

  //feedback response
  public title = "";
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "",
  };
  public continueType: string;
  public formState: string = "active";

  //form vars
  public formLoading: boolean = true;
  public groupForm: UntypedFormGroup;
  public groupsSelect: any[];
  public viewOnly: { group: string; allGroupsAdded: boolean } = {
    group: null,
    allGroupsAdded: false,
  };
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Add",
    reset: "Add Another Group",
    retry: "Retry",
  };
  public preSelected: {
    id: number;
    name: string;
    date: any;
    schedule_id: number;
    invitee_id: number;
  } = {
    id: null,
    name: null,
    date: null,
    schedule_id: null,
    invitee_id: null,
  };

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  public groupNames = [];
  searchText = new Subject();
  results: Observable<{ name: string }[]>;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public scheduleGroupFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _userServices: UserServicesService,
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef,
    private TitleCase: TitleCasePipe,
    private eventService: EventServicesService
  ) {}

  ngOnInit() {
    this.action = this.action === undefined ? "add" : this.action;
    this.retrieveLabels();
    this.title = "Add " + this.labels.userGroup.singular;
    this.msgs.actionMsg =
      "Add " +
      this.labels.userGroup.singular +
      " to " +
      this.labels.event.singular +
      ":";
    this.continueType = "reset";
    this.existingGroups = JSON.parse(
      JSON.stringify(this.targetEvent.scheduled_user_groups)
    );

    this.retrieveToken();
    this.dynamicCopy();

    this.results = this.searchText.pipe(
      startWith(""),
      map((group) => (group ? this.filter(group) : this.groupNames))
    );

    if (this.action === "remove") {
      this.buildPreselected();
    } else {
      this.retrieveEventGroups();
    }
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveEventGroups() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveGroups = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "groups",
      this.teamID,
      options,
      true
    );

    retrieveGroups.subscribe(
      (result) => {
        this.eventGroups = this._userServices.processGroups(result);
        this.processGroupSelect();
      },
      (error) => {
        let result = {
          group_information: [],
        };

        this.eventGroups = this._userServices.processGroups(result);
        this.processGroupSelect();
      }
    );
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  public onDisplayValue(group): string | undefined {
    return group ? group.name : "";
  }

  private filter(option): string[] {
    return this.groupNames.filter((group) =>
      group.name.toLowerCase().includes(option.toLowerCase())
    );
  }

  private processGroupSelect() {
    let groupsSelect = [];

    this.eventGroups.forEach((group) => {
      let check = false;
      this.existingGroups.forEach((existing) => {
        if (existing.id === group.id) {
          check = true;
        }
      });

      if (check) return false;

      let thisGroupSelect = {
        value: group.id,
        label: group.name,
      };

      this.groupNames.push(group);
      groupsSelect.push(thisGroupSelect);
    });
    this.results = this.searchText.pipe(
      startWith(""),
      map((group) => (group ? this.filter(group) : this.groupNames))
    );
    this.groupsSelect = groupsSelect;
    this.viewOnly.allGroupsAdded =
      this.eventGroups.length && !this.groupsSelect.length ? true : false;

    this.buildPreselected();
  }

  private buildPreselected() {
    this.preSelected.name =
      this.targetGroup === undefined ? null : this.targetGroup.name;
    this.preSelected.id =
      this.targetGroup === undefined ? null : this.targetGroup.id;
    this.preSelected.schedule_id =
      this.targetGroup === undefined ? null : this.targetGroup.schedule_id;
    this.preSelected.invitee_id =
      this.targetGroup === undefined ? null : this.targetGroup.invitee_id;
    this.viewOnly.group = this.targetGroup === undefined ? null : "disabled";
    this.makeFormValidatingGroup();
  }

  public dynamicCopy() {
    this.btnLabel.reset =
      "Add Another " + this.TitleCase.transform(this.labels.userGroup.singular);

    if (this.action) {
      switch (this.action) {
        case "remove":
          this.title = "Remove " + this.labels.userGroup.singular;
          this.continueType = "none";
          this.msgs.actionMsg =
            "Are you sure you want to remove this scheduled " +
            this.labels.userGroup.singular +
            " from the following " +
            this.labels.event.singular +
            "? ";
          this.btnLabel.main = "Remove";
          break;
      }
    }
  }

  private makeFormValidatingGroup() {
    this.groupForm = new UntypedFormGroup({
      groupAutocomplete: new UntypedFormControl(
        this.preSelected.name,
        Validators.required
      ),
    });

    this.formLoading = false;
  }

  public manageGroup() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    if (this.action === "add") {
      this.msgs.errorMsg = "";
      this.msgs.statusMsg = "";
      this.msgs.processingMsg = `<span class='loading-msg'>Adding ${this.labels.userGroup.singular}...</span>`;
      this.formState = "processing";

      let incomingValues = this.groupForm.value;

      let group_id = incomingValues.groupAutocomplete.id;

      const options = {
        headers: headers,
        schedule_id: this.targetEvent.schedule_id,
      };

      //handle legacy individually added users
      if (
        this.targetEvent.meta === undefined ||
        this.targetEvent.meta === null ||
        this.targetEvent.meta.invitee_version === undefined ||
        this.targetEvent.meta.invitee_version < 2
      ) {
        this.inviteeLegacyHandler(options, group_id);
      } else {
        this.addGroupToClassInit(group_id);
      }
    } else {
      this.removeGroupFromClassInit(headers);
    }
  }

  private inviteeLegacyHandler(options, group_id) {
    let setUpGroupsForScheduledEvents =
      this._userServices.setUpGroupsForScheduledEvents(
        group_id,
        this.targetEvent.schedule_id,
        options
      );

    setUpGroupsForScheduledEvents
      .then((response) => {
        let invitees = response[1].scheduled_event.invitees;
        let inviteesToUpdate = {
          new: [],
          existing: [],
        };

        invitees.forEach((invitee) => {
          if (
            invitee.invitee_type === "user" &&
            (invitee.meta === undefined ||
              invitee.meta === null ||
              invitee.meta.groups === undefined ||
              !invitee.meta.groups.length)
          ) {
            let meta =
              invitee.meta === undefined || invitee.meta === null
                ? {}
                : invitee.meta;
            meta.individual = true;

            let thisUpdate = {
              scheduled_event_id: invitee.scheduled_event_id,
              invitee_type: "user",
              invitee_id: invitee.invitee_id,
              meta: meta,
            };
            inviteesToUpdate.existing.push(thisUpdate);
          }
        });

        console.log(
          "inviteesToUpdate in inviteeLegacyHandler",
          inviteesToUpdate
        );

        if (inviteesToUpdate.existing.length) {
          this.updateGroupInvitees(
            inviteesToUpdate,
            group_id,
            this.targetEvent.schedule_id,
            options,
            response
          );
        } else {
          this.addGroupToClassLegacyInit(group_id, options, null, null);
        }
      })
      .catch ((error) => {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = `There was an issue in adding this ${this.labels.userGroup.singular}, please try again.`;
      });
  }

  private addGroupToClassInit(group_id) {
    let thisInvitee = {
      invitee_type: "group",
      group_ids: [group_id],
      scheduled_event_id: this.targetEvent.schedule_id,
    };

    let finalInvitees = [thisInvitee];

    let addingInvitees = this.eventService.addingInvitees(
      this.targetEvent.schedule_id,
      finalInvitees
    );

    addingInvitees
      .then((response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(
          this.labels.userGroup.singular
        )} successfully added`;
        let outgoingData = {
          action: this.action === undefined ? "add" : this.action,
          experience: this.targetEvent,
          scheduled_event: response.scheduled_event,
          group_id: group_id,
        };

        console.log("outgoingData in addGroupToClassInit", outgoingData);

        this.outgoing.next(outgoingData);
        let addedGroup = {
          id: group_id,
        };
        this.existingGroups.push(addedGroup);
        this.processGroupSelect();
      })
      .catch ((error) => {
        console.log("error in addGroupToClassInit", error);
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = `There was an issue when adding this ${this.labels.userGroup.singular}, please try again.`;
      });
  }

  private addGroupToClassLegacyInit(
    options,
    group_id,
    legacyResponse?,
    inviteeVersion?,
    updatedInvitees?
  ) {
    if (inviteeVersion === undefined) inviteeVersion = 2;
    if (updatedInvitees === undefined) updatedInvitees = null;
    if (updatedInvitees !== undefined) inviteeVersion = 2;

    if (legacyResponse === undefined || legacyResponse === null) {
      let setUpGroupsForScheduledEvents =
        this._userServices.setUpGroupsForScheduledEvents(
          group_id,
          this.targetEvent.schedule_id,
          options
        );

      setUpGroupsForScheduledEvents
        .then((response) => {
          let members = response[0].members;
          let invitees = response[1].scheduled_event.invitees;

          if (updatedInvitees !== null) invitees = updatedInvitees;

          let groupInvitees = this.buildInvitees(members, invitees, group_id);
          if (groupInvitees.existing.length) {
            this.updateGroupInvitees(
              groupInvitees,
              group_id,
              this.targetEvent.schedule_id,
              options
            );
          } else {
            this.addGroupToClass(group_id, groupInvitees.new, options);
          }
        })
        .catch ((error) => {
          this.msgs.statusMsg = "";
          this.msgs.processingMsg = "";
          this.msgs.errorMsg = `There was an issue when adding this ${this.labels.userGroup.singular}, please try again.`;
        });
    } else {
      let members = legacyResponse[0].members;
      let invitees = legacyResponse[1].scheduled_event.invitees;

      let groupInvitees = this.buildInvitees(members, invitees, group_id);

      if (groupInvitees.existing.length) {
        this.updateGroupInvitees(
          groupInvitees,
          group_id,
          this.targetEvent.schedule_id,
          options,
          null,
          inviteeVersion
        );
      } else {
        this.addGroupToClass(
          group_id,
          groupInvitees.new,
          options,
          null,
          inviteeVersion
        );
      }
    }
  }

  private addGroupToClass(
    group_id,
    groupInvitees,
    options,
    legacyResponse?,
    inviteeVersion?,
    updatedInvitees?
  ) {
    //legacy handling
    if (legacyResponse === undefined) legacyResponse = null;
    if (inviteeVersion === undefined) inviteeVersion = null;
    if (updatedInvitees === undefined) updatedInvitees = null;

    let usersBody = JSON.stringify(groupInvitees);

    let scheduleUsersToClassGroup = this.scheduleUsersToClassGroup(
      "schedule-invitee",
      usersBody,
      options
    );

    scheduleUsersToClassGroup
      .then((response) => {
        console.log(
          "legacyResponse, inviteeVersion in scheduleUsersToClassGroup response",
          legacyResponse,
          inviteeVersion
        );

        if (legacyResponse === null && inviteeVersion === null) {
          this.msgs.processingMsg = "";
          this.msgs.statusMsg = `${this.TitleCase.transform(
            this.labels.userGroup.singular
          )} successfully added`;
          let outgoingData = {
            action: this.action === undefined ? "add" : this.action,
            experience: this.targetEvent,
            scheduled_event: response.scheduled_event,
            group_id: group_id,
          };

          console.log(
            "this.outgoing. in scheduleUsersToClassGroup",
            this.outgoing
          );
          console.log(
            "outgoingData in scheduleUsersToClassGroup",
            outgoingData
          );

          this.outgoing.next(outgoingData);
          let addedGroup = {
            id: group_id,
          };
          this.existingGroups.push(addedGroup);
          this.processGroupSelect();
        } else if (legacyResponse !== null) {
          //send back to addGroupToClassInit to actually add group
          this.addGroupToClassLegacyInit(
            options,
            group_id,
            legacyResponse,
            inviteeVersion,
            updatedInvitees
          );
        } else if (inviteeVersion !== null) {
          this.updateEventMeta(options, group_id);
        }
      })
      .catch ((error) => {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = `There was an issue adding this ${this.labels.userGroup.singular}, please try again.`;
      });
  }

  private updateEventMeta(options, group_id) {
    let scheduled_event_id = this.targetEvent.schedule_id;
    let meta =
      this.targetEvent.meta === undefined || this.targetEvent.meta === null
        ? {}
        : this.targetEvent.meta;
    meta.invitee_version = 2;
    let body = {
      meta: meta,
    };

    let updateScheduledEvent = this._xrPlatformRestService.scheduledExperiences(
      scheduled_event_id,
      body,
      options
    );

    updateScheduledEvent.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(
          this.labels.userGroup.singular
        )} successfully added`;

        let outgoingData = {
          action: this.action === undefined ? "add" : this.action,
          experience: this.targetEvent,
          scheduled_event: response.scheduled_event,
          group_id: group_id,
        };
        this.outgoing.next(outgoingData);

        let addedGroup = {
          id: group_id,
        };
        this.existingGroups.push(addedGroup);
        this.processGroupSelect();
      },
      (err) => {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = `There is an issue adding this ${this.labels.userGroup.singular}, please try again.`;
      }
    );
  }

  private updateGroupInvitees(
    groupInvitees,
    group_id,
    scheduled_event_id,
    options,
    legacyResponse?,
    inviteeVersion?
  ) {
    let removeList = [];
    let reAddList = [];
    let updateList = [];

    //for legacy handling
    if (legacyResponse === undefined) legacyResponse = null;
    if (inviteeVersion === undefined) inviteeVersion = null;

    console.log(
      "legacyResponse, inviteeVersion in updateGroupInvitees",
      legacyResponse,
      inviteeVersion
    );

    let existingInvitees = groupInvitees.existing;
    let newInvitees = groupInvitees.new;

    existingInvitees.forEach((existing) => {
      let meta = existing.meta;

      if (legacyResponse === null) {
        if (meta === null) meta = {};

        if (meta.groups === undefined) {
          meta.groups = [];
        }

        let newGroup = {
          group_id: group_id,
        };
        meta.groups.push(newGroup);
      }

      let thisUpdate = {
        scheduled_event_id: scheduled_event_id,
        invitee_type: "user",
        invitee_id: existing.invitee_id,
        meta: existing.meta,
      };

      updateList.push(thisUpdate);
    });

    let body = {
      entity: "schedule",
      entity_id: scheduled_event_id,
      toUpdate: updateList,
    };

    let updateUsersinGroup = this._xrPlatformRestService.scheduledExperiences(
      "invitee-meta-update",
      body,
      options
    );

    updateUsersinGroup.subscribe(
      (response) => {
        console.log(
          "response.ScheduledEvent.invitees in updateUsersinGroup",
          response.ScheduledEvent.invitees
        );

        setTimeout(() => {
          this.addGroupToClass(
            group_id,
            newInvitees,
            options,
            legacyResponse,
            inviteeVersion,
            response.ScheduledEvent.invitees
          );
        }, 1);
      },
      (error) => {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = `There is an issue when adding this ${this.labels.userGroup.singular}, please try again.`;
      }
    );
  }

  private async removeGroupFromClassInit(headers) {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = `<span class='loading-msg'>Removing ${this.labels.userGroup.singular}...</span>`;
    this.formState = "processing";

    let group_id = this.preSelected.id;

    let thisInvitee = {
      invitee_type: "group",
      group_ids: [group_id],
      scheduled_event_id: this.targetEvent.schedule_id,
    };

    let finalInvitees = [thisInvitee];

    let removingInvitees = await this.eventService
      .removingInvitees(this.targetEvent.schedule_id, finalInvitees)
      .catch ((error) => {
        console.log("error", error);
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = `There was an issue removing this ${this.labels.userGroup.singular}, please try again.`;
      });

    this.msgs.processingMsg = "";
    this.msgs.statusMsg = `${this.TitleCase.transform(
      this.labels.userGroup.singular
    )} successfully removed`;

    let outgoingData = {
      action: this.action === undefined ? "add" : this.action,
      experience: this.targetEvent,
      scheduled_event: removingInvitees.scheduled_event,
      group_id: group_id,
    };

    console.log("outgoingData in removeInvitees", outgoingData);

    this.outgoing.next(outgoingData);
  }

  private removeGroupFromClassInitLegacy(headers) {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = `<span class='loading-msg'>Removing ${this.labels.userGroup.singular}...</span>`;
    this.formState = "processing";

    let incomingValues = this.groupForm.value;
    let group_id = this.preSelected.id;
    const options = {
      headers: headers,
      schedule_id: this.targetEvent.schedule_id,
    };

    let setUpGroupsForScheduledEvents =
      this._userServices.setUpGroupsForScheduledEvents(
        group_id,
        this.targetEvent.schedule_id,
        options
      );

    setUpGroupsForScheduledEvents
      .then((response) => {
        let invitees = response[1].scheduled_event.invitees;

        let removals = this.buildRemovals(
          invitees,
          group_id,
          this.targetEvent.schedule_id
        );
        this.removeGroupFromClass(
          removals,
          options,
          group_id,
          this.targetEvent.schedule_id
        );
      })
      .catch ((error) => {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = `There was an issue removing this ${this.labels.userGroup.singular}, please try again.`;
      });
  }

  private removeGroupFromClass(
    removals,
    options,
    group_id,
    scheduled_event_id
  ) {
    let body = {
      entity: "schedule",
      entity_id: scheduled_event_id,
      toRemove: removals.remove,
    };

    let removeInvitees = this._xrPlatformRestService.scheduledExperiences(
      "remove",
      body,
      options
    );

    removeInvitees.subscribe(
      (response) => {
        if (removals.keep.length) {
          this.updateRemovals(
            removals.keep,
            options,
            scheduled_event_id,
            group_id
          );
        } else {
          this.msgs.processingMsg = "";
          this.msgs.statusMsg = `${this.TitleCase.transform(
            this.labels.userGroup.singular
          )} successfully removed`;
          let outgoingData = {
            action: this.action === undefined ? "add" : this.action,
            experience: this.targetEvent,
            scheduled_event: response.scheduled_event,
            group_id: group_id,
          };

          this.outgoing.next(outgoingData);
        }
      },
      (err) => {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  private updateRemovals(toUpdate, options, scheduled_event_id, group_id) {
    let body = {
      entity: "schedule",
      entity_id: scheduled_event_id,
      toUpdate: toUpdate,
    };

    let updateRemovals = this._xrPlatformRestService.scheduledExperiences(
      "invitee-meta-update",
      body,
      options
    );

    updateRemovals.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(
          this.labels.userGroup.singular
        )} successfully removed`;
        let outgoingData = {
          action: this.action === undefined ? "add" : this.action,
          experience: this.targetEvent,
          scheduled_event: response.scheduled_event,
          group_id: group_id,
        };

        this.outgoing.next(outgoingData);
      },
      (error) => {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = `There was an issue removing this ${this.labels.userGroup.singular}, please try again.`;
      }
    );
  }

  private scheduleUsersToClassGroup(action, body, options) {
    let scheduleUsers = this._xrPlatformRestService.scheduledExperiences(
      action,
      body,
      options
    );

    return scheduleUsers.toPromise();
  }

  public buildInvitees(members, invitees, group_id) {
    let invitees_out = {
      existing: [],
      new: [],
    };

    if (members.length) {
      members.forEach((member) => {
        let exisitingInvitee = this.getInviteeData(
          invitees,
          member.other_id,
          "user"
        );

        if (exisitingInvitee.length) {
          invitees_out.existing.push(exisitingInvitee[0]);
        } else {
          let groups = [{ group_id: group_id }];

          let thisMember = {
            invitee_type: "user",
            invitee_id: member.other_id,
            meta: {
              groups: groups,
            },
          };

          invitees_out.new.push(thisMember);
        }
      });
    }

    //add group as an invitee
    let group = this.getGroupData(this.groups, group_id);

    let groupMember = {
      invitee_type: "group",
      invitee_id: group_id,
      meta: {
        name: group[0].name,
        description: group[0].description,
        type: group[0].type,
      },
    };

    invitees_out.new.push(groupMember);

    return invitees_out;
  }

  private buildRemovals(invitees, group_id, scheduled_event_id) {
    let removalsOut = {
      remove: [],
      keep: [],
    };

    invitees.forEach((invitee) => {
      if (
        invitee.meta === undefined ||
        invitee.meta === null ||
        invitee.meta.groups === undefined
      )
        return false;

      invitee.meta.groups.forEach((group, index) => {
        if (group.group_id === group_id) {
          if (invitee.meta.groups.length > 1) {
            invitee.meta.groups.splice(index, 1);

            let thisKeep = {
              scheduled_event_id: scheduled_event_id,
              invitee_type: "user",
              invitee_id: invitee.invitee_id,
              meta: invitee.meta,
            };

            removalsOut.keep.push(thisKeep);
          } else if (invitee.meta.groups.length === 1) {
            invitee.meta.groups.splice(index, 1);

            let thisRemove = {
              scheduled_event_id: scheduled_event_id,
              invitee_type: "user",
              invitee_id: invitee.invitee_id,
              meta: invitee.meta,
            };

            if (
              invitee.meta.individual !== undefined &&
              invitee.meta.individual
            ) {
              removalsOut.keep.push(thisRemove);
            } else {
              delete thisRemove.meta;
              removalsOut.remove.push(thisRemove);
            }
          }
        }
      });
    });

    let groupMember = {
      invitee_type: "group",
      invitee_id: group_id,
      scheduled_event_id: scheduled_event_id,
    };

    removalsOut.remove.push(groupMember);

    return removalsOut;
  }

  public getUserData(users, user_id) {
    return users.filter((user) => {
      return user.id === user_id;
    });
  }

  private getGroupData(groups, group_id) {
    return groups.filter((group) => {
      return group.id === group_id;
    });
  }

  private getInviteeData(invitees, invitee_id, type) {
    return invitees.filter((invitee) => {
      return invitee.invitee_id === invitee_id && invitee.invitee_type === type;
    });
  }

  public resetForm() {
    this.groupForm.reset();
    this.formState = "active";
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.formState = "active";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.scheduleGroupFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }
  public turnConfirmOn(frame) {
    this.confirmOn = true;

    this.mainModal.classList.add("overlay");

    frame.show();
  }

  public closeConfirm(frame) {
    this.confirmOn = false;
    this.mainModal.classList.remove("overlay");

    frame.hide();
  }
  public closeAllModals(frame) {
    this.confirmOn = false;
    frame.hide();
    this.scheduleGroupFrame.hide();
  }
}
