<button
  *ngIf="options.closeButton"
  (click)="remove()"
  type="button"
  class="toast-close-button"
  aria-label="Close"
>
  <span aria-hidden="true"><fa-icon [icon]="faTimes"></fa-icon></span>
</button>
<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
  {{ title }}
  <ng-container *ngIf="duplicatesCount"
    >[{{ duplicatesCount + 1 }}]</ng-container
  >
</div>
<div
  *ngIf="message && options.enableHtml"
  role="alert"
  [class]="options.messageClass"
  [innerHTML]="message"
></div>
<div
  *ngIf="message && !options.enableHtml"
  role="alert"
  [class]="options.messageClass"
  [attr.aria-label]="message"
>
  <fa-icon class="success-icon" [icon]="faCheckCircle"></fa-icon
  ><fa-icon
    class="saving-icon"
    [icon]="faCircleNotch"
    animation="spin"
  ></fa-icon
  ><fa-icon class="warning-icon" [icon]="faExclamationCircle"></fa-icon
  ><fa-icon class="error-icon" [icon]="faExclamationTriangle"></fa-icon>
  {{ message }}
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
