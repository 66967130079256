import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { MustMatch } from "src/app/modules/user-management/validators/must-match.directive";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-edit-client-password-modal",
  templateUrl: "./edit-client-password-modal.component.html",
  styleUrls: ["./edit-client-password-modal.component.scss"],
})
export class EditClientPasswordModalComponent implements OnInit {
  public token: string;

  //incoming
  public client: any;

  public formTitle: string = "Edit Password";
  public errorMsg: string = "";
  public statusMsg: string = "";
  public formState: string = "active";

  public pwForm: UntypedFormGroup;
  public btnLabel: { close: string; main: string } = {
    close: "Cancel",
    main: "Update",
  };

  public preSelected: {
    password: string;
  } = {
    password: null,
  };

  public fieldTextType: boolean;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public pwFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.makeFormValidatingGroup();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private makeFormValidatingGroup() {
    this.pwForm = new UntypedFormGroup(
      {
        password: new UntypedFormControl(
          "",
          Validators.compose([Validators.required, Validators.minLength(6)])
        ),
        confirm_password: new UntypedFormControl(
          "",
          Validators.compose([Validators.required])
        ),
      },
      [MustMatch("password", "confirm_password")]
    );
  }

  get password() {
    return this.pwForm.get("password");
  }

  get confirm_password() {
    return this.pwForm.get("confirm_password");
  }

  public editPassword() {
    this.errorMsg = "";
    let incomingValues = this.pwForm.value;
    this.formState = "processing";
    this.statusMsg = "<span class='loading-msg'>Updating password</span>";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const httpOptions = {
      headers: headers,
    };

    let formValues = {
      default_password: incomingValues.password,
    };

    let updatePassword = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.client.id + "/update-default",
      "put",
      formValues,
      httpOptions
    );

    updatePassword.subscribe(
      (response) => {
        this.statusMsg = "Password successfully updated.";
        this.formState = "success";
        this.btnLabel.close = "Close";
      },
      (err) => {
        ;
        this.statusMsg = "";
        this.formState = "active";
        let errorMsg = JSON.parse(err._body);
        this.errorMsg = errorMsg.error;
      }
    );
  }
  public toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
