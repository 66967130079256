import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';

@Component({
  selector: 'app-add-survey-question-modal',
  templateUrl: './add-survey-question-modal.component.html',
  styleUrls: ['./add-survey-question-modal.component.scss']
})
export class AddSurveyQuestionModalComponent implements OnInit {

  public survey: any;
  public teamID: number;
  public autocompleteLoading: boolean = true;

  public questionsSelect: {value: string; label: string}[];

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public addSurveyQuestionForm: any;

  public selectedQuestion;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Survey",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();
  
  constructor(
    public addSurveyQuestionModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.continueType = "reset";

    this.retrieveAutocomplete()

    this.addSurveyQuestionForm = this.formBuilder.group({
      question: '',
    });
  }

  private retrieveAutocomplete() {
    let getAutocomplete = this._xrPlatformRestService.userDataHandler(
      '/survey/' + this.survey.id + '/questions/available',
      'get',
      {}
    )

    getAutocomplete.subscribe((response) => {
      this.questionsSelect = response.questions
      this.autocompleteLoading = false;
    })
  }

  public clickAddSurveyQuestion() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Adding Question to Survey</span>";

    
    const data = {
      question_id: this.selectedQuestion
    }

    let addSurveyQuestion = this._xrPlatformRestService.userDataHandler(
      '/survey/' + this.survey.id + '/question',
      'post',
      data
    )

    addSurveyQuestion.subscribe((response) => {
      this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Survey Question successfully added";

        let outgoingData = {
          action: 'add',
          question: response.question
        }
        this.outgoing.next(outgoingData);

      this.questionsSelect = response.questions
      this.autocompleteLoading = false;
    },
    (error) => {
      ;
      this.msgs.processingMsg = "";
      let errorMsg = JSON.parse(error._body);
      this.btnLabel.retry = "Retry";
      this.msgs.errorMsg = errorMsg.error;
    });
  }

  public questionSelected(event: any) {
    
    this.selectedQuestion = event.value;
  }

  public resetForm() {
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.addSurveyQuestionModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

}
