import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReusableTableComponent } from "./reusable-table/reusable-table.component";
import { SharedModulesModule } from "../shared-modules/shared-modules.module";
import { PersistentModule } from "../persistent/persistent.module";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";

@NgModule({
  declarations: [ReusableTableComponent],
  imports: [
    CommonModule,
    SharedModulesModule,
    PersistentModule,
    MDBBootstrapModulesPro.forRoot(),
  ],
  exports: [ReusableTableComponent],
})
export class ReusableModule {}
