<div class="modal-header">
  <h4 class="modal-title w-100 font-weight-bold">
    Schedule Group <mdb-icon far icon="calendar-alt"></mdb-icon>
  </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="scheduleGroupFrame.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body m-3 p-0">
  {{ actionMsg }}
  <strong class="font-weight-bold">{{ experience.name }}</strong>
  <div
    *ngIf="errorMsg !== ''"
    class="alert alert-danger"
    role="alert"
    [innerHTML]="errorMsg"
  ></div>
  <div
    *ngIf="statusMsg !== ''"
    class="alert alert-info"
    role="alert"
    [innerHTML]="statusMsg"
  ></div>
  <form
    *ngIf="formLoading"
    [formGroup]="scheduleForm"
    class="text-center border border-light p-5"
  >
    <div *ngIf="!viewOnly.group">
      <div *ngIf="groupsSelect.length" class="md-form mb-5">
        <mdb-select
          [options]="groupsSelect"
          placeholder="Select Group"
          formControlName="scheduledGroup"
        ></mdb-select>
        <mdb-error
          *ngIf="
            scheduledGroup &&
            scheduledGroup.invalid &&
            (scheduledGroup.dirty || scheduledGroup.touched)
          "
        >
          Input invalid
        </mdb-error>
      </div>
      <div
        *ngIf="!groupsSelect.length && !groupsIncoming.length"
        class="md-form mb-5"
      >
        <p>No groups added yet</p>
      </div>
      <div
        *ngIf="!groupsSelect.length && groupsIncoming.length"
        class="md-form mb-5"
      >
        <p>All groups have been added to this experience.</p>
      </div>
    </div>
    <div *ngIf="viewOnly.group">
      <p>{{ preSelected.name }}</p>
    </div>

    <div
      *ngIf="
        (viewOnly.date !== 'remove' &&
          viewOnly.date !== 'delete' &&
          !viewOnly.allGroupsAdded) ||
        (viewOnly.date === 'update' && viewOnly.allGroupsAdded)
      "
    >
      <div class="md-form mb-5">
        <mdb-date-picker
          [inline]="true"
          name="mydate"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected date'"
          formControlName="scheduledDate"
          required
        ></mdb-date-picker>
        <mdb-error
          *ngIf="
            scheduledDate.invalid &&
            (scheduledDate.dirty || scheduledDate.touched)
          "
        >
          Input invalid
        </mdb-error>
      </div>
    </div>
    <div *ngIf="viewOnly.date === 'remove' || viewOnly.date === 'delete'">
      <p>{{ preSelected.date }}</p>
    </div>

    <input
      *ngIf="targetGroup !== undefined"
      type="hidden"
      formControlName="scheduleID"
      mdbInput
    />
  </form>
</div>
<div class="row flex-add-form">
  <form
    class="text-center border border-light p-5 edit-form w-100"
    [formGroup]="groupForm"
    autocomplete="off"
  >
    <div class="md-form mb-5">
      <input
        type="text"
        id="groupAuto"
        formControlName="groupAutocomplete"
        [ngModel]="searchText | async"
        (ngModelChange)="searchText.next($event)"
        [mdbAutoCompleter]="auto"
        placeholder="Group Name"
        class="completer-input form-control mdb-autocomplete"
        mdbInput
      />
      <label for="groupAuto"></label>
      <mdb-auto-completer
        #auto="mdbAutoCompleter"
        textNoResults="No results found"
      >
        <mdb-option *ngFor="let option of results | async" [value]="option">
          {{ option }}
        </mdb-option>
      </mdb-auto-completer>
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button
    mdbBtn
    class="waves-light theme-action-button"
    data-dismiss="modal"
    mdbWavesEffect
    (click)="scheduleGroupFrame.hide()"
  >
    {{ btnLabel.close }}
  </button>
  <button
    mdbBtn
    class="waves-light theme-action-button"
    data-dismiss="modal"
    mdbWavesEffect
    [disabled]="!scheduleForm.valid"
    (click)="scheduleGroup()"
  >
    {{ btnLabel.main }}
  </button>
</div>
