<div class="modal-header text-center">
  <h4 class="modal-title w-100">
    <mdb-icon fas icon="users-cog"></mdb-icon> Super Admin {{labels.experience.singular}} Updates
  </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="adminFrame.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body mt-3 p-0">
  <div class="row">
    <div class="col-12">
      <div
        *ngIf="errorMsg !== ''"
        class="alert alert-danger text-capitalize"
        role="alert"
        [innerHTML]="errorMsg"
      ></div>
      <div
        *ngIf="statusMsg !== ''"
        class="alert alert-info text-capitalize"
        role="alert"
        [innerHTML]="statusMsg"
      ></div>
      <p *ngIf="!formLoading">{{ actionMsg }}</p>
      <p *ngIf="formLoading">Loading...</p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      size="md"
      data-dismiss="modal"
      [disabled]="formLoading"
      (click)="updateMediaInvitees()"
    >
      {{ btnLabel.main }}
    </button>
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      size="md"
      data-dismiss="modal"
      [disabled]="formLoading"
      (click)="removeUsersFromOrphanedGroups()"
    >
      Remove Users From Orphaned Groups
    </button>
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      size="md"
      data-dismiss="modal"
      [disabled]="formLoading"
      (click)="removeIndividualUsersFromDeletedEvents()"
    >
      Remove Invitees From Deleted Events
    </button>
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      size="md"
      data-dismiss="modal"
      [disabled]="formLoading"
      (click)="removeGroupsAndMediaFromDeletedEvents()"
    >
      Remove Group Invitees From Deleted Events
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="adminFrame.hide()"
    >
      {{ btnLabel.close }}
    </button>
  </div>
</div>
