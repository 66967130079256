import { Component, EventEmitter, Input, AfterViewInit, OnInit, Output, ElementRef, } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { CloseConfirmationModalComponent } from './close-confirmation-modal/close-confirmation-modal.component';

@Component({
  selector: 'app-close-confirmation',
  templateUrl: './close-confirmation.component.html',
  styleUrls: ['./close-confirmation.component.scss']
})
export class CloseConfirmationComponent implements OnInit, AfterViewInit {

  @Input("action") action: string;  
  @Input("mainModal") mainModal: any;  
  @Input("parentFrame") parentFrame: MDBModalRef;

  
  @Output("confirmOn") confirmOn = new EventEmitter();

    //modal settings
    public confirmFrame: MDBModalRef;
    private modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: "",
      containerClass: "",
      animated: true,
      data: {},
    };


  constructor(
    private elementRef: ElementRef,    
    private modalService: MDBModalService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }

  public turnConfirmOn() {
    if (this.action === "delete") {
      this.parentFrame.hide();
      return true;
    }
    this.mainModal.classList.add("overlay");

    this.confirmOn.next(true);
    this.show();
  }
  
  public show(){
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "confirm-modal fade",
      backdrop:true
        };

        this.confirmFrame = this.modalService.show(
              CloseConfirmationModalComponent,
              this.modalOptions
        );

        this.confirmFrame.content.outgoing.subscribe(changedData => {

            //this is to respond to the button actions on the close-confirmation-modal
            if(changedData === "closeAll"){
                  this.confirmOn.next(false);
                  this.parentFrame.hide();                                  
            } else {
                  this.confirmOn.next(false);
                  this.confirmFrame.hide();
                  this.mainModal.classList.remove("overlay");
            }
        })
      }
}
