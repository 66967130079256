<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span>{{ title }}</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="mediaFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <p class="justify-content-center text-center">
        {{ message }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-button btn"
      (click)="manageMedia()"
    >
      {{ button }}
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="mediaFrame.hide()"
    >
      cancel
    </button>
  </div>
</div>
