import { UntypedFormGroup } from "@angular/forms";
import * as moment from "moment";

// custom validator to check a time field ls later than another time field
// if dates match
export function DateMustBeLater(startDateName: string, endDateName: string) {
  return (formGroup: UntypedFormGroup) => {
    const startDate = formGroup.controls[startDateName];
    const endDate = formGroup.controls[endDateName];

    if (endDate.errors && !endDate.errors.timeMustBeLater) {
      // return if another validator has already found an error on the endTime
      return;
    }

    if (endDate.value === null) {
      return;
    }

    let startDay = moment(startDate.value).format("D");
    let startMonth = moment(startDate.value).format("M");
    let startYear = moment(startDate.value).format("YYYY");

    let endDay = moment(endDate.value).format("D");
    let endMonth = moment(endDate.value).format("M");
    let endYear = moment(endDate.value).format("YYYY");

    if (parseInt(endYear) < parseInt(startYear)) {
      endDate.setErrors({ dateMustBeLater: true });
    } else if (
      endYear === startYear &&
      parseInt(endMonth) < parseInt(startMonth)
    ) {
      endDate.setErrors({ dateMustBeLater: true });
    } else if (
      endYear === startYear &&
      endMonth === startMonth &&
      parseInt(endDay) < parseInt(startDay)
    ) {
      endDate.setErrors({ dateMustBeLater: true });
    } else {
      endDate.setErrors(null);
    }

    return null;
  };
}
