<div class="modal-header text-center">
  <h4 class="modal-title w-100 font-weight-bold">Session Timeout</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="logoutFrame.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-fluid-content p-0">
  <div class="container-overflow-auto">
    <p>Extend your session?</p>
  </div>
</div>
<div class="modal-footer">
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    color="link"
    class="theme-modal-button btn"
    (click)="extendSession()"
  >
    yes
  </button>
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    color="link"
    class="theme-modal-cancel-button btn"
    (click)="logout()"
  >
    no
  </button>
</div>
