<div class="class-wrapper">
    <app-status-messages
      [msgs]="msgs"
      [btnLabel]="btnLabel"
      [continueType]="continueType"
      (targetAction)="handleIncomingAction($event)"
    ></app-status-messages>
    <div class="modal-header text-center">
      <h4 class="modal-title w-100 font-weight-bold">Add Option</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="addOptionModal.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body m-3 p-0">
      <div class="row flex-add-form">
        <form
          class="text-center border border-light p-5 edit-form w-100"
          [formGroup]="optionForm"
          (ngSubmit)="clickAddPersona()"
          autocomplete="off"
        >
          <div class="md-form mb-5 form-group">
            <input
              type="text"
              id="label"
              formControlName="label"
              class="form-control"
              mdbInput
            />
            <label for="label">Label</label>
          </div>
          <div class="md-form mb-5 form-group">
            <input
              type="text"
              id="name"
              formControlName="name"
              class="form-control"
              mdbInput
            />
            <label for="name">Name</label>
          </div>
          <div class="md-form mb-5 form-group">
            <input
              type="text"
              id="value"
              formControlName="value"
              class="form-control"
              mdbInput
            />
            <label for="value">Value</label>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
  
      <button
        mdbBtn
        class="waves-light theme-action-button"
        mdbWavesEffect
        (click)="addOptionModal.hide()"
      >
        Close
      </button>
      <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="clickAddPersona()"
    >
      Add Option
    </button>
    </div>
  </div>
  