<div class="modal-header text-center">
  <h4 class="modal-title w-100">Add Event Type</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="eventTypeFrame.hide()"
    mdbTooltip="Close"
    placement="bottom"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body mt-3 p-0">
  <div class="row mb-3">
    <div class="col-12">
      <form [formGroup]="eventTypesForm">
        <div class="md-form">
          <input
            type="text"
            id="eventLabel"
            class="form-control"
            formControlName="label"
            mdbInput
          />
          <label for="eventLabel">Label</label>
        </div>
        <div class="md-form">
          <input
            type="text"
            id="eventName"
            class="form-control"
            formControlName="name"
            mdbInput
          />
          <label for="eventName">Name</label>
        </div>
        <div class="md-form">
          <input
            type="text"
            id="eventDescription"
            class="form-control"
            formControlName="description"
            mdbInput
          />
          <label for="eventDescription">Description</label>
        </div>
        <div class="md-form">
          <mdb-select
            [options]="timeBoundariesSelect"
            id="boundEvent"
            formControlName="time_boundaries"
            mdbInput
            placeholder="Select"
          ></mdb-select>
          <label for="boundEvent">Time Boundaries</label>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    size="md"
    color="link"
    data-dismiss="modal"
    (click)="eventTypeFrame.hide()"
  >
    Close
  </button>
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    size="md"
    color="link"
    data-dismiss="modal"
    (click)="addEventTypes()"
  >
    Add
  </button>
</div>
