import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { AddSurveyQuestionModalComponent } from '../add-survey-question-modal/add-survey-question-modal.component';

@Component({
  selector: 'app-view-survey',
  templateUrl: './view-survey.component.html',
  styleUrls: ['./view-survey.component.scss']
})
export class ViewSurveyComponent implements OnInit {

  public survey: any;
  public teamID: number;
  public token: string;
  public user: any;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Survey",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();

  public addSurveyQuestionModal: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-add-room',
    containerClass: '',
    animated: true,
    data: {},
  };
  
  constructor(
    public viewSurveyModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private modalService: MDBModalService,
  ) { }

  ngOnInit(): void {
  }

  public openAddSurveyQuestionModal(survey: any)
  {
    this.modalOptions.data = {
      survey: survey,
      teamID: this.teamID
    };

    this.modalOptions.class = ''
    this.addSurveyQuestionModal = this.modalService.show(
      AddSurveyQuestionModalComponent,
      this.modalOptions
    );

    this.addSurveyQuestionModal.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.survey.questions.push(changedData.question)
            break;
          case "update":
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }

}
