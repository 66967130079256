import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-manage-global-experience",
  templateUrl: "./manage-global-experience.component.html",
  styleUrls: ["./manage-global-experience.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageGlobalExperienceComponent implements OnInit {
  //incoming
  public teamID: number;
  public type: string;
  public targetExperience: any;

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Add",
    reset: "Add Another Experience",
    retry: "Retry",
  };

  private preSelected: {
    label: string;
    name: string;
    description: string;
  };

  public experienceGlobalForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public continueType: string;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Add an Experience",
  };
  public formState: string = "active";
  public token: string;
  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public experienceGlobalFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage
  ) {}

  ngOnInit(): void {
    this.continueType = "reset";

    if (this.type === "add") {
      this.continueType = "reset";
    } else if (this.type === "edit") {
      this.btnLabel.retry = "Continue Editing";
      this.continueType = "continue";
    }

    this.retrieveTeamID();
    this.retrieveToken();
    this.buildPreselected();
    this.makeValidatingForm();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private buildPreselected() {
    if (!this.targetExperience) {
      this.preSelected = {
        label: "",
        name: "",
        description: "",
      };
    } else {
      this.preSelected = {
        label: this.targetExperience.label,
        name: this.targetExperience.name,
        description: this.targetExperience.description,
      };
    }
  }

  private makeValidatingForm() {
    this.experienceGlobalForm = new UntypedFormGroup({
      label: new UntypedFormControl(this.preSelected.label),
      name: new UntypedFormControl(this.preSelected.name),
      description: new UntypedFormControl(this.preSelected.description),
    });
  }

  public addExperience() {
    let link = "/experience";
    let requestType = "post";
    let returnMessage = "added";
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Adding Experience</span>";
    let action = "add";
    if (this.type === "add") {
      this.continueType = "reset";
    } else if (this.type === "edit") {
      link = link + "/" + this.targetExperience.id;
      requestType = "put";
      this.msgs.processingMsg =
        "<span class='processing-msg'>Editting Experience</span>";
      returnMessage = "edited";
      action = "update";
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      label: this.experienceGlobalForm.value.label,
      name: this.experienceGlobalForm.value.name,
      description: this.experienceGlobalForm.value.description,
    };

    let addedexperienceGlobal = this._xrPlatformRestService.restfulAPIQuery(
      link,
      requestType,
      data,
      getOptions
    );

    addedexperienceGlobal.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        if (response.error !== 418) {
          this.msgs.statusMsg = "Experience successfully " + returnMessage;
          let outgoingData = {
            action: action,
            experience: response.experience,
          };
          this.outgoing.next(outgoingData);
        } else {
          this.msgs.processingMsg = "";
          this.btnLabel.retry = "Retry";
          this.msgs.errorMsg = response.status;
        }
      },
      (error) => {
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public resetForm() {
    this.experienceGlobalForm.reset();
    this.experienceGlobalForm.value.constrained = false;
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.experienceGlobalFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
