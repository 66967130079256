import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";

import { MDBModalRef, MDBDatePickerComponent } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { IMyOptions } from "ng-uikit-pro-standard";

import * as moment from "moment";
import "moment-timezone";
import { DateFormatPipe } from "ngx-moment";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { UserServicesService } from "src/app/modules/user-management/services/user-services.service";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-schedule-user",
  templateUrl: "./schedule-user.component.html",
  styleUrls: ["./schedule-user.component.scss"],
  providers: [DateFormatPipe, TitleCasePipe],
})
export class ScheduleUserComponent implements OnInit {
  @ViewChild("datePickerStart", { static: false })
  datePickerStart: MDBDatePickerComponent;
  public labels: any;
  private token: string;
  public teamID: number;
  private outgoing: Subject<any> = new Subject();

  public experience: any;
  public targetUser: any;
  public action: string;
  public viewOnly: { user: string; date: string };
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another User",
    retry: "Retry",
  };
  public continueType: string;
  public preSelected: {
    id: any;
    name: any;
    date: any;
    schedule_id: any;
  } = {
    id: null,
    name: null,
    date: null,
    schedule_id: null,
  };
  public scheduleForm: UntypedFormGroup;
  public selectUsers: any[];
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "ddd, d mmm yyyy",
  };

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Schedule a User for the experience:",
  };
  public formState: string = "active";

  public loading: boolean;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _userServices: UserServicesService,
    public scheduleFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private amDateFormat: DateFormatPipe,
    private TitleCase: TitleCasePipe
  ) {}

  ngOnInit() {
    this.loading = true;
    this.preSelected.name =
      this.targetUser === undefined
        ? null
        : this.targetUser.first_name + " " + this.targetUser.last_name;
    this.preSelected.id =
      this.targetUser === undefined ? null : this.targetUser.id;
    this.preSelected.date =
      this.targetUser === undefined
        ? null
        : this.targetUser.expiration === undefined
        ? new Date(this.experience.endDate)
        : new Date(this.targetUser.expiration);
    this.preSelected.schedule_id =
      this.targetUser === undefined ? null : this.targetUser.schedule_id;
    this.viewOnly = {
      user: this.targetUser === undefined ? null : "disabled",
      date:
        this.action === "delete" || this.action === "remove"
          ? "disabled"
          : null,
    };

    

    this.continueType = "reset";
    this.retrieveLabels();
    if (this.action) {
      switch (this.action) {
        case "update":
          this.msgs.actionMsg =
            `Update ${this.labels.user.singular} for the following ${this.labels.event.singular}: `;
          this.btnLabel.main = "Update";
          this.continueType = "continue";
          break;
        case "delete":
          this.msgs.actionMsg =
            `Are you sure you want to delete the following ${this.labels.event.singular} for this ${this.labels.user.singular}?`;
          this.btnLabel.main = "Delete";
          this.continueType = "none";
          break;
        case "remove":
          this.msgs.actionMsg =
            "Are you sure you want to remove the scheduled "+ this.labels.user.singular+" for the following "+this.labels.event.singular+"?";
          this.btnLabel.main = "Remove";
          this.continueType = "none";
          break;
      }
    }
    
    this.retrieveToken();
    if (this.action === "remove") {
      this.makeFormValidatingGroup();
    } else {
      this.retrieveUsers();
    }
  }
  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options
    );

    retrieveUsers.subscribe(
      (response) => {
        this.processUsers(response);
        
      },
      (error) => {
        ;
      }
    );
  }

  private processUsers(response) {
    let usersSelect = [];
    let existingUsers = this.experience.scheduled_users;

    response.forEach((element) => {
      let check = false;
      existingUsers.forEach((existing) => {
        if (existing.id === element.id) {
          check = true;
        }
      });

      if (check) return false;

      let thisUserSelect = {
        value: element.id,
        label: element.first_name + " " + element.last_name,
      };

      usersSelect.push(thisUserSelect);
    });

    this.selectUsers = usersSelect;
    this.makeFormValidatingGroup();
  }

  private makeFormValidatingGroup() {
    this.scheduleForm = new UntypedFormGroup({
      scheduleUser: new UntypedFormControl(this.preSelected.id, Validators.required),
      scheduleDate: new UntypedFormControl(this.preSelected.date, Validators.required),
      scheduleID: new UntypedFormControl(this.preSelected.schedule_id),
    });

    this.loading = false;
  }

  get scheduleUser() {
    return this.scheduleForm.get("scheduleUser");
  }

  get scheduleDate() {
    return this.scheduleForm.get("scheduleDate");
  }

  public scheduleUsers() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.formState = "processing";

    
    if (this.action === "remove") {
      
      this.msgs.processingMsg =
        `<span class='loading-msg'>Removing ${this.TitleCase.transform(this.labels.user.singular)}</span>`;
      let user_id = this.targetUser.id;
      let removeList = [
        {
          scheduled_event_id: this.experience.schedule_id,
          invitee_type: "user",
          invitee_id: user_id,
        },
      ];

      let body = {
        entity: "schedule",
        entity_id: this.experience.schedule_id,
        toRemove: removeList,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      const options = {
        headers: headers,
        schedule_id: this.experience.schedule_id,
      };

      //if user already exists via a group, we need to update meta
      let retrieveScheduledEvent =
        this._userServices.retrieveScheduledEventByID(
          this.experience.schedule_id,
          options
        );

      retrieveScheduledEvent
        .then((response) => {
          let scheduled_event = response.scheduled_event;
          let hit = false;

          scheduled_event.invitees.forEach((invitee) => {
            if (
              invitee.invitee_id !== user_id ||
              invitee.invitee_type !== "user"
            )
              return false;

            if (
              invitee.meta !== undefined &&
              invitee.meta !== null &&
              invitee.meta.groups !== undefined &&
              invitee.meta.groups.length
            ) {
              hit = true;
              let meta = invitee.meta;
              meta.individual = false;

              let toUpdate = [
                {
                  scheduled_event_id: this.experience.schedule_id,
                  invitee_type: "user",
                  invitee_id: user_id,
                  meta: meta,
                },
              ];

              this.updateUserMeta(
                toUpdate,
                this.experience.schedule_id,
                user_id,
                options
              );
            }
          });

          if (!hit) this.removeUsersFromClass(body, options, user_id);
        })
        .catch ((err) => {
          this.msgs.processingMsg = "";
          this.btnLabel.retry = "Retry";
          let errorMsg = JSON.parse(err._body);
          this.msgs.errorMsg = errorMsg.error;
        });
    } else {
      this.userActions();
    }
  }

  private removeUsersFromClass(body, options, user_id) {
    let removeUsers = this._xrPlatformRestService.scheduledExperiences(
      "remove",
      body,
      options
    );

    
    removeUsers.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.user.singular)} successfully removed`;
        this.formState = "success";

        let outgoingData = {
          action: this.action === undefined ? "new" : this.action,
          scheduled_event: response.scheduled_event,
          user_id: user_id,
        };
        this.outgoing.next(outgoingData);
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg =
          `There was an issue with adding this ${this.labels.user.singular}, please try again.`;
      }
    );
  }

  private updateUserMeta(toUpdate, entity_id, user_id, options) {
    let body = {
      entity: "schedule",
      entity_id: entity_id,
      toUpdate: toUpdate,
    };

    let updateUserinGroup = this._xrPlatformRestService.scheduledExperiences(
      "invitee-meta-update",
      body,
      options
    );

    updateUserinGroup.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.user.singular)} successfully removed`;
        this.formState = "success";

        let outgoingData = {
          action: this.action === undefined ? "new" : this.action,
          scheduled_event: response.scheduled_event,
          user_id: user_id,
        };
        this.outgoing.next(outgoingData);
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg =
        `There was an issue with adding this ${this.labels.user.singular}, please try again.`;
      }
    );
  }

  public userActions() {
    let incomingValues = this.scheduleForm.value;
    let body = {};
    let action = "new-scheduled-event";
    let today = moment();
    let todayFormatted =
      today.format("ddd, DD MMM YYYY HH:mm:ss") +
      " " +
      moment.tz(moment.tz.guess()).format("z");
    let endDateFormatted =
      incomingValues.scheduleDate +
      " 23:59:59 " +
      moment.tz(moment.tz.guess()).format("z");
    let formValues = {};

    if (this.targetUser === undefined) {
      this.msgs.processingMsg =
        `<span class='loading-msg'>Scheduling ${this.TitleCase.transform(this.labels.user.singular)}</span>`;
      formValues = {
        resource_type: "single_experience_user",
        resource_id: this.experience.id,
        event_name: this.experience.name,
        start_at: todayFormatted,
        end_at: endDateFormatted,
        invitees: [
          {
            invitee_type: "team",
            invitee_id: this.teamID,
          },
          {
            invitee_type: "user",
            invitee_id: incomingValues.scheduleUser,
            meta: {
              individual: true,
            },
          },
        ],
      };

      body = JSON.stringify(formValues);
    } else if (this.action === "update") {
      this.msgs.processingMsg =
        `<span class='loading-msg'>Updating ${this.TitleCase.transform(this.labels.user.singular)}</span>`;
      formValues = {
        resource_type: "single_experience_user",
        resource_id: this.experience.id,
        start_at: todayFormatted,
        end_at: endDateFormatted,
      };

      body = JSON.stringify(formValues);

      action = this.targetUser.schedule_id;
    } else if (this.action === "delete") {
      this.msgs.processingMsg =
        `<span class='loading-msg'>Removing ${this.TitleCase.transform(this.labels.user.singular)}</span>`;
      body = {
        entity: "schedule",
        entity_id: this.targetUser.schedule_id,
      };
      action = "delete";
    } else if (this.action === "remove") {
      this.msgs.processingMsg =
        `<span class='loading-msg'>Removing ${this.TitleCase.transform(this.labels.user.singular)}</span>`;
      let removeList = [
        {
          scheduled_event_id: this.experience.schedule_id,
          invitee_type: "user",
          invitee_id: this.targetUser.id,
        },
      ];

      body = {
        entity: "schedule",
        entity_id: this.experience.schedule_id,
        toRemove: removeList,
      };
      action = "remove";
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    ;

    let scheduleUser = this._xrPlatformRestService.scheduledExperiences(
      action,
      body,
      options
    );

    scheduleUser.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        if (this.action === "update") {
          this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.user.singular)} successfully updated`;
          this.btnLabel.retry = "Continue Editing";
        } else if (this.action === "delete") {
          this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.user.singular)} successfully removed`;
        } else {
          this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.user.singular)} scheduled successfully`;
        }
        this.formState = "success";
        let outgoingData = {
          action: this.action === undefined ? "new" : this.action,
          scheduled_event: response.scheduled_event,
          user_id: this.targetUser.id,
        };
        this.outgoing.next(outgoingData);
      },
      (err) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  private addUsersToClass(body, options, user_id, message?) {
    let scheduleUser = this._xrPlatformRestService.scheduledExperiences(
      "schedule-invitee",
      body,
      options
    );

    if (message === undefined)
      message = {
        content: "User successfully added to class",
        action: "add",
      };

    scheduleUser.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.statusMsg = message.content;
        this.formState = "success";

        if (message.action === "remove") {
          let outgoingData = {
            action: "remove",
            scheduled_event: response.scheduled_event,
            user_id: user_id,
          };
          this.outgoing.next(outgoingData);
        }
      },
      (err) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
        ;
      }
    );
  }

  public resetForm() {
    this.scheduleForm.reset();
    this.formState = "active";
    this.datePickerStart.clearDate();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.formState = "active";
  }

  public handleIncomingAction(action) {
    ;
    switch (action) {
      case "close-modal":
        this.scheduleFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
