import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";

//third-party imports
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";

//local services
import { ScheduleUserComponent } from "../schedule-user/schedule-user.component";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { CoolLocalStorage } from "@angular-cool/storage";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageUsersComponent implements OnInit {
  //incoming
  public targetEvent: any;
  public thisUser: any;
  public teamID: number;
  public clientSettings: any;

  //outgoing
  private outgoing: Subject<any> = new Subject();

  //settings
  public showRoles = false;
  public showPersonas = false;

  public invitees: any;
  public labels: any;
  public title = "Manage users";
  public errorMsg: string = "";
  public statusMsg: string = "";
  public actionMsg: string = "Add or remove media from class";
  public formState: string = "active";
  public formLoading: boolean = false;

  public scheduledTableHeaders: string[] = [
    "First",
    "Last",
    "Email",
    "Username",
  ];

  public userManageFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  //btn labels
  public btnLabel: { close: string } = {
    close: "Close",
  };

  public token: string;

  constructor(
    private modalService: MDBModalService,
    public manageUsersFrame: MDBModalRef,
    public editPersonaRoleModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.showRoles = this.clientSettings.showRole.unconstrained_default;
    this.showPersonas = this.clientSettings.showPersona.unconstrained_default;

    this.retrieveLabels();
    this.retrieveToken();
    this.configureHeaders();
    this.setupUsers();
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private configureHeaders() {
    if (this.showRoles)
      this.scheduledTableHeaders.push(this.labels.eventRoles.singular);
    if (this.showPersonas)
      this.scheduledTableHeaders.push(this.labels.eventPersona.singular);
  }

  public openUsersModal(experience, scheduledUser?, action?) {
    this.modalOptions.data = {
      experience: experience,
      user: this.thisUser,
      targetUser: scheduledUser,
      action: action,
    };

    this.userManageFrame = this.modalService.show(
      ScheduleUserComponent,
      this.modalOptions
    );

    this.userManageFrame.content.outgoing.subscribe((result: any) => {
      let thisEvent = result.scheduled_event;
      let user_id = result.user_id;

      if (result.action === "update") {
        this.targetEvent.scheduled_users.forEach((user, index) => {
          if (user.schedule_id === thisEvent.id) {
            if (user.invitee_set_id === thisEvent.invitees[0].id) {
              user.expiration = thisEvent.end_at;
            }
          }
        });
      } else if (result.action === "remove") {
        ;
        ;
        this.invitees.forEach((user, index) => {
          if (user.invitee_id === user_id) {
            this.targetEvent.scheduled_users.splice(index, 1);
            this.invitees.splice(index, 1);
            this.targetEvent.userCount--;
            let outgoing = {
              action: "remove",
              type: "user",
              targetEvent: this.targetEvent,
            };
            this.outgoing.next(outgoing);
          }
        });
      }
    });
  }

  private setupUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    ;
    let eventRequest = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/" + this.targetEvent.id,
      "get",
      {},
      getOptions
    );

    eventRequest.subscribe(
      (response) => {
        ;
        this.invitees = this.processUsers(response.scheduled_event.invitees);
      },
      (error) => {}
    );
  }

  private processUsers(invitees) {
    let inviteesOut = [];

    invitees.forEach((invitee) => {
      if (invitee.invitee_type === "user") {
        //checks to see if user is part of a group
        //group users are viewed in a different modal
        let groupUserOnly = false;

        if (
          invitee.meta !== undefined &&
          invitee.meta !== null &&
          invitee.meta.groups !== undefined &&
          invitee.meta.groups.length
        ) {
          if (invitee.meta.individual === undefined || !invitee.meta.individual)
            groupUserOnly = true;
        }

        if (invitee.as_individual === false) groupUserOnly = true;

        if (groupUserOnly) return;

        inviteesOut.push(invitee);
      }
    });

    ;

    return inviteesOut;
  }
}
