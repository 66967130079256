import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  SimpleChange,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
} from "@angular/core";
import { ManageMediaViewComponent } from "./modals/manage-media-view/manage-media-view.component";
//third party services
import {
  MdbTableDirective,
  MDBModalRef,
  MDBModalService,
  IMyOptions,
} from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { ManageMediaSharingComponent } from "./modals/manage-media-sharing/manage-media-sharing.component";
import { ManageMediaUploadsComponent } from "./modals/manage-media-uploads/manage-media-uploads.component";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ManageMediaRemovalComponent } from "./modals/manage-media-removal/manage-media-removal.component";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { Observable, Subject, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MediaServicesV3Service } from "../../../services/media.services.v3.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { UntypedFormControl, UntypedFormGroup, NgModel } from "@angular/forms";
import { debounceTime, distinctUntilChanged, skip } from "rxjs/operators";
import { EventMediaVersion3Service } from "src/app/modules/event-management/services/event.media.version.3.service";
import { ManageMediaUploadsModelViewerComponent } from "./modals/manage-media-uploads-model-viewer/manage-media-uploads-model-viewer.component";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";

import * as moment from "moment";
import { DateTime } from "luxon";
import * as timezoneFixes from "src/assets/timezones/ianaToAbbreviation.json";

import {
  faFilter,
  faFilterCircleXmark,
  faSquareMinus,
  faSquarePlus,
  faPencilAlt,
  faImages,
  faUsers,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faCopy,
  faTimes,
  faInfoCircle,
  faShareAlt,
  faCalendarPlus,
  faMagnifyingGlass,
  faCircle,
  faUpload
} from "@fortawesome/free-solid-svg-icons";

import { faCircle as faCircleInverse } from "@fortawesome/free-regular-svg-icons";
import { ManageMediaEventsComponent } from "./modals/manage-media-events/manage-media-events.component";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { ManageMediaChangeComponent } from "./modals/manage-media-change/manage-media-change.component";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-media-manager-table-view",
  templateUrl: "./media-manager-table-view.component.html",
  styleUrls: ["./media-manager-table-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MediaManagerTableView implements OnInit, OnDestroy {
  @ViewChild("row", { static: true }) row: ElementRef;

  @Input() actionButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
      info: false,
      share: false,
      delete: false,
    };

  @Input() columnVisibility: {
    thumbnail: boolean;
    pdfStatus: boolean;
    fileType: boolean;
    owner: boolean;
    urlType: boolean;
    dateCreated: boolean;
    shares: boolean;
  } = {
      thumbnail: true,
      pdfStatus: false,
      fileType: true,
      owner: true,
      urlType: false,
      dateCreated: true,
      shares: false,
    };

  @Input() selectable: boolean = false;

  @Input() selectedMedia: any = [];
  private newleySelected: any = [];

  @Input() defaultTab: string = "route";

  //also track removals
  public selectedRemovals: any = [];

  public currentPreviewMedia: any = null;
  public selectedIDs: number[] = [];

  @Output() selected = new EventEmitter();

  @Input() filterByType: string = "all";
  @Input() ownerID: any = -1;
  public ownerForm: UntypedFormGroup;

  @Input() selectableType: string = "single";

  @Input() clickableThumb: boolean = true;

  @Input() systemButtons: any = {
    action: null,
    cancel: null,
  };

  @Input() systemInteraction: string = "none";

  @Input() TargetEntity: any = null;

  @Input() attached: any = [];

  @Input() tab_current_page: number;
  @Input() tab_current_total: number;

  //fa icons
  public faFilter = faFilter;
  public faFilterCircleXmark = faFilterCircleXmark;
  public faSquareMinus = faSquareMinus;
  public faPencilAlt = faPencilAlt;
  public faImages = faImages;
  public faUsers = faUsers;
  public faTrashAlt = faTrashAlt;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public faCopy = faCopy;
  public faTimes = faTimes;
  public faInfoCircle = faInfoCircle;
  public faShareAlt = faShareAlt;
  public faCalendarPlus = faCalendarPlus;
  public faMagnifyingGlass = faMagnifyingGlass;
  public faSquarePlus = faSquarePlus;
  public faCircle = faCircle;
  public faCircleInverse = faCircleInverse;
  public faUpload = faUpload;

  public tz: any;

  //visibility control
  public isModerator: boolean = false;
  public isParticipant: boolean = false;

  /***TURN THIS OFF WHEN PDF RENDERING IS LIVE**/
  public pdfRenderingLive: boolean = false;

  public returnMedia: {
    name: string;
    date: string;
    meta: any;
    thumbnail_url: string;
    file_ext: string;
    id: number;
  };

  public waitForChanges: boolean = false;

  public first: number = 0;
  public rows: number = 20;

  headElements = [
    "Media Visibility",
    "",
    "File Name",
    "Size",
    "File Type",
    "Owner",
    "Uploaded On",
  ];
  sort_elements = [
    {
      type: "none",
      head: "action-icons",
    },
    {
      type: "none",
      head: "thumbnail-container",
    },
    {
      type: "filename",
      head: "name",
    },
    {
      type: "filesize",
      head: "size",
    },
    {
      type: "filetype",
      head: "type",
    },
    {
      type: "owner",
      head: "owner_username",
    },
    {
      type: "date",
      head: "created",
    },
  ];
  sort = {
    direction: "asc",
    head: "name",
    type: "string",
  };

  filter_elements = [
    {
      filter: true,
      disabled: false,
      type: "media_select",
      target: "visibility",
      options: [{
        "label": "All Media",
        "value": "all"
      },
      {
        "label": "Private Media",
        "value": "private"
      },
      {
        "label": "Public Media",
        "value": "public"
      }],
      default: "all",
    },
    {
      filter: true,
      disabled: false,
      type: "media_select_added",
      target: "added",
      placeholder: "",
      default: "",
    },
    {
      filter: true,
      disabled: false,
      type: "text",
      target: "name",
      placeholder: "Enter File Name",
      default: "",
    },
    {
      filter: true,
      disabled: false,
      type: "media_select",
      target: "size",
      options: [{
        "label": "All Sizes",
        "value": "all"
      },
      {
        "label": "Below 1mb",
        "value": "below_1mb"
      },
      {
        "label": "1 - 5mb",
        "value": "1_5mb"
      },
      {
        "label": "5 - 10mb",
        "value": "5_10mb"
      },
      {
        "label": "10 - 50mb",
        "value": "10_50mb"
      },
      {
        "label": "50 - 100mb",
        "value": "20_50mb"
      },
      {
        "label": "Above 100mb",
        "value": "above_100mb"
      }],
      default: "all",
    },
    {
      filter: true,
      disabled: false,
      type: "media_select",
      target: "type",
      options: [],
      default: "all",
    },
    {
      filter: true,
      disabled: false,
      type: "media_autocomplete",
      target: "owner_username",
      default: -1,
    },
    {
      filter: true,
      disabled: false,
      type: "created",
      target: "created",
      default: "all",
    },
  ];

  //table filtering
  public filterText: {
    visibility: string;
    name: string;
    size: string;
    type: string;
    owner_username: number;
    created: string;
  } = {
      visibility: "all",
      name: "",
      size: "all",
      type: "all",
      owner_username: -1,
      created: null,
    };

  //added filter for multi-select
  public addedFilterText = "added";
  public addedFilterOptions = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Added",
      value: "added",
    }
  ]

  public createdCalendarOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d yyyy",
    alignSelectorRight: true
  };

  public sortBy = "created";
  public sortOrder = "desc";
  public sortingActive: boolean = false;
  @Input() tableLoading: any;

  backpackElements = [];
  sortBackpack = ["", "name", "file_ext", "created", ""];

  searchText: string = "";
  previous: string;

  @Input() media: any[];
  @Input() guardrailSettings: any;
  @Input() errorRetrievingMsg: string;
  @Input() emptyMedia: any;
  @Input() mediaSharing: any;
  @Input() loadMore: boolean;
  @Input() quantityChange: boolean = false;
  @Input() loading: {
    //keep track of tab loading
    allMedia: boolean;
  } = {
      allMedia: true,
    };

  @Output("updatedMedia") updatedMedia = new EventEmitter();
  @Output("sortChange") sortChange = new EventEmitter();
  @Output("loadMoreEvent") loadMoreEvent = new EventEmitter();

  //separate data holder for table data
  //this avoids errors with search filtering
  public tableMedia: any[];
  public labels: any;
  public token: string;

  public mediaFrame: MDBModalRef;

  public user: any;

  //owner autocomplete
  public ownerText = new Subject();
  ownerResults: Observable<{ label: string }[]>;

  //loading flag
  public mediaLoading: boolean = false;
  public filtersLoading: boolean = true;

  //conditions
  private tableSearchConfigured: boolean = false;

  //modal frames
  public modalFrame: MDBModalRef;
  public attachedEventsModal: MDBModalRef;
  public deleteFrame: MDBModalRef;
  public sharedMediaModal: MDBModalRef;

  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {}
  };
  @Output() filterChange = new EventEmitter();
  ngModelChangeDebounceTime = 500;
  subscription: Subscription;
  private filterSubject = new Subject();

  public clientSettings: any;
  private timeoutID: any;

  public title: string;
  public message: string;

  public filterData: any;
  private ownersOrig: any;
  public selectedFileType: string = this.filterByType;
  public selectedDate: string;
  public selectedOwner: number;
  // pdf render
  public processing: boolean;

  private mdbTable: MdbTableDirective;

  @Input() teamID: number;
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";
  public placeHolderMp3: string = "assets/img/mp3-thumbnail.png";
  public placeHolderWav: string = "assets/img/wav-thumbnail.png";

  /**
  
   * Our viewChild uses a setter that detects when the child content is available
   */
  @ViewChild(MdbTableDirective, { static: false }) set Content(
    content: MdbTableDirective
  ) {
    if (content) {
      this.mdbTable = content;
      //once the content is available, we then setup the search config
      if (!this.tableSearchConfigured) {
        this.tableSearchConfigured = true;
        this.searchTableConfig();
      }
    }
  }

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private modalService: MDBModalService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    private mediaServicesV3Service: MediaServicesV3Service,
    private _settingsService: SettingsService,
    private eventMediaVersion3Service: EventMediaVersion3Service,
    private _sharedDataService: SharedDataService,
    private _notificationService: NotificationsService,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscription = this.filterSubject
      .pipe(
        skip(1), // skip initial value
        distinctUntilChanged(),
        debounceTime(this.ngModelChangeDebounceTime)
      )
      .subscribe((value) => this.filterChange.emit(value));
  }

  ngOnInit(): void {
    this.resolveTimeZone();

    //added filter changes to all if targetEntity is undefined
    if (this.TargetEntity === undefined) this.addedFilterText = "all";

    if (this.filterByType !== "all") this.filterText["type"] = this.filterByType;

    this.initActions();
    this.searchTableConfig();
    this.getFilterSettings();
  }

  private initActions() {
    this.retrieveLabels();
    this.retrieveToken();
    this.retrieveUser();

    if (this.user.role_type_id === 3) this.isModerator = true;
    if (this.user.role_type_id === 4 || this.user.role_type_id === 6) {
      this.isParticipant = true;
      this.filterText["visibility"] = "private";

      this.filter_elements = this.filter_elements.map((filter_element) => {
        if (filter_element.target === "visibility") {
          filter_element.disabled = true;
        }

        return filter_element;
      });

    }

    if (this.teamID == null) {
      this.retrieveTeamID();
    }

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );
    this.title = "";
    this.message = "";

    if (this.selectable) {
      if (this.selectableType === "multiple" && !this.selectedMedia.length && this.attached.length && this.attached[0] !== null) {
        this.selectedMedia = this.attached;
      }

      //configure selected media with selected prop
      this.selectedMedia.forEach((mediaItem, index) => {
        mediaItem.selected = true;
      });

      console.log("this.selectedMedia in initActions", JSON.parse(JSON.stringify(this.selectedMedia)));

      if (this.selectedMedia.length) {
        this.setSelected();
      }
    }

    console.log("this.media in initActions", this.media);

    //table data is the same as grid data
    //however parsing avoids issues with search filtering
    this.tableMedia = this.media;
  }

  private resolveTimeZone() {
    let tz_iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.tz = moment.tz(tz_iana).format("z");

    //if the string includes a negative number in the form -03 or -02, check for a timezone fix
    if (this.tz.includes("-")) {
      let fix = timezoneFixes[tz_iana];

      if (fix !== undefined) {
        this.tz = fix;
      } else {
        //fall back to UTC from Luxon
        this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
      }
    } else if (this.tz.includes("+")) {
      //fall back to UTC from Luxon
      //@todo: update timezone fixes to include plus returns
      this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
    }
  }

  private setActionButtons() {

    if (this.selectable) return;

    let actionButtonsChange = {};

    actionButtonsChange = {
      type: "actionButtonsChange",
      data: {
        actions: [
          {
            action: "upload_media",
            type: "uploadMedia",
            title: "Upload Media",
          }
          // {
          //   action: "upload_3d_object",
          //   type: "upload3DObject",
          //   title: "Upload 3D Object",
          // },
        ],
      },
    };

    this._sharedDataService.sendSharedData(actionButtonsChange);
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private setSelected() {
    //setup our preview pane
    //this is a legacy event settings thing, can clean up once those settings are deprecated

    if (this.selectedMedia.length && this.selectedMedia[0] === null)
      this.selectedMedia = [];

    if (this.currentPreviewMedia === null && this.selectedMedia.length)
      this.currentPreviewMedia = this.selectedMedia[0];

    if (this.systemInteraction === "event_media" || this.systemInteraction === "settings_media_multiple") {
      this.newleySelected.forEach((mediaItem) => {

        let existing = this.selectedMedia.filter((selectedMediaItem) => {
          return selectedMediaItem.id === mediaItem.id;
        });

        if (!existing.length) {
          this.selectedMedia.push(mediaItem);
        }

        existing = this.media.filter((selectedMediaItem) => {
          return mediaItem.id === selectedMediaItem.id;
        });

        if (!existing.length) this.media.push(mediaItem);

      });
    } else if (this.systemInteraction === "settings_media") {

      //check to make sure selected media is in the media array
      this.selectedMedia.forEach((selectedMediaItem) => {
        let existing = this.media.filter((mediaItem) => {
          return mediaItem.id === selectedMediaItem.id;
        });

        if (!existing.length) this.media.unshift(selectedMediaItem);
      });

    }

    console.log("selectedMedia in setSelected after processing", JSON.parse(JSON.stringify(this.selectedMedia)));
    console.log("media in setSelected", JSON.parse(JSON.stringify(this.media)));

    let temp_media = [];
    this.media.forEach((thisMedia, index) => {

      thisMedia.selected = false;
      this.selectedMedia.forEach((element) => {
        if (thisMedia.id == element.id) thisMedia.selected = true;
      });
      temp_media.push(thisMedia);
    });

    console.log("temp_media in setSelected", temp_media)

    this.media = temp_media;
    this.tableMedia = this.media;
  }

  public previewThisMedia(mediaItem) {
    this.currentPreviewMedia = mediaItem;
  }

  ngOnChanges(changes: SimpleChange) {
    this.first = (this.tab_current_page - 1) * this.rows;

    let mediaChanges = changes["media"];

    //for deletions that happen elsewhere
    if (
      this.tableMedia !== undefined &&
      this.media.length < this.tableMedia.length
    ) {
      this.tableMedia = this.media;
      this.processOwners();
      this.setActionButtons();
    }

    if (mediaChanges && this.loadMore) {
      this.tableMedia = mediaChanges.currentValue;
      this.processOwners();
    }

    if (mediaChanges && this.quantityChange) {
      this.tableMedia = mediaChanges.currentValue;
      this.processOwners();
      this.quantityChange = false;
    }

    if (mediaChanges && this.sortingActive) {
      this.sortingActive = false;
      this.tableMedia = mediaChanges.currentValue;
      this.processOwners();
    }

    //to setup preview image
    if (this.selectedMedia.length || (this.selectedMedia.length === 0 && this.selectableType === "single")) {
      this.setSelected();
    }

    if (this.waitForChanges && !this.tableLoading) {
      this.waitForChanges = false;
      this.setActionButtons();
      this.initActions();
    }

  }

  public async openLink(uuid: string, type: string) {
    let downloadTarget =
      this._clientManagementService.getRESTurl("v1") +
      `/asset/download/${uuid}/public`;

    if (type === "private")
      downloadTarget = await this.retrievePrivateLink(uuid);

    window.open(downloadTarget, "_blank");
  }

  private retrievePrivateLink(uuid) {
    const headers = {
      "Content-Type": "text/html",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
      responseType: "text",
    };

    let getPrivateLink = this._xrPlatformRestService.retrieveEntityData(
      "asset",
      uuid + "/url",
      getOptions
    );

    return getPrivateLink.toPromise();
  }

  onFilterChange(resetPageNum) {

    //cheap debounce
    //@todo: find a more angular-centric way to do this
    if (this.timeoutID) window.clearTimeout(this.timeoutID);
    this.timeoutID = window.setTimeout(() => {

      let filterReq = {
        searchText: this.filterText["name"],
        fileType: this.filterText["type"],
        date: "all",
        uploadedOn: this.filterText["created"],
        owner: this.selectedOwner,
        resetPageNum: resetPageNum,
        eventID: -1,
        size: this.filterText["size"],
        visibility: this.filterText["visibility"],
      };

      if (this.selectable && this.addedFilterText === "added" && this.TargetEntity !== undefined && this.TargetEntity !== null) filterReq["eventID"] = this.TargetEntity.id;

      this.waitForChanges = true;
      this.filterChange.emit(filterReq);
    }, 250);
  }

  onDateChange(event) {
    this.filterText[
      "created"
    ] = `${event.date.year}-${event.date.month}-${event.date.day}`;

    console.log('this.filterText["created"]', this.filterText["created"]);

    this.onFilterChange(true);
  }

  onDateFocusBlur(event) {
    //console.log("event in onDateFocusBlur", event);
  }

  onPageChange(event) {
    const pageVals = {
      targetPage: event.page + 1,
    };

    this.loadMoreEvent.emit(pageVals);
  }

  /**
   * Reset specific filter ngModel filterText[filter_elements[i].target]
   * @param index
   */
  public resetFilter(index) {
    this.filterText[this.filter_elements[index].target] =
      this.filter_elements[index].default;
    this.onFilterChange(true);
  }

  public resetAddedFilter() {
    this.addedFilterText = "all";
    this.onFilterChange(true);
  }

  private getFilterSettings() {
    this.mediaServicesV3Service
      .getFilterSettings(this.token, this.teamID)
      .subscribe((res) => {

        this.filterData = res;
        this.ownersOrig = res.owners;
        this.ownersOrig.push({ label: "All Owners", value: -1 });
        this.processOwners();

        let selectedOwner =
          this.filterData.owners.length < 2
            ? this.filterData.owners[0]
            : null;

        this.ownerForm = new UntypedFormGroup({
          ownerSelection: new UntypedFormControl(selectedOwner),
        });

        this.ownerResults =
          this.ownerForm.controls.ownerSelection.valueChanges.pipe(
            startWith(selectedOwner),
            map((owner) => this.filterOwners(owner))
          );

        if (this.filterByType === "all")
          this.filterData.types.push({ label: "All Types", value: "all" });
        this.selectedOwner =
          this.ownerID === -1 ? this.ownerID : this.ownerID.id;
        this.selectedFileType = this.filterByType;

        if (this.filterByType !== "all") {
          let newTypesFilters = this.filterData.types.filter((thisFilter) => {
            return thisFilter.value === this.filterByType;
          });

          this.filterData.types = newTypesFilters;
        }

        //for filter_elements, assign this.filterData.types to the filter_element where filter_element.target === 'type'
        this.filter_elements.forEach((filter_element) => {
          if (filter_element.target === "type") {
            filter_element.options = this.filterData.types;
          }
        });

        this.filtersLoading = false;
      });
  }

  private processOwners() {
    if (this.filterData === undefined) return;

    if (this.ownerID === -1) {
      this.filterData.owners = this.ownersOrig;
    } else {

      let owners = []

      this.media.forEach((mediaItem) => {
        mediaItem.shared_with.forEach((sharedWith) => {

          //check to see if sharedWidth.user_id is already in owners
          let ownerExists = owners.filter((owner) => {
            return owner.value === sharedWith.user_id;
          });

          if (!ownerExists.length) {
            owners.push({
              label: sharedWith.username,
              value: sharedWith.user_id,
            });
          }

        });

      });

      this.filterData.owners = owners;
    }

  }

  private filterOwners(option): { label: string }[] {

    if (option === null || option === undefined) return this.filterData.owners;

    return this.filterData.owners.filter((owner) => {
      return owner.label
        .toLowerCase()
        .includes(
          option.label ? option.label.toLowerCase() : option.toLowerCase()
        )
    });
  }

  public onOwnerDisplayValue(owner): string | undefined {
    return owner ? owner.label : owner;
  }

  public onOwnerSelected(incoming) {
    this.selectedOwner = incoming.text.value;
    this.onFilterChange(true);
  }

  public onClearButtonClick() {

    this.selectedOwner = -1;
    this.ownerID = -1;
    this.onFilterChange(true);

  }

  public sortColumn(head) {
    let targetSort = ["Name", "Date Created"];
    let sort = false;

    if (targetSort.indexOf(head) > -1) sort = true;

    return sort;
  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction, type) {
    this.sortBy = head;
    this.sortOrder = direction;

    //alert parent of sorting changes
    let sortChange = {
      sortOrder: direction,
      sortBy: type,
    };

    this.sortingActive = true;

    this.sortChange.emit(sortChange);
  }

  retrieveAllMedia(reqObj) {
    this.mediaServicesV3Service
      .retrieveAllMedia(this.token, this.user.id, this.teamID, reqObj)
      .then((response) => {
        this.tableMedia = JSON.parse(JSON.stringify(response.media.media));
      });
  }

  /**
   * Process table search
   * @returns void
   */
  private searchItems() {
    //if we are in grid view, do not run this processing, the grid view is filtered using a pipe

    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.tableMedia = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.tableMedia = this.mdbTable.searchLocalDataByMultipleFields(
        this.searchText,
        ["name"]
      );
      this.mdbTable.setDataSource(prev);
    }
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  /**
   * Conditional search config depending on whether we are looking at the table view or the grid view
   * @returns false or void
   */
  private searchTableConfig() {
    if (this.mdbTable) {
      this.mdbTable.setDataSource(this.tableMedia);
      this.previous = this.mdbTable.getDataSource();
      //run search items in case search is already populated from grid view
      this.searchItems();
    }
  }

  public maybeClearOwners(event) {
    let currentValue = this.ownerForm.controls["ownerSelection"].value;

    if (
      currentValue !== null &&
      currentValue.label !== undefined &&
      currentValue.label !== null &&
      currentValue.label === "All Owners"
    ) {
      this.ownerForm.controls["ownerSelection"].setValue("");
    } else {
      //console.log("resetting owners maybeClearOwners")
      //this.maybeResetOwners(event);
    }
  }

  public maybeResetOwners(event) {
    let currentValue = this.ownerForm.controls["ownerSelection"].value;

    let selectedOwner =
      this.filterData.owners.length < 2
        ? this.filterData.owners[0]
        : { label: "All Owners", value: -1 };

    if ((event.relatedTarget === undefined || event.relatedTarget === null) && currentValue === "") {
      this.ownerForm.controls["ownerSelection"].setValue(selectedOwner);
    }
  }

  public openAlternateUploadModal() {

    let mainEventDisplay = this.document.getElementsByClassName(
      "event-media-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.data = {
      user: this.user,
      teamID: this.teamID,
      guardrailSettings: this.guardrailSettings,
      filterByType: this.filterByType
    };
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "upload-modal-container",
      class:
        this.modalOptions.class +
        " modal-full-height modal-right media-display",
    };

    this.modalFrame = this.modalService.show(
      ManageMediaUploadsComponent,
      this.modalOptions
    );

    //find document item with id mediaManagement and add class "uploading"
    let mediaManagement = document.getElementById("mediaManagement");
    mediaManagement.classList.add("uploading");

    this.modalFrame.content.outgoing.subscribe((result: any) => {

      //remove uploading class
      mediaManagement.classList.remove("uploading");

      if (result === "close") {

        //if the modal was closed without uploading, we need to remove the uploading class
        mediaManagement.classList.remove("uploading");

      } else {
        this.emptyMedia.allMedia = false;

        let outbound = {
          type: "add",
          asset: result.changedData.asset,
          thumbnail: null
        };

        if (this.systemInteraction === "event_media" || this.systemInteraction === "settings_media_multiple") {
          this.newleySelected.push(result.changedData.asset);

          let outbound = {
            selected: this.selectedMedia,
          };

          this.selected.emit(outbound);
        }

        if (result.action === "add_thumbnail") {
          outbound = {
            type: "add_thumbnail",
            asset: result.changedData.originalMedia.asset,
            thumbnail: result.changedData.thumbnail.asset
          }
        }

        this.updatedMedia.next(outbound);
      }
    });
  }

  public openViewModal(user, media_item, index) {
    let mainEventDisplay = this.document.getElementsByClassName(
      "event-media-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.data = {
      user: user,
      media_item: media_item,
      teamID: this.teamID,
      mediaSharing: this.mediaSharing,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "media-details-modal",
      class:
        this.modalOptions.class +
        " modal-full-height modal-right media-display",
    };

    if (this.clickableThumb) {
      this.modalFrame = this.modalService.show(
        ManageMediaViewComponent,
        this.modalOptions
      );
      this.modalFrame.content.outgoing.subscribe((result: any) => {
        if (result.action !== undefined) {
          switch (result.action) {
            case "update":

              let outbound = {
                type: "update",
                asset: result.changedData.asset,
                thumbnail: null
              };

              this.updatedMedia.next(outbound);

              break;
            case "update-thumbnail":
              this.media[index] = result.changedData;
              break;
            case "delete":
              this.media.splice(index, 1);
              if (!this.media.length) {
                this.emptyMedia.allMedia = true;
              }
              break;
          }
        }
      });
    }
  }

  public openAttachedEventsModal(media_item, index) {
    this.modalOptions.data = {
      media_item: media_item,
      teamID: this.teamID,
      token: this.token,
      labels: this.labels,
      action: "update"
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "media-attached-events-modal",
      class:
        this.modalOptions.class +
        " modal-full-height modal-right media-display",
    };

    this.attachedEventsModal = this.modalService.show(
      ManageMediaEventsComponent,
      this.modalOptions
    );

    this.attachedEventsModal.content.outgoing.subscribe((result: any) => {
      if (result.action === "update") {
        this.media[index].event_connections = result.event_connections;
      }
    });
  }

  public openShareModal(user, media_item) {
    this.modalOptions.data = {
      user: user,
      media_item: media_item,
      labels: this.labels,
      teamID: this.teamID,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "share-modal-container",
      class:
        this.modalOptions.class +
        " modal-full-height modal-right media-display",
    };

    this.sharedMediaModal = this.modalService.show(
      ManageMediaSharingComponent,
      this.modalOptions
    );

    this.sharedMediaModal.content.outgoing.subscribe((result: any) => {

      if (result.action === "updating_sharing") {
        this.media.forEach((mediaItem, index) => {
          if (mediaItem.id === media_item.id) {
            this.media[index].shared_with = result.shared_with;

            let outbound = {
              type: "update",
              asset: this.media[index],
            };

            this.updatedMedia.next(outbound);
          }
        });
      }
    });
  }

  public openModelViewerUpload() {
    this.modalOptions.data = {
      user: this.user,
      teamID: this.teamID,
    };
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "upload-modal-container",
    };

    this.modalFrame = this.modalService.show(
      ManageMediaUploadsModelViewerComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((result: any) => {
      this.emptyMedia.allMedia = false;

      let outbound = {
        type: "add",
        asset: result.changedData.asset,
      };

      this.updatedMedia.next(outbound);
    });
  }

  public changeVisibility(public_status, media_item) {
    this.modalOptions.containerClass = "";
    this.modalOptions.class = "modal-dialog modal-dialog-centered";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "delete-asset-container",
    };

    let values = {
      "public": public_status,
    }

    this.modalOptions.data = {
      targetMedia: media_item,
      values: values,
      teamID: this.teamID,
      clientSettings: this.clientSettings,
      title: `Make ${media_item.name} ${public_status ? "Public" : "Private"}?`,
      message: `Making this media item ${public_status ? "public" : "private"} will ${public_status ? "remove it from your private media and add it to the public library" : "remove it from the public library and add it to your private media"}.`,
      button: `Make ${public_status ? "Public" : "Private"}`,
    };

    this.mediaFrame = this.modalService.show(
      ManageMediaChangeComponent,
      this.modalOptions
    );

    this.mediaFrame.content.outgoing.subscribe((result: any) => {
      if (result.action !== undefined) {
        switch (result.action) {
          case "update":

            this._notificationService.successNotification("Media successfully updated");

            let outbound = {
              type: "update",
              asset: result.changedData.asset,
              thumbnail: null
            };

            this.updatedMedia.next(outbound);

            break;
        }
      }
    });
  }

  public clickDeleteAsset(media_item, index) {
    this.modalOptions.containerClass = "";
    this.modalOptions.class = "modal-dialog modal-dialog-centered";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "delete-asset-container",
    };
    this.modalOptions.data = {
      targetMedia: media_item,
      action: "delete",
      teamID: this.teamID,
      clientSettings: this.clientSettings,
    };

    this.mediaFrame = this.modalService.show(
      ManageMediaRemovalComponent,
      this.modalOptions
    );

    this.mediaFrame.content.outgoing.subscribe((result: any) => {
      this.media = this.media.filter((thisMedia) => {
        return thisMedia.id !== result.id;
      });
      this.tableMedia = this.media;

      let outbound = {
        type: "delete",
        asset: result,
      };

      this.updatedMedia.next(outbound);

      this._notificationService.successNotification("Media successfully deleted");

      if (!this.media.length) {
        this.emptyMedia.allMedia = true;
      }
    });
  }

  public renderPdf(uuid: string, i: number) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let renderElement = this._xrPlatformRestService.restfulAPIQuery(
      "/asset/render/" + uuid,
      "put",
      {},
      getOptions,
      "v1"
    );

    this.tableMedia[i].status = "Rendering";

    renderElement.subscribe(
      (response) => {
        this.tableMedia[i] = response.asset;
      },
      (error) => { }
    );
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) this.subscription.unsubscribe();
  }

  clickSelected(asset, index) {

    asset.selected = !asset.selected;

    if (asset.selected && this.selectedMedia.indexOf(asset) === -1 && this.newleySelected.indexOf(asset) === -1) {
      this.selectedMedia.push(asset);
      this.newleySelected.push(asset);
    } else {
      let updatedSelectedMedia = this.selectedMedia.filter((mediaItem) => {
        return mediaItem.id !== asset.id;
      });

      this.selectedMedia = updatedSelectedMedia;

      let updatedNewleySelected = this.newleySelected.filter((mediaItem) => {
        return mediaItem.id !== asset.id;
      });

      this.newleySelected = updatedNewleySelected;
    }

    let outbound = {
      selected: this.selectedMedia,
    };

    this.selected.emit(outbound);

  }

  public clickSingleSelected(asset, index) {
    asset.selected = !asset.selected;

    //set all other assets to selected false
    this.media.forEach((mediaItem) => {
      if (mediaItem.id !== asset.id) mediaItem.selected = false;
    });

    this.selectedMedia = [asset];

    let outbound = {
      selected: this.selectedMedia,
    };

    this.currentPreviewMedia = asset;

    this.selected.emit(outbound);
  }

  //@todo: figure out why currentPreviewMedia is undefined here and fix
  public processClass(img, i) {
    //{'selected-media': img.id === currentPreviewMedia.id}

    if (
      this.currentPreviewMedia !== undefined &&
      this.currentPreviewMedia !== null
    )
      if (img.id === this.currentPreviewMedia.id) return "selected-media";
  }

  public async clickActionButton() {
    if (this.systemInteraction == "event_media" || this.systemInteraction == "settings_media_multiple") {

      let eventMedia = await this.eventMediaVersion3Service
        .manageMedia(
          this.token,
          this.TargetEntity,
          this.setupSelectedMedia(),
        )


    } else if (this.systemInteraction == "settings_media") {
      let asset = null;
      this.media.forEach((el) => {
        if (el.selected == true) {
          asset = el;
        }
      });
      if (asset != null) {
        this.selected.emit(asset);
      }
    }
  }

  private setupSelectedMedia() {
    let temp_selected = [];
    this.selectedMedia.forEach((element) => {
      temp_selected.push({
        id: element.id
      });
    });

    return temp_selected;
  }

  clickCancelButton() { }
}
