<div class="class-wrapper">
    <app-status-messages
      [msgs]="msgs"
      [btnLabel]="btnLabel"
      [continueType]="continueType"
      (targetAction)="handleIncomingAction($event)"
    ></app-status-messages>
    <div class="modal-header text-center">
      <h4 class="modal-title w-100 font-weight-bold">Add {{ type }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="addInventoryEntityModal.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body m-3 p-0">
      <div class="row flex-add-form">
        <form
          class="text-center border border-light p-5 edit-form w-100"
          [formGroup]="entityForm"
          (ngSubmit)="clickAddInventoryEntity()"
          autocomplete="off"
        >
          <div class="md-form mb-5 form-group">
            <input
              type="text"
              id="entityname"
              formControlName="entityname"
              class="form-control"
              mdbInput
            />
            <label for="entityname">{{ type }} Name</label>
          </div>
          <div class="md-form mb-5 form-group">
            <input
              type="text"
              id="entitydescription"
              formControlName="entitydescription"
              class="form-control"
              mdbInput
            />
            <label for="entitydescription">{{ type }} Description</label>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
      
      <button
        mdbBtn
        class="waves-light theme-action-button"
        mdbWavesEffect
        (click)="addInventoryEntityModal.hide()"
      >
        Close
      </button>
      <button
        mdbBtn
        class="waves-light theme-action-button"
        mdbWavesEffect
        (click)="clickAddInventoryEntity()"
      >
        Add {{ type }}
      </button>
    </div>
  </div>
