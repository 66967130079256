import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: 'app-add-inventory-modal',
  templateUrl: './add-inventory-modal.component.html',
  styleUrls: ['./add-inventory-modal.component.scss']
})
export class AddInventoryModalComponent implements OnInit {

  public team_id: number;
  public token: string;
  public type: string;

  public entityForm = this.formBuilder.group({
    entityname: "",
    entitydescription: "",
  });

  public continueType: string;

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Role",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();

  constructor(
    public addInventoryEntityModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.continueType = "reset";
    this.retrieveToken();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public clickAddInventoryEntity() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Adding " + this.type + "</span>";
    ;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      name: this.entityForm.value.entityname,
      description: this.entityForm.value.entitydescription,
    };
    let url = ''
    if(this.type == 'item')
    {
      url = '/team/' + this.team_id + '/inventory'
    }
    let addedEntity = this._xrPlatformRestService.restfulAPIQuery(
      url,
      'post',
      data,
      getOptions
    );
    addedEntity.subscribe(
      (response) => {
        ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = this.type + " successfully added";
        let outgoingData = {
          action: 'add',
          item: response.item,
          option: response.option,
          column: response.column
        }
        this.outgoing.next(outgoingData);
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public resetForm() {
    this.entityForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.addInventoryEntityModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

}
