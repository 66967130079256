<ngx-extended-pdf-viewer
  [src]="pdfSrc"
  [textLayer]="true"
  [showToolbar]="true"
  [showSidebarButton]="true"
  [showFindButton]="true"
  [showPagingButtons]="true"
  [showDrawEditor]="false"
  [showStampEditor]="false"
  [showTextEditor]="false"
  [showZoomButtons]="true"
  [showPresentationModeButton]="true"
  [showOpenFileButton]="false"
  [showPrintButton]="true"
  [showDownloadButton]="false"
  [showSecondaryToolbarButton]="true"
  [showRotateButton]="true"
  [showHandToolButton]="true"
  [showScrollingButton]="true"
  [showSpreadButton]="false"
  [showPropertiesButton]="false"
  (pdfLoaded)="onPdfLoad($event)"
>
  ></ngx-extended-pdf-viewer
>
