<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <mdb-icon fas icon="{{ icon }}"></mdb-icon>
      {{ title | titlecase }}
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="eventSettingFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-3 p-0">
    <div *ngIf="propsLoading">
      <p class="loading-text">Loading...</p>
    </div>

    <div *ngIf="!propsLoading">
      <div class="card-group" *ngIf="props.length">
        <ng-container *ngFor="let prop of props; let i = index">
          <div *ngIf="!prop.props.archived">
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title>
                  <h5 class="mb-3">{{ prop.props.label }}</h5>
                  <p>{{ prop.props.description }}</p>
                </mdb-card-title>

                <!-- the props -->
                <mdb-card-text class="mb-0">
                  <div
                    class="alert alert-primary custom-alert-saving"
                    role="alert"
                    [ngClass]="{
                      visible:
                        status === 'processing' && i === currentUpdateIndex
                    }"
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    Saving
                  </div>
                  <div
                    class="alert alert-success custom-alert"
                    role="alert"
                    [ngClass]="{
                      visible: status === 'saved' && i === currentUpdateIndex
                    }"
                  >
                    <mdb-icon fas icon="check"></mdb-icon>
                    Successfully Saved
                  </div>
                  <div
                    class="alert alert-success custom-alert"
                    role="alert"
                    [ngClass]="{
                      visible: status === 'removed' && i === currentUpdateIndex
                    }"
                  >
                    <mdb-icon fas icon="check"></mdb-icon>
                    Successfully Removed
                  </div>
                  <div>
                    <ul class="list-unstyled mb-0">
                      <li>
                        <!-- multiselect -->

                        <div *ngIf="prop.props.type == 'multi_select_list'">
                          <div class="md-form">
                            <mdb-select-2
                              [multiple]="true"
                              id="prop.props.id"
                              placeholder="Choose your options"
                              [(value)]="prop.props.constrained_value"
                            >
                              <mdb-select-option
                                *ngFor="let option of prop.props.options"
                                [value]="option.id"
                                (click)="updateEventProp(prop, option.id, i)"
                                >{{ option.label }}</mdb-select-option
                              >
                            </mdb-select-2>
                          </div>
                        </div>
                        <!-- boolean -->
                        <div *ngIf="prop.props.type == 'boolean'">
                          <mdb-checkbox
                            [checked]="prop.props.unconstrained_value"
                            [default]="prop.props.unconstrained_value"
                            (change)="updateEventProp(prop, $event, i)"
                          >
                            Enabled</mdb-checkbox
                          >
                        </div>
                        <!-- select list -->
                        <div *ngIf="prop.props.type == 'select_list'">
                          <div class="md-form">
                            <mdb-select-2
                              id="prop.props.id"
                              placeholder="Choose your option"
                              (selected)="updateEventProp(prop, $event, i)"
                              [(value)]="prop.props.constrained_value"
                            >
                              <mdb-select-option
                                *ngFor="let option of prop.props.options"
                                [value]="option.id"
                                >{{ option.label }}</mdb-select-option
                              >
                            </mdb-select-2>
                          </div>
                        </div>
                        <!-- string -->
                        <div *ngIf="prop.props.type == 'string'">
                          <div class="col-md-12">
                            <div class="d-flex flex-row">
                              <div class="flex-fill">
                                <app-text-inline-edit
                                  [data]="prop.props.unconstrained_value"
                                  (saveData)="updateEventProp(prop, $event, i)"
                                ></app-text-inline-edit>
                              </div>
                              <div class="flex-fill"></div>
                            </div>
                          </div>
                        </div>
                        <!-- number -->
                        <div *ngIf="prop.props.type == 'number'">
                          <div class="md-form">
                            <input
                              mdbInput
                              type="number"
                              class="form-control"
                              [ngModel]="props[i].props.unconstrained_value"
                              (ngModelChange)="updateEventProp(prop, $event, i)"
                            />
                          </div>
                        </div>
                        <!-- assets -->
                        <div
                          *ngIf="prop.props.type == 'asset'"
                          class="d-flex justify-content-between"
                        >
                          <div *ngIf="prop.props.subtype == 'image'">
                            <div
                              [ngStyle]="{
                                'background-image':
                                  'url(' + checkThumbnail(prop) + ')'
                              }"
                              class="thumbnail-container"
                            ></div>
                          </div>
                          <div *ngIf="prop.props.subtype == 'video'">
                            <div
                              [ngStyle]="{
                                'background-image':
                                  'url(' + checkThumbnail(prop) + ')'
                              }"
                              class="thumbnail-container"
                            ></div>
                          </div>
                          <div *ngIf="prop.props.subtype == 'pdf'">
                            <div
                              [ngStyle]="{
                                'background-image':
                                  'url(' +
                                  (prop.props.asset
                                    ? activeHolderPdf
                                    : placeHolderPdf) +
                                  ')'
                              }"
                              class="thumbnail-container"
                            ></div>
                          </div>
                          <div class="md-form my-0">
                            <button
                              class="theme-modal-button btn"
                              type="button"
                              mdbBtnp
                              mdbWavesEffect
                              (click)="openSelectMediaModal(prop, i)"
                            >
                              <mdb-icon
                                far
                                icon="plus-square"
                                class="pr-1"
                              ></mdb-icon>
                              Update Asset
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </mdb-card-text>
              </mdb-card-body>
              <mdb-card-footer> </mdb-card-footer>
            </mdb-card>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!props.length">
        <div class="row">
          <div class="col-12">
            <mdb-card wider="true">
              <mdb-card-body>
                <p *ngIf="user.role_type_id === 1" class="text-center">
                  Event Properties are not currently configured for this client,
                  please go
                  <a
                    [routerLink]="['/client', teamID, 'settings']"
                    (click)="eventSettingFrame.hide()"
                  >
                    here</a
                  >
                  to add Event Properties.
                </p>
                <p *ngIf="user.role_type_id !== 1" class="text-center">
                  Event properties are not currently configured, please contact
                  support to find out more.
                </p>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      (click)="eventSettingFrame.hide()"
    >
      Close
    </button>
  </div>
</div>
