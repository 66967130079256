import { CoolLocalStorage } from "@angular-cool/storage";
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactory, ElementRef, Inject, NgZone, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as Dropzone from 'dropzone';
import { MDBModalRef, PopoverDirective } from "ng-uikit-pro-standard";

import {
  DropzoneComponent,
  DropzoneDirective,
  DropzoneConfigInterface,
} from "ngx-dropzone-wrapper";
import { Subject } from "rxjs";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { WcModelViewerComponent } from "src/app/modules/web-components/wc-model-viewer/wc-model-viewer.component";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

import {
  faCircleNotch,
  faMinusSquare,
  faTriangleExclamation,
  faTimes,
  faCircleCheck,
  faCloudArrowUp,
  faCircleQuestion
} from "@fortawesome/free-solid-svg-icons";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-manage-media-uploads",
  templateUrl: "./manage-media-uploads.component.html",
  styleUrls: ["./manage-media-uploads.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageMediaUploadsComponent implements OnInit, AfterViewInit {
  @ViewChild(DropzoneComponent, { static: false })
  componentRef?: DropzoneComponent;
  @ViewChild(DropzoneDirective, { static: false })
  directiveRef?: DropzoneDirective;
  @ViewChild("drpzone") drpzone: DropzoneComponent;

  @ViewChild('customPreviewTemplate', { static: false }) customPreviewTemplate: ElementRef;
  @ViewChild('alertIcon', { static: false }) alertIcon: ElementRef;
  @ViewChild('successIcon', { static: false }) successIcon: ElementRef;
  @ViewChild('pendingIcon', { static: false }) pendingIcon: ElementRef;
  @ViewChild('toolTipPrivatePublic', { static: false }) toolTipPrivatePublic: ElementRef;

  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  private restURL: string = this._clientManagementService.getRESTurl();

  //incoming
  public user: any;
  public guardrailSettings: any;
  public filterByType: string = "all";

  //tracking
  public isClean: boolean = true;
  public isValid: boolean = true;
  public isQueueProcessing: boolean = false;
  public isUploading: boolean = false;

  public teamID: number;
  public clientSettings;
  public loading: boolean = true;
  public generatedThumbnails: { [key: string]: string } = {};

  //for continuing uploads in the background
  private backgroundDropzone: Dropzone;

  //nativeElement holder for alert icon
  public alertIconHolder: any;
  public successIconHolder: any;
  public pendingIconHolder: any;
  public toolTipPrivatePublicHolder: any;

  //icons
  public faCircleNotch = faCircleNotch;
  public faMinusSquare = faMinusSquare;
  public faTriangleExclamation = faTriangleExclamation;
  public faTimes = faTimes;
  public faCircleCheck = faCircleCheck;
  public faCloudArrowUp = faCloudArrowUp;
  public faCircleQuestion = faCircleQuestion;

  //visibility control
  public isModerator: boolean = false;
  public isParticipant: boolean = false;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    public modalFrame: MDBModalRef,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private _MediaManagementServicesService: MediaManagementServicesService,
    private _clientManagementService: ClientManagementService,
    private _settingsService: SettingsService,
    private cdRef: ChangeDetectorRef,
    private _ngZone: NgZone,
    private container: ViewContainerRef,
    private _notificationService: NotificationsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public config: DropzoneConfigInterface;

  private outgoing: Subject<any> = new Subject();
  public labels: any;
  public token: string;

  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";
  public placeHolderMp3: string = "assets/img/mp3-thumbnail.png";
  public placeHolderWav: string = "assets/img/wav-thumbnail.png";

  ngOnInit(): void {

    console.log("guardrailSettings in upload modal init", this.guardrailSettings);

    if (this.user.role_type_id === 3) this.isModerator = true;
    if (this.user.role_type_id === 4 || this.user.role_type_id === 6) this.isParticipant = true;

    this.retrieveLabels();
    this.token = this._MediaManagementServicesService.retrieveToken();

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.restURL = this._clientManagementService.getRESTurl("v1");
  }

  ngAfterViewInit(): void {
    const self = this;

    let previewNode = this.customPreviewTemplate.nativeElement;
    previewNode.id = "";
    let previewTemplate = previewNode.parentNode.innerHTML;
    previewNode.parentNode.removeChild(previewNode);

    let alertIcon = this.alertIcon.nativeElement;
    this.alertIconHolder = this.alertIcon.nativeElement.innerHTML;
    alertIcon.parentNode.removeChild(alertIcon);

    let successIcon = this.successIcon.nativeElement;
    this.successIconHolder = this.successIcon.nativeElement.innerHTML;
    successIcon.parentNode.removeChild(successIcon);

    let pendingIcon = this.pendingIcon.nativeElement;
    this.pendingIconHolder = this.pendingIcon.nativeElement.innerHTML;
    pendingIcon.parentNode.removeChild(pendingIcon);

    let toolTipPrivatePublic = this.toolTipPrivatePublic.nativeElement;
    this.toolTipPrivatePublicHolder = this.toolTipPrivatePublic.nativeElement.innerHTML;
    toolTipPrivatePublic.parentNode.removeChild(toolTipPrivatePublic);

    this.config = {
      url: this.restURL + "/asset/" + this.teamID + "/upload",
      headers: {
        Authorization: "Bearer " + this.token,
      },
      autoProcessQueue: false,
      clickable: true,
      autoReset: null,
      errorReset: null,
      cancelReset: null,
      chunking: true,
      chunkSize: 2000000,
      maxFilesize: 100000000,
      parallelUploads: 1,
      previewTemplate: previewTemplate,
      previewsContainer: "#previews",
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      filesizeBase: 1024,
      // TODO: Find out what I did here
      init: function () {
        this.on("addedfile", function (file) {

          self.isQueueProcessing = true;
          self.isClean = false;
          self.cdRef.detectChanges();

          let thumbnailURL = self.placeHolderImg;

          console.log("data.URL", file.dataURL);

          //handle thumnbnail
          if (file.dataURL !== undefined) {
            thumbnailURL = file.dataURL;
          } else {

            let file_ext = file.name.split('.').pop();

            switch (file_ext) {
              case "pdf":
                thumbnailURL = self.placeHolderPdf;
                break;
              case "mp4":
                thumbnailURL = self.placeHolderMp4;
                break;
              case "mp3":
                thumbnailURL = self.placeHolderMp3;
                break;
              case "wav":
                thumbnailURL = self.placeHolderWav;
                break;
              default:
                thumbnailURL = self.placeHolderImg;
                break;
            }

          }

          //find the img-thumbnail in the file preview and set the style "background-image" to the thumbnailURL
          let imgThumbnail = file.previewElement.querySelector(".img-thumbnail");
          imgThumbnail.style.backgroundImage = `url(${thumbnailURL})`;

          let validation = self.validateMedia(file);

          console.log("validation", validation);

          if (!validation.valid) {
            self._ngZone.run(() => {
              file.status = "INVALID";
              file.previewElement.classList.add("invalid");
              file.previewElement.querySelector(".progress-wrapper").innerHTML = `<div class="error">${self.alertIconHolder}<span class="error-content">${validation.message}</span></div>`;
              self._notificationService.errorNotification(validation.message, 7000, 12000);
              self.isQueueProcessing = false;
            });

            self.checkForValidQueue();
            return
          }

          //give the file preview a unique id using modified time
          file.previewElement.id = `upload${file.lastModified}`;

          //give the file preview a class of "dz-processing"
          file.previewElement.classList.add("dz-processing");
          file.previewElement.classList.add("upload-validated");

          let extras = file.previewElement.querySelector(".extras");
          let renderPrivate = document.createElement("div");

          file.previewElement.querySelector(".progress-wrapper").insertAdjacentHTML('beforeend', `<div class="pending">${self.pendingIconHolder}<span class="pending-content">Upload Pending</span></div>`);

          //add private option
          if (!self.isParticipant) {
            renderPrivate.className = "checkbox checkbox-private md-form";
            renderPrivate.innerHTML = `<input type='checkbox' id='renderPrivate${file.lastModified}' name='renderPrivate${file.lastModified}' class="form-check-input"/><label for='renderPrivate${file.lastModified}'>Private</label${self.toolTipPrivatePublicHolder}`;
            extras.appendChild(renderPrivate);
          }

          const possible360Types = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "video/mp4",
          ];

          //if file type is in the possible 360 types array, add a checkbox for 360 rendering to the file preview
          if (possible360Types.includes(file.type)) {

            //the 360 checkbox will be appended to the id dzImageExtras inside of the file preview
            let render360 = document.createElement("div");
            render360.className = "checkbox checkbox-360 md-form";
            render360.innerHTML = `<input type='checkbox' id='render360${file.lastModified}' name='render360${file.lastModified}' class="form-check-input"/><label for='render360${file.lastModified}'>Is this a 360?</label>`;
            extras.appendChild(render360);
          }

          //get the file extension from the file name
          let modelSrc = "";

          // Check if the file is a GLB
          if (file.name.endsWith(".glb")) {
            // Create a URL for the file

            self._ngZone.run(() => {
              modelSrc = URL.createObjectURL(file);

              console.log("modelSrc in addedfile", modelSrc);

              let modelViewer = self.container.createComponent(WcModelViewerComponent);
              modelViewer.instance.modelSrc = modelSrc;
              modelViewer.instance.action = "upload";
              modelViewer.instance.uploadID = file.lastModified;
              modelViewer.instance.generatedThumbnail.subscribe(event => self.onGeneratedThumbnail(event));
              modelViewer.instance.ngOnChanges();

              //attach modelViewer to the modelViewerHolder div
              let modelViewerHolder = document.querySelector("#modelViewerHolder");
              modelViewer.location.nativeElement.id = `modelViewer${file.lastModified}`;
              modelViewerHolder.appendChild(modelViewer.location.nativeElement);
            });

            // Now you can use the URL to load the file into the Google model-viewer
            // For example:
            // document.querySelector('model-viewer').src = url;
          } else {

            //remove class "dz-processing" from file preview
            file.previewElement.classList.remove("dz-processing");
          }

          // file.previewElement.innerHTML = `${file.previewElement.innerHTML} <div class="checkbox-pdf-render"><input type='checkbox' id='render' name='render'class="mr-1"/><label for='render'>Public</label></div>`;
        });

        this.on("processing", function (file) {
          console.log("processing", file);
          this.isQueueProcessing = true;
        });

        this.on("processingmultiple", function (files) {
          console.log("processingmultiple", files);
        });

        this.on("queuecomplete", function (files) {
          console.log("queuecomplete", files);
        });

        this.on("thumbnail", function (file, dataURL) {

          console.log("thumbnail is a go");

          if (dataURL) {
            //find the img-thumbnail in the file preview and set the style "background-image" to the thumbnailURL
            let imgThumbnail = file.previewElement.querySelector(".img-thumbnail");
            imgThumbnail.style.backgroundImage = `url(${dataURL})`;
          }

        });

        this.on("complete", function (file) {
        });
        this.on("sending", function (data, xhr, formData) {
          console.log("sending", data, xhr, formData);

          let targetID = `#renderPrivate${data.lastModified}`;
          let isPrivateCheck = data.previewElement.querySelector(targetID);
          if (isPrivateCheck && isPrivateCheck.checked)
            formData.append("public", false);

          //participants can only upload private media
          if (self.isParticipant) formData.append("public", false);

          targetID = `#render360${data.lastModified}`;
          let is360Check = data.previewElement.querySelector(targetID);
          if (is360Check && is360Check.checked)
            formData.append("is_360", true);
        });

        this.on("addedfiles", function (files) {
          console.log("addedfiles", files);
          self.checkForValidQueue();
        });
      },
    };



    this.loading = false;
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  getFormats(formats): string {
    return formats.map(format => format.label).join(", ");
  }

  private checkForValidQueue() {
    let validated = document.querySelectorAll(".upload-validated");
    let invalidEntries = document.querySelectorAll(".invalid");
    //check to see if there are any preview elements in the dropzone with a class of "dz-processing"
    let processing = document.querySelectorAll(".dz-processing");

    console.log("processing in checkForValidQueue", processing);
    console.log("invalidEntries in checkForValidQueue", invalidEntries);

    if (!validated.length && invalidEntries.length) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }

    if (!validated.length) {
      this.isClean = true;
    } else {
      this.isClean = false;
    }

    if (!processing.length){
      this.isQueueProcessing = false;
    } else {
      this.isQueueProcessing = true;
    }

    this.cdRef.detectChanges();

    console.log("this.isValid in checkForValidQueue", this.isValid);
    console.log("this.isClean in checkForValidQueue", this.isClean);
    console.log("this.isQueueProcessing in checkForValidQueue", this.isQueueProcessing);
  }

  public onUploadInit(args: any): void {
  }

  public onUploadError(args: any): void {

    let errorType = args[1] !== undefined ? args[1] : "error";

    if (errorType === "error") {
      let file = args[0];
      let message = "There was an issue uploading this file, please re-add the file and try again."

      file.status = "INVALID";
      file.previewElement.classList.remove("upload-validated");
      file.previewElement.classList.add("invalid");
      file.previewElement.querySelector(".progress-wrapper").innerHTML = `<div class="error">${this.alertIconHolder}<span class="error-content">${message}</span></div>`;
      this._notificationService.errorNotification(message, 7000, 12000);
    }

    this.isQueueProcessing = false;
    this.checkForValidQueue();

  }

  public async onUploadSuccess(args: any): Promise<void> {

    console.log("onUploadSuccess:", args);
    let openmedia = args[1];
    let file = args[0];
    file.previewElement.classList.remove("upload-validated", "dz-success", "dz-complete", "dz-processing");

    //first check for errors
    if (openmedia.status !== undefined && openmedia.status === "error") {

      let message = openmedia.error;

      file.status = "INVALID";
      file.previewElement.classList.add("invalid");
      file.previewElement.querySelector(".progress-wrapper").innerHTML = `<div class="error">${this.alertIconHolder}<span class="error-content">${message}</span></div>`;;
      this._notificationService.errorNotification(message, 7000, 12000);
      this.isQueueProcessing = false;

      this.checkForValidQueue();
      return;

    } else {

      file.status = "success";
      file.previewElement.classList.add("upload-success");
      file.previewElement.querySelector(".progress-wrapper").innerHTML = `<div class="success">${this.successIconHolder}<span class="success">Upload successful</span></div>`;

    }



    if (this.drpzone.directiveRef.dropzone().getQueuedFiles().length)
      this.drpzone.directiveRef.dropzone().processQueue();
    let num_of_events = 0;
    if (
      openmedia.asset.meta &&
      openmedia.asset.meta != null &&
      openmedia.asset.meta.scheduled_events != null
    ) {
      num_of_events = openmedia.asset.meta.scheduled_events.length;
    }
    openmedia.asset.num_of_events = num_of_events;

    openmedia.asset.num_of_shares = openmedia.asset.shared_with.length;
    openmedia.asset.attached = [];


    let uploadID = file.previewElement.id;

    console.log("this.generatedThumbnails", this.generatedThumbnails);

    let outgoingData = {
      action: "add",
      changedData: openmedia,
    };
    this.outgoing.next(outgoingData);

    if (this.generatedThumbnails[uploadID] !== undefined) {
      let uploadThumbnail = await this.uploadThumbnail(
        this.generatedThumbnails[uploadID],
        openmedia.asset.uuid,
        openmedia.asset.name
      ).catch((err) => {
        console.log("err in uploadThumbnail", err);
      });

      let changedData = {
        thumbnail: uploadThumbnail,
        originalMedia: openmedia,
      }

      let outgoingData = {
        action: "add_thumbnail",
        changedData: changedData,
      };

      this.outgoing.next(outgoingData);
    }

    this.isQueueProcessing = false;
    this.checkForValidQueue();
  }

  public startUpload(): void {
    this.isUploading = true;
    this.drpzone.directiveRef.dropzone().processQueue();
  }

  public onGeneratedThumbnail(event) {
    console.log("event in onGeneratedThumbnail", event);

    //convert blog to image we can use for preview
    this.generatedThumbnails[`upload${event.uploadID}`] = event;
    let generatedThumbnailLink = URL.createObjectURL(event);


    //add to dropzone preview
    let thisUpload = document.querySelector(`#upload${event.uploadID}`);
    let preview = thisUpload.querySelector(".img-thumbnail") as HTMLElement; // Cast to HTMLElement
    preview.style.backgroundImage = `url(${generatedThumbnailLink})`;

    //remove class "processing" from file preview
    thisUpload.classList.remove("dz-processing");

    // //remove div of event.uploadID from modelViewerHolder
    let modelViewerHolder = document.querySelector("#modelViewerHolder");
    let modelViewer = document.querySelector(`#modelViewer${event.uploadID}`);
    if (modelViewer) {
      modelViewerHolder.removeChild(modelViewer);
    } else {
      console.log(`Element #modelViewer${event.uploadID} not found`);
    }

    //check to see if there are any preview elements in the dropzone with a class of "dz-processing"
    let processing = document.querySelectorAll(".dz-processing");

    if (!processing.length) {
      this.isQueueProcessing = false;
      this.cdRef.detectChanges();
    }
  }

  private uploadThumbnail(res, uuid, name) {
    const headers = {
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    //remove extension from name without removing any other periods
    let nameWithoutExtension = name.substring(0, name.lastIndexOf("."));

    let file = new File([res], nameWithoutExtension, {
      type: res.type,
    });

    let data = new FormData();
    data.append("file", file);
    data.append("max_width", "500");

    const targetURL = "/asset/" + uuid + "/thumbnail";

    let uploadThumbnail = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "post",
      data,
      options,
      "v1"
    );

    return uploadThumbnail.toPromise();
  }

  private validateMedia(file) {
    console.log("file in validateMedia", file);
    let status = {
      valid: true,
      message: ""
    }

    let fileExtension = file.name.toLowerCase().split('.').pop();

    //special exception for txt files, which are currently needed for AI
    if (fileExtension === "txt") return status;

    let allowedExtensions = this.guardrailSettings.guardrail_formats;

    if (!allowedExtensions.includes(fileExtension)) {
      status.valid = false;
      status.message = `Unsupported file format.`;

      return status;
    }

    let thisGuardrailType = this.guardrailSettings.guardrail_types.find(type =>
      type.guardrail_formats.some(format => format.name === fileExtension)
    );


    if (!thisGuardrailType.guardrail_settings.length) return status;

    for (let setting of thisGuardrailType.guardrail_settings) {

      if (!setting.frontend) continue;

      switch (setting.name) {
        case "max_file_size_image":

          let checkValue = 100000000000;

          switch (setting.guardrail_value_type.name) {
            case "mb":
              //convert setting.value to binary bytes
              checkValue = setting.value * 1024 * 1024;
              break;
          }

          if (file.size > checkValue) {
            status.valid = false;
            status.message = `File is too large. Max file size for images is ${setting.value}MB.`;
          }

          break;

        case "max_file_size_video":

          let checkValueVideo = 100000000000;

          switch (setting.guardrail_value_type.name) {
            case "mb":
              //convert setting.value to binary bytes
              checkValueVideo = setting.value * 1024 * 1024;
              break;
          }

          if (file.size > checkValueVideo) {
            status.valid = false;
            status.message = `File is too large. Max file size for videos is ${setting.value}MB.`;
          }
          break;

        case "max_file_size_audio":

          let checkValueAudio = 100000000000;

          switch (setting.guardrail_value_type.name) {
            case "mb":
              //convert setting.value to binary bytes
              checkValueAudio = setting.value * 1024 * 1024;
              break;
          }

          if (file.size > checkValueAudio) {
            status.valid = false;
            status.message = `File is too large. Max file size for audio is ${setting.value}MB.`;
          }
          break;

        case "max_file_size_pdf":

          let checkValuePDF = 100000000000;

          switch (setting.guardrail_value_type.name) {
            case "mb":
              //convert setting.value to binary bytes
              checkValuePDF = setting.value * 1024 * 1024;
              break;
          }

          if (file.size > checkValuePDF) {
            status.valid = false;
            status.message = `File is too large. Max file size for PDFs is ${setting.value}MB.`;
          }
          break;

        case "max_file_size_3d_objects":

          let checkValueModel = 100000000000;

          switch (setting.guardrail_value_type.name) {
            case "mb":
              //convert setting.value to binary bytes
              checkValueModel = setting.value * 1024 * 1024;
              break;
          }

          if (file.size > checkValueModel) {
            status.valid = false;
            status.message = `File is too large. Max file size for models is ${setting.value}MB.`;
          }
          break;
      }

      if (!status.valid) return status;
    }

    console.log("status in validateMedia #3", status);

    return status;

  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    let mainEventDisplay = this.document.getElementsByClassName(
      "event-media-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("secondary");
      mainEventDisplay.item(0).classList.add("restore-primary");
    }

    this.modalFrame.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }
}
