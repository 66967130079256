import { CoolLocalStorage } from "@angular-cool/storage";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-my-users",
  templateUrl: "./my-users.component.html",
  styleUrls: ["./my-users.component.scss"],
})
export class MyUsersComponent implements OnInit, AfterViewInit {
  public users: any = [];
  public tableUsers: [];
  public tableLoading: boolean = true;
  public labels: any;
  private mdbTableData: MdbTableDirective;
  public teamID: number;

  private token: string;
  public searchText: string;
  public emailSearchText: string = "";
  public fNameSearchText: string = "";
  public lNameSearchText: string = "";
  public previous: string;
  public sortBy: string;
  public sortOrder: string;

  /**
   * Our viewChild uses a setter that detects when the child content is available
   */
  @ViewChild(MdbTableDirective, { static: false }) set Content(
    content: MdbTableDirective
  ) {
    if (content) {
      this.mdbTableData = content;
      this.mdbTableData.setDataSource(this.users);
    }
  }

  @ViewChild("tablePagination") mdbTablePagination: MdbTablePaginationComponent;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  @HostListener("input", ["$event.target"]) oninput(target) {
    this.searchCriteria();
  }

  searchCriteria() {
    let searchCriteria = [];

    if (this.searchText) {
      if (searchCriteria.length > 0) {
        searchCriteria = searchCriteria.filter((x) => x.key != "username");
      }
      const searchtype = { key: "username", value: this.searchText };
      searchCriteria.push(searchtype);
    } else {
      if (searchCriteria.length > 0) {
        searchCriteria = searchCriteria.filter((x) => x.key != "username");
      }
    }

    //this.searchItems();
    if (this.emailSearchText) {
      if (searchCriteria.length > 0) {
        searchCriteria = searchCriteria.filter((x) => x.key != "email");
      }
      const searchtype = { key: "email", value: this.emailSearchText };
      searchCriteria.push(searchtype);
    } else {
      if (searchCriteria.length > 0) {
        searchCriteria = searchCriteria.filter((x) => x.key != "email");
      }
    }

    if (this.fNameSearchText) {
      if (searchCriteria.length > 0) {
        searchCriteria = searchCriteria.filter((x) => x.key != "first_name");
      }
      const searchtype = { key: "first_name", value: this.fNameSearchText };
      searchCriteria.push(searchtype);
    } else {
      if (searchCriteria.length > 0) {
        searchCriteria = searchCriteria.filter((x) => x.key != "first_name");
      }
    }

    if (this.lNameSearchText) {
      if (searchCriteria.length > 0) {
        searchCriteria = searchCriteria.filter((x) => x.key != "last_name");
      }
      const searchtype = { key: "last_name", value: this.lNameSearchText };
      searchCriteria.push(searchtype);
    } else {
      if (searchCriteria.length > 0) {
        searchCriteria = searchCriteria.filter((x) => x.key != "last_name");
      }
    }

    this.filterTable(searchCriteria);
  }

  filterTable(searchCriteria) {
    this.mdbTableData.setDataSource(this.previous);
    this.tableUsers = this.mdbTableData.getDataSource();
    this.users = this.tableUsers;
    if (searchCriteria.length > 0) {
      let filteritems = this.users;
      searchCriteria.forEach((element) => {
        filteritems = this.filterIt(filteritems, element.key, element.value);
        this.mdbTableData.setDataSource(filteritems);
        this.users = filteritems;
      });
    }
  }
  filterIt(arr, searchKey, searchValue) {
    return arr.filter((item) =>
      item[searchKey].toLowerCase().includes(searchValue)
    );
  }

  ngOnInit(): void {
    this.retrieveTeamID();
    this.retrieveToken();
    this.retrieveLabels();
    this.retrieveUserData();
  }

  private retrieveTeamID(){
    this.teamID = JSON.parse(this.coolLocalStorage.getItem("admin_panel_team_id"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveUserData() {
    const userInfo = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_userinfo")
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };
    let getUsers = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/users-of/" + userInfo["id"] + "/4",
      "get",
      {},
      options
    );

    getUsers.subscribe(
      (response) => {
        ;
        if (response.status === "success") {
          response.users.map((item) =>
            item.events.forEach((element) => {
              item["classes"] = item["classes"]
                ? item["classes"] + ", " + element.event_name
                : element.event_name;
            })
          );
          this.users = response.users;
          //this.tableUsers=this.users;
          this.previous = this.users;
          this.tableLoading = false;
          if (this.users.length) this.paginationEnabled();
        }
      },
      (err) => {}
    );
  }
  public sortListener(event) {
    ;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
  }
  paginationEnabled(): void {
    setTimeout(() => {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    }, 3000);
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    if (this.users.length) this.paginationEnabled();
  }
}
