import {
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  AfterContentInit,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[appAutofocus]",
})
export class AutofocusDirective implements AfterContentInit {

  constructor(private elementRef: ElementRef, public renderer2: Renderer2) {}

  ngAfterContentInit(): void {
    this.renderer2.selectRootElement(this.elementRef.nativeElement).focus();
  }
}
