import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import * as fileSaver from "file-saver";
import * as moment from "moment";
import { IMyOptions } from "ng-uikit-pro-standard";

export class chartDates {
  constructor(public start: string, public end: string) {}
}

@Component({
  selector: "app-user-meta-inventory-session-results-historic",
  templateUrl: "./user-meta-inventory-session-results-historic.component.html",
  styleUrls: [
    "./user-meta-inventory-session-results-historic.component.scss",
    "../../../../metrics/metrics-interactive/metrics-interactive.component.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UserMetaInventorySessionResultsHistoricComponent
  implements OnInit
{
  //persistent
  public token: string;
  public teamID: number;
  public users: any;
  public sessions: any;

  //content
  public copy: string = "";

  //feedback response
  public loading: boolean = true;
  public isUserSelected: boolean = false;
  public sessionsLoading: boolean = true;
  public tableStatus: string = "pending";
  public filter: boolean = false;

  //hours target index
  public hoursTargetIndex: number = 0;
  public dateTargetIndex: string = "";

  //form vars
  public sessionsSelect: { value: string; label: string }[];
  public usersSelect: { value: string; label: string }[];
  public typeSelect: { value: string; label: string }[] = [];

  //table data
  public tableData: {
    m_dataSaveTime: string;
    m_itemName: string;
    m_expectedItemStatusList: { m_statusNames: string[] };
    m_studentStatuses: { m_statusNames: string[] };
    m_expectedProductAmount: string;
    m_reportedProductAmount: string;
    m_Location: string;
    m_userInputOtherStatuses: string;
  }[] = [];

  //role conditions
  public adminDefault: boolean;
  private user: any = null;
  public user_role_id: number = 0;

  public selectedUser: any = null;
  public selectedSession: any = null;
  public selectedType: any = null;
  public inventory: any = null;
  public useMeta: boolean = false;

  // public maxDate = moment();
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";

  // charts options
  //chart vars - date selection chart
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDateoickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };

  public headingHit: any = null;

  // temp tooltips
  public tooltips = [
    "This is the name of the user saving the data seen on this table",
    "This is the name of the box in the scene that the user is reviewing",
    "This is the status that the student saved in the VR experience after reviewing the box",
    "This is the correct status of the box in the VR experience, to show if the user reported the status correctly",
    "This is where the box is located in the VR experience, based on the asile, row and shelf in the virtual warehouse",
    "This is where the user's custom text description is saved if they choose the 'other' status after review the box",
    "This is the number of products saved within the box being reviewed",
    "This is the number of products the user reports being in the box after reviewing, to show if the user reported the quantity correctly ",
    "This is the date that the user the saved the data shown in this row on this graph",
    "This is the a single word entry about the look of the box materials if the user specified 'other' as the correct answer",
    "This is the status that the student saved in the VR experience after reviewing the products inside box",
    "This is the correct status of the products inside the box in the VR experience, to show if the user reported the status correctly",
  ];

  constructor(
    private route: ActivatedRoute,
    private _clientManagementService: ClientManagementService,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService
  ) {}

  ngOnInit() {
    this.retrieveTeamID();

    let clientSettings = this._clientManagementService.getClientSettings(
      this.teamID
    );

    console.log(
      "clientSettings in inventory session results init",
      clientSettings
    );

    this.typeSelect.push({ value: null, label: "No Filter" });
    this.typeSelect.push({ value: "normal", label: "Normal" });
    this.typeSelect.push({ value: "floortolist", label: "Floor to List" });

    this.copy =
      "Review Inventory Session Results. Use the user drop down on the right to select a user to review.";

    this.retrieveToken();
    this.retrieveUser();
    const today = moment();

    this.model = new chartDates(
      today.subtract(7, "d").format("MMM D, YYYY"),
      moment().format("MMM D, YYYY")
    );

    if (this.user_role_id !== 1 && this.user_role_id !== 2) {
      this.adminDefault = true;
      this.loading = false;
      //this.getUserMeta(this.user.id);
      this.processUsers([this.user]);
      //this.retrieveSessions();
    } else {
      this.adminDefault = true;
      this.retrieveUsers();
      //this.retrieveSessions();
    }
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
    if (this.user !== null) this.user_role_id = this.user.role_type_id;
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options,
      true
    );

    retrieveUsers.subscribe(
      (response) => {
        this.processUsers(response);
      },
      (error) => {
        ;
      }
    );
  }

  private retrieveSessions() {
    let url = "/inventory/sessions";
    if (this.user_role_id !== 1 && this.user_role_id !== 2) {
      url += "/" + this.user.id;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveSessions = this._xrPlatformRestService.restfulAPIQuery(
      url,
      "get",
      {},
      options
    );

    retrieveSessions.subscribe(
      (response) => {
        ;
        this.processSessions(response.sessions);
      },
      (error) => {
        ;
      }
    );
  }

  private processUsers(users) {
    let usersSelect = [];

    users.sort((a, b) => a.last_name.localeCompare(b.last_name));

    users.forEach((users) => {
      let thisUser = {
        value: users.id,
        label: `${users.first_name} ${users.last_name}`,
      };

      usersSelect.push(thisUser);
    });

    this.usersSelect = usersSelect;
    if (this.user_role_id !== 1 && this.user_role_id !== 2) {
      this.selectedUser = this.user.id;
    }
    this.loading = false;
  }

  private processSessions(sessions) {
    let sessionsSelect = [];
    ;

    sessions = sessions.sort((a, b) => {
      return b.session_id - a.session_id;
    });

    let thisSession = {
      value: null,
      label: "No Session",
    };

    sessionsSelect.push(thisSession);

    sessions.forEach((session) => {
      let thisSession = {
        value: session.session_id,
        label:
          session.session_name +
          " " +
          moment(session.session_date).format("MMM D, YYYY"),
      };

      sessionsSelect.push(thisSession);
    });

    this.sessionsSelect = sessionsSelect;
    this.sessionsLoading = false;
  }

  public userSelected(event) {
    this.selectedUser = event.value;
    this.isUserSelected = true;
  }

  public sesssionSelected(event) {
    this.selectedSession = event.value;
  }

  public typeSelected(event) {
    this.selectedType = event.value;
  }

  private getUserMeta(user_id) {
    ;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    //retrieve user data
    let retrieveUser = this._xrPlatformRestService.retrieveEntityData(
      "user",
      user_id,
      options
    );

    retrieveUser.subscribe((incomingUser) => {
      ;
      let thisUser = incomingUser.user;
      this.selectedUser = incomingUser.user;
      this.processUser(thisUser);
    });
  }

  public getInventory() {
    this.loading = true;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");

    //retrieve user data
    let retrieveInventory = this._xrPlatformRestService.restfulAPIQuery(
      "/inventory/filtered",
      "post",
      {
        entity_id: this.selectedUser,
        session_id: this.selectedSession,
        session_type: this.selectedType,
        start_date: startDate,
        end_date: endDate,
      },
      options
    );

    retrieveInventory.subscribe((inventory) => {
      this.inventory = inventory;
      ;
      this.loading = false;
      if (inventory.inventory.length > 0) {
        this.tableStatus = "dataLoaded";

        this.inventory = {
          ...this.inventory,
          tooltips: this.tooltips,
        };

        //locate hours target index
        //loop through inventory.labels and find label = 'm_dataSaveTimeHour'
        //@todo need a much better way to do this
        this.inventory.labels.forEach((label, index) => {
          if (label === "Save Date") {
            this.hoursTargetIndex = index;
          } else if (label === "Save Time") {
            this.dateTargetIndex = index;
          }
        });

        this.inventory.inventory.forEach((columns) => {
          let thisDate = "";

          //first get date
          columns.forEach((column, columnDex) => {
            if (columnDex === this.dateTargetIndex) {
              thisDate = column;
            }
          });

          //then use date to populate hours in correct tz
          columns.forEach((column, columnDex) => {
            if (columnDex === this.hoursTargetIndex) {
              let thisHour = moment(thisDate + " " + column + " UTC").format(
                "h:mm:ss a"
              );
              columns[columnDex] = thisHour;
            }
          });
        });
      } else {
        this.tableStatus = "noData";
      }
    });
  }

  private processUser(user) {
    if (
      user.meta === null ||
      user.meta.nsuInventory === undefined ||
      !user.meta.nsuInventory.length
    ) {
      this.tableStatus = "noData";
      return;
    }

    this.tableData = user.meta.nsuInventory;
    ;
    this.tableStatus = "dataLoaded";
  }

  public possibleData(data, target) {
    if (data[target] === undefined) return "";

    return data[target];
  }

  public importMeta() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    ;
    const data = {
      inventory: this.selectedUser.meta,
    };

    //retrieve user data
    let importMeta = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" +
        this.teamID +
        "/inventory/user/" +
        this.selectedUser.id +
        "/add-object",
      "post",
      data,
      options
    );

    importMeta.subscribe((imported) => {
      ;
      this.inventory = imported;
      if (imported.inventory.length > 0) {
        this.tableStatus = "dataLoaded";
      } else {
        this.tableStatus = "noData";
      }
    });
  }

  public downloadCSV() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
      responseType: "blob",
    };
    let name = "";

    if (this.selectedUser != null) {
      this.usersSelect.forEach((user) => {
        if (user.value == this.selectedUser) {
          name += user.label + " ";
        }
      });
    }

    if (this.selectedSession != null) {
      this.sessionsSelect.forEach((session) => {
        if (session.value == this.selectedSession) {
          name += session.label + " ";
        }
      });
    }

    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("MM/DD/YYYY");
    let endDate = moment(end).format("MM/DD/YYYY");

    ;
    const data = {
      entity_id: this.selectedUser,
      entity_type: "user",
      session_id: this.selectedSession,
      session_type: this.selectedType,
      start_date: startDate,
      end_date: endDate,
    };

    ;

    //retrieve user data
    let downloadCSV = this._xrPlatformRestService.restfulAPIQuery(
      "/inventory/download/csv",
      "post",
      {
        entity_id: this.selectedUser,
        entity_type: "user",
        session_id: this.selectedSession,
        session_type: this.selectedType,
        start_date: startDate,
        end_date: endDate,
      },
      options
    );

    downloadCSV.subscribe((file) => {
      let blob: any = new Blob([file], { type: "text/csv; charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, name + ".csv");
    });
  }

  public mouseEnter(i) {
    this.headingHit = i;
  }
  public mouseLeave() {
    this.headingHit = null;
  }
}
