<!-- <div class="grid-options">
  <button
    mdbBtn
    type="button"
    mdbWavesEffect
    class="theme-modal-button btn"
    (click)="sidebarVisible = !sidebarVisible"
  >
    <span *ngIf="!sidebarVisible">See Other Charts</span>
    <span *ngIf="sidebarVisible">Close Other Charts</span>
  </button>
  <button
    mdbBtn
    type="button"
    mdbWavesEffect
    class="theme-modal-button btn"
    (click)="saveColumnState()"
  >
    Save Column State
  </button>
  <button
    mdbBtn
    type="button"
    mdbWavesEffect
    class="theme-modal-button btn"
    (click)="loadColumnState()"
  >
    Load Column State
  </button>
</div> -->
<div class="readquest-analytics-highlevel content-card">
  <ng-container *ngIf="loading">
    <div class="row">
      <div class="col-12">
        <!-- if content hasnt load -->
        <div
          class="loading-card d-flex align-content-center justify-content-center"
        >
          <div
            class="d-flex justify-content-center"
            *ngIf="errorRetrievingMsg === ''"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            class="d-flex justify-content-center"
            *ngIf="errorRetrievingMsg !== ''"
          >
            <div
              class="alert alert-danger"
              role="alert"
              [innerHTML]="errorRetrievingMsg"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <ag-grid-angular
      #myGrid
      *ngIf="gridVisible"
      style="width: 100%; height: 100%"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [sideBar]="sideBar"
      [rowData]="rowData"
      [autoSizeStrategy]="autoSizeStrategy"
      [class]="themeClass"
      [enableCharts]="enableCharts"
      [enableRangeSelection]="enableRangeSelection"
      [initialState]="initialState"
      (stateUpdated)="onStateUpdated($event)"
      (columnResized)="onColumnResized($event)"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </ng-container>
</div>
<p-sidebar [(visible)]="sidebarVisible" position="right">
  <h3>Other Charts</h3>
  <links *ngIf="!loading"
    ><ul class="collapsible collapsible-accordion mt-0">
      <li>
        <button
          class="btn btn-clear"
          type="button"
          mdbBtn
          (click)="initiateRoutedModal('drillDownGrids', 'userData')"
        >
          User Data
        </button>
      </li>
    </ul></links
  >
</p-sidebar>
