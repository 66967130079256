<div class="modal-header text-center">
  <h4 class="modal-title w-100">Manage {{labels.event.singular|titlecase}} {{labels.userGroup.plural|titlecase}}</h4>
</div>
<div class="modal-body media-modal p-0">
  <div class="row">
    <div class="col-md-12">
      <table
        *ngIf="targetEvent.userGroupCount > 0"
        class="table table-striped table-hover table-sm my-2"
        mdbTable
        #tableEl="mdbTable"
        mdbTableScroll
        scrollY="true"
        maxHeight="400"
        hover="true"
        striped="true"
      >
        <thead class="sticky-top theme-base-medium-dark white-text">
          <tr>
            <th *ngFor="let head of scheduledGroupsTableHeaders" scope="col">
              {{ head }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            mdbTableCol
            *ngFor="let group of targetEvent.scheduled_user_groups"
          >
            <td>{{ group.name }}</td>
            <td>{{ group.number_of_members }}</td>
            <td>
              <div class="btn-group list-btns-sm">
                <a
                  mdbBtn
                  mdbWavesEffect
                  class="btn-flat"
                  (click)="openViewGroupModal(group)"
                >
                  <mdb-icon fas icon="users"></mdb-icon>
                </a>

                <a
                  mdbBtn
                  mdbWavesEffect
                  class="btn-flat"
                  (click)="openGroupToClassModal(group, 'remove')"
                >
                  <mdb-icon fas icon="minus-circle"></mdb-icon>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <p *ngIf="targetEvent.userGroupCount === 0">
        No groups are yet scheduled for this experience.
      </p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    data-dismiss="modal"
    size="md"
    color="link"
    (click)="manageGroupFrame.hide()"
  >
    {{ btnLabel.close }}
  </button>
</div>
