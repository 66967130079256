import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';

@Component({
  selector: 'app-edit-inventory-modal',
  templateUrl: './edit-inventory-modal.component.html',
  styleUrls: ['./edit-inventory-modal.component.scss']
})
export class EditInventoryModalComponent implements OnInit {

  constructor(
    public editInventoryEntityModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder) { }

  public team_id: number;
  public token: string;
  public type: string;
  public entity: any;

  public experienceForm: UntypedFormGroup;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Room",
    retry: "Continue Editing",
  };

  public entityForm;

  private outgoing: Subject<any> = new Subject();

  ngOnInit(): void {
    this.continueType = "continue";
    this.retrieveToken();

    this.entityForm = this.formBuilder.group({
      entityname: this.entity.name,
      entitydescription: this.entity.label,
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public clickEditInventoryEntity()
  {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Editing " + this.type + "</span>";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    
    let data = {
    };

    let url = '';
    if(this.type == 'item')
    {
      url = '/team/' + this.team_id + '/inventory/' + this.entity.id + '/update'
      data = {
        name: this.entityForm.value.entityname,
        description: this.entityForm.value.entitydescription + '',
      };
    }
    else if(this.type == 'option')
    {

    }
    else if(this.type == 'column')
    {
      url = '/team/' + this.team_id + '/inventory/column/' + this.entity.id + '/update'
      data = {
        name: this.entityForm.value.entityname,
        label: this.entityForm.value.entitydescription,
      };
    }

    let edittedEntity = this._xrPlatformRestService.restfulAPIQuery(
      url,
      'put',
      data, 
      getOptions
    );

    edittedEntity.subscribe((response) => {
      
      this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Experience successfully udpated";

      if(response.error != 418)
      {
        let outgoingData = {
          action: 'update',
          item: response.inventory,
          option: response.option,
          column: response.column
        }
        this.outgoing.next(outgoingData);
        
      }
    },
    (error) => {
      ;
      this.msgs.processingMsg = "";
      let errorMsg = JSON.parse(error._body);
      this.btnLabel.retry = "Retry";
      this.msgs.errorMsg = errorMsg.error;
    });
  }

  public resetForm() {
    this.experienceForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    ;

    switch (action) {
      case "close-modal":
        this.editInventoryEntityModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

}
