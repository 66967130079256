import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModulesModule } from "./../shared-modules/shared-modules.module";
import { NgxCsvParserModule } from 'ngx-csv-parser';

import { UserCatalogComponent } from "./user-catalog/user-catalog.component";
import { PersistentModule } from "./../persistent/persistent.module";
import { EditUserComponent } from "./user-catalog/modals/edit-user/edit-user.component";
import { UserGroupsComponent } from "./user-groups/user-groups.component";
import { ManageUserGroupComponent } from "./user-groups/modals/manage-user-group/manage-user-group.component";
import { UserToGroupComponent } from "./user-groups/modals/user-to-group/user-to-group.component";
import { UsersByGroupComponent } from "./user-groups/modals/users-by-group/users-by-group.component";
import { EditPasswordComponent } from "./user-catalog/modals/edit-password/edit-password.component";
import { UserMetaDisplayComponent } from "./user-meta-display/user-meta-display.component";
import { UserMetaInventorySessionResultsComponent } from "./user-meta-display/displays/user-meta-inventory-session-results/user-meta-inventory-session-results.component";
import { MyUsersComponent } from "./my-users/my-users.component";
import { UserSettingsManageMediaComponent } from "./user-catalog/modals/user-settings-manage-media/user-settings-manage-media.component";
import { MediaManagementModule } from "../media-management/media-management.module";
import { ManageSystemUsersComponent } from './user-catalog/modals/manage-system-users/manage-system-users.component';
import { UserMetaInventorySessionResultsHistoricComponent } from './user-meta-display/displays/user-meta-inventory-session-results-historic/user-meta-inventory-session-results-historic.component';
import { ManageUserGroupRosterComponent } from './user-groups/modals/manage-user-group-roster/manage-user-group-roster.component';
@NgModule({
    declarations: [
        UserCatalogComponent,
        EditUserComponent,
        UserGroupsComponent,
        ManageUserGroupComponent,
        UserToGroupComponent,
        UsersByGroupComponent,
        EditPasswordComponent,
        UserMetaDisplayComponent,
        UserMetaInventorySessionResultsComponent,
        MyUsersComponent,
        UserSettingsManageMediaComponent,
        ManageSystemUsersComponent,
        UserMetaInventorySessionResultsHistoricComponent,
        ManageUserGroupRosterComponent,
    ],
    imports: [
        CommonModule,
        SharedModulesModule,
        PersistentModule,
        MediaManagementModule,
        NgxCsvParserModule
    ],
    exports: [UserCatalogComponent]
})
export class UserManagementModule {}
