import { environment } from "src/environments/environment";
import { Component, Input, Output, ViewEncapsulation } from "@angular/core";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { ManageAppBundlesComponent } from "./modals/manage-app-bundles/manage-app-bundles.component";
import { Subject, Subscription } from "rxjs";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";

@Component({
  selector: "app-bundles",
  templateUrl: "./app-bundles.component.html",
  styleUrls: ["./app-bundles.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppBundlesComponent {
  @Input() data: any;
  @Input() opts: any;
  @Input() clientSettings: any;
  @Output() outgoing: any = new Subject();

  public tableLoading: boolean = false;
  public errorRetrievingMsg: string = "Error retrieving bundles";
  public tableData: any;
  public tableDataType: {
    singular: string;
    plural: string;
  } = {
    singular: "bundle",
    plural: "bundles",
  };
  public tableColumns: {
    type: string;
    parent?: string;
    target: string;
    actions?: any;
  }[] = [
    {
      type: "actions",
      target: "actions",
      actions: [
        {
          label: "Edit",
          icon: "faPencilAlt",
          type: "edit",
          onClick: (row) => {
            this.manageBundle("edit", row);
          },
        },
        {
          label: "Delete",
          icon: "faTrashAlt",
          type: "delete",
          onClick: (row) => {
            this.manageBundle("delete", row);
          },
        },
      ],
    },
    {
      type: "keyValue",
      target: "label",
    },
    {
      type: "keyValue",
      target: "bundle_id",
    },
    {
      type: "keyValue",
      target: "environment",
    },
  ];

  //head elements
  public headElements = ["action", "Label", "Bundle ID", "Environment"];

  //sorting
  sort_elements = [
    {
      type: "none",
      head: "actions",
    },
    {
      type: "string",
      head: "label",
    },
    {
      type: "string",
      head: "bundle_id",
    },
    {
      type: "string",
      head: "environment",
    },
  ];
  sort = {
    direction: "asc",
    head: "label",
    type: "string",
  };

  public sortBy = "label";
  public sortOrder = "asc";

  //filtering
  filter_elements = [];
  public filterText = {
    label: "",
    bundle_id: "",
    environment: "",
  };
  public compoundFilterParams: {};

  //modal related
  private modalOptions = {
    backdrop: "static",
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public bundleFrame: MDBModalRef;

  //subscriptions
  private shareDataSubscription: Subscription;

  constructor(
    private modalService: MDBModalService,
    private _sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.configureFilters();
    this.tableData = this.data.appBundles;
    ;

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "add_bundle"
          ) {
            this.manageBundle("add");
          }
        }
      });

    //also let menu know that we're done loading
    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);
  }

  private configureFilters() {
    this.filter_elements = [
      {
        filter: false,
        type: "close_filters",
        target: "actions",
      },
      {
        filter: true,
        type: "text",
        target: "label",
      },
      {
        filter: true,
        type: "text",
        target: "bundle_id",
      },
      {
        filter: true,
        type: "text",
        target: "environment",
      },
    ];

    this.compoundFilterParams = {
      label: {
        text: this.filterText.label,
        type: "text",
      },
      bundle_id: {
        text: this.filterText.bundle_id,
        type: "text",
      },
      environment: {
        text: this.filterText.environment,
        type: "text",
      },
    };
  }

  public manageBundle(action, bundle?) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass:
        action === "delete"
          ? "remove-class-container"
          : "schedule-class-container",
      class:
        action !== "delete"
          ? this.modalOptions.class +
            " modal-full-height modal-right manage-event-display"
          : "",
    };

    this.modalOptions.data = {
      teamID: this.data.teamID,
      type: "bundle",
      targetBundle: bundle,
      action: action,
      data: this.data,
    };

    this.bundleFrame = this.modalService.show(
      ManageAppBundlesComponent,
      this.modalOptions
    );

    this.bundleFrame.content.outgoing.subscribe((result: any) => {
      if (action === "add") {
        this.data.appBundles.push(result.app_bundle);
      } else if (action === "edit") {
        this.data.appBundles = this.data.appBundles.map((bundle) => {
          if (bundle.id === result.app_bundle.id) {
            bundle = result.app_bundle;
          }
          return bundle;
        });
      } else if (action === "delete") {
        this.data.appBundles = this.data.appBundles.filter((bundle) => {
          return bundle.id !== result.app_bundle.id;
        });
      }

      this.tableData = this.data.appBundles.filter((bundle) => {
        return bundle;
      });

      this.outgoing.next({
        type: "updateBundles",
        data: {
          appBundles: this.data.appBundles,
        },
      });
    });
  }
}
