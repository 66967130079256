<mdb-card class="event-management-card content-card" *ngIf="tableLoading">
  <div class="row">
    <div class="col-12">
      <!-- if content hasnt load -->
      <div class="loading-screen d-flex align-content-center">
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg === ''"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg !== ''"
        >
          <div
            class="alert alert-danger"
            role="alert"
            [innerHTML]="errorRetrievingMsg"
          ></div>
        </div>
      </div>
    </div>
  </div>
</mdb-card>
<mdb-card class="management-card content-card" *ngIf="!tableLoading">
  <div class="row flex-row flex-nowrap">
    <div class="col-12 p-0">
      <div class="table-responsive">
        <table
          class="table table-striped"
          mdbTable
          #tableEl="mdbTable"
          small="true"
        >
          <colgroup class="items" [class]="tableDataType.plural">
            <col
              *ngFor="let head of headElements; let i = index"
              [ngClass]="'col-' + sort_elements[i].head"
            />
          </colgroup>
          <thead class="theme-base-medium-dark white-text">
            <tr class="sorting">
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="'col-' + sort_elements[i].head"
              >
                <span *ngIf="head !== 'action'">{{ head }}</span>
                <ng-template [ngIf]="sort_elements[i].type !== 'none'">
                  <mdb-icon
                    fas
                    *ngIf="sortBy !== sort_elements[i].head"
                    icon="sort"
                    class="sort-icon"
                    [mdbTooltip]="'Sort by ' + head"
                    placement="bottom"
                    (click)="
                      sortTable(
                        sort_elements[i].head,
                        'desc',
                        sort_elements[i].type
                      )
                    "
                  ></mdb-icon>
                  <mdb-icon
                    *ngIf="
                      sortBy == sort_elements[i].head && sortOrder == 'asc'
                    "
                    (click)="
                      sortTable(
                        sort_elements[i].head,
                        'desc',
                        sort_elements[i].type
                      )
                    "
                    fas
                    icon="caret-up"
                    class="sort-icon"
                  ></mdb-icon>
                  <mdb-icon
                    *ngIf="
                      sortBy == sort_elements[i].head && sortOrder == 'desc'
                    "
                    (click)="
                      sortTable(
                        sort_elements[i].head,
                        'asc',
                        sort_elements[i].type
                      )
                    "
                    fas
                    icon="caret-down"
                    class="sort-icon"
                  ></mdb-icon>
                </ng-template>
              </th>
            </tr>
            <tr class="item-filters">
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="'col-' + sort_elements[i].head"
              >
                <ng-template [ngIf]="filter_elements[i].filter">
                  <!--all filter types except access-->
                  <div
                    class="md-form md-filter-input mb-0 mt-0"
                    *ngIf="filter_elements[i].type !== 'access'"
                  >
                    <!--start text filter-->
                    <input
                      *ngIf="filter_elements[i].type === 'text'"
                      type="text"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      class="form-control"
                      [id]="'filter-' + filter_elements[i].target"
                      mdbInput
                    />
                    <!--end text filter-->
                    <!--start number filter-->
                    <input
                      *ngIf="filter_elements[i].type === 'number'"
                      type="number"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      class="form-control"
                      [id]="'filter-' + filter_elements[i].target"
                      mdbInput
                    />
                    <!--end number filter-->
                    <fa-icon
                      *ngIf="
                        filterText[filter_elements[i].target] === '' ||
                        filterText[filter_elements[i].target] === null
                      "
                      [icon]="faFilter"
                    ></fa-icon>
                    <fa-icon
                      class="cursor-pointer"
                      *ngIf="
                        filterText[filter_elements[i].target] !== '' &&
                        filterText[filter_elements[i].target] !== null
                      "
                      [icon]="faFilterCircleXmark"
                      (click)="resetFilter(i)"
                    ></fa-icon>
                  </div>
                  <!--end all filter types except access-->
                  <!--start access filter type-->
                  <div
                    class="md-form md-filter-input mb-0 mt-0"
                    *ngIf="filter_elements[i].type === 'access'"
                  >
                    <!--start access filter-->
                    <mdb-select-2
                      class="custom-arrow"
                      [id]="'filter-' + filter_elements[i].target"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                    >
                      <mdb-select-option
                        *ngFor="let option of filter_elements[i].options"
                        [value]="option.value"
                        >{{ option.label }}</mdb-select-option
                      >
                    </mdb-select-2>
                    <!--end access filter-->
                    <fa-icon
                      *ngIf="filterText[filter_elements[i].target] === 'all'"
                      [icon]="faFilter"
                    ></fa-icon>
                    <fa-icon
                      class="cursor-pointer"
                      *ngIf="filterText[filter_elements[i].target] !== 'all'"
                      [icon]="faFilterCircleXmark"
                      (click)="resetFilter(i, 'all')"
                    ></fa-icon>
                  </div>
                  <!--end access filter type-->
                </ng-template>
              </th>
            </tr>
          </thead>
          <tbody #row>
            <tr
              *ngFor="
                let item of tableData
                  | compoundFilter : compoundFilterParams
                  | singleSort : sort.direction : sort.head : sort.type;
                let i = index
              "
            >
              <ng-container
                *ngIf="
                  i + 1 >= tablePagination.firstItemIndex &&
                  i < tablePagination.lastItemIndex
                "
              >
                <td
                  *ngFor="let column of tableColumns"
                  [ngClass]="{
                  'action-icons': column.type === 'actions',
                }"
                >
                  <ng-container *ngIf="column.type === 'actions'">
                    <span class="btn-group list-btns-sm btn-icons">
                      <ng-container *ngFor="let action of column.actions">
                        <button
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon ml-2"
                          mdbTooltip="Edit {{ tableDataType.singular }}"
                          placement="bottom"
                          (click)="action.onClick(item)"
                        >
                          <fa-icon
                            *ngIf="action.type === 'edit'"
                            [icon]="faPencilAlt"
                          ></fa-icon>
                          <fa-icon
                            *ngIf="action.type === 'delete'"
                            [icon]="faTrashAlt"
                          ></fa-icon>
                        </button>
                      </ng-container>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="column.type === 'keyValue'">
                    {{ item[column.target] }}
                  </ng-container>
                  <ng-container *ngIf="column.type === 'keyParent'">
                    {{ item[column.parent][column.target] }}
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </tbody>
          <tfoot class="grey lighten-5 w-100">
            <tr>
              <td colspan="12">
                <mdb-table-pagination
                  #tablePagination
                  [tableEl]="tableEl"
                  [searchDataSource]="tableData"
                ></mdb-table-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</mdb-card>
