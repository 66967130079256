import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
@Component({
  selector: 'app-add-prop-option-modal',
  templateUrl: './add-prop-option-modal.component.html',
  styleUrls: ['./add-prop-option-modal.component.scss']
})
export class AddPropOptionModalComponent implements OnInit {

  public prop: any;
  public team_id: number;
  public token: string;

  public optionForm = this.formBuilder.group({
    label: "",
    name: "",
    value: ""
  });

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Persona",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();

  constructor(
    public addOptionModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.continueType = "reset";
    this.retrieveToken();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public clickAddPersona() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Adding option</span>";
    ;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      label: this.optionForm.value.label,
      name: this.optionForm.value.name,
      value: this.optionForm.value.value
    };
    let addedPersona = this._xrPlatformRestService.restfulAPIQuery(
      '/schedule/prop/' + this.prop + '/option',
      'post',
      data,
      getOptions
    );
    addedPersona.subscribe(
      (response) => {
        ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Persona successfully added";

        let outgoingData = {
          action: 'add',
          option: response.option
        }
        this.outgoing.next(outgoingData);
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public resetForm() {
    this.optionForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        
        this.addOptionModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

}
