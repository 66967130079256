<div class="modal-content-wrapper" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      Manage Users for {{ labels.event.singular | titlecase }}
    </h4>
    <button
      *ngIf="isClean"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="add-user-management-card content-card modal-fluid-content">
    <div class="row d-flex align-items-center">
      <div class="col-4 available-users">
        <div *ngIf="currentTab === 'users'" class="md-form">
          <input
            type="search"
            [(ngModel)]="SearchText"
            (ngModelChange)="search()"
            class="form-control"
            id="searchUsers"
            name="searchUsers"
            [disabled]="usersLoading"
            mdbInput
            autocomplete="off"
          />
          <label class="active-label active" for="searchUsers"
            >Search Available {{ labels.user.plural | titlecase }}</label
          >
        </div>
        <div *ngIf="currentTab === 'groups'" class="md-form">
          <input
            type="search"
            [(ngModel)]="SearchTextGroups"
            (ngModelChange)="searchGroups()"
            class="form-control"
            id="SearchGroups"
            name="SearchGroups"
            [disabled]="usersLoading"
            mdbInput
            autocomplete="off"
          />
          <label class="active-label active" for="Search"
            >Search Available {{ labels.userGroup.plural | titlecase }}</label
          >
        </div>
      </div>
    </div>
    <div class="row fluid-content">
      <div class="col-4 h-100 tabs-table available">
        <mdb-card class="user-card h-100">
          <mdb-tabset
            #availableTabs
            id="availableTabs"
            [buttonClass]="'classic-tabs'"
            class="admin-tab-styles"
            contentClass="remove-card available-tabs h-100"
            tabsButtonsClass="tab-nav-fluid"
            tabsContentClass="tab-content-fluid"
          >
            <mdb-tab
              heading=" Available {{ labels.user.plural | titlecase }}"
              (select)="syncTabs(0, 'added', 'users')"
              ><div
                *ngIf="usersLoading"
                class="loading-screen d-flex align-items-center h-100 justify-content-center"
              >
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <table
                class="table table-responsive-md btn-table available-users-table"
                mdbTable
                mdbTableScroll
                small="true"
                scrollY="true"
                *ngIf="!usersLoading"
              >
                <colgroup>
                  <col span="1" style="width: 10%" />
                  <col span="1" style="width: 90%" />
                </colgroup>
                <tbody>
                  <tr
                    mdbTableCol
                    *ngFor="let user of userList; index as user_index"
                  >
                    <td class="pr-0">
                      <button
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="
                          addUser(
                            user.first_name,
                            user.last_name,
                            user.personaName,
                            user.role,
                            user.id,
                            user.username
                          )
                        "
                      >
                        <fa-icon [icon]="faSquarePlus"></fa-icon>
                      </button>
                    </td>
                    <td>
                      {{ user.first_name }} {{ user.last_name }} ({{
                        user.username
                      }})
                    </td>
                  </tr>
                </tbody>
              </table></mdb-tab
            >
            <mdb-tab
              heading="Available {{ labels.userGroup.plural | titlecase }}"
              (select)="syncTabs(1, 'added', 'groups')"
            >
              <div
                *ngIf="usersLoading"
                class="loading-screen d-flex align-items-center h-100 justify-content-center"
              >
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <table
                class="table btn-table available-users-table"
                mdbTable
                mdbTableScroll
                small="true"
                scrollY="true"
                *ngIf="!usersLoading"
              >
                <colgroup>
                  <col span="1" style="width: 20px" />
                  <col span="1" style="width: 100%" />
                </colgroup>
                <tbody>
                  <tr
                    mdbTableCol
                    *ngFor="let group of groups; index as user_index"
                  >
                    <td class="pr-0">
                      <button
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="addGroup(group)"
                      >
                        <fa-icon [icon]="faSquarePlus"></fa-icon>
                      </button>
                    </td>
                    <td>{{ group.name }}</td>
                  </tr>
                </tbody>
              </table></mdb-tab
            >
          </mdb-tabset>
        </mdb-card>
      </div>
      <div class="col-8 h-100 tabs-table roster">
        <div
          class="action-button"
          id="addGroupButton"
          *ngIf="currentTab === 'groups'"
        >
          <button
            mdbBtn
            type="button"
            mdbWavesEffect
            class="btn theme-modal-button"
            size="md"
            (click)="openAddUserGroup()"
          >
            Add {{ labels.userGroup.singular | titlecase }}
          </button>
        </div>
        <mdb-tabset
          #addedTabs
          id="addedTabs"
          [buttonClass]="'classic-tabs'"
          class="admin-tab-styles"
          contentClass="remove-card added-tabs h-100"
          tabsButtonsClass="tab-nav-fluid"
          tabsContentClass="tab-content-fluid"
        >
          <mdb-tab
            heading=" Added {{ labels.user.plural | titlecase }}"
            (select)="syncTabs(0, 'available', 'users')"
          >
            <div
              *ngIf="rosterLoading"
              class="loading-screen d-flex align-items-center h-100 justify-content-center"
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div
              class="responsive"
              *ngIf="addedUsers.length > 0 && !rosterLoading"
            >
              <table
                class="table table-striped table-responsive-md btn-table user-table"
                mdbTable
                mdbTableScroll
                #tableEl="mdbTable"
                small="true"
                scrollY="true"
                maxHeight="450"
              >
                <colgroup>
                  <col span="1" style="width: 22%" />
                  <col span="1" style="width: 20%" />
                  <col span="1" style="width: 20%" />
                  <col span="1" style="width: 19%" />
                  <col span="1" style="width: 19%" />
                </colgroup>
                <thead class="theme-base-medium-dark white-text sticky-top">
                  <tr>
                    <th>Remove From Roster</th>
                    <th>First</th>
                    <th>Last</th>
                    <th>Username</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    mdbTableCol
                    *ngFor="let user of addedUsers; index as user_index"
                  >
                    <td class="text-center">
                      <button
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="removeAddedUser(user.id)"
                      >
                        <fa-icon [icon]="faMinusSquare"></fa-icon>
                      </button>
                    </td>
                    <td>
                      {{ user.first_name }}
                    </td>
                    <td>
                      {{ user.last_name }}
                    </td>
                    <td>
                      {{ user.username }}
                    </td>
                    <td>
                      {{ user.role }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="empty-message"
              *ngIf="addedUsers.length === 0 && !rosterLoading"
            >
              <p class="m-0">
                User roster is currently empty. Add Users on the left, or switch
                to Available Groups and add Users via User Groups
              </p>
            </div>
          </mdb-tab>
          <mdb-tab
            heading=" Added {{ labels.userGroup.plural | titlecase }}"
            (select)="syncTabs(1, 'available', 'groups')"
          >
            <div
              *ngIf="rosterLoading"
              class="loading-screen d-flex align-items-center h-100 justify-content-center"
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div
              class="responsive"
              *ngIf="addedGroups.length > 0 && !rosterLoading"
            >
              <table
                class="table table-striped table-responsive-md btn-table user-table"
                mdbTable
                mdbTableScroll
                #tableEl="mdbTable"
                small="true"
                scrollY="true"
                maxHeight="450"
              >
                <thead class="theme-base-medium-dark white-text sticky-top">
                  <tr>
                    <th>Remove From Roster</th>
                    <th>Name</th>
                    <th>Members</th>
                    <th>Manage Group</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    mdbTableCol
                    *ngFor="let group of addedGroups; index as group_index"
                  >
                    <td>
                      <button
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="removeAddedGroup(group.id)"
                      >
                        <fa-icon [icon]="faMinusSquare"></fa-icon>
                      </button>
                    </td>
                    <td>
                      {{ group.name }}
                    </td>
                    <td>
                      {{ group.number_of_members }}
                    </td>
                    <td>
                      <button
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="openAddUserGroup(group.id)"
                      >
                        <fa-icon [icon]="faUsers"></fa-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="empty-message"
              *ngIf="addedGroups.length === 0 && !rosterLoading"
            >
              <p class="m-0">
                User roster is currently empty. Add Users on the left, or switch
                to Available Groups and add Users via User Groups
              </p>
            </div></mdb-tab
          >
        </mdb-tabset>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      [disabled]="isClean"
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button"
      size="md"
      (click)="rosterActions()"
    >
      <ng-container *ngIf="from === 'manage-event'">
        {{
          action !== "update"
            ? "Save and Return to " + labels.event.singular
            : "Update and Return to " + labels.event.singular
        }}
      </ng-container>
      <ng-container *ngIf="from === 'events'">
        {{
          action !== "update"
            ? "Save and Return to " + labels.event.plural
            : "Update and Return to " + labels.event.plural
        }}
      </ng-container>
    </button>
    <button
      *ngIf="isClean"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>

<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <p class="semi-bold">
      Warning! All {{ action === "add" ? "work" : "updates" }} on this
      {{ labels.event.singular }} roster will be lost.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        (click)="closePopOvers()"
      >
        Back to {{ labels.event.singular }} roster
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        (click)="closeModal(true)"
      >
        Continue Cancel
      </button>
    </div>
  </div></ng-template
>
