import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfSrc: string;
  @Output("pdfLoaded") pdfLoaded = new EventEmitter();

  private proxyURL: string = environment.proxyURL;

  constructor() { }

  ngOnInit(): void {

    // this.pdfSrc = this.proxyURL + this.pdfSrc;

    console.log("this.pdfSrc in PdfViewerComponent", this.pdfSrc);
  }

  public onPdfLoad(e: any): void {
    console.log("pdfLoaded", e);
    this.pdfLoaded.emit(e);
  }

}
