import { Component } from '@angular/core';
import type { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import type { INoRowsOverlayParams } from '@ag-grid-community/core';

type CustomNoRowsOverlayParams = INoRowsOverlayParams & { noRowsMessageFunc: () => string };

@Component({
  selector: 'app-custom-no-rows-overlay',
  templateUrl: './custom-no-rows-overlay.component.html',
  styleUrls: ['./custom-no-rows-overlay.component.scss']
})
export class CustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  public params!: CustomNoRowsOverlayParams;

  agInit(params: CustomNoRowsOverlayParams): void {
    this.refresh(params);
  }

  refresh(params: CustomNoRowsOverlayParams): void {
    this.params = params;
  }


}
