import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

//third party modules
import { CoolLocalStorage } from "@angular-cool/storage";

//local services
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-experiences-all",
  templateUrl: "./experiences-all.component.html",
  styleUrls: ["./experiences-all.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ExperiencesAllComponent implements OnInit {
  //persistent
  private token: string;
  public teamID: number;
  public clientCode: string;
  public loading: boolean = true;
  public user: any;
  public labels: any;

  //data
  public experiences: any;

  // search
  public searchText: string = "";

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.retrieveTeamID();
    this.retrieveClientCode();
    this.retrieveLabels();
    this.retrieveToken();
    this.retrieveUser();
    this.retrieveExperiences();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveExperiences() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveExperiences =
      this._xrPlatformRestService.retrieveEntityCollection(
        "team",
        "legacy-experiences/flag",
        this.teamID,
        options,
        true
      );

    retrieveExperiences.subscribe(
      (response) => {
        this.experiences =
          this.user.role_type_id === 4
            ? response.ExperiencesLegacy.filter((experience) => {
                return experience.number_of_events > 0;
              })
            : response.ExperiencesLegacy;
        this.experiences.sort((a, b) => (a.name > b.name ? 1 : -1));

        if (this.user.role_type_id !== 1)
          this.experiences = this.experiences.filter((experience) => {
            return !experience.archived;
          });

        this.loading = false;
      },
      (error) => {
        ;
      }
    );
  }
}
