<div class="welcome-page page">
  <div class="account-summary-container">
    <div class="row mb-4">
      <div class="col-12">
        <mdb-card class="content-card engagement-card p-0 m-0">
          <div class="col-12 mb-1 mt-3">
            <h4>Client Details</h4>
          </div>
          <div class="d-flex justify-content-center" *ngIf="teamLoading">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="d-flex justify-content-left" *ngIf="!teamLoading">
            <div class="col-6">
              <p class="bold mb-1">Client Code</p>
              <div class="d-flex">
                <div>
                  <p>
                    {{ team.code }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-around" *ngIf="!teamLoading">
            <div class="col-6 mb-2">
              <p class="bold mb-1">Engagement Window</p>
              <div class="d-flex">
                <div class="mr-3">
                  <p *ngIf="team.engagements[0]">
                    {{
                      team.engagements[0].start_date
                        | amDateFormat : "ddd, MMM D YYYY h:mm a"
                    }}
                    -
                    {{
                      team.engagements[0].end_date
                        | amDateFormat : "ddd, MMM D YYYY h:mm a"
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 mb-2">
              <p class="bold mb-1">Window Description</p>
              <p *ngIf="team.engagements[0]">
                {{ team.engagements[0].description }}
              </p>
            </div>
          </div>
        </mdb-card>
      </div>
    </div>
    <div
      class="row mb-4"
      *ngIf="user.role_type_id === 1 || user.role_type_id === 2"
    >
      <div class="col-4">
        <mdb-card>
          <div class="row">
            <div class="col-12 mt-3">
              <p>Available Actions</p>
              <button
                *ngIf="!this.clientSettings.myEvents.unconstrained_default"
                class="theme-modal-button btn mb-3 w-100 justify-content-start sec-account-btn"
                type="button"
                size="lg"
                mdbBtn
                mdbWavesEffect
                [routerLink]="['/client', clientCode, 'experiences']"
                id="manage-events"
              >
                <fa-icon [icon]="faCalendarDay"></fa-icon>
                Manage {{ this.labels.event.plural | titlecase }}
              </button>
              <button
                *ngIf="this.clientSettings.myEvents.unconstrained_default"
                class="theme-modal-button btn mb-3 w-100 justify-content-start sec-account-btn"
                type="button"
                size="lg"
                mdbBtn
                mdbWavesEffect
                [routerLink]="['/client', clientCode, 'all-events']"
                id="manage-events"
              >
                <fa-icon [icon]="faCalendarDay"></fa-icon>
                Manage {{ this.labels.event.plural | titlecase }}
              </button>
              <button
                class="theme-modal-button btn mb-3 w-100 justify-content-start sec-account-btn"
                type="button"
                size="lg"
                mdbBtn
                mdbWavesEffect
                [routerLink]="['/client', clientCode, 'users']"
                id="manage-users"
              >
                <fa-icon [icon]="faUsersCog"></fa-icon>
                Manage {{ this.labels.user.plural | titlecase }}
              </button>
              <button
                class="theme-modal-button btn mb-3 w-100 justify-content-start sec-account-btn"
                type="button"
                size="lg"
                mdbBtn
                mdbWavesEffect
                [routerLink]="['/client', clientCode, 'media-library']"
                id="manage-media-library"
              >
                <fa-icon [icon]="faPhotoVideo"></fa-icon>
                Manage {{ this.labels.media.plural | titlecase }}
              </button>
            </div>
          </div>
        </mdb-card>
      </div>
      <div class="col-8">
        <mdb-card>
          <div class="row">
            <div class="col-12 mt-3 at-a-glance-container">
              <p>At a Glance</p>
              <hr />
              <div class="d-flex justify-content-center" *ngIf="metricsLoading">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div
                class="metric-item d-flex align-items-baseline"
                *ngIf="user.role_type_id === 1 || user.role_type_id === 2 && !metricsLoading"
              >
                <mdb-badge class="theme-base-dark" pill="true">
                  {{ metrics.events }}</mdb-badge
                >
                <p class="ml-2">{{ this.labels.event.plural | titlecase }}</p>
              </div>
              <div
                class="metric-item d-flex align-items-baseline"
                *ngIf="user.role_type_id === 1 || user.role_type_id === 2 && !metricsLoading"
              >
                <mdb-badge class="theme-base-dark" pill="true">
                  {{ metrics.users }}</mdb-badge
                >
                <p class="ml-2">{{ this.labels.user.plural | titlecase }}</p>
              </div>
              <div
                class="metric-item d-flex align-items-baseline"
                *ngIf="user.role_type_id === 1 || user.role_type_id === 2 && !metricsLoading"
              >
                <mdb-badge class="theme-base-dark" pill="true">
                  {{ metrics.assets }}</mdb-badge
                >
                <p class="ml-2">
                  {{ this.labels.media.plural | titlecase }} Assets
                </p>
              </div>
            </div>
          </div>
        </mdb-card>
      </div>
    </div>
  </div>
  <!-- information modal -->
  <div
    mdbModal
    #infoModal="mdbModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myBasicModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100">About Unified Admin Panel</h4>
          <button type="button" class="close" (click)="infoModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p [innerHTML]="copy"></p>
        </div>
        <div class="modal-footer">
          <button
            mdbBtn
            mdbWavesEffect
            type="button"
            size="md"
            color="link"
            (click)="infoModal.hide()"
            id="close-modal-btn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
