<app-status-messages
  [msgs]="msgs"
  [btnLabel]="btnLabel"
  [continueType]="continueType"
  (targetAction)="handleIncomingAction($event)"
></app-status-messages>
<div class="modal-header text-center">
  <h4 class="modal-title w-100 font-weight-bold"[ngClass]="{'removeTitle':action ==='remove'}">
   <mdb-icon far icon="calendar-alt" *ngIf="action !=='remove'">Schedule Users</mdb-icon>
    <mdb-icon far icon="trash-alt" size="2x" *ngIf="action ==='remove'"></mdb-icon>
    <span>Remove {{labels.user.singular|titlecase}}</span>
  </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="scheduleFrame.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body m-3 p-0">
  {{ msgs.actionMsg }}
  <strong class="font-weight-bold">{{ experience.name }}</strong>
  <form [formGroup]="scheduleForm" class="text-center border border-light p-5">
    <div *ngIf="!viewOnly.user">
      <div class="md-form mb-5">
        <mdb-select
          [options]="selectUsers"
          placeholder="Select User"
          formControlName="scheduleUser"
        ></mdb-select>
        <mdb-error
          *ngIf="
            scheduleUser &&
            scheduleUser.invalid &&
            (scheduleUser.dirty || scheduleUser.touched)
          "
        >
          Input invalid
        </mdb-error>
      </div>
    </div>
    <div *ngIf="viewOnly.user">
      <p>{{ preSelected.name }}</p>
    </div>

    <div *ngIf="!viewOnly.date">
      <div class="md-form mb-5">
        <mdb-date-picker
          #datePickerStart
          [inline]="true"
          name="mydate"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected date'"
          formControlName="scheduleDate"
          required
        ></mdb-date-picker>
        <mdb-error
          *ngIf="
            scheduleDate.invalid && (scheduleDate.dirty || scheduleDate.touched)
          "
        >
          Input invalid
        </mdb-error>
      </div>
    </div>
    <div *ngIf="viewOnly.date">
      <p>{{ preSelected.date }}</p>
    </div>
    <input
      *ngIf="targetUser !== undefined"
      type="hidden"
      formControlName="scheduleID"
      mdbInput
    />
  </form>
</div>

<div class="modal-footer">
  <button
  mdbBtn
  type="button"
  mdbWavesEffect
  class="theme-modal-button btn"
  data-dismiss="modal"
    [disabled]="!scheduleForm.valid"
    (click)="scheduleUsers()"
  >
    {{ btnLabel.main }}
  </button>
  <button
  mdbBtn
  mdbWavesEffect
  type="button"
  size="md"
  color="link"
  data-dismiss="modal"
    (click)="scheduleFrame.hide()"
  >
    {{ btnLabel.close }}
  </button>
</div>
