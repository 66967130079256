import { Pipe, PipeTransform } from "@angular/core";
import { filter } from "rxjs/operators";
import * as moment from "moment";
import "moment-timezone";

@Pipe({
  name: "compoundFilter",
})
export class CompoundFilterPipe implements PipeTransform {
  public tz: any = moment.tz(moment.tz.guess()).format("z");

  transform(items: any, filter: any): any {
    return this.filterActions(items, filter);
  }

  private filterActions(items, filter) {
    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);
      return items.filter((item) =>
        filterKeys.reduce(
          (memo, keyName) =>
            (memo &&
              this.testString(
                filter[keyName].text,
                filter[keyName],
                item,
                keyName
              )) ||
            filter[keyName] === "",
          true
        )
      );
    } else {
      return items;
    }
  }

  private testString(
    filterText: string,
    attributes: any,
    item: any,
    keyName: string
  ): boolean {
    switch (attributes.type) {
      case "text":
        return (
          (item[keyName] &&
            item[keyName].toLowerCase().includes(filterText.trim().toLowerCase())) ||
          filterText === "" ||
          filterText === null
        );
      case "number":
        return (
          (item[keyName] && parseInt(item[keyName]) === parseInt(filterText)) ||
          filterText === "" ||
          filterText === null
        );
      case "date":
        return (
          (item[keyName] &&
            this.convertDateToEpoch(item[keyName]) ===
              this.convertDateToEpoch(filterText + " 00:00:00 " + this.tz)) ||
          filterText === "" ||
          filterText === null
        );
      case "start_at":
        return (
          this.convertDateToEpoch(item[keyName]) -
            this.convertDateToEpoch(filterText + " 00:00:00 " + this.tz) >
            0 ||
          filterText === "" ||
          filterText === null
        );
      case "end_at":
        //end_at Fri, 16 Dec 2022 22:00:00 GMT Thu, 20 Jul 2023
        return (
          this.convertDateToEpoch(item[keyName]) -
            this.convertDateToEpoch(filterText + " 23:59:59 " + this.tz) <
            0 ||
          filterText === "" ||
          filterText === null
        );
      case "access":
        switch (filterText) {
          case "all":
            return true;
          case "public":
            return item.is_public;
          case "private_invite":
            return !item.is_public && item.has_invitees;
          case "private_access_code":
            return !item.is_public && item.is_access_code_required;
        }
      case "select":
        let targetItem = item[keyName];

        if (attributes.is_meta !== undefined && attributes.is_meta === true) {
          targetItem = item.meta[keyName];
        }

        return (
          parseInt(targetItem) === parseInt(filterText) ||
          parseInt(filterText) === 0
        );
    }

    return true;
  }

  private convertDateToEpoch(incomingDate): number {
    return parseInt(
      moment(new Date(incomingDate), "MMMM D YYYY, H:mm Z").format("X")
    );
  }
}
