<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 text-center">
        <h5>Data Entry Breakdown</h5>
      </div>
      <div
        class="col-12 d-flex justify-content-center align-items-center flex-row loading-height"
        *ngIf="metricsLoading"
      >
        <div
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        class="col-12 d-flex justify-content-center flex-row metrics-aggregates"
        *ngIf="!metricsLoading"
      >
        <div class="aggregate-container">
          <h3 class="aggregate-value">{{ aggregates[0].value }}</h3>
          <small class="aggregate-label">{{ aggregates[0].label }}</small>
        </div>
        <div class="aggregate-container">
          <h3 class="aggregate-value">{{ aggregates[1].value }}</h3>
          <small class="aggregate-label">Number Of Supervisor Calls</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="
            position: relative;
            height: 50vh;
            width: auto;
            margin-top: 2rem;
            margin-bottom: 3rem;
          ">
          <canvas #dataEntryChart> </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
