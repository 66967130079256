import { filter } from "rxjs/operators";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import {
  FormControl,
  FormGroup,
  Validators,
  UntypedFormBuilder,
  FormArray,
} from "@angular/forms";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";

import * as moment from "moment";
import "moment-timezone";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { ActivatedRoute } from "@angular/router";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { TitleCasePipe } from "@angular/common";
import { EventMediaVersion3Service } from 'src/app/modules/event-management/services/event.media.version.3.service';
import { EventMediaVersion2Service } from "src/app/modules/event-management/services/event.media.version.2.service";

@Component({
  selector: "app-media-to-class-media-manager",
  templateUrl: "./media-to-class-media-manager.component.html",
  styleUrls: ["./media-to-class-media-manager.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class MediaToClassMediaManagerComponent implements OnInit {
  public labels: any;
  private token: string;
  public media: any;
  public attached: any;
  public tz: any = moment.tz(moment.tz.guess()).format("z");

  //incoming
  public teamID: number;
  public targetEvent: any;

  //start legacy
  public type: string;
  public amount: string;
  //end legacy

  //feedback response
  public title = "";
  public errorMsg: string = "";
  public statusMsg: string = "";
  public actionMsg: string = "Add or remove media from class";
  public formState: string = "active";
  public formLoading: boolean = false;
  public noChange: boolean = true;

  //form vars
  public scheduleForm: any = { 'myOptionsArray': [] };
  public mediaSelect: any[];
  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Update",
  };

  //start legacy
  public pdfOptionsGroup: any;
  public imageOptionsGroup: any;
  public videoOptionsGroup: any;
  public existingValues: {
    pdf: any;
    image: any;
    video: any;
    pdfSrc: string;
    imgSrc: string;
    videoSrc: string;
  } = {
      pdf: "",
      image: "",
      video: "",
      pdfSrc: "",
      imgSrc: "",
      videoSrc: "",
    };
  //end legacy

  //media handling
  public selectedMedia: {
    name: string;
    date: string;
    meta: any;
    thumbnail_url: string;
    file_ext: string;
    id: number;
  };

  // thumbnails
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpeg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";

  public actionButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
      info: false,
      share: false,
      delete: false,
    };

  public columnVisibility: {
    thumbnail: boolean;
    pdfStatus: boolean;
    fileType: boolean;
    owner: boolean;
    urlType: boolean;
    dateCreated: boolean;
    shares: boolean;
  } = {
      thumbnail: true,
      pdfStatus: false,
      fileType: true,
      owner: true,
      urlType: false,
      dateCreated: true,
      shares: false,
    };

  public selectable: boolean = true;

  public filterByType: string = "all";

  public selectableType: string = "multiple";

  public clickableThumb: boolean = false;

  public systemButtons: any = {
    action: null,
    cancel: null,
  };

  public systemInteraction: string = "event_media";

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private _mediaManagementService: MediaManagementServicesService,
    private _clientManagementService: ClientManagementService,
    public scheduleGroupFrame: MDBModalRef,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private _settingsService: SettingsService,
    private TitleCase: TitleCasePipe,
    private eventMediaVersion2Service: EventMediaVersion2Service,
    private eventMediaVersion3Service: EventMediaVersion3Service
  ) { }

  ngOnInit(): void {
    ;
    this.attached = [];

    this.selectedMedia = {
      name: "",
      date: "",
      meta: {},
      thumbnail_url: "",
      file_ext: "",
      id: 0,
    };

    let clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.retrieveLabels();
    this.title =
      "Manage " + this.labels.event.singular + " " + this.labels.media.plural;
    this.retrieveToken();
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public manageMedia() {

    this.eventMediaVersion3Service.errorObj.subscribe((err) => {
      if (err) {
        this.errorMsg = err.errorMsg ? err.errorMsg : this.errorMsg;
        this.statusMsg = err.statusMsg ? err.statusMsg : this.statusMsg;
        this.formState = err.formState ? err.formState : this.formState;
      }
    });

    this.eventMediaVersion3Service
      .manageMediaLegacy(
        this.token,
        this.targetEvent,
        this.scheduleForm,
        this.media,
        this.labels.media.plural,
        this.attached
      )
      .then((res) => {
        if (res && res.status === "Asset successfully added to event")
          this.statusMsg = `${this.TitleCase.transform(
            this.labels.media.plural
          )} successfully added. Click Close to exit.`;
      });
  }

  private async getMediaMeta(mediaToUpdate) {
    let mediaMeta = [];

    await Promise.all(
      mediaToUpdate.map(async (media) => {
        const response = await this.getMediaItem(media.item);

        let toUpdate = {
          action: media.action,
          item: response,
        };

        mediaMeta.push(toUpdate);
      })
    );

    ;

    this.updateMediaMeta(mediaMeta);
  }

  private getMediaItem(media) {
    let getMediaItem = this._mediaManagementService.retrieveMediaItem(
      media.uuid,
      this.token
    );

    return getMediaItem.toPromise();
  }

  private async updateMediaMeta(mediaToUpdate) {
    await Promise.all(
      mediaToUpdate.map(async (media) => {
        const response = await this.updateMediaItem(media.item, media.action);
        ;
      })
    );

    //@todo: need to add error handling here
    this.statusMsg = `${this.TitleCase.transform(
      this.labels.media.plural
    )} successfully added.`;
  }

  private updateMediaItem(media, action) {
    let meta = {
      scheduled_events: [],
    };

    if (media.meta !== undefined && media.meta !== null) meta = media.meta;

    if (meta.scheduled_events === undefined) meta.scheduled_events = [];

    let existing = false;

    if (meta.scheduled_events.length) {
      meta.scheduled_events.forEach((event, index) => {
        if (event.id === this.targetEvent.schedule_id) {
          existing = true;
          if (action === "remove") meta.scheduled_events.splice(index, 1);
        }
      });
    }

    if (!existing && action === "add") {
      let thisAttached = {
        name: this.targetEvent.name,
        experience: this.targetEvent.experience,
        id: this.targetEvent.id,
      };

      meta.scheduled_events.push(thisAttached);
    }

    ;

    let body = {
      scheduled_events: meta.scheduled_events,
    };

    let updateMediaItem = this._mediaManagementService.manageMedia(
      media.uuid,
      this.token,
      "update-meta",
      body
    );

    return updateMediaItem.toPromise();
  }

  private determineCurrentMedia(media, media_id) {
    return media.filter((thisMedia, index) => {
      thisMedia.current_index = index;
      return thisMedia.id === media_id;
    });
  }

  public hideStatus(type) {
    if (type === "error") {
      this.errorMsg = "";
    } else {
      this.statusMsg = "";
    }
  }

  public resetMedia(type) {
    if (type === "pdf") {
      this.existingValues.pdf = "";
      this.existingValues.pdfSrc = "";
      this.scheduleForm.controls["pdfOptionsValue"].setValue("");
    } else if (type === "image") {
      this.existingValues.image = "";
      this.existingValues.imgSrc = "";
      this.scheduleForm.controls["imageOptionsValue"].setValue("");
    } else if (type === "video") {
      this.existingValues.video = "";
      this.existingValues.videoSrc = "";
      this.scheduleForm.controls["videoOptionsValue"].setValue("");
    }
  }

  public onParentActions(incoming) {

    ;

    if (incoming.action === "update_event_media") {

      this.media = incoming.media;
      this.attached = this.attached;

      this.scheduleForm = {
        value: {
          myOptionsArray: incoming.finalSelectedMedia
        }
      }

      this.noChange = false;

    }

    if (incoming.action === "closeModal") {
      this.scheduleGroupFrame.hide();
    }

  }
}
