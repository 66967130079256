import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { CoolLocalStorage } from "@angular-cool/storage";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: 'app-edit-class-room',
  templateUrl: './edit-class-room.component.html',
  styleUrls: ['./edit-class-room.component.scss'],
  providers: [TitleCasePipe]
})
export class EditClassRoomComponent implements OnInit {

  constructor(
    public editclassroom: MDBModalRef, 
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder,
    private TitleCase: TitleCasePipe ) { }

  private teamID: number;
  private event: any;
  public labels: any;
  public token: string;
  public rooms: any;
  public room_names: string[] = [];
  public roomForm;
  public roomFormGroup: UntypedFormGroup;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Update Room",
    retry: "Retry",
  };

  searchText = new Subject();
  results: Observable<string[]>;

  private outgoing: Subject<any> = new Subject();
  private outgoingData: any;
  ngOnInit(): void {
    this.retrieveLabels();
    this.retrieveToken();
    this.getRooms();
    this.roomForm = this.formBuilder.group({
      room_name: this.event.room[0].name
    });
    this.roomFormGroup = this.roomForm;
    this.results = this.searchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value))
    );
  }
  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }
  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private getRooms() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let rooms = this._xrPlatformRestService.getRooms(this.teamID, getOptions);
    rooms.subscribe((response) => {
      ;
      if (response.error != 418) {
        ;
        this.rooms = response.Rooms;
        response.Rooms.forEach((element) => {
          this.room_names.push(element.name);
        });
      }
      (error) => {
        //@todo: handle error
      }
    });
  }

  public clickEditRoom() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = `<span class='processing-msg'>Updating ${this.TitleCase.transform(this.labels.room.singular)}</span>`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      room_id: this.getRoomIdFromName(this.roomForm.value.room_name),
    };
    let edittedClassRoom = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/" + this.event.id + "/room/" + this.event.room[0].id,
      "put",
      data,
      getOptions
    );
    edittedClassRoom.subscribe(
      (response) => {
        ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.room.singular)} successfully updated`;

        if (response.error != 418) {
          ;
          let outgoingData = {
            action: "update",
            room: response.room_values.room
          }
          this.outgoing.next(outgoingData);
        }
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  private getRoomIdFromName(roomname: string) {
    let index = this.rooms.findIndex((x) => x.name === roomname);
    return this.rooms[index].id;
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.room_names.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }

  public resetForm() {
    this.roomForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    ;

    switch (action) {
      case "close-modal":
        this.editclassroom.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
