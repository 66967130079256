import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { throwError } from "rxjs";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import zoomPlugin from "chartjs-plugin-zoom";
//import { Chart, Point } from 'chart.js';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
import "chartjs-plugin-zoom";
import Chart from "chart.js/auto";
Chart.register(zoomPlugin);
import { MetricsServicesService } from "../../../services/metrics-services.service";

@Component({
  selector: 'app-data-entry-breakdown',
  templateUrl: './data-entry-breakdown.component.html',
  styleUrls: ['./data-entry-breakdown.component.scss']
})
export class DataEntryBreakdownComponent implements AfterViewInit {

  @Input("teamID") teamID: number;
  @Input("session_id") session_id: number;
  @Input("labels") labels: any;
  @Input("users") users: any;
  @Input("clientSettings") clientSettings: any;

  @ViewChild("dataEntryChart") private dataEntryChartRef: ElementRef;
  private chart: Chart;

  public metricsLoading: boolean = true;

  public chartTypeLT: string = "line";
  public chartDatasetsLT: Array<any> = [];
  public chartLabelsLT: Array<any> = [];

  public yLabels: Array<string>;

  public chartOptionsLT: any = {};
  public chartColorsLT: Array<any> = [
    {
      backgroundColor: ["#7F95D1"],
      borderColor: ["#7F95D1"],
    },
  ];

  public empty: boolean = true;

  public speakingLoading = false;
  public active: any;

  public aggregates: {
    value: number;
    label: string;
  }[];
  public chartColors: any = [];

  constructor(
    private http: HttpClient,
    private _clientManagementService: ClientManagementService,
    private _metricsServicesService: MetricsServicesService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.metricsLoading = true;
    this.chartColors = this._metricsServicesService.getMetricsColors()
    this.setupUserBreakdown();
  }

  public setupUserBreakdown() {
    let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let tmzParts = tmz.split("/");

    let retrieveMetricsData =
      this._metricsServicesService.restfulForetellAPIRequest(
        `session/${this.session_id}/users/breakdown/data?tmz_general=${tmzParts[0]}&tmz_specific=${tmzParts[1]}`,
        'get',
        {}
      );

    const randomColor = () => {
      return "hsl(" + 360 * Math.random() + "," + "70%," + "50%)";
    };

    const changeSaturation = (colorHSL) => {
      return colorHSL.replace("50", function () {
        return Math.floor(Math.random() * (85 - 75 + 1)) + 75;
      });
    };
    retrieveMetricsData.subscribe(
      (response: any) => {

        const labels = response.Graph.labelData;
        let temp_aggregates = []
        Object.keys(response.Aggregates[0]).forEach(function (key) {
          let thisAggregate = {
            label: key,
            value: response.Aggregates[0][key],
          };

          temp_aggregates.push(thisAggregate);
        });
        this.aggregates = temp_aggregates;
        this.metricsLoading = true;
        let datasets = [];
        let graph = response.Graph;
        let yLabels = [""];
        if (graph.Data.length != 0) {
          this.empty = false;
        }
        let i = 0;
        graph.Data.forEach((element) => {

          let user = {
            id: element.external_user_id,
            username: element.user_id
          };

          if (this.clientSettings.metricsUsername.unconstrained_default){
            let findUser = this.users.find((x) => x.id === parseInt(element.external_user_id));

            if (findUser === undefined || findUser === null) {
              user = {
                id: element.external_user_id,
                username: element.user_id
              }
            } else {
              user = {
                id: findUser.id,
                username: findUser.username
              }
            }
          }


          let label = user.id;

          if (this.clientSettings.metricsUsername.unconstrained_default) label = user.username;

          yLabels.push(label);
          let color = randomColor();
          let tempData = {
            data: element.periods,
            label: label,
            borderColor: this.chartColors[i],
            backgroundColor: this.chartColors[i],
            tension: 0,
            fill: false,
          };

          i += 1;
          if (i >= this.chartColors.length) {
            i = 0;
          }

          this.chartDatasetsLT.push(tempData);
          datasets.push(tempData);
        });
        let tempData = {
          data: Array(labels.length).fill(this.chartDatasetsLT.length + 1, 0),
          label: "",
          borderColor: 'rgba(255, 99, 132, 0)',
          backgroundColor: 'rgba(255, 99, 132, 0)',
          tension: 0,
          fill: false,
        }
        yLabels.push("");
        this.chartDatasetsLT.push(tempData);
        datasets.push(tempData);

        this.chartLabelsLT = graph.labelData;

        const getOrCreateTooltip = (chart) => {
          let tooltipEl = chart.canvas.parentNode.querySelector("div");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
            tooltipEl.style.borderRadius = "3px";
            tooltipEl.style.color = "white";
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.transform = "translate(-50%, 0)";
            tooltipEl.style.transition = "all .1s ease";
            tooltipEl.style.zIndex = "10000";
            tooltipEl.style.padding = "5px";

            const table = document.createElement("table");
            table.style.margin = "0px";

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
          }

          return tooltipEl;
        };

        const externalTooltipHandler = (context) => {
          const { chart, tooltip } = context;
          const tooltipEl = getOrCreateTooltip(chart);
          var y = tooltip["dataPoints"][0]["raw"];
          var index = labels.indexOf(tooltip["dataPoints"][0]["label"]);



          if (
            y != null &&
            y != 0 &&
            (datasets[y - 1]["data"][index - 1] == null ||
              datasets[y - 1]["data"][index + 1] == null)
          ) {
            let body_text = "";
            if (index == graph.Data[y - 1]['last_index']) {
              body_text += "Last time user entered data"
            }
            else if (index > graph.Data[y - 1]['last_index'] && datasets[y - 1]["data"][index + 1] == null) {
              body_text += "User exited app"
            }

            tooltipEl.style.opacity = 1;
            if (tooltip.body) {
              const titleLines = tooltip.title || [];
              const bodyLines = tooltip.body.map((b) => b.lines);

              const tableHead = document.createElement("thead");

              titleLines.forEach((title) => {
                const tr = document.createElement("tr");
                //tr.style.borderWidth = 0;

                const th = document.createElement("th");
                //th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
              });

              const tableBody = document.createElement("tbody");
              bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement("span");
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = "2px";
                span.style.marginRight = "10px";
                span.style.height = "10px";
                span.style.width = "10px";
                span.style.display = "inline-block";

                const tr = document.createElement("tr");
                tr.style.backgroundColor = "inherit";
                //tr.style.borderWidth = 0;

                const td = document.createElement("td");
                //td.style.borderWidth = 0;

                const text = document.createTextNode(body_text);
                if (body_text != "") {
                  td.appendChild(span);
                  td.appendChild(text);
                  tr.appendChild(td);
                  tableBody.appendChild(tr);
                }
              });

              const tableRoot = tooltipEl.querySelector("table");

              // Remove old children
              while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
              }

              // Add new childSren
              tableRoot.appendChild(tableHead);
              tableRoot.appendChild(tableBody);
            }

            const { offsetLeft: positionX, offsetTop: positionY } =
              chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + "px";
            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
              tooltip.padding + "px " + tooltip.padding + "px";
          } else {
            tooltipEl.style.opacity = 0;
          }
        };
        const zoomOptions = {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        };
        const data = {
          labels: labels,
          datasets: datasets,
          yLabels: yLabels,
        };

        this.chartOptionsLT = {
          responsive: true,
          maintainAspectRatio: false,

          legend: { display: false },
          interaction: {
            intersect: false,
          },
          plugins: {
            zoom: zoomOptions,
            title: {
              display: true,
              text: graph.graphTitle,
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: externalTooltipHandler,
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Time",
              },
              ticks: {},
              grid: {
                lineWidth: function adjustLineEndPoints(context) {
                  return context.tick.value % 60 === 0 ? 3 : 1;
                },
              },
            },
            y: {
              title: {
                display: true,
                text: "Username",
              },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                callback: function (value, index, values) {
                  return yLabels[index];
                },
              },
            },
          },
          elements: {
            point: {
              radius: function adjustLineEndPoints(context) {
                var y = context.parsed.y;
                var index = context.parsed.x;
                if (
                  y != null &&
                  y != 0 &&
                  (datasets[y - 1]["data"][index - 1] == null ||
                    datasets[y - 1]["data"][index + 1] == null)
                ) {
                  return 5;
                }
                return 0;
              },
              hoverRadius: function adjustLineEndPoints(context) {
                var y = context.parsed.y;
                var index = context.parsed.x;
                if (
                  y != null &&
                  y != 0 &&
                  (datasets[y - 1]["data"][index - 1] == null ||
                    datasets[y - 1]["data"][index + 1] == null)
                ) {
                  return 10;
                }
                return 0;
              },
            },
            line: {
              borderWidth: 5,
            },
          },
        };
        this.chart = new Chart(this.dataEntryChartRef.nativeElement, {
          type: "line",
          data: data,
          options: this.chartOptionsLT,
        });

        this.metricsLoading = false;
      },
      (error) => {
        ;
      }
    );
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }

}
