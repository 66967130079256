<app-media-manager
  [actionButtons]="actionButtons"
  [columnVisibility]="columnVisibility"
  [showHeading]="showHeading"
  [selectable]="selectable"
  [selectedMedia]="selectedMedia"
  [filterByType]="filterByType"
  [selectableType]="selectableType"
  [clickableThumb]="clickableThumb"
  [systemButtons]="systemButtons"
  (selected)="printSelected($event)"
></app-media-manager>
<!--Scroll to top-->
<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
  <a
    mdbBtn
    floating="true"
    class="back-top-btn"
    mdbWavesEffect
    (click)="scrollToTop()"
    mdbTooltip="Back To Top"
  >
    <mdb-icon fas icon="arrow-alt-circle-up"></mdb-icon>
  </a>
</div>
