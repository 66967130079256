<div class="modal-header text-center">
  <h4 class="modal-title w-100">
    <mdb-icon fas icon="photo-video"></mdb-icon> {{ title | titlecase }}
  </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="addBackpackModal.hide()"
    mdbTooltip="Close"
    placement="bottom"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body media-modal mt-3 p-0">
  <!-- <div class="row" *ngIf="actionMsg">
    <div class="col-12">
      <p class="mb-0">
        {{ actionMsg }}
      </p>
    </div>
  </div>
  <hr /> -->
  <!-- media -->
  <div class="row">
    <app-media-manager
      [teamID]="teamID"
      [defaultTab]="'personal-media'"
      [actionButtons]="actionButtons"
      [columnVisibility]="columnVisibility"
      [selectable]="selectable"
      [filterByType]="filterByType"
      [selectableType]="selectableType"
      [clickableThumb]="clickableThumb"
      [systemButtons]="systemButtons"
      [systemInteraction]="systemInteraction"
      [defaultTab]="'personal-media'"
      (parentActions)="handleIncomingActions($event)"
    ></app-media-manager>
  </div>
</div>
