import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ViewPersonaPermissionModalComponent } from "./modals/view-persona-permission-modal/view-persona-permission-modal.component";
import { ArchiveModalComponent } from "./modals/archive-modal/archive-modal.component";
import { ManageRoomModalComponent } from "./modals/manage-room-modal/manage-room-modal.component";
import { ManageExperienceModalComponent } from "./modals/manage-experience-modal/manage-experience-modal.component";
import { ManageRoleModalComponent } from "./modals/manage-role-modal/manage-role-modal.component";
import { ManagePersonaModalComponent } from "./modals/manage-persona-modal/manage-persona-modal.component";
import { ManagePermissionModalComponent } from "./modals/manage-permission-modal/manage-permission-modal.component";

@Component({
  selector: "app-entity-management",
  templateUrl: "./entity-management.component.html",
  styleUrls: ["./entity-management.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EntityManagementComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private modalService: MDBModalService
  ) {}

  public loading: boolean;
  public copy: string;

  public teamID: number;
  public token: string;

  public rooms: any;
  public locations: any;
  public branding_spaces: any;
  public experiences: any;
  public event_types: any;
  public roles: any;
  public personas: any;
  public permissions: any;
  headElements = ["Name", "ID", "Description", "", ""];
  sort_elements = ["name", "ID", "description", "", ""];

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-add-room",
    containerClass: "",
    animated: true,
    data: {},
  };

  ngOnInit(): void {
    this.loading = true;
    this.retrieveTeamID();
    this.retrieveToken();
    this.setupRooms();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private setupRooms() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let rooms_request = this._xrPlatformRestService.getRooms(
      this.teamID,
      getOptions
    );
    rooms_request.subscribe((params) => {
      ;
      this.rooms = params.Rooms;
      this.retrieveLocations();
    });
  }

  private retrieveLocations() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let locations_incoming = this._xrPlatformRestService.getLocations(
      this.teamID,
      options
    );
    locations_incoming.subscribe((data) => {
      ;
      let locations = data.locations.filter((location) => {
        return !location.archived;
      });
      this.locations = locations;
      this.setupBrandingSpaces();
      ;
    });
  }

  private setupBrandingSpaces() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let spaces_request = this._xrPlatformRestService.getBrandingSpaces(
      this.teamID,
      getOptions
    );
    spaces_request.subscribe((params) => {
      ;
      this.branding_spaces = params.Spaces;
      this.setupExperiences();
    });
  }

  private setupExperiences() {
    ;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let experiences_request = this._xrPlatformRestService.getLegacyExperiences(
      this.teamID,
      getOptions
    );

    experiences_request.subscribe((params) => {
      ;
      this.experiences = params.ExperiencesLegacy;
      this.getEventType();
    });
    ;
  }

  private getEventType() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let globalEventTypes = this._xrPlatformRestService.restfulAPIQuery(
      "/scheduled-event-types",
      "get",
      {},
      options
    );

    globalEventTypes.subscribe((data) => {
      this.event_types = data.event_types;
      this.setupRoles();
      this.assignEventLabel();
    });
  }

  private assignEventLabel() {
    let experience = this.experiences;
    let experience_event_type = this.event_types;

    let addedLabel = experience.map((item) => ({
      ...item,
      event_type: experience_event_type.filter(
        (f) => f.id == item.event_type_id
      ),
    }));
    ;
    this.experiences = addedLabel;
  }

  private setupRoles() {
    ;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let roles_request = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/role",
      "get",
      data,
      getOptions
    );
    roles_request.subscribe((params) => {
      ;
      this.roles = params.roles;
      this.setupPersonas();
    });
  }

  private setupPersonas() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let personas_request = this._xrPlatformRestService.restfulAPIQuery(
      "/personas/findpersonasbyclient/" + this.teamID,
      "get",
      data,
      getOptions
    );
    personas_request.subscribe((params) => {
      ;
      this.personas = params.personas;
      this.setupPermissions();
    });
  }

  private setupPermissions() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let permissions_request = this._xrPlatformRestService.restfulAPIQuery(
      "/personas/getpermissions",
      "get",
      data,
      getOptions
    );
    permissions_request.subscribe((params) => {
      ;
      this.permissions = params.permissions;
      this.loading = false;
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public openAddRoomModal() {
    this.modalOptions.data = {
      team_id: this.teamID,
      mode: "Add",
    };

    this.modalOptions.class = "";
    this.modalFrame = this.modalService.show(
      ManageRoomModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.rooms.push(changedData.room);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openEditRoomModal(room: any, index: number) {
    this.modalOptions.data = {
      team_id: this.teamID,
      room: room,
      index: index,
      mode: "Edit",
    };

    this.modalOptions.class = "";
    this.modalFrame = this.modalService.show(
      ManageRoomModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            this.rooms[index] = changedData.room;
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openAddExperienceModal() {
    this.modalOptions.data = {
      team_id: this.teamID,
      global_eventTypes: this.event_types,
      mode: "Add",
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ManageExperienceModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      console.log(
        "changedData in modalFrame subscribe inside of EntityManagementComponent::openAddExperienceModal()",
        changedData
      );

      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.experiences.push(changedData.experience);
            this.assignEventLabel();
            break;
          case "update":
            let thisExperience = changedData.experience;

            this.experiences.forEach((experience) => {
              if (experience.id === thisExperience.id)
                experience = thisExperience;
            });

            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openEditExperienceModal(experience: any, index: number) {
    this.modalOptions.data = {
      team_id: this.teamID,
      experience: experience,
      global_eventTypes: this.event_types,
      mode: "Edit",
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ManageExperienceModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      console.log(
        "changeData in manage experience modal openEditExperienceModal()",
        changedData
      );
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            this.experiences[index] = changedData.experience;
            this.assignEventLabel();
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openAddRoleModal() {
    this.modalOptions.data = {
      team_id: this.teamID,
      mode: "Add",
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ManageRoleModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.roles.push(changedData.role);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openEditRoleModal(role: any, index: number) {
    this.modalOptions.data = {
      team_id: this.teamID,
      role: role,
      mode: "Edit",
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ManageRoleModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      ;
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            ;
            this.roles[index] = changedData.role;
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openAddPersonaModal() {
    this.modalOptions.data = {
      team_id: this.teamID,
      mode: "Add",
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ManagePersonaModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.personas.push(changedData.persona);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openEditPersonaModal(persona: any, index: number) {
    this.modalOptions.data = {
      team_id: this.teamID,
      persona: persona,
      mode: "Edit",
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ManagePersonaModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      ;
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            ;
            this.personas[index] = changedData.persona;
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openViewPersonaPermissionModal(persona: any) {
    this.modalOptions.data = {
      team_id: this.teamID,
      persona: persona,
    };
    this.modalOptions.class = "modal-dialog-centered modal-lg";
    this.modalFrame = this.modalService.show(
      ViewPersonaPermissionModalComponent,
      this.modalOptions
    );
  }

  public openAddPermissionModal() {
    this.modalOptions.data = {
      team_id: this.teamID,
      mode: "Add",
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ManagePermissionModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.permissions.push(changedData.permission);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openEditPermissionModal(permission: any, index: number) {
    this.modalOptions.data = {
      team_id: this.teamID,
      permission: permission,
      mode: "Edit",
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ManagePermissionModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      ;
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            ;
            this.permissions[index] = changedData.permission;
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openArchiveEntityModal(
    entity: any,
    entityType: string,
    index: number
  ) {
    this.modalOptions.data = {
      team_id: this.teamID,
      entity: entity,
      entityType: entityType,
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ArchiveModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            ;
            this.removeArchivedElement(entityType, index);
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  private removeArchivedElement(entityType: string, index: number) {
    if (entityType == "persona") {
      this.personas.slice(index, 1);
    } else if (entityType == "room") {
      this.rooms.splice(index, 1);
    } else if (entityType == "location") {
      this.locations.splice(index, 1);
    } else if (entityType == "permission") {
      this.permissions.splice(index, 1);
    } else if (entityType == "role") {
      this.roles.splice(index, 1);
    } else if (entityType == "experience") {
      this.experiences.splice(index, 1);
    } else if (entityType == "branding space") {
      this.branding_spaces.splice(index, 1);
    }
  }
}
