import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-manage-room-modal",
  templateUrl: "./manage-room-modal.component.html",
  styleUrls: ["./manage-room-modal.component.scss"],
})
export class ManageRoomModalComponent implements OnInit {

  constructor(
    public manageRoomModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder
  ) { }

  public team_id: number;
  public token: string;
  public room: any;
  public mode: any;

  public roomForm: UntypedFormGroup;

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
      errorMsg: "",
      statusMsg: "",
      processingMsg: "",
    };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      reset: "Add Another Room",
      retry: "Retry",
    };
  private outgoing: Subject<any> = new Subject();
  private outgoingData: any;

  ngOnInit(): void {

    if (this.mode === 'Add') {
      this.continueType = "reset";
      this.roomForm = this.formBuilder.group({
        room_name: "",
        room_description: "",
      });
    } else if (this.mode === 'Edit') {
      this.btnLabel.retry = "Continue Editing";
      this.continueType = "continue";
      this.roomForm = this.formBuilder.group({
        room_name: this.room.name,
        room_description: this.room.description,
      });
    }

    this.retrieveToken();
  }
  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }
  public handleIncomingAction(action) {
    ;

    switch (action) {
      case "close-modal":
        this.manageRoomModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
  public resetForm() {
    this.roomForm.reset();
    this.clearStatus();
  }
  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }
  manageRoom() {
    if (this.mode === 'Add') {
      this.addRoom();
    } else if (this.mode === 'Edit') {
      this.editRoom();
    }
  }
  addRoom() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Adding room</span>";
    ;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      name: this.roomForm.value.room_name,
      description: this.roomForm.value.room_description,
    };
    let addedRoom = this._xrPlatformRestService.addRoom(
      this.team_id,
      data,
      getOptions
    );
    addedRoom.subscribe(
      (response) => {
        ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Room successfully added";
        let outgoingData = {
          action: 'add',
          room: response.room
        }
        this.outgoing.next(outgoingData);

      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }
  editRoom() {
    ;

    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Updating room</span>";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      name: this.roomForm.value.room_name,
      description: this.roomForm.value.room_description,
    };
    let edittedRoom = this._xrPlatformRestService.editRoom(
      this.team_id,
      this.room.id,
      data,
      getOptions
    );
    edittedRoom.subscribe(
      (response) => {
        ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Room successfully udpated";

        if (response.error != 418) {
          let outgoingData = {
            action: 'update',
            room: response.room
          }
          this.outgoing.next(outgoingData);
          ;
        }
      },
      (error) => {
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }
}
