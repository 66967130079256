<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <mdb-icon fas icon="photo-video"></mdb-icon> {{ title | titlecase }}
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="scheduleGroupFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body media-modal content-card modal-fluid-content px-3 py-0"
  >
    <div class="row">
      <div class="col-12">
        <p class="mb-0">
          {{ labels.event.singular | titlecase }} Name: {{ targetEvent.name }}
        </p>
      </div>
    </div>
    <!-- media -->
    <div class="row fluid-content container-fluid">
      <app-media-manager
        [teamID]="teamID"
        [defaultTab]="'all-media'"
        [actionButtons]="actionButtons"
        [columnVisibility]="columnVisibility"
        [selectable]="selectable"
        [filterByType]="filterByType"
        [selectableType]="selectableType"
        [clickableThumb]="clickableThumb"
        [systemButtons]="systemButtons"
        [systemInteraction]="systemInteraction"
        [TargetEntity]="targetEvent"
        (parentActions)="onParentActions($event)"
      ></app-media-manager>
    </div>
    <!-- end media -->
  </div>
  <div class="modal-footer">
    <div
      class="alert custom-alert d-flex"
      role="alert"
      [ngClass]="{
        'visible alert-danger': errorMsg !== '',
        'visible alert-info': statusMsg !== ''
      }"
    >
      <div *ngIf="errorMsg !== ''" [innerHTML]="errorMsg"></div>
      <div *ngIf="statusMsg !== ''" [innerHTML]="statusMsg"></div>

      <button
        *ngIf="errorMsg !== ''"
        type="button"
        class="close close-status"
        aria-label="Close"
        (click)="hideStatus('error')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <button
        *ngIf="statusMsg !== ''"
        type="button"
        class="close close-status"
        aria-label="Close"
        (click)="hideStatus('status')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      data-dismiss="modal"
      [disabled]="noChange"
      (click)="manageMedia()"
    >
      {{ btnLabel.main }}
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="scheduleGroupFrame.hide()"
    >
      {{ btnLabel.close }}
    </button>
  </div>
</div>
