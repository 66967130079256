<div class="event-types-container">
  <div class="row justify-content-between">
    <div class="col-4">
      <div class="md-form w-100 mr-2 experience-search">
        <input
          class="form-control"
          id="search"
          type="search"
          mdbInput
          [(ngModel)]="experiencesGlobalSearchText"
        />
        <label for="search">Search Experiences</label>
      </div>
    </div>
    <div class="col-3">
      <div class="btn-spacing text-right">
        <button
          class="theme-modal-button btn"
          type="button"
          mdbBtnp
          mdbWavesEffect
          (click)="manageExperience('add')"
        >
          <mdb-icon far icon="plus-square" class="pr-1"></mdb-icon>
          Add Experience
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="!experiencesGlobalLoading">
      <div class="card-group">
        <div
          *ngFor="
            let experience of experiencesGlobal
              | filter : experiencesGlobalSearchText;
            let i = index
          "
        >
          <mdb-card>
            <mdb-card-body>
              <mdb-card-title>
                <h5 class="mb-3">{{ experience.label }}</h5>
              </mdb-card-title>
              <mdb-card-text class="mb-0">
                <ul class="list-unstyled">
                  <li>
                    Max Attendees: {{ experience.max_attendees }}
                  </li>
                  <li>
                    {{ experience.description }}
                  </li>
                </ul>
              </mdb-card-text>
            </mdb-card-body>
            <mdb-card-footer class="modal-footer">
              <a
                (click)="manageExperience('edit', i, experience)"
                mdbTooltip="Edit"
                placement="top"
              >
                <mdb-icon far icon="edit"></mdb-icon>
              </a>
              <a
                (click)="archiveExperience(experience)"
                mdbTooltip="Archive"
                placement="top"
              >
                <mdb-icon fas icon="archive"></mdb-icon>
              </a>
            </mdb-card-footer>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
</div>
