<app-status-messages
  [msgs]="msgs"
  [btnLabel]="btnLabel"
  [continueType]="continueType"
  (targetAction)="handleIncomingAction($event)"
></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">
      <mdb-icon fas icon="user-plus"></mdb-icon> {{ title | titlecase}}
    </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="userToGroupFrame.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body m-3 p-0">
  {{ msgs.actionMsg }}
  <p *ngIf="formLoading">Loading {{labels.user.plural}}...</p>
  <form
    *ngIf="!formLoading"
    [formGroup]="scheduleForm"
    class="text-center"
  >
    <div class="md-form mb-5" *ngIf="action !== 'remove'">
      <mdb-select
        [options]="selectUsers"
        placeholder="Select {{labels.user.singular|titlecase}}"
        formControlName="group_user"
      ></mdb-select>
      <mdb-error
        *ngIf="
          group_user &&
          group_user.invalid &&
          (group_user.dirty || group_user.touched)
        "
      >
        Input invalid
      </mdb-error>
      <mdb-success
        *ngIf="
          group_user &&
          group_user.valid &&
          (group_user.dirty || group_user.touched)
        "
      >
        Input valid
      </mdb-success>
    </div>
    <div class="md-form mb-5" *ngIf="action === 'remove'">
      <p>
        <strong>{{this.labels.userGroupMember.singular | titlecase}} Name:</strong> {{ targetUser.first_name }}
        {{ targetUser.last_name }}
      </p>
    </div>
  </form>
</div>

  <div class="modal-footer">
    <button *ngIf="!formLoading" mdbBtn type="button" mdbWavesEffect class="theme-modal-button btn" data-dismiss="modal"
      [disabled]="!scheduleForm.valid ||formState === 'processing'"
      (click)="userToGroup()">
      {{ btnLabel.main }}
    </button>
    <button mdbBtn mdbWavesEffect type="button" size="md" color="link" data-dismiss="modal"
      (click)="userToGroupFrame.hide()">
      {{ btnLabel.close }}
    </button>
  </div>
