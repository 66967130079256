<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">
      {{ title | titlecase }} <mdb-icon far icon="calendar-alt"></mdb-icon>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body media-modal content-card modal-fluid-content px-3 py-0"
  >
    <div class="row fluid-content container-fluid">
      <div
        class="col-12 h-100 content-section content-pdfs"
        *ngIf="type === 'pdf'"
      >
        <app-media-manager
          [teamID]="teamID"
          [defaultTab]="'all-media'"
          [actionButtons]="actionButtons"
          [columnVisibility]="columnVisibility"
          [selectable]="selectable"
          [selectedMedia]="selectedMedia"
          [filterByType]="filterByType"
          [selectableType]="selectableType"
          [systemInteraction]="systemInteraction"
          [clickableThumb]="clickableThumb"
          [systemButtons]="systemButtons"
          [TargetEntity]="experience"
          (selected)="outputSelected($event)"
          (parentActions)="onParentActions($event)"
        ></app-media-manager>
      </div>
      <div
        class="col-12 h-100 content-section content-images"
        *ngIf="type === 'image'"
      >
        <app-media-manager
          [teamID]="teamID"
          [defaultTab]="'all-media'"
          [actionButtons]="actionButtons"
          [columnVisibility]="columnVisibility"
          [selectable]="selectable"
          [selectedMedia]="selectedMedia"
          [filterByType]="filterByType"
          [selectableType]="selectableType"
          [clickableThumb]="clickableThumb"
          [systemButtons]="systemButtons"
          [systemInteraction]="systemInteraction"
          [TargetEntity]="experience"
          (selected)="outputSelected($event)"
          (parentActions)="onParentActions($event)"
        ></app-media-manager>
      </div>
      <div
        class="col-12 h-100 content-section content-images"
        *ngIf="type === 'video' || type === 'video_360'"
      >
        <app-media-manager
          [teamID]="teamID"
          [defaultTab]="'all-media'"
          [actionButtons]="actionButtons"
          [columnVisibility]="columnVisibility"
          [selectable]="selectable"
          [selectedMedia]="selectedMedia"
          [filterByType]="filterByType"
          [selectableType]="selectableType"
          [clickableThumb]="clickableThumb"
          [systemButtons]="systemButtons"
          [systemInteraction]="systemInteraction"
          [TargetEntity]="experience"
          (selected)="outputSelected($event)"
          (parentActions)="onParentActions($event)"
        ></app-media-manager>
      </div>
      <div
        class="col-12 h-100 content-section content-images"
        *ngIf="type === 'file'"
      >
        <app-media-manager
          [teamID]="teamID"
          [defaultTab]="'all-media'"
          [actionButtons]="actionButtons"
          [columnVisibility]="columnVisibility"
          [selectable]="selectable"
          [selectedMedia]="selectedMedia"
          [filterByType]="filterByType"
          [selectableType]="selectableType"
          [clickableThumb]="false"
          [systemButtons]="systemButtons"
          [systemInteraction]="systemInteraction"
          [TargetEntity]="experience"
          (selected)="outputSelected($event)"
          (parentActions)="onParentActions($event)"
        ></app-media-manager>
      </div>
    </div>
  </div>
  <div class="modal-footer with-preview">
    <div class="preview" *ngIf="type !== 'file'">
      <div class="content">
        <p class="semi-bold">Selected {{ typeLabel }}:</p>
        <button
          mdbBtn
          mdbWavesEffect
          type="button"
          color="link"
          class="theme-modal-button light btn"
          (click)="removeLogo()"
        >
          No {{ typeLabel }}
        </button>
      </div>
      <div class="image-preview">
        <div class="thumbnail-container">
          <div
            *ngIf="selectedMedia[0]"
            class="img-thumbnail"
            [style.background-image]="
              selectedMedia[0].thumbnail_asset_url
                ? 'url(' + selectedMedia[0].thumbnail_asset_url + ')'
                : !selectedMedia[0].thumbnail_asset_url &&
                  selectedMedia[0].file_ext === 'pdf'
                ? 'url(' + placeHolderPdf + ')'
                : !selectedMedia[0].thumbnail_asset_url &&
                  selectedMedia[0].file_ext === 'mp4'
                ? 'url(' + placeHolderMp4 + ')'
                : !selectedMedia[0].thumbnail_asset_url &&
                  selectedMedia[0].file_ext === 'webp'
                ? 'url(' + placeHolderWebp + ')'
                : !selectedMedia[0].thumbnail_asset_url &&
                  selectedMedia[0].file_ext === 'wav'
                ? 'url(' + placeHolderWav + ')'
                : !selectedMedia[0].thumbnail_asset_url &&
                  selectedMedia[0].file_ext === 'mp3'
                ? 'url(' + placeHolderMp3 + ')'
                : !selectedMedia[0].thumbnail_asset_url &&
                  selectedMedia[0].file_ext === 'png'
                ? 'url(' + placeHolderImg + ')'
                : 'url(' + placeHolderImg + ')'
            "
          ></div>
          <div
            *ngIf="!selectedMedia[0]"
            class="img-thumbnail"
            [style.background-image]="'url(' + placeHolderImg + ')'"
          ></div>
        </div>
      </div>
    </div>
    <div class="modal-actions">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        [disabled]="isClean"
        class="theme-modal-button btn"
        (click)="manageMedia()"
      >
        {{ buttonLabel }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn"
        (click)="closeModal()"
      >
        {{ btnLabel.close }}
      </button>
    </div>
  </div>
</div>
