import { UntypedFormGroup } from "@angular/forms";
import { ExperienceServicesService } from "src/app/modules/experience-management/services/experience-services.service";

export function EventCapacity(
  experienceFormName: string,
  numberOfAttendeesPropName: string,
  experiences: any,
  attendeePropID: number,
  teamPropOptions: any
) {
  return (formGroup: UntypedFormGroup) => {
    let selectedAttendeeID =
      formGroup.controls[numberOfAttendeesPropName].value;

    if(selectedAttendeeID === null) return null;

    let getCapacity = teamPropOptions.find(
      (option) =>
        option.prop_id === attendeePropID && option.id === selectedAttendeeID
    );
    let currentCapacity = getCapacity.value;

    let formExperiences = formGroup.controls[experienceFormName];

    //track number of event capacity errors vs missing experience errors
    let eventCapacityErrors = 1;
    let missingExperienceErrors = 1;

    formExperiences["controls"].forEach((experience, index) => {
      let selectedExperienceValue = experience["controls"].experience_id.value;
      let selectedExperience = experiences.find(
        (experience) => experience.experience_id === selectedExperienceValue
      );

      if (selectedExperience === undefined) {
        formExperiences.setErrors({
          missingExperience: missingExperienceErrors++,
        });
        return;
      }

      let capacity = selectedExperience.experience.max_attendees;

      if (capacity < currentCapacity) {
        experience.controls["experience_id"].setErrors({ eventCapacity: true });

        let errors = formExperiences.errors;
        if (errors === null) {
          formExperiences.setErrors({ eventCapacity: eventCapacityErrors++ });
        } else if(errors.eventCapacity === undefined && errors.missingExperience !== null){
          formExperiences.setErrors({ eventCapacity: eventCapacityErrors++, missingExperience: errors.missingExperience });
        } else if(errors.eventCapacity !== undefined && errors.missingExperience === null){
          formExperiences.setErrors({ eventCapacity: errors.eventCapacity++ });
        }
         
      } else {
        experience.controls["experience_id"].setErrors(null);
      }
    });

    return null;
  };
}
