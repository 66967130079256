import { Component, OnInit } from "@angular/core";
import { CoolLocalStorage } from "@angular-cool/storage";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
// modals
import { AddEventTypeModalComponent } from "./modals/add-event-type-modal/add-event-type-modal.component";

@Component({
  selector: "app-event-types",
  templateUrl: "./event-types.component.html",
  styleUrls: ["./event-types.component.scss"],
})
export class EventTypesComponent implements OnInit {
  public token: string;
  public user: any;

  public eventTypesLoading: boolean = true;
  public eventTypesOptionsLoading: boolean = true;

  public eventTypesSearchText: string = "";

  public eventTypes: any = [];
  public eventTypesOptions: any = [];

  public teamID: number;
  public copy = "";

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _clientManagement: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private route: ActivatedRoute,
    private modalService: MDBModalService,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.retrieveUser();
    this.retrieveToken();
    this.retrieveEventTypes();
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveEventTypes() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let eventTypes = this._xrPlatformRestService.restfulAPIQuery(
      "/scheduled-event-types",
      "get",
      {},
      options
    );

    eventTypes.subscribe(
      (response) => {
        ;
        this.eventTypes = response.event_types;
        this.eventTypesLoading = false;
      },
      (error) => {}
    );
  }

  public openAddEventTypesModal() {
    this.modalOptions.data = {
      team_id: this.teamID,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "add-event-types-container",
    };

    this.modalFrame = this.modalService.show(
      AddEventTypeModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.eventTypes.push(changedData.prop);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openViewEventTypeModal(prop) {
    this.modalOptions.data = {
      team_id: this.teamID,
      // prop: prop,
    };

    ;

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "view-event-types-container",
    };
    this.modalFrame = this.modalService.show(
      // ViewPropModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.eventTypes.push(changedData.prop);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openArchiveEventTypesModal(prop, index, type) {
    this.modalOptions.data = {
      teamID: this.teamID,
      // propID: prop,
      // type: type,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "archive-event-types-container",
    };
    this.modalFrame = this.modalService.show(
      // ArchivePropModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.eventTypes.push(changedData.prop);
            break;
          case "update":
            break;
          case "delete":
            this.eventTypes.splice(index, 1);
            break;
        }
      } else {
      }
    });
  }
}
