<div class="modal-content-wrapper" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      Add {{ media_item.name | titlecase }} to {{ labels.event.plural }}
    </h4>
    <button
      *ngIf="isClean || action === 'delete'"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean && action !== 'delete'"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="add-user-management-card content-card modal-fluid-content">
    <div class="row d-flex align-items-center">
      <div class="col-4 available-users">
        <div class="md-form">
          <input
            type="search"
            [(ngModel)]="SearchText"
            (ngModelChange)="search()"
            class="form-control"
            id="Search"
            [disabled]="eventsLoading"
            mdbInput
          />
          <label class="active-label active" for="Search"
            >Search Available {{ labels.event.plural | titlecase }}</label
          >
        </div>
      </div>
    </div>
    <div class="row fluid-content">
      <div class="col-4 h-100 tabs-table available">
        <mdb-card class="user-card h-100">
          <mdb-tabset
            #availableTabs
            id="availableTabs"
            [buttonClass]="'classic-tabs'"
            class="admin-tab-styles"
            contentClass="remove-card available-tabs h-100"
            tabsButtonsClass="tab-nav-fluid"
            tabsContentClass="tab-content-fluid"
          >
            <mdb-tab heading="Available {{ labels.event.plural }}"
              ><div
                *ngIf="eventsLoading"
                class="loading-screen d-flex align-items-center h-100 justify-content-center"
              >
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <table
                *ngIf="!eventsLoading"
                class="table table-responsive-md btn-table available-users-table"
                mdbTable
                mdbTableScroll
                small="true"
                scrollY="true"
              >
                <colgroup>
                  <col span="1" style="width: 20px" />
                  <col span="1" style="width: 100%" />
                </colgroup>
                <tbody>
                  <tr
                    mdbTableCol
                    *ngFor="let event of eventList; index as event_index"
                  >
                    <td class="pr-0">
                      <button
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="addEvent(event)"
                      >
                        <fa-icon [icon]="faSquarePlus"></fa-icon>
                      </button>
                    </td>
                    <td>
                      <span>{{ event.event_name }}</span>
                    </td>
                  </tr>
                </tbody>
              </table></mdb-tab
            >
          </mdb-tabset>
        </mdb-card>
      </div>
      <div class="col-8 h-100 tabs-table roster">
        <mdb-tabset
          #addedTabs
          id="addedTabs"
          [buttonClass]="'classic-tabs'"
          class="admin-tab-styles"
          contentClass="remove-card added-tabs h-100"
          tabsButtonsClass="tab-nav-fluid"
          tabsContentClass="tab-content-fluid"
        >
          <mdb-tab heading="{{ labels.event.plural | titlecase }} Added To"
            ><div
              *ngIf="attachedEventsLoading"
              class="loading-screen d-flex align-items-center h-100 justify-content-center"
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div
              class="responsive"
              *ngIf="addedEvents.length > 0 && !attachedEventsLoading"
            >
              <table
                class="table table-striped table-responsive-md btn-table user-table"
                mdbTable
                mdbTableScroll
                #tableEl="mdbTable"
                small="true"
                scrollY="true"
                maxHeight="450"
              >
                <colgroup>
                  <col span="1" style="width: 22%" />
                  <col span="1" style="width: 22%" />
                  <col span="1" style="width: 22%" />
                  <col span="1" style="width: 34%" />
                </colgroup>
                <thead class="theme-base-medium-dark white-text sticky-top">
                  <tr>
                    <th>Remove {{ labels.event.singular }} Connection</th>
                    <th>{{ labels.event.singulr }} Name</th>
                    <th>Starts</th>
                    <th>Ends</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    mdbTableCol
                    *ngFor="let event of addedEvents; index as event_index"
                  >
                    <td>
                      <button
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="removeAddedEvent(event)"
                      >
                        <fa-icon [icon]="faSquareMinus"></fa-icon>
                      </button>
                    </td>
                    <td>
                      <span>{{ event.event_name }}</span>
                    </td>
                    <td>
                      <span class="date">{{
                        event.start_at | amDateFormat : "MMM D YYYY"
                      }}</span>
                      <br />
                      <span class="time">{{
                        event.start_at | amDateFormat : "h:mm a"
                      }}</span>
                    </td>
                    <td>
                      <span
                        class="date"
                        [ngClass]="{ 'different-day': !event.sameDay }"
                        >{{ event.end_at | amDateFormat : "MMM D YYYY" }}</span
                      >
                      <br />
                      <span class="time">{{
                        event.end_at | amDateFormat : "h:mm a"
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="empty-message" *ngIf="addedEvents.length === 0">
              <p class="m-0">
                Not currently added to any {{ labels.event.plural }}. Select
                {{ labels.event.plural }} on the left to attach this media item
                to.
              </p>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      [disabled]="isClean || processing"
      (click)="updateAttachedEvents()"
    >
      Update {{ labels.media.singular }}
    </button>

    <button
      *ngIf="isClean"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>
<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <div class="left-arrow"></div>
    <p class="semi-bold">
      Warning! All {{ action === "add" ? "work" : "updates" }} on this
      {{ labels.event.singular }} will be lost.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        (click)="closePopOvers()"
      >
        Back to Edit {{ labels.event.singular }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        (click)="closeModal(true)"
      >
        Continue Cancel
      </button>
    </div>
  </div></ng-template
>
