import { Component, OnInit } from "@angular/core";

//third-party deps
import { CoolLocalStorage } from "@angular-cool/storage";

//services
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-events-base",
  templateUrl: "./events-base.component.html",
  styleUrls: ["./events-base.component.scss"],
})
export class EventsBaseComponent implements OnInit {
  //persistent
  public labels: any;
  public clientSettings: any;
  public teamID: number = 0;
  public isLegacy: boolean = false;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService,
    private _clientManagementService: ClientManagementService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    if (this.route.routeConfig.path.indexOf("legacy") !== -1) this.isLegacy = true;

    this.retrieveTeamID();
    this.retrieveLabels();
    this.retrieveSettings();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(this.coolLocalStorage.getItem("admin_panel_team_id"));
  }

  private retrieveSettings() {
    let storedSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );
    let legacySettings = this._clientManagementService.getClientSettings(
      this.teamID
    );

    //port stored settings to hardcoded format (easier backwards compatability)
    this.clientSettings = {
      showEventProperties:
        storedSettings.showEventProperties.unconstrained_default,
      showLegacyEventMeta:
        storedSettings.showLegacyEventMeta.unconstrained_default,
      eventWizardLabelStep1: "Step 1: Main Class Details",
      eventWizardLabelStep2: "Step 2: Location",
      eventWizardLabelStep3: "Step 3: Add Students",
      eventWizardLabelStep4: "Step 4: Add Event Media",
      eventWizardLabelStep5: "Step 5: Extra Spaces",
      eventWizardLabelStep6: "Step 6: Finish Up",
      showProps: {
        showPersona: storedSettings.showPersona.unconstrained_default,
        showRole: storedSettings.showRole.unconstrained_default,
      },
      experienceMetaOptions: legacySettings.experienceMetaOptions
    }
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }
}
