import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-archive-prop-modal",
  templateUrl: "./archive-prop-modal.component.html",
  styleUrls: ["./archive-prop-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ArchivePropModalComponent implements OnInit {
  //incoming
  public teamID: number;
  public prop: any;
  public type: string;

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Archive Another Prop",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();
  public token: string;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    public archivePropModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService
  ) {}

  ngOnInit(): void {
    this.continueType = "none";

    if (this.type === "Team Experience") {
      this.btnLabel.reset = "Archive Another Experience";
      this.prop.label = this.prop.experience.label;
    }

    this.retrieveToken();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public clickArchive() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Archiving " + this.type + "</span>";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let url = "";
    let data = {};

    if (this.type == "Event Property") {
      url = "/schedule/prop/" + this.prop.id + "/archive";
    } else if (this.type == "Team Event Property") {
      url =
        "/team/" + this.teamID + "/schedule/prop/" + this.prop.id + "/archive";
    } else if (this.type == "Team Experience") {
      url = `/experience-team-rel/${this.prop.id}`;
      data = {
        archived: true,
      };
    }

    let archiveProp = this._xrPlatformRestService.restfulAPIQuery(
      url,
      "put",
      data,
      getOptions
    );

    archiveProp.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = this.type + " successfully archived";
        let outgoingData = {
          action: "delete",
          prop: this.prop,
        };
        this.outgoing.next(outgoingData);
      },
      (error) => {
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.archivePropModal.hide();
        break;
      case "reset-form":
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
