<div class="metrics-landing-page page">
  <div class="heading-content">
    <h4>Performance Metrics</h4>
    <div class="callout-text-wrapper">
      <p [innerHTML]="copy"></p>
    </div>
  </div>
  <div class="row body-row">
    <div class="table-wrapper col-md-12" *ngIf="!tableLoading">
      <div class="table-toolbar">
        <div class="table-content mb-3">
          <h3>Performance Analytics: Corrective Mode</h3>
          <p>
            <strong>Did they skip:</strong> how many times a given step was
            skipped <br /><strong>#tries to 100%:</strong> will count how many
            times a given step needed to be attempted to reach 100% completion
          </p>
        </div>
        <div class="table-utilities">
          <div class="md-form mx-0 mt-0 mb-4">
            <input
              mdbInput
              type="text"
              id="filterCorrective"
              class="form-control"
              placeholder="Type to filter by last name..."
              (keyup)="updateFilter('corrective', $event)"
            />
            <label for="filterCorrective">Filter Last Name: </label>
          </div>
          <div class="md-form m-0">
            <mdb-select-2
              id="selectSortingCorrective"
              placeholder="Select a sorting option"
              label="Sort table by:"
              [value]="'skips'"
            >
              <mdb-select-option
                *ngFor="let option of sortingOptions"
                [value]="option.value"
                (click)="onSortingOptionsChange(option.value, 'corrective')"
                >{{ option.label }}</mdb-select-option
              >
            </mdb-select-2>
          </div>
        </div>
      </div>
      <p-table
        class="material metrics-datatable main-table wrap"
        [columns]="columns"
        [value]="rows"
        [tableStyle]="{ 'min-width': '50rem' }"
        [frozenColumns]="frozenColumns"
        [scrollable]="true"
        (sortFunction)="customSort($event, 'corrective')"
        [customSort]="true"
      >
        <ng-template pTemplate="header" let-columns>
          <tr class="datatable-header">
            <ng-container *ngFor="let col of columns">
              <th
                *ngIf="col.frozenLeft"
                pFrozenColumn
                [frozen]="col.frozenLeft"
                alignFrozen="left"
                [ngClass]="'type-' + col.type + ' ' + col.class"
              >
                {{ col.name }}
              </th>
              <th
                *ngIf="!col.frozenLeft"
                alignFrozen="left"
                [ngClass]="'type-' + col.type + ' ' + col.class"
                pSortableColumn="{{ col.field }}"
              >
                <span class="sorting">
                  <span class="sorting-header">
                    {{ col.name }}
                  </span>
                  <p-sortIcon field="{{ col.field }}"></p-sortIcon>
                </span>
              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td
              class="datatable-body-cell-label"
              *ngFor="let col of columns"
              pFrozenColumn
              [frozen]="col.frozenLeft"
              alignFrozen="left"
              [ngClass]="'type-' + col.type + ' ' + col.class"
            >
              <div class="datatable-cell-content">
                <ng-container *ngIf="col.type === 'core'">
                  <span class="type-core">
                    {{ rowData[col.field] }}
                  </span>
                </ng-container>
                <ng-container *ngIf="col.type === 'core-date'">
                  <div class="date-wrapper">
                    <span class="date">{{
                      rowData[col.field] | amDateFormat : "MMM D YYYY"
                    }}</span>
                    <br />
                    <span class="time">{{
                      rowData[col.field] | amDateFormat : "h:mm a"
                    }}</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="col.type === 'format'">
                  <span class="type-format">
                    <span class="top">Did they skip</span>
                    <span class="bottom"># tries to 100%</span>
                  </span>
                </ng-container>
                <ng-container *ngIf="col.type === 'task'">
                  <span class="type-task">
                    <span class="top">{{ rowData[col.field].skips }}</span>
                    <span class="bottom">{{ rowData[col.field].tries }}</span>
                  </span>
                </ng-container>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="table-wrapper col-md-12" *ngIf="!tableLoading">
      <div class="table-toolbar">
        <div class="table-content mb-3">
          <h3>Performance Analytics: Test Mode</h3>
          <p>
            <strong>% complete:</strong> how much of the given step was done;
            zero reflects a skipped step <br /><strong>Out of order:</strong>
            when a given step is conducted out of order
          </p>
        </div>
        <div class="table-utilities">
          <div class="md-form mx-0 mt-0 mb-4">
            <input
              mdbInput
              type="text"
              id="filterCorrective"
              class="form-control"
              placeholder="Type to filter by last name..."
              (keyup)="updateFilter('test', $event)"
            />
            <label for="filterCorrective">Filter Last Name: </label>
          </div>
          <div class="md-form m-0">
            <mdb-select-2
              id="selectSortingCorrective"
              placeholder="Select a sorting option"
              label="Sort table by:"
              [value]="'percent_complete'"
            >
              <mdb-select-option
                *ngFor="let option of sortingOptionsTest"
                [value]="option.value"
                (click)="onSortingOptionsChange(option.value, 'test')"
                >{{ option.label }}</mdb-select-option
              >
            </mdb-select-2>
          </div>
        </div>
      </div>
      <p-table
        class="material metrics-datatable main-table wrap"
        [columns]="columns"
        [value]="rowsTest"
        [tableStyle]="{ 'min-width': '50rem' }"
        [frozenColumns]="frozenColumns"
        [scrollable]="true"
        (sortFunction)="customSort($event, 'test')"
        [customSort]="true"
      >
        <ng-template pTemplate="header" let-columns>
          <tr class="datatable-header">
            <ng-container *ngFor="let col of columns">
              <th
                *ngIf="col.frozenLeft"
                pFrozenColumn
                [frozen]="col.frozenLeft"
                alignFrozen="left"
                [ngClass]="'type-' + col.type + ' ' + col.class"
              >
                {{ col.name }}
              </th>
              <th
                *ngIf="!col.frozenLeft"
                alignFrozen="left"
                [ngClass]="'type-' + col.type + ' ' + col.class"
                pSortableColumn="{{ col.field }}"
              >
                <span class="sorting">
                  <span class="sorting-header">
                    {{ col.name }}
                  </span>
                  <p-sortIcon field="{{ col.field }}"></p-sortIcon>
                </span>
              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td
              class="datatable-body-cell-label"
              *ngFor="let col of columns"
              pFrozenColumn
              [frozen]="col.frozenLeft"
              alignFrozen="left"
              [ngClass]="'type-' + col.type + ' ' + col.class"
            >
              <div class="datatable-cell-content">
                <ng-container *ngIf="col.type === 'core'">
                  <span class="type-core">
                    {{ rowData[col.field] }}
                  </span>
                </ng-container>
                <ng-container *ngIf="col.type === 'core-date'">
                  <div class="date-wrapper">
                    <span class="date">{{
                      rowData[col.field] | amDateFormat : "MMM D YYYY"
                    }}</span>
                    <br />
                    <span class="time">{{
                      rowData[col.field] | amDateFormat : "h:mm a"
                    }}</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="col.type === 'format'">
                  <span class="type-format">
                    <span class="top">% Complete</span>
                    <span class="bottom">Out of Order</span>
                  </span>
                </ng-container>
                <ng-container *ngIf="col.type === 'task'">
                  <span class="type-task">
                    <span class="top">{{
                      rowData[col.field].percent_complete
                    }}</span>
                    <span class="bottom">{{
                      rowData[col.field].out_of_order
                    }}</span>
                  </span>
                </ng-container>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div *ngIf="tableLoading" class="col-md-12">
      <p>Loading Tables...</p>
    </div>
  </div>
</div>
