<div class="background-replacement"></div>
<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header">
    <h4 class="modal-title w-100 font-weight-bold">
      Schedule Class <mdb-icon far icon="calendar-alt"></mdb-icon>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="addRegionFrame.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3 p-0">
    <p>{{ msgs.actionMsg }}</p>
    <strong class="font-weight-bold">{{ experience.name }}</strong>
    <p>Current Regions Attached to this Class:</p>
    <div id="table" class="table-editable">
      <table
        *ngIf="formLoading"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <tr>
          <th class="text-center">Region Name</th>
          <th class="text-center">Remove</th>
        </tr>
        <tr *ngFor="let region of regions; let id = index">
          <td>
            {{ region.name }}
          </td>
          <td>
            <span class="table-remove">
              <button
                type="button"
                mdbBtn
                size="sm"
                class="my-0"
                (click)="remove(region.id)"
              >
                Remove
              </button>
            </span>
          </td>
        </tr>
      </table>
    </div>
    <p>Add a Region to this Class:</p>
    <form
      [formGroup]="addRegionForm"
      *ngIf="formLoading"
      class="text-center border border-light p-5"
    >
      <div class="md-form mb-5">
        <mdb-select
          [options]="selectRegions"
          placeholder="Select Region"
          formControlName="scheduledRegion"
        ></mdb-select>
        <mdb-error
          *ngIf="
            scheduledRegion &&
            scheduledRegion.invalid &&
            (scheduledRegion.dirty || scheduledRegion.touched)
          "
        >
          Please select a region
        </mdb-error>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button
    mdbBtn
    class="waves-light theme-action-button"
    data-dismiss="modal"
    mdbWavesEffect
    (click)="addRegionFrame.hide()"
  >
    {{ btnLabel.close }}
  </button>
  <button
    mdbBtn
    *ngIf="formState !== 'success'"
    class="waves-light theme-action-button"
    data-dismiss="modal"
    mdbWavesEffect
    (click)="addRegion()"
    [disabled]="formState === 'processing'"
  >
    {{ btnLabel.main }}
  </button>
</div>
