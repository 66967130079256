import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-archive-environments",
  templateUrl: "./archive-environments.component.html",
  styleUrls: ["./archive-environments.component.scss"],
})
export class ArchiveEnvironmentsComponent implements OnInit {
  //incoming
  public teamID: number;
  public targetEnvironment: any;
  public type: string;

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Archive Another Environment",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();
  public token: string;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    public archiveEnvironmentModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService
  ) {}

  ngOnInit(): void {
    this.continueType = "none";
    this.retrieveToken();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public clickArchive() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Archiving " + this.type + "</span>";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let url = `/environment/${this.targetEnvironment.id}`;
    let data = {
      archived: true,
    };

    let archiveProp = this._xrPlatformRestService.restfulAPIQuery(
      url,
      "put",
      data,
      getOptions
    );

    archiveProp.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Environment successfully archived";
        let outgoingData = {
          action: this.type,
          environment: this.targetEnvironment,
        };
        this.outgoing.next(outgoingData);
      },
      (error) => {
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.archiveEnvironmentModal.hide();
        break;
      case "reset-form":
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
