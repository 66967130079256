<mat-card
  class="d-flex flex-row justify-content-center ngx-audio-player"
  style="margin: 0px"
>
  <audio #audioPlayer [src]="tracks[currentIndex]?.link"></audio>

  <button
    *ngIf="tracks.length > 1"
    (click)="previousSong()"
    [disabled]="loaderDisplay"
    class="p-1"
    mat-button
  >
    <mat-icon aria-hidden="true">
      <!-- Skip previous icon (skip_previous) -->
      <svg
        height="32"
        viewBox="0 0 24 24"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
        <path d="M0 0h32v32H0z" fill="none" />
      </svg>
    </mat-icon>
  </button>
  <button
    (click)="playBtnHandler()"
    [disabled]="loaderDisplay"
    class="p-1 play-pause"
    mat-button
  >
    <svg
      *ngIf="loaderDisplay"
      height="34px"
      preserveAspectRatio="xMidYMid"
      style="margin: auto; display: block; shape-rendering: auto"
      viewBox="0 0 100 100"
      width="34px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="rotate(0 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.9166666666666666s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.8333333333333334s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.75s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.6666666666666666s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.5833333333333334s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.5s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.4166666666666667s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.3333333333333333s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.25s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.16666666666666666s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="-0.08333333333333333s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect fill="#7a7a7a" height="12" rx="3" ry="6" width="6" x="47" y="20">
          <animate
            attributeName="opacity"
            begin="0s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          />
        </rect>
      </g>
    </svg>

    <mat-icon
      *ngIf="!loaderDisplay && !isPlaying"
      aria-hidden="true"
      class="play-track"
    >
      <!-- Play icon (play_arrow) -->
      <svg
        height="32"
        viewBox="0 0 24 24"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 5v14l11-7z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </mat-icon>
    <mat-icon
      *ngIf="!loaderDisplay && isPlaying"
      aria-hidden="true"
      class="pause-track"
    >
      <!-- Pause icon (pause) -->
      <svg
        height="32"
        viewBox="0 0 24 24"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </mat-icon>
  </button>
  <button
    *ngIf="tracks.length > 1"
    (click)="nextSong()"
    [disabled]="loaderDisplay"
    class="p-1 skip-next"
    mat-button
  >
    <mat-icon aria-hidden="true" class="next-track">
      <!-- Skip next icon (skip_next) -->
      <svg
        height="32"
        viewBox="0 0 24 24"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </mat-icon>
  </button>

  <div class="col">
    <div class="d-flex flex-fill justify-content-center">
      <div class="d-none d-sm-block py-3 px-1" style="font-size: 12px">
        <span *ngIf="duration !== 0.01">
          {{ currentTime | secondsToMinutes }}
        </span>
      </div>
      <!-- // aa mat-slider that reacts to the music -->
      <mat-slider
        type="range"
        [disabled]="disablePositionSlider"
        min="startOffset"
        class="d-none d-sm-block flex-fill p-1 track-control"
        max="{{ duration - endOffset }}"
        style="width: 100%"
        (value)="(currentTime)"
      >
        <input
          [(ngModel)]="currentTime"
          (ngModelChange)="currTimePosChanged($event)"
          matSliderThumb
        />
      </mat-slider>
      <div
        class="py-3 px-1"
        style="font-size: 12px; text-align: right; color: black"
      >
        <span *ngIf="duration !== 0.01">
          -{{ duration - currentTime | secondsToMinutes }}
        </span>
      </div>
    </div>
  </div>
  <button
    (click)="toggleRepeat()"
    *ngIf="displayRepeatControls"
    class="p-1 volume justify-content-center"
    mat-button
  >
    <mat-icon
      *ngIf="repeat === 'none'"
      aria-hidden="true"
      class="volume-mute justify-content-center"
    >
      <!-- Repeat None -->
      <svg
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <g>
          <g>
            <path d="m0,0l24,0l0,24l-24,0l0,-24z" fill="none" />
            <path
              d="m8,20l0,1.932a0.5,0.5 0 0 1 -0.82,0.385l-4.12,-3.433a0.5,0.5 0 0 1 0.322,-0.884l14.618,0a2,2 0 0 0 2,-2l0,-8l2,0l0,8a4,4 0 0 1 -4,4l-10,0zm8,-16l0,-1.932a0.5,0.5 0 0 1 0.82,-0.385l4.12,3.433a0.5,0.5 0 0 1 -0.321,0.884l-14.619,0a2,2 0 0 0 -2,2l0,8l-2,0l0,-8a4,4 0 0 1 4,-4l10,0z"
            />
            <path
              d="M 3 0 L 22.4164 22.2706 L 21 24 L 1.5836 1.8128 L 3 0"
            ></path>
          </g>
          <!-- <line xmlns="http://www.w3.org/2000/svg" id="svg_4" y2="22.27056" x2="22.41638" y1="1.81276" x1="1.58362" stroke-width="2" fill="none"/> -->
        </g>
      </svg>
    </mat-icon>
    <mat-icon *ngIf="repeat === 'all'" aria-hidden="true" class="volume-mute">
      <!-- Repeat All -->
      <svg
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <g>
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M8 20v1.932a.5.5 0 0 1-.82.385l-4.12-3.433A.5.5 0 0 1 3.382 18H18a2 2 0 0 0 2-2V8h2v8a4 4 0 0 1-4 4H8zm8-16V2.068a.5.5 0 0 1 .82-.385l4.12 3.433a.5.5 0 0 1-.321.884H6a2 2 0 0 0-2 2v8H2V8a4 4 0 0 1 4-4h10z"
          />
        </g>
      </svg>
    </mat-icon>
    <mat-icon *ngIf="repeat === 'one'" aria-hidden="true" class="volume-up">
      <!-- Repeat ONE -->
      <svg
        height="24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <g>
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M8 20v1.932a.5.5 0 0 1-.82.385l-4.12-3.433A.5.5 0 0 1 3.382 18H18a2 2 0 0 0 2-2V8h2v8a4 4 0 0 1-4 4H8zm8-16V2.068a.5.5 0 0 1 .82-.385l4.12 3.433a.5.5 0 0 1-.321.884H6a2 2 0 0 0-2 2v8H2V8a4 4 0 0 1 4-4h10zm-5 4h2v8h-2v-6H9V9l2-1z"
          />
        </g>
      </svg>
    </mat-icon>
  </button>
  <button
    (click)="toggleVolume()"
    *ngIf="displayVolumeControls"
    class="p-1 volume"
    mat-button
  >
    <mat-icon *ngIf="volume === 0" aria-hidden="true" class="volume-mute">
      <!-- Volume mute icon (volume_off) -->
      <svg
        height="28"
        viewBox="0 0 24 24"
        width="28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </mat-icon>
    <mat-icon *ngIf="volume > 0" aria-hidden="true" class="volume-up">
      <!-- Volume up icon (volume_up) -->
      <svg
        height="28"
        viewBox="0 0 24 24"
        width="28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </mat-icon>
  </button>
  <mat-slider
    *ngIf="displayVolumeSlider"
    [min]="0"
    class="d-none d-sm-block flex-fill p-1 volume-slider"
    max="1"
    step="0.01"
    (value)="(volume)"
    ><input
      [(ngModel)]="volume"
      (ngModelChange)="currVolumeChanged($event)"
      matSliderThumb
  /></mat-slider>
</mat-card>

<mat-card *ngIf="displayTitle" class="mat-elevation-z1 ngx-audio-player">
  <div
    style="
      text-align: center;
      color: black;
      font-size: large;
      font-weight: bold;
      height: 50px;
    "
  >
    <div style="margin: 1px 2px; padding: 1em">
      <span *ngIf="!isPlaying"
        >{{ tracks[currentIndex]?.title
        }}{{
          displayArtist && tracks[currentIndex]?.artist
            ? " | " + tracks[currentIndex]?.artist
            : ""
        }}</span
      >
      <marquee *ngIf="isPlaying" behavior="scroll" direction="left"
        >{{ tracks[currentIndex]?.title
        }}{{
          displayArtist && tracks[currentIndex]?.artist
            ? " | " + tracks[currentIndex]?.artist
            : ""
        }}
      </marquee>
    </div>
    <div class="clear"></div>
  </div>
</mat-card>

<mat-accordion
  class="ngx-audio-player"
  *ngIf="displayPlaylist && tracks.length > 1"
>
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
      {{ tableHeader }}
    </mat-expansion-panel-header>
    <table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      style="cursor: pointer"
      mat-table
    >
      <ng-container matColumnDef="title">
        <th *matHeaderCellDef style="font-size: large" mat-header-cell>
          {{ titleHeader }}
        </th>
        <td
          (click)="selectTrack(element.index)"
          *matCellDef="let element"
          [ngClass]="{
            'currently-playing': tracks[currentIndex]?.title === element.title
          }"
          mat-cell
        >
          {{ element.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="artist">
        <th *matHeaderCellDef style="font-size: large" mat-header-cell>
          {{ artistHeader }}
        </th>
        <td
          (click)="selectTrack(element.index)"
          *matCellDef="let element"
          [ngClass]="{
            'currently-playing': tracks[currentIndex]?.title === element.title
          }"
          mat-cell
        >
          {{ element?.artist ? element?.artist : "" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="duration">
        <th *matHeaderCellDef mat-header-cell>{{ durationHeader }}</th>
        <td
          (click)="selectTrack(element.index)"
          *matCellDef="let element"
          mat-cell
        >
          {{ element?.duration ? (element?.duration | secondsToMinutes) : "" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell></th>
        <td
          *matCellDef="let element"
          [ngClass]="{
            'currently-playing': tracks[currentIndex]?.title === element.title
          }"
          mat-cell
        ></td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: displayedColumns"
        class="mat-select-content"
        mat-row
      ></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
    ></mat-paginator>
  </mat-expansion-panel>
</mat-accordion>
