import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WcModelViewerComponent } from "./wc-model-viewer/wc-model-viewer.component";

@NgModule({
  declarations: [WcModelViewerComponent],
  imports: [CommonModule],
  exports: [WcModelViewerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WebComponentsModule {}
