import { NgModule } from "@angular/core";
import { CommonModule, TitleCasePipe } from "@angular/common";
import { SharedModulesModule } from "../shared-modules/shared-modules.module";
import { PersistentModule } from "../persistent/persistent.module";
import { MDBBootstrapModulesPro, TabsModule } from "ng-uikit-pro-standard";
import { MediaManagerComponent } from "./persistent/media-manager/media-manager.component";
import { MediaManagerBaseComponent } from "./media-manager-base/media-manager-base.component";
import { MediaManagerTableView } from "./persistent/media-manager/media-manager-table-view/media-manager-table-view.component";
import { ManageMediaLockerComponent } from "./persistent/media-manager/media-manager-table-view/modals/manage-media-locker/manage-media-locker.component";
import { ManageMediaUploadsComponent } from "./persistent/media-manager/media-manager-table-view/modals/manage-media-uploads/manage-media-uploads.component";
import { ManageMediaRemovalComponent } from "./persistent/media-manager/media-manager-table-view/modals/manage-media-removal/manage-media-removal.component";
import { ManageMediaSharingComponent } from "./persistent/media-manager/media-manager-table-view/modals/manage-media-sharing/manage-media-sharing.component";
import { ManageMediaViewComponent } from "./persistent/media-manager/media-manager-table-view/modals/manage-media-view/manage-media-view.component";
import { ManageMediaOwnerComponent } from "./persistent/media-manager/media-manager-table-view/modals/manage-media-owner/manage-media-owner.component";
import { ManageMediaUploadsModelViewerComponent } from "./persistent/media-manager/media-manager-table-view/modals/manage-media-uploads-model-viewer/manage-media-uploads-model-viewer.component";
import { WebComponentsModule } from "../web-components/web-components.module";
import { ManageMediaUpdateThumbnailComponent } from './persistent/media-manager/media-manager-table-view/modals/manage-media-update-thumbnail/manage-media-update-thumbnail.component';
import { VideoPlayerComponent } from './persistent/media-manager/media-manager-table-view/players/video-player/video-player.component';
import { ThreeDObjectViewerComponent } from './persistent/media-manager/media-manager-table-view/players/three-d-object-viewer/three-d-object-viewer.component';
import { PdfViewerComponent } from './persistent/media-manager/media-manager-table-view/players/pdf-viewer/pdf-viewer.component';
import { AudioMediaPlayerComponent } from './persistent/media-manager/media-manager-table-view/players/audio-media-player/audio-media-player.component';
import { AudioPlayerModule } from "../audio-player/audio-player.module";
import { ExamplePdfViewerComponent } from "src/app/example-pdf-viewer/example-pdf-viewer.component";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DropzoneCustomModule } from "../ngx-dropzone-custom/ngx-dropzone-custom.module";
import { ManageMediaEventsComponent } from './persistent/media-manager/media-manager-table-view/modals/manage-media-events/manage-media-events.component';
import { ManageMediaChangeComponent } from './persistent/media-manager/media-manager-table-view/modals/manage-media-change/manage-media-change.component';

@NgModule({
  declarations: [
    MediaManagerComponent,
    MediaManagerBaseComponent,
    MediaManagerTableView,
    ManageMediaLockerComponent,
    ManageMediaUploadsComponent,
    ManageMediaRemovalComponent,
    ManageMediaSharingComponent,
    ManageMediaViewComponent,
    ManageMediaOwnerComponent,
    ManageMediaUploadsModelViewerComponent,
    ManageMediaUpdateThumbnailComponent,
    VideoPlayerComponent,
    ThreeDObjectViewerComponent,
    PdfViewerComponent,
    AudioMediaPlayerComponent,
    ExamplePdfViewerComponent,
    ManageMediaEventsComponent,
    ManageMediaChangeComponent
  ],
  imports: [
    CommonModule,
    SharedModulesModule,
    PersistentModule,
    MDBBootstrapModulesPro.forRoot(),
    TabsModule.forRoot(),
    WebComponentsModule,
    AudioPlayerModule,
    NgxExtendedPdfViewerModule,
    DropzoneCustomModule
  ],
  exports: [MediaManagerComponent],
  providers: [TitleCasePipe],
})
export class MediaManagementModule { }
