<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      {{ type | titlecase }} Experience Attribute
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="experienceAttributeFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body mt-3 p-0">
    <div class="row mb-3">
      <div class="col-12 experienceAttribute">
        <form [formGroup]="experienceAttributeForm">
          <div class="md-form">
            <input
              type="text"
              id="experienceAttributeLabel"
              class="form-control"
              formControlName="label"
              mdbInput
            />
            <label for="experienceAttributeLabel">Label</label>
          </div>

          <div class="md-form">
            <button
              mdbBtn
              class="info-button color-theme-base-medium"
              mdbPopover="Note: Name is a code-friendly reference for this experienceAttribute. Name should only contain numbers, leters, and underscores, and it must be unique across the entire Admin Panel System."
              placement="right"
              mdbPopoverHeader="About the Name Field"
              triggers="focus"
              mdbWavesEffect
            >
              <mdb-icon fas icon="info-circle"></mdb-icon>
            </button>
            <input
              type="text"
              id="experienceAttributeName"
              class="form-control"
              formControlName="name"
              mdbInput
            />
            <label for="experienceAttributeName">Name</label>
          </div>

          <div class="md-form">
            <input
              type="text"
              id="experienceAttributeDescription"
              class="form-control"
              formControlName="description"
              mdbInput
            />
            <label for="experienceAttributeDescription">Description</label>
          </div>

          <div class="md-form">
            <mdb-select
              [options]="propsTypes"
              id="constainedTypes"
              formControlName="type"
              mdbInput
              placeholder="Choose type"
              label="Property Type"
            ></mdb-select>
          </div>

          <div class="md-form">
            <mdb-checkbox
              id="propsConstained"
              class="form-control border-0"
              formControlName="constrained"
              mdbInput
              >Constrained</mdb-checkbox
            >
            <!-- <label for="propsConstained">Constrained</label> -->
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        data-dismiss="modal"
        (click)="addExperienceAttribute()"
        class="theme-modal-button btn"
      >
        {{ type }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        data-dismiss="modal"
        (click)="experienceAttributeFrame.hide()"
      >
        Close
      </button>
    </div>
  </div>
</div>
