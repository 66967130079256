import { CoolLocalStorage } from "@angular-cool/storage";
import { Component } from "@angular/core";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-version-support",
  templateUrl: "./version-support.component.html",
  styleUrls: ["./version-support.component.scss"],
})
export class VersionSupportComponent {
  public token: string;
  public user: any;
  public loading: boolean = true;

  //persistent
  public teamID: number;
  public clientSettings: any;
  public copy = "";
  private timeoutID: any;

  //data
  public data: {
    versions: any;
    appBundles: any;
    appDevices: any;
  } = {
    versions: [],
    appBundles: [],
    appDevices: [],
  };
  public opts: {
    appBundles: any;
    appDevices: any;
  } = {
    appBundles: [],
    appDevices: [],
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _settingsService: SettingsService,
    private _sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.retrieveToken();
    this.retrieveUser();
    this.retrieveTeamID();

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.retrieveData();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let versions = await this.retrieveVersions(options).catch ((err) => {
      ;
    });

    this.data.versions = versions.app_versions;

    let appBundles = await this.retrieveAppBundles(options).catch ((err) => {
      ;
    });

    this.data.appBundles = appBundles.app_bundles;

    let appDevices = await this.retrieveDevices(options).catch ((err) => {
      ;
    });

    this.data.appDevices = appDevices.app_devices;
    this.opts.appDevices = this.buldDeviceOpts();

    ;

    this.loading = false;
  }

  private retrieveVersions(options) {
    let versions = this._xrPlatformRestService.restfulAPIQuery(
      `/app-versions`,
      "get",
      {},
      options
    );

    return versions.toPromise();
  }

  private retrieveAppBundles(options) {
    let appBundles = this._xrPlatformRestService.restfulAPIQuery(
      `/app-bundles`,
      "get",
      {},
      options
    );

    return appBundles.toPromise();
  }

  private retrieveDevices(options) {
    let devices = this._xrPlatformRestService.restfulAPIQuery(
      `/app-devices`,
      "get",
      {},
      options
    );

    return devices.toPromise();
  }

  private buldDeviceOpts() {
    let opts = [];

    this.data.appDevices.forEach((device) => {
      opts.push({
        value: device.id,
        label: device.label,
      });
    });

    return opts;
  }

  public tabSelected(targetTab) {
    //cheap debounce
    //@todo: find a more angular-centric way to do this
    if (this.timeoutID) window.clearTimeout(this.timeoutID);

    this.timeoutID = window.setTimeout(() => {
      let buttonTitleChange = {
        type: "buttonTitleChange",
        data: {
          buttonTitle: "Add Version",
          buttonAction: "add_version",
        },
      };

      switch (targetTab) {
        case "device-types":
          buttonTitleChange.data.buttonTitle = "Add Device Type";
          buttonTitleChange.data.buttonAction = "add_device_type";
          break;
        case "bundles":
          buttonTitleChange.data.buttonTitle = "Add Bundle";
          buttonTitleChange.data.buttonAction = "add_bundle";
          break;
      }

      this._sharedDataService.sendSharedData(buttonTitleChange);
    });
  }

  public onDataChange(incoming) {
    ;

    if (incoming.type === "updateVersions") {
      this.data.versions = incoming.data.versions;
    } else if (incoming.type === "updateDeviceTypes"){
      this.data.appDevices = incoming.data.appDevices;
      this.opts.appDevices = this.buldDeviceOpts();
    } else if (incoming.type === "updateBundles"){
      this.data.appBundles = incoming.data.appBundles;
    }
  }
}
