<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" *ngIf="action === 'remove'">
      <mdb-icon
        *ngIf="action === 'remove'"
        fas
        icon="minus-circle"
        size="2x"
        class="d-flex justify-content-center my-2 text-danger"
      >
      </mdb-icon>
      {{ title }}
    </h4>
    <h4  *ngIf="action === 'add'" class="modal-title w-100">Add {{labels.eventConnection.singular|titlecase}}</h4>
    <button  *ngIf="action === 'add'"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="addconnection.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-3 p-0">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="connectionForm" class="px-5" autocomplete="off" *ngIf="action !== 'remove'">
          <div class="md-form">
            <input
              type="text"
              id="event_name"
              formControlName="event_name"
              [ngModel]="searchText | async"
              (ngModelChange)="searchText.next($event)"
              [mdbAutoCompleter]="auto"
              placeholder="Name of {{labels.event.singular|titlecase}} to add"
              class="completer-input form-control mdb-autocomplete"
              mdbInput
            />
            <label for="event_name">{{labels.event.singular|titlecase}} Name</label>
            <mdb-auto-completer
              #auto="mdbAutoCompleter"
              textNoResults={{textNoResults}}
            >
              <mdb-option
                *ngFor="let option of results | async"
                [value]="option"
              >
                {{ option }}
              </mdb-option>
            </mdb-auto-completer>
          </div>
        </form>
         <div *ngIf="action === 'remove'">
          <p class="text-center">
            This will remove
            <strong class="color-theme-base-medium-dark">{{
              targetConnection.event_name
            }}</strong>
            from
            <strong class="color-theme-base-medium-dark">
              {{ event.name }}</strong
            > 
          </p>
          <p class="text-center"><strong>Are you sure?</strong></p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        [ngClass]="action == 'remove' ? 'btn-danger' : 'theme-modal-button btn'"
        class="theme-modal-button btn"
        size="md"
        (click)="roomActions()"
      >
      {{ btnLabel.main }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        data-dismiss="modal"
        (click)="addconnection.hide()"
      >
        Close
      </button>
    </div>
  </div>
</div>
