<a
  mdbBtn
  class="btn-flat p-0 m-0 mb-3 mt-2 back-button"
  mdbWavesEffect
  [routerLink]="['/client', clientCode, 'authoring', 'skills']"
>
  <mdb-icon fas icon="chevron-left"></mdb-icon>
  Back to Skills
</a>
<p-confirmDialog
  [key]="'sheetsConfirm'"
  class="delete-confirm"
  #sheetConfirm
  [style]="{ width: '30rem' }"
>
  <ng-template pTemplate="header">
    <div class="modal-header text-center">
      <h4 class="modal-title w-100">Push to Google Sheet?</h4>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="modal-footer">
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button danger btn"
        (click)="sheetConfirm.accept()"
      >
        Push to Sheets
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn"
        (click)="sheetConfirm.reject()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
<p-confirmDialog
  [key]="'cd'"
  class="delete-confirm"
  #cd
  [style]="{ width: '30rem' }"
>
  <ng-template pTemplate="header">
    <div class="modal-header text-center">
      <h4 class="modal-title w-100">Delete this Action?</h4>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="modal-footer">
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button danger btn"
        (click)="cd.accept()"
      >
        Delete Action
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn"
        (click)="cd.reject()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
<ng-container *ngIf="tableLoading">
  <div class="row">
    <div class="col-12">
      <!-- if content hasnt load -->
      <div
        class="loading-card d-flex align-content-center justify-content-center"
      >
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg === ''"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg !== ''"
        >
          <div
            class="alert alert-danger"
            role="alert"
            [innerHTML]="errorRetrievingMsg"
          ></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!tableLoading">
  <div class="card authoring-card">
    <p-table
      class="authoring-table"
      *ngIf="!tableLoading"
      [tableStyleClass]="'table'"
      [value]="tableActions"
      [tableStyle]="{ 'min-width': '60rem' }"
      [reorderableColumns]="true"
      (onRowReorder)="updateRowOrder($event)"
      [columns]="parameterColumns"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pFrozenColumn></th>
          <th pFrozenColumn></th>
          <th pFrozenColumn>Actions</th>
          <th [attr.colspan]="numOfColumns">Parameters</th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-columns="columns"
        let-action
        let-rowData
        let-index="rowIndex"
      >
        <tr
          [pReorderableRow]="index"
          [style.background-color]="action.background"
          [ngClass]="{ 'action-selected': action.id !== 0 }"
        >
          <td class="action-icons" pFrozenColumn>
            <div class="action-order">{{ action.order }}</div>
          </td>
          <td class="action-icons" pFrozenColumn>
            <div
              *ngIf="action.label !== ''"
              class="btn-group list-btns-sm btn-icons"
            >
              <span class="pi pi-bars" pReorderableRowHandle></span>
              <button
                size="lg"
                mdbBtn
                mdbWavesEffect
                class="btn-flat btn-icon"
                (click)="confirmDelete('delete', index, action)"
                placement="bottom"
              >
                <fa-icon [icon]="faTrashAlt"></fa-icon>
              </button>
              <button
                size="lg"
                mdbBtn
                mdbWavesEffect
                class="btn-flat btn-icon"
                (click)="copyRowConfirm($event, index)"
                placement="bottom"
              >
                <fa-icon [icon]="faCopy"></fa-icon>
              </button>
              <button
                size="lg"
                mdbBtn
                mdbWavesEffect
                class="btn-flat btn-icon"
                (click)="addRowBelow($event, index)"
                placement="bottom"
              >
                <fa-icon [icon]="faPlus"></fa-icon>
              </button>
            </div>
            <div
              *ngIf="action.label === ''"
              class="btn-group list-btns-sm btn-icons"
            >
              &nbsp;
            </div>
          </td>
          <td
            pFrozenColumn
            [pEditableColumn]="action.action_function_id"
            pEditableColumnField="action_function_id"
          >
            <ng-container *ngIf="action.label === ''">
              Select an action
            </ng-container>
            <ng-container *ngIf="action.label !== ''">
              {{ action.label }}
            </ng-container>
          </td>
          <ng-container *ngIf="action.loading">
            <td [attr.colspan]="columns.length">
              <div class="d-flex justify-content-start">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="!action.loading">
            <ng-container *ngIf="action.action_function_id === 0">
              <td [attr.colspan]="numOfColumns">
                Select an action to populate the parameters
              </td>
            </ng-container>
            <ng-container *ngIf="action.action_function_id > 0">
              <ng-container *ngFor="let col of columns; let columndex = index">
                <ng-container
                  *ngIf="action.action_parameters[columndex] !== undefined"
                >
                  <td
                    *ngIf="!action.action_parameters[columndex].constrained"
                    class="action-parameters"
                    [pEditableColumn]="
                      action.action_parameters[columndex].action_value.value
                    "
                    pEditableColumnField="value"
                    [ngClass]="{
                      required:
                        action.action_parameters[columndex].required &&
                        ((action.action_parameters[columndex].type.indexOf(
                          'chip'
                        ) === -1 &&
                          (!action.action_parameters[columndex].action_value
                            .value === null ||
                            action.action_parameters[columndex].action_value
                              .value === '')) ||
                          (action.action_parameters[columndex].type.indexOf(
                            'chip'
                          ) !== -1 &&
                            action.action_parameters[columndex]
                              .stored_chips_length === 0))
                    }"
                  >
                    <div class="parameter-wrapper">
                      <span class="parameter-name"
                        ><span
                          *ngIf="action.action_parameters[columndex].required"
                          >*</span
                        ><span class="parameter-label">{{
                          action.action_parameters[columndex].label
                        }}</span>
                        <div
                          class="updating-notification"
                          *ngIf="action.action_parameters[columndex].updating"
                        >
                          <div class="d-flex justify-content-start">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                        <fa-icon
                          *ngIf="action.action_parameters[columndex].success"
                          class="success-notification"
                          [icon]="faCircleCheck"
                        ></fa-icon
                      ></span>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <div class="editing">
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'boolean'
                              "
                            >
                              <p-checkbox
                                [(ngModel)]="
                                  action.action_parameters[columndex]
                                    .action_value.value
                                "
                                [binary]="true"
                                [inputId]="
                                  action.name +
                                  (index + 1) +
                                  action.action_parameters[columndex].name
                                "
                                (ngModelChange)="
                                  initiateActionValueUpdate(
                                    action.action_parameters[columndex],
                                    columndex,
                                    action.id
                                  )
                                "
                              ></p-checkbox>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'text'
                              "
                            >
                              <input
                                type="text"
                                class="form-control"
                                [id]="
                                  action.name +
                                  (index + 1) +
                                  action.action_parameters[columndex].name
                                "
                                [(ngModel)]="
                                  action.action_parameters[columndex]
                                    .action_value.value
                                "
                                (ngModelChange)="
                                  initiateActionValueUpdate(
                                    action.action_parameters[columndex],
                                    columndex,
                                    action.id
                                  )
                                "
                                [maxLength]="charLimit"
                              />
                            </ng-container>
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'textarea'
                              "
                            >
                              <textarea
                                class="form-control"
                                [id]="
                                  action.name +
                                  (index + 1) +
                                  action.action_parameters[columndex].name
                                "
                                [(ngModel)]="
                                  action.action_parameters[columndex]
                                    .action_value.value
                                "
                                (ngModelChange)="
                                  initiateActionValueUpdate(
                                    action.action_parameters[columndex],
                                    columndex,
                                    action.id
                                  )
                                "
                                [maxLength]="charLimit"
                              ></textarea>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'number'
                              "
                            >
                              <input
                                type="number"
                                class="form-control"
                                [id]="
                                  action.name +
                                  (index + 1) +
                                  action.action_parameters[columndex].name
                                "
                                [(ngModel)]="
                                  action.action_parameters[columndex]
                                    .action_value.value
                                "
                                (ngModelChange)="
                                  initiateActionValueUpdate(
                                    action.action_parameters[columndex],
                                    columndex,
                                    action.id
                                  )
                                "
                                [max]="
                                  action.action_parameters[columndex].max_value
                                "
                              />
                            </ng-container>
                            <ng-container
                              *ngIf="
                                action.action_parameters[
                                  columndex
                                ].type.indexOf('chip') !== -1
                              "
                            >
                              <ng-container
                                *ngFor="
                                  let chip_group of action.action_parameters[
                                    columndex
                                  ].chip_groups;
                                  let chipdex = index
                                "
                              >
                                <div class="inputgroup">
                                  <p-dropdown
                                    [options]="
                                      action.action_parameters[columndex]
                                        .chip_options.chips
                                    "
                                    [(ngModel)]="
                                      action.action_parameters[columndex]
                                        .chip_groups[chipdex].chip.id
                                    "
                                    optionLabel="label"
                                    optionValue="id"
                                    [inputId]="
                                      action.name +
                                      (index + 1) +
                                      action.action_parameters[columndex].name +
                                      'chip' +
                                      chipdex
                                    "
                                    (onChange)="
                                      initiateActionValueUpdate(
                                        action.action_parameters[columndex],
                                        columndex,
                                        action.id
                                      )
                                    "
                                    [placeholder]="'Select Chip'"
                                    [showClear]="true"
                                  ></p-dropdown>
                                  <p-dropdown
                                    [options]="
                                      action.action_parameters[columndex]
                                        .chip_options.modifiers
                                    "
                                    [(ngModel)]="
                                      action.action_parameters[columndex]
                                        .chip_groups[chipdex].modifier.id
                                    "
                                    optionLabel="label"
                                    optionValue="id"
                                    [inputId]="
                                      action.name +
                                      (index + 1) +
                                      action.action_parameters[columndex].name +
                                      'modifier' +
                                      chipdex
                                    "
                                    (onChange)="
                                      initiateActionValueUpdate(
                                        action.action_parameters[columndex],
                                        columndex,
                                        action.id
                                      )
                                    "
                                    [placeholder]="'Select Modifier'"
                                    [showClear]="true"
                                  ></p-dropdown>
                                  <p-dropdown
                                    [options]="
                                      action.action_parameters[columndex]
                                        .chip_options.texts
                                    "
                                    [(ngModel)]="
                                      action.action_parameters[columndex]
                                        .chip_groups[chipdex].text1.id
                                    "
                                    optionLabel="label"
                                    optionValue="id"
                                    [inputId]="
                                      action.name +
                                      (index + 1) +
                                      action.action_parameters[columndex].name +
                                      'text1' +
                                      chipdex
                                    "
                                    (onChange)="
                                      initiateActionValueUpdate(
                                        action.action_parameters[columndex],
                                        columndex,
                                        action.id
                                      )
                                    "
                                    [placeholder]="'Select text1'"
                                    [showClear]="true"
                                  ></p-dropdown>
                                  <p-dropdown
                                    [options]="
                                      action.action_parameters[columndex]
                                        .chip_options.texts
                                    "
                                    [(ngModel)]="
                                      action.action_parameters[columndex]
                                        .chip_groups[chipdex].text2.id
                                    "
                                    optionLabel="label"
                                    optionValue="id"
                                    [inputId]="
                                      action.name +
                                      (index + 1) +
                                      action.action_parameters[columndex].name +
                                      'text2' +
                                      chipdex
                                    "
                                    (onChange)="
                                      initiateActionValueUpdate(
                                        action.action_parameters[columndex],
                                        columndex,
                                        action.id
                                      )
                                    "
                                    [placeholder]="'Select text2'"
                                    [showClear]="true"
                                  ></p-dropdown>
                                </div>
                              </ng-container>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'initial_word'
                              "
                            >
                              <p-dropdown
                                [options]="
                                  action.action_parameters[columndex].extras
                                    .phonemeListValues
                                "
                                [(ngModel)]="
                                  action.action_parameters[columndex]
                                    .action_value.value
                                "
                                placeholder="Select Initial Word"
                                [disabled]="
                                  action.action_parameters[columndex].extras
                                    .phonemeListValues.length === 0
                                "
                                optionLabel="label"
                                [id]="
                                  action.name +
                                  (index + 1) +
                                  action.action_parameters[columndex].name
                                "
                                (onChange)="
                                  initiateActionValueUpdate(
                                    action.action_parameters[columndex],
                                    columndex,
                                    action.id
                                  )
                                "
                              ></p-dropdown>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'repeat_list'
                              "
                            >
                              <p-dropdown
                                [options]="wordListOptions"
                                [(ngModel)]="
                                  action.action_parameters[columndex]
                                    .action_value.value
                                "
                                placeholder="Select Word List"
                                optionLabel="label"
                                [id]="
                                  action.name +
                                  (index + 1) +
                                  action.action_parameters[columndex].name
                                "
                                (onChange)="
                                  initiateActionValueUpdate(
                                    action.action_parameters[columndex],
                                    columndex,
                                    action.id
                                  )
                                "
                              ></p-dropdown>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'context'
                              "
                            >
                              <div class="inputgroup">
                                <p-dropdown
                                  [options]="contextOptions"
                                  [(ngModel)]="
                                    action.action_parameters[columndex]
                                      .action_value.value
                                  "
                                  placeholder="Select or Enter Context"
                                  [editable]="true"
                                  optionLabel="label"
                                  [id]="
                                    action.name +
                                    (index + 1) +
                                    action.action_parameters[columndex].name
                                  "
                                  (onChange)="
                                    initiateActionValueUpdate(
                                      action.action_parameters[columndex],
                                      columndex,
                                      action.id
                                    )
                                  "
                                ></p-dropdown>
                              </div>
                            </ng-container>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <ng-container
                            *ngIf="
                              action.action_parameters[columndex].type.indexOf(
                                'chip'
                              ) === -1 &&
                              (action.action_parameters[columndex].action_value
                                .value === '' ||
                                action.action_parameters[columndex].action_value
                                  .value === null)
                            "
                          >
                            <span class="default">
                              Enter
                              {{ action.action_parameters[columndex].label }}
                            </span>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              action.action_parameters[columndex].action_value
                                .value !== ''
                            "
                          >
                            <span
                              class="pre"
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'textarea'
                              "
                              >{{
                                action.action_parameters[columndex].action_value
                                  .value
                              }}</span
                            >
                            <span
                              class="pre"
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'initial_word'
                              "
                              >{{
                                getParameterOptionLabel(
                                  action.action_parameters[columndex]
                                    .action_value.value,
                                  action.action_parameters[columndex].extras
                                    .phonemeListValues,
                                  "value"
                                )
                              }}</span
                            >
                            <span
                              class="pre"
                              *ngIf="
                                action.action_parameters[columndex].type !==
                                  'textarea' &&
                                action.action_parameters[columndex].type !==
                                  'initial_word'
                              "
                            >
                              {{
                                action.action_parameters[columndex].action_value
                                  .value
                              }}
                            </span>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              action.action_parameters[columndex].type.indexOf(
                                'chip'
                              ) !== -1
                            "
                          >
                            <ng-container
                              *ngFor="
                                let chip_set of outputChips(
                                  action.action_parameters[columndex]
                                    .chip_groups
                                )
                              "
                            >
                              <span>{{ chip_set }}</span
                              ><br />
                            </ng-container>
                          </ng-container>
                        </ng-template>
                      </p-cellEditor>
                    </div>
                  </td>
                  <td
                    *ngIf="action.action_parameters[columndex].constrained"
                    class="action-parameters"
                    [pEditableColumn]="
                      action.action_parameters[columndex].action_value
                        .action_parameter_option_id
                    "
                    pEditableColumnField="action_parameter_option_id"
                    [ngClass]="{
                      required:
                        action.action_parameters[columndex].required &&
                        (!action.action_parameters[columndex].action_value
                          .action_parameter_option_id ||
                          action.action_parameters[columndex].action_value
                            .action_parameter_option_id === 0)
                    }"
                  >
                    <div class="parameter-wrapper">
                      <span class="parameter-name"
                        ><span
                          *ngIf="action.action_parameters[columndex].required"
                          >*</span
                        ><span class="parameter-label">{{
                          action.action_parameters[columndex].label
                        }}</span>
                        <div
                          class="updating-notification"
                          *ngIf="action.action_parameters[columndex].updating"
                        >
                          <div class="d-flex justify-content-start">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                        <fa-icon
                          *ngIf="action.action_parameters[columndex].success"
                          class="success-notification"
                          [icon]="faCircleCheck"
                        ></fa-icon
                      ></span>
                      <p-cellEditor>
                        <ng-container>
                          <ng-template pTemplate="input">
                            <div class="editing">
                              <ng-container
                                *ngIf="
                                  action.action_parameters[columndex].type ===
                                  'select'
                                "
                              >
                                <p-dropdown
                                  [options]="
                                    action.action_parameters[columndex]
                                      .action_parameter_options
                                  "
                                  [(ngModel)]="
                                    action.action_parameters[columndex]
                                      .action_value.action_parameter_option_id
                                  "
                                  optionLabel="label"
                                  optionValue="id"
                                  [inputId]="
                                    action.name +
                                    (index + 1) +
                                    action.action_parameters[columndex].name
                                  "
                                  (onChange)="
                                    initiateActionValueUpdate(
                                      action.action_parameters[columndex],
                                      columndex,
                                      action.id
                                    )
                                  "
                                  [placeholder]="
                                    'Select ' +
                                    action.action_parameters[columndex].label
                                  "
                                ></p-dropdown>
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  action.action_parameters[columndex].type ===
                                  'multiselect'
                                "
                              >
                                <p-multiSelect
                                  [options]="
                                    action.action_parameters[columndex]
                                      .action_parameter_options
                                  "
                                  [(ngModel)]="
                                    action.action_parameters[columndex]
                                      .action_value.value
                                  "
                                  optionLabel="label"
                                  optionValue="id"
                                  [inputId]="
                                    action.name +
                                    (index + 1) +
                                    action.action_parameters[columndex].name
                                  "
                                  (onChange)="
                                    initiateActionValueUpdate(
                                      action.action_parameters[columndex],
                                      columndex,
                                      action.id
                                    )
                                  "
                                  [placeholder]="
                                    'Select ' +
                                    action.action_parameters[columndex].label
                                  "
                                  [filter]="false"
                                  [showClear]="false"
                                ></p-multiSelect>
                              </ng-container>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="output">
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'select'
                              "
                              ><ng-container
                                *ngIf="
                                  action.action_parameters[columndex]
                                    .action_value.action_parameter_option_id ===
                                  0
                                "
                              >
                                <span class="default">
                                  Select a
                                  {{
                                    action.action_parameters[columndex].label
                                  }}
                                </span>
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  action.action_parameters[columndex]
                                    .action_value.action_parameter_option_id !==
                                  0
                                "
                              >
                                {{
                                  getParameterOptionLabel(
                                    action.action_parameters[columndex]
                                      .action_value.action_parameter_option_id,
                                    action.action_parameters[columndex]
                                      .action_parameter_options
                                  )
                                }}</ng-container
                              ></ng-container
                            >
                            <ng-container
                              *ngIf="
                                action.action_parameters[columndex].type ===
                                'multiselect'
                              "
                              ><ng-container
                                *ngIf="
                                  !action.action_parameters[columndex]
                                    .action_value.value
                                "
                              >
                                <span class="default">
                                  Select a
                                  {{
                                    action.action_parameters[columndex].label
                                  }}
                                </span>
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  action.action_parameters[columndex]
                                    .action_value.value
                                "
                              >
                                <ng-container
                                  *ngFor="
                                    let value of action.action_parameters[
                                      columndex
                                    ].action_value.value
                                  "
                                  ><span>
                                    {{
                                      getParameterOptionLabel(
                                        value,
                                        action.action_parameters[columndex]
                                          .action_parameter_options
                                      )
                                    }} </span
                                  ><br /></ng-container></ng-container
                            ></ng-container>
                          </ng-template>
                        </ng-container>
                      </p-cellEditor>
                    </div>
                  </td>
                </ng-container>
                <ng-container
                  *ngIf="action.action_parameters[columndex] === undefined"
                >
                  <td class="action-parameters">&nbsp;</td>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tr>
        <tr
          #lastRow
          id="authoringTableLastRow"
          class="last-row"
          *ngIf="index === actionsLength - 1"
        >
          <td></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer"></ng-template>
    </p-table>
    <div class="table-actions">
      <p-dropdown
        [options]="actionFunctions"
        [appendTo]="'body'"
        [(ngModel)]="newAction"
        optionLabel="label"
        optionValue="id"
        [scrollHeight]="'400'"
        [styleClass]="'dropup'"
        [inputId]="'addNewActionDropdown'"
        placeholder="Add an Action"
        (ngModelChange)="addTableRow($event)"
        (onShow)="updateTDzindex($event)"
        (onHide)="resetTDzindex($event)"
      ></p-dropdown>
    </div>
  </div>
</ng-container>
<!-- OverlayPanel content -->
<p-overlayPanel #actionSelectOverlay [styleClass]="'button-overlay'">
  <ul class="overlay-list">
    <li
      *ngFor="let option of actionFunctionsOverlay"
      (click)="onActionOverlaySelect($event, option)"
    >
      {{ option.label }}
    </li>
  </ul>
</p-overlayPanel>
<p-overlayPanel #actionCopyOverlay [styleClass]="'button-overlay'">
  <div class="card flex flex-wrap copy-to">
    <div class="flex-auto">
      <label for="integeronly">Copy To:</label>
      <p-inputNumber inputId="integeronly" [(ngModel)]="copyLocation">
      </p-inputNumber>
      <p-inputNumber
        hidden
        inputId="integeronly"
        [(ngModel)]="copyLocation"
      ></p-inputNumber>
    </div>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      data-dismiss="modal"
      (click)="copyRow($event)"
      class="theme-modal-button btn"
    >
      Copy
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="cancelCopy($event)"
    >
      Cancel
    </button>
  </div>
</p-overlayPanel>
