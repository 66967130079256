import { Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {} from "@angular/common/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";

import { CoolLocalStorage } from "@angular-cool/storage";

@Injectable({
  providedIn: "root",
})
export class MediaManagementServicesService {
  private restURL: string = this._clientManagementService.getRESTurl();

  //observables
  public mediaMetaObserver = new Subject();
  public mediaMetaDone$ = this.mediaMetaObserver.asObservable();

  public updateMediaMetaObserver = new Subject();
  public updateMediaMetaDone$ = this.updateMediaMetaObserver.asObservable();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    private http: HttpClient,
    private coolLocalStorage: CoolLocalStorage
  ) {}

  public retrieveUserMedia(token: string) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let retrieveMedia = this._xrPlatformRestService.retrieveEntityData(
      "user",
      "assets",
      options
    );

    return retrieveMedia.toPromise();
  }

  public mediaService(teamID: number, token: string) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let retrieveMedia = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "assets",
      teamID,
      options,
      true
    );

    return retrieveMedia;
  }

  public retrieveMediaItem(uuid: string, token: string): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const httpOptions = {
      headers: headers,
    };

    let compileURL = [this.restURL, "asset", uuid];
    let restURL = compileURL.join("/");

    return this.http
      .get(restURL, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public manageMedia(uuid: string, token: string, action: string, body?: any) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    if (body === undefined) {
      body = {};
    }

    let compileURL = [this.restURL, "asset", uuid];

    if (action !== "") {
      compileURL.push(action);
    }

    let restURL = compileURL.join("/");

    const httpOptions = {
      headers: headers,
    };

    return this.http
      .put(restURL, body, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public determineMediaForMeta(
    mediaKey,
    mediaArray,
    legacy,
    incoming,
    attached,
    attachedType,
    action?
  ) {
    //if media was never added in the first place
    if (legacy === undefined || legacy[mediaKey] === undefined)
      legacy[mediaKey] = null;

    let thisAttached = {};

    if (action === undefined) action = "";

    if (attachedType === "experience")
      thisAttached = {
        name: attached.name,
        experience: attached.experience,
        id: attached.id,
        key: mediaKey,
      };

    if (attachedType === "user")
      thisAttached = {
        id: attached.id,
        first_name: attached.first_name,
        last_name: attached.last_name,
        email: attached.email,
        username: attached.username,
      };

    //totally new media
    if (incoming[mediaKey] !== null && legacy[mediaKey] === null) {
      mediaArray.push({
        action: "add",
        item: incoming[mediaKey][0],
        thisAttached: thisAttached,
      });
    }

    //totally removing all media
    if (incoming[mediaKey] === null && legacy[mediaKey] !== null)
      mediaArray.push({
        action: "remove",
        item: legacy[mediaKey][0],
        thisAttached: thisAttached,
      });

    //updating media: removing old media and adding new media
    if (
      incoming[mediaKey] !== null &&
      legacy[mediaKey] !== null &&
      incoming[mediaKey][0].id !== legacy[mediaKey][0].id
    ) {
      mediaArray.push({
        action: "remove",
        item: legacy[mediaKey][0],
        thisAttached: thisAttached,
      });

      mediaArray.push({
        action: "add",
        item: incoming[mediaKey][0],
        thisAttached: thisAttached,
      });
    }

    //totally removing all media on entity delete
    if (
      incoming[mediaKey] !== null &&
      legacy[mediaKey] !== null &&
      incoming[mediaKey][0].id === legacy[mediaKey][0].id &&
      action === "delete"
    ) {
      mediaArray.push({
        action: "remove",
        item: legacy[mediaKey][0],
        thisAttached: thisAttached,
      });
    }

    return mediaArray;
  }

  public async getMediaMeta(mediaToUpdate, entity_id, targetMeta, token) {
    let mediaMeta = [];

    if (!mediaMeta.length) this.updateMediaMetaObserver.next("done");

    let waiting = await Promise.all(
      mediaToUpdate.map(async (media) => {
        const response = await this.getMediaItem(media.item, token);

        let toUpdate = {
          action: media.action,
          item: response,
          thisAttached: media.thisAttached,
        };

        mediaMeta.push(toUpdate);
      })
    );

    if (waiting.length)
      return this.updateMediaMeta(mediaMeta, entity_id, targetMeta, token);
  }

  private getMediaItem(media, token) {
    let getMediaItem = this.retrieveMediaItem(media.uuid, token);

    return getMediaItem.toPromise();
  }

  private async updateMediaMeta(mediaToUpdate, entity_id, targetMeta, token) {
    let waiting = await Promise.all(
      mediaToUpdate.map(async (media) => {
        const response = await this.updateMediaItem(
          media.item,
          media.action,
          entity_id,
          targetMeta,
          media.thisAttached,
          token
        );
      })
    );

    if (waiting.length) this.updateMediaMetaObserver.next("done");
  }

  private updateMediaItem(
    media,
    action,
    entity_id,
    targetMeta,
    thisAttached,
    token
  ) {
    let meta = {
      [targetMeta]: [],
    };

    if (media.meta !== undefined && media.meta !== null) meta = media.meta;

    if (meta[targetMeta] === undefined) meta[targetMeta] = [];

    let existing = false;

    if (meta[targetMeta].length) {
      meta[targetMeta].forEach((metaEntity, index) => {
        if (metaEntity.id === entity_id) {
          existing = true;
          if (action === "remove") meta[targetMeta].splice(index, 1);
        }
      });
    }

    if (!existing && action === "add") {
      meta[targetMeta].push(thisAttached);
    }

    let body = {
      [targetMeta]: meta[targetMeta],
    };

    let updateMediaItem = this.manageMedia(
      media.uuid,
      token,
      "update-meta",
      body
    );

    return updateMediaItem.toPromise();
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }

  public retrieveToken() {
    return this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public retrieveUser() {
    return this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  public retrieveEventsAttachedAsProps(
    uuid: string,
    token: string
  ): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const httpOptions = {
      headers: headers,
    };
    let compileURL = [this.restURL, "asset", uuid, "schedule/prop/attachments"];
    let restURL = compileURL.join("/");

    return this.http
      .get(restURL, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
