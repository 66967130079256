import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-manage-experience-modal",
  templateUrl: "./manage-experience-modal.component.html",
})
export class ManageExperienceModalComponent implements OnInit {
  constructor(
    public manageExperienceModal: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder
  ) {}

  public team_id: number;
  public token: string;
  public mode: any;
  public experience: any;
  public client_eventTypes: any;
  public global_eventTypes: any;
  public eventTypes_names: any = [];
  public experienceForm: UntypedFormGroup;

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Room",
    retry: "Retry",
  };

  private outgoing: Subject<any> = new Subject();
  private outgoingData: any;

  // autocompleter
  searchText = new Subject();
  results: Observable<string[]>;

  ngOnInit(): void {
    if (this.mode === "Add") {
      this.continueType = "reset";
      this.experienceForm = this.formBuilder.group({
        experience_name: "",
        experience_description: "",
        experience_type: "",
      });
      this.outgoingData = {
        action: "add",
        experiences: [],
      };
    } else if (this.mode === "Edit") {
      this.btnLabel.retry = "Continue Editing";
      this.continueType = "continue";
      this.experienceForm = this.formBuilder.group({
        experience_name: this.experience.name,
        experience_description: this.experience.description,
        experience_type:
          this.experience.event_type_id !== null
            ? this.getLabel(this.experience.event_type_id)
            : "",
      });
    }
    this.retrieveToken();
    this.assingEventType();
    this.experienceForm = this.experienceForm;
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }
  public resetForm() {
    this.experienceForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.manageExperienceModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

  private getClientEventType() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };
    const options = {
      headers: headers,
    };
    let incomingEventTypes = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.team_id + "/scheduled-event-types",
      "get",
      {},
      options
    );
    return incomingEventTypes.toPromise();
  }

  private getGlobalEventType() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };
    const options = {
      headers: headers,
    };
    let globalEventTypes = this._xrPlatformRestService.restfulAPIQuery(
      "/scheduled-event-types",
      "get",
      {},
      options
    );
    return globalEventTypes.toPromise();
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.eventTypes_names.filter((item: any) =>
      item.toLowerCase().includes(filterValue)
    );
  }

  public assingEventType() {
    let clientEventTypes = this.getClientEventType();
    let globalEventTypes = this.getGlobalEventType();
    let promise = [clientEventTypes, globalEventTypes];
    Promise.all(promise)
      .then((response) => {
        let clientEvenT = response[0].event_types;
        let globalEvenT = response[1].event_types;
        let event_type_labels = [];
        let etLabel = clientEvenT.map((singleET) => ({
          ...singleET,
          ...globalEvenT.find((globalET) => {
            singleET.event_type_id === globalET.id
              ? event_type_labels.push(globalET.label)
              : "";
          }),
        }));
        this.eventTypes_names = event_type_labels;
        this.client_eventTypes = etLabel;

        this.results = this.searchText.pipe(
          startWith(""),
          map((value: string) => this.filter(value))
        );
      })
      .catch ((error) => {
        ;
      });
  }

  manageExperience() {
    if (this.mode === "Add") {
      this.addExperience();
    } else if (this.mode === "Edit") {
      this.editExperience();
    }
  }
  addExperience() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Adding experience</span>";
    ;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      name: this.experienceForm.value.experience_name,
      description: this.experienceForm.value.experience_description,
      team_id: this.team_id,
      event_type_id: this.getEventId(this.experienceForm.value.experience_type),
    };
    let addedExperience = this._xrPlatformRestService.addExperience(
      data,
      getOptions
    );
    addedExperience.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Experience successfully added";
        
        if (response.error === undefined || response.error != 418) {
          
          let outgoingData = {
            action: "add",
            experience: response.experience,
          };

          ;
          
          this.outgoing.next(outgoingData);
        }
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }
  editExperience() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Editing experience</span>";

    ;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      name: this.experienceForm.value.experience_name,
      description: this.experienceForm.value.experience_description,
      team_id: this.team_id,
      event_type_id: this.getEventId(this.experienceForm.value.experience_type),
    };
    let edittedExperience = this._xrPlatformRestService.editExperience(
      this.experience.id,
      data,
      getOptions
    );
    edittedExperience.subscribe(
      (response) => {
        ;
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Experience successfully udpated";

        if (response.error != 418) {
          let outgoingData = {
            action: "update",
            experience: response.experience,
          };
          this.outgoing.next(outgoingData);
          ;
        }
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  private getEventId(label: string) {
    let index = this.global_eventTypes.findIndex((x) => x.label === label);
    if (index === -1) index = 0;
    return this.global_eventTypes[index].id;
  }

  private getLabel(id: number) {
    let index = this.global_eventTypes.findIndex((i) => i.id === id);
    return this.global_eventTypes[index].label;
    // return this.global_eventTypes[index].id;
  }
}
