<div class="media-upload-page page">
  <div class="row header-row" style="margin-bottom: 0%">
    <div class="col-md-12">
      <div class="title-callout">
        <div class="callout-headers-wrapper">
          <div class="callout-headers float-left">
            <h2 class="h4 strong m-0">Entity Management</h2>
          </div>
        </div>
        <div class="callout-text-wrapper">
          <p>
            <span [innerHTML]="copy"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row body-row" *ngIf="!loading">
    <div class="col-md-12">
      <mdb-tabset
        #staticTabs
        [buttonClass]="'nav md-pills pills-primary mb-2'"
        [justified]="true"
        style="padding-top: 0%"
      >
        <mdb-tab heading="Rooms">
          <div class="row">
            <div class="upload-button col-12">
              <button
                type="button"
                mdbBtn
                class="waves-light theme-action-button mb-3"
                mdbWavesEffect
                (click)="openAddRoomModal()"
                id="add-entity-room"
              >
                Add Room
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table
                mdbTable
                mdbTableScroll
                stickyHeader="true"
                scrollY="true"
                maxHeight="1000"
                hover="true"
                striped="true"
                class=""
              >
                <thead class="sticky-top">
                  <tr>
                    <th
                      *ngFor="let head of headElements; let i = index"
                      [mdbTableSort]="rooms"
                      [sortBy]="sort_elements[i]"
                      scope="col"
                    >
                      {{ head | titlecase }}
                    </th>
                  </tr>
                </thead>
                <tbody #row>
                  <tr
                    mdbTableCol
                    *ngFor="let el of rooms; let i = index"
                  >
                    <th>{{ el.name }}</th>
                    <th>{{ el.id }}</th>
                    <td>{{ el.description }}</td>
                    <td>
                      <mdb-icon
                        fas
                        icon="edit"
                        (click)="openEditRoomModal(el, i)"
                        id="edit-entity-room"
                      ></mdb-icon>
                    </td>
                    <td>
                      <mdb-icon
                        fas
                        icon="archive"
                        id="archive-entity-room"
                        (click)="openArchiveEntityModal(el, 'room', i)"
                      ></mdb-icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mdb-tab>

        <mdb-tab heading="Legacy Experiences">
          <div class="row">
            <div class="upload-button">
              <button
                type="button"
                mdbBtn
                class="waves-light theme-action-button"
                mdbWavesEffect
                (click)="openAddExperienceModal()"
                id="add-entity-experience"
              >
                <i class="">&nbsp;Add Legacy Experience</i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 container-gallery justify-content-start">
              <div *ngFor="let experience of experiences; let i = index">
                <mdb-card class="w-100 container-item">
                  <mdb-card-body>
                    <a class="activator px-3 mr-6">
                      <mdb-icon
                        id="edit-entity-experience"
                        far
                        (click)="openEditExperienceModal(experience, i)"
                        icon="edit"
                      ></mdb-icon>
                      <mdb-icon
                        id="archive-entity-experience"
                        fas
                        icon="archive"
                        (click)="
                          openArchiveEntityModal(experience, 'experience', i)
                        "
                      ></mdb-icon>
                    </a>
                    <mdb-card-title>
                      <h4>{{ experience.name }}</h4>
                      <p
                        class="font-weight-bold"
                      >
                        ID: {{ experience.id }}
                      </p>
                    </mdb-card-title>

                    <hr />
                    <mdb-card-text>
                      {{ experience.description }}
                      <ul
                        class="list-unstyled"
                        *ngIf="
                          experience.event_type_id !== null;
                          else noEventType
                        "
                      >
                        <li>{{ experience.event_type.label }}</li>
                      </ul>
                      <ng-template #noEventType>
                        <ul class="list-unstyled">
                          <li>No Event Type Assigned</li>
                        </ul>
                      </ng-template>
                    </mdb-card-text>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </div>
        </mdb-tab>
        <mdb-tab heading="Personas">
          <div class="row">
            <div class="upload-button">
              <button
                type="button"
                mdbBtn
                class="waves-light theme-action-button"
                mdbWavesEffect
                (click)="openAddPersonaModal()"
                id="add-entity-persona"
              >
                <i class="">&nbsp;Add Persona</i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 container-gallery justify-content-start">
              <div *ngFor="let persona of personas; let i = index">
                <mdb-card class="w-100">
                  <mdb-card-body>
                    <a class="activator px-3 mr-6">
                      <mdb-icon
                        fas
                        (click)="openViewPersonaPermissionModal(persona)"
                        icon="users"
                        id="view-entity-persona"
                      ></mdb-icon>
                      <mdb-icon
                        far
                        (click)="openEditPersonaModal(persona, i)"
                        icon="edit"
                        id="edit-entity-persona"
                      ></mdb-icon>
                      <mdb-icon
                        fas
                        icon="archive"
                        (click)="openArchiveEntityModal(persona, 'persona', i)"
                        id="archive-entity-persona"
                      ></mdb-icon>
                    </a>
                    <mdb-card-title>
                      <h4>{{ persona.persona_name }}</h4>
                      <p
                        class="font-weight-bold"
                      >
                        ID: {{ persona.id }}
                      </p>
                    </mdb-card-title>
                    <hr />

                    <mdb-card-text>
                      {{ persona.description }}
                    </mdb-card-text>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </div>
        </mdb-tab>
        <mdb-tab heading="Permissions">
          <div class="row">
            <div class="upload-button">
              <button
                type="button"
                mdbBtn
                class="waves-light theme-action-button"
                mdbWavesEffect
                (click)="openAddPermissionModal()"
                id="add-entity-permission"
              >
                <i class="">&nbsp;Add Permission</i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 container-gallery justify-content-start">
              <div *ngFor="let permission of permissions; let i = index">
                <mdb-card class="w-100">
                  <mdb-card-body>
                    <a class="activator px-3 mr-6">
                      <mdb-icon
                        far
                        (click)="openEditPermissionModal(permission, i)"
                        icon="edit"
                        id="edit-entity-permission"
                      ></mdb-icon>
                      <mdb-icon
                        fas
                        icon="archive"
                        (click)="
                          openArchiveEntityModal(permission, 'permission', i)
                        "
                        id="archive-entity-permission"
                      ></mdb-icon>
                    </a>
                    <mdb-card-title>
                      <h4>{{ permission.permission_name }}</h4>
                      <p
                        class="font-weight-bold"
                      >
                        ID: {{ permission.id }}
                      </p>
                    </mdb-card-title>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </div>
        </mdb-tab>
      </mdb-tabset>
    </div>
  </div>
</div>
