import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";

import { XrPlatformRestService } from "./../../../../../services/rest/xr-platform/xr-platform-rest.service";
import { StringManagementService } from "src/app/services/utilities/string-management.service";
import { MustMatch } from "../../../validators/must-match.directive";

@Component({
  selector: "app-edit-password",
  templateUrl: "./edit-password.component.html",
  styleUrls: ["./edit-password.component.scss"],
})
export class EditPasswordComponent implements OnInit {
  //persistent
  public token: string;

  //incoming
  public teamID: number;
  public targetUser: any;
  public user: any;

  //feedback response
  public formTitle: string = "Edit Password";
  public errorMsg: string = "";
  public statusMsg: string = "";
  public formState: string = "active";
  public loading: boolean = true;

  //form vars
  public pwForm: UntypedFormGroup;
  public btnLabel: { close: string; main: string } = {
    close: "Cancel",
    main: "Update",
  };
  public preSelected: {
    id: any;
    password: string;
  } = {
    id: null,
    password: null,
  };

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _stringManagementService: StringManagementService,
    public pwFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage
  ) {}

  ngOnInit() {
    this.preSelected.id =
      this.targetUser === undefined ? null : this.targetUser.id;

    this.retrieveToken();
    this.makeFormValidatingGroup();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private makeFormValidatingGroup() {
    this.pwForm = new UntypedFormGroup(
      {
        password: new UntypedFormControl(
          "",
          Validators.compose([Validators.required, Validators.minLength(6)])
        ),
        confirm_password: new UntypedFormControl(
          "",
          Validators.compose([Validators.required])
        ),
      },
      [MustMatch("password", "confirm_password")]
    );

    this.loading = false;
  }

  get password() {
    return this.pwForm.get("password");
  }

  get confirm_password() {
    return this.pwForm.get("confirm_password");
  }

  public managePassword() {
    this.errorMsg = "";
    let incomingValues = this.pwForm.value;
    this.formState = "processing";
    this.statusMsg = "<span class='loading-msg'>Updating password</span>";

    ;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const httpOptions = {
      headers: headers,
    };

    let formValues = {
      password: incomingValues.password,
    };

    ;

    const options = JSON.stringify(formValues);

    let updateUser = this._xrPlatformRestService.manageUsers(
      this.targetUser.id,
      "update-by-admin",
      options,
      httpOptions
    );

    updateUser.subscribe(
      (response) => {
        this.statusMsg = "Password successfully updated.";
        this.formState = "success";
        this.btnLabel.close = "Close";
      },
      (err) => {
        ;
        this.statusMsg = "";
        this.formState = "active";
        let errorMsg = JSON.parse(err._body);
        this.errorMsg = errorMsg.error;
      }
    );
  }
}
