import { CoolLocalStorage } from "@angular-cool/storage";
import { group } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-view-options-modal",
  templateUrl: "./view-options-modal.component.html",
  styleUrls: ["./view-options-modal.component.scss"],
})
export class ViewOptionsModalComponent implements OnInit {
  public team_id: number;
  public options: any;
  public item: any;
  public token: string;
  public loading: boolean;

  public addOptionModal: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    public viewItemOptionsModal: MDBModalRef,
    private modalService: MDBModalService
  ) {}

  ngOnInit(): void {
    this.loading = false;

    this.retrieveToken();
    this.options = this.item.options;
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public clickDeleteItemOption(option_id: number, index: number) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let optionDelete = this._xrPlatformRestService.restfulAPIQuery(
      "/inventory/" + this.item.id + "/options/" + option_id + "/archive",
      "put",
      {},
      getOptions
    );
    optionDelete.subscribe((response) => {
      this.options.splice(index, 1);
      if (response.error != 418) {
      }
      (error) => {
        //@todo: handle error
      };
    });
  }
}
