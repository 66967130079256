<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span>{{ type | titlecase }} App Version</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="appVersionFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true"><fa-icon [icon]="faTimes"></fa-icon></span>
    </button>
  </div>

  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <div class="row">
        <div *ngIf="action === 'delete'">
          <div class="col-12">
            <p class="font-16">
              Archive the app version
              {{ targetVersion.event_name }}?
            </p>
          </div>
        </div>
        <form
          *ngIf="!formLoading && action !== 'delete'"
          class="edit-form striped-form form"
          autocomplete="disabled"
          [formGroup]="manageAppVersionForm"
        >
          <div class="row p-0">
            <div class="col-12">
              <p>
                <span class="action-msg-small">Required*</span>
              </p>
            </div>
          </div>
          <div class="row striped">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form">
                  <input
                    type="text"
                    id="itemLabel"
                    class="form-control"
                    formControlName="label"
                    mdbInput
                  />
                  <label class="active-label active" for="itemLabel"
                    >* Label</label
                  >
                  <mdb-error
                    *ngIf="label.invalid && (label.dirty || label.touched)"
                  >
                    Please provide a label
                  </mdb-error>
                </div>
              </div>
              <div class="grid-item">
                <div class="md-form">
                  <button
                    mdbBtn
                    class="info-button color-theme-base-medium"
                    mdbPopover="Note: Name is a code-friendly reference for this experience. Name should only contain numbers, leters, and underscores, and it must be unique across the entire Admin Panel System."
                    placement="right"
                    mdbPopoverHeader="About the Name Field"
                    triggers="focus"
                    mdbWavesEffect
                  >
                    <mdb-icon fas icon="info-circle"></mdb-icon>
                  </button>
                  <input
                    type="text"
                    id="itemName"
                    class="form-control"
                    formControlName="name"
                    mdbInput
                  />
                  <label class="active-label active" for="itemName"
                    >* Name</label
                  >
                  <mdb-error
                    *ngIf="name.invalid && (name.dirty || name.touched)"
                  >
                    Please provide a name
                  </mdb-error>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form">
                  <input
                    type="text"
                    id="experienceDescription"
                    class="form-control"
                    formControlName="description"
                    mdbInput
                  />
                  <label class="active-label active" for="experienceDescription"
                    >Description</label
                  >
                </div>
              </div>
              <div class="grid-item">
                <div class="row dates">
                  <div class="col-7">
                    <!--start start date-->
                    <div class="md-form">
                      <mdb-date-picker
                        #datePickerStart
                        class="start-picker"
                        name="asOfDate"
                        id="asOfDate"
                        [options]="myDatePickerOptions"
                        label="* As Of"
                        [inline]="true"
                        formControlName="as_of_date"
                        required
                      ></mdb-date-picker>
                    </div>
                    <!--end start date-->
                  </div>
                  <div class="col-5">
                    <!--start start time-->
                    <div class="md-form">
                      <mdb-select-2 formControlName="as_of_time">
                        <mdb-select-option
                          *ngFor="let option of timeSelect"
                          [value]="option.value"
                          >{{ option.label }}</mdb-select-option
                        >
                      </mdb-select-2>
                    </div>
                    <!--end start time-->
                  </div>
                  <mdb-error
                    *ngIf="
                      as_of_date.invalid &&
                      as_of_time.valid &&
                      (as_of_date.dirty || as_of_date.touched)
                    "
                  >
                    Please provide a date
                  </mdb-error>
                  <mdb-error
                    *ngIf="
                      as_of_time.invalid &&
                      as_of_date.valid &&
                      (as_of_time.dirty || as_of_time.touched)
                    "
                  >
                    Please provide a time
                  </mdb-error>
                  <mdb-error
                    *ngIf="
                      as_of_date.invalid &&
                      as_of_time.invalid &&
                      (as_of_date.dirty || as_of_date.touched) &&
                      (as_of_time.dirty || as_of_time.touched)
                    "
                  >
                    Please provide a date and time
                  </mdb-error>
                </div>
              </div>
            </div>
          </div>
          <div class="row striped">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form search">
                  <input
                    type="text"
                    id="bundleAuto"
                    formControlName="bundle_id"
                    [mdbAutoCompleter]="autoBundle"
                    class="completer-input form-control mdb-autocomplete"
                    placeholder="Search for Bundle"
                  />
                  <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                  <mdb-auto-completer
                    [displayValue]="onDisplayValue"
                    #autoBundle="mdbAutoCompleter"
                    textNoResults="No results found"
                    [clearButton]="true"
                  >
                    <mdb-option
                      *ngFor="let option of bundleResults | async"
                      [value]="option"
                    >
                      {{ option.label }}
                    </mdb-option>
                  </mdb-auto-completer>
                  <label class="active-label active" for="itemName"
                    >* App Bundle</label
                  >
                  <mdb-error
                    *ngIf="
                      bundle_id.invalid &&
                      (bundle_id.dirty || bundle_id.touched)
                    "
                  >
                    Please provide a bundle
                  </mdb-error>
                </div>
              </div>
              <div class="grid-item">
                <div class="md-form search">
                  <input
                    type="text"
                    id="deviceAuto"
                    formControlName="device_id"
                    [mdbAutoCompleter]="autoDevice"
                    class="completer-input form-control mdb-autocomplete"
                    placeholder="Search for Device"
                  />
                  <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                  <mdb-auto-completer
                    [displayValue]="onDisplayValue"
                    #autoDevice="mdbAutoCompleter"
                    textNoResults="No results found"
                    [clearButton]="true"
                  >
                    <mdb-option
                      *ngFor="let option of deviceResults | async"
                      [value]="option"
                    >
                      {{ option.label }}
                    </mdb-option>
                  </mdb-auto-completer>
                  <label class="active-label active" for="itemName"
                    >* App Device</label
                  >
                  <mdb-error
                    *ngIf="
                      device_id.invalid &&
                      (device_id.dirty || device_id.touched)
                    "
                  >
                    Please provide a device
                  </mdb-error>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form">
                  <input
                    type="text"
                    id="currentVersion"
                    class="form-control"
                    formControlName="current_version"
                    mdbInput
                  />
                  <label class="active-label active" for="currentVersion"
                    >* Current Version</label
                  >
                  <mdb-error
                    *ngIf="
                      current_version.invalid &&
                      (current_version.dirty || current_version.touched)
                    "
                  >
                    Please provide the current version
                  </mdb-error>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      [disabled]="manageAppVersionForm.invalid"
      (click)="manageAppVersion()"
      class="theme-modal-button btn"
    >
      {{ action }} App Version
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      class="theme-modal-cancel-button btn"
      data-dismiss="modal"
      (click)="appVersionFrame.hide()"
    >
      Close
    </button>
  </div>
</div>
