<div class="heading-content">
  <h4>My {{labels.user.plural | titlecase}}</h4>
</div>
<mdb-card class="user-management-card content-card">
  <div *ngIf="tableLoading">
    <div class="row">
      <div class="col-12">
        <p class="loading-text-alt">Loading {{labels.user.plural}}...</p>
      </div>
    </div>
  </div>
  <div *ngIf="!tableLoading">
    <div class="row justify-content-between">
      <div class="col">
        <div class="md-form">
          <input
            type="search"
            [(ngModel)]="fNameSearchText"
            class="form-control"
            id="fNameSearch"
            mdbInput
          />
          <label for="fNameSearch">First Name</label>
        </div>
      </div>
      <div class="col">
        <div class="md-form">
          <input
            type="search"
            [(ngModel)]="lNameSearchText"
            class="form-control"
            id="lNameSearch"
            mdbInput
          />
          <label for="lNameSearch">Last Name</label>
        </div>
      </div>
      <div class="col">
        <div class="md-form">
          <input
            type="search"
            [(ngModel)]="emailSearchText"
            class="form-control"
            id="emailSearch"
            mdbInput
          />
          <label for="emailSearch">email</label>
        </div>
      </div>
      <div class="col">
        <div class="md-form">
          <input
            type="text"
            [(ngModel)]="searchText"
            class="form-control"
            id="search"
            mdbInput
          />
          <label for="search">Search Username</label>
        </div>
      </div>
    </div>
    <div
      class="row"
      *ngIf="!users.length"
    >
      <div class="col-12">No {{labels.event.singular}} {{labels.user.plural}}. <a
          [routerLink]="['/client', teamID, 'events']"
        >Add {{labels.user.plural}} to your {{labels.event.plural}}.</a></div>
    </div>
    <div
      class="row"
      *ngIf="users.length"
    >
      <div class="col-12">
        <table
          class="table table-striped table-responsive-md btn-table user-table"
          mdbTable
          #tableEl="mdbTable"
          small="true"
        >
          <thead class="theme-base-medium-dark white-text">
            <tr>
              <th
                [mdbTableSort]="users"
                sortBy="first_name"
                (sorted)="sortListener($event)"
              >
                First
                <mdb-icon
                  *ngIf="sortBy != 'first_name'"
                  fas
                  icon="sort"
                  class="sort-icon"
                  mdbTooltip="Sort by first name"
                  placement="bottom"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == 'first_name' && sortOrder == 'constant'"
                  fas
                  icon="caret-up"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == 'first_name' && sortOrder == 'descending'"
                  fas
                  icon="caret-down"
                ></mdb-icon>
              </th>
              <th
                [mdbTableSort]="users"
                sortBy="last_name"
                (sorted)="sortListener($event)"
              >
                Last
                <mdb-icon
                  *ngIf="sortBy != 'last_name'"
                  fas
                  icon="sort"
                  class="sort-icon"
                  mdbTooltip="Sort by last name"
                  placement="bottom"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == 'last_name' && sortOrder == 'ascending'"
                  fas
                  icon="caret-up"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == 'last_name' && sortOrder == 'descending'"
                  fas
                  icon="caret-down"
                ></mdb-icon>
              </th>
              <th
                [mdbTableSort]="users"
                sortBy="email"
                (sorted)="sortListener($event)"
              >
                Email
                <mdb-icon
                  *ngIf="sortBy != 'email'"
                  fas
                  icon="sort"
                  class="sort-icon"
                  mdbTooltip="Sort by email"
                  placement="bottom"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == 'email' && sortOrder == 'ascending'"
                  fas
                  icon="caret-up"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == 'email' && sortOrder == 'descending'"
                  fas
                  icon="caret-down"
                ></mdb-icon>
              </th>
              <th
                [mdbTableSort]="users"
                sortBy="username"
                (sorted)="sortListener($event)"
              >
                Username
                <mdb-icon
                  *ngIf="sortBy != 'username'"
                  fas
                  icon="sort"
                  class="sort-icon"
                  mdbTooltip="Sort by username"
                  placement="bottom"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == 'username' && sortOrder == 'ascending'"
                  fas
                  icon="caret-up"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == 'username' && sortOrder == 'descending'"
                  fas
                  icon="caret-down"
                ></mdb-icon>
              </th>

              <th>Classes</th>
            </tr>
          </thead>
          <tbody>
            <tr
              mdbTableCol
              *ngFor="let thisUser of users; index as user_index"
            >
              <ng-container *ngIf="
                  user_index + 1 >= tablePagination.firstItemIndex &&
                  user_index < tablePagination.lastItemIndex
                ">
                <td>{{ thisUser.first_name }}</td>
                <td>{{ thisUser.last_name }}</td>
                <td>{{ thisUser.email }}</td>
                <td>{{ thisUser.username }}</td>
                <td>{{ thisUser.classes }}</td>
              </ng-container>
            </tr>
          </tbody>
          <tfoot class="grey lighten-5 w-100">
            <tr>
              <td colspan="12">
                <mdb-table-pagination
                  #tablePagination
                  [tableEl]="tableEl"
                  [searchDataSource]="users"
                ></mdb-table-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</mdb-card>
