import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

//rxjs imports
import { throwError, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

//third party modules
import { CoolLocalStorage } from "@angular-cool/storage";

//local services
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ExperienceServicesService } from "../services/experience-services.service";
import { UserServicesService } from "../../user-management/services/user-services.service";
import {
  MDBModalRef,
  MDBModalService,
  ToastService,
} from "ng-uikit-pro-standard";
import { ScheduleClassComponent } from "../modals/schedule-class/schedule-class.component";
import { ScheduleUserToClassComponent } from "../modals/schedule-user-to-class/schedule-user-to-class.component";
import { ScheduleGroupToClassComponent } from "../modals/schedule-group-to-class/schedule-group-to-class.component";
import { ExperienceMetaComponent } from "../modals/experience-meta/experience-meta.component";
import { EditClassRoomComponent } from "../modals/edit-class-room/edit-class-room.component";
import { ManageUsersComponent } from "../modals/manage-users/manage-users.component";
import { ManageGroupsComponent } from "../modals/manage-groups/manage-groups.component";
import { SuperAdminExperienceUpdatesComponent } from "../modals/super-admin-experience-updates/super-admin-experience-updates.component";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { EventSettingsModalComponent } from "../modals/event-settings-modal/event-settings-modal.component";
import { MediaToClassMediaManagerComponent } from "../modals/media-to-class-media-manager/media-to-class-media-manager.component";
import { Clipboard } from "@angular/cdk/clipboard";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

@Component({
  selector: "app-experiences-single",
  templateUrl: "./experiences-single.component.html",
  styleUrls: ["./experiences-single.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ExperiencesSingleComponent implements OnInit {
  //persistent
  public labels: any;
  private token: string;
  public teamID: number;
  public experience_id: number;
  public loading: boolean = true;
  public experienceLoading: boolean = true;
  public currentExperience: any;
  public displayedExperience: any;
  public recursionCounter: number = 0;

  // search
  public searchText: string = "";

  //data
  public events: any;
  public groups: any;
  public regions: any;
  public clientSettings: any;
  public user: any;
  public user_role_id: number;

  //settings
  public showLegacyEventMeta: boolean = true;
  public showEventProperties: boolean = false;
  public showLocation: boolean = false;

  //visibilty control
  public editPermissions: any;
  public showAccessCode: boolean = false;

  //debouncing
  private debounceSubject = new Subject();

  //modals
  public scheduleFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private modalService: MDBModalService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _experienceServices: ExperienceServicesService,
    private _userServices: UserServicesService,
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _settingsService: SettingsService,
    private clipboard: Clipboard,
    private toastrService: ToastService,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    //initial values
    this.editPermissions = false;
    this.user_role_id = 0;
    this.retrieveTeamID();

    //setup debouncing
    this.debounceSubject
      .pipe(debounceTime(350))
      .subscribe((params: any) => this.copyAction(params));

    this.route.params.subscribe((params) => {
      this.experience_id =
        params["experience_id"] !== undefined
          ? parseInt(params["experience_id"])
          : 0;

      this.clientSettings = this._settingsService.getSettingsFromStorage(
        this.teamID
      );

      this.clientSettings.experienceOptions = {
        buildings: {
          properties: [
            {
              label: "Technology Center",
              value: "technology_center",
            },
            {
              label: "Business Center",
              value: "business_center",
            },
            {
              label: "None",
              value: "none",
            },
          ],
        },
      };

      this.showLegacyEventMeta =
        this.clientSettings.showLegacyEventMeta.unconstrained_default;
      this.showEventProperties =
        this.clientSettings.showEventProperties.unconstrained_default;

      this.retrieveLabels();
      this.retrieveToken();
      this.retrieveUser();
      this.retrieveCurrentExperiences();
    });
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
    this.user_role_id = this.user.role_type_id;

    let editPermissions = [1, 2, 3];

    if (editPermissions.indexOf(this.user_role_id) !== -1)
      this.editPermissions = true;
  }

  private retrieveCurrentExperiences() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveExperiences = this._xrPlatformRestService.retrieveEntityData(
      "team/experience",
      this.experience_id,
      options
    );

    retrieveExperiences.subscribe(
      (response) => {
        this.currentExperience = response.ExperiencesLegacy;
        this.experienceLoading = false;
        this.setupExperiences();
      },
      (error) => {
        ;
      }
    );
  }

  private setupExperiences() {
    let retrieveDisplayedExperieces = this._experienceServices.setupExperiences(
      this.teamID,
      this.token,
      this.currentExperience,
      this.clientSettings
    );

    retrieveDisplayedExperieces
      .then((response) => {
        ;

        //if response is null, let's try again (this is probably a backend issue to address at some point)
        if (response[0] === null && this.recursionCounter < 10) {
          this.recursionCounter++;
          ;
          return this.setupExperiences();
        }

        let scheduledExperiences = response[0].events.filter((item) => {
          return !item.deleted;
        });

        let regions = this.processRegions(response[1]);

        let groups = {};
        groups = this._userServices.processGroups(response[2]);

        this.groups = groups;
        this.regions = regions;

        setTimeout(() => {
          let getEvents = this._experienceServices.mergeExperiencesData(
            this.currentExperience,
            scheduledExperiences,
            groups,
            regions,
            this.clientSettings
          );

          this.events = getEvents.scheduled_experiences;
          this.displayedExperience = getEvents;

          console.log(
            "this.displayedExperience in retrieveDisplayedExperieces",
            this.displayedExperience
          );
          ;
          this.loading = false;
        }, 1);
      })
      .catch(this.handleError);
  }

  public toggleAccessCode() {
    this.showAccessCode = !this.showAccessCode;
  }

  public copyAccessCode(event) {
    let params = {
      copy: event.access_code,
    };
    this.debounceSubject.next(params);
  }

  private copyAction(params) {
    let copyResults = this.clipboard.copy(params.copy);
    let message = "Code successufly copied";

    if (!copyResults) {
      message = "Issue copying code";
    }

    let notification = this._notificationService.successNotification(message);
  }

  private processRegions(incomingTeam) {
    if (incomingTeam.meta === null || incomingTeam.meta.regions === undefined)
      return [];

    return incomingTeam.meta.regions;
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }

  public openClassesModal() {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "schedule-class-container",
    };
    this.modalOptions.data = {
      experience: this.displayedExperience,
      user: this.user,
      teamID: this.teamID,
      experienceInDB: true,
      events: this.events,
      inviteeVersion: 2,
    };

    this.scheduleFrame = this.modalService.show(
      ScheduleClassComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((changedData) => {
      let scheduled_event =
        changedData.scheduled_event !== undefined
          ? changedData.scheduled_event
          : {};

      let thisExperience = {
        experience: this.currentExperience.name,
        name: scheduled_event.event_name,
        is_public: scheduled_event.is_public,
        id: scheduled_event.id,
        meta: scheduled_event.meta,
        endDate: scheduled_event.end_at,
        startDate: scheduled_event.start_at,
        schedule_id: scheduled_event.id,
        owner_id: scheduled_event.owner_id,
        team_id: scheduled_event.team_id,
        room: scheduled_event.rooms,
        spaces: scheduled_event.spaces,
        userCount: 0,
        userGroupCount: 0,
        scheduled_users: [],
        scheduled_user_groups: [],
        scheduled_photon_regions: [],
        scheduled_photon_regions_count: 0,
        building: null,
      };

      if (changedData.region_id) {
        let thisRegion = this.regions.filter((region) => {
          return region.id === changedData.region_id;
        });

        if (!thisRegion.length) return false;

        //we can do this because there is only ever one region per class
        thisExperience.scheduled_photon_regions.push(thisRegion[0]);
        thisExperience.scheduled_photon_regions_count++;
      }

      this.currentExperience.scheduled_experiences_count++;
      this.events.push(thisExperience);
    });
  }

  public openSuperAdminUpdateModal() {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "update-container",
    };

    this.modalOptions.data = {
      experienceID: this.displayedExperience.id,
      teamID: this.teamID,
    };

    this.scheduleFrame = this.modalService.show(
      SuperAdminExperienceUpdatesComponent,
      this.modalOptions
    );
  }

  public openExperiencesModal(experience, scheduledExperience?, action?) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass:
        action === "delete"
          ? "remove-class-container"
          : "schedule-class-container",
    };
    this.modalOptions.data = {
      experience: experience,
      user: this.user,
      teamID: this.teamID,
      targetClass: scheduledExperience,
      action: action,
      events: this.events,
    };

    this.scheduleFrame = this.modalService.show(
      ScheduleClassComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((result: any) => {
      let updatedEvent = result.scheduled_event;

      if (result.action === "update") {
        this.events.forEach((thisEvent, index) => {
          if (thisEvent.schedule_id === updatedEvent.id) {
            thisEvent.endDate = updatedEvent.end_at;
            thisEvent.startDate = updatedEvent.start_at;
            thisEvent.name = updatedEvent.event_name;
            thisEvent.is_public = updatedEvent.is_public;
            if (result.region_id) {
              let thisRegion = this.regions.filter((region) => {
                return region.id === result.region_id;
              });

              if (!thisRegion.length) return false;

              //we can do this because there is only ever one region per class
              thisEvent.scheduled_photon_regions = [];
              thisEvent.scheduled_photon_regions_count = 0;

              thisEvent.scheduled_photon_regions.push(thisRegion[0]);
              thisEvent.scheduled_photon_regions_count++;
            }
          }
        });
      } else if (result.action === "delete") {
        this.events.forEach((scheduledExperience, index) => {
          if (scheduledExperience.schedule_id === updatedEvent.id) {
            this.events.splice(index, 1);
          }
        });
      }
    });
  }

  public openManageUsersModal(targetEvent, index) {
    this.modalOptions.data = {
      targetEvent: targetEvent,
      thisUser: this.user,
      teamID: this.teamID,
      clientSettings: this.clientSettings,
    };

    this.modalOptions.class =
      this.modalOptions.class + " modal-full-height modal-right";
    this.modalOptions.containerClass = "manage-single-users-container";

    this.scheduleFrame = this.modalService.show(
      ManageUsersComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((result: any) => {
      if (result.action === "remove") {
        this.events[index] = result.targetEvent;
      }
    });
  }

  public openManageGroupsModal(targetEvent, index) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-event-groups-container",
    };

    this.modalOptions.data = {
      targetEvent: targetEvent,
      thisUser: this.user,
      groups: this.groups,
      teamID: this.teamID,
      clientSettings: this.clientSettings,
    };

    this.scheduleFrame = this.modalService.show(
      ManageGroupsComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((result: any) => {
      if (result.action === "remove") {
        this.events[index] = result.targetEvent;
      }
    });
  }

  public openUserToClassModal(targetEvent) {
    this.modalOptions.data = {
      teamID: this.teamID,
      targetEvent: targetEvent,
      clientSettings: this.clientSettings,
    };

    this.modalOptions.class = "modal-dialog modal-dialog-centered";

    this.scheduleFrame = this.modalService.show(
      ScheduleUserToClassComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((result: any) => {
      if (result.action === "scheduled-invitee") {
        this.events.forEach((thisEvent, index) => {
          if (result.experience.schedule_id === thisEvent.schedule_id) {
            result.users.forEach((user) => {
              if (user.id === result.user_id) {
                this.events[index].scheduled_users.push(user);
                this.events[index].userCount++;
              }
            });
          }
        });
      }
    });
  }

  public openGroupToClassModal(targetEvent, targetGroup?, action?) {
    this.modalOptions.data = {
      targetEvent: targetEvent,
      targetGroup: targetGroup,
      teamID: this.teamID,
      action: action,
      groups: this.groups,
    };

    this.modalOptions.class = "modal-dialog modal-dialog-centered";

    this.scheduleFrame = this.modalService.show(
      ScheduleGroupToClassComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((result: any) => {
      let groupID = result.group_id;

      if (result.action === "add") {
        let getNewGroup = this._experienceServices.getGroupData(
          this.groups,
          groupID
        );

        this.events.forEach((thisEvent, index) => {
          if (result.experience.schedule_id === thisEvent.schedule_id) {
            let newGroup = getNewGroup[0];
            newGroup.schedule_id = thisEvent.schedule_id;

            let inviteeID = 0;
            result.scheduled_event.invitees.forEach((thisInvitee) => {
              let inviteeCheck = targetEvent.scheduled_user_groups.filter(
                (thisGroup) => {
                  return thisGroup.invitee_id === thisInvitee.invitee_id;
                }
              );

              if (!inviteeCheck.length) inviteeID = thisInvitee.invitee_id;
            });
            newGroup.invitee_id = inviteeID;

            this.events[index].scheduled_user_groups.push(newGroup);

            if (this.events[index].userGroupCount === undefined)
              this.events[index].userGroupCount = 0;
            this.events[index].userGroupCount++;
          }
        });
      }
    });
  }

  public openManageMediaModal(targetEvent, type?, amount?) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "event-media-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };

    this.modalOptions.data = {
      targetEvent: targetEvent,
      teamID: this.teamID,
      type: type !== undefined ? type : "all",
      amount: amount !== undefined ? amount : "multi",
    };

    this.scheduleFrame = this.modalService.show(
      MediaToClassMediaManagerComponent,
      this.modalOptions
    );
  }

  public openExperienceMetaModalLegacy(targetEvent) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "edit-class-settings-container",
    };

    this.modalOptions.data = {
      targetEvent: targetEvent,
      teamID: this.teamID,
      action: "update",
    };

    this.scheduleFrame = this.modalService.show(
      ExperienceMetaComponent,
      this.modalOptions
    );
  }

  public openExperienceMetaModal(targetEvent) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      // class: "modal-dialog-centered modal-fluid",
      containerClass: "edit-class-settings-container",
    };

    this.modalOptions.data = {
      targetEvent: targetEvent,
      teamID: this.teamID,
      action: "update",
    };

    this.scheduleFrame = this.modalService.show(
      EventSettingsModalComponent,
      this.modalOptions
    );
  }

  public openMetaModal(metaType, targetEvent, action) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "settings-class-container",
    };
    this.modalOptions.data = {
      metaType: metaType,
      targetEvent: targetEvent,
      teamID: this.teamID,
      action: action,
    };

    this.scheduleFrame = this.modalService.show(
      ExperienceMetaComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((incoming) => {
      if (incoming.action !== undefined) {
        if (incoming.action === "add" || "edit") {
          this.events.forEach((thisEvent) => {
            if (thisEvent.id === targetEvent.id)
              thisEvent.building = incoming.value;
          });
        }
      }
    });
  }

  public openEditClassRoomModal(targetEvent, index: number) {
    this.modalOptions.data = {
      teamID: this.teamID,
      event: targetEvent,
    };

    this.modalOptions.class = "modal-dialog";

    this.scheduleFrame = this.modalService.show(
      EditClassRoomComponent,
      this.modalOptions
    );

    this.scheduleFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            this.events[index].rooms = [changedData.rooms];
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }
}
