import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { ViewPropModalComponent } from "./modals/view-prop-modal/view-prop-modal.component";
import { AddPropOptionModalComponent } from "./modals/add-prop-option-modal/add-prop-option-modal.component";
import { ArchivePropModalComponent } from "./modals/archive-prop-modal/archive-prop-modal.component";
import { AddSettingModalComponent } from "./modals/add-setting-modal/add-setting-modal.component";

@Component({
  selector: "app-universal-settings",
  templateUrl: "./universal-settings.component.html",
  styleUrls: ["./universal-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UniversalSettingsComponent implements OnInit {
  public token: string;
  public user: any;

  public eventPropsLoading: boolean = true;
  public eventPropOptionsLoading: boolean = true;

  public eventPropsSearchText: string = "";

  public eventProps: any = [];
  public eventPropOptions: any = [];

  public teamID: number;
  public copy = "";

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _clientManagement: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private route: ActivatedRoute,
    private modalService: MDBModalService,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.retrieveUser();
    this.retrieveToken();
    this.retrieveEventProps();
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveEventProps() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/props",
      "get",
      {},
      options
    );

    eventProps.subscribe(
      (response) => {
        ;
        this.eventProps = response.props;
        this.eventPropsLoading = false;
      },
      (error) => {}
    );
  }

  public openAddEventPropsModal(type, index, prop) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "add-setting-container",
    };
    this.modalOptions.data = {
      team_id: this.teamID,
      type: type,
      targetProp: prop,
    };

    this.modalFrame = this.modalService.show(
      AddSettingModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.eventProps.push(changedData.prop);
            break;
          case "update":
            this.eventProps[index] = changedData.prop;
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openViewEventPropsModal(prop) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "view-prop-container",
    };
    this.modalOptions.data = {
      team_id: this.teamID,
      prop: prop,
    };

    ;

    this.modalFrame = this.modalService.show(
      ViewPropModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.eventProps.push(changedData.prop);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openArchivePropsModal(prop, index, type) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "archive-prop-container",
    };
    ;
    this.modalOptions.data = {
      teamID: this.teamID,
      prop: prop,
      type: type,
    };

    this.modalFrame = this.modalService.show(
      ArchivePropModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.eventProps.push(changedData.prop);
            break;
          case "update":
            break;
          case "delete":
            ;
            
            let updatedProps = this.eventProps.filter((prop) => {
              return prop.id !== changedData.prop.id;
            })

            this.eventProps = updatedProps;

            break;
        }
      } else {
      }
    });
  }
}
