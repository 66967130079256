import { Component, OnInit } from "@angular/core";
import { CoolLocalStorage } from "@angular-cool/storage";

import { SettingsService } from "src/app/services/utilities/settings.service";
@Component({
  selector: "app-metrics-landing",
  templateUrl: "./metrics-landing.component.html",
  styleUrls: ["./metrics-landing.component.scss"],
})
export class MetricsLandingComponent implements OnInit {
  public token: string;
  public teamID: number;
  public clientCode: string;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.retrieveTeamID();
    this.retrieveToken();
    this.retrieveClientCode();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }
}
