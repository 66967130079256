import { CoolLocalStorage } from "@angular-cool/storage";
import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { DateFormatPipe } from "ngx-moment";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-add-setting-modal",
  templateUrl: "./add-setting-modal.component.html",
  styleUrls: ["./add-setting-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddSettingModalComponent implements OnInit {
  //incoming
  public teamID: number;
  public type: string;
  public targetProp: any;

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another Prop",
    retry: "Retry",
  };

  private preSelected: {
    label: string;
    name: string;
    description: string;
    type: string;
    subtype: string;
    constrained: boolean;
    constrainedValue: string;
    order: number;
  };

  public eventPropForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public continueType: string;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Schedule a Class for the experience:",
  };
  public formState: string = "active";

  public propsTypes: any = [
    { label: "Asset", value: "asset" },
    { label: "Boolean", value: "boolean" },
    { label: "Multi Select List", value: "multi_select_list" },
    { label: "Number", value: "number" },
    { label: "Select List", value: "select_list" },
    { label: "Text", value: "string" },
    { label: "Add Media Button", value: "add_media_button" },
  ];

  public token: string;

  public constrained: boolean = false;

  private outgoing: Subject<any> = new Subject();

  public assetSelect = [
    { label: "Image", value: "image" },
    { label: "PDF", value: "pdf" },
    { label: "Video", value: "video" },
  ];

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    public eventPropFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    ;
    this.continueType = "reset";

    if (this.type === "Add") {
      this.continueType = "reset";
    } else if (this.type === "Edit") {
      ;
      this.btnLabel.retry = "Continue Editing";
      this.continueType = "continue";
    }

    this.retrieveTeamID();

    this.retrieveToken();

    this.buildPreselected();
    this.makeValidatingForm();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private buildPreselected() {
    if (!this.targetProp) {
      this.preSelected = {
        label: "",
        name: "",
        description: "",
        type: "",
        subtype: "",
        constrained: false,
        constrainedValue: "",
        order: 0,
      };
    } else {
      this.preSelected = {
        label: this.targetProp.label,
        name: this.targetProp.name,
        description: this.targetProp.description,
        type: this.targetProp.type,
        subtype: this.targetProp.subtype,
        constrained: this.targetProp.constrained,
        constrainedValue: this.targetProp.constrained_value,
        order: this.targetProp.order,
      };
    }
  }

  private makeValidatingForm() {
    this.eventPropForm = new UntypedFormGroup({
      label: new UntypedFormControl(this.preSelected.label),
      name: new UntypedFormControl(this.preSelected.name),
      description: new UntypedFormControl(this.preSelected.description),
      type: new UntypedFormControl(this.preSelected.type),
      subtype: new UntypedFormControl(this.preSelected.subtype),
      order: new UntypedFormControl(this.preSelected.order),
      constrained: new UntypedFormControl(this.preSelected.constrained),
      constrainedValue: new UntypedFormControl(
        this.preSelected.constrainedValue
      ),
    });
  }

  private formatTypes(types) {
    ;
    let eventTypes = [];
    for (let key in types.options) {
      if (types.options[key].is_default) {
        eventTypes.push({
          label: types.options[key].label,
          value: types.options[key].value,
        });
      }
    }

    return eventTypes;
  }

  public addEventProps() {
    let link = "/schedule/prop";
    let requestType = "post";
    let returnMessage = "added";
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Adding Event Prop</span>";
    let action = "add";
    if (this.type === "Add") {
      this.continueType = "reset";
    } else if (this.type === "Edit") {
      link = link + "/" + this.targetProp.id + "/update";
      requestType = "put";
      this.msgs.processingMsg =
        "<span class='processing-msg'>Editting Event Prop</span>";
      returnMessage = "editted";
      action = "update";
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    ;

    const data = {
      label: this.eventPropForm.value.label,
      name: this.eventPropForm.value.name,
      description: this.eventPropForm.value.description,
      type: this.eventPropForm.value.type,
      subtype: this.eventPropForm.value.subtype,
      constrained: this.eventPropForm.value.constrained,
      constrained_value: this.eventPropForm.value.constrainedValue,
      order: this.eventPropForm.value.order,
    };

    let addedEventProp = this._xrPlatformRestService.restfulAPIQuery(
      link,
      requestType,
      data,
      getOptions
    );

    addedEventProp.subscribe(
      (response) => {
        ;
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        if (response.error !== 418) {
          this.msgs.statusMsg = "Event Prop successfully " + returnMessage;
          let outgoingData = {
            action: action,
            prop: response.props,
          };
          this.outgoing.next(outgoingData);
        } else {
          this.msgs.processingMsg = "";
          this.btnLabel.retry = "Retry";
          this.msgs.errorMsg = response.status;
        }
      },
      (error) => {
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public selectedType(incoming) {
    let constrained = ["select_list", "multi_select_list"];

    if (constrained.indexOf(incoming.value) !== -1) {
      this.eventPropForm.controls.constrained.setValue(true);
    } else {
      this.eventPropForm.controls.constrained.setValue(false);
    }
  }

  public updateConstained() {
    ;
  }

  public resetForm() {
    this.eventPropForm.reset();
    this.eventPropForm.value.constrained = false;
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.eventPropFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
