import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { ClientManagementService } from 'src/app/services/utilities/client-management.service';
import { SettingsService } from 'src/app/services/utilities/settings.service';

@Component({
  selector: 'app-edit-prop-modal',
  templateUrl: './edit-prop-modal.component.html',
  styleUrls: ['./edit-prop-modal.component.scss']
})
export class EditPropModalComponent implements OnInit {

  public prop: any;

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another Class",
    retry: "Retry",
  };

  public eventPropForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Schedule a Class for the experience:",
  };

  public formState: string = "active";

  public token: string;

  public constrained: boolean = false;

  private outgoing: Subject<any> = new Subject();

  public assetSelect = [{label: 'Image', value: 'image'},
                        {label: 'PDF', value: 'pdf'},
                        {label: 'Video', value: 'video'},]

  constructor(private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    public eventPropEditFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private _settingsService: SettingsService) { }

  ngOnInit(): void {
    let clientSettings = this._settingsService.getSettingsFromStorage(
      1
    );

    this.eventPropForm = new UntypedFormGroup({
      label: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      type: new UntypedFormControl(''),
      subtype: new UntypedFormControl(''),
      constrained: new UntypedFormControl(false),
      constrainedType: new UntypedFormControl(''),
    })

    this.retrieveToken();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private formatTypes(types) {
    
    let eventTypes = []
    for(let key in types.options) {
      if(types.options[key].is_default) {
        eventTypes.push({label: types.options[key].label, value: types.options[key].value})
      }
    }

    return eventTypes;
  }

}
