import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { CustomToastComponent } from "src/app/modules/persistent/custom-toast/custom-toast.component";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  sharedDataObserver = new Subject();

  notificationOptions = {
    toastComponent: CustomToastComponent,
    closeButton: true,
    newestOnTop: true,
    timeOut: 7000,
    extendedTimeOut: 50000,
    positionClass: "toast-top-center",
    toastClass: "ngx-toastr theme-base-status-info success",
  };

  constructor(private toastr: ToastrService) { }

  successNotification(message, timeout = 7000) {
    this.notificationOptions.toastClass =
      "ngx-toastr theme-base-modal-medium-light success";

    this.notificationOptions.timeOut = timeout;
    return this.toastr.success(message, "", this.notificationOptions);
  }

  savingNotification(message) {
    this.notificationOptions.toastClass =
      "ngx-toastr theme-base-modal-medium-light saving";

    this.notificationOptions.timeOut = 20000;
    this.notificationOptions.extendedTimeOut = 20000;

    return this.toastr.info(message, "", this.notificationOptions);
  }

  warningNotification(message) {
    this.notificationOptions.toastClass =
      "ngx-toastr theme-base-warning warning";
    return this.toastr.warning(message, "", this.notificationOptions);
  }

  errorNotification(message, timeout = 2000, extendedTimeout = 5000) {
    this.notificationOptions.toastClass =
      "ngx-toastr theme-base-status-error error";

    this.notificationOptions.timeOut = timeout;
    this.notificationOptions.extendedTimeOut = extendedTimeout;

    return this.toastr.error(message, "", this.notificationOptions);
  }

  clearNotification(notification) {
    this.toastr.clear(notification.toastId);
  }

  clearAllNotifications() {
    this.toastr.clear();
  }
}
