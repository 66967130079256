import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mediaSize",
})
export class MediaSizePipe implements PipeTransform {
  transform(value: number, target: string): unknown {
    switch (target) {
      case "mb":
        value = Math.round(value * 0.00000095367432 * 100) / 100;
        break;
    }

    return value;
  }
}
