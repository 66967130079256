import {
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";

import "@google/model-viewer";

import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-wc-model-viewer",
  templateUrl: "./wc-model-viewer.component.html",
  styleUrls: ["./wc-model-viewer.component.scss"],
})
export class WcModelViewerComponent implements OnInit, OnChanges {
  @Input("modelSrc") modelSrc: string;
  @Input("action") action: string;
  @Input("uploadID") uploadID: string;
  @Input("changes") changes: string;
  @ViewChild("modelViewer") modelViewer: ElementRef;
  @Output() generatedThumbnail = new EventEmitter();
  @Output() modelLoadedEvent = new EventEmitter();
  public modelLoaded: boolean = false;
  public thumbnailSource: string = "";
  public teamID: number;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService
  ) { }

  ngOnInit(): void {
    console.log("this.modelSrc", this.modelSrc);
    console.log("this.action", this.action);

    if (this.uploadID === undefined) this.uploadID = "";

    this.retrieveTeamID();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  ngOnChanges(): void {
    console.log("this.modelSrc", this.modelSrc);

    setTimeout(() => {
      if (this.modelViewer !== undefined) {
        console.log(
          "modelViewer in WcModelViewerComponent view init",
          this.modelViewer
        );

        this.modelViewer.nativeElement.addEventListener("load", (event) => {
          this.updatemodelLoaded(true, event);
        });

        this.modelViewer.nativeElement.addEventListener(
          "model-visibility",
          (event) => {
            this.updatemodelLoaded(true, event);
            this.modelLoadedEvent.emit(true);
          }
        );
      }
    }, 10);
  }

  public updatemodelLoaded(state, event) {
    console.log("state in updatemodelLoaded", state);
    console.log("event in updatemodelLoaded", event);
    this.modelLoaded = state;
    if (
      state === true &&
      event.detail !== undefined &&
      (event.detail.visible !== undefined || event.detail.url !== undefined) &&
      this.action === "upload"
    )
      this.generateThumbnail();
  }

  public generateThumbnail() {
    setTimeout(() => {
      this.modelViewer.nativeElement
        .toBlob({
          mimeType: "image/png",
          qualityArgument: 0.92,
          idealAspect: true,
        })
        .then((res) => {

          res.uploadID = this.uploadID;

          console.log("res in toBlob", res);
          // create an ObjectURL from Blob and
          this.generatedThumbnail.emit(res);
        });
    }, 500);
  }
}
