<div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">Manage {{ survey.label }}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" mdbTooltip="close" placement="bottom"
        (click)="viewSurveyModal.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body m-3 p-0">
    <div class="row">
        <div class="col-4">
            <p class="card-text">Survey: {{ survey.name }}</p>
        </div>
        <div class="col-8">
            <div class="btn-spacing text-right">
                <button class="theme-modal-button btn" type="button" (click)="openAddSurveyQuestionModal(survey)"
                    mdbBtnp mdbWavesEffect>
                    <mdb-icon far icon="plus-square" class="pr-1"></mdb-icon>
                    Add Question to Survey
                </button>
            </div>
        </div>
    </div>
    <div class="row container-gallery container-overflow justify-content-start">
        <div class="col-12">
            <div class="card-group">
                <div *ngFor="let question of survey.questions; let i = index">
                    <mdb-card>
                        <mdb-card-body>
                            <mdb-card-title>
                                <div class="row justify-content-between">
                                    <h5 class="mb-3">{{ question.label }}</h5>
                                </div>
                            </mdb-card-title>
                            <mdb-card-text class="mb-0">
                                <div class="d-flex flex-row">
                                    <ul class="list-unstyled">
                                        <li>
                                            Name: <b>{{ question.name }}</b>
                                        </li>
                                        <li>
                                            type <b>{{ question.type }}</b>
                                        </li>
                                    </ul>
                                </div>
                            </mdb-card-text>
                        </mdb-card-body>
                        <mdb-card-footer> </mdb-card-footer>
                    </mdb-card>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal-footer">
    <button mdbBtn mdbWavesEffect type="button" size="md" color="link" data-dismiss="modal"
        (click)="viewSurveyModal.hide()">
        close
    </button>
</div>