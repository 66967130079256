import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { MediaManagementServicesService } from './media-management-services.service';

import { TitleCasePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class MediaServicesV3Service {
  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _mediaManagementService: MediaManagementServicesService,
    private TitleCase: TitleCasePipe
  ) {}

  showWarning: boolean;
  token: string;
  attachedProfilePictures: any;
  eventsAttachedTo: any;
  eventsAttachedToAsMeta: any;
  targetMedia: any;
  eventsAttachedToAsProps: any;

  //data back to the parent
  public outgoing: Subject<any> = new Subject();
  public statusMsg: Subject<any> = new Subject();

  public async retrieveScheduledEvents(targetMedia, token) {
    this.token = token;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let retrieveScheduledEvents = await this._xrPlatformRestService
      .restfulAPIQuery(
        "/asset/" + targetMedia.id + "/attached",
        "get",
        {},
        options
      )
      .toPromise();

    let retrieveMedia;
    let eventsAttachedTo;

    /** only continue if there are events where @var targetMedia is attached as an invitee */
    if (
      retrieveScheduledEvents.events !== undefined &&
      retrieveScheduledEvents.events.length
    ) {
      /** because we're using '/schedule/filtered', we know the incoming events represent events that this media is attached to, so we assign those incoming events to the component @var eventsAttachedTo */
      eventsAttachedTo = retrieveScheduledEvents.events;

      //trigger warnin
      this.showWarning = true;

      /** Next we are going to retrieve this media item, just to make sure we have the very latest media object data */
      retrieveMedia = await this.retrieveMedia(targetMedia, token);
    } else {
      /** if there are no events where @var targetMedia is attached as an invitee, we continue to @method retrieveMedia() */

      retrieveMedia = await this.retrieveMedia(targetMedia, token);
    }

    let outbound = {
      eventsAttachedToAsMeta: retrieveMedia.eventsAttachedToAsMeta,
      attachedProfilePictures: retrieveMedia.attachedProfilePictures,
      eventsAttachedToAsProps: retrieveMedia.eventsAttachedToAsProps,
      showWarning: this.showWarning,
      eventsAttachedTo: eventsAttachedTo,
    };

    ;

    return Promise.resolve(outbound);
  }

  private async retrieveMedia(targetMedia, token) {
    let eventsAttachedToAsMeta;
    let attachedProfilePictures;
    let eventsAttachedToAsProps;

    /** Configure request to retrieve media object via media UUID */
    let getMediaItem = await this._mediaManagementService
      .retrieveMediaItem(targetMedia.uuid, token)
      .toPromise();
    let getEventsAttachedItem = await this._mediaManagementService
      .retrieveEventsAttachedAsProps(targetMedia.id, token)
      .toPromise();

    /** Process media request response */

    /** Assign incoming response to local @var mediaItem */
    let mediaItem = getMediaItem;

    ;

    /** Check firs to see if media @prop meta exists and is not null */
    if (mediaItem.meta !== undefined && mediaItem.meta !== null) {
      /** If the meta contains the array @prop attached_events, and this array has elements, we know this media is added to an event or events via the event settings */
      if (
        mediaItem.meta.attached_events !== undefined &&
        mediaItem.meta.attached_events.length
      ) {
        /** Media added to events via event settings is assigned to component @var eventsAttachedToAsMeta */
        eventsAttachedToAsMeta = mediaItem.meta.attached_events;

        //need the event name for each attached event
        await eventsAttachedToAsMeta.forEach(async (attachedEvent) => {
          if (attachedEvent.name === undefined) {
            let thisAttachedEvent = {
              id: attachedEvent.id,
            };

            let retrieveAttachedEvent = await this.retrieveEventMeta(
              thisAttachedEvent
            );

            if (retrieveAttachedEvent.scheduled_event !== undefined) {
              attachedEvent.name =
                retrieveAttachedEvent.scheduled_event.event_name;
            }
          }
        });

        //trigger warning
        this.showWarning = true;
      }

      /** If the meta contains the array @prop user_profile_pictures, and this array has elements, we know this media is added to a user account or user accounts as a profile picture*/
      if (
        mediaItem.meta.user_profile_pictures !== undefined &&
        mediaItem.meta.user_profile_pictures.length
      ) {
        /** Media added to user accounts as a profile picture is assigned to component @var attachedProfilePictures */
        attachedProfilePictures = mediaItem.meta.user_profile_pictures;

        //trigger warning
        this.showWarning = true;
      }
    }
    if (
      getEventsAttachedItem.attachments &&
      getEventsAttachedItem.attachments.length > 0
    ) {
      eventsAttachedToAsProps = getEventsAttachedItem.attachments;
      this.showWarning = true;
    }

    let outbound = {
      eventsAttachedToAsMeta: eventsAttachedToAsMeta,
      attachedProfilePictures: attachedProfilePictures,
      eventsAttachedToAsProps: eventsAttachedToAsProps,
    };
    return Promise.resolve(outbound);
  }

  public async manageMedia(
    targetMedia,
    showWarning,
    token,
    attachedProfilePictures,
    eventsAttachedTo,
    eventsAttachedToAsMeta,
    eventsAttachedToAsProps
  ): Promise<{ status: string; message: string; response: any }>  {
    this.token = token;
    this.attachedProfilePictures = attachedProfilePictures;
    this.eventsAttachedTo = eventsAttachedTo;
    this.eventsAttachedToAsMeta = eventsAttachedToAsMeta;
    this.targetMedia = targetMedia;
    this.eventsAttachedToAsProps = eventsAttachedToAsProps;

    const statusMsgObj = {
      errorMsg: "",
      formState: "",
      statusMsg: "",
      statusType: "manage_media"
    };

    statusMsgObj.formState = "processing";
    statusMsgObj.statusMsg = "Updating media...";

    this.statusMsg.next(statusMsgObj);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._mediaManagementService.retrieveToken(),
    };

    const getOptions = {
      headers: headers,
    };

    statusMsgObj.statusMsg = "Deleting media...";
    this.statusMsg.next(statusMsgObj);

    //configure meta to clear
    let body = {
      attached_events: [],
      user_profile_pictures: [],
    };

    let removeMeta = await this._mediaManagementService
      .manageMedia(this.targetMedia.uuid, this.token, "update-meta", body)
      .toPromise();

    let manageMedia = await this._xrPlatformRestService
      .deleteAsset(targetMedia.uuid, "half", getOptions)
      .toPromise();

    statusMsgObj.statusMsg = "Media successfully updated.";
    this.statusMsg.next(statusMsgObj);
    ;

    if (showWarning) {
      statusMsgObj.statusMsg = "Removing media from scheduled events...";
      this.statusMsg.next(statusMsgObj);
      let removedFromEvents = await this.removeMediaFromScheduledEvents();
      let removedFromAttachments = await this.removeAttachedMedia();
    }

    return this.successfulDelete(manageMedia);
  }

  private async removeMediaFromScheduledEvents() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    if (this.eventsAttachedTo.length) {
      await Promise.all(
        this.eventsAttachedTo.map(async (event) => {
          const response = await this.updateEvent(event, options);
          ;
        })
      );
    }

    return "success";
  }
  private async removeMediaFromEventProps() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    if (this.eventsAttachedToAsProps.length) {
      await Promise.all(
        this.eventsAttachedToAsProps.map(async (event) => {
          event.props.map(async (prop) => {
            const response = await this.removeMediaFromProps(
              event,
              prop,
              options
            );
          });
        })
      );
    }

    return "success";
  }

  private removeMediaFromProps(event, prop, options) {
    let body = {
      eventId: event.event.id,
      propId: prop.id,
    };

    let removeMedia = this._xrPlatformRestService.scheduledExperiences(
      "remove-prop",
      body,
      options
    );

    return removeMedia.toPromise();
  }

  private updateEvent(event, options) {
    let body = {
      assets: [this.targetMedia.id],
    };

    let removeAssets = this._xrPlatformRestService.restfulAPIQuery(
      "/event/" + event.id + "/assets/remove",
      "put",
      body,
      options
    );

    return removeAssets.toPromise();
  }

  private async removeAttachedMedia() {
    if (this.eventsAttachedToAsMeta.length) {
      (async () => {
        for (const event of this.eventsAttachedToAsMeta) {
          ;
          await this.handleAttachedEvent(event);
          ;
        }
      })().then(() => {
        this.removeUserProfilePictures();
      });
    } else {
      this.removeUserProfilePictures();
    }
  }

  private handleAttachedEvent(event) {
    return new Promise(async (resolve) => {
      await this.retrieveEventMeta(event).then((response) => {
        ;
        this.removeAttached(response, event).then((response) => {
          ;
          resolve(true);
        });
      });
    });
  }

  private async retrieveEventMeta(event) {
    ;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    ;

    let retrieveExperience = this._xrPlatformRestService.retrieveEntityData(
      "schedule",
      event.id,
      options
    );

    return retrieveExperience.toPromise();
  }

  private removeAttached(response, event) {
    ;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    return new Promise((resolve) => {
      if (
        response.scheduled_event === undefined ||
        response.scheduled_event.meta === undefined ||
        response.scheduled_event.meta === null ||
        response.scheduled_event.meta[event.key] === undefined
      )
        resolve(false);

      let eventResponse = response.scheduled_event;

      ;

      eventResponse.meta[event.key] = null;

      let body = {
        meta: eventResponse.meta,
      };

      let updateExperienceMeta =
        this._xrPlatformRestService.scheduledExperiences(
          event.id,
          body,
          options
        );

      updateExperienceMeta.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  private async removeUserProfilePictures() {
    console.log(
      "this.attachedProfilePictures in removeUserProfilePictures",
      this.attachedProfilePictures
    );

    if (this.attachedProfilePictures.length) {
      (async () => {
        for (const user of this.attachedProfilePictures) {
          ;
          await this.handleUserMeta(user);
          ;
        }
      })();

      this.removeMediaFromEventProps();
    } else {
      this.removeMediaFromEventProps();
    }
  }

  private async handleUserMeta(user) {
    return new Promise(async (resolve) => {
      await this.retrieveUserMeta(user).then((response) => {
        ;
        this.updateUser(response).then((response) => {
          ;
          resolve(true);
        });
      });
    });
  }

  private retrieveUserMeta(user) {
    ;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    //first retrieve user meta
    let retrieveUser = this._xrPlatformRestService.retrieveEntityData(
      "user",
      user.id,
      options
    );

    return retrieveUser.toPromise();
  }

  private updateUser(response) {
    ;

    return new Promise((resolve) => {
      if (
        response.user === undefined ||
        response.user.meta === undefined ||
        response.user.meta === null
      )
        resolve(false);

      delete response.user.meta.profilePicture;

      let formValues = {
        meta: response.user.meta,
      };

      const options = JSON.stringify(formValues);

      ;

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      const httpOptions = {
        headers: headers,
      };

      let updateUser = this._xrPlatformRestService.manageUsers(
        response.user.id,
        "update-by-admin",
        options,
        httpOptions
      );

      updateUser.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  private successfulDelete(response) {
    let outbound = {
      status: "success",
      message: "Media successfully deleted",
      response: response,
    };

    return Promise.resolve(outbound);
  }

  public getFilterSettings(token, teamId) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(
      "/assets/team/" + teamId + "/settings",
      "get",
      {},
      options
    );
  }

  public async retrieveAllMedia(token, userId, teamId, reqObj) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const getOptions = {
      headers: headers,
    };
    let retrieveMedia = await this._xrPlatformRestService
      .restfulAPIQuery(
        `/team/${teamId}/assets/filtered`,
        "post",
        reqObj,
        getOptions
      )
      .toPromise();
    return this.processMedia(retrieveMedia, userId);
  }

  private processMedia(incoming, userId) {
    let media = [];
    let my_media = [];
    let shared_media = [];

    media = incoming.assets.filter((asset) => {
      return !asset.is_preview_image;
    });

    media.forEach((thisMedia) => {
      thisMedia.loading = "loaded";

      thisMedia.num_of_shares = thisMedia.shared_with.length;
      thisMedia.shared_with.forEach((share) => {
        if (share.user_id == userId && share.is_owner == false) {
          shared_media.push(thisMedia);
        }
        if (share.is_owner == true) {
          thisMedia.current_owner = share.user_id;
          thisMedia.owner_username = share.username;
        }
        if (share.is_owner && share.user_id == userId) {
          my_media.push(thisMedia);
        }
      });
    });
    const allMediaResult = {
      media: media,
      totalPages: incoming.total_pages,
    };
    return {
      my_media: my_media,
      shared_media: shared_media,
      media: allMediaResult,
    };
  }

  public retrieveFilteredMedia(token, user_id, team_id, filters) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const getOptions = {
      headers: headers,
    };
    let retrieveMedia = this._xrPlatformRestService.restfulAPIQuery(
      `/team/${team_id}/assets/filtered`,
      "post",
      filters,
      getOptions
    );

    retrieveMedia.subscribe(
      (response) => {
        return response;
      },
      (error) => {}
    );
  }

  public async retrieveLockerMedia(
    token,
    userID,
    labels
  ): Promise<{ status: string; message: string; response: any }> {
    let attached = [];

    let outbound = {
      status: "",
      message: "",
      response: {},
    };

    let resolution = await this.retrieveLocker(token, userID).catch ((error) => {
      outbound = {
        status: "error",
        message: `Problem retrieving ${labels.mediaLocker.singular}`,
        response: error,
      };

      return Promise.resolve(outbound);
    });

    ;

    resolution.locker.forEach((element) => {
      attached.push(element.asset);
    });

    outbound = {
      status: "success",
      message: `Successfully retrieved ${labels.mediaLocker.singular}`,
      response: attached,
    };

    return Promise.resolve(outbound);
  }

  private retrieveLocker(token, userID) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const getOptions = {
      headers: headers,
    };

    let lockerRequest = this._xrPlatformRestService.restfulAPIQuery(
      "/asset/user_assets/" + userID + "/locker/backpack",
      "get",
      {},
      getOptions
    );

    return lockerRequest.toPromise();
  }

  private checkIfInLocker(media_id, attached) {
    let isLockerItem = false;
    attached.forEach((asset) => {
      if (asset.id === media_id) {
        ;
        isLockerItem = true;
      }
    });
    return isLockerItem;
  }

  private processLockerMedia(incomingValues, attached){
    
    
    let outbound = {
      "assets": [],
      "removals": []
    }

    incomingValues.myOptionsArray.forEach((incoming) => {
      if (!incoming.selected) {
        let retrieveThisMedia = this.checkIfInLocker(incoming.id, attached);
        if (retrieveThisMedia) {
          outbound.removals.push(incoming.id);
        }
        return false;
      }

      let retrieveThisMedia = this.checkIfInLocker(incoming.id, attached);
      if (!retrieveThisMedia) {
        outbound.assets.push({ asset_id: incoming.id, meta: {} });
      }
    });
    

    return outbound;

  }

  public async manageMediaLocker(
    token, incomingValues, attached, userID, labels
  ): Promise<{ status: string; message: string; response: any }> {
    let outbound = {
      status: "",
      message: "",
      response: {},
    };

    let responses = {
      added: "",
      removed: ""
    }

    let messages = {
      errorMsg: "",
      statusMsg: `<span class='loading-msg'>Updating ${labels.media.plural}</span>`,
      formState: "processing",
      statusType: "media_locker"
    }

    this.statusMsg.next(messages);

    let processed = this.processLockerMedia(incomingValues, attached);

    responses.added = await this.addToMediaLocker(token, userID, processed.assets).catch ((error) => {
      outbound = {
        status: "error",
        message: `Problem adding media to ${labels.mediaLocker.singular}`,
        response: error,
      };

      messages.statusMsg = "";
      messages.formState = "active";
      messages.errorMsg = outbound.message;

      this.statusMsg.next(messages);

      return Promise.resolve(outbound);
    });

    responses.removed = await this.removeFromMediaLocker(token, userID, processed.removals).catch ((error) => {
      outbound = {
        status: "error",
        message: `Problem removing media from ${labels.mediaLocker.singular}`,
        response: error,
      };

      messages.statusMsg = "";
      messages.formState = "active";
      messages.errorMsg = outbound.message;

      this.statusMsg.next(messages);

      return Promise.resolve(outbound);
    });

    messages.errorMsg = "";
    messages.formState = "active";
    messages.statusMsg = `${this.TitleCase.transform(labels.mediaLocker.singular)} updated. Click Close to exit.`;

    this.statusMsg.next(messages);

    outbound.response = responses;
    outbound.status = "success";

    return Promise.resolve(outbound);
  }

  private addToMediaLocker(token, userID, assets){
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let addToBackPack = this._xrPlatformRestService.restfulAPIQuery(
      "/asset/" + userID + "/locker/backpack",
      "post",
      { assets: assets },
      options
    );

    return addToBackPack.toPromise();
  }

  private removeFromMediaLocker(token, userID, removals){
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
    };

    let removeFromBackPack = this._xrPlatformRestService.restfulAPIQuery(
      "/user/" + userID + "/locker/remove",
      "put",
      { assets: removals },
      options
    );

    return removeFromBackPack.toPromise();
  }
}
