import { CoolLocalStorage } from '@angular-cool/storage';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { ClientManagementService } from 'src/app/services/utilities/client-management.service';
import { AddSurveyQuestionModalComponent } from './modals/add-survey-question-modal/add-survey-question-modal.component';
import { ArchiveVendorEntityComponent } from './modals/archive-vendor-entity-modal/archive-vendor-entity-modal.component';
import { ManageQuestionModalComponent } from './modals/manage-question-modal/manage-question-modal.component';
import { ManageSurveyComponent } from './modals/manage-survey/manage-survey.component';
import { ViewQuestionModalComponent } from './modals/view-question-modal/view-question-modal.component';
import { ViewSurveyComponent } from './modals/view-survey/view-survey.component';

@Component({
  selector: 'app-vendor-management',
  templateUrl: './vendor-management.component.html',
  styleUrls: ['./vendor-management.component.scss']
})
export class VendorManagementComponent implements OnInit {
  public token: string;
  public user: any;
  public loading: boolean = true;
  public teamID: number;
  public surveys: any = [];
  public copy =
    "Manage the general settings for this client instance. Super admin only.";

  public questions: any = [];
  public questionsLoading: boolean = true;

  public options: any = [];
  public optionsLoading: boolean = true;

  public searchText:string = '';
  public searchQuestionText: string = '';
  public searchOptionText: string = '';

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-add-room',
    containerClass: '',
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _clientManagement: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private route: ActivatedRoute,
    private modalService: MDBModalService,) { }

  ngOnInit(): void {
    this.retrieveTeamID();
    this.retrieveUser();
    this.retrieveSurveys();
    this.retieveQuestions();
  }

  private retrieveTeamID(){
    this.teamID = JSON.parse(this.coolLocalStorage.getItem("admin_panel_team_id"));
  } 

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveSurveys()
  { 
    let retrieveSurveys = this._xrPlatformRestService.userDataHandler(
      "/surveys/" + this.teamID + "/team/apb",
      'get',
      {}
    );

    retrieveSurveys.subscribe((response) => {
      this.surveys = response.surveys
      
      
      this.loading = false;
    },
    (error) => {
      
    })
  }

  private retieveQuestions()
  { 
    let retrieveQuestions = this._xrPlatformRestService.userDataHandler(
      "/questions",
      'get',
      {}
    );

    retrieveQuestions.subscribe((response) => {
      
      this.questions = response.questions
      this.questionsLoading = false;
    },
    (error) => {
      
    })
  }

  private retrieveOptions()
  { 
    let retrieveQuestions = this._xrPlatformRestService.userDataHandler(
      "/questions",
      'get',
      {}
    );

    retrieveQuestions.subscribe((response) => {
      this.surveys = response.questions
      
      
      this.loading = false;
    },
    (error) => {
      
    })
  }

  public openManageSurveyModal(type: string, survey: any, index: number)
  {
    this.modalOptions.data = {
      type: type,
      survey: survey,
      teamID: this.teamID
    };

    this.modalOptions.class = ''
    this.modalFrame = this.modalService.show(
      ManageSurveyComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.surveys.push(changedData.survey)
            break;
          case "update":
            this.surveys[index] = changedData.survey
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }

  public openManageQuestionModal(type: string, question: any, index: number)
  {
    this.modalOptions.data = {
      type: type,
      question: question,
      teamID: this.teamID
    };

    this.modalOptions.class = ''
    this.modalFrame = this.modalService.show(
      ManageQuestionModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.questions.push(changedData.question)
            break;
          case "update":
            this.questions[index] = changedData.question
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }

  public openViewSurveyModal(survey: any, index: number)
  {
    this.modalOptions.data = {
      survey: survey
    };

    this.modalOptions.class = 'modal-dialog-centered modal-lg view-experience-modal'
    this.modalFrame = this.modalService.show(
      ViewSurveyComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.surveys.push(changedData.survey)
            break;
          case "update":
            this.surveys[index] = changedData.survey
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }

  public openViewQuestionModal(question: any, index: number)
  {
    this.modalOptions.data = {
      question: question
    };

    this.modalOptions.class = 'modal-dialog-centered modal-lg view-experience-modal'
    this.modalFrame = this.modalService.show(
      ViewQuestionModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.surveys.push(changedData.survey)
            break;
          case "update":
            this.surveys[index] = changedData.survey
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }

  public openAddSurveyQuestionModal(survey: any, index: number)
  {
    this.modalOptions.data = {
      survey: survey
    };

    this.modalOptions.class = ''
    this.modalFrame = this.modalService.show(
      AddSurveyQuestionModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.surveys.push(changedData.survey)
            break;
          case "update":
            this.surveys[index] = changedData.survey
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }

  public openArchiveModal(type: string, entity: any, index: number)
  {
    this.modalOptions.data = {
      type: type,
      entity: entity
    };

    this.modalOptions.class = ''
    this.modalFrame = this.modalService.show(
      ArchiveVendorEntityComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.surveys.push(changedData.survey)
            break;
          case "update":
            
            
            if(type == 'survey') {
              this.surveys.splice(index, 1)
            }
            else if(type == 'question') {
              this.questions.splice(index, 1)
            }
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }
}
