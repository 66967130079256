<div class="password-reset-wrapper">
  <app-menu *ngIf="teamID === 0" [token]="token"></app-menu>
  <div class="mt-5" *ngIf="!showContent">
    <p>Loading...</p>
  </div>
  <div class="mt-5" *ngIf="showContent">
    <div class="password-form" *ngIf="isToken && !formLoading">
      <mdb-card class="w-50 mx-auto">
        <mdb-card-body>
          <mdb-card-title>
            <h2>Please enter and confirm your new password.</h2>
          </mdb-card-title>
          <div
            *ngIf="errorMsg !== ''"
            class="alert alert-danger text-capitalize"
            role="alert"
            [innerHTML]="errorMsg"
          ></div>
          <div
            *ngIf="statusMsg !== ''"
            class="alert alert-info text-capitalize"
            role="alert"
            [innerHTML]="statusMsg"
          ></div>
          <form
            [formGroup]="pwForm"
            class="text-center border border-light p-5"
          >
            <!--password-->
            <div class="md-form mb-5">
              <input
                type="password"
                id="pwform-password"
                class="form-control"
                formControlName="password"
                mdbInput
                mdbValidate
              />
              <label for="pwform-password">Password</label>
              <mdb-error
                *ngIf="
                  password.invalid &&
                  !password.errors.minlength &&
                  (password.dirty || password.touched)
                "
                >This field is required
              </mdb-error>
              <mdb-error *ngIf="password.invalid && password.errors.minlength">
                Password must be at least 6 characters
              </mdb-error>
            </div>
            <!--end password-->

            <!--confirm password-->
            <div class="md-form mb-5">
              <input
                type="password"
                id="pwform-confirm_password"
                class="form-control"
                formControlName="confirm_password"
                mdbInput
                mdbValidate
              />
              <label for="pwform-confirm_password">Re-enter Password</label>
              <mdb-error
                *ngIf="
                  confirm_password.invalid &&
                  !confirm_password.errors.minlength &&
                  !confirm_password.errors.mustMatch &&
                  (confirm_password.dirty || confirm_password.touched)
                "
                >This field is required
              </mdb-error>
              <mdb-error
                *ngIf="
                  confirm_password.invalid &&
                  confirm_password.errors.minlength &&
                  !confirm_password.errors.mustMatch
                "
              >
                Password must be at least 6 characters
              </mdb-error>
              <mdb-error
                *ngIf="
                  confirm_password.invalid &&
                  !confirm_password.errors.minlength &&
                  confirm_password.errors.mustMatch
                "
              >
                Passwords do not match
              </mdb-error>
            </div>
            <!--end confirm password-->
          </form>
        </mdb-card-body>
        <mdb-card-footer
          ><button
            mdbBtn
            class="waves-light theme-action-button"
            mdbWavesEffect
            [disabled]="!pwForm.valid || formState === 'processing'"
            (click)="managePassword()"
            id="confirm-update-password"
          >
            {{ btnLabel.main }}
          </button></mdb-card-footer
        >
      </mdb-card>
    </div>

    <div class="password-form" *ngIf="!isToken">
      <mdb-card class="w-50 mx-auto">
        <mdb-card-body>
          <mdb-card-title>
            <p *ngIf="!isValidToken">
              Invalid or expired token. Please enter your email below to resend
              the reset link.
            </p>
            <p *ngIf="isValidToken">
              Please enter your email below to send a password reset link.
            </p>
          </mdb-card-title>
          <div
            *ngIf="errorMsg !== ''"
            class="alert alert-danger text-capitalize"
            role="alert"
            [innerHTML]="errorMsg"
          ></div>
          <div
            *ngIf="statusMsg !== ''"
            class="alert alert-info text-capitalize"
            role="alert"
            [innerHTML]="statusMsg"
          ></div>
          <form
            [formGroup]="pwForm"
            class="text-center border border-light p-5"
          >
            <input
              type="email"
              id="emailform-password"
              class="form-control"
              formControlName="email"
              mdbInput
              mdbValidate
            />
            <label for="emailform-password">Email</label>
          </form>
        </mdb-card-body>
        <mdb-card-footer>
          <button
            mdbBtn
            class="waves-light theme-action-button"
            mdbWavesEffect
            [disabled]="formState === 'processing'"
            (click)="sendResetEmail()"
            id="request-password-reset"
          >
            {{ btnLabel.send }}
          </button>
        </mdb-card-footer>
      </mdb-card>
    </div>
  </div>
</div>
