import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, Subject } from "rxjs";

import * as moment from "moment";
import "moment-timezone";

import { CoolLocalStorage } from "@angular-cool/storage";
import { IMyOptions, MDBModalRef } from "ng-uikit-pro-standard";

import { startWith, map } from "rxjs/operators";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

import { faTimes, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-manage-app-versions",
  templateUrl: "./manage-app-versions.component.html",
  styleUrls: ["./manage-app-versions.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class ManageAppVersionsComponent {
  //incoming
  public teamID: number;
  public type: string;
  public targetVersion: any;
  public action: string;
  public data: any;

  private preSelected: {
    label: string;
    name: string;
    description: string;
    as_of_date: any;
    as_of_time: string;
    bundle_id: number;
    device_id: number;
    current_version: string;
  };

  public manageAppVersionForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
  };

  public formState: string = "active";
  public token: string;
  private outgoing: Subject<any> = new Subject();

  //icons
  public faTimes = faTimes;
  public faMagnifyingGlass = faMagnifyingGlass;

  //select values
  public timeSelect: { value: string; label: string }[] = [];

  //autocomplete
  public bundleResults: Observable<{ label: string }[]>;
  public deviceResults: Observable<{ label: string }[]>;

  //timezone
  public tz: any = moment.tz(moment.tz.guess()).format("z");

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public appVersionFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService,
    private TitleCase: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.timeSelect = this.buildTimeSelect();
    this.buildPreselected();
    this.makeValidatingForm();
    this.buildUpAutocompletes();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private buildPreselected() {
    if (!this.targetVersion) {
      //as_of_time calc
      let now = moment();
      let remainder = 30 - (now.minute() % 30);
      let currentAsOf = moment(now).add(remainder, "minutes").format("h:mm A");

      this.preSelected = {
        label: "",
        name: "",
        description: "",
        as_of_date: new Date(),
        as_of_time: currentAsOf,
        bundle_id: null,
        device_id: null,
        current_version: "",
      };
    } else {
      //as_of_time calc
      let start = moment(this.targetVersion.start_at);
      let remainder = 30 - (start.minute() % 30);
      let currentAsOf = moment(start)
        .add(remainder, "minutes")
        .format("h:mm A");

      //retrieve bundle and device
      let bundle = this.data.appBundles.find(
        (bundle) => bundle.id === this.targetVersion.app_bundle_id
      );
      let device = this.data.appDevices.find(
        (device) => device.id === this.targetVersion.app_device_id
      );

      this.preSelected = {
        label: this.targetVersion.label,
        name: this.targetVersion.name,
        description: this.targetVersion.description,
        as_of_date: new Date(this.targetVersion.as_of),
        as_of_time: currentAsOf,
        bundle_id: bundle,
        device_id: device,
        current_version: this.targetVersion.current_version,
      };
    }
  }

  private makeValidatingForm() {
    this.manageAppVersionForm = new UntypedFormGroup({
      label: new UntypedFormControl(
        this.preSelected.label,
        Validators.required
      ),
      name: new UntypedFormControl(this.preSelected.name, Validators.required),
      description: new UntypedFormControl(this.preSelected.description),
      as_of_date: new UntypedFormControl(
        this.preSelected.as_of_date,
        Validators.required
      ),
      as_of_time: new UntypedFormControl(
        this.preSelected.as_of_time,
        Validators.required
      ),
      bundle_id: new UntypedFormControl(
        this.preSelected.bundle_id,
        Validators.required
      ),
      device_id: new UntypedFormControl(
        this.preSelected.device_id,
        Validators.required
      ),
      current_version: new UntypedFormControl(
        this.preSelected.current_version,
        Validators.required
      ),
    });
  }

  //getters for form controls
  get label() {
    return this.manageAppVersionForm.controls.label;
  }

  get name() {
    return this.manageAppVersionForm.controls.name;
  }

  get as_of_date() {
    return this.manageAppVersionForm.controls.as_of_date;
  }

  get as_of_time() {
    return this.manageAppVersionForm.controls.as_of_time;
  }

  get bundle_id() {
    return this.manageAppVersionForm.controls.bundle_id;
  }

  get device_id() {
    return this.manageAppVersionForm.controls.device_id;
  }

  get current_version() {
    return this.manageAppVersionForm.controls.current_version;
  }

  private buildUpAutocompletes() {
    this.bundleResults =
      this.manageAppVersionForm.controls.bundle_id.valueChanges.pipe(
        startWith(""),
        map((value: string) => this.filterBundles(value))
      );

    this.deviceResults =
      this.manageAppVersionForm.controls.device_id.valueChanges.pipe(
        startWith(""),
        map((value: string) => this.filterDevices(value))
      );
  }

  filterBundles(value: any): { label: string }[] {
    const filterValue = value ? value.name.toLowerCase() : "";
    return this.data.appBundles.filter((item: any) =>
      item.name.toLowerCase().includes(filterValue)
    );
  }

  filterDevices(value: any): { label: string }[] {
    const filterValue = value ? value.name.toLowerCase() : "";
    return this.data.appDevices.filter((item: any) =>
      item.name.toLowerCase().includes(filterValue)
    );
  }

  public onDisplayValue(value): string | undefined {
    return value ? value.label : "";
  }

  /**
   * Using moment js, dynamically create an array of objects to populate the time select
   * @param start
   * @param end
   * @param interval
   * @returns {Array}
   */
  private buildTimeSelect() {
    let start = moment().startOf("day");
    let end = moment().endOf("day");
    let interval = 15;
    let timeSelect = [];

    while (start <= end) {
      timeSelect.push({
        value: start.format("h:mm A"),
        label: start.format("h:mm A"),
      });
      start.add(interval, "minutes");
    }

    return timeSelect;
  }

  public manageAppVersion() {
    let actionAdj = this.action.replace(/e$/, "");
    let notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing ${this.type}...`
    );

    console.log(
      "this.manageAppVersionForm.value",
      this.manageAppVersionForm.value
    );

    //configure as_of time
    //first combine as_of_date and as_of_time
    let outboundDate = `${moment(
      this.manageAppVersionForm.value.as_of_date
    ).format("MM/DD/YYYY")} ${this.manageAppVersionForm.value.as_of_time} ${
      this.tz
    }`;

    let outbound = {
      app_bundle_id: this.manageAppVersionForm.value.bundle_id.id,
      app_device_id: this.manageAppVersionForm.value.device_id.id,
      label: this.manageAppVersionForm.value.label,
      name: this.manageAppVersionForm.value.name,
      description: this.manageAppVersionForm.value.description,
      as_of: outboundDate,
      current_version: this.manageAppVersionForm.value.current_version,
      archived: this.targetVersion ? this.targetVersion.archived : false,
    };

    if (this.action === "delete") outbound.archived = true;

    //determine the type of request
    let requestType =
      this.action === "edit" || this.action === "delete" ? "put" : "post";
    let requestURL =
      requestType === "put"
        ? `/app-version/${this.targetVersion.id}`
        : "/app-version";

    //user xrplatformrest service to make the request
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let manageVersion = this._xrPlatformRestService.restfulAPIQuery(
      requestURL,
      requestType,
      outbound,
      options
    );

    manageVersion.subscribe(
      (response: any) => {
        //clear notification
        this._notificationService.clearNotification(notification);

        this._notificationService.successNotification(
          `${this.TitleCase.transform(actionAdj)}ed ${this.type}!`
        );
        this.outgoing.next(response);
        this.appVersionFrame.hide();
      },
      (error: any) => {
        //clear notification
        this._notificationService.clearNotification(notification);

        this._notificationService.errorNotification(
          `Error while ${this.TitleCase.transform(actionAdj)}ing ${
            this.type
          }. Please try again or contact support`
        );
        this.outgoing.next(error);
        this.appVersionFrame.hide();
      }
    );
  }
}
