import { CoolLocalStorage } from "@angular-cool/storage";
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MDBModalRef,
  MDBModalService,
  TabsetComponent,
  PopoverDirective,
} from "ng-uikit-pro-standard";

import { EventServicesService } from "src/app/modules/event-management/services/event-services.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { DOCUMENT } from "@angular/common";
import { EventEmitter } from "events";

import {
  faCirclePlus,
  faMinusSquare,
  faSquareMinus,
  faUsers,
  faSquarePlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";
import { ManageUserGroupRosterComponent } from "src/app/modules/user-management/user-groups/modals/manage-user-group-roster/manage-user-group-roster.component";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

@Component({
  selector: "app-manage-event-roster",
  templateUrl: "./manage-event-roster.component.html",
  styleUrls: ["./manage-event-roster.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageEventRosterComponent implements OnInit, OnDestroy {
  @ViewChild("availableTabs") availableTabs: TabsetComponent;
  @ViewChild("addedTabs") addedTabs: TabsetComponent;
  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  //fonts
  faCirclePlus = faCirclePlus;
  faMinusSquare = faMinusSquare;
  faUsers = faUsers;
  faSquarePlus = faSquarePlus;
  faSquareMinus = faSquareMinus;
  faTimes = faTimes;

  //persistent
  public preSelected: any; //variable to hold preselected values
  public shareDataSubscription: any;
  public currentTab: string = "users";
  public usersLoading: boolean = true;
  public rosterLoading: boolean = true;
  public isClean: boolean = true;

  //outbound
  private outgoing: Subject<any> = new Subject();

  //incoming
  public teamID: number;
  public action: string;
  public experienceId: any;
  public clientSettings: any;
  public targetEvent: any; //if this is an "update" action, the target event is the event currently being updateed; this is passed from the parent EditEventComponent modal
  public eventId: number;
  public labels: any;
  public from: string = "manage-event";

  //invitee handling
  public inviteeUsers: any[] = [];
  public inviteeGroups: any[] = [];

  public SearchText: string;
  public SearchTextGroups: string;
  public token: string;
  public userList: any;
  public addedUsers: any = [];
  public origAddedUsers: any = [];
  public addedGroups: any = [];
  public removedUsers: any = [];
  public removedGroups: any = [];
  public origUserList: any;
  public unchanagedUserList: any;
  public origGroupList: any;
  public unchangedGroupList: any;
  public origAddedGroups: any = [];
  public personas: any;
  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  //groups
  public groups: any = [];

  // saving notification
  public status = "";
  public confirmOn = false;
  public mainModal: any;

  //modals
  public manageGroupRosterFrame: MDBModalRef;

  //tab syncing
  private timeoutID: any;
  private currentTarget: number = 0;
  getActiveTab = new EventEmitter();

  constructor(
    public manageEventRosterFrame: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private eventService: EventServicesService,
    private elementRef: ElementRef,
    private cdref: ChangeDetectorRef,
    private modalService: MDBModalService,
    private _notificationService: NotificationsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    if (this.action === undefined) this.action = "add";

    this.eventId = this.targetEvent ? this.targetEvent.id : null;

    this.retrieveToken();
    this.retrieveData();
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }

  ngOnDestroy(): void {
    ;
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    //retrieve users
    let users = await this.retreiveUsers(getOptions);

    //retrieve personas
    let personas = await this.retrievePersonas(getOptions);

    //retrieve groups
    let groups = await this.retrieveGroups(getOptions);

    this.groups = groups.group_information;
    this.origGroupList = JSON.parse(JSON.stringify(this.groups));
    this.unchangedGroupList = JSON.parse(JSON.stringify(this.groups));

    this.processUsers(users, personas.personas);

    this.usersLoading = false;

    //retrieve invites
    if (this.targetEvent) {
      let invitees = await this.retrieveInvitees(getOptions);
      let processedInvitees = this.processInvitees(invitees.invitees);

      this.inviteeUsers = processedInvitees.inviteeUsers;
      this.inviteeGroups = processedInvitees.inviteeGroups;
    }

    this.buildPreselected();

    this.isClean = true;
    this.rosterLoading = false;
  }

  /**
   * Retrieve invitees
   * @param options
   * @returns invitees
   */
  private retrieveInvitees(options) {
    let retrieveInvitees = this._xrPlatformRestService.restfulAPIQuery(
      "/event/" + this.eventId + "/invitees",
      "get",
      {},
      options
    );

    return retrieveInvitees.toPromise();
  }

  /**
   * Retrieve Team Users
   * @param options
   * @returns
   */
  private retreiveUsers(options) {
    let retrieverUsers = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/users",
      "get",
      {},
      options
    );

    return retrieverUsers.toPromise();
  }

  private retrievePersonas(options) {
    let retrievePersonas = this._xrPlatformRestService.restfulAPIQuery(
      "/personas/findpersonasbyclient/" + this.teamID,
      "get",
      {},
      options
    );

    return retrievePersonas.toPromise();
  }

  private retrieveGroups(options) {
    let retrieveGroups = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/groups",
      "get",
      {},
      options
    );

    return retrieveGroups.toPromise();
  }

  private processInvitees(invitees) {
    let outbound = {
      attached: [],
      inviteeUsers: [],
      inviteeGroups: [],
    };

    //get media
    outbound.attached = invitees.filter((thisAsset) => {
      if (thisAsset.invitee_type === "media") return thisAsset;
    });

    outbound.attached = outbound.attached.map((thisAsset) => {
      return thisAsset.meta.media;
    });

    //get invitee users
    outbound.inviteeUsers = invitees.filter((thisAsset) => {
      if (thisAsset.invitee_type === "user" && thisAsset.as_individual === true)
        return thisAsset;
    });

    //get invitee groups
    outbound.inviteeGroups = invitees.filter((thisAsset) => {
      if (thisAsset.invitee_type === "group") return thisAsset;
    });

    ;

    return outbound;
  }

  private processUsers(users, personas) {
    //process users
    users.forEach((element, index) => {
      if (element.meta && element.meta.persona_id) {
        const personaDetails = personas.filter(
          (item) => item.id === element.meta.persona_id
        );
        if (personaDetails.length > 0) {
          users[index].personaName = personaDetails[0].persona_name;
        } else {
          users[index].personaName = "Not Set";
        }
      } else {
        users[index].personaName = "Not Set";
      }

      let role = "Not Set";

      if (element.meta !== null && element.meta.role !== undefined) {
        switch (element.meta.role) {
          case 1:
            role = "Observer";
            break;
          case 2:
            role = "Leader";
            break;
          case 3:
            role = "Participant";
            break;
        }
      }

      users[index].role = role;
    });

    users = users.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );

    this.origUserList = JSON.parse(JSON.stringify(users));
    this.unchanagedUserList = JSON.parse(JSON.stringify(users));
    this.userList = users;
  }

  buildPreselected() {
    if (this.inviteeUsers.length > 0) {
      this.inviteeUsers.forEach((element) => {
        let user = this.origUserList.filter(
          (item) => item.id === element.invitee_id
        );

        ;

        if (user.length > 0) {
          this.addUser(
            user[0].first_name,
            user[0].last_name,
            user[0].personaName,
            user[0].role,
            user[0].id,
            user[0].username
          );
        }
      });

      this.origAddedUsers = JSON.parse(JSON.stringify(this.addedUsers));
    }

    if (this.inviteeGroups.length > 0) {
      this.inviteeGroups.forEach((element) => {
        let group = this.origGroupList.filter(
          (item) => item.id === element.invitee_id
        );

        if (group.length > 0) {
          this.addGroup(group[0]);
        }
      });

      this.origAddedGroups = JSON.parse(JSON.stringify(this.addedGroups));
    }
  }

  public syncTabs(index, target, tabToActivate) {
    ;

    //to prevent a double call, we only want to call this function when the target tab is different than the current target
    if (this.currentTarget === index) return;

    this.currentTab = tabToActivate;

    //cheap debounce
    //@todo: find a more angular-centric way to do this
    if (this.timeoutID) window.clearTimeout(this.timeoutID);
    this.timeoutID = window.setTimeout(() => {
      ;

      if (target === "available") {
        this.setTab(this.availableTabs, index);
      } else if (target === "added") {
        this.setTab(this.addedTabs, index);
      }

      this.currentTarget = index;
    }, 10);
  }

  private setTab(tabs, index) {
    ;

    if (tabs.tabs[index] === undefined) return false;

    tabs.tabs[index].active = true;
    tabs.getActiveTab.emit({
      el: tabs.tabs[index],
      activeTabIndex: index,
    });
    this.cdref.detectChanges();
  }

  /**
   * Open MDB modal component ManageUserGroupRosterComponent
   *
   * Pass the following data:
   * - teamID
   * - action = "add"
   * - labels
   *
   * Receive the following data:
   * - group
   */
  public openAddUserGroup(groupID?) {
    ;

    let mainEventDisplay = this.document.getElementsByClassName(
      "event-roster-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    let targetGroup = null;

    if (groupID !== undefined) {
      targetGroup = this.getGroup(groupID);
    }

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "group-roster-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };
    this.modalOptions.data = {
      teamID: this.teamID,
      action: groupID !== undefined ? "update" : "add",
      labels: this.labels,
      targetGroup: targetGroup,
      parentModalClass: "event-roster-container",
      from: "event-roster",
    };

    this.manageGroupRosterFrame = this.modalService.show(
      ManageUserGroupRosterComponent,
      this.modalOptions
    );

    this.manageGroupRosterFrame.content.outgoing.subscribe((result) => {
      ;

      //if result is add, add the incoming group to the addedGroups array
      if (result.action === "add") {
        this.addedGroups.push(result.group);
        this.addedGroups.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      }
    });
  }

  public rosterActions() {
    if (this.action === "update") {
      this.updateUsers();
    } else {
      //send addedUsers and added Groups back to parent
      let outgoing = {
        action: "update_event_roster",
        eventRoster: {
          users: this.addedUsers,
          groups: this.addedGroups,
        },
      };

      this.outgoing.next(outgoing);
      this.isClean = true;
      this.closeModal();
    }
  }

  private async updateUsers() {
    let notification = this._notificationService.savingNotification(
      "Updating Event Roster..."
    );

    //check to see if addedUsers has any items not in origAddedUsers
    //if so, add them to them to a new array
    let finalAddedUsers = [];

    this.addedUsers.forEach((element) => {
      let user = this.origAddedUsers.filter((item) => item.id === element.id);

      if (user.length === 0) {
        finalAddedUsers.push(element);
      }
    });

    if (finalAddedUsers.length > 0) {
      let res = await this.eventService.addInviteesToEvent(
        this.eventId,
        finalAddedUsers
      );
    }

    console.log("this.removedUsers", this.removedUsers);

    if (this.removedUsers.length > 0) {
      let removeRes = await this.eventService.removeInviteesFromEvent(
        this.eventId,
        this.removedUsers,
        false
      );
    }

    //do the same as above but for groups
    let finalAddedGroups = [];

    this.addedGroups.forEach((element) => {
      let group = this.origAddedGroups.filter((item) => item.id === element.id);

      if (group.length === 0) {
        finalAddedGroups.push(element);
      }
    });

    ;
    ;
    ;

    if (finalAddedGroups.length > 0) {
      let resGroup = await this.eventService.addInviteesToEvent(
        this.eventId,
        finalAddedGroups,
        true
      );
    }

    if (this.removedGroups.length > 0) {
      let removeResGroup = await this.eventService.removeInviteesFromEvent(
        this.eventId,
        this.removedGroups,
        true
      );
    }

    //send addedUsers and added Groups back to parent
    let outgoing = {
      action: "update_event_roster",
      eventRoster: {
        users: this.addedUsers,
        groups: this.addedGroups,
      },
    };

    this.outgoing.next(outgoing);

    this._notificationService.clearNotification(notification);

    this.isClean = true;

    this.closeModal();
  }

  addUser(firstName, lastName, personaName, role, id, username) {
    ;

    const isUserAlreadyAdded =
      this.addedUsers.filter((item) => item.id === id).length > 0;
    if (!isUserAlreadyAdded) {
      this.userList = this.userList.filter((item) => item.id !== id);
      this.origUserList = this.origUserList.filter((item) => item.id !== id);
      this.addedUsers.push({
        first_name: firstName,
        last_name: lastName,
        username: username,
        expPersona: personaName ? personaName : "Not Set",
        role: role,
        id: id,
        invitee_id: id,
      });

      //when in update mode, make sure this added user is not on the remove list
      //i.e. if a user removes a user, then decides to add them back in again
      if (this.action === "update") {
        this.removedUsers = this.removedUsers.filter((removed_id) => {
          return removed_id !== id;
        });

        this.isClean = this.checkForRosterChanges(
          this.origAddedUsers,
          this.addedUsers
        );
      } else if (this.action === "add") {
        this.isClean = false;
      }
    }
  }

  public addGroup(group) {
    //give the group an "invitee_id"
    group.invitee_id = group.id;

    //add group to addedGroup array if it's not already there
    const isGroupAlreadyAdded =
      this.addedGroups.filter((item) => item.id === group.id).length > 0;
    if (!isGroupAlreadyAdded) {
      //remove group from groups array
      this.groups.forEach((element, index) => {
        if (element.id && element.id == group.id) {
          this.groups.splice(index, 1);
        }
      });
      this.addedGroups.push(group);
      this.addedGroups.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    }

    //when in update mode, make sure this added group is not on the remove list
    //i.e. if a user removes a group, then decides to add it back in again
    if (this.action === "update") {
      this.removedGroups = this.removedGroups.filter((group) => {
        return group.id !== group.id;
      });

      this.isClean = this.checkForRosterChanges(
        this.origAddedGroups,
        this.addedGroups
      );
    } else if (this.action === "add") {
      this.isClean = false;
    }
  }

  removeAddedUser(id) {
    this.addedUsers.forEach((element, index) => {
      if (element.id == id) {
        this.addedUsers.splice(index, 1);
      }
    });
    const removedItem = this.unchanagedUserList.filter(
      (item) => item.id && item.id == id
    );

    //if this is an update action, add removed user id to removedUsers array
    if (this.action === "update") {

      //check to see if user is part of origAddedUsers
      //if not, do not add to removedUsers array
      let isUserInOrigAddedUsers = this.origAddedUsers.filter(
        (item) => item.id === id
      );

      if (isUserInOrigAddedUsers.length > 0) this.removedUsers.push(id);

      this.isClean = this.checkForRosterChanges(
        this.origAddedUsers,
        this.addedUsers
      );
    }

    if (this.action === "add" && this.addedUsers.length === 0)
      this.isClean = true;

    ;

    this.userList.push(removedItem[0]);
    this.userList.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    this.origUserList.push(removedItem[0]);
    this.origUserList.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }

  removeAddedGroup(id) {
    this.addedGroups.forEach((element, index) => {
      if (element.id == id) {
        this.addedGroups.splice(index, 1);
      }
    });

    const removedItem = this.unchangedGroupList.filter(
      (item) => item.id && item.id == id
    );

    //if this is an update action, add removed user id to removedUsers array
    if (this.action === "update") {
      this.removedGroups.push(id);

      this.isClean = this.checkForRosterChanges(
        this.origAddedGroups,
        this.addedGroups
      );
    }

    if (this.action === "add" && this.addedGroups.length === 0)
      this.isClean = true;

    this.groups.push(removedItem[0]);
    this.groups.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    console.log(
      "this.isClean, overrule in closeModal()",
      this.isClean,
      overrule
    );

    if (!this.isClean && !overrule) return false;

    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("secondary");
      mainEventDisplay.item(0).classList.add("restore-primary");
    }

    this.manageEventRosterFrame.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  search(): void {
    const searchKey = this.SearchText.toLowerCase();
    this.userList = this.origUserList.filter(function (tag) {
      return (
        tag.first_name.toLowerCase().indexOf(searchKey) >= 0 ||
        tag.last_name.toLowerCase().indexOf(searchKey) >= 0 ||
        tag.username.toLowerCase().indexOf(searchKey) >= 0 ||
        (
          tag.first_name.toLowerCase() +
          " " +
          tag.last_name.toLowerCase()
        ).indexOf(searchKey) >= 0
      );
    });
  }

  searchGroups(): void {
    const searchKey = this.SearchTextGroups.toLowerCase();
    this.groups = this.origGroupList.filter(function (tag) {
      return tag.name.toLowerCase().indexOf(searchKey) >= 0;
    });
  }

  /**
   * Retrieve group information from groups array via group id
   * @param id
   *
   * @returns group object
   */
  public getGroup(id) {
    let group = this.addedGroups.filter((item) => item.id === id);

    return group[0];
  }

  /**
   * For each item in origList, see if the property other_id matches the id property in any item in currentList
   * Also do the vice versa to check for new adds
   * If it does not, then there are changes
   * @param origList
   * @param currentList
   */
  private checkForRosterChanges(origList, currentList) {
    let isClean = true;

    origList.forEach((element) => {
      let isFound = currentList.filter((item) => item.id === element.other_id);
      if (isFound.length === 0) isClean = false;
    });

    currentList.forEach((element) => {
      let isFound = origList.filter((item) => item.other_id === element.id);
      if (isFound.length === 0) isClean = false;
    });

    return isClean;
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}
