import { Component } from '@angular/core';

@Component({
  selector: 'app-three-d-object-viewer',
  templateUrl: './three-d-object-viewer.component.html',
  styleUrls: ['./three-d-object-viewer.component.scss']
})
export class ThreeDObjectViewerComponent {

}
