import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { EditUserComponent } from "./modals/edit-user/edit-user.component";

import { XrPlatformRestService } from "./../../../services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "./../../../services/utilities/client-management.service";

import { debounceTime } from "rxjs/operators";

import { TitleCasePipe } from "@angular/common";

import {
  MdbTableDirective,
  MDBModalRef,
  MDBModalService,
  MdbTablePaginationComponent,
  TabsetComponent,
} from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { EditPasswordComponent } from "./modals/edit-password/edit-password.component";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { UserServicesService } from "../services/user-services.service";
import { ManageSystemUsersComponent } from "./modals/manage-system-users/manage-system-users.component";

import {
  faFilter,
  faFilterCircleXmark,
  faSquareMinus,
  faTrashAlt,
  faPencilAlt,
  faKey,
  faTimes,
  faDownload,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { Subscription, Subject } from "rxjs";
import { ManageUserGroupRosterComponent } from "../user-groups/modals/manage-user-group-roster/manage-user-group-roster.component";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { Router } from "@angular/router";

import * as moment from "moment";
import * as fileSaver from "file-saver";
import { CompoundFilterPipe } from '../../shared-modules/pipes/compound-filter.pipe';
import { SingleSortPipe } from '../../shared-modules/pipes/single-sort.pipe';
import { ManageUserGroupComponent } from "../user-groups/modals/manage-user-group/manage-user-group.component";

@Component({
  selector: "app-user-catalog",
  templateUrl: "./user-catalog.component.html",
  styleUrls: ["./user-catalog.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe, CompoundFilterPipe, SingleSortPipe],
})
export class UserCatalogComponent implements OnInit, OnDestroy {
  @ViewChild("pagination") pagination: any;

  //fa icons
  public faFilter = faFilter;
  public faFilterCircleXmark = faFilterCircleXmark;
  public faSquareMinus = faSquareMinus;
  public faTrashAlt = faTrashAlt;
  public faPencilAlt = faPencilAlt;
  public faKey = faKey;
  public faTimes = faTimes;
  public faDownload = faDownload;
  public faExclamationTriangle = faExclamationTriangle;

  public labels: any;
  public token: string;
  public teamID: number;
  public domain: string = "default";
  public copy: string = "";
  public clientSettings: any;
  public clientSettingsLegacy: any;
  public groupLength: number;
  private timeoutID: any;

  public csvStatus: string = "pending";

  //user data
  public user: any;
  public users: any = [];
  public allUsers: any = [];
  public usersLength: number = 0;
  public usersIncludingDeleted: any;
  public selectedUser: any;

  //group data
  public groups: any[] = [];
  public allUserGroups: any[] = [];
  public userGroupsLength: number = 0;
  public loadingGroups: boolean = true;
  public loadingError: boolean = false;

  //subscriptions
  private shareDataSubscription: Subscription;

  //settings
  public showLocation = false;
  public showRole = false;
  public showPersona = false;

  //table paginiation
  public first: number = 0;
  public rows: number = 20;
  public page: number = 1;

  //filter and sort detection
  public triggerFilterUpdate: Subject<any> = new Subject();

  //user feedback
  public errorRetrievingMsg: string = "";

  public showProps: any;
  public errorMsg: string = "";
  public statusMsg: string = "";
  public formState: string = "active";

  public formTitle: string = "Add User";
  public formType: string = "add_user";

  public formUser: {
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    user_id: number;
    role_type_id: number;
    meta: {
      role: number;
      persona_id: number;
      assigned_seat: number;
      company: string;
      title: string;
      location: string;
      facility: string;
      shift: string;
      kerberos: string;
    };
  } = {
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      user_id: 0,
      role_type_id: 2,
      meta: {
        role: 3,
        persona_id: 1,
        assigned_seat: 0,
        company: "",
        title: "",
        location: "",
        facility: "",
        shift: "",
        kerberos: "",
      },
    };
  public validatingForm: UntypedFormGroup;

  //table sorting
  headElements = [];
  sort_elements = [
    {
      type: "none",
      head: "actions",
    },
    {
      type: "string",
      head: "first_name",
    },
    {
      type: "string",
      head: "last_name",
    },
    {
      type: "string",
      head: "email",
    },
    {
      type: "string",
      head: "username",
    },
    {
      type: "number",
      head: "role_type_id",
    },
    {
      type: "string",
      head: "meta",
      meta: "role",
    },
  ];
  sort = {
    direction: "asc",
    head: "username",
    type: "string",
  };

  public sortBy = "Username";
  public sortOrder = "asc";
  public sortingActive: boolean = false;

  //filtering
  filter_elements = [];
  //table filtering
  public filterText: {
    action: string;
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    role_type_id: number;
    role: number;
  } = {
      action: null,
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      role_type_id: 0,
      role: 0,
    };

  //modals
  public userFrame: MDBModalRef;
  public inviteUsersFrame: MDBModalRef;
  public groupFrame: MDBModalRef;
  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  public adminCaps: { value: number; label: string }[];
  public expRoleSelect: { value: number; label: string }[];
  public expPersonaSelect: { value: number; label: string }[];
  public previous: any[];
  private mdbTableData: MdbTableDirective;
  public tableUsers: [];

  //for async tab loading
  public loading: {
    init: boolean
    users: boolean;
    userGroups: boolean;
  } = {
      init: true,
      users: true,
      userGroups: true,
    };

  /**viewchild for tabsetEl */
  @ViewChild("tabsetEl", { static: false }) tabsetEl: TabsetComponent;

  /**
   * Our viewChild uses a setter that detects when the child content is available
   */
  @ViewChild("tableEl", { static: false }) set Content(
    content: MdbTableDirective
  ) {
    if (content) {
      this.mdbTableData = content;
      this.mdbTableData.setDataSource(this.users);
    }
  }

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private modalService: MDBModalService,
    private _clientManagementService: ClientManagementService,
    private _settingsService: SettingsService,
    private _userService: UserServicesService,
    private cd: ChangeDetectorRef,
    private TitleCase: TitleCasePipe,
    private _sharedDataService: SharedDataService,
    private _notificationService: NotificationsService,
    private compoundFilterPipe: CompoundFilterPipe,
    private singleSortPipe: SingleSortPipe
  ) { }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  ngOnInit() {

    console.log("loading", this.loading);

    //init vars
    this.groupLength = 0;

    this.headElements = [];

    this.triggerFilterUpdate
      .pipe(debounceTime(350))
      .subscribe(() => this.updateFilteredUsers(true));

    this.retrieveLabels();
    this.retrieveTeamID();

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );
    this.showPersona = this.clientSettings.showPersona.unconstrained_default;

    this.adminCaps = this.formatAdminCaps(
      this.clientSettings.adminCaps.options
    );
    this.expRoleSelect = this.formatRoleSelect(
      this.clientSettings.expRoleSelect.options
    );

    this.clientSettingsLegacy = this._clientManagementService.getClientSettings(
      this.teamID
    );

    this.copy = "Add and / or edit users.";

    this.users = [];

    this.retrieveUser();
    this.retrieveToken();
    this.configureHeadElements();
    this.retrieveUserData();
    this.tableUsers = this.user;

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "add_users"
          ) {
            if (this.tabsetEl.getActiveElement() === undefined) return;

            let activeTab = this.tabsetEl.getActiveElement().activeTabIndex;

            if (activeTab === 0) {
              this.openInviteUsersModal();
            } else if (activeTab === 1) {
              this.openManageGroupModal();
            }
          }
        }
      });
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  ngOnDestroy(): void {
    this._sharedDataService.sendSharedData({
      type: "recheckTitle",
    });
    if (this.userFrame !== undefined) this.userFrame.hide();
  }

  public retrieveTeamDomain() {
    let restURL = this._clientManagementService.getRESTurl();
    let myRestURL = restURL + "/team/" + this.teamID + "/domain";
    if (this.teamID == 0) {
      myRestURL = restURL + "/team/1/domain";
    }

    let retrieveDomain = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/domain",
      "get",
      {}
    );

    return retrieveDomain.toPromise();
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private configureHeadElements() {
    this.headElements = [
      "action",
      "First",
      "Last",
      "Email",
      "Username",
      this.TitleCase.transform(this.labels.systemCapability.singular),
    ];

    this.headElements.push(
      this.TitleCase.transform(this.labels.eventRoles.plural)
    );

    if (this.showPersona) {
      this.headElements.push(
        this.TitleCase.transform(this.labels.eventPersona.plural)
      );
      this.sort_elements.push({
        type: "string",
        head: "meta",
        meta: "persona_id",
      });
    }
  }

  private configureFilterElements() {
    this.adminCaps = [
      { value: 0, label: "All Capabilities" },
      ...this.adminCaps,
    ];
    this.expRoleSelect = [
      { value: 0, label: "All Roles" },
      ...this.expRoleSelect,
    ];

    if (this.showPersona) {
      this.expPersonaSelect = [
        { value: 0, label: "All Personas" },
        ...this.expPersonaSelect,
      ];
    }

    this.filter_elements = [
      {
        filter: true,
        type: "downloadCSV",
        target: null,
      },
      {
        filter: true,
        type: "text",
        target: "first_name",
        placeholder: "First Name",
      },
      {
        filter: true,
        type: "text",
        target: "last_name",
        placeholder: "Last Name",
      },
      {
        filter: true,
        type: "text",
        target: "email",
        placeholder: "Email",
      },
      {
        filter: true,
        type: "text",
        target: "username",
        placeholder: "Username",
      },
      {
        filter: true,
        type: "select",
        target: "role_type_id",
        options: this.adminCaps,
      },
      {
        filter: true,
        type: "select",
        target: "role",
        meta: true,
        options: this.expRoleSelect,
      },
    ];

    this.filterText = {
      action: null,
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      role_type_id: 0,
      role: 0,
    };

    if (this.showPersona) {
      this.filter_elements.push({
        filter: true,
        type: "select",
        target: "persona_id",
        meta: true,
        options: this.expPersonaSelect,
      });

      this.filterText["persona_id"] = 0;
    }
  }

  /**
   * Reset specific filter ngModel filterText[filter_elements[i].target]
   * @param index
   */
  public resetFilter(index, value?) {
    if (value === undefined) value = "";

    console.log(
      "this.filter_elements[index].target",
      this.filter_elements[index].target
    );
    this.filterText[this.filter_elements[index].target] = value;

    this.updateFilteredUsers();
  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction, type) {
    this.sortBy = head;
    this.sortOrder = direction;

    this.sort.head = head;
    this.sort.direction = direction;
    this.sort.type = type;

    this.updateFilteredUsers();
  }

  private async retrieveUserData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrievDomain = await this.retrieveTeamDomain().catch((error) => {
      this.errorRetrievingMsg = "Error retrieving data";
    });

    let retrieveUsers = await this.retrieveUsers(options).catch((error) => {
      this.errorRetrievingMsg = `Error retrieve ${this.labels.user.plural}`;
    });

    if (this.showPersona) {
      let personas = await this.retrievePersonas(options).catch((error) => {
        this.errorRetrievingMsg = `Error retrieve ${this.labels.eventPersona.plural}`;
      });

      this.processPersonas(personas);
    }

    this.domain = retrievDomain.domain;
    this.processUsers(retrieveUsers);
  }

  private retrieveUsers(options) {
    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users/include-deleted/1",
      this.teamID,
      options,
      true
    );

    return retrieveUsers.toPromise();
  }

  private retrievePersonas(options) {
    let retrievePersonas = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "personas",
      this.teamID,
      options,
      true
    );

    return retrievePersonas.toPromise();
  }

  private processUsers(incomingUsers) {
    let usersOut = [];
    let usersIncludingDeleted = [];

    incomingUsers.forEach((thisUser) => {
      thisUser.role_label = this.processRoleType(thisUser.role_type_id);
      thisUser.assigned_experience = 0;
      thisUser.active_experiences = 0;
      thisUser.completed_experiences = 0;

      if (this.showLocation) {
        if (thisUser.meta === null && this.showLocation) {
          thisUser.meta = {
            location: "",
            facility: "",
            shift: "",
            kerberos: "",
          };
        } else if (thisUser.meta.location === undefined) {
          thisUser.meta.location = "";
        } else if (thisUser.meta.facility === undefined) {
          thisUser.meta.facility = "";
        } else if (thisUser.meta.shift === undefined) {
          thisUser.meta.shift = "";
        } else if (thisUser.meta.kerberos === undefined) {
          thisUser.meta.kerberos = "";
        }
      }

      if (!thisUser.deleted) usersOut.push(thisUser);
      usersIncludingDeleted.push(thisUser);
    });

    this.configureFilterElements();
    this.users = usersOut;
    this.allUsers = usersOut;
    this.sortTable("username", "asc", "string");
    this.previous = usersOut;
    this.usersIncludingDeleted = usersIncludingDeleted;
    this.updateFilteredUsers();
    this.loading.users = false;
    this.loading.init = false;

    //also let menu know that we're done loading
    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);
  }

  updateFilteredUsers(resetPage?: boolean) {

    if (resetPage === undefined) resetPage = false;

    this.users = this.compoundFilterPipe.transform(this.allUsers, {
      first_name: {
        text: this.filterText.first_name,
        type: 'text'
      },
      last_name: {
        text: this.filterText.last_name,
        type: 'text'
      },
      email: {
        text: this.filterText.email,
        type: 'text'
      },
      username: {
        text: this.filterText.username,
        type: 'text'
      },
      role_type_id: {
        text: this.filterText.role_type_id,
        type: 'select'
      },
      role: {
        text: this.filterText.role,
        type: 'select',
        is_meta: true
      }
    });

    this.users = this.singleSortPipe.transform(this.users, this.sort.direction, this.sort.head, this.sort.type);

    //get length of filtered events before pagination
    this.usersLength = this.users.length;

    if (resetPage) {
      this.page = 1;
      this.pagination.changePage(0);
    }

    //return paginated results based on rows and page
    this.users = this.users.slice((this.page - 1) * this.rows, (this.page - 1) * this.rows + this.rows);
  }

  onPageChange(event) {

    console.log("this.pagination", this.pagination);

    this.page = event.page + 1;
    this.updateFilteredUsers();

  }

  private async setupGroups() {
    let retrieveGroups = await this.retrieveGroups().catch((error) => {
      this.errorRetrievingMsg = `Error retrieve ${this.labels.userGroup.plural}`; 
    });

    let groupsOut = this._userService.processGroups(retrieveGroups);
    this.groups = groupsOut;
    this.allUserGroups = groupsOut;
    this.userGroupsLength = groupsOut.length;
    this.previous = groupsOut;
    this.loadingGroups = false;
    this.loading.userGroups = false;

    //configure groups for pagination
    let page = 1;
    let rows = 3;
    this.groups = this.groups.slice((page - 1) * rows, (page - 1) * rows + rows);

  }

  private retrieveGroups() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveGroups = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "groups",
      this.teamID,
      options,
      true
    );

    return retrieveGroups.toPromise();
  }

  private formatAdminCaps(caps) {
    let adminCaps = [];
    for (let key in caps) {
      if (caps[key].is_default) {
        adminCaps.push({
          label: this._userService.systemCapabilityLabelling(
            caps[key].label,
            +caps[key].value
          ),
          value: +caps[key].value,
        });
      }
    }

    return adminCaps;
  }

  private formatPersonaSelect(caps) {
    let adminCaps = [];
    for (let key in caps.options) {
      adminCaps.push({
        label: caps.options[key].label,
        value: +caps.options[key].value,
      });
    }

    return adminCaps;
  }

  private formatRoleSelect(roles) {
    let roleSelect = [];
    for (let key in roles) {
      if (roles[key].is_default) {
        roleSelect.push({ label: roles[key].label, value: +roles[key].value });
      }
    }

    return roleSelect;
  }

  private processPersonas(incomingPersonas) {
    let personasSelect = [];

    let personas =
      incomingPersonas !== null && incomingPersonas.personas !== undefined
        ? incomingPersonas.personas
        : [];

    personas.forEach((persona) => {
      let thisPersonaSelect = {
        value: persona.id,
        label: persona.persona_name,
      };

      personasSelect.push(thisPersonaSelect);
    });

    this.expPersonaSelect = personasSelect;
  }

  private processRoleType(role_type_id) {
    let roleType = "Admin";

    this.adminCaps.forEach((adminCap) => {
      if (adminCap.value === role_type_id) roleType = adminCap.label;
    });

    return roleType;
  }

  public async downloadCSV() {
    this.csvStatus = "loading";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    let options = {
      headers: headers,
      responseType: "json",
    };

    let teamData = await this._xrPlatformRestService.restfulAPIQuery(
      `/team/${this.teamID}`,
      "get",
      {},
      options
    ).toPromise();

    //convert team name to a file friendly name
    let teamName = teamData.name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, ".").toLowerCase();

    //get todays date in the format MM.DD.YY
    let date = moment().format("YYYY.M.D.hh.mm.A");

    options.responseType = "blob";

    //retrieve users csv
    let downloadCSV = this._xrPlatformRestService.restfulAPIQuery(
      `/users/${this.teamID}/download/csv`,
      "post",
      {},
      options
    );

    downloadCSV.subscribe((file: any) => {
      let blob: any = new Blob([file], { type: "text/csv; charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(
        blob,
        `${teamName}.users.${date}.csv`
      );
      this.csvStatus = "success";
    }, (error) => {
      this.csvStatus = "error";
    });

  }

  public openInviteUsersModal() {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-system-users-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };

    this.modalOptions.data = {
      user: this.user,
      users: this.usersIncludingDeleted,
      teamID: this.teamID,
      clientSettings: this.clientSettings,
      labels: this.labels,
      expPersonaSelect: this.expPersonaSelect,
    };

    this.inviteUsersFrame = this.modalService.show(
      ManageSystemUsersComponent,
      this.modalOptions
    );

    this.inviteUsersFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined && changedData.action === "add") {
        //fold incoming changedData.users into users
        this.users = [...this.users, ...changedData.users];

        this.processUsers(this.users);

        if (changedData.newGroup) {
          this.groups.push(changedData.newGroup);
          this.groups = this.groups.filter((group) => {
            return group.id !== null;
          });
        }
      } else if (changedData.action !== undefined && changedData.action === "restore") {

        this.users.push(changedData.user);

        //remove from deleted users
        this.usersIncludingDeleted = this.usersIncludingDeleted.filter((user) => {
          return user.id !== changedData.user.id;
        });

      }
    });
  }

  public openManageGroupModal(targetGroup?, action?) {
    this.modalOptions.data = {
      teamID: this.teamID,
      targetGroup: targetGroup === undefined ? null : targetGroup,
      action: "add",
      labels: this.labels,
      from: "user-groups",
    };

    this.modalOptions.containerClass = "";
    this.modalOptions.class =
      this.modalOptions.class + " modal-full-height modal-right";
    this.modalOptions.containerClass = "group-roster-container";

    //for right now we're using the legacy modal to handle deletion
    //@todo: refactor to use the new modal
    if (action === "delete") {
      this.modalOptions.class = "";
      this.modalOptions.containerClass = "delete-group-container";

      this.groupFrame = this.modalService.show(
        ManageUserGroupComponent,
        this.modalOptions
      );
    } else {
      this.groupFrame = this.modalService.show(
        ManageUserGroupRosterComponent,
        this.modalOptions
      );
    }

    this.groupFrame.content.outgoing.subscribe((result: any) => {

      let actionAdj = result.action.replace(/e$/, "");
      let toastMsg = `${targetGroup.name} has been ${actionAdj}ed`;

      this._notificationService.successNotification(toastMsg);

      if (result.action === "add") {
        this.groups.push(result.group);
        this.groups = this.groups.filter((group) => {
          return group.id !== null;
        });
      } else if (result.action === "edit") {
        this.groups.forEach((group, index) => {
          if (group.id === result.group.id) {
            this.groups[index].name = result.group.name;
            this.groups[index].description = result.group.description;
            this.groups[index].members = result.group.members;
            this.groups[index].number_of_members =
              result.group.number_of_members;
            this.groups[index].number_of_events =
              result.group.number_of_events;
          }
        });
      } else if (result.action === "delete") {
        //remove group from groups via changedData.group.id
        this.groups = this.groups.filter((group) => {
          return group.id !== result.group.id;
        });
      }
    });
  }

  public tabSelected(event, targetTab) {
    //cheap debounce
    //@todo: find a more angular-centric way to do this
    if (this.timeoutID) window.clearTimeout(this.timeoutID);
    this.timeoutID = window.setTimeout(() => {
      if (targetTab === "userGroups" && this.loading.userGroups) {
        this.setupGroups();
      } else {
        if (!this.loading.init) this.loading[targetTab] = false;
      }

      let buttonTitleChange = {
        type: "buttonTitleChange",
        data: {
          buttonTitle: "Add Users",
        },
      };

      if (targetTab === "userGroups") {
        buttonTitleChange = {
          type: "buttonTitleChange",
          data: {
            buttonTitle: "Add User Group",
          },
        };
      }

      this._sharedDataService.sendSharedData(buttonTitleChange);
    }, 10);
  }

  public openUserModal(action, thisUser?) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass:
        action === "delete" || action === "restore" ? "delete-users-container" : "add-users-container",
      class:
        action !== "delete" && action !== "restore"
          ? this.modalOptions.class + " modal-full-height modal-right"
          : "",
    };

    this.modalOptions.data = {
      user: this.user,
      users: this.usersIncludingDeleted,
      targetUser: thisUser,
      teamID: this.teamID,
      action: action,
      domain: this.domain,
      sourceType: "user",
    };

    if (this.showPersona) {
      this.modalOptions.data["expPersonaSelect"] = this.expPersonaSelect;
    }

    this.userFrame = this.modalService.show(
      EditUserComponent,
      this.modalOptions
    );

    this.userFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        let actionAdj = action.replace(/e$/, "");
        this._notificationService.successNotification(
          `${this.TitleCase.transform(
            this.labels.user.singular
          )} successfully ${actionAdj}ed`
        );

        console.log("changedData in user add/update", changedData);

        switch (changedData.action) {
          case "add":
            if (changedData.user !== undefined) {
              let allUsers = this.users;
              allUsers.push(changedData.user);
              this.processUsers(allUsers);
            }
            break;
          case "update":
          case "restore":
            if (changedData.user !== undefined) {
              let updatedUser = changedData.user;

              this.users = this.users.map(user => {
                if (user.id === updatedUser.id) {
                  updatedUser.role_label = this.processRoleType(updatedUser.role_type_id);
                  return updatedUser;
                }
                return user;
              });
            }
            break;
          case "delete":
            if (changedData.user !== undefined) {
              this.users = this.users.filter((user) => {
                return user.id !== changedData.user.id;
              });
            }

            break;
        }
      }
    });
  }

  public openPasswordModal(thisUser) {
    this.modalOptions.data = {
      user: this.user,
      targetUser: thisUser,
      teamID: this.teamID,
    };

    this.userFrame = this.modalService.show(
      EditPasswordComponent,
      this.modalOptions
    );
  }

  public processAdminCaps(id: number) {
    let capLabel = this.adminCaps.filter((option) => {
      if (option.value === id) return true;
    });

    if (capLabel[0] === undefined)
      capLabel[0] = {
        value: 4,
        label: "Not Set",
      };
    return capLabel[0].label;
  }

  public processExpRole(meta: any) {
    let id =
      meta !== undefined && meta !== null && meta.role !== undefined
        ? meta.role
        : -1;

    let roleLabel = this.expRoleSelect.filter((option) => {
      if (option.value === id) return true;
    });

    if (roleLabel[0] === undefined)
      roleLabel[0] = {
        value: 4,
        label: "Not Set",
      };
    return roleLabel[0].label;
  }

  public processExpPersona(meta: any) {
    let id =
      meta !== undefined && meta !== null && meta.persona_id !== undefined
        ? meta.persona_id
        : -1;

    let personaLabel = this.expPersonaSelect.filter((option) => {
      if (option.value === id) return true;
    });

    if (personaLabel[0] === undefined)
      personaLabel[0] = {
        value: null,
        label: "Not Set",
      };
    return personaLabel[0].label;
  }

  public processAssignedSeat(meta: any) {
    let id =
      meta !== undefined && meta !== null && meta.assigned_seat !== undefined
        ? meta.assigned_seat
        : -1;

    if (id === -1) return "Not Set";

    return id;
  }
}
