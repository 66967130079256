import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

import { LoginComponent } from "./login/login.component";
import { PersistentModule } from "./../persistent/persistent.module";

import { SharedModulesModule } from "./../shared-modules/shared-modules.module";
import { PasswordResetComponent } from './password-reset/password-reset.component';

@NgModule({
    declarations: [LoginComponent, PasswordResetComponent],
    imports: [
        CommonModule,
        SharedModulesModule,
        HttpClientModule,
        PersistentModule
    ],
    exports: [LoginComponent]
})
export class AuthenticationModule {}
