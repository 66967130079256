<div class="class-wrapper">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">{{ mode }} Permission</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="managePermissionModal.hide()"
      id="close-modal-icon"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3 p-0">
    <div class="row flex-add-form">
      <form
        class="text-center border border-light p-5 edit-form w-100"
        [formGroup]="permissionForm"
        (ngSubmit)="managePermission()"
        autocomplete="off"
      >
        <div class="md-form mb-5 form-group">
          <input
            type="text"
            id="permission_name"
            formControlName="permission_name"
            class="form-control"
            mdbInput
          />
          <label for="permission_name">Permission Name</label>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="managePermissionModal.hide()"
      id="close-modal-btn"
    >
      Close
    </button>
    <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="managePermission()"
      id="action-permission-btn"
    >
      {{ mode }} Permission
    </button>
  </div>
</div>
