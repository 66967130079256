import { map, filter } from "rxjs/operators";
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnChanges,
} from "@angular/core";
import { Router, RouterStateSnapshot } from '@angular/router';

import { CoolLocalStorage } from "@angular-cool/storage";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { NeedAuthGuardService } from "src/app/services/utilities/need-auth-guard.service";
import { Observable } from "rxjs";

import {
  faCirclePlus,
  faCube,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit, OnChanges {
  @Input("token") token: string;
  @Input("control") control: boolean;
  public menuLoaded: boolean;
  public isLoggedIn: Observable<boolean>;
  private snapshot: RouterStateSnapshot;

  //icons
  public faCirclePlus = faCirclePlus;
  public faCube = faCube;
  public faUpload = faUpload;

  public teamID: number = 0;
  public clientCode: string;
  public logoSrc: string;
  public thisRoute: string;
  public user: any = null;
  public user_role_id: number = 0;
  public pageTitle = "";
  public requiredMsg: boolean = false;
  public data: any;
  public buttonAction = "";
  public buttonTitle = "";
  public buttonActionType = "";
  public pageLoading: boolean = true;
  public loadingAni: boolean = false;

  public logoutFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private modalService: MDBModalService,
    private _needAuthGuardService: NeedAuthGuardService,
    private _sharedDataService: SharedDataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.menuInit();

    this.snapshot = this.router.routerState.snapshot;

    this._needAuthGuardService.navEnd.subscribe(() => {
      console.log("navEnd in menu");
      this.menuInit();
    });

    this._needAuthGuardService.routeDataObserver.subscribe((data) => {
      console.log("data in menu", data);
      this.menuInit();
      this.determinePageTitle(data);
    });

    this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {

      console.log("incoming in menu", incoming);

      if (incoming.type === "pageLoaded") {
        if (
          incoming.data.pageLoaded !== undefined &&
          incoming.data.pageLoaded
        ) {
          this.pageLoading = false;
        }
      } else if (incoming.type === "actionButtonsChange") {
        this.data.actions = incoming.data.actions;
        console.log("this.data.actions after update", this.data.actions);
      } else if (incoming.type === "buttonTitleChange") {
        if (incoming.data.buttonTitle !== undefined)
          this.buttonTitle = incoming.data.buttonTitle;

        //if the buttonAction is defined, also update the button action
        if (incoming.data.buttonAction !== undefined)
          this.buttonAction = incoming.data.buttonAction;
      } else if (incoming.type === "pageTitleChange") {
        if (incoming.data.pageTitle !== undefined) this.pageTitle = incoming.data.pageTitle;
        if (incoming.data.loadingAni !== undefined) this.loadingAni = incoming.data.loadingAni;
      }
    });
  }

  ngOnChanges() {
    if (this.token !== undefined && this.control !== undefined)
      this.checkLoginStatus();
  }

  private determinePageTitle(data) {
    //first reset values
    if (data.title !== "determine") this.pageTitle = "";
    this.data = data;
    this.buttonAction = "";
    if (data.title !== "determine") this.buttonTitle = "";
    this.buttonActionType = "";
    this.requiredMsg = false;
    this.loadingAni = false;

    if (data.title !== undefined && data.title !== "determine") {
      this.pageTitle = data.title;

      if (data.username !== undefined)
        this.pageTitle = `${this.pageTitle} ${this.user.first_name}`;
    }
    if (data.action !== undefined) this.buttonAction = data.action;
    if (data.actionTitle !== undefined && data.actionTitle !== "determine") this.buttonTitle = data.actionTitle;
    if (data.actionType !== undefined) this.buttonActionType = data.actionType;
    if (data.requiredMsg !== undefined && data.requiredMsg) this.requiredMsg = data.requiredMsg;
    if (data.loadingAni !== undefined && data.loadingAni) this.loadingAni = data.loadingAni;
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
    if (this.user !== null) this.user_role_id = this.user.role_type_id;
  }

  private menuInit() {
    this.retrieveClientCode();
    this.retrieveUser();

    if (this.control === undefined) this.control = false;
    if (this.token === undefined) this.token = null;
    this.retrieveTeamID();

    console.log("this.control, this.token in menuInit", this.control, this.token);

    this.logoSrc = "/assets/img/foretell_logo_globe.png";

    if (this.data !== undefined && this.data.actions !== undefined)
      console.log("this.data.actions in menuInit", this.data.actions);

    this.checkLoginStatus();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private checkLoginStatus() {
    if (
      (this.token === undefined || this.token === null) &&
      (this.control === false || this.control === true)
    ) {
      this.isLoggedIn = new Observable((observer) => observer.next(false));
    } else {
      this.isLoggedIn = new Observable((observer) => observer.next(true));
    }
  }

  public logout() {

    this._needAuthGuardService.initiateLogout(this.teamID, true, this.snapshot, true);

  }

  public toggleSideNav() {
    let toSend = {
      type: "toggleNav",
      toggleSideNav: true,
    };

    this._sharedDataService.sendSharedData(toSend);
  }

  public runPageAction(action?) {

    if (action === undefined) action = this.buttonAction;

    let toSend = {
      type: "buttonAction",
      data: {
        buttonAction: action,
      },
    };

    this._sharedDataService.sendSharedData(toSend);
  }
}
