<div class="class-wrapper">
    <app-status-messages [msgs]="msgs" [btnLabel]="btnLabel" [continueType]="continueType"
        (targetAction)="handleIncomingAction($event)"></app-status-messages>
    <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">{{ type }} Question</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="manageSurveyModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body m-3 p-0">
        <div class="row flex-add-form">
            <form class="text-center border border-light p-5 edit-form w-100" [formGroup]="questionForm"
                (ngSubmit)="clickUpdateQuestion()" autocomplete="off">
                <div class="md-form mb-5 form-group">
                    <input type="text" id="name" formControlName="name" class="form-control" mdbInput />
                    <label for="name">Question Name</label>
                </div>
                <div class="md-form mb-5 form-group">
                    <input type="text" id="label" formControlName="label" class="form-control" mdbInput />
                    <label for="label">Question Label</label>
                </div>
                <div class="md-form mb-5 form-group">
                    <input type="text" id="question" formControlName="question" class="form-control" mdbInput />
                    <label for="question">Question</label>
                </div>
                <div class="md-form mb-5 form-group">
                    <mdb-select [options]="typeSelect" id="system" formControlName="type" mdbInput
                        placeholder="Type for answer" label="Type">

                    </mdb-select>
                </div>
                <mdb-checkbox [checked]="
                questionForm.value.constrained
                                        " [default]="
                                        questionForm.value.constrained
                                        " (change)="
                                          updateCheckbox($event)
                                        ">Constrained</mdb-checkbox>
                <div class="md-form mb-5 form-group">
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn class="waves-light theme-action-button" mdbWavesEffect (click)="manageSurveyModal.hide()">
            Close
        </button>
        <button mdbBtn class="waves-light theme-action-button" mdbWavesEffect (click)="clickUpdateQuestion()">
            {{ type }} Question
        </button>
    </div>
</div>