import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";

//third-party imports
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";

//local services
import { ScheduleGroupToClassComponent } from "../schedule-group-to-class/schedule-group-to-class.component";
import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ViewGroupModalComponent } from "../view-group-modal/view-group-modal.component";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-manage-groups",
  templateUrl: "./manage-groups.component.html",
  styleUrls: ["./manage-groups.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class ManageGroupsComponent implements OnInit {
  //incoming
  public targetEvent: any;
  public thisUser: any;
  public groups: any;
  public teamID: number;
  public clientSettings: any;

  //outgoing
  private outgoing: Subject<any> = new Subject();

  public labels: any;
  public token: string;

  public title = "Manage users";
  public errorMsg: string = "";
  public statusMsg: string = "";
  public actionMsg: string = "Add or remove media from class";
  public formState: string = "active";
  public formLoading: boolean = false;

  public scheduledGroupsTableHeaders: string[] = [];

  public groupManagerFrame: MDBModalRef;
  public viewGroupFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  //btn labels
  public btnLabel: { close: string } = {
    close: "Close",
  };

  constructor(
    private modalService: MDBModalService,
    public manageGroupFrame: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private titleCase: TitleCasePipe
  ) {}

  ngOnInit(): void {
    ;
    this.retrieveLabels();
    this.retrieveToken();
    this.scheduledGroupsTableHeaders.push(
      this.titleCase.transform(this.labels.userGroup.singular)
    );
    this.scheduledGroupsTableHeaders.push(
      "Number of " +
        this.titleCase.transform(this.labels.userGroupMember.plural),
      ""
    );
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public retrieveGroups() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    ;
    let groupRequest = this._xrPlatformRestService.restfulAPIQuery(
      "/group/class/" + this.targetEvent.id + "/classes",
      "get",
      {},
      getOptions
    );

    groupRequest.subscribe(
      (response) => {
        ;
        this.groups = response.scheduled_event.invitees;
      },
      (error) => {}
    );
  }

  public openGroupToClassModal(targetGroup, action?) {
    this.modalOptions.data = {
      targetEvent: this.targetEvent,
      user: this.thisUser,
      groups: this.groups,
      teamID: this.teamID,
      targetGroup: targetGroup,
      action: action,
    };
    this.modalOptions.class = "modal-dialog modal-dialog-centered";
    this.groupManagerFrame = this.modalService.show(
      ScheduleGroupToClassComponent,
      this.modalOptions
    );

    this.groupManagerFrame.content.outgoing.subscribe((result: any) => {
      if (result.action === "remove") {
        this.targetEvent.scheduled_user_groups.forEach((scheduled, index) => {
          if (scheduled.id === result.group_id) {
            this.targetEvent.scheduled_user_groups.splice(index, 1);
            this.targetEvent.userGroupCount--;
            let outgoing = {
              action: "remove",
              type: "group",
              targetEvent: this.targetEvent,
            };

            this.outgoing.next(outgoing);
          }
        });
      }
    });
  }

  public openViewGroupModal(targetGroup) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "event-media-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };

    this.modalOptions.data = {
      parent_group: targetGroup,
      teamID: this.teamID,
      targetEvent: this.targetEvent,
      clientSettings: this.clientSettings,
    };
    // let modalOptions = this.modalOptions;
    // modalOptions.class = "modal-dialog modal-dialog-centered modal-lg";
    this.viewGroupFrame = this.modalService.show(
      ViewGroupModalComponent,
      this.modalOptions
    );
  }

  close() {
    this.manageGroupFrame.hide();
    this.modalService.hide(1);
  }
}
