import {
  Component,
  OnInit,
  AfterContentChecked,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "app-status-messages",
  templateUrl: "./status-messages.component.html",
  styleUrls: ["./status-messages.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StatusMessagesComponent implements OnInit, AfterContentChecked {
  @Input("btnLabel") btnLabel: any;
  @Input("msgs") msgs: any;
  @Input("continueType") continueType: string;
  @Output("targetAction") targetAction = new EventEmitter();

  constructor() {}

  ngOnInit() {
    //default continueType
    this.continueType =
      this.continueType === undefined ? "reset" : this.continueType;
  }

  ngAfterContentChecked() {
    //add loader if it's not already in there
    if (
      this.msgs.processingMsg !== "" &&
      this.msgs.processingMsg.indexOf("spinner-grow") === -1
    ) {
      this.msgs.processingMsg +=
        '<div class="spinner-grow" role="status"></div>';
    }
  }

  public triggerAction(action) {
    ;
    this.targetAction.emit(action);
  }
}
