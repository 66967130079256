<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 text-center">
        <h5>{{this.labels.user.singular | titlecase}} Entry and Exits</h5>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 d-flex justify-content-center align-items-center flex-row loading-height"
        *ngIf="lineBreakLoading"
      >
        <div
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="col-12" *ngIf="!lineBreakLoading">
        <div
          style="
            position: relative;
            height: 50vh;
            width: inherit;
            margin-top: 2rem;
            margin-bottom: 3rem;
          "
        >
          <canvas
            mdbChart
            height="240"
            [chartType]="chartTypeLT"
            [datasets]="chartDatasetsLT"
            [labels]="chartLabelsLT"
            [options]="chartOptionsLT"
            [colors]="chartColorsLT"
            [legend]="false"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
