import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
} from "@angular/core";

import { Clipboard } from "@angular/cdk/clipboard";

import {
  faUserTie,
  faTableColumns,
  faUsers,
  faVrCardboard,
  faPhotoFilm,
  faChartColumn,
  faScrewdriverWrench,
  faTableList,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import { CoolLocalStorage } from "@angular-cool/storage";
import {
  MDBModalRef,
  MDBModalService,
  SidenavComponent,
  ToastService,
} from "ng-uikit-pro-standard";

import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { debounceTime } from "rxjs/operators";

import { LogoutComponent } from "../menu/modals/logout/logout.component";

import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { NeedAuthGuardService } from "src/app/services/utilities/need-auth-guard.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

import { environment } from 'src/environments/environment';

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SideMenuComponent implements OnInit, AfterViewInit {
  @Input("token") token: string;
  @Input("control") control: boolean;
  @ViewChild("sidenav", { static: true }) sidenav: SidenavComponent;

  public teamID: number = 0;
  public superAdminLevel: boolean = false;
  public clientCode: string = "1";
  public logoSrc: string;
  public thisRoute: string;
  public user: any = null;
  public labels: any;
  public user_role_id: number = 0;
  public clientSettings: any;
  public isAdmin: boolean = false;
  public isModerator: boolean = false;
  public includeSpecialComponent: boolean = environment.includeSpecialComponent;
  //public labels: any;

  //font awesome icons
  public faUserTie = faUserTie;
  public faTableColumns = faTableColumns;
  public faUsers = faUsers;
  public faVrCardboard = faVrCardboard;
  public faPhotoFilm = faPhotoFilm;
  public faChartColumn = faChartColumn;
  public faScrewdriverWrench = faScrewdriverWrench;
  public faTableList = faTableList;
  public faCircleInfo = faCircleInfo;

  //debouncing
  private debounceSubject = new Subject();

  public menuOptions: {
    myEvents: boolean;
    experiences: boolean;
    defaultMetrics: boolean;
    metricsExtras: boolean;
    metaDisplay: { name: string; menuLabel: string }[];
  } = {
      myEvents: true,
      experiences: false,
      defaultMetrics: true,
      metricsExtras: false,
      metaDisplay: [],
    };

  public logoutFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  //font awesome icons
  public faCopy = faCopy;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private _needAuthGuardService: NeedAuthGuardService,
    private _sharedDataService: SharedDataService,
    private _settingsService: SettingsService,
    private _xrPlatformRestService: XrPlatformRestService,
    private toastrService: ToastService,
    private clipboard: Clipboard,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit() {
    this.retrieveTeamID();
    this.retrieveClientCode();
    this.retrieveLabels();

    //setup debouncing
    this.debounceSubject
      .pipe(debounceTime(350))
      .subscribe((params: any) => this.copyAction(params));

    this._needAuthGuardService.navEnd.subscribe(() => {
      console.log("navEnd in menu");
      this.menuInit();
    });

    this.route.params.subscribe((params) => {
      if (params["client_code"] === undefined) this.superAdminLevel = true;
      this.menuInit();
    });

    this._sharedDataService.sharedDataComm$.subscribe((data: any) => {
      console.log("shared data in side menu", data);
      if (data.toggleSideNav !== undefined) this.sidenav.toggle();
      if (
        data.type !== undefined &&
        data.type === "logout" &&
        data.status === "success"
      )
        this.token = null;
    });
  }

  ngAfterViewInit() { }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
    if (this.user !== null) this.user_role_id = this.user.role_type_id;
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private menuInit() {
    this.retrieveUser();

    if (this.user_role_id === 1 || this.user_role_id === 2) this.isAdmin = true;

    if (this.user_role_id === 3) this.isModerator = true;

    if (this.control === undefined) this.control = false;
    this.menuConfig();
  }

  public copyAccessCode() {
    let params = {
      copy: this.clientCode,
    };
    this.debounceSubject.next(params);
  }

  private copyAction(params) {
    let copyResults = this.clipboard.copy(params.copy);
    let message = "Code successufly copied";

    if (!copyResults) {
      message = "Issue copying code";
    }

    let notification = this._notificationService.successNotification(message);
  }

  private async menuConfig() {
    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    if (
      !this.superAdminLevel &&
      this.clientSettings !== null &&
      this.clientSettings.clientLogo.asset !== undefined
    ) {
      let asset = this.clientSettings.clientLogo.asset;

      if (asset !== undefined && asset.cdn_uri !== undefined) {
        this.logoSrc = asset.cdn_uri;
      } else {
        this.logoSrc = "/assets/img/GlimpseGroupWhite.png";
      }
    } else {
      this.logoSrc = "/assets/img/GlimpseGroupWhite.png";
    }

    if (this.clientSettings !== null) {

      let metricsExtras = false;

      if (this.clientSettings.showReadquestAnalytics !== undefined && this.clientSettings.showReadquestAnalytics.unconstrained_default) metricsExtras = true;

      this.menuOptions = {
        myEvents: this.clientSettings.myEvents.unconstrained_default,
        experiences: this.clientSettings.experiences.unconstrained_default,
        defaultMetrics:
          this.clientSettings.defaultMetrics.unconstrained_default,
        metricsExtras: metricsExtras,
        metaDisplay: [],
      };

      if (this.clientSettings.umdShow.unconstrained_default) {
        this.menuOptions.metaDisplay = [
          {
            name: this.clientSettings.umdName.unconstrained_default,
            menuLabel: this.clientSettings.umdLabel.unconstrained_default,
          },
        ];
      }
    }
  }

  public logout() {
    this.modalOptions.data = {
      teamID: this.teamID,
    };

    this.logoutFrame = this.modalService.show(
      LogoutComponent,
      this.modalOptions
    );
  }
}
