import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
  selector: "app-super-admin-experience-updates",
  templateUrl: "./super-admin-experience-updates.component.html",
  styleUrls: ["./super-admin-experience-updates.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SuperAdminExperienceUpdatesComponent implements OnInit {
  //incoming
  public teamID: number;
  public experienceID: number;
  public labels: any;

  //persistent
  public legacyEvents: any;
  public token: string;

  //feedback response
  public formLoading: boolean = true;
  public errorMsg: string = "";
  public statusMsg: string = "";
  public actionMsg: string = "Ready to update SEs.";
  public formState: string = "active";

  //modal vars
  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Update Media Invitees",
  };

  constructor(
    public adminFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _mediaManagementService: MediaManagementServicesService
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveLabels();
    this.retrieveScheduledExperiences(this.teamID, this.token);
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveScheduledExperiences(teamID: number, token: string) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const httpOptions = {
      headers: headers,
    };

    let targetExperience = `legacy-experience/${this.experienceID}/flag`;

    let retrieveScheduledExperiences =
      this._xrPlatformRestService.retrieveEntityCollection(
        "team",
        targetExperience,
        teamID,
        httpOptions,
        true
      );

    retrieveScheduledExperiences.subscribe(
      (response) => {
        this.legacyEvents = response.events;
        this.formLoading = false;
      },
      (err) => {
        ;
      }
    );
  }

  public async updateMediaInvitees() {
    this.statusMsg = "Update Team IDs";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let waiting = await Promise.all(
      this.legacyEvents.map(async (event) => {
        let response = await this.checkInvitees(event.invitees);
      })
    );

    if (waiting.length) this.statusMsg = "Done";
  }

  private async checkInvitees(invitees) {
    let waiting = await Promise.all(
      invitees.map(async (invitee) => {
        if (
          invitee.invitee_type === "media" &&
          invitee.meta.media.thumbnail_asset_uuid === undefined
        ) {
          let asset = await this.retrieveMediaItem(invitee.meta.media.uuid);

          let response = await this.updateInvitee(invitee, asset);

          if (response) Promise.resolve();
        } else {
          Promise.resolve();
        }
      })
    );
  }

  private retrieveMediaItem(uuid) {
    let getMediaItem = this._mediaManagementService.retrieveMediaItem(
      uuid,
      this.token
    );

    return getMediaItem.toPromise();
  }

  private updateInvitee(invitee, asset) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let meta = invitee.meta;
    meta.media["thumbnail_asset_uuid"] = asset.thumbnail_asset_uuid;

    let updateList = [
      {
        scheduled_event_id: invitee.scheduled_event_id,
        invitee_type: "media",
        id: invitee.id,
        invitee_id: invitee.invitee_id,
        meta: meta,
      },
    ];

    let body = {
      entity: "schedule",
      entity_id: invitee.scheduled_event_id,
      toUpdate: updateList,
    };

    let updateInviteeMeta = this._xrPlatformRestService.scheduledExperiences(
      "invitee-meta-update",
      body,
      options
    );
    return updateInviteeMeta.toPromise();
  }

  public async removeGroupsAndMediaFromDeletedEvents() {
    for (const event of this.legacyEvents) {
      if (event.deleted === true) {
        console.log("event name", event.event_name);

        for (const invitee of event.invitees) {
          if (
            invitee.invitee_type === "group" ||
            invitee.invitee_type === "media"
          ) {
            console.log("removing invitee", invitee.invitee_id);

            let response = await this.removeInvitee(
              invitee.invitee_id,
              invitee.scheduled_event_id,
              invitee.invitee_type
            );

            console.log("response from remove invitee", response);
          }
        }
      }
    }

    console.log("Done");
  }

  public async removeIndividualUsersFromDeletedEvents() {
    for (const event of this.legacyEvents) {
      if (event.deleted === true) {
        console.log("event name", event.event_name);

        for (const invitee of event.invitees) {
          if (invitee.invitee_type === "user") {
            console.log("removing invitee", invitee.invitee_id);

            let response = await this.removeInvitee(
              invitee.invitee_id,
              invitee.scheduled_event_id,
              invitee.invitee_type
            );

            console.log("response from remove invitee", response);
          }
        }
      }
    }

    console.log("Done");
  }

  public async removeUsersFromOrphanedGroups() {
    for (const event of this.legacyEvents) {
      console.log("event name", event.event_name);
      console.log("event invitees", event.invitees);

      let groupIDs = [];
      //find any invitees that are of invitee_type "group"
      event.invitees.forEach((invitee) => {
        if (invitee.invitee_type === "group") {
          groupIDs.push(invitee.invitee_id);
        }
      });

      console.log("groupIDs", groupIDs);

      //await loop through invitees and check to see if user is part of a group via meta.groups
      //meta should be checked if not null not, then checked if groups is defined, and finally checked if the groups array is not empty
      //then we check each group_id in the groups array to see if it is in the groupIDs array
      //if it is not, then we remove it from meta.groups; if the meta.groups array is then empty and meta.individual is not defined or false, then we remove the invitee
      for (const invitee of event.invitees) {
        if (invitee.invitee_type === "user") {
          if (invitee.meta !== null) {
            if (
              invitee.meta.groups !== undefined &&
              invitee.meta.groups.length
            ) {
              let groups = invitee.meta.groups;

              console.log("invitee.invitee_id", invitee.invitee_id);
              console.log("invitee.meta", invitee);

              let remainingGroups = groups.filter((group) => {
                return groupIDs.includes(group.group_id);
              });

              console.log("remainingGroups", remainingGroups);

              if (
                !remainingGroups.length &&
                (invitee.meta.individual === undefined ||
                  (invitee.meta.individual !== undefined &&
                    invitee.meta.individual === false))
              ) {
                let response = await this.removeInvitee(
                  invitee.invitee_id,
                  invitee.scheduled_event_id,
                  invitee.invitee_type
                );

                console.log("response from remove invitee", response);
              } else {
                let meta = invitee.meta;
                meta.groups = remainingGroups;

                let response = await this.updateEventMeta(
                  invitee.scheduled_event_id,
                  invitee.invitee_id,
                  meta
                );

                console.log("response from update meta", response);
              }
            }
          } else {
            if (invitee.groups.length === 0 && !invitee.as_individual) {
              let response = await this.removeInvitee(
                invitee.invitee_id,
                invitee.scheduled_event_id,
                invitee.invitee_type
              );

              console.log("response from remove invitee", response);
            }
          }
        }
      }
    }

    console.log("Done");
  }

  private updateEventMeta(event_ID, invitee_ID, meta) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
      schedule_id: event_ID,
    };

    let thisUpdate = [
      {
        scheduled_event_id: event_ID,
        invitee_type: "user",
        invitee_id: invitee_ID,
        meta: meta,
      },
    ];

    let body = {
      entity: "schedule",
      entity_id: event_ID,
      toUpdate: thisUpdate,
    };

    let updateEventMeta = this._xrPlatformRestService.scheduledExperiences(
      "invitee-meta-update",
      body,
      options
    );
    return updateEventMeta.toPromise();
  }

  private removeInvitee(invitee_ID, event_ID, invitee_type) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
      schedule_id: event_ID,
    };

    let toRemove: any = [
      {
        scheduled_event_id: event_ID,
        invitee_type: invitee_type,
        invitee_id: invitee_ID,
      },
    ];

    if (invitee_type === "group") {
      toRemove = [
        {
          scheduled_event_id: event_ID,
          invitee_type: invitee_type,
          group_ids: [invitee_ID],
        },
      ];
    }

    let body = {
      entity: "schedule",
      entity_id: event_ID,
      toRemove: toRemove,
    };

    let removeInvitee = this._xrPlatformRestService.scheduledExperiences(
      "remove",
      body,
      options
    );
    return removeInvitee.toPromise();
  }
}
