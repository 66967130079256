import { filter } from 'rxjs/operators';
import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { DOCUMENT, TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-event-settings-manage-media",
  templateUrl: "./event-settings-manage-media.component.html",
  styleUrls: ["./event-settings-manage-media.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class EventSettingsManageMediaComponent implements OnInit {
  private token: string;
  public media: any;
  public labels: any;

  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";
  public placeHolderMp3: string = "assets/img/mp3-thumbnail.png";
  public placeHolderWav: string = "assets/img/wav-thumbnail.png";

  //incoming
  public teamID;
  public type;
  public subtype;
  public fromLocation: string;
  public targetAsset: any;
  public name;
  public experience: any;
  public parentModalClass: string = "";
  public typeLabel: string = "Logo";
  public buttonLabel: string;

  //content
  public title = "Select Media";

  //tracking
  public isClean: boolean = true;

  //form vars
  public scheduleForm: UntypedFormGroup;
  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Select",
  };
  public pdfOptionsGroup: any;
  public imageOptionsGroup: any;
  public videoOptionsGroup: any;
  public formLoading: boolean = false;
  public formState: string = "active";

  //data from media component
  public mediaData: any;

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  //existing values
  public existingValues: any;

  public actionButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
      info: false,
      share: false,
      delete: false,
    };

  public columnVisibility: {
    thumbnail: boolean;
    pdfStatus: boolean;
    fileType: boolean;
    owner: boolean;
    urlType: boolean;
    dateCreated: boolean;
    shares: boolean;
  } = {
      thumbnail: true,
      pdfStatus: false,
      fileType: true,
      owner: true,
      urlType: false,
      dateCreated: true,
      shares: false,
    };

  public selectable: boolean = true;

  public selectedMedia: any = [];

  public filterByType: string = "all";

  public selectableType: string = "single";

  public clickableThumb: boolean = true;

  public systemButtons: any = {
    action: null,
    cancel: null,
  };

  public systemInteraction: string = "settings_media";

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private _mediaManagementService: MediaManagementServicesService,
    private _clientManagementService: ClientManagementService,
    public eventSettingsManageFrame: MDBModalRef,
    public TitleCase: TitleCasePipe,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.retrieveLabels();
    this.title = `Select ${this.TitleCase.transform(
      this.labels.media.singular
    )}`;
    if (this.buttonLabel === undefined) this.buttonLabel = `Update and Return to ${this.labels.event.singular}`;

    if (this.fromLocation == "aiaFiles") {

      if (this.type === "asset_multiple") {
        this.selectableType = "multiple";
        this.systemInteraction = "settings_media_multiple";
      }
      this.type = this.subtype;

    }

    this.filterByType = this.type;

    if (this.type === "image") {
      this.title = "Select a logo image";
    } else if (this.type === "pdf") {
      this.title = "Select a PDF" + this.selectableType == "multiple" ? "s" : "";
    } else if (this.type === "video") {
      this.title = "Select a Video";
    } else if (this.type === "video_360") {
      this.title = "Select a 360 Video";
    } else if (this.type === "file") {
      this.title = "Select a File" + this.selectableType == "multiple" ? "s" : "";
    }

    this.retrieveToken();

    if (this.fromLocation == "zoneMedia") {
      this.selectedMedia.push(this.targetAsset);
    } else if (this.fromLocation == "aiaFiles") {
      this.selectedMedia = this.targetAsset.length ? this.targetAsset : [];
      console.log("this.selectedMedia", this.selectedMedia);
    } else {
      if (
        this.fromLocation == "eventProps" ||
        this.fromLocation == "manageEvents" ||
        this.fromLocation == "manageSkill"
      ) {
        this.configureExisting();
      } else {
        this.configureLegacyExisting();
      }
    }

    //this.retrieveMedia();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private configureExisting() {
    this.existingValues = {};

    let type = this.type;

    if (this.type === "video_360") type = "video";

    this.existingValues[this.type] = null;

    if (this.targetAsset) this.existingValues[this.type] = this.targetAsset;

    this.selectedMedia.push(this.existingValues[this.type]);
  }

  private configureLegacyExisting() {
    let meta = this.experience.meta;

    this.existingValues = {};
    this.existingValues[this.type] = null;

    if (
      meta !== undefined &&
      meta !== null &&
      meta[this.name] !== undefined &&
      meta[this.name] !== null &&
      meta[this.name].length
    ) {
      this.existingValues[this.type] = meta[this.name][0];
      this.existingValues[this.type].src = "";
    }

    this.selectedMedia.push(this.existingValues[this.type]);
  }

  private retrieveMedia() {
    let retrieveMedia = this._mediaManagementService
      .mediaService(this.teamID, this.token)
      .toPromise();

    retrieveMedia.then(
      (media) => {
        this.media = this.processMedia(media);
        this.media = this.parseMedia();
        this.buildForm();
      },
      (error) => {
        //@todo: handle error
      }
    );
  }

  private processMedia(incoming) {
    let media = [];

    media = incoming.assets.filter((asset) => {
      return !asset.is_preview_image;
    });

    media.forEach((thisMedia) => {
      thisMedia.loading = "loaded";
    });

    return media;
  }

  private parseMedia() {
    let parsed = {
      pdfs: [],
      images: [],
      videos: [],
    };

    let media = this.media;

    media.forEach((asset) => {
      let file_ext =
        asset.file_ext !== null ? asset.file_ext.toLowerCase() : "";

      if (file_ext === "pdf") {
        parsed.pdfs.push(asset);
      } else if (
        file_ext === "jpg" ||
        file_ext === "png" ||
        file_ext === "gif"
      ) {
        parsed.images.push(asset);
      } else if (file_ext === "mp4") {
        parsed.videos.push(asset);
      }
    });

    return parsed;
  }

  private buildForm() {
    if (this.type === "pdf") {
      let pdfOptionsGroup = [];

      this.media.pdfs.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        pdfOptionsGroup.push(thisValue);
      });

      this.pdfOptionsGroup = pdfOptionsGroup;
    }

    if (this.type === "image") {
      let imageOptionsGroup = [];

      this.media.images.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        if (
          this.existingValues.image !== null &&
          this.existingValues.image.id === thisMedia.id
        )
          this.existingValues.image.src = thisMedia.thumbnail_asset_url;

        imageOptionsGroup.push(thisValue);
      });

      this.imageOptionsGroup = imageOptionsGroup;
    }

    if (this.type === "video" || this.type === "video_360") {
      let videoOptionsGroup = [];

      this.media.videos.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        if (
          this.existingValues !== undefined &&
          this.existingValues.video !== null &&
          this.existingValues.video.id === thisMedia.id
        )
          this.existingValues.video.src = thisMedia.thumbnail_asset_url;

        videoOptionsGroup.push(thisValue);
      });

      this.videoOptionsGroup = videoOptionsGroup;
    }

    this.scheduleForm = new UntypedFormGroup({
      pdfOptionsValue: new UntypedFormControl(""),
      imageOptionsValue: new UntypedFormControl(""),
      videoOptionsValue: new UntypedFormControl(""),
    });

    this.formLoading = true;
    this.formChanges();
  }

  get imageOptionsArray() {
    return this.scheduleForm.get("imageOptionsArray") as UntypedFormArray;
  }

  get videoOptionsArray() {
    return this.scheduleForm.get("videoOptionsArray") as UntypedFormArray;
  }

  get pdfOptionsValue() {
    return this.scheduleForm.get("pdfOptionsValue") as UntypedFormArray;
  }

  get imageOptionsValue() {
    return this.scheduleForm.get("imageOptionsValue") as UntypedFormArray;
  }

  get videoOptionsValue() {
    return this.scheduleForm.get("videoOptionsValue") as UntypedFormArray;
  }

  private formChanges() {
    this.scheduleForm.get("pdfOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });

    this.scheduleForm.get("imageOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });

    this.scheduleForm.get("videoOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });
  }

  public resetMedia(type) {
    this.btnLabel.main = "Set to None";

    if (type === "pdf") {
      this.existingValues.pdf = "";
      this.existingValues.pdfSrc = "";
      this.scheduleForm.controls["pdfOptionsValue"].setValue("");
    } else if (type === "image") {
      this.existingValues.image = "";
      this.existingValues.imgSrc = "";
      this.scheduleForm.controls["imageOptionsValue"].setValue("");
    } else if (type === "video" || type === "video_360") {
      this.existingValues.video = "";
      this.existingValues.vidSrc = "";
      this.scheduleForm.controls["videoOptionsValue"].setValue("");
    }
  }

  public removeLogo() {
    this.selectedMedia = [];
    this.isClean = false;
  }

  public manageMedia() {
    let outgoing = {
      action: "update",
      media: this.mediaData,
      type: this.type,
      name: this.name,
    };
    this.outgoing.next(outgoing);
    this.closeModal();
  }

  private processParsedMediaForUpdate(incomingMedia) {
    let storedMedia = [];

    if (incomingMedia.pdfOptionsValue !== "") {
      let retrieveThisMedia = this.determineCurrentMedia(
        this.media.pdfs,
        incomingMedia.pdfOptionsValue
      );
      let thisMedia = retrieveThisMedia[0];

      let thisStoredMedia = {
        created: thisMedia.created,
        description: thisMedia.description,
        file_ext: thisMedia.file_ext,
        id: thisMedia.id,
        modified: thisMedia.modified,
        name: thisMedia.name,
        type: thisMedia.type,
        uuid: thisMedia.uuid,
      };

      storedMedia.push(thisStoredMedia);
    }

    if (incomingMedia.imageOptionsValue !== "") {
      let retrieveThisMedia = this.determineCurrentMedia(
        this.media.images,
        incomingMedia.imageOptionsValue
      );
      let thisMedia = retrieveThisMedia[0];

      let thisStoredMedia = {
        created: thisMedia.created,
        description: thisMedia.description,
        file_ext: thisMedia.file_ext,
        id: thisMedia.id,
        modified: thisMedia.modified,
        name: thisMedia.name,
        type: thisMedia.type,
        uuid: thisMedia.uuid,
      };

      storedMedia.push(thisStoredMedia);
    }

    if (incomingMedia.videoOptionsValue !== "") {
      let retrieveThisMedia = this.determineCurrentMedia(
        this.media.videos,
        incomingMedia.videoOptionsValue
      );
      let thisMedia = retrieveThisMedia[0];

      let thisStoredMedia = {
        created: thisMedia.created,
        description: thisMedia.description,
        file_ext: thisMedia.file_ext,
        id: thisMedia.id,
        modified: thisMedia.modified,
        name: thisMedia.name,
        type: thisMedia.type,
        uuid: thisMedia.uuid,
      };

      storedMedia.push(thisStoredMedia);
    }

    return storedMedia;
  }

  private determineCurrentMedia(media, media_id) {
    return media.filter((thisMedia) => {
      return thisMedia.id === media_id;
    });
  }

  public setSelected(asset) {
    this.existingValues[this.type] = asset;
    this.btnLabel.main = "Select";
    if (this.type == "image") {
      this.scheduleForm.patchValue({
        pdfOptionsValue: "",
        imageOptionsValue: asset.id,
        videoOptionsValue: "",
      });
    } else if (this.type == "pdf") {
      this.scheduleForm.patchValue({
        pdfOptionsValue: asset.id,
        imageOptionsValue: "",
        videoOptionsValue: "",
      });
    } else if (this.type == "video" || this.type == "video_360") {
      this.scheduleForm.patchValue({
        pdfOptionsValue: "",
        imageOptionsValue: "",
        videoOptionsValue: asset.id,
      });
    }
  }

  public outputSelected(asset) {
    console.log("event in outputSelected", event);

    this.mediaData = [asset];
    this.selectedMedia = [asset];
    this.isClean = false;
  }

  public onParentActions(incoming) {
    if (incoming.action !== undefined && incoming.action === "closeModal")
      this.closeModal();
  }

  public closeModal() {
    let mainEventDisplay = this.document.getElementsByClassName(
      this.parentModalClass
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("secondary");
      mainEventDisplay.item(0).classList.add("restore-primary");
    }

    this.eventSettingsManageFrame.hide();
  }
}
