import { Component, Input, ViewChild, ChangeDetectorRef } from "@angular/core";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "ng-uikit-pro-standard";

import {
  faFilter,
  faFilterCircleXmark,
  faSquareMinus,
  faPencilAlt,
  faImages,
  faUsers,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faCopy,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-reusable-table",
  templateUrl: "./reusable-table.component.html",
  styleUrls: ["./reusable-table.component.scss"],
})
export class ReusableTableComponent {
  @Input() tableLoading: any;
  @Input() errorRetrievingMsg: any;
  @Input() clientSettings: any;

  //incoming table data
  @Input() tableData: any;
  @Input() tableDataType: any;
  @Input() tableColumns: any;
  @Input() headElements: any;

  //incoming sorting
  @Input() sort_elements: any;
  @Input() sort: any;

  //incoming filtering
  @Input() filter_elements: any;
  @Input() filterText: any;
  @Input() compoundFilterParams: any;

  //local table sorting
  public sortBy = "none";
  public sortOrder = "none";

  //icons
  public faFilter = faFilter;
  public faFilterCircleXmark = faFilterCircleXmark;
  public faSquareMinus = faSquareMinus;
  public faPencilAlt = faPencilAlt;
  public faImages = faImages;
  public faUsers = faUsers;
  public faTrashAlt = faTrashAlt;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public faCopy = faCopy;
  public faCirclePlus = faCirclePlus;

  private mdbTableData: MdbTableDirective;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTableDirective, { static: false }) set Content(
    content: MdbTableDirective
  ) {
    if (content) {
      this.mdbTableData = content;
      this.mdbTableData.setDataSource(this.tableData);
    }
  }

  @ViewChild("tablePagination") mdbTablePagination: MdbTablePaginationComponent;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.paginationEnabled();
  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction, type) {
    this.sortBy = head;
    this.sortOrder = direction;

    this.sort.head = head;
    this.sort.direction = direction;
    this.sort.type = type;
  }

  /**
   * Reset specific filter ngModel filterText[filter_elements[i].target]
   * @param index
   */
  public resetFilter(index, value?) {
    if (value === undefined) value = "";

    this.filterText[this.filter_elements[index].target] = value;
  }

  paginationEnabled(): void {
    setTimeout(() => {
      if (this.mdbTablePagination !== undefined) {
        this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);
        this.mdbTablePagination.calculateFirstItemIndex();
        this.mdbTablePagination.calculateLastItemIndex();
      }
    }, 1);
    this.cd.detectChanges();
  }
}
