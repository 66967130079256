<div class="modal-header text-center">
  <h4 class="modal-title w-100">3D Object Upload</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="modalFrame.hide()"
    mdbTooltip="Close"
    placement="bottom"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body mt-3 p-0">
  <div class="row">
    <div class="col-12">
      <p class="text-center mb-0">
        <strong>Note:</strong> only GLBs currently supported
      </p>
      <dropzone
        *ngIf="!loading"
        class="dropzone dropzone-container dz-multiple dz-clickable"
        [config]="config"
        #drpzone
        fxLayout="row"
        fxLayoutAlign="start strech"
        fxFlex="auto"
        message="Click or drag GLB file here to upload."
        (init)="onUploadInit($event)"
        (error)="onUploadError($event)"
        (success)="onUploadSuccess($event)"
        max="2"
      ></dropzone>
    </div>
  </div>
  <div class="row d-flex object-processing">
    <div class="thumbnail-preview">
      <div id="previews" class="dropzone dropzone-previews"></div>
    </div>
    <div class="model-preview">
      <app-wc-model-viewer
        [modelSrc]="modelSrc"
        (generatedThumbnail)="onGeneratedThumbnail($event)"
        [action]="'upload'"
      ></app-wc-model-viewer>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="confirm-upload-btn"
      type="button"
      mdbBtn
      mdbWavesEffect
      (click)="startUpload()"
    >
      <mdb-icon fas icon="cloud-upload-alt" class="pr-1"></mdb-icon>
      Start Upload
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      (click)="modalFrame.hide()"
    >
      Close
    </button>
  </div>
</div>
<div #dzCustomPreview>
  <div class="dz-preview dz-file-preview">
    <div class="dz-image">
      <img data-dz-thumbnail />
      <mdb-icon fas icon="cube"></mdb-icon>
    </div>
    <div class="dz-details">
      <div class="dz-size"><span data-dz-size></span></div>
      <div class="dz-filename"><span data-dz-name></span></div>
    </div>
    <div class="dz-progress">
      <span class="dz-upload" data-dz-uploadprogress></span>
    </div>
    <div class="dz-error-message"><span data-dz-errormessage></span></div>
    <div class="dz-success-mark">
      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.2071 29.7929L14.2929 25.7071C14.6834 25.3166 15.3166 25.3166 15.7071 25.7071L21.2929 31.2929C21.6834 31.6834 22.3166 31.6834 22.7071 31.2929L38.2929 15.7071C38.6834 15.3166 39.3166 15.3166 39.7071 15.7071L43.7929 19.7929C44.1834 20.1834 44.1834 20.8166 43.7929 21.2071L22.7071 42.2929C22.3166 42.6834 21.6834 42.6834 21.2929 42.2929L10.2071 31.2071C9.81658 30.8166 9.81658 30.1834 10.2071 29.7929Z"
        />
      </svg>
    </div>
    <div class="dz-error-mark">
      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.2929 20.2929L19.2071 13.2071C18.8166 12.8166 18.1834 12.8166 17.7929 13.2071L13.2071 17.7929C12.8166 18.1834 12.8166 18.8166 13.2071 19.2071L20.2929 26.2929C20.6834 26.6834 20.6834 27.3166 20.2929 27.7071L13.2071 34.7929C12.8166 35.1834 12.8166 35.8166 13.2071 36.2071L17.7929 40.7929C18.1834 41.1834 18.8166 41.1834 19.2071 40.7929L26.2929 33.7071C26.6834 33.3166 27.3166 33.3166 27.7071 33.7071L34.7929 40.7929C35.1834 41.1834 35.8166 41.1834 36.2071 40.7929L40.7929 36.2071C41.1834 35.8166 41.1834 35.1834 40.7929 34.7929L33.7071 27.7071C33.3166 27.3166 33.3166 26.6834 33.7071 26.2929L40.7929 19.2071C41.1834 18.8166 41.1834 18.1834 40.7929 17.7929L36.2071 13.2071C35.8166 12.8166 35.1834 12.8166 34.7929 13.2071L27.7071 20.2929C27.3166 20.6834 26.6834 20.6834 26.2929 20.2929Z"
        />
      </svg>
    </div>
  </div>
</div>
