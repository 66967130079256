import { Component, OnInit } from "@angular/core";
import { CoolLocalStorage } from "@angular-cool/storage";
import { ActivatedRoute } from "@angular/router";
import {
  MDBModalRef,
  MDBModalService,
  MDBBootstrapModulesPro,
} from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ArchiveInventoryModalComponent } from "./modals/archive-inventory-modal/archive-inventory-modal.component";
import { AddInventoryModalComponent } from "./modals/add-inventory-modal/add-inventory-modal.component";
import { EditInventoryModalComponent } from "./modals/edit-inventory-modal/edit-inventory-modal.component";
import { ViewOptionsModalComponent } from "./modals/view-options-modal/view-options-modal.component";
@Component({
  selector: "app-inventory-management",
  templateUrl: "./inventory-management.component.html",
  styleUrls: ["./inventory-management.component.scss"],
})
export class InventoryManagementComponent implements OnInit {
  public enableItems: boolean = false;
  public enableColumns: boolean = true;
  public enableOptions: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private modalService: MDBModalService
  ) {}

  public loading: boolean = true;
  public columnsLoading: boolean = true;
  public optionsLoading: boolean = true;
  public copy: string;

  public teamID: number;
  public token: string;

  public items: any;
  public columns: any;
  public options: any;

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-add-room",
    containerClass: "",
    animated: true,
    data: {},
  };

  ngOnInit(): void {
    this.loading = true;
    this.retrieveToken();
    this.retrieveTeamID();

    if (this.enableItems) {
      this.getItems();
    }
    if (this.enableColumns) {
      this.getColumns();
    }
    if (this.enableOptions) {
      this.getOptions();
    }
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private getItems() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let inventoryItemRequest = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/inventories",
      "get",
      data,
      getOptions
    );
    inventoryItemRequest.subscribe((params) => {
      ;
      this.items = params.inventories;
      this.loading = false;
    });
  }

  private getColumns() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};
    let inventoryOptionsRequest = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/inventory/columns",
      "get",
      data,
      getOptions
    );
    inventoryOptionsRequest.subscribe((params) => {
      ;
      this.columns = params.columns;
      this.columnsLoading = false;
    });
  }

  private getOptions() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let inventoryOptionsRequest = this._xrPlatformRestService.restfulAPIQuery(
      "/inventory/options",
      "get",
      data,
      getOptions
    );
    inventoryOptionsRequest.subscribe((params) => {
      this.options = params.inventories;
      this.optionsLoading = false;
    });
  }

  public openAddInventoryEntityModal(entityType: any) {
    this.modalOptions.data = {
      team_id: this.teamID,
      type: entityType,
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      AddInventoryModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.updateEntities("add", entityType, 1, changedData);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openEditInventoryModal(
    entity: any,
    entityType: string,
    index: number
  ) {
    this.modalOptions.data = {
      team_id: this.teamID,
      entity: entity,
      type: entityType,
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      EditInventoryModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            this.updateEntities("update", entityType, index, changedData.item);
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openArchiveInventoryModal(
    entity: any,
    entityType: string,
    index: number
  ) {
    this.modalOptions.data = {
      team_id: this.teamID,
      entity: entity,
      entityType: entityType,
    };

    this.modalOptions.class = "";

    this.modalFrame = this.modalService.show(
      ArchiveInventoryModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            break;
          case "delete":
            ;
            this.updateEntities("remove", entityType, index);
            break;
        }
      } else {
      }
    });
  }

  public openViewItemOptionsModal(item: any) {
    this.modalOptions.data = {
      team_id: this.teamID,
      item: item,
    };
    this.modalOptions.class = "modal-fluid";
    this.modalFrame = this.modalService.show(
      ViewOptionsModalComponent,
      this.modalOptions
    );
  }

  private updateEntities(
    action: string,
    entityType: string,
    index?: number,
    entity?
  ) {
    if (entityType == "item") {
      if (action == "remove") {
        ;
        this.items.splice(index, 1);
      } else if (action == "add") {
        ;
        this.items.push(entity.item);
      } else if (action == "update") {
        this.items[index] = entity;
      }
    } else if (entityType == "option") {
      this.options.splice(index, 1);
    }
  }
}
