<div class="modal-header text-center">
  <h4 class="modal-title w-100">Update Thumbnail</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="modalFrame.hide()"
    mdbTooltip="Close"
    placement="bottom"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body mt-3 p-0">
  <div class="row d-flex object-processing">
    <div class="thumbnail-preview" *ngIf="thumbnailSrc !== null">
      <img [src]="thumbnailSrc" class="img-fluid" alt="Thumbnail Preview" />
    </div>
    <div class="thumbnail-missing" *ngIf="thumbnailSrc === null">
      <p class="text-center mb-0">
        <strong>Note:</strong> no thumbnail found, please click "Change
        Thumbnail" to create a thumbnail.
      </p>
    </div>
    <div class="model-preview">
      <app-wc-model-viewer
        *ngIf="modelLoaded"
        [modelSrc]="modelSrc"
        (generatedThumbnail)="onGeneratedThumbnail($event)"
        [action]="'update'"
      ></app-wc-model-viewer>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="confirm-upload-btn"
      type="button"
      mdbBtn
      mdbWavesEffect
      (click)="uploadThumbnail(media_item)"
    >
      <mdb-icon fas icon="cloud-upload-alt" class="pr-1"></mdb-icon>
      Update Thumbnail
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      (click)="modalFrame.hide()"
    >
      Close
    </button>
  </div>
</div>
