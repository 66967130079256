import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { ClientManagementService } from 'src/app/services/utilities/client-management.service';
import { environment } from 'src/environments/environment';
import { MediaManagementServicesService } from '../../media-management/services/media-management-services.service';

@Injectable({
  providedIn: 'root'
})
export class EventMediaVersion3Service {
  public errorObj: BehaviorSubject<any> = new BehaviorSubject(false);

  private proxyURL: string = environment.proxyURL;
  private restURL: string = this._clientManagementService.getRESTurl();

  constructor(
    private _mediaManagementService: MediaManagementServicesService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    private http: HttpClient
  ) { }

  /**
   * Retrieves client media and media attached to an event of given schedule_id (i.e. the event ID)
   * @param teamID - ID of the current client
   * @param token - user token for making backend requests
   * @param schedule_id - i.e. event ID
   * @returns an object with the following properties
   * @prop status - currently just returns "success", but in the future we can update this to handle errors as well
   * @prop media - the client media
   * @prop attached - the media attached to this event
  */
  public async retrieveMedia(
    teamID: number,
    token: string,
    eventId: number,
    retrieveAllMedia?: boolean
  ): Promise<{ status: string; media: any; attached: any }> { //we need this part to make TypeScript happy

    if (retrieveAllMedia === undefined) retrieveAllMedia = true;

    let media = [];
    if (retrieveAllMedia) {
      /**
       * Calls async request @method MediaManagementService::mediaService(), and waits for response
       * Assigns response to local @var retrieveMedia
       */
      let retrieveMedia = await this._mediaManagementService
        .mediaService(teamID, token)
        .toPromise();

      /** After response from above, processes incoming media via @method processMedia() and returns to local @var media */

      let media = this.processMedia(retrieveMedia);
    }

    /**
     * Calls async request @method retrieveAttachedMedia(), and waits for response
     * Assigns respone to local @var attached
     */
    let attached = await this.retrieveAttachedMediaByEvent(token, eventId);
    //let attached = await this.retrieveAttachedMedia(token, schedule_id);

    /**
     * After response from above, adds local @var media and @var attached to an outbound object @var outbound that also includes @prop status
     */
    let outbound = {
      status: "success",
      media: media,
      attached: attached,
    };

    /** resolves promise and returns @var outbound */
    return Promise.resolve(outbound);
  }

  /* Process Media Definition */
  private processMedia(incoming) {
    let media = [];


    media = incoming.assets.filter((asset) => {
      return !asset.is_preview_image;
    });

    media.forEach((thisMedia) => {
      thisMedia.loading = "loaded";
    });

    return media;
  }
  /**
 * Configures async call to @method XrPlatformRestService::retrieveEntityData()
 * This is now a promise so we can use it in an async/await in @method retrieveMedia()
 * @param token 
 * @param schedule_id 
 * @returns Promise
 */
  public retrieveAttachedMediaByEvent(token, eventId): Promise<any> {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    const options = {
      headers: headers,
    };
    let retrieveAttachedMediaByEvent = this._xrPlatformRestService.restfulAPIQuery(
      "/event/" + eventId + "/assets",
      "get",
      {},
      options
    );

    return retrieveAttachedMediaByEvent.toPromise();
  }

  public async manageMedia(token, targetEvent, assets): Promise<{ status: string; attached: any }> {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    console.log("targetEvent in manageMedia", targetEvent);

    let manageEventMedia = this._xrPlatformRestService.restfulAPIQuery(
      "/event/" + targetEvent.schedule_id + "/assets",
      "post",
      { assets: assets },
      { headers: headers }
    ).toPromise();

    let outbound = {
      status: "success",
      attached: manageEventMedia,
    };

    /** resolves promise and returns @var outbound */
    return Promise.resolve(outbound);
  }

  public async manageMediaLegacy(token, targetEvent, scheduleForm, media, mediaLabel, attachedList): Promise<any> {
    //handling user output
    //@todo: update to use app-status-message

    const errorMsgObj = {
      errorMsg: "",
      statusMsg: `<span class='loading-msg'>Updating ${mediaLabel}</span>`,
      formState: "processing"
    }

    this.errorObj.next(errorMsgObj);

    //constants for holding header and option data for outbound requests
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const options = {
      headers: headers,
      schedule_id: targetEvent.schedule_id,
    };

    //key local vars
    //Stores the incoming values from the media-to-event form (which actually includes all of the media items)
    let incomingValues = scheduleForm.value;
    //An array to store media that should be removed from this event
    let removals = [];
    //An array to store media that should be added to this event as invitees
    let invitees = [];
    /**
     * An array to track what media items need meta updates
     * Note: this is for updating the meta field on the *media* item, not on the invitee item (see below)
     * In V2 of media-to-events, we don't have a direct relationship between media and events, and therefore we don't have an easy way to track which events media is attached to.
     * So we currently use the media meta to track what events a media item is attached to, which is important for things like removing a media item, were we also need to know which events to remove it from.
     */
    let mediaToUpdate = [];

    console.log(
      "incomingValues.myOptionsArray in manageMedia",
      incomingValues.myOptionsArray
    );

    //here we loop through the incominValues, i.e. all of the media items on the media-to-event form
    incomingValues.myOptionsArray.forEach((thisMedia) => {
      //continue with anyone that needs to be added
      ;

      //adds this new invitee object to the array of invitees to add, i.e. media to add to the is event
      if (thisMedia.selected) {
        invitees.push(thisMedia);
        mediaToUpdate.push({
          action: "add",
          item: thisMedia,
        });
      } else {
        removals.push(thisMedia);
        mediaToUpdate.push({
          action: "remove",
          item: thisMedia,
        });
      }
    });

    //configuring the invitees into a body string
    let addBody =
    {
      assets: []
    }
    invitees.forEach(item => {
      addBody.assets.push({ 'asset_id': item.id })
    })

    //configure the body object for the removal request
    let body = {
      assets: []
    };
    removals.forEach(item => {
      body.assets.push(item.id);
    })

    //our first step is to handle the removal of media items from the event (if there any to remove)
    //@todo: rearchitect into an async/await structure and use a conditional to only run removal if the local @var removals is not empty
    if (removals.length > 0) {
      let removeMedia = await this._xrPlatformRestService.scheduledExperiences(
        "schedule-assets-remove",
        body,
        options
      ).toPromise();
    }

    //after running the remove process, we then media items to this event (if there are any to add)
    //@todo: rearchitect into an async/await structure and use a conditional to run only if there are media items to add to this event
    let attachMedia = await this._xrPlatformRestService.scheduledExperiences(
      "schedule-assets",
      addBody,
      options
    ).toPromise();

    /** Local @var mediaToUpdate tells us we have media items that need their meta fields updated (see above) */
    if (mediaToUpdate.length) {

      /**If local @var mediaToUpdate is not empty, we kick start the process to update media meta via @method getMediaMeta() */
      //let mediaData= await this.getMediaMeta(mediaToUpdate, openExtraSpaceModal, attachMedia, mediaLabel)
      //return Promise.resolve(mediaData);
    } else {

      /**If local @var mediaToUpdate is empty, we show a success message and move on to the @method finalActions() */
      const errorMsgObj = {
        errorMsg: "",
        statusMsg: `${mediaLabel
          } successfully added.`,
        formState: "processing"
      }

      this.errorObj.next(errorMsgObj);

    }
    return attachMedia;
  }


  private determineCurrentMedia(media, media_id) {
    return media.filter((thisMedia, index) => {
      thisMedia.current_index = index;
      return thisMedia.id === media_id;
    });
  }

  public async getMediaItems(items, token): Promise<{ status: string; media: any; }> {

    let fullMediaItems = [];

    let waiting = await Promise.all(
      items.map(async (media) => {
        ;

        const response = await this.getMediaItem(media.uuid, token).catch(
          (incomingErr) => {
            let msg =
              incomingErr.error !== undefined &&
                incomingErr.error.error !== undefined
                ? incomingErr.error.error
                : "";

            ;

            if (msg.indexOf("asset.deleted")) {
              let toRemove = {
                id: media.id,
                uuid: media.uuid,
                status: "toRemove",
              };

              ;

              fullMediaItems.push(toRemove);
            }
          }
        );
        ;
        if (response !== undefined) fullMediaItems.push(response);
      })
    );

    let outbound = {
      status: "success",
      media: fullMediaItems,
    };

    /** resolves promise and returns @var outbound */
    return Promise.resolve(outbound);

  }

  private getMediaItem(uuid, token) {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    const httpOptions = {
      headers: headers,
    };

    let getMediaItem = `${this.proxyURL}${this.restURL}/asset/${uuid}`;

    return this.http.get(getMediaItem, httpOptions).toPromise();

  }


}
