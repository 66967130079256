import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, ElementRef, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-add-team-experience-modal",
  templateUrl: "./add-team-experience-modal.component.html",
  styleUrls: ["./add-team-experience-modal.component.scss"],
})
export class AddTeamExperienceModalComponent implements OnInit {
  //incoming
  public teamExperiences;
  public teamID: number;

  public experiences: any = [];
  public experiences_names: any = [];

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another Experience",
    retry: "Retry",
  };

  public eventPropForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Add Event Prop to client",
  };
  public formState: string = "active";

  public continueType: string = "reset";

  public experiencesForm = this.formBuilder.group({
    experiences_name: "",
  });
  public experiencesFormGroup: UntypedFormGroup;

  searchText = new Subject();
  results: Observable<string[]>;

  public token: string;

  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    public experienceFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private _settingsService: SettingsService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.experiencesFormGroup = this.experiencesForm;
    this.retrieveExperiences();
    this.results = this.searchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value))
    );
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private filter(value: string): string[] {
    if (value === null || value === undefined || value === "") {
      return this.experiences_names;
    }

    const filterValue = value.toLowerCase();
    let filtered = this.experiences_names.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }

  public retrieveExperiences() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/experiences",
      "get",
      data,
      getOptions
    );

    eventProps.subscribe((response) => {
      this.experiences = response.experiences;
      this.processExperiences();
      this.experiences.forEach((element) => {
        this.experiences_names.push(element.label);
      });
      this.results = this.searchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value))
      );
    });
  }

  private processExperiences() {
    let temp_experiences = [];
    this.experiences.forEach((element) => {
      let index = this.teamExperiences.findIndex((x) => {
        return x.experience.id === element.id;
      });

      if (index == -1) {
        temp_experiences.push(element);
      }
    });

    this.experiences = temp_experiences;
  }

  public addExperience() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      "<span class='processing-msg'>Adding Experience</span>";
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let addedEventProp = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" +
        this.teamID +
        "/experience/" +
        this.getExperienceIdFromLabel(
          this.experiencesForm.value.experiences_name
        ),
      "post",
      data,
      getOptions
    );

    addedEventProp.subscribe(
      (response) => {
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Experience successfully added";
        if (response.error != 418) {
          ;
          let outgoingData = {
            action: "add",
            experience: response.experiences_team_rel,
          };
          this.outgoing.next(outgoingData);
        }

        //remove experience from experiences_names
        this.experiences_names = this.experiences_names.filter(
          (item) => item !== this.experiencesForm.value.experiences_name
        );
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  private getExperienceIdFromLabel(label: string) {
    let index = this.experiences.findIndex((x) => x.label === label);
    ;
    ;
    return this.experiences[index].id;
  }

  public resetForm() {
    this.experiencesForm.reset();

    //reset searchtext
    this.results = this.searchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value))
    );

    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.experienceFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
