<app-reusable-table
  [tableLoading]="tableLoading"
  [errorRetrievingMsg]="errorRetrievingMsg"
  [clientSettings]="clientSettings"
  [tableData]="tableData"
  [tableDataType]="tableDataType"
  [tableColumns]="tableColumns"
  [headElements]="headElements"
  [sort_elements]="sort_elements"
  [sort]="sort"
  [filter_elements]="filter_elements"
  [filterText]="filterText"
  [compoundFilterParams]="compoundFilterParams"
></app-reusable-table>