import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { EventMediaVersion3Service } from "src/app/modules/event-management/services/event.media.version.3.service";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
@Component({
  selector: 'app-user-settings-manage-media',
  templateUrl: './user-settings-manage-media.component.html',
  styleUrls: ['./user-settings-manage-media.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserSettingsManageMediaComponent implements OnInit {
//persistent
public media: any;
public token: string;
public loading: boolean = true;

//incoming
public teamID;
public type;
public incomingMedia;

//content
public title = "Select Media";

//data back to parent
private outgoing: Subject<any> = new Subject();

//existing values
public existingValues: {
  pdf: any;
  image: any;
  pdfSrc: string;
  imgSrc: string;
} = {
  pdf: "",
  image: "",
  pdfSrc: "",
  imgSrc: "",
};

public actionButtons: {
  info: boolean,
  share: boolean,
  delete: boolean
} = {
  info: false,
  share: false,
  delete: false
}

public columnVisibility: {
  thumbnail: boolean,
  pdfStatus: boolean,
  fileType: boolean,
  owner: boolean,
  urlType: boolean,
  dateCreated: boolean,
  shares: boolean
} = {
  thumbnail: true,
  pdfStatus: false,
  fileType: true,
  owner: true,
  urlType: false,
  dateCreated: true,
  shares: false
}

public selectable: boolean = true;

  public filterByType: string = "image";

  public selectableType: string = "single";

  public selectedMedia: any[] = [];

  public clickableThumb: boolean = false;

  public systemButtons: any = {
    "action": "Select",
    "cancel": "Cancel"
  };

  public systemInteraction: string = "settings_media";

constructor(
  public mediaGroupFrame: MDBModalRef,
  private eventMediaVersion3Service: EventMediaVersion3Service,
  private coolLocalStorage: CoolLocalStorage
) {}

ngOnInit() {
  this.title = `Select ${this.type}`;
  
  this.retrieveToken();
  this.configureExisting();
}

private configureExisting() {
  if (this.incomingMedia === null) return false;

  if (
    this.incomingMedia.image !== undefined &&
    this.incomingMedia.image !== null
  ) {
    this.existingValues.image = this.incomingMedia.image;

    let items = [this.incomingMedia.image];

    //need to retrieve the entire image for preview purposes
    let getFullMediaItems = this.eventMediaVersion3Service.getMediaItems(items, this.token);

    getFullMediaItems.then((res) => {

      this.selectedMedia = res.media;
      this.loading = false;

    });

  }

  if (
    this.incomingMedia.pdf !== undefined &&
    this.incomingMedia.pdf !== null
  ) {
    this.existingValues.pdf = this.incomingMedia.pdf;
  }

  this.loading = false;
}

private retrieveToken() {
  this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
}

public outputSelected(event) {
  let outgoing = {
    media: [event],
    type: this.type,
  };

  this.outgoing.next(outgoing);
  this.mediaGroupFrame.hide();
}

public onParentActions(incoming){

  if(incoming.action !== undefined && incoming.action === "closeModal"){

    this.mediaGroupFrame.hide();

  }

}

}

