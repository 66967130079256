import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { CoolLocalStorage } from "@angular-cool/storage";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-add-class-room",
  templateUrl: "./add-class-room.component.html",
  styleUrls: ["./add-class-room.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe]
})
export class AddClassRoomComponent implements OnInit {
  constructor(
    public addclassroom: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder,
    private TitleCase: TitleCasePipe
  ) {}

  //incoming
  private teamID: number;
  public event: any;
  public targetRoom: any;
  public action: string;
  public labels: any;
  public token: string;
  private current_room: number;
  public room_names = [];
  private rooms: any;

  //copy
  public title: string;

  public roomForm = this.formBuilder.group({
    room_name: "",
  });
  public roomFormGroup: UntypedFormGroup;

  searchText = new Subject();
  results: Observable<string[]>;

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
    main: string;
  } = {
    close: "Close",
    reset: "Add Another Room",
    retry: "Retry",
    main: "",
  };

  //background handling
  public confirmOn = false;

  private outgoing: Subject<any> = new Subject();
  private outgoingData: any;

  ngOnInit(): void {
    this.continueType = "none";

    if (this.action === undefined) this.action === "add";    
    this.retrieveLabels();
    this.retrieveToken();
    this.btnLabel.main = "Add " + this.TitleCase.transform(this.labels.room.singular);
    this.handleCopy();    
    if (this.action === "add") {
      this.roomFormGroup = this.roomForm;
      this.getRooms();
      this.results = this.searchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value))
      );
    }
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }
  
  private handleCopy() {
    this.title = "Add "+ this.TitleCase.transform(this.labels.room.singular);
    this.btnLabel.reset = "Add Another " + this.TitleCase.transform(this.labels.room.singular);

    if (this.action === "remove") {
      this.title = "You are about to remove this "+this.labels.room.singular;
      this.btnLabel.main = "Remove "+this.labels.room.singular;
    }
  }
 
  private getRooms() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let rooms = this._xrPlatformRestService.getRooms(this.teamID, getOptions);
    rooms.subscribe((response) => {
      ;
      if (response.error != 418) {
        ;
        this.rooms = response.Rooms;
        response.Rooms.forEach((element) => {
          this.room_names.push(element.name);
        });
        this.results = this.searchText.pipe(
          startWith(""),
          map((value: string) => this.filter(value))
        );
      }
      (error) => {
        //@todo: handle error
      }
    });
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.room_names.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }

  public roomActions() {
    if (this.action === "add") {
      this.clickAddRoom();
    } else {
      this.removeRoom();
    }
  }

  public clickAddRoom() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = `<span class='processing-msg'>Adding ${this.TitleCase.transform(this.labels.room.singular)}</span>`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
      room_id: this.getRoomIdFromName(this.roomForm.value.room_name),
    };
    let addedClassRoom = this._xrPlatformRestService.addRoomEvent(
      this.event.id,
      data,
      getOptions
    );
    addedClassRoom.subscribe(
      (response) => {
        ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.room.singular)} successfully added`;

        if (response.error != 418) {
          ;
          let outgoingData = {
            action: "add",
            value: response.room_values,
          };
          this.outgoing.next(outgoingData);
        }
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  private removeRoom() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      `<span class='processing-msg'>Removing ${this.TitleCase.transform(this.labels.room.singular)}</span>`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      body: {},
    };

    let entityID = `${this.event.id}/room/${this.targetRoom.id}`;

    let removeRoom = this._xrPlatformRestService.manageEntity(
      "schedule",
      entityID,
      "remove",
      options,
      headers
    );

    removeRoom.subscribe(
      (response) => {
        ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.room.singular)} successfully removed`;

        if (response.error != 418) {
          ;
          let outgoingData = {
            action: "delete",
            value: this.targetRoom,
          };
          this.outgoing.next(outgoingData);
        }
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  private getRoomIdFromName(roomname: string) {
    let index = this.rooms.findIndex((x) => x.name === roomname);
    return this.rooms[index].id;
  }

  public turnConfirmOn(frame) {
    this.confirmOn = true;
    frame.show();
  }

  public closeConfirm(frame) {
    this.confirmOn = false;
    frame.hide();
  }

  public closeAllModals(frame) {
    this.confirmOn = false;
    frame.hide();
    this.addclassroom.hide();
  }

  public resetForm() {
    this.roomForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    ;

    switch (action) {
      case "close-modal":
        this.addclassroom.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
