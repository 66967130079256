import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

import * as moment from "moment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  @Input("clientCode") clientCode: string;
  public settings: any;
  public copyright: string = "The Glimpse Group";
  public year: string = moment().format("YYYY");

  constructor(private _xrPlatformRestService: XrPlatformRestService) {}

  ngOnInit() {
  }
}
