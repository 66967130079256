 <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">
      <mdb-icon fas icon="users-cog"></mdb-icon>Manage {{labels.userGroupMember.plural|titlecase}}
    </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="manageGroupMembers.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body media-modal m-3 p-0">
  <table *ngIf="groupedUsersCount > 0" class="table table-striped table-hover table-sm" 
    mdbTable #tableEl="mdbTable"
    mdbTableScroll
    scrollY="true"
    maxHeight="200"
    hover="true"
    striped="true">
    <thead class="sticky-top theme-base-medium-dark white-text">
      <tr>
        <th *ngFor="let head of groupedUsersTableHeaders" scope="col">
          {{ head }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr mdbTableCol *ngFor="let user of group.members">
        <td>{{ user.first_name }}</td>
        <td>{{ user.last_name }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.username }}</td>
        <td>
          
          <button
          mdbBtn
          mdbWavesEffect
          type="button"
          color="link"
          (click)="openUsersModal(group, user, 'remove')"
          class="unshare-link m-0 p-0"
        >
          remove
        </button>
         
        </td>
      </tr>
    </tbody>
  </table>
  <p *ngIf="groupedUsersCount === 0">No users are yet added to this group.</p>
</div>
<div class="modal-footer">
<button mdbBtn mdbWavesEffect type="button" size="md" color="link" data-dismiss="modal"
        (click)="manageGroupMembers.hide()">
        close
      </button>
</div>
