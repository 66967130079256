<div class="modal-header text-center">
  <h4 class="modal-title w-100">Confirm</h4>
</div>
<div class="modal-body">
  <p>
    <strong>Are you sure?</strong>
  </p>
  <p>All form fields will be cleared upon exit.</p>
</div>
<div class="modal-footer">
  <button
    mdbBtn
    type="button"
    mdbWavesEffect
    class="theme-modal-button btn"
    (click)="this.closeConfirm()"
  >
    Continue Editing
  </button>
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    size="md"
    color="link"
    (click)="this.closeAllModals()"
  >
    Close Without Saving
  </button>
</div>
