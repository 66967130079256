<app-side-menu></app-side-menu>
<app-menu [control]="true"></app-menu>
<mdb-card class="client-settings content-card">
  <mdb-tabset
    #staticTabs
    [buttonClass]="'classic-tabs'"
    class="admin-tab-styles sessions-breackdown-tabs"
    [contentClass]="'remove-card'"
  >
    <mdb-tab heading="Versions" (select)="tabSelected('versions')">
      <app-versions
        *ngIf="!loading"
        [data]="data"
        [opts]="opts"
        [clientSettings]="clientSettings"
        (outgoing)="onDataChange($event)"
      ></app-versions>
    </mdb-tab>
    <mdb-tab heading="Device Types" (select)="tabSelected('device-types')">
      <app-device-types
        *ngIf="!loading"
        [data]="data"
        [opts]="opts"
        [clientSettings]="clientSettings"
        (outgoing)="onDataChange($event)"
      ></app-device-types>
    </mdb-tab>
    <mdb-tab heading="App Bundles" (select)="tabSelected('bundles')">
      <app-bundles
        *ngIf="!loading"
        [data]="data"
        [opts]="opts"
        [clientSettings]="clientSettings"
        (outgoing)="onDataChange($event)"
      ></app-bundles>
    </mdb-tab>
  </mdb-tabset>
</mdb-card>
