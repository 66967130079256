import { CoolLocalStorage } from "@angular-cool/storage";
import { TitleCasePipe } from "@angular/common";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  ElementRef,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { AddTeamPropModalComponent } from "../add-team-prop-modal/add-team-prop-modal.component";
import { SelectMetaMediaComponent } from "../select-meta-media/select-meta-media.component";
import { EventSettingsManageMediaComponent } from "src/app/modules/event-management/modals/event-settings-manage-media/event-settings-manage-media.component";
@Component({
  selector: "app-experience-meta",
  templateUrl: "./experience-meta.component.html",
  styleUrls: ["./experience-meta.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe]
})
export class ExperienceMetaComponent implements OnInit, AfterViewInit {
  //persistent
  private token: string;
  public mainModal: any;
  public clientSettings: any;
  public labels: any

  //incoming
  public teamID: number;
  public targetEvent: any;
  public action: string;
  public metaType: string;

  //feedback response
  public title = "";
  public icon = "";
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "",
  };
  public continueType: string;

  //form settings
  public metaForm: UntypedFormGroup;
  public formLoading: boolean = false;
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Update",
    reset: "",
    retry: "Retry",
  };
  public formOptions: any;
  public formState: string = "active";
  public confirmOn = false;
  public preSelected: {
    description: string;
    number_of_seats: number;
    protocol: number;
    logo: any;
    pdf: any;
    video: any;
    building: string;
    initial_pdf: string;
    initial_video: string;
    display_name: string;
    chimera_environment: string;
    photon_id: string;
    event_type: string;
  } = {
    description: null,
    number_of_seats: null,
    protocol: null,
    logo: null,
    pdf: null,
    video: null,
    building: "",
    initial_pdf: "",
    initial_video: "",
    display_name: "",
    chimera_environment: "",
    photon_id: "",
    event_type: null,
  };
  public existingMedia: {
    logo: any;
    pdf: any;
    video: any;
  } = {
    logo: null,
    pdf: null,
    video: null,
  };

  //for event type (not related to Event Type API)
  public selectEventTypes: any[];
  public selectEventTypeLabel: any = null;
  public defaultType: any = null;

  public mediaFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  //start location code
  public buildingSelect: { value: number; label: string }[] = [];
  public optionSelected = false;
  //end location code

  //media
  public thumbnails: {
    logo: string;
    pdf: boolean;
    video: string;
  } = {
    logo: null,
    pdf: false,
    video: null,
  };

  //Event Props API
  public props: any = [];
  public showProps: boolean = false;
  public propsLoading: boolean = true;

  public addPropFrame: MDBModalRef;

  // placeholders
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpeg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";
  public activeHolderWebp: string = "assets/img/webp-thumbnail-selected.png";
  public activeHolderPdf: string = "assets/img/pdf-thumbnail-selected.png";
  public activeHolderMp4: string = "assets/img/mp4-thumbnail-selected.png";

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private _clientManagementService: ClientManagementService,
    private _mediaManagementService: MediaManagementServicesService,
    public experienceMetaFrame: MDBModalRef,
    private modalService: MDBModalService,
    private elementRef: ElementRef,
    private _settingsService: SettingsService,
    private TitleCase: TitleCasePipe
  ) {}

  ngOnInit(): void {
    ;
    this.continueType = "continue";
    if(this.metaType !== "building") {
      this.clientSettings = this._settingsService.getSettingsFromStorage(
        this.teamID
      );

      this.props = this.targetEvent.props
      this.propsLoading = false;
      //this.retrieveEventProps();
    }
    else { 
      this.clientSettings = this._clientManagementService.getClientSettings(
        this.teamID
      );
    }

    let clientSettingsLegacy = this._clientManagementService.getClientSettings(this.teamID);

    this.formOptions = clientSettingsLegacy.experienceMetaOptions;

    ;
    

    if (this.metaType === undefined) this.metaType = "default";

    ;

    this.retrieveLabels();
    this.title=this.labels.event.singular+" Settings";
    this.msgs.actionMsg="Update settings for this "+this.labels.event.singular;
    this.retrieveToken();
    this.retrieveExperienceMeta();

    this._mediaManagementService.updateMediaMetaDone$.subscribe((data: any) => {
      ;

      //@todo: need to add error handling here
      this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.event.singular)} ${this.targetEvent.name} successfully updated`;
      this.btnLabel.retry = "Continue editing";
    });
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveExperienceMeta(incoming?) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    if (incoming === undefined) incoming = null;

    let retrieveExperience = this._xrPlatformRestService.retrieveEntityData(
      "schedule",
      this.targetEvent.schedule_id,
      options
    );

    retrieveExperience.subscribe(
      (response) => {
        let scheduled_event = response.scheduled_event;
        console.log(
          "scheduled_event in retrieveExperienceMeta",
          scheduled_event
        );

        this.targetEvent.meta = scheduled_event.meta;

        if (incoming !== null) {
          this.updateMeta(scheduled_event, incoming);
        } else {

          //start location code
          if (this.metaType === "building") {
            this.icon = "cogs";
            this.msgs.actionMsg =
              this.action === "add"
                ? `Add a ${this.labels.building.singular} to this ${this.labels.event.singular}`
                : `Update the ${this.labels.building.singular} for this ${this.labels.event.singular}`;
            this.makeBuildingFormGroup(scheduled_event);
          //end location code
          } else {
            this.makeFormValidatingGroup(scheduled_event);
          }
        }
      },
      (err) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public retrieveEventProps() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {
    };

    

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      '/team/' + this.teamID + '/schedule/' + this.targetEvent.id + '/props',
      'get',
      data,
      getOptions
    );

    eventProps.subscribe((response) => {
      
      this.props = response.props
      this.propsLoading = false;
      //this.processEventProps();
    })  
  }

  private processEventProps() {
    let temp_props = []
    this.props.forEach(element => {
      let prop = element.props
      if(prop.constrained == false) {
        if(prop.type == 'boolean' && element.constrained_value == 'false') {
          prop.unconstrained_default = false
        }
        else if(prop.type == 'boolean' && element.unconstrained_default == 'true') {
          prop.unconstrained_default = true
        }
        temp_props.push(prop)
      }
      else {
        let temp_prop = element.props;
        if(prop.type == 'select_list') {
          prop.options.forEach(option => {
            if(option.is_default == true) {
              temp_prop.default_value = option.id
              temp_props.push(temp_prop)
            }
          });
        }
        else if(prop.type == 'multi_select_list') {
          let defaults = []
          prop.options.forEach(option => {
            if(option.is_default == true) {
              defaults.push(option.id)
            }
          });
          
          prop.default_value = defaults
          temp_props.push(prop)
        }
        else {
          temp_props.push(prop)
        }
      }
    });

    this.props = temp_props;
  }

  private buildPreselected(scheduled_event) {
    let meta: any = {
      description: null,
      number_of_seats: null,
      protocol: null,
      logo: null,
      pdf: null,
      video: null,
      initial_pdf: null,
      inital_video: null,
      display_name: null,
      chimera_environment: null,
      photon_id: null,
      event_type: null,
    };

    if (scheduled_event.meta !== undefined && scheduled_event.meta !== null)
      meta = scheduled_event.meta;

    this.preSelected.description =
      meta.description !== null ? meta.description : null;
    this.preSelected.number_of_seats =
      meta.number_of_seats !== null ? meta.number_of_seats : null;
    this.preSelected.protocol = meta.protocol !== null ? meta.protocol : null;
    this.preSelected.logo =
      meta.logo !== null && meta.logo !== undefined ? meta.logo : null;
    this.preSelected.pdf =
      meta.pdf !== null && meta.pdf !== undefined ? meta.pdf : null;
    this.preSelected.video =
      meta.video !== null && meta.video !== undefined ? meta.video : null;
    this.preSelected.initial_pdf =
      meta.initial_pdf !== null && meta.initial_pdf !== undefined
        ? meta.initial_pdf
        : null;
    this.preSelected.initial_video =
      meta.initial_video !== null && meta.initial_video !== undefined
        ? meta.initial_video
        : null;
    this.preSelected.display_name =
      meta.display_name !== null && meta.display_name !== undefined
        ? meta.display_name
        : null;
    this.preSelected.chimera_environment =
      meta.chimera_environment &&
      (meta.chimera_environment !== undefined) !== null
        ? meta.chimera_environment
        : null;

    if (
      this.formOptions.event_type.selectValues.length
    ) {
      this.preSelected.event_type =
        this.formOptions.event_type.selectValues[0].value;
    }

    if (
      this.targetEvent !== undefined &&
      this.targetEvent.meta !== null &&
      this.targetEvent.meta.event_type !== undefined
    ) {
      this.preSelected.event_type = this.targetEvent.meta.event_type;
    }

    if (
      this.formOptions.event_type.selectValues.length
    ) {
    this.selectEventTypeLabel =
      this.formOptions.event_type.selectValues[0].value;
    }

    this.preSelected.photon_id =
      meta.photon_id && (meta.photon_id !== undefined) !== null
        ? meta.photon_id
        : null;

    console.log(
      "this.preSelected in experienceMeta.buildPreselected()",
      this.preSelected
    );

    //setup existing media assets
    if (meta.logo !== null) this.existingMedia.logo = meta.logo;
    if (meta.pdf !== null) this.existingMedia.pdf = meta.pdf;
    if (meta.video !== null) this.existingMedia.video = meta.video;

    if (this.preSelected.pdf !== null) this.thumbnails.pdf = true;

    this.setUpThumbnails();

    ;
  }

  private setUpThumbnails() {
    let promises = [];

    if (this.preSelected.logo !== null) {
      let retrieveLogo = this.retrieveAsset(this.preSelected.logo[0].uuid);
      promises.push(retrieveLogo);
    }
    if (this.preSelected.video !== null) {
      let retrieveVideo = this.retrieveAsset(this.preSelected.video[0].uuid);
      promises.push(retrieveVideo);
    }

    if (!promises.length) return;

    Promise.all(promises)
      .then((response) => {
        console.log(
          "response for Promise.all in experienceMeta.setUpThumbnails()",
          response
        );

        if (this.preSelected.logo !== null)
          this.thumbnails.logo = response[0].thumbnail_asset_url;
        if (this.preSelected.video !== null && this.preSelected.logo === null)
          this.thumbnails.video = response[0].thumbnail_asset_url;
        if (this.preSelected.video !== null && this.preSelected.logo !== null)
          this.thumbnails.video = response[1].thumbnail_asset_url;
      })
      .catch ((error) => {
        ;
      });
  }

  private retrieveAsset(uuid) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveMediaEntity = this._xrPlatformRestService.retrieveEntityData(
      "asset",
      uuid,
      options
    );

    return retrieveMediaEntity.toPromise();
  }

  private makeFormValidatingGroup(scheduled_event) {
    this.buildPreselected(scheduled_event);

    this.metaForm = new UntypedFormGroup({
      description: new UntypedFormControl(this.preSelected.description),
    });

    if (this.formOptions.number_of_seats.status)
      this.metaForm.addControl(
        "number_of_seats",
        new UntypedFormControl(this.preSelected.number_of_seats)
      );

    if (this.formOptions.protocol.status)
      this.metaForm.addControl(
        "protocol",
        new UntypedFormControl(this.preSelected.protocol)
      );

    if (this.formOptions.display_name.status)
      this.metaForm.addControl(
        "display_name",
        new UntypedFormControl(this.preSelected.display_name)
      );

    if (this.formOptions.chimera_environment.status)
      this.metaForm.addControl(
        "chimera_environment",
        new UntypedFormControl(this.preSelected.chimera_environment)
      );

    if (this.formOptions.initial_pdf.status)
      this.metaForm.addControl(
        "initial_pdf",
        new UntypedFormControl(this.preSelected.initial_pdf)
      );

    if (this.formOptions.initial_video.status)
      this.metaForm.addControl(
        "initial_video",
        new UntypedFormControl(this.preSelected.initial_video)
      );

    if (this.formOptions.event_type.status)
      this.metaForm.addControl(
        "event_type",
        new UntypedFormControl(this.preSelected.event_type)
      );

    if (this.formOptions.photon_id.status)
      this.metaForm.addControl(
        "photon_id",
        new UntypedFormControl(this.preSelected.photon_id)
      );

    ;

    this.formLoading = true;
  }

  //start location code
  private buildBuildingPreselected(scheduled_event) {
    let meta: any = {
      building: null,
    };

    console.log(
      "this.clientSettings.experienceOptions in experienceMeta::buildBuildingPreselected()",
      this.clientSettings.experienceOptions    );

    this.buildingSelect =
      this.clientSettings.experienceOptions.buildings.properties;

    if (scheduled_event.meta !== undefined && scheduled_event.meta !== null)
      meta = scheduled_event.meta;

    this.preSelected.building =
      meta.building !== undefined && meta.building !== null
        ? meta.building.value
        : null;
  }
  //end location code

  //start location code
  private makeBuildingFormGroup(scheduled_event) {
    this.buildBuildingPreselected(scheduled_event);

    this.metaForm = new UntypedFormGroup({
      building: new UntypedFormControl(this.preSelected.building),
    });

    //listen for changes
    this.metaForm.valueChanges.subscribe((value: any) => {
      ;
      this.optionSelected = true;
    });

    this.formLoading = true;
  }
  //end location code

  get description() {
    return this.metaForm.get("description");
  }

  get number_of_seats() {
    return this.metaForm.get("number_of_seats");
  }

  get protocol() {
    return this.metaForm.get("protocol");
  }

  get display_name() {
    return this.metaForm.get("display_name");
  }

  get chimera_environment() {
    return this.metaForm.get("chimera_environment");
  }

  get initial_pdf() {
    return this.metaForm.get("initial_pdf");
  }

  get initial_video() {
    return this.metaForm.get("initial_video");
  }

  get event_type() {
    return this.metaForm.get("event_Type");
  }

  get photon_id() {
    return this.metaForm.get("photon_id");
  }

  public openMediaModal(type, name) {
    this.modalOptions.data = {
      type: type,
      name: name,
      teamID: this.teamID,
      experience: this.targetEvent,
    };

    this.modalOptions.class = "modal-fluid media-modal-dialog";

    this.mediaFrame = this.modalService.show(
      EventSettingsManageMediaComponent,
      this.modalOptions
    );

    this.mediaFrame.content.outgoing.subscribe((result: any) => {
      ;

      this.preSelected[result.name] = result.media;

      if (result.name === "logo" || result.name === "video") {
        if (result.media.length) {
          if (this.targetEvent.meta === null) this.targetEvent.meta = {};
          //reset logo
          this.targetEvent.meta[result.name] = [];
          this.targetEvent.meta[result.name].push(result.media[0]);
          let retrieveAsset = this.retrieveAsset(result.media[0].uuid);

          retrieveAsset.then((response) => {
            this.thumbnails[result.name] = response.thumbnail_asset_url;
          });
        } else {
          this.thumbnails[result.name] = null;
          this.preSelected[result.name] = null;
        }
      } else if (result.name === "pdf") {
        if (result.media.length) {
          this.thumbnails[result.name] = true;

          if (this.targetEvent.meta === null) this.targetEvent.meta = {};
          //reset pdf
          this.targetEvent.meta[result.name] = [];
          this.targetEvent.meta[result.name].push(result.media[0]);
        } else {
          this.thumbnails[result.name] = false;
          this.preSelected[result.name] = null;
        }
      }
    });
  }

  public openMediaModalLegacy(type, name) {
    this.modalOptions.data = {
      type: type,
      name: name,
      teamID: this.teamID,
      experience: this.targetEvent,
    };

    this.modalOptions.class = "modal-fluid media-modal-dialog";

    this.mediaFrame = this.modalService.show(
      SelectMetaMediaComponent,
      this.modalOptions
    );

    this.mediaFrame.content.outgoing.subscribe((result: any) => {
      ;

      this.preSelected[result.name] = result.media;

      if (result.name === "logo" || result.name === "video") {
        if (result.media.length) {
          if (this.targetEvent.meta === null) this.targetEvent.meta = {};
          //reset logo
          this.targetEvent.meta[result.name] = [];
          this.targetEvent.meta[result.name].push(result.media[0]);
          let retrieveAsset = this.retrieveAsset(result.media[0].uuid);

          retrieveAsset.then((response) => {
            this.thumbnails[result.name] = response.thumbnail_asset_url;
          });
        } else {
          this.thumbnails[result.name] = null;
          this.preSelected[result.name] = null;
        }
      } else if (result.name === "pdf") {
        if (result.media.length) {
          this.thumbnails[result.name] = true;

          if (this.targetEvent.meta === null) this.targetEvent.meta = {};
          //reset pdf
          this.targetEvent.meta[result.name] = [];
          this.targetEvent.meta[result.name].push(result.media[0]);
        } else {
          this.thumbnails[result.name] = false;
          this.preSelected[result.name] = null;
        }
      }
    });
  }

  public updateMetaInit() {
    // handle error when value is empty

    this.msgs.errorMsg = `Select a ${this.labels.building.singular} from the list.`;

    this.msgs.statusMsg =
      `<span class='loading-msg'>Updating ${this.labels.event.singular} meta...</span>`;
    this.formState = "processing";

    let incomingValues = this.metaForm.value;

    //retrieve meta again as a safety measure
    this.retrieveExperienceMeta(incomingValues);
  }

  public updateMeta(legacy, incoming) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let scheduled_event_id = this.targetEvent.schedule_id;
    let existing =
      legacy.meta === undefined || legacy.meta === null ? {} : legacy.meta;

    ;
    ;

    if (this.metaType === "default") {
      if (this.formOptions.logo.status) {
        if (this.preSelected.logo !== null) {
          existing.logo = this.preSelected.logo;
        } else {
          existing.logo = null;
        }
      }

      if (this.formOptions.pdf.status) {
        if (this.preSelected.pdf !== null) {
          existing.pdf = this.preSelected.pdf;
        } else {
          existing.pdf = null;
        }
      }

      if (this.formOptions.video.status) {
        if (this.preSelected.video !== null) {
          existing.video = this.preSelected.video;
        } else {
          existing.video = null;
        }
      }

      existing.description =
        incoming.description === null ? "" : incoming.description;

      if (this.formOptions.number_of_seats.status)
        existing.number_of_seats = incoming.number_of_seats;

      if (this.formOptions.protocol.status)
        existing.protocol = incoming.protocol;

      if (this.formOptions.photon_id.status)
        existing.photon_id = incoming.photon_id;

      if (this.formOptions.initial_pdf.status)
        existing.initial_pdf = incoming.initial_pdf;

      if (this.formOptions.initial_video.status)
        existing.initial_video = incoming.initial_video;

      if (this.formOptions.display_name.status)
        existing.display_name = incoming.display_name;

      if (this.formOptions.chimera_environment.status)
        existing.chimera_environment = incoming.chimera_environment;

      if (this.formOptions.event_type.status)
        existing.event_type = incoming.event_type;
    }

    //start location code
    if (this.metaType === "building") {
      let thisBuilding = this.getBuilding(incoming.building);
      existing.building = thisBuilding[0];
      if(this.action==='remove')
      existing.building = null;
    }
    //end location code

    ;

    let body = {
      meta: existing,
    };

    let updateExperienceMeta = this._xrPlatformRestService.scheduledExperiences(
      scheduled_event_id,
      body,
      options
    );

    updateExperienceMeta.subscribe(
      (response) => {

        if (this.metaType === "default") {

          let mediaToUpdate = [];

          if (this.formOptions.logo.status) {

            ;
            ;
            ;

            mediaToUpdate = this._mediaManagementService.determineMediaForMeta(
              "logo",
              mediaToUpdate,
              this.existingMedia,
              existing,
              this.targetEvent,
              "experience"
            );
          }

          if (this.formOptions.pdf.status) {
            mediaToUpdate = this._mediaManagementService.determineMediaForMeta(
              "pdf",
              mediaToUpdate,
              this.existingMedia,
              existing,
              this.targetEvent,
              "experience"
            );
          }

          if (this.formOptions.video.status) {
            mediaToUpdate = this._mediaManagementService.determineMediaForMeta(
              "video",
              mediaToUpdate,
              this.existingMedia,
              existing,
              this.targetEvent,
              "experience"
            );
          }

          this.existingMedia.logo = existing.logo;
          this.existingMedia.pdf = existing.pdf;
          this.existingMedia.video = existing.video;

          ;

          if (mediaToUpdate.length) {
            let getMediaMeta = this._mediaManagementService.getMediaMeta(
              mediaToUpdate,
              this.targetEvent.id,
              "attached_events",
              this.token
            );
          } else {
            this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.event.singular)} ${this.targetEvent.name} successfully updated`;
            this.btnLabel.retry = "Continue editing";
          }
        //start location code
        } else if (this.metaType === "building") {
          const actionText = this.action==='remove'?'removed':'updated'
          this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.building.singular)} for ${this.targetEvent.name} successfully updated`;
          if(this.action !=='remove')
          this.btnLabel.retry = `Update ${this.TitleCase.transform(this.labels.building.singular)}`;
          else this.continueType='removed';

          let outgoing = {
            action: this.action,
            value: existing.building,
          };

          this.outgoing.next(outgoing);
        }
        //end location code
      },
      (err) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = JSON.parse(err._body);
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  //start location code
  public getBuilding(building_value) {
    return this.clientSettings.experienceOptions.buildings.properties.filter(
      (thisBulding) => {
        return thisBulding.value === building_value;
      }
    );
  }
  //end location code

  private formatEventProp(prop) {

  }

  public openAddEventPropsModal() {
    this.modalOptions.data = {
      teamID: this.teamID,
      teamProps: this.props,
      eventID: this.targetEvent.id
    };

    this.modalOptions.class = "modal-dialog";
    this.addPropFrame = this.modalService.show(
      AddTeamPropModalComponent,
      this.modalOptions
    );

    this.addPropFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.props.push(changedData.prop)
            break;
          case "update":
            
            break;
          case "delete":

            break;
        }
      }
      else {

      }
    });
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.formState = "active";
  }

  public handleIncomingAction(action) {
    ;
    switch (action) {
      case "close-modal":
        this.experienceMetaFrame.hide();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

  public turnConfirmOn(frame) {
    this.confirmOn = true;

    this.mainModal.classList.add("overlay");

    frame.show();
  }

  public closeConfirm(frame) {
    this.confirmOn = false;
    this.mainModal.classList.remove("overlay");

    frame.hide();
  }

  public closeAllModals(frame) {
    this.confirmOn = false;
    frame.hide();
    this.experienceMetaFrame.hide();
  }
  public optionSelect(event: any) {
    this.optionSelected = true;
    ;
  }
}
