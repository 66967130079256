<app-side-menu></app-side-menu>
<app-menu [control]="true" [token]="token"></app-menu>
<div class="heading-content">
  <h4>Client List</h4>
  <div class="callout-text-wrapper">
    <p [innerHTML]="copy"></p>
  </div>
</div>
<mdb-card class="client-sign-up content-card">
  <div class="row" *ngIf="clientsLoading">
    <div class="col-12">
      <!-- if content hasnt load -->
      <div class="loading-screen d-flex align-content-center">
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg === ''"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg !== ''"
        >
          <div
            class="alert alert-danger"
            role="alert"
            [innerHTML]="errorRetrievingMsg"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- when content loads -->
  <div class="row justify-content-between" *ngIf="!clientsLoading">
    <div class="col-5 d-flex">
      <div class="md-form mr-2 w-100">
        <input
          type="search"
          [(ngModel)]="searchText"
          class="form-control"
          id="searchTeams"
          mdbInput
        />
        <label for="searchTeams">Search Clients</label>
      </div>
      <div class="mr-2 filter w-100">
        <div class="md-form filter-clients">
          <mdb-select
            [options]="teamTypes"
            [(ngModel)]="initialType"
            id="role"
            mdbInput
            mdbValidate
            placeholder="All Engagements"
            (selected)="filterClients($event)"
          >
          </mdb-select>
        </div>
      </div>
    </div>
    <div class="col-3 btn-spacing filter text-right">
      <button
        class="theme-modal-button"
        type="button"
        mdbBtn
        mdbWavesEffect
        (click)="openManageClientModal($event, '', '', 'add')"
      >
        <mdb-icon class="pr-1" fas icon="plus-square"></mdb-icon>
        Add Client
      </button>
    </div>
  </div>
  <div class="row flex-row flex-nowrap" *ngIf="!clientsLoading">
    <div class="col-12">
      <div class="table-responsive">
        <table
          class="client-list-table table table-striped btn-table"
          mdbTable
          small="true"
        >
          <thead class="theme-base-medium-dark white-text">
            <colgroup class="client-list">
              <col
                *ngFor="let head of headElements; let i = index"
                [ngClass]="'col-' + headClasses[i]"
              />
            </colgroup>
            <tr class="text-center">
              <th colspan="4" style="border: none"></th>
              <th colspan="3" style="border: none">Engagement Window</th>
              <th colspan="1" style="border: none"></th>
            </tr>
            <tr>
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="headClasses[i]"
              >
                {{ head | titlecase }}
                <ng-template [ngIf]="sortColumn(head)">
                  <mdb-icon
                    fas
                    *ngIf="sortBy !== sort_elements[i]"
                    icon="sort"
                    class="sort-icon"
                    [mdbTooltip]="'Sort by ' + head"
                    placement="bottom"
                    (click)="sortTable(sort_elements[i], 'desc')"
                  ></mdb-icon>
                  <mdb-icon
                    *ngIf="sortBy == sort_elements[i] && sortOrder == 'asc'"
                    (click)="sortTable(sort_elements[i], 'desc')"
                    fas
                    icon="caret-up"
                    class="sort-icon"
                  ></mdb-icon>
                  <mdb-icon
                    *ngIf="sortBy == sort_elements[i] && sortOrder == 'desc'"
                    (click)="sortTable(sort_elements[i], 'asc')"
                    fas
                    icon="caret-down"
                    class="sort-icon"
                  ></mdb-icon>
                </ng-template>
              </th>
            </tr>
          </thead>
          <tbody #row>
            <tr
              *ngFor="
                let team of teams | filterBy : 'name' : searchText;
                let i = index
              "
              [ngClass]="processEngagementClass(team)"
            >
              <td class="btn-group list-btns-sm action-icons" role="group">
                <a
                  size="lg"
                  (click)="openManageClientModal($event, team, i, 'update')"
                  mdbBtn
                  mdbWavesEffect
                  class="btn-flat"
                  mdbTooltip="Edit"
                  placement="bottom"
                >
                  <mdb-icon fas icon="edit"></mdb-icon>
                </a>
                <a
                  size="lg"
                  mdbBtn
                  mdbWavesEffect
                  class="btn-flat"
                  mdbTooltip="Settings"
                  placement="bottom"
                  [routerLink]="['/client/' + team.code + '/settings']"
                >
                  <mdb-icon fas icon="cog"></mdb-icon>
                </a>
              </td>
              <td
                class="thumbnail-container theme-base-medium-light thumbnail"
                *ngIf="team.logo"
                (click)="onClickClientRow(team)"
              >
                <img
                  class="media-thumbnail"
                  *ngIf="
                    team.logo !== null && team.logo.thumbnail_asset_url !== null
                  "
                  [src]="team.logo.thumbnail_asset_url"
                />
                <img
                  class="media-thumbnail"
                  *ngIf="
                    team.logo === null || team.logo.thumbnail_asset_url === null
                  "
                  [src]="placeHolderImg"
                />
              </td>
              <td
                class="thumbnail"
                *ngIf="!team.logo"
                (click)="onClickClientRow(team)"
              >
                <span (click)="openEditLogo(team, i)"> No logo set </span>
              </td>
              <td class="client-name" (click)="onClickClientRow(team)">
                {{ team.name }}
              </td>
              <td class="client-code">
                <div class="chip">{{ team.code }}</div>
              </td>
              <td
                *ngIf="team.engagements[0]"
                class="engagement-time start-date"
                (click)="onClickClientRow(team)"
              >
                <span class="date">{{
                  team.engagements[0].start_date | amDateFormat : "MMM D YYYY"
                }}</span>
                <br />
                <span class="time">{{
                  team.engagements[0].start_date | amDateFormat : "h:mm a"
                }}</span>
              </td>
              <td
                class="engagement-time start-date"
                *ngIf="!team.engagements[0]"
                (click)="onClickClientRow(team)"
              >
                None
              </td>
              <td
                *ngIf="team.engagements[0]"
                class="engagement-time end-date"
                (click)="onClickClientRow(team)"
              >
                <span class="date">{{
                  team.engagements[0].end_date | amDateFormat : "MMM D YYYY"
                }}</span>
                <br />
                <span class="time">{{
                  team.engagements[0].end_date | amDateFormat : "h:mm a"
                }}</span>
              </td>
              <td
                class="engagement-time end-date"
                *ngIf="!team.engagements[0]"
                (click)="onClickClientRow(team)"
              >
                None
              </td>
              <td
                class="description"
                *ngIf="team.engagements[0]"
                (click)="onClickClientRow(team)"
              >
                {{ team.engagements[0].description }}
              </td>
              <td *ngIf="!team.engagements[0]" (click)="onClickClientRow(team)">
                None
              </td>
              <td class="team-id">
                <div class="chip" (click)="onClickClientRow(team)">
                  {{ team.id }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</mdb-card>
