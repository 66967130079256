<div class="modal-header">
  <h4 class="modal-title w-100 font-weight-bold">
    {{ title }} <mdb-icon far icon="calendar-alt"></mdb-icon>
  </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="scheduleGroupFrame.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body media-modal m-3 p-0">
  <form
    *ngIf="formLoading"
    [formGroup]="scheduleForm"
    class="text-center border border-light p-4"
  >
    <div class="md-form parsed-selection mb-5">
      <div
        class="content-section content-pdfs theme-base-light"
        *ngIf="type === 'pdf'"
      >
        <div class="current-section theme-base-medium-light">
          <h4>Selected PDF</h4>
          <button
            class="btn-sm btn btn-outline-theme ng-star-inserted waves-effect"
            *ngIf="existingValues.pdf !== ''"
            (click)="resetMedia('pdf')"
          >
            set to none
          </button>
          <div *ngIf="existingValues.pdf === null" class="no-selection">
            <p>No PDF selected.</p>
          </div>
          <div *ngIf="existingValues.pdf !== null" class="has-selection">
            <mdb-icon
              class="color-theme-base-medium-dark"
              size="4x"
              fas
              icon="file-pdf"
            ></mdb-icon>
            <p>{{ existingValues.pdf.name }}</p>
          </div>
        </div>
        <h4>Select a PDF</h4>
        <div class="asset-select-wrapper d-flex flex-wrap">
          <div
            class="asset-select-item mb-3"
            *ngFor="let pdfOption of pdfOptionsGroup; let i = index"
          >
            <div class="form-check">
              <div class="select-image">
                <div class="image-wrapper view overlay pdf-icon">
                  <mdb-icon
                    *ngIf="pdfOption.file_ext === 'pdf'"
                    class="color-theme-base-medium-dark"
                    size="4x"
                    fas
                    icon="file-pdf"
                  ></mdb-icon>
                  <div
                    class="
                      mask
                      flex-center
                      waves-effect waves-light
                      rgba-stylish-strong
                    "
                  >
                    <p class="white-text text-center">
                      {{ pdfOption.name }}
                    </p>
                  </div>
                </div>
                <div class="label-wrapper">
                  <input
                    type="radio"
                    class="form-check-input"
                    formControlName="pdfOptionsValue"
                    [id]="'pdfOptions' + i"
                    name="pdfOptionsValue"
                    [value]="pdfOption.id"
                  />
                  <label class="form-check-label" [for]="'pdfOptions' + i"
                    >&nbsp;</label
                  >
                  <p class="asset-name">
                    {{ pdfOption.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="content-section content-images theme-base-light"
        *ngIf="type === 'image'"
      >
        <div class="current-section theme-base-medium-light">
          <h4>Selected Image</h4>
          <button
            class="btn-sm btn btn-outline-theme ng-star-inserted waves-effect"
            *ngIf="existingValues.image !== ''"
            (click)="resetMedia('image')"
          >
            set to none
          </button>
          <div *ngIf="existingValues.image === null" class="no-selection">
            <p>No image selected.</p>
          </div>
          <div *ngIf="existingValues.image !== null" class="has-selection">
            <img class="img-fluid" [src]="existingValues.image.src" />
            <p>{{ existingValues.image.name }}</p>
          </div>
        </div>
        <h4>Select a Logo Image</h4>
        <div class="asset-select-wrapper d-flex flex-wrap">
          <div
            class="asset-select-item mb-3"
            *ngFor="let imageOption of imageOptionsGroup; let i = index"
          >
            <div class="form-check">
              <div class="select-image">
                <div class="view overlay image-wrapper">
                  <img
                    *ngIf="imageOption.src"
                    class="img-fluid"
                    [src]="imageOption.src"
                  />
                  <div
                    class="
                      mask
                      flex-center
                      waves-effect waves-light
                      rgba-stylish-strong
                    "
                  >
                    <p class="white-text text-center">
                      {{ imageOption.name }}
                    </p>
                  </div>
                </div>
                <div class="label-wrapper">
                  <input
                    type="radio"
                    class="form-check-input"
                    formControlName="imageOptionsValue"
                    [id]="'imageOptions' + i"
                    name="imageOptionsValue"
                    [value]="imageOption.id"
                  />
                  <label class="form-check-label" [for]="'imageOptions' + i"
                    >&nbsp;</label
                  >
                  <p class="asset-name">
                    {{ imageOption.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="content-section content-images theme-base-light"
        *ngIf="type === 'video'"
      >
        <div class="current-section theme-base-medium-light">
          <h4>Selected Video</h4>
          <button
            class="btn-sm btn btn-outline-theme ng-star-inserted waves-effect"
            *ngIf="existingValues.video !== ''"
            (click)="resetMedia('video')"
          >
            set to none
          </button>
          <div *ngIf="existingValues.video === null" class="no-selection">
            <p>No video selected.</p>
          </div>
          <div *ngIf="existingValues.video !== null" class="has-selection">
            <img class="img-fluid" [src]="existingValues.video.src" />
            <p>{{ existingValues.video.name }}</p>
          </div>
        </div>
        <h4>Select a Video</h4>
        <div class="asset-select-wrapper d-flex flex-wrap">
          <div
            class="asset-select-item mb-3"
            *ngFor="let videoOption of videoOptionsGroup; let i = index"
          >
            <div class="form-check">
              <div class="select-image">
                <div class="view overlay image-wrapper">
                  <img
                    *ngIf="videoOption.src"
                    class="img-fluid"
                    [src]="videoOption.src"
                  />
                  <div
                    class="
                      mask
                      flex-center
                      waves-effect waves-light
                      rgba-stylish-strong
                    "
                  >
                    <p class="white-text text-center">
                      {{ videoOption.name }}
                    </p>
                  </div>
                </div>
                <div class="label-wrapper">
                  <input
                    type="radio"
                    class="form-check-input"
                    formControlName="videoOptionsValue"
                    [id]="'videoOptions' + i"
                    name="videoOptionsValue"
                    [value]="videoOption.id"
                  />
                  <label class="form-check-label" [for]="'videoOptions' + i"
                    >&nbsp;</label
                  >
                  <p class="asset-name">
                    {{ videoOption.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button
    mdbBtn
    class="waves-light theme-action-button"
    data-dismiss="modal"
    mdbWavesEffect
    (click)="scheduleGroupFrame.hide()"
  >
    {{ btnLabel.close }}
  </button>
  <button
    *ngIf="formLoading"
    mdbBtn
    class="waves-light theme-action-button"
    data-dismiss="modal"
    mdbWavesEffect
    [disabled]="formState === 'processing'"
    (click)="manageMedia()"
  >
    {{ btnLabel.main }}
  </button>
</div>
