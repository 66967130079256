import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';

@Component({
  selector: 'app-add-question-option-modal',
  templateUrl: './add-question-option-modal.component.html',
  styleUrls: ['./add-question-option-modal.component.scss']
})
export class AddQuestionOptionModalComponent implements OnInit {

  public question: any;
  public teamID: number;
  
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public addQuestionOptionForm: any;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Survey",
    retry: "Retry",
  };
  
  private outgoing: Subject<any> = new Subject();

  public is_default: boolean = false;

  constructor(
    public addSurveyQuestionModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.continueType = "reset";

    this.addQuestionOptionForm = this.formBuilder.group({
      name: '',
      label: '',
      value: '',
    });
  }

  public clickAddQuestionOption() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Adding Question to Survey</span>";

    
    const data = {
      name: this.addQuestionOptionForm.value.name,
      label: this.addQuestionOptionForm.value.label,
      value: this.addQuestionOptionForm.value.value,
      is_default: false
    }

    let addQuestionOption = this._xrPlatformRestService.userDataHandler(
      '/question/' + this.question.id + '/option',
      'post',
      data
    )

    addQuestionOption.subscribe((response) => {
      
      this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "Option successfully added";

        let outgoingData = {
          action: 'add',
          option: response.option
        }
        this.outgoing.next(outgoingData);
    },
    (error) => {
      ;
      this.msgs.processingMsg = "";
      let errorMsg = JSON.parse(error._body);
      this.btnLabel.retry = "Retry";
      this.msgs.errorMsg = errorMsg.error;
    });
  }

  public resetForm() {
    this.addQuestionOptionForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.addQuestionOptionForm.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
