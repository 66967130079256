<div class="class-wrapper">
    <app-status-messages [msgs]="msgs" [btnLabel]="btnLabel" [continueType]="continueType"
        (targetAction)="handleIncomingAction($event)"></app-status-messages>
    <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Add Question to Survey</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="addSurveyQuestionModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body m-3 p-0">
        <div class="row flex-add-form">
            <div class="text-center border border-light p-5 edit-form w-100">
                <div class="md-form mb-5 form-group">
                    <mdb-select
                    [options]="questionsSelect"
                    (selected)="questionSelected($event)"
                    id="question"
                    mdbInput
                    placeholder="Choose question to add"
                    label="Question">

                    </mdb-select>
                </div>

            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="addSurveyQuestionModal.hide()"
    >
      Close
    </button>
    <button
      mdbBtn
      class="waves-light theme-action-button"
      mdbWavesEffect
      (click)="clickAddSurveyQuestion()"
    >
      Add Question
    </button>
    </div>
</div>
