import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { EditClientLogoModalComponent } from "./modals/edit-client-logo-modal/edit-client-logo-modal.component";
import { ManageClientModalComponent } from "./modals/manage-client-modal/manage-client-modal.component";

@Component({
  selector: "app-client-list",
  templateUrl: "./client-list.component.html",
  styleUrls: ["./client-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClientListComponent implements OnInit {
  public copy: string;
  public token: string;
  public teams: any;
  public allTeams: any;
  public user: any;
  public clientsLoading: boolean = true;
  public errorRetrievingMsg: string = "";
  public searchText: string = "";

  // placeholders
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";

  //timezone
  public tz: any = moment.tz(moment.tz.guess()).format("z");

  public headElements = [
    "",
    "",
    "Client Name",
    "Client Code",
    "Start",
    "End",
    "Description",
    "Team ID",
  ];

  public headClasses = [
    "action-icons",
    "thumbnail",
    "client-name",
    "client-code",
    "start-date",
    "end-date",
    "description",
    "team-id",
  ]

  sort_elements = [
    "",
    "",
    "name",
    "code",
    "start_date",
    "end_date",
    "description",
    "id",
  ];

  public teamTypes = ([] = [
    { value: "current", label: "Current Engagements" },
    { value: "future", label: "Future Engagements" },
    { value: "past", label: "Past Engagements" },
    { value: "all", label: "All Engagements" },
  ]);
  public initialType = "current";

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  //table sorting
  public sortBy = "none";
  public sortOrder = "none";

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: MDBModalService,
    private _xrPlatformRestService: XrPlatformRestService
  ) { }

  ngOnInit(): void {
    this.copy = "Admin Panel";
    this.retrieveToken();
    this.retrieveUser();

    this.retrieveTeams();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveTeams() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    ;

    let teamsRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      "/teams/date-oriented",
      "post",
      {
        check_date: moment(new Date()).format("MMM D, YYYY HH:mm:ss Z"),
      },
      getOptions
    );

    teamsRetrieve.subscribe(
      (incoming) => {
        this.teams = incoming;
        this.allTeams = incoming;
        this.sortTable("name", "asc");
        this.filterClients({ value: "current", label: "Current Engagements" });
        this.clientsLoading = false;
        this.errorRetrievingMsg = "";
      },
      (error) => {
        ;
        this.errorRetrievingMsg = error;
      }
    );
  }

  public filterClients(incoming) {
    this.teams = this.allTeams;

    let filteredTeams = this.teams.filter((team) => {
      let engagement =
        team.engagements === undefined || null || !team.engagements.length
          ? { state: "past" }
          : team.engagements[0];
      return incoming.value === "all"
        ? true
        : incoming.value === "current" && (engagement.state === "current" || engagement.state === "ending")
          ? true
          : engagement.state === incoming.value;
    });

    this.teams = filteredTeams;
  }

  private nullStringCheck(incoming) {
    if (incoming === null) incoming = "";

    return incoming;
  }

  private workOutEngagementDate(incoming, target): number {
    let engagement =
      incoming.engagements === undefined || null || !incoming.engagements.length
        ? { end_date: "", start_date: "" }
        : incoming.engagements[0];

    if (engagement[target] === "") return 0;

    return parseInt(
      moment(new Date(engagement[target]), "MMMM D YYYY, H:mm A Z").format("X")
    );
  }

  public sortColumn(head) {
    let targetSort = ["Client Name", "Start", "End", "Team ID"];
    let sort = false;

    if (targetSort.indexOf(head) > -1) sort = true;

    return sort;
  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction) {
    console.log(
      "head, direction in ClientListComponent::sortTable()",
      head,
      direction
    );

    this.sortBy = head;
    this.sortOrder = direction;

    switch (head) {
      case "name":
        if (direction === "asc") {
          this.teams = this.teams.sort((a, b) => {
            return this.nullStringCheck(a[head]).localeCompare(
              this.nullStringCheck(b[head])
            );
          });
        } else {
          this.teams = this.teams.sort((a, b) => {
            return this.nullStringCheck(b[head]).localeCompare(
              this.nullStringCheck(a[head])
            );
          });
        }

        break;
      case "start_date":
      case "end_date":
        if (direction === "desc") {
          this.teams = this.teams.sort((a, b) => {
            let dateA = this.workOutEngagementDate(a, head);
            let dateB = this.workOutEngagementDate(b, head);

            return dateB - dateA;
          });
        } else {
          this.teams = this.teams.sort((a, b) => {
            let dateA = this.workOutEngagementDate(a, head);
            let dateB = this.workOutEngagementDate(b, head);
            return dateA - dateB;
          });
        }

        break;
      case "id":
        if (direction === "desc") {
          this.teams = this.teams.sort((a, b) => {
            return b[head] - a[head];
          });
        } else {
          this.teams = this.teams.sort((a, b) => {
            return a[head] - b[head];
          });
        }

        break;
      default:
        if (direction === "desc") {
          this.teams = this.teams.sort((a, b) => {
            return b[head] - a[head];
          });
        } else {
          this.teams = this.teams.sort((a, b) => {
            return a[head] - b[head];
          });
        }
    }
  }

  public openManageClientModal(event, client, index, action) {
    event.preventDefault();
    event.stopPropagation();

    this.modalOptions.data = {
      client: client,
      action: action,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-client-modal-container",
    };

    this.modalFrame = this.modalService.show(
      ManageClientModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((result) => {
      if (result.action !== undefined) {
        switch (result.action) {
          case "add":
            this.teams.push(result.client);
            break;
          case "update":
            this.teams[index] = result.client;
            break;
          case "delete":
            break;
        }
      }
    });
  }

  public onClickClientRow(team) {

    //set the appropriate stored vars first
    this.coolLocalStorage.setObject("admin_panel_team_id", team.id);
    this.coolLocalStorage.setItem("admin_panel_clientcode", team.code);

    this.router.navigate(["client/" + team.code]);
  }

  public processEngagementClass(team) {
    let classOut = "past";

    if (team.engagements.length) {
      classOut = team.engagements[0].state;
    }

    if (classOut === "current") classOut = classOut + " z-depth-1";

    return classOut;
  }

  public openEditLogo(client, index) {
    this.modalOptions.data = {
      client: client,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "edit-logo-modal-container",
    };

    this.modalFrame = this.modalService.show(
      EditClientLogoModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((result) => {
      ;
      if (result.action !== undefined) {
        ;
        switch (result.action) {
          case "add":
            break;
          case "update":
            this.teams[index].logo = result.media;
            break;
          case "delete":
            break;
        }
      }
    });
  }
}
