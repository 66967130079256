import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ElementRef } from "@angular/core";
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { DateFormatPipe } from "ngx-moment";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-add-event-type-option-modal",
  templateUrl: "./add-event-type-option-modal.component.html",
  styleUrls: ["./add-event-type-option-modal.component.scss"],
})
export class AddEventTypeOptionModalComponent implements OnInit {
  //incoming
  public teamEventTypes;
  public teamID: number;

  public eventTypes: any = [];
  public eventTypes_names: any = [];

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Schedule",
    reset: "Add Another Class",
    retry: "Retry",
  };

  public eventTypesForm: UntypedFormGroup;
  public formLoading: boolean = false;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Schedule a Class for the experience:",
  };
  public formState: string = "active";

  public eventTypeForm = this.formBuilder.group({
    eventtype_name: "",
  });
  public eventTypesFormGroup: UntypedFormGroup;

  searchText = new Subject();
  results: Observable<string[]>;

  public token: string;

  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _clientManagementService: ClientManagementService,
    public eventTypeFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private _settingsService: SettingsService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.eventTypesFormGroup = this.eventTypeForm;

    this.retrieveEventTypes();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  // this assings the results based on available eventTypes_names
  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.eventTypes_names.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }

  public retrieveEventTypes() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let eventTypes = this._xrPlatformRestService.restfulAPIQuery(
      "/scheduled-event-types",
      "get",
      data,
      getOptions
    );

    eventTypes.subscribe((response) => {
      this.eventTypes = response.event_types;
      this.processEventTypes();
      this.eventTypes.forEach((element) => {
        ;
        this.eventTypes_names.push(element.label);
      });
      this.results = this.searchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value))
      );
    });
  }

  // this returns all the options available to ADD
  // if they are not passed from the teamEventTypes
  private processEventTypes() {
    let temp_eventTypes = [];
    this.eventTypes.forEach((element) => {
      let index = this.teamEventTypes.findIndex((x) => x.id === element.id);
      if (index == -1) {
        temp_eventTypes.push(element);
      }
    });

    this.eventTypes = temp_eventTypes;
  }

  public addEventType() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let eventId = this.getEventId(this.eventTypeForm.value.eventtype_name);

    const data = {
      event_type_id: eventId,
      team_id: this.teamID,
    };

    let addEventType = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/scheduled-event-type",
      "post",
      data,
      getOptions
    );

    addEventType.subscribe((response) => {
      if (response.error != 418) {
        ;
        ;
        let outgoingData = {
          action: "add",
          event_type: response.event_type,
        };
        this.outgoing.next(outgoingData);
      }
    });
    this.eventTypeFrame.hide();
  }

  private getEventId(label: string) {
    let index = this.eventTypes.findIndex((x) => x.label === label);
    ;
    ;
    return this.eventTypes[index].id;
  }
}
