import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Component({
    selector: 'app-manage-permission-modal',
    templateUrl: './manage-permission-modal.component.html',
})
export class ManagePermissionModalComponent implements OnInit {

    public team_id: number;
    public token: string;
    public mode: any;

    public permission: any;

    public permissionForm: UntypedFormGroup;

    //status messages
    public msgs: {
        errorMsg: string;
        statusMsg: string;
        processingMsg: string;
    } = {
            errorMsg: "",
            statusMsg: "",
            processingMsg: "",
        };

    public continueType: string;

    public btnLabel: {
        close: string;
        reset: string;
        retry: string;
    } = {
            close: "Close",
            reset: "Add Another Permission",
            retry: "Retry",
        };

    private outgoing: Subject<any> = new Subject();
    private outgoingData: any;

    constructor(
        public managePermissionModal: MDBModalRef,
        private coolLocalStorage: CoolLocalStorage,
        private _xrPlatformRestService: XrPlatformRestService,
        private formBuilder: UntypedFormBuilder) { }

    ngOnInit(): void {
        if (this.mode === 'Add') {
            this.continueType = "reset";
            this.permissionForm = this.formBuilder.group({
                permission_name: "",
                permission_description: "",
            });
        } else if (this.mode === 'Edit') {
            this.btnLabel.retry = "Continue Editing";
            this.continueType = "continue";
            this.permissionForm = this.formBuilder.group({
                permission_name: this.permission.permission_name
            });
        }

        this.retrieveToken();

    }

    private retrieveToken() {
        this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
    }

    public resetForm() {
        this.permissionForm.reset();
        this.clearStatus();
    }

    public clearStatus() {
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
    }

    public handleIncomingAction(action) {
        switch (action) {
            case "close-modal":
                this.managePermissionModal.hide();
                break;
            case "reset-form":
                this.resetForm();
                break;
            case "clear-status":
                this.clearStatus();
                break;
        }
    }

    managePermission() {
        if (this.mode === 'Add') {
            this.addPermission();
        } else if (this.mode === 'Edit') {
            this.editPermission();
        }
    }
    addPermission() {
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "";
        this.msgs.processingMsg = "<span class='processing-msg'>Adding permission</span>";
        ;
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
        };

        const getOptions = {
            headers: headers,
        };

        const data = {
            permission_name: this.permissionForm.value.permission_name
        };
        let addedPermission = this._xrPlatformRestService.restfulAPIQuery(
            '/personas/addpermission/',
            'post',
            data,
            getOptions
        );
        addedPermission.subscribe(
            (response) => {
                ;

                this.msgs.processingMsg = "";
                this.msgs.errorMsg = "";
                this.msgs.statusMsg = "Permission successfully added";
                let outgoingData = {
                    action: 'add',
                    permission: response.permission
                }
                this.outgoing.next(outgoingData);
            },
            (error) => {
                ;
                this.msgs.processingMsg = "";
                let errorMsg = JSON.parse(error._body);
                this.btnLabel.retry = "Retry";
                this.msgs.errorMsg = errorMsg.error;
            }
        );
    }
    editPermission() {
        ;

        this.msgs.errorMsg = "";
        this.msgs.statusMsg = "";
        this.msgs.processingMsg =
            "<span class='processing-msg'>Editing branding permission</span>";

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
        };

        const getOptions = {
            headers: headers,
        };

        const data = {
            permission_name: this.permissionForm.value.permission_name
        };
        let edittedPermission = this._xrPlatformRestService.restfulAPIQuery(
            '/personas/addpermission/' + this.permission.id,
            'put',
            data,
            getOptions
        );
        edittedPermission.subscribe(
            (response) => {
                ;

                this.msgs.processingMsg = "";
                this.msgs.errorMsg = "";
                this.msgs.statusMsg = "Branding Permission successfully udpated";

                if (response.error != 418) {
                    ;
                    let outgoingData = {
                        action: 'update',
                        permission: response.permission
                    }
                    this.outgoing.next(outgoingData);
                }
            },
            (error) => {
                ;
                this.msgs.processingMsg = "";
                let errorMsg = JSON.parse(error._body);
                this.btnLabel.retry = "Retry";
                this.msgs.errorMsg = errorMsg.error;
            }
        );
    }

}
