import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';

@Component({
  selector: 'app-archive-modal',
  templateUrl: './archive-vendor-entity-modal.component.html',
  styleUrls: ['./archive-vendor-entity-modal.component.scss']
})
export class ArchiveVendorEntityComponent implements OnInit {

  public type: string;
  public entity: any;
  public teamID: number;

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    reset: "Add Another Survey",
    retry: "Retry",
  };

  public continueType: string;

  private outgoing: Subject<any> = new Subject();

  constructor(
    public archiveEntityModal: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private modalService: MDBModalService,
  ) { }

  ngOnInit(): void {
    this.continueType = "close";
  }

  public clickArchiveEntity() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "<span class='processing-msg'>Archiving " + this.type + "</span>";

    let url = '';

    if(this.type == 'survey')
    {
      url = "/survey/" + this.entity.id +"/archive"
    }
    else if(this.type == "question") {
      url = "/question/" + this.entity.id +"/archive"
    }
    else if(this.type == 'option') {
      url = "/question/1/option/" + this.entity.id +"/archive"
    }

    let archiveEntity = this._xrPlatformRestService.userDataHandler(
      url,
      'put',
      {}
    )

    archiveEntity.subscribe((response) => {
      this.msgs.processingMsg = "";
          this.msgs.errorMsg = "";
          this.msgs.statusMsg = this.type + " successfully archived";
  
          let outgoingData = {
            action: 'update',
            status: response.status
          }
          this.outgoing.next(outgoingData);
    },
    (error) => {
      ;
      this.msgs.processingMsg = "";
      let errorMsg = JSON.parse(error._body);
      this.btnLabel.retry = "Retry";
      this.msgs.errorMsg = errorMsg.error;
    })

  }

  public resetForm() {
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.archiveEntityModal.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
