import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";

@Component({
  selector: "app-media-manager-base",
  templateUrl: "./media-manager-base.component.html",
  styleUrls: ["./media-manager-base.component.scss"],
})
export class MediaManagerBaseComponent implements OnInit {
  // scroll height
  public windowScrolled: boolean;

  //media control inputs
  public inputActiveButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
    info: true,
    share: true,
    delete: true,
  };

  public actionButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
    info: true,
    share: true,
    delete: true,
  };

  public columnVisibility: {
    thumbnail: boolean;
    pdfStatus: boolean;
    fileType: boolean;
    owner: boolean;
    urlType: boolean;
    dateCreated: boolean;
    shares: boolean;
  } = {
    thumbnail: true,
    pdfStatus: true,
    fileType: true,
    owner: true,
    urlType: true,
    dateCreated: true,
    shares: true,
  };

  public selectable: boolean = false;

  public selectedMedia: any = [];

  public filterByType: string = "all";

  public selectableType: string = "multiple";

  public clickableThumb: boolean = true;

  public systemButtons: any = {
    action: null,
    cancel: null,
  };

  public systemInteraction: string = "none";

  public showHeading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private _clientManagementService: ClientManagementService,
    private _settingsService: SettingsService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop > 100
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }
  ngOnInit(): void {}

  printSelected(event) {
    ;
  }
}
