import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CoolLocalStorage } from "@angular-cool/storage";

@Component({
  selector: "app-manage-media-owner",
  templateUrl: "./manage-media-owner.component.html",
  styleUrls: ["./manage-media-owner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageMediaOwnerComponent implements OnInit {
  constructor(
    private coolLocalStorage: CoolLocalStorage,
    public ownerModal: MDBModalRef,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private _MediaManagementServicesService: MediaManagementServicesService
  ) {}

  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpeg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";

  public user: any;
  public media_item: any;
  public labels: any;
  public token: string;
  private teamID = 0;
  public usernames_autocomplete: string[] = [];
  public users_autocompete: any;

  searchText = new Subject();
  results: Observable<string[]>;

  public newOwner: UntypedFormGroup;
  public usernameselector: string = "";

  public current_owner: number;
  public mainModal: any;

  public confirmOn: boolean = false;
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "Update settings for this class",
  };
  public continueType: string;
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Update",
    reset: "",
    retry: "Retry",
  };

  ngOnInit(): void {
    this.token = this._MediaManagementServicesService.retrieveToken();
    this.retrieveTeamID();
    this.retrieveLabels();

    this.newOwner = new UntypedFormGroup({
      usernameselector: new UntypedFormControl(),
    });

    this.getUserstoShare();
    this.getCurrentOwner();

    this.continueType = "Continue";
  }
  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }

  private getUserstoShare() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };
    let get_users = this._xrPlatformRestService.getAvailableUsers(
      this.teamID,
      getOptions
    );
    get_users.subscribe((response) => {
      this.users_autocompete = response;
      response.forEach((usr) => {
        this.usernames_autocomplete.push(usr.username);
      });
      this.results = this.searchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value))
      );
      (error) => {
        //@todo: handle error
      };
    });
  }

  private getCurrentOwner() {
    this.media_item.shared_with.forEach((share) => {
      console.log(
        "Media item: " + this.media_item.id + " Owner: " + share.is_owner
      );
      if (share.is_owner == true) {
        this.current_owner = share.user_id;
      }
    });
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.usernames_autocomplete.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
    return filtered;
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.ownerModal.hide();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    //this.formState = "active";
  }

  public clickChangeOwner() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };
    const newOwnerName = this.newOwner.get("usernameselector").value;
    console.log(
      "Username Selector: " + this.newOwner.get("usernameselector").value
    );
    let data = {
      working_user_id: this.user.id,
      current_owner_id: this.current_owner,
      asset_id: this.media_item.id,
      transfer_user_id: this.getUserIdFromName(newOwnerName),
    };
    let changed = this._xrPlatformRestService.changeOwnership(data, getOptions);
    changed.subscribe((response) => {
      if (response.error === null) {
        this.media_item.owner_username = newOwnerName;
        this.msgs.statusMsg = "Ownername successfully changed";
      }
      (error) => {
        //@todo: handle error
      };
    });
  }

  private getUserIdFromName(username: string) {
    let index = this.users_autocompete.findIndex(
      (x) => x.username === username
    );
    return this.users_autocompete[index].user_id;
  }

  public turnConfirmOn(frame) {
    this.mainModal.classList.add("overlay");

    this.confirmOn = true;
    frame.show();
  }

  public closeConfirm(frame) {
    this.mainModal.classList.remove("overlay");
    this.confirmOn = false;
    frame.hide();
  }

  public closeAllModals(frame) {
    this.confirmOn = false;
    frame.hide();
    this.ownerModal.hide();
  }
}
