<div class="modal-content-wrapper" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span>{{ title | titlecase }}</span>
    </h4>
    <button
      *ngIf="isClean"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div
    class="modal-body media-modal content-card modal-fluid-content px-3 py-0"
  >
    <div class="row fluid-content container-fluid">
      <div class="col-12 h-100">
        <app-media-manager
          [teamID]="teamID"
          [defaultTab]="'all-media'"
          [actionButtons]="actionButtons"
          [columnVisibility]="columnVisibility"
          [selectable]="selectable"
          [selectedMedia]="attached"
          [filterByType]="filterByType"
          [selectableType]="selectableType"
          [clickableThumb]="clickableThumb"
          [systemButtons]="systemButtons"
          [systemInteraction]="systemInteraction"
          [TargetEntity]="targetEvent"
          (parentActions)="onParentActions($event)"
        ></app-media-manager>
      </div>
    </div>
    <!-- end media -->
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      [disabled]="isClean"
      (click)="manageMedia()"
    >
      <span *ngIf="!isClean"
        >Update {{ labels.event.singular }} {{ labels.media.plural }}</span
      >
      <span *ngIf="isClean">Return to {{ labels.event.singular }}</span>
    </button>
    <button
      *ngIf="isClean"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>
<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <p class="semi-bold">
      Warning! All {{ action === "add" ? "work" : "updates" }} on this
      {{ labels.event.singular }} will be lost.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        (click)="closePopOvers()"
      >
        Back to Manage {{ labels.event.singular }} {{ labels.media.plural }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        (click)="closeModal(true)"
      >
        Continue Cancel
      </button>
    </div>
  </div></ng-template
>
