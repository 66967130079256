<div class="modal-header text-center">
  <h4 class="modal-title w-100 font-weight-bold">
    Manage Permissions for Persona {{ persona.persona_name }}
  </h4>
</div>
<div class="modal-body m-3 p-0">
  <div class="row">
    <div class="col-12">
      <p class="card-text">Experiences: {{ persona.persona_name }}</p>
    </div>
  </div>
  <div class="row">
    <div
      class="col-12 container-gallery justify-content-start"
      *ngIf="!loading"
    >
      <div class="permissions-grid" *ngIf="permissions.length !== 0">
        <div
          class="permissions"
          *ngFor="let permission of permissions; let i = index"
        >
          <mdb-card>
            <mdb-card-body>
              <a
                class="activator px-3 mr-2"
                mdbTooltip="Remove"
                placement="bottom"
                id="remove-permission-{{ permission.id }}"
              >
                <mdb-icon
                  fas
                  icon="minus-circle"
                  (click)="clickDeletePersonaPermission(permission.id, i)"
                ></mdb-icon>
              </a>

              <mdb-card-title>
                <h5 class="mb-3 permission-name">
                  {{ permission.permission.permission_name }}
                </h5>
              </mdb-card-title>
              <hr />
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    mdbBtn
    type="button"
    mdbWavesEffect
    class="theme-modal-button btn"
    data-dismiss="modal"
    (click)="openAddPersonaPermissionModal()"
    id="connect-permission-to-persona"
  >
    ADD PERMISSION TO PERSONA
  </button>
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    size="md"
    color="link"
    data-dismiss="modal"
    (click)="viewPersonaPermissionModal.hide()"
    id="close-modal-btn"
  >
    close
  </button>
</div>
