import { Component, OnInit } from "@angular/core";
import { CoolLocalStorage } from "@angular-cool/storage";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ManageEnvironmentsComponent } from "./modals/manage-environments/manage-environments.component";
import { ArchiveEnvironmentsComponent } from "./modals/archive-environments/archive-environments.component";

@Component({
  selector: "app-environments",
  templateUrl: "./environments.component.html",
  styleUrls: ["./environments.component.scss"],
})
export class EnvironmentsComponent implements OnInit {
  public token: string;
  public user: any;

  public environmentsLoading: boolean = true;
  public environmentsSearchText: string = "";

  public environments: any = [];

  public teamID: number;
  public copy = "";

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private modalService: MDBModalService
  ) {}

  ngOnInit(): void {
    this.retrieveUser();
    this.retrieveToken();
    this.retrieveEnvironments();
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  //retrieve environments
  private retrieveEnvironments() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let environments = this._xrPlatformRestService.restfulAPIQuery(
      "/environments",
      "get",
      {},
      options
    );

    environments.subscribe(
      (response) => {
        ;
        this.environments = response.environments;
        this.environmentsLoading = false;
      },
      (error) => {}
    );
  }

  public manageEnvironment(type, index?, targetEnvironment?) {
    this.modalOptions.data = {
      team_id: this.teamID,
      type: type,
      targetEnvironment: targetEnvironment,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-environments-container",
    };

    this.modalFrame = this.modalService.show(
      ManageEnvironmentsComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.environments.push(changedData.environment);
            break;
          case "update":
            this.environments[index] = changedData.environment;
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public archiveEnvironment(targetEnvironment) {
    this.modalOptions.data = {
      teamID: this.teamID,
      targetEnvironment: targetEnvironment,
      type: "delete",
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "archive-event-types-container",
    };
    this.modalFrame = this.modalService.show(
      ArchiveEnvironmentsComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            break;
          case "delete":
            let updatedEnvironments = this.environments.filter(
              (environment) => {
                return environment.id !== changedData.environment.id;
              }
            );

            this.environments = updatedEnvironments;
            break;
        }
      } else {
      }
    });
  }
}
