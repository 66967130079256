import { UntypedFormGroup } from "@angular/forms";
import * as moment from "moment";

// custom validator to check a time field ls later than another time field
// if dates match
export function TimeMustBeLater(
  startTimeName: string,
  EndTimeName: string,
  startDateName: string,
  endDateName: string
) {
  return (formGroup: UntypedFormGroup) => {
    const startTime = formGroup.controls[startTimeName];
    const endTime = formGroup.controls[EndTimeName];
    const startDate = formGroup.controls[startDateName];
    const endDate = formGroup.controls[endDateName];
    const tz: any = moment.tz(moment.tz.guess()).format("z");

    if (endTime.errors && !endTime.errors.timeMustBeLater) {
      // return if another validator has already found an error on the endTime
      return;
    }

    if (endTime.value === null || endDate.value === null) {
      return;
    }

    let startTimeValue = startTime.value;

    if (startTimeValue.indexOf("AM") !== -1) {
      startTimeValue.replace("", "AM");
      startTimeValue += " AM";
    } else {
      startTimeValue.replace("", "PM");
      startTimeValue += " PM";
    }

    let startTimeFormatted = moment(startTimeValue, ["h:mm A"]);

    let startHour = moment(startTimeFormatted).format("H");
    let startMin = moment(startTimeFormatted).format("m");

    let startDay = moment(startDate.value).format("D");
    let startMonth = moment(startDate.value).format("M");
    let startYear = moment(startDate.value).format("YYYY");

    let endTimeValue = endTime.value;

    if (endTimeValue.indexOf("AM") !== -1) {
      endTimeValue.replace("", "AM");
      endTimeValue += " AM";
    } else {
      endTimeValue.replace("", "PM");
      endTimeValue += " PM";
    }

    let endTimeFormatted = moment(endTimeValue, ["h:mm A"]);

    let endHour = moment(endTimeFormatted).format("H");
    let endMin = moment(endTimeFormatted).format("m");

    let endDay = moment(endDate.value).format("D");
    let endMonth = moment(endDate.value).format("M");
    let endYear = moment(endDate.value).format("YYYY");

    if (!startTimeFormatted.isValid() || !endTimeFormatted.isValid()) {
      if (!startTimeFormatted.isValid()) {
        startTime.setErrors({ timeMustBeValid: true });
      }

      if (!endTimeFormatted.isValid()) {
        endTime.setErrors({ timeMustBeValid: true });
      }

      return null;
    }

    if (
      endDay === startDay &&
      endMonth === startMonth &&
      endYear === startYear
    ) {
      if (parseInt(endHour) < parseInt(startHour)) {
        endTime.setErrors({ timeMustBeLater: true });
      } else if (parseInt(endHour) === parseInt(startHour)) {
        if (parseInt(endMin) <= parseInt(startMin)) {
          endTime.setErrors({ timeMustBeLater: true });
        } else {
          endTime.setErrors(null);
        }
      } else {
        endTime.setErrors(null);
      }
    } else {
      endTime.setErrors(null);
    }

    return null;
  };
}
