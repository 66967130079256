import { filter } from "rxjs/operators";
import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { DOCUMENT, TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-edit-team-logo",
  templateUrl: "./edit-team-logo.component.html",
  styleUrls: ["./edit-team-logo.component.scss"],
  providers: [TitleCasePipe],
})
export class EditTeamLogoComponent implements OnInit {
  private token: string;
  public media: any;
  public labels: any;

  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";
  public placeHolderMp3: string = "assets/img/mp3-thumbnail.png";
  public placeHolderWav: string = "assets/img/wav-thumbnail.png";

  //incoming
  public teamID;
  public type;
  public fromLocation: string;
  public targetAsset: any;

  //content
  public title = "Select Media";

  //tracking
  public isClean: boolean = true;

  //form vars
  public mediaForm: UntypedFormGroup;
  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Select",
  };
  public pdfOptionsGroup: any;
  public imageOptionsGroup: any;
  public videoOptionsGroup: any;
  public formLoading: boolean = false;
  public formState: string = "active";

  //data from media component
  public mediaData: any;

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  //existing values
  public existingValues: any;

  public actionButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
      info: false,
      share: false,
      delete: false,
    };

  public columnVisibility: {
    thumbnail: boolean;
    pdfStatus: boolean;
    fileType: boolean;
    owner: boolean;
    urlType: boolean;
    dateCreated: boolean;
    shares: boolean;
  } = {
      thumbnail: true,
      pdfStatus: false,
      fileType: true,
      owner: true,
      urlType: false,
      dateCreated: true,
      shares: false,
    };

  public selectable: boolean = true;

  public selectedMedia: any = [];

  public filterByType: string = "all";

  public selectableType: string = "single";

  public clickableThumb: boolean = false;

  public systemButtons: any = {
    action: null,
    cancel: null,
  };

  public systemInteraction: string = "settings_media";

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _mediaManagementService: MediaManagementServicesService,
    public eventSettingsManageFrame: MDBModalRef,
    public TitleCase: TitleCasePipe,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.retrieveLabels();
    this.title = `Select ${this.TitleCase.transform(
      this.labels.media.singular
    )}`;

    this.filterByType = this.type;

    if (this.type === "image") {
      this.title = "Select a logo image";
    } else if (this.type === "pdf") {
      this.title = "Select a PDF";
    } else if (this.type === "video") {
      this.title = "Select a Video";
    }

    this.retrieveToken();
    this.configureExisting();
    this.retrieveMedia();

  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private configureExisting() {
    this.existingValues = {};
    this.existingValues[this.type] = null;

    if (this.targetAsset) this.existingValues[this.type] = this.targetAsset;

    this.selectedMedia.push(this.existingValues[this.type]);
  }

  private retrieveMedia() {
    let retrieveMedia = this._mediaManagementService
      .mediaService(this.teamID, this.token)
      .toPromise();

    retrieveMedia.then(
      (media) => {
        this.media = this.processMedia(media);
        this.media = this.parseMedia();
        this.buildForm();
      },
      (error) => {
        //@todo: handle error
      }
    );
  }

  private processMedia(incoming) {
    let media = [];

    media = incoming.assets.filter((asset) => {
      return !asset.is_preview_image;
    });

    media.forEach((thisMedia) => {
      thisMedia.loading = "loaded";
    });

    return media;
  }

  private parseMedia() {
    let parsed = {
      pdfs: [],
      images: [],
      videos: [],
    };

    let media = this.media;

    media.forEach((asset) => {
      let file_ext =
        asset.file_ext !== null ? asset.file_ext.toLowerCase() : "";

      if (file_ext === "pdf") {
        parsed.pdfs.push(asset);
      } else if (
        file_ext === "jpg" ||
        file_ext === "png" ||
        file_ext === "gif"
      ) {
        parsed.images.push(asset);
      } else if (file_ext === "mp4") {
        parsed.videos.push(asset);
      }
    });

    return parsed;
  }

  private buildForm() {
    if (this.type === "pdf") {
      let pdfOptionsGroup = [];

      this.media.pdfs.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        pdfOptionsGroup.push(thisValue);
      });

      this.pdfOptionsGroup = pdfOptionsGroup;
    }

    if (this.type === "image") {
      let imageOptionsGroup = [];

      this.media.images.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        if (
          this.existingValues.image !== null &&
          this.existingValues.image.id === thisMedia.id
        )
          this.existingValues.image.src = thisMedia.thumbnail_asset_url;

        imageOptionsGroup.push(thisValue);
      });

      this.imageOptionsGroup = imageOptionsGroup;
    }

    if (this.type === "video") {
      let videoOptionsGroup = [];

      this.media.videos.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        if (
          this.existingValues !== undefined &&
          this.existingValues.video !== null &&
          this.existingValues.video.id === thisMedia.id
        )
          this.existingValues.video.src = thisMedia.thumbnail_asset_url;

        videoOptionsGroup.push(thisValue);
      });

      this.videoOptionsGroup = videoOptionsGroup;
    }

    this.mediaForm = new UntypedFormGroup({
      pdfOptionsValue: new UntypedFormControl(""),
      imageOptionsValue: new UntypedFormControl(""),
      videoOptionsValue: new UntypedFormControl(""),
    });

    this.formLoading = true;
    this.formChanges();
  }

  get imageOptionsArray() {
    return this.mediaForm.get("imageOptionsArray") as UntypedFormArray;
  }

  get videoOptionsArray() {
    return this.mediaForm.get("videoOptionsArray") as UntypedFormArray;
  }

  get pdfOptionsValue() {
    return this.mediaForm.get("pdfOptionsValue") as UntypedFormArray;
  }

  get imageOptionsValue() {
    return this.mediaForm.get("imageOptionsValue") as UntypedFormArray;
  }

  get videoOptionsValue() {
    return this.mediaForm.get("videoOptionsValue") as UntypedFormArray;
  }

  private formChanges() {
    this.mediaForm.get("pdfOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });

    this.mediaForm.get("imageOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });

    this.mediaForm.get("videoOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });
  }

  public resetMedia(type) {
    this.btnLabel.main = "Set to None";

    if (type === "pdf") {
      this.existingValues.pdf = "";
      this.existingValues.pdfSrc = "";
      this.mediaForm.controls["pdfOptionsValue"].setValue("");
    } else if (type === "image") {
      this.existingValues.image = "";
      this.existingValues.imgSrc = "";
      this.mediaForm.controls["imageOptionsValue"].setValue("");
    } else if (type === "video") {
      this.existingValues.video = "";
      this.existingValues.vidSrc = "";
      this.mediaForm.controls["videoOptionsValue"].setValue("");
    }
  }

  public removeLogo() {
    this.selectedMedia = [];
    this.isClean = false;
  }

  public manageMedia() {
    let outgoing = {
      action: "update",
      media: this.mediaData,
      type: this.type,
    };
    this.outgoing.next(outgoing);
    this.closeModal();
  }

  public outputSelected(asset) {
    console.log("event in outputSelected", event);

    this.mediaData = asset;
    this.selectedMedia = [asset];
    this.isClean = false;
  }

  public onParentActions(incoming) {
    if (incoming.action !== undefined && incoming.action === "closeModal")
      this.closeModal();
  }

  public closeModal() {
    this.eventSettingsManageFrame.hide();
  }
}
