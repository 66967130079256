<div class="modal-content-wrapper media-uploads">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      {{ labels.media.singular | titlecase }} Upload
    </h4>
    <button
      *ngIf="isClean && !isQueueProcessing"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean || isQueueProcessing"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <div class="media-uploads">
        <div class="upload-container">
          <div class="row" *ngIf="!loading">
            <div class="col-12">
              <dropzone-custom
                class="dropzone dropzone-container dz-multiple dz-clickable"
                [config]="config"
                #drpzone
                fxLayout="row"
                fxLayoutAlign="start strech"
                fxFlex="auto"
                message="Add {{
                  labels.media.singular
                }} to the upload queue by dragging it here or clicking to open the file selection tool. When you're ready to upload, click &quot;Start Upload&quot;"
                (init)="onUploadInit($event)"
                (error)="onUploadError($event)"
                (success)="onUploadSuccess($event)"
              ></dropzone-custom>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div #alertIcon>
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              </div>
              <div #successIcon>
                <fa-icon [icon]="faCircleCheck"></fa-icon>
              </div>
              <div #pendingIcon>
                <fa-icon [icon]="faCloudArrowUp"></fa-icon>
              </div>
              <div #toolTipPrivatePublic>
                <fa-icon
                  [icon]="faCircleQuestion"
                  pTooltip="Enter your username"
                  tooltipPosition="right"
                ></fa-icon>
              </div>
              <div class="files style-table table table-striped" id="previews">
                <div
                  #customPreviewTemplate
                  id="template"
                  class="file-row style-table-row"
                >
                  <!-- This is used as the file preview template -->
                  <div class="style-table-cell actions">
                    <button
                      data-dz-remove
                      class="btn-flat btn-icon remove-standard"
                    >
                      <fa-icon [icon]="faMinusSquare"></fa-icon>
                    </button>
                  </div>
                  <div class="style-table-cell preview">
                    <div class="thumbnail-container">
                      <div class="img-thumbnail"></div>
                    </div>
                  </div>
                  <div class="style-table-cell filename">
                    <span class="ellipsis-wrapper">
                      <span class="text-ellipsis" data-dz-name></span>
                    </span>
                    <div class="extras"></div>
                  </div>
                  <div class="style-table-cell size">
                    <span data-dz-size></span>
                  </div>
                  <div class="style-table-cell progress-wrapper">
                    <div
                      class="progress progress-striped active"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="0"
                    >
                      <div
                        class="progress-bar progress-bar-success"
                        style="width: 0%"
                        data-dz-uploadprogress
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="guidelines-container">
          <p class="semi-bold">Supported Formats and Limitations</p>
          <div class="guardrails">
            <div
              *ngFor="let guardrail of guardrailSettings.guardrail_types"
              class="guardail-type"
            >
              <h6>{{ guardrail.label }}</h6>
              <ul>
                <li>Formats: {{ getFormats(guardrail.guardrail_formats) }}</li>
                <li *ngFor="let setting of guardrail.guardrail_settings">
                  {{ setting.label }}:
                  <span *ngIf="setting.value_unit !== ''">{{
                    setting.value_unit
                  }}</span
                  ><span *ngIf="setting.value_unit === ''">{{
                    setting.value
                  }}</span>
                  {{ setting.guardrail_value_type.label }}
                </li>
              </ul>
            </div>
          </div>
          <ng-container *ngIf="!isParticipant">
            <hr>
            <p class="semi-bold">What is Private Media?</p>
            <p>Checking "Private" will store the media item in your private library and it will not be available in the public media library. This means this media item will only be avaialable to you.</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      [disabled]="isClean || isQueueProcessing || !isValid"
      (click)="startUpload()"
    >
      Start Upload
    </button>
    <button
      *ngIf="isClean && !isQueueProcessing"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean || isQueueProcessing"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>
<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <div class="left-arrow"></div>
    <p class="semi-bold">
      Warning! Closing this window will cancel the upload of all files.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        (click)="closePopOvers()"
      >
        Continue Uploads
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        (click)="closeModal(true)"
      >
        Cancel All Uploads
      </button>
    </div>
  </div></ng-template
>
