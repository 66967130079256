import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-select-meta-media",
  templateUrl: "./select-meta-media.component.html",
  styleUrls: ["./select-meta-media.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe]
})
export class SelectMetaMediaComponent implements OnInit {
  //persistent
  private token: string;
  public media: any;
  public labels: any;

  //incoming
  public teamID;
  public type;
  public fromLocation: string;
  public targetAsset: any;
  public name;
  public experience: any;

  //content
  public title = "Select Media";

  //form vars
  public scheduleForm: UntypedFormGroup;
  public btnLabel: { close: string; main: string } = {
    close: "Close",
    main: "Select",
  };
  public pdfOptionsGroup: any;
  public imageOptionsGroup: any;
  public videoOptionsGroup: any;
  public formLoading: boolean = false;
  public formState: string = "active";

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  //existing values
  public existingValues: any;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private _mediaManagementService: MediaManagementServicesService,
    private _clientManagementService: ClientManagementService,
    public scheduleGroupFrame: MDBModalRef,
    public TitleCase: TitleCasePipe
  ) {}

  ngOnInit(): void {
    ;

    this.retrieveLabels();
    this.title = `Select ${this.TitleCase.transform(this.labels.media.singular)}`;

    if (this.type === "image") {
      this.title = "Select a logo image";
    } else if (this.type === "pdf") {
      this.title = "Select a PDF";
    } else if (this.type === "video") {
      this.title = "Select a Video";
    }

    this.retrieveToken();
    
    if (this.fromLocation == "eventProps") {
      this.configureExisting();
    } else {
      this.configureLegacyExisting();
    }

    this.retrieveMedia();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private configureExisting(){
    this.existingValues = {};
    this.existingValues[this.type] = null;

    if(this.targetAsset) this.existingValues[this.type] = this.targetAsset;

    ;
  }

  private configureLegacyExisting() {
    let meta = this.experience.meta;

    ;
    ;

    this.existingValues = {};
    this.existingValues[this.type] = null;

    if (
      meta !== undefined &&
      meta !== null &&
      meta[this.name] !== undefined &&
      meta[this.name] !== null &&
      meta[this.name].length
    ) {
      this.existingValues[this.type] = meta[this.name][0];
      this.existingValues[this.type].src = "";
    }

    ;
  }

  private retrieveMedia() {
    let retrieveMedia = this._mediaManagementService.mediaService(
      this.teamID,
      this.token
    ).toPromise();

    retrieveMedia.then(
      (media) => {
        
        this.media = this.processMedia(media);
        this.media = this.parseMedia();
        this.buildForm();
      },
      (error) => {
        //@todo: handle error
      }
    );
  }

  private processMedia(incoming) {
    let media = [];

    media = incoming.assets.filter((asset) => {
      return !asset.is_preview_image;
    });

    media.forEach((thisMedia) => {
      thisMedia.loading = "loaded";
    });

    return media;
  }

  private parseMedia() {
    let parsed = {
      pdfs: [],
      images: [],
      videos: [],
    };

    let media = this.media;

    media.forEach((asset) => {

      let file_ext = asset.file_ext !== null ? asset.file_ext.toLowerCase() : "";

      if (file_ext === "pdf") {
        parsed.pdfs.push(asset);
      } else if (
        file_ext === "jpg" ||
        file_ext === "png" ||
        file_ext === "gif"
      ) {
        parsed.images.push(asset);
      } else if (file_ext === "mp4") {
        parsed.videos.push(asset);
      }
    });

    return parsed;
  }

  private buildForm() {
    
    if (this.type === "pdf") {
      
      let pdfOptionsGroup = [];

      this.media.pdfs.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        pdfOptionsGroup.push(thisValue);
      });

      this.pdfOptionsGroup = pdfOptionsGroup;
    }

    if (this.type === "image") {
      
      let imageOptionsGroup = [];

      this.media.images.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        if (
          this.existingValues.image !== null &&
          this.existingValues.image.id === thisMedia.id
        )
          this.existingValues.image.src = thisMedia.thumbnail_asset_url;

        imageOptionsGroup.push(thisValue);
      });

      this.imageOptionsGroup = imageOptionsGroup;
    }

    if (this.type === "video") {
      
      let videoOptionsGroup = [];

      this.media.videos.forEach((thisMedia) => {
        let thisValue = {
          id: thisMedia.id,
          name: thisMedia.name,
          src: thisMedia.thumbnail_asset_url,
          file_ext: thisMedia.file_ext,
        };

        if (
          this.existingValues.video !== null &&
          this.existingValues.video.id === thisMedia.id
        )
          this.existingValues.video.src = thisMedia.thumbnail_asset_url;

        videoOptionsGroup.push(thisValue);
      });

      this.videoOptionsGroup = videoOptionsGroup;
    }

    this.scheduleForm = new UntypedFormGroup({
      pdfOptionsValue: new UntypedFormControl(""),
      imageOptionsValue: new UntypedFormControl(""),
      videoOptionsValue: new UntypedFormControl(""),
    });

    this.formLoading = true;
    this.formChanges();
  }

  get imageOptionsArray() {
    return this.scheduleForm.get("imageOptionsArray") as UntypedFormArray;
  }

  get videoOptionsArray() {
    return this.scheduleForm.get("videoOptionsArray") as UntypedFormArray;
  }

  get pdfOptionsValue() {
    return this.scheduleForm.get("pdfOptionsValue") as UntypedFormArray;
  }

  get imageOptionsValue() {
    return this.scheduleForm.get("imageOptionsValue") as UntypedFormArray;
  }

  get videoOptionsValue() {
    return this.scheduleForm.get("videoOptionsValue") as UntypedFormArray;
  }

  private formChanges() {
    this.scheduleForm.get("pdfOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });

    this.scheduleForm.get("imageOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });

    this.scheduleForm.get("videoOptionsValue").valueChanges.subscribe((val) => {
      if (val) {
        this.btnLabel.main = "Select";
      } else {
        this.btnLabel.main = "set to None";
      }
    });
  }

  public resetMedia(type) {
    this.btnLabel.main = "Set to None";

    if (type === "pdf") {
      this.existingValues.pdf = "";
      this.existingValues.pdfSrc = "";
      this.scheduleForm.controls["pdfOptionsValue"].setValue("");
    } else if (type === "image") {
      this.existingValues.image = "";
      this.existingValues.imgSrc = "";
      this.scheduleForm.controls["imageOptionsValue"].setValue("");
    } else if (type === "video") {
      this.existingValues.video = "";
      this.existingValues.vidSrc = "";
      this.scheduleForm.controls["videoOptionsValue"].setValue("");
    }
  }

  public manageMedia() {
    let incomingValues = this.scheduleForm.value;
    this.formState = "processing";

    let outBoundMedia = this.processParsedMediaForUpdate(incomingValues);
    let outgoing = {
      action: 'update',
      media: outBoundMedia,
      type: this.type,
      name: this.name,
    };

    this.outgoing.next(outgoing);
    this.scheduleGroupFrame.hide();
  }

  private processParsedMediaForUpdate(incomingMedia) {
    ;

    let storedMedia = [];

    if (incomingMedia.pdfOptionsValue !== "") {
      let retrieveThisMedia = this.determineCurrentMedia(
        this.media.pdfs,
        incomingMedia.pdfOptionsValue
      );
      let thisMedia = retrieveThisMedia[0];

      let thisStoredMedia = {
        created: thisMedia.created,
        description: thisMedia.description,
        file_ext: thisMedia.file_ext,
        id: thisMedia.id,
        modified: thisMedia.modified,
        name: thisMedia.name,
        type: thisMedia.type,
        uuid: thisMedia.uuid,
      };

      storedMedia.push(thisStoredMedia);
    }

    if (incomingMedia.imageOptionsValue !== "") {
      let retrieveThisMedia = this.determineCurrentMedia(
        this.media.images,
        incomingMedia.imageOptionsValue
      );
      let thisMedia = retrieveThisMedia[0];

      let thisStoredMedia = {
        created: thisMedia.created,
        description: thisMedia.description,
        file_ext: thisMedia.file_ext,
        id: thisMedia.id,
        modified: thisMedia.modified,
        name: thisMedia.name,
        type: thisMedia.type,
        uuid: thisMedia.uuid,
      };

      storedMedia.push(thisStoredMedia);
    }

    if (incomingMedia.videoOptionsValue !== "") {
      let retrieveThisMedia = this.determineCurrentMedia(
        this.media.videos,
        incomingMedia.videoOptionsValue
      );
      let thisMedia = retrieveThisMedia[0];

      let thisStoredMedia = {
        created: thisMedia.created,
        description: thisMedia.description,
        file_ext: thisMedia.file_ext,
        id: thisMedia.id,
        modified: thisMedia.modified,
        name: thisMedia.name,
        type: thisMedia.type,
        uuid: thisMedia.uuid,
      };

      storedMedia.push(thisStoredMedia);
    }

    return storedMedia;
  }

  private determineCurrentMedia(media, media_id) {
    return media.filter((thisMedia) => {
      return thisMedia.id === media_id;
    });
  }
}
