<div class="background-replacement" (click)="turnConfirmOn(confirmModal)"></div>
<div class="class-wrapper" [ngClass]="{ 'confirm-on': confirmOn }">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Manage {{labels.media.singular|titlecase}} Owner</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="ownerModal.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-3 p-0">
    <div class="row flex-nowrap">
      <div class="col-3">
        <mdb-card
          [ngStyle]="{
            'background-image':
              'url(' +
              (media_item.thumbnail_asset_url
                ? media_item.thumbnail_asset_url
                : !media_item.thumbnail_asset_url &&
                  media_item.file_ext === 'pdf'
                ? placeHolderPdf
                : !media_item.thumbnail_asset_url &&
                  media_item.file_ext === 'mp4'
                ? placeHolderMp4
                : !media_item.thumbnail_asset_url &&
                  media_item.file_ext === 'webp'
                ? placeHolderWebp
                : placeHolderImg) +
              ')'
          }"
          class="media-thumbnail"
        >
        </mdb-card>
        <p class="file-name">{{ media_item.name }}</p>
      </div>
      <div class="vertical-divider"></div>
      <div class="col-9">
        <p>Update {{labels.media.singular|titlecase}} Ownership</p>
        <ul class="list-unstyled media-detail-list">
          <li class="media-title">
            <strong>Uploaded by: </strong>{{ media_item.user_id }}
          </li>
          <li><strong>Owned by: : </strong>{{ media_item.owner_username }}</li>
        </ul>
        <hr />
        <div class="d-flex flex-row align-items-center mb-2">
          <div [formGroup]="newOwner" class="w-100">
            <div class="md-form">
            <input
              type="text"
              formControlName="usernameselector"
              class="completer-input form-control mdb-autocomplete"
              [ngModel]="searchText | async"
              (ngModelChange)="searchText.next($event)"
              [mdbAutoCompleter]="auto"
              placeholder="Search new owner by username"
            />
            <mdb-auto-completer
              #auto="mdbAutoCompleter"
              textNoResults="No matches"
            >
              <mdb-option
                *ngFor="let option of results | async"
                [value]="option"
              >
                {{ option }}
              </mdb-option>
            </mdb-auto-completer>
          </div>
          </div>

          <button
            mdbBtn
            type="button"
            mdbWavesEffect
            class="btn-outline-sec"
            (click)="clickChangeOwner()"
          >
            Change Owner
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        (click)="ownerModal.hide()"
      >
        Close
      </button>
    </div>
  </div>
</div>
<!-- Confirm Modal -->
<div
  mdbModal
  #confirmModal="mdbModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModal"
  aria-hidden="true"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h5 class="modal-title w-100">Confirm</h5>
      </div>
      <div class="modal-body">
        <p>
          <strong>Are you sure?</strong>
        </p>
        <p>
          Closing the <strong>Manage Asset Owner</strong> panel without saving
          will erase all your unsaved changes.
        </p>
      </div>
      <div class="modal-footer">
        <button
          mdbBtn
          type="button"
          mdbWavesEffect
          class="theme-modal-button btn"
          (click)="this.closeConfirm(confirmModal)"
        >
          Continue Editing
        </button>
        <button
          mdbBtn
          mdbWavesEffect
          type="button"
          size="md"
          color="link"
          (click)="this.closeAllModals(confirmModal)"
        >
          Close Without Saving
        </button>
      </div>
    </div>
  </div>
</div>
