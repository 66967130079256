<div class="modal-header text-center">
  <h4 class="modal-title w-100">Add Event Type</h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="eventTypeFrame.hide()"
    mdbTooltip="Close"
    placement="bottom"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body mt-3 p-0">
  <div class="row mb-3">
    <div class="col-12">
      <form class="px-5" [formGroup]="eventTypesFormGroup" autocomplete="off">
        <div class="md-form">
          <input
            type="text"
            id="event_type"
            formControlName="eventtype_name"
            [ngModel]="searchText | async"
            (ngModelChange)="searchText.next($event)"
            [mdbAutoCompleter]="auto"
            class="completer-input form-control mdb-autocomplete"
            mdbInput
          />
          <label for="event_type">Add Event Type</label>
          <mdb-auto-completer
            #auto="mdbAutoCompleter"
            textNoResults="No results found"
          >
            <mdb-option *ngFor="let option of results | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    size="md"
    color="link"
    data-dismiss="modal"
    (click)="addEventType()"
  >
    Add
  </button>
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    size="md"
    color="link"
    data-dismiss="modal"
    (click)="eventTypeFrame.hide()"
  >
    Close
  </button>
</div>
