<div class="modal-header text-center">
  <h4 class="modal-title w-100 font-weight-bold">
    <mdb-icon fas icon="key"></mdb-icon> {{ formTitle }}
  </h4>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="pwFrame.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body m-3 p-0">
  <div
    *ngIf="errorMsg !== ''"
    class="alert alert-danger text-capitalize"
    role="alert"
    [innerHTML]="errorMsg"
  ></div>
  <div
    *ngIf="statusMsg !== ''"
    class="alert alert-info text-capitalize"
    role="alert"
    [innerHTML]="statusMsg"
  ></div>
  <form class="text-center py-3" [formGroup]="pwForm">
    <!--password-->
    <div class="md-form mb-5">
      <input
        type="password"
        id="pwform-password"
        class="form-control"
        formControlName="password"
        mdbInput
        mdbValidate
      />
      <label for="pwform-password">Password</label>
      <mdb-error
        *ngIf="
          password.invalid &&
          !password.errors.minlength &&
          (password.dirty || password.touched)
        "
        >This field is required
      </mdb-error>
      <mdb-error *ngIf="password.invalid && password.errors.minlength">
        Password must be at least 6 characters
      </mdb-error>
    </div>
    <!--end password-->

    <!--confirm password-->
    <div class="md-form mb-5">
      <input
        type="password"
        id="pwform-confirm_password"
        class="form-control"
        formControlName="confirm_password"
        mdbInput
        mdbValidate
      />
      <label for="pwform-confirm_password">Re-enter Password</label>
      <mdb-error
        *ngIf="
          confirm_password.invalid &&
          !confirm_password.errors.minlength &&
          !confirm_password.errors.mustMatch &&
          (confirm_password.dirty || confirm_password.touched)
        "
        >This field is required
      </mdb-error>
      <mdb-error
        *ngIf="
          confirm_password.invalid &&
          confirm_password.errors.minlength &&
          !confirm_password.errors.mustMatch
        "
      >
        Password must be at least 6 characters
      </mdb-error>
      <mdb-error
        *ngIf="
          confirm_password.invalid &&
          !confirm_password.errors.minlength &&
          confirm_password.errors.mustMatch
        "
      >
        Passwords do not match
      </mdb-error>
    </div>
    <!--end confirm password-->
  </form>
</div>

<div class="modal-footer">
  <button
    *ngIf="!loading"
    mdbBtn
    mdbWavesEffect
    type="button"
    color="link"
    class="theme-modal-button btn"
    [disabled]="!pwForm.valid || formState === 'processing'"
    (click)="managePassword()"
  >
    {{ btnLabel.main }}
  </button>
  <button
    mdbBtn
    mdbWavesEffect
    type="button"
    color="link"
    class="theme-modal-cancel-button btn"
    data-dismiss="modal"
    (click)="pwFrame.hide()"
  >
    {{ btnLabel.close }}
  </button>
</div>
