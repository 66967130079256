import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { AddPropOptionModalComponent } from "../add-prop-option-modal/add-prop-option-modal.component";
@Component({
  selector: "app-view-prop-modal",
  templateUrl: "./view-prop-modal.component.html",
  styleUrls: ["./view-prop-modal.component.scss"],
})
export class ViewPropModalComponent implements OnInit {
  public team_id: number;
  public prop: any;

  public options: any;

  public token: string;
  public loading: boolean;

  public addPropOptionsModal: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    public viewOptionsModal: MDBModalRef,
    private modalService: MDBModalService
  ) {}

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveOptions();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveOptions() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let options = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/prop/" + this.prop + "/options",
      "get",
      {},
      getOptions
    );

    options.subscribe((response) => {
      this.options = response.options;
      this.loading = false;
      (error) => {
        //@todo: handle error
      };
    });
  }

  public openAddOptionModal() {
    this.modalOptions.data = {
      team_id: this.team_id,
      prop: this.prop,
    };

    this.modalOptions.class = "modal-dialog";

    this.addPropOptionsModal = this.modalService.show(
      AddPropOptionModalComponent,
      this.modalOptions
    );
    this.addPropOptionsModal.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.options.push(changedData.option);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public removeOption(option_id, index) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let optionArchive = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/prop/" + this.prop + "/option/" + option_id + "/archive",
      "put",
      {},
      getOptions
    );
    optionArchive.subscribe((response) => {
      this.options.splice(index, 1);
      if (response.error != 418) {
      }
      (error) => {
        //@todo: handle error
      };
    });
  }
}
