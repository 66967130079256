<div class="background-replacement" (click)="turnConfirmOn(confirmModal)"></div>
<div class="class-wrapper" [ngClass]="{ 'confirm-on': confirmOn }">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>

  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">
      <mdb-icon far icon="calendar-alt"></mdb-icon> {{labels.schedule.singular|titlecase}} {{labels.user.plural|titlecase}} for {{labels.event.singular|titlecase}}
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="scheduleFrame.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3 p-0">
    <div class="row">
      <div class="col-12">
        <p class="modal-body p-0">
          {{ msgs.actionMsg | capitalizeFirst:[] }} <strong>{{ targetEvent.name }}</strong>
        </p>
      </div>
    </div>
    <form [formGroup]="scheduleForm" class="text-center" autocomplete="off">
      <div class="md-form mb-5">
        <input
          type="text"
          id="scheduleUser"
          formControlName="scheduleUser"
          [ngModel]="userSearchText | async"
          (ngModelChange)="userSearchText.next($event)"
          [mdbAutoCompleter]="auto"
          placeholder="Username"
          class="completer-input form-control mdb-autocomplete"
          mdbInput
        />
        <label for="scheduleUser">{{ labels.user.singular | titlecase }}</label>
        <mdb-auto-completer
          #auto="mdbAutoCompleter"
          textNoResults="No results found"
        >
          <mdb-option
            *ngFor="let option of userResults | async"
            [value]="option"
          >
            {{ option }}
          </mdb-option>
        </mdb-auto-completer>
        <mdb-error
          *ngIf="
            scheduleUser &&
            scheduleUser.invalid &&
            (scheduleUser.dirty || scheduleUser.touched)
          "
        >
          Input invalid
        </mdb-error>
        <mdb-success
          *ngIf="
            scheduleUser &&
            scheduleUser.valid &&
            (scheduleUser.dirty || scheduleUser.touched)
          "
        >
          Input valid
        </mdb-success>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      data-dismiss="modal"
      [disabled]="!scheduleForm.valid || formState === 'processing'"
      (click)="scheduleUsers()"
    >
      {{ btnLabel.main | titlecase }}
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="scheduleFrame.hide()"
    >
      {{ btnLabel.close }}
    </button>
  </div>
</div>
  <!-- confirm modal -->
  <div
    mdbModal
    #confirmModal="mdbModal"
    class="modal confirm-modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100">Confirm</h4>
        </div>
        <div class="modal-body">
          <p>
            <strong>Are you sure?</strong>
          </p>
          <p>All form fields will be cleared upon exit.</p>
        </div>
        <div class="modal-footer">
          <button
            mdbBtn
            type="button"
            mdbWavesEffect
            class="theme-modal-button btn"
            (click)="this.closeConfirm(confirmModal)"
          >
            Continue Editing
          </button>
          <button
            mdbBtn
            mdbWavesEffect
            type="button"
            size="md"
            color="link"
            (click)="this.closeAllModals(confirmModal)"
          >
            Close Without Saving
          </button>
        </div>
      </div>
    </div>
  </div>

