<div class="background-replacement" (click)="turnConfirmOn(confirmModal)"></div>
<div class="class-wrapper" [ngClass]="{ 'confirm-on': confirmOn }">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <mdb-icon
        *ngIf="action === 'remove'"
        fas
        icon="minus-circle"
        size="2x"
        class="d-flex justify-content-center my-2 text-danger"
      >
      </mdb-icon>
      {{ title }}
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="addclassroom.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-3 p-0">
    <div class="row">
      <div class="col-12">
        <form
          *ngIf="action === 'add'"
          class="px-5"
          [formGroup]="roomFormGroup"
          autocomplete="off"
        >
          <div class="md-form">
            <input
              type="text"
              id="room_name"
              formControlName="room_name"
              [ngModel]="searchText | async"
              (ngModelChange)="searchText.next($event)"
              [mdbAutoCompleter]="auto"
              placeholder="Name of {{labels.room.singular}} to add"
              class="completer-input form-control mdb-autocomplete"
              mdbInput
            />
            <label for="room_name">{{labels.room.singular|titlecase}} Name</label>
            <mdb-auto-completer
              #auto="mdbAutoCompleter"
              textNoResults="No results found"
            >
              <mdb-option
                *ngFor="let option of results | async"
                [value]="option"
              >
                {{ option }}
              </mdb-option>
            </mdb-auto-completer>
          </div>
        </form>
        <div *ngIf="action === 'remove'">
          <p class="text-center">
            This will remove
            <strong class="color-theme-base-medium-dark">{{
              targetRoom.name
            }}</strong>
            from
            <strong class="color-theme-base-medium-dark">
              {{ event.name }}</strong
            >
          </p>
          <p class="text-center"><strong>Are you sure?</strong></p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        [ngClass]="action == 'remove' ? 'btn-danger' : 'theme-modal-button btn'"
        size="md"
        data-dismiss="modal"
        (click)="roomActions()"
      >
        {{ btnLabel.main }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        data-dismiss="modal"
        (click)="addclassroom.hide()"
      >
        Close
      </button>
    </div>
  </div>
</div>

<div
  mdbModal
  #confirmModal="mdbModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModal"
  aria-hidden="true"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel">Confirming Close</h4>
      </div>
      <div class="modal-body">
        <p>
          Are you sure you want to close this form? All form fields will be
          cleared upon exit.
        </p>
      </div>
      <div class="modal-footer">
        <button
          mdbBtn
          class="waves-light theme-action-button"
          data-dismiss="modal"
          mdbWavesEffect
          (click)="this.closeAllModals(confirmModal)"
        >
          Yes, Close Form
        </button>
        <button
          mdbBtn
          class="waves-light theme-action-button"
          data-dismiss="modal"
          mdbWavesEffect
          (click)="this.closeConfirm(confirmModal)"
        >
          No, Continue Editing
        </button>
      </div>
    </div>
  </div>
</div>
