import { CoolLocalStorage } from "@angular-cool/storage";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { Subject } from "rxjs";
import { SelectMetaMediaComponent } from "../select-meta-media/select-meta-media.component";
import { EventSettingsManageMediaComponent } from "src/app/modules/event-management/modals/event-settings-manage-media/event-settings-manage-media.component";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-event-settings-modal",
  templateUrl: "./event-settings-modal.component.html",
  styleUrls: ["./event-settings-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EventSettingsModalComponent implements OnInit {
  public clientSettings: any;
  private token: string;
  public labels: any;
  public user: any;

  //incoming
  public teamID: number;
  public targetEvent: any;

  //debouncing
  private debounceWaitTime: number = 5000;
  private debounceSubject = new Subject();
  private removePropSubject = new Subject();

  //feedback response
  public title = "";
  public icon = "";
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
    actionMsg: "",
  };
  public continueType: string;

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
    close: "Close",
    main: "Update",
    reset: "",
    retry: "Retry",
  };

  //Event Props API
  public props: any = [];
  public showProps: boolean = true;
  public propsLoading: boolean = true;

  public selectMediaFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  private outgoing: Subject<any> = new Subject();

  // notification
  // public updating: boolean = false;
  // public saved: boolean = false;
  public status: string = "";
  public currentUpdateIndex: number = 0;

  // placeholders
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpeg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";
  public activeHolderImage: string = "assets/img/image-thumbnail-selected.png";
  public activeHolderWebp: string = "assets/img/webp-thumbnail-selected.png";
  public activeHolderPdf: string = "assets/img/pdf-thumbnail-selected.png";
  public activeHolderMp4: string = "assets/img/mp4-thumbnail-selected.png";

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private _clientManagementService: ClientManagementService,
    private modalService: MDBModalService,
    private _settingsService: SettingsService,
    public eventSettingFrame: MDBModalRef
  ) {}

  ngOnInit(): void {
    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );
    this.props = this.targetEvent.props;
    ;
    ;

    //subscribe to debounce subject
    this.debounceSubject
      .pipe(debounceTime(350))
      .subscribe((params: any) => this.runUpdate(params));
    this.removePropSubject
      .pipe(debounceTime(350))
      .subscribe((params: any) => this.removeEventProp(params));

    this.retrieveToken();
    this.retrieveLabels();
    this.retrieveUser();
    this.title = this.labels.event.singular + " Properties";

    this.retrieveEventProps();
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveEventProps() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {};

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/schedule/" + this.targetEvent.id + "/props",
      "get",
      data,
      getOptions
    );

    eventProps.subscribe((response) => {
      ;
      if (response.error != 418) {
        this.props = this.formatEventProps(response.props);
        console.log(
          "props in eventSettingsModal::retrieveEventProps()",
          this.props
        );
        this.propsLoading = false;
      }
    });
  }

  private formatEventProps(props) {
    let temp_props = [];
    props.forEach((element) => {
      ;
      if (element.props.constrained == false) {
        if (
          element.props.type == "boolean" &&
          element.props.unconstrained_default == "false"
        ) {
          element.props.unconstrained_default = false;
        } else if (
          element.props.type == "boolean" &&
          element.props.unconstrained_default == "true"
        ) {
          element.props.unconstrained_default = true;
        }
        temp_props.push(element);
      } else {
        if (element.props.type == "select_list") {
          ;
          element.props.default_value = element.props.constrained_value;
          temp_props.push(element);
        } else if (element.props.type == "mult_select_list") {
          ;
          let defaults = [];
          element.props.options.forEach((option) => {
            if (option.enabled == true) {
              defaults.push(option.id);
            }
          });

          element.props.default_value = defaults;
          temp_props.push(element);
        } else {
          ;
          temp_props.push(element);
        }
      }
    });

    ;

    return temp_props;
  }

  public updateEventProp(prop, event, index) {
    let params = {
      prop: prop,
      event: event,
      index: index,
    };
    this.debounceSubject.next(params);
  }

  public runUpdate(params) {
    this.currentUpdateIndex = params.index;

    this.status = "processing";
    // setTimeout(() => (this.status = ""), 500);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let event = params.event;
    let index = params.index;

    let data = {
      prop_id: params.prop.props.id,
      prop_option_id: null,
      value: null,
    };

    let prop = params.prop.props;
    if (prop.type == "boolean") {
      data.value = event.checked.toString();
    } else if (prop.type == "string" || prop.type == "email") {
      data.value = event;
    } else if (prop.type == "select_list") {
      data.prop_option_id = event;
      data.value = "True";
    } else if (prop.type == "mult_select_list") {
      data.prop_option_id = event;
      prop.options.forEach((element) => {
        if (element.id == event) {
          if (element.enabled == true) {
            data.value = "False";
          } else {
            data.value = "True";
          }
        }
      });
    } else if (prop.type == "number") {
      ;
      ;
      data.value = event;
    } else if (prop.type == "asset") {
      data.value = event;
    }

    ;

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/" + this.targetEvent.id + "/prop/add",
      "post",
      data,
      getOptions
    );

    eventProps.subscribe((response) => {
      ;
      this.status = "saved";
      setTimeout(() => (this.status = ""), 1500);

      if (response.error != 418) {
      }
    });
  }

  private removeEventProp(params) {
    this.currentUpdateIndex = params.index;

    this.status = "processing";
    // setTimeout(() => (this.status = ""), 500);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let targetProp = this.props[params.index];

    console.log(
      "targetProp in EventSettingsModalComponent::removeEventProp()",
      targetProp
    );

    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      `/schedule/${this.targetEvent.id}/prop/${targetProp.prop_id}/remove`,
      "put",
      {},
      getOptions
    );

    eventProps.subscribe((response) => {
      ;
      this.status = "removed";
      setTimeout(() => (this.status = ""), 1500);

      if (response.error != 418) {
      }
    });
  }

  private formatEventProp(prop) {
    let element = prop;
    if (element.props.constrained == false) {
      if (
        element.props.type == "boolean" &&
        element.props.unconstrained_default == "false"
      ) {
        element.props.unconstrained_default = false;
      } else if (
        element.props.type == "boolean" &&
        element.props.unconstrained_default == "true"
      ) {
        element.props.unconstrained_default = true;
      }
    } else {
      if (element.props.type == "select_list") {
        ;
        element.props.default_value = element.props.constrained_value;
      } else if (element.props.type == "mult_select_list") {
        ;
        let defaults = [];
        element.props.options.forEach((option) => {
          if (option.enabled == true) {
            defaults.push(option.id);
          }
        });

        element.props.default_value = defaults;
      } else {
        ;
      }
    }
  }

  public openSelectMediaModal(prop, index) {
    console.log(
      "prop in EventSettingsModalComponent:openSelectMediaModal()",
      prop
    );

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "select-asset-container",
    };

    this.modalOptions.data = {
      teamID: this.teamID,
      type: prop.props.subtype,
      targetAsset: prop.props.asset !== undefined ? prop.props.asset : null,
      fromLocation: "eventProps",
      experience: this.targetEvent,
    };

    this.selectMediaFrame = this.modalService.show(
      EventSettingsManageMediaComponent,
      this.modalOptions
    );

    this.selectMediaFrame.content.outgoing.subscribe((changedData) => {
      console.log(
        "changedData sent back from child modal in EventSettingsModalComponent::openSelectMediaModal()",
        changedData
      );
      ;
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "update":
            ;

            if (changedData.media.length) {
              let retrievedUpdatedAsset = this.retrieveAsset(
                changedData.media[0].uuid
              );

              retrievedUpdatedAsset.then((response) => {
                this.props[index].props.asset = response;
                this.updateEventProp(prop, changedData.media[0].id, index);
              });
            } else {
              this.props[index].props.asset = null;
              let params = {
                prop: prop,
                event: null,
                index: index,
              };
              this.removePropSubject.next(params);
            }
            break;
        }
      } else {
      }
    });
  }

  public openSelectMediaModalLegacy(prop, index) {
    console.log(
      "prop in EventSettingsModalComponent:openSelectMediaModal()",
      prop
    );

    this.modalOptions.data = {
      teamID: this.teamID,
      type: prop.props.subtype,
      targetAsset: prop.props.asset !== undefined ? prop.props.asset : null,
      fromLocation: "eventProps",
      experience: this.targetEvent,
    };

    this.modalOptions.class = "modal-dialog modal-fluid";
    this.selectMediaFrame = this.modalService.show(
      SelectMetaMediaComponent,
      this.modalOptions
    );

    this.selectMediaFrame.content.outgoing.subscribe((changedData) => {
      console.log(
        "changedData sent back from child modal in EventSettingsModalComponent::openSelectMediaModal()",
        changedData
      );
      ;
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "update":
            ;

            if (changedData.media.length) {
              let retrievedUpdatedAsset = this.retrieveAsset(
                changedData.media[0].uuid
              );

              retrievedUpdatedAsset.then((response) => {
                this.props[index].props.asset = response;
                this.updateEventProp(prop, changedData.media[0].id, index);
              });
            } else {
              this.props[index].props.asset = null;
              let params = {
                prop: prop,
                event: null,
                index: index,
              };
              this.removePropSubject.next(params);
            }
            break;
        }
      } else {
      }
    });
  }

  private retrieveAsset(uuid) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveMediaEntity = this._xrPlatformRestService.retrieveEntityData(
      "asset",
      uuid,
      options
    );

    return retrieveMediaEntity.toPromise();
  }

  public checkThumbnail(prop) {
    let passivePlaceholder = this.placeHolderImg;
    let activePlaceholder = this.activeHolderImage;
    let outgoing = passivePlaceholder;

    switch (prop.props.subtype) {
      case "video":
        passivePlaceholder = this.placeHolderMp4;
        activePlaceholder = this.activeHolderMp4;
        break;
    }

    if (prop.props.asset && prop.props.asset.thumbnail_asset_url !== null) {
      outgoing = prop.props.asset.thumbnail_asset_url;
    } else if (
      prop.props.asset &&
      prop.props.asset.thumbnail_asset_url == null
    ) {
      outgoing = activePlaceholder;
    }

    return outgoing;
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    ;
    switch (action) {
      case "close-modal":
        this.eventSettingFrame.hide();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
