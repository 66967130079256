import { Component, OnInit, AfterViewInit, ElementRef } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TitleCasePipe } from "@angular/common";

import { Subject } from "rxjs";

import { MDBModalRef } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { ExperienceServicesService } from "src/app/modules/experience-management/services/experience-services.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

@Component({
  selector: "app-manage-user-group",
  templateUrl: "./manage-user-group.component.html",
  styleUrls: ["./manage-user-group.component.scss"],
  providers: [TitleCasePipe],
})
export class ManageUserGroupComponent implements OnInit, AfterViewInit {
  //persistent
  public labels: any;
  private token: string;
  public attachedEvents: any;
  public loadAttached: boolean = false;
  public assetPrefix: string = "/assets/json";
  public mainModal: any;

  //incoming
  public targetGroup: any;
  public teamID: number;
  public action: string;
  public groups: any;

  //dynamic copy
  public title: string = "Add User Group";
  public icon: string = "add-plus";

  //feedback response
  public formLoading: boolean = false;
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
      errorMsg: "",
      statusMsg: "",
      processingMsg: "",
      actionMsg: "Add a new user group:",
    };
  public formState: string = "active";
  public continueType: string;

  //form vars
  public groupingForm: UntypedFormGroup;
  public showMain: boolean = false;
  public preSelected: {
    group_name: string;
    group_description: string;
  } = {
      group_name: "",
      group_description: "",
    };
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: "Add",
      reset: "Add Another Group",
      retry: "Retry",
    };

  public confirmOn = false;

  //data back to the parent
  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _experienceServices: ExperienceServicesService,
    private _clientManagement: ClientManagementService,
    public scheduleFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private TitleCase: TitleCasePipe,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit() {
    ;
    this.retrieveLabels();

    this.btnLabel.reset = `Add Another ${this.TitleCase.transform(
      this.labels.userGroup.singular
    )}`;

    this.retrieveToken();
    this.buildUpPreselected();
    if (this.action === "delete") {
      this.continueType = "none";
    } else {
      this.continueType = "reset";
      this.makeFormValidatingGroup();
    }

    this.handleDynamicCopy();
    this.showMain = true;
  }

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }
  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private buildUpPreselected() {
    if (this.targetGroup === null) return false;

    this.preSelected.group_name =
      this.targetGroup.name === undefined ? null : this.targetGroup.name;
    this.preSelected.group_description =
      this.targetGroup.description === undefined ||
        this.targetGroup.description === null
        ? "No description"
        : this.targetGroup.description;
  }

  private makeFormValidatingGroup() {
    this.groupingForm = new UntypedFormGroup({
      group_name: new UntypedFormControl(
        this.preSelected.group_name,
        Validators.required
      ),
      group_description: new UntypedFormControl(
        this.preSelected.group_description
      ),
    });

    this.formLoading = true;
  }

  get group_name() {
    return this.groupingForm.get("group_name");
  }

  get group_description() {
    return this.groupingForm.get("group_description");
  }

  public turnConfirmOn(frame) {
    if (this.action === "delete") {
      this.scheduleFrame.hide();
      return true;
    }

    this.mainModal.classList.add("overlay");

    this.confirmOn = true;
    frame.show();
  }

  public closeConfirm(frame) {
    this.confirmOn = false;
    this.mainModal.classList.remove("overlay");
    frame.hide();
  }

  public closeAllModals(frame) {
    this.confirmOn = false;
    frame.hide();
    this.scheduleFrame.hide();
  }

  private handleDynamicCopy() {
    this.title = "Add " + this.labels.userGroup.singular;
    this.msgs.actionMsg = "Add a new " + this.labels.userGroup.singular + ":";
    switch (this.action) {
      case "delete":
        this.title = "Delete " + this.labels.userGroup.singular;
        this.msgs.actionMsg =
          "Delete this " +
          this.labels.userGroup.singular +
          "from the system? This cannot be undone.";

        this.btnLabel.close = "Close";
        this.btnLabel.main = "Delete";
        break;
      case "edit":
        this.continueType = "continue";
        this.title = "Edit " + this.labels.userGroup.singular;
        this.msgs.actionMsg =
          "Edit " + this.labels.userGroup.singular + " details";
        this.btnLabel.close = "Close";
        this.btnLabel.main = "Update";
        this.btnLabel.retry = "Continue Editing";
        break;
    }

    console.log(
      "this.btnLabel in handleDynamicCopy manage-user-group",
      this.btnLabel
    );
  }

  public manageGroup() {
    //add toast notifications
    let actionAdj = this.action.replace(/e$/, "");
    let notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing ${this.labels.userGroup.singular
      }`
    );

    this.msgs.errorMsg = "";
    this.formState = "processing";
    this.btnLabel.retry = "Continue Editing";
    this.msgs.statusMsg = `<span class='loading-msg'>Adding ${this.labels.userGroup.singular}</span>`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    let incomingValues = this.action !== 'delete' ? this.groupingForm.value : this.targetGroup;

    let thisGroup = {
      group_type: "user",
      name: incomingValues.group_name,
      description: incomingValues.group_description,
      team_id: this.teamID,
    };

    let options = {
      body: JSON.stringify(thisGroup),
    };

    let manageGroup = this._xrPlatformRestService.manageEntity(
      "group",
      this.teamID,
      "add",
      options,
      headers
    );

    switch (this.action) {
      case "edit":
        this.msgs.statusMsg = `Updating ${this.labels.userGroup.singular}...`;

        let thisGroup = {
          name: incomingValues.group_name,
          description: incomingValues.group_description,
        };

        options = {
          body: JSON.stringify(thisGroup),
        };

        manageGroup = this._xrPlatformRestService.manageEntity(
          "group",
          this.targetGroup.id,
          "update",
          options,
          headers
        );

        break;
      case "delete":
        this.msgs.statusMsg = `Deleting ${this.labels.userGroup.singular}...`;

        manageGroup = this._xrPlatformRestService.manageEntity(
          "group",
          this.targetGroup.id,
          "delete",
          options,
          headers
        );

        break;
    }

    manageGroup.subscribe(
      (response) => {
        this.msgs.statusMsg = `${this.TitleCase.transform(
          this.labels.userGroup.singular
        )} successfully saved.`;

        let groupOut = {
          number_of_members: 0,
          id: 0,
          name: thisGroup.name,
          description: thisGroup.description,
        };

        switch (this.action) {
          case "add":
            groupOut.id = response.group;
            break;
          case "delete":
            this.msgs.statusMsg = `${this.TitleCase.transform(
              this.labels.userGroup.singular
            )} successfully deleted`;
            groupOut.id = this.targetGroup.id;
            break;
          case "edit":
            this.msgs.statusMsg = `${this.TitleCase.transform(
              this.labels.userGroup.singular
            )} successfully updated.`;
            groupOut.id = this.targetGroup.id;
            break;
        }

        let outbound = {
          action: this.action,
          group: groupOut,
        };

        this.outgoing.next(outbound);
        this._notificationService.clearNotification(notification);
        this.scheduleFrame.hide();
      },
      (err) => {
        this.msgs.statusMsg = "";
        this.btnLabel.retry = "Retry";
        let errorMsg = err.statusText;
        this.msgs.errorMsg = errorMsg;
      }
    );
  }

  public resetForm() {
    this.formState = "active";
    this.preSelected = {
      group_name: "",
      group_description: "",
    };

    this.groupingForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.formState = "active";
  }

  public handleIncomingAction(action) {
    ;
    switch (action) {
      case "close-modal":
        this.scheduleFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
