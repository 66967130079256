<div class="background-replacement" (click)="turnConfirmOn(confirmModal)"></div>
<div class="class-wrapper" [ngClass]="{ 'confirm-on': confirmOn }">
  <app-status-messages
    [msgs]="msgs"
    [btnLabel]="btnLabel"
    [continueType]="continueType"
    (targetAction)="handleIncomingAction($event)"
  ></app-status-messages>
  <div class="modal-header text-center">
    <h4
      class="modal-title w-100 font-weight-bold"
      [ngClass]="{ removeTitle: action === 'remove' }"
    >
      <mdb-icon far icon="calendar-alt" *ngIf="action !== 'remove'"></mdb-icon>
      <mdb-icon
        far
        icon="trash-alt"
        size="2x"
        *ngIf="action === 'remove'"
      ></mdb-icon>
      <span>{{ title|titlecase }}</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="scheduleGroupFrame.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-3 p-0">
    <div class="row">
      <div class="col-12">
        <p class="text-center">
          {{ msgs.actionMsg }} <strong> {{ targetEvent.name }}</strong>
        </p>
      </div>
    </div>
    <div class="row flex-add-form" *ngIf="!formLoading">
      <form
        class="text-center p-5 edit-form w-100"
        [formGroup]="groupForm"
        autocomplete="off"
      >
        <div class="md-form mb-5" *ngIf="!viewOnly.group">
          <input
            type="text"
            id="groupAuto"
            formControlName="groupAutocomplete"
            [ngModel]="searchText | async"
            (ngModelChange)="searchText.next($event)"
            [mdbAutoCompleter]="auto"
            placeholder="{{labels.userGroup.singular | titlecase}} Name"
            class="completer-input form-control mdb-autocomplete"
            mdbInput
          />
          <label for="groupAuto"></label>
          <mdb-auto-completer
          [displayValue]="onDisplayValue"
            #auto="mdbAutoCompleter"
            textNoResults="No results found"
          >
          <mdb-option
          *ngFor="let option of results | async"
          [value]="option"
        >
          {{ option.name }}
        </mdb-option>
           </mdb-auto-completer>
        </div>
        <div *ngIf="viewOnly.group">
          <p>
            <strong class="font-weight-bold">{{ preSelected.name }}</strong>
            from
            <strong class="font-weight-bold">{{ targetEvent.name }}</strong>
          </p>
        </div>
      </form>
    </div>
  </div>

    <div class="modal-footer" *ngIf="!formLoading">
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button btn"
        data-dismiss="modal"
        [disabled]="!groupForm.valid || formState === 'processing'"
        (click)="manageGroup()"
      >
        {{ btnLabel.main }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        size="md"
        color="link"
        data-dismiss="modal"
        (click)="scheduleGroupFrame.hide()"
      >
        {{ btnLabel.close }}
      </button>
  </div>
</div>
<!-- confirm modal -->
<div
  mdbModal
  #confirmModal="mdbModal"
  class="modal confirm-modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmModal"
  aria-hidden="true"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100">Confirm</h4>
      </div>
      <div class="modal-body">
        <p>
          <strong>Are you sure?</strong>
        </p>
        <p>All form fields will be cleared upon exit.</p>
      </div>
      <div class="modal-footer">
        <button
          mdbBtn
          type="button"
          mdbWavesEffect
          class="theme-modal-button btn"
          (click)="this.closeConfirm(confirmModal)"
        >
          Continue Editing
        </button>
        <button
          mdbBtn
          mdbWavesEffect
          type="button"
          size="md"
          color="link"
          (click)="this.closeAllModals(confirmModal)"
        >
          Close Without Saving
        </button>
      </div>
    </div>
  </div>
</div>
