import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { FormGroup, UntypedFormBuilder } from "@angular/forms";
import { CoolLocalStorage } from "@angular-cool/storage";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-add-event-connection-modal",
  templateUrl: "./add-event-connection-modal.component.html",
  styleUrls: ["./add-event-connection-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe]
})
export class AddEventConnectionModalComponent implements OnInit {
  constructor(
    public addconnection: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private formBuilder: UntypedFormBuilder,
    private TitleCase: TitleCasePipe
  ) {}

   //incoming
  public targetConnection: any;
  private connection_id: number;
  public action: string;
  private teamID: number;
  public event: any;
  public labels: any;
  public token: string;

  public events: any;
  public event_names: string[] = [];
  public textNoResults = "No results found";
  searchText = new Subject();
  results: Observable<string[]>;

  public connectionForm = this.formBuilder.group({
    event_name: "",
  });

  //status messages
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
  } = {
    errorMsg: "",
    statusMsg: "",
    processingMsg: "",
  };

  public continueType: string;

  public btnLabel: {
    close: string;
    reset: string;
    retry: string;
    main: string;
  } = {
    close: "Close",
    reset: "Add Another Connection",
    retry: "Retry",
    main:  "",
  };

  private outgoing: Subject<any> = new Subject();
  
 

  //copy
  public title: string;


  ngOnInit(): void {
    ;
    this.continueType = "none";
    if (this.action === undefined) this.action === "add";

    this.retrieveLabels();
    this.btnLabel.main="connect to "+this.labels.event.singular;

    this.handleCopy();

    this.retrieveToken();
    if (this.action === "add") {
    this.getEvents();
    this.results = this.searchText.pipe(
      startWith(""),
      map((value: string) => this.filter(value))
    );
    }
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }
   private handleCopy() {
    this.title = "";
    this.btnLabel.reset = "Add Another " + this.TitleCase.transform(this.labels.eventConnection.singular);

    if (this.action === "remove") {
      this.title = "You are about to remove a " + this.labels.eventConnection.singular;
      this.btnLabel.main = "Remove";
    }
  }

  private getEvents() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let events = this._xrPlatformRestService.getEventsByTeamSimple(
      this.teamID,
      getOptions
    );
    this.textNoResults = "Loading..."; 
    events.subscribe((response) => {
      ;
      this.events = response.scheduled_event;
      this.events.forEach((element) => {
        this.event_names.push(element.event_name);
      });
      this.results = this.searchText.pipe(
        startWith(""),
        map((value: string) => this.filter(value))
      );
      this.textNoResults = "No results found";
      if (response.error != 418) {
        ;
      }
      (error) => {
        //@todo: handle error
      }
    });
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.event_names.filter((item: string) =>
      item.toLowerCase().includes(filterValue)
    );
  }

  public clickAddConnection() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      `<span class='processing-msg'>Adding ${this.TitleCase.transform(this.labels.eventConnection.singular)}</span>`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let index = this.event_names.indexOf(this.connectionForm.value.event_name);
    
    let addedConnection = this._xrPlatformRestService.addEventConnection(
      this.event.id,
      this.events[index].id,
      getOptions
    );
    addedConnection.subscribe(
      (response) => {
        ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.eventConnection.singular)} successfully added`;

        if (response.error != 418) {
          ;
          let outgoingData = {
            action: "add",
            connected: response.connection,
          };
          this.outgoing.next(outgoingData);
        }
      },
      (error) => {
        ;
        this.msgs.processingMsg = "";
        let errorMsg = JSON.parse(error._body);
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = errorMsg.error;
      }
    );
  }

  public roomActions() {
    if (this.action === "add") {
      this.clickAddConnection();
    } else {
      this.removeConnection();
    }
  }
  removeConnection() {
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    this.msgs.processingMsg =
      `<span class='processing-msg'>${this.TitleCase.transform(this.labels.eventConnection.singular)} connection</span>`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let retrievedConnections =
      this._xrPlatformRestService.deleteEventConnection(
        this.connection_id,
        getOptions
      );
    retrievedConnections.subscribe((response) => {
      ;

        this.msgs.processingMsg = "";
        this.msgs.errorMsg = "";
        this.msgs.statusMsg = `${this.TitleCase.transform(this.labels.eventConnection.singular)} successfully removed`;

      if (response.error != 418) {
        ;
        let outgoingData = {
          action: "delete",
          connection_id: this.connection_id,
        };
        this.outgoing.next(outgoingData);
      }
    },
    (error) => {
      ;
      this.msgs.processingMsg = "";
      let errorMsg = JSON.parse(error._body);
      this.btnLabel.retry = "Retry";
      this.msgs.errorMsg = errorMsg.error;
    });
  }

  public resetForm() {
    this.connectionForm.reset();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
  }

  public handleIncomingAction(action) {
    switch (action) {
      case "close-modal":
        this.addconnection.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }
}
