import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

@Injectable({
  providedIn: "root",
})
export class UserServicesService {
 
  public outgoing$: BehaviorSubject<any> = new BehaviorSubject(false);
  constructor(private _xrPlatformRestService: XrPlatformRestService) {}

  public processGroups(groupsIn) {
    if (!groupsIn.group_information.length) return [];

    let groupsOut = [];

    groupsIn.group_information.forEach((group) => {
      let thisGroup = group;
      group.members = [];
      group.membersLoaded = false;

      groupsOut.push(thisGroup);
    });

    return groupsOut;
  }

  public retrieveMembers(group_id, options) {
    let retrieveMembers = this._xrPlatformRestService.retrieveEntityCollection(
      "group",
      "members",
      group_id,
      options,
      true
    );

    return retrieveMembers.toPromise();
  }

  public retrieveScheduledEventByID(scheduled_event_id, options) {
    let retrieveScheduledEvent = this._xrPlatformRestService.retrieveEntityData(
      "schedule",
      scheduled_event_id,
      options
    );

    return retrieveScheduledEvent.toPromise();
  }

  public setUpGroupsForScheduledEvents(group_id, scheduled_event_id, options) {
    let retrieveMembers = this.retrieveMembers(group_id, options);
    let retrieveScheduledEventByID = this.retrieveScheduledEventByID(
      scheduled_event_id,
      options
    );

    let promises = [retrieveMembers, retrieveScheduledEventByID];

    return Promise.all(promises);
  }

  /**
   * Temporary override for admin caps until we have a DB-based labelling system
   * @param labelIn 
   * @param capValue 
   * @returns 
   */
  public systemCapabilityLabelling(labelIn, capValue){
    
    let labelOut = labelIn;

    switch (capValue) {
      case 3:
        labelOut = "Moderator";
        break;
    }

    return labelOut;

  }
}
